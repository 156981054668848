export const loadReportSSOELayout = {
"idType":"",
"mbrBundleReport":true,
"sftp": {
"flag": "",
"format": "",
"delimiter": ""
},
"response": {
"flag": "",
"format": "",
"delimiter": ""
},
"breakType": false,
"sortOrder": [],
"includeMsgTypes": [],
"updatedTS": "",
"maskedData":[],
"reportFormat":[]
}

export const contactsSSOELayout = {
"email": {
"flag": "",
"distributionList": [],
"format": "",
"delimiter": "",
"updatedContactsTS": ""
}
}
