import {
  AfterContentInit,
  AfterViewInit,
  Component,
  Input, OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {Store} from '@ngrx/store';
import {Router} from '@angular/router';
import {AgGridAngular} from 'ag-grid-angular';
import {combineLatest, Observable, Subscription} from 'rxjs';
import {Filetracker} from '../../models/Filetracker';
import {UsersService} from '../../services/users.service';
import {ClearFiletrakcerAction, LoadAllFiletrackerAction, LoadFiletrackerAction} from '../../actions/filetracker.action';
import {getDrivers, getIsRegisteredUser,
  getReadOnly, getRole, getUserId,getPermissionsFiletrackerObject} from '../../reducers';
import * as fromRoot from '../../reducers';
import * as ColumnSets from '../../filetracker-dashboard/filetrackerDashboardColumnSets';
import {FiletrackerService} from '../../services/filetracker.service';
import {Permission} from '../../models/Authorization';
import {EligibilityDialogComponent} from "../../eligibility-dialog/eligibility-dialog.component";
import {MatLegacyDialog as MatDialog} from "@angular/material/legacy-dialog";
import {MatLegacySlideToggleChange as MatSlideToggleChange} from "@angular/material/legacy-slide-toggle";
import {saveAs} from "file-saver";
import {tap} from "rxjs/operators";
import {AlertType} from "../../models/AlertType";
import {SetFirstLoadAction} from "../../actions/epr.action";

@Component({
  selector: 'app-eprfiletracker',
  templateUrl: './epr-file-tracker.component.html',
  styleUrls: ['./epr-file-tracker.component.scss']
})
export class EPRFiletrackerComponent implements OnInit, AfterViewInit , OnDestroy, OnChanges {
  @ViewChild('eprfiletracker') eprfiletracker: AgGridAngular;
  @ViewChild('eprfilemonitor') eprfilemonitor: AgGridAngular;

  @Input() readOnly: boolean;


  isRegisteredUser$ = this.store.select(getIsRegisteredUser) as Observable<boolean>;

  getUserIdSubscription: Subscription;
  filetracker$ = this.store.select(fromRoot.getAllFiletracker) as Observable<Filetracker[]>;

  drivers$ = this.store.select(getDrivers);


  currentUserId: number;
  currentDateMonitor: Date;
  pli: any;
  isAdmin = false;
  islatest = true;
  role: any;
  isAddNewEntry: boolean = true;
  permissionObject$ = this.store.select(getPermissionsFiletrackerObject) as Observable<Object>;
  permissionObject:any;
  constructor(private store: Store<fromRoot.AppState>, private router: Router,
              public dialog: MatDialog,
              private usersService: UsersService, private filetrackerService: FiletrackerService,
              ) {
  }

  ngOnInit() {
    this.usersService.userRole.subscribe(role =>{
      if(role){
        this.role= role;
        if ([Permission.VISITOR].includes(role)) {
          this.isAdmin = false;
        } else {
          this.isAdmin = true;

        }
      }
    });
    this.usersService.userId.subscribe((userId) => {
      this.currentUserId = userId
    })
    // this.getUserIdSubscription = this.store.select(getUserId).subscribe(userId => this.currentUserId = userId);
    this.store.dispatch(new LoadAllFiletrackerAction());
    // this.readOnly$.subscribe(res => {
    //   this.readOnly = res;
    // });
    this.store.subscribe(resp => {
      this.pli = resp.header.pli;
    });
    // this.store.select(getRole).subscribe(role => {
      this.permissionObject$.subscribe(res=>{
        this.permissionObject = res
      })
  }



  ngAfterViewInit(): void {
    this.getNewTableData('tableOne', 'tableOne');
    if(this.readOnly){
      this.eprfiletracker.api.setGridOption("rowData", []);
      this.isAddNewEntry = true;
    } else if(!this.readOnly && (this.role === Permission.FILETRACKER || this.role === Permission.VISITOR)){
      this.eprfiletracker.api.setGridOption("rowData", []);
      this.isAddNewEntry = true;
    } else{
      this.isAddNewEntry = false;
    }
  }


  onRowClicked($event: any) {
    if((this.permissionObject.viewFtOnboarding
      && this.permissionObject.pbmRole)){
    this.filetrackerService.isNew = false;
    this.filetrackerService.fileTrackerRowData = $event.data
    this.filetrackerService.cltSetupId = $event.data.cltSetupID
    this.store.dispatch(new LoadFiletrackerAction($event.data.cltSetupID));
  }else{
    return
  }
  }

  onRowClicked_Monitor($event: any) {

    $event.data.screenName = true;

    const dialogRef = this.dialog.open(EligibilityDialogComponent, {
      width: '1400px',
      height: '750px',
      panelClass: 'my-dialog',
      disableClose: true ,
      data: {
        selected: 'Eligibility',
        body: $event.data,
      }
    });

  }
  onDragStopped(tableId: string) {
  }

  onSortChange(tableId: string) {
  }

  onFilterChange(tableId: string) {

  }

  refreshMyProcessingFiles(tableId: string) {
    if (tableId === 'tableTwo') {
      this.currentDateMonitor = new Date();
      if (this.pli !== null && this.pli.length) {
        if (this.islatest === true) {
          const idno = 0;
          // this.filetrackerService.getFileTrackerEligibilityPli(idno, this.pli).subscribe(rowData => {
          //   this.eprfilemonitor.api.setGridOption('rowData',rowData);
          // });
          this.eprfilemonitor.api.setGridOption("rowData", []);
        } else {
          const idno = -1;
          // this.filetrackerService.getFileTrackerEligibilityPli(idno, this.pli).subscribe(rowData => {
          //   this.eprfilemonitor.api.setGridOption('rowData',rowData);
          // });
          this.eprfilemonitor.api.setGridOption("rowData", []);
        }
      } else {
        this.eprfilemonitor.api.setGridOption("rowData", []);
      }
    }
  }

  private setColumnType() {
    this.eprfiletracker.api.setGridOption("columnDefs", ColumnSets.filetrackerColumns);
  }
  private setColumnTypeMonitor() {
    this.eprfilemonitor.api.setGridOption("columnDefs", ColumnSets.filetrackerMCColumns);
  }
  onFirstDataRendered = params => {
    const yourFilterComponent = params.api.getFilterInstance(this.pli);
    yourFilterComponent.set({
      filterType: 'text',
      type: 'contains',
      filter: 'No'
    });
    params.api.onFilterChanged();
  }
  private getNewTableData(dataSetToFetch: string, tableId: string) {
    let sortOrder;
    this.setColumnType();
    this.setColumnTypeMonitor();
    sortOrder = [];
    this.setSortOrder(tableId, sortOrder);

    this.filetrackerService.getFiletrackerByPli(this.pli).subscribe((filetrackerData) => {
      if (filetrackerData.length) {
            // const flterData =  filetrackerData.filter(rec => rec['pliCode'] === this.pli);
            this.sortinOrderByAction(filetrackerData, filetrackerData.length);
            // this.eprfiletracker.api.setGridOption('rowData',flterData);
            // this.eprGridHeight.next(flterData.length * 42 + 69);
          }
        // this.rowData = filetrackerData;
        // setTimeout(() => {
        //   const pliCodeComponent = this.eprfiletracker.api.getFilterInstance('pliCode');
        //   pliCodeComponent.setModel({
        //     type: 'contains',
        //     filter: 123
        //   });
        //   pliCodeComponent.onAnyFilterChanged();
        // }, 100);

    });
    if (this.pli !== null && this.pli.length) {
      if (this.islatest === true) {
        const idno = 0;
        // this.filetrackerService.getFileTrackerEligibilityPli(idno, this.pli).subscribe(rowData => {
        //   this.eprfilemonitor.api.setGridOption('rowData',rowData);
        // });
        this.eprfilemonitor.api.setGridOption("rowData", []);
      } else {
        const idno = -1;
        // this.filetrackerService.getFileTrackerEligibilityPli(idno, this.pli).subscribe(rowData => {
        //   this.eprfilemonitor.api.setGridOption('rowData',rowData);
        // });
        this.eprfilemonitor.api.setGridOption("rowData", []);
      }
    } else {
      this.eprfilemonitor.api.setGridOption("rowData", []);
    }
  }
  onChange($event: MatSlideToggleChange) {
    this.islatest = $event.checked;
    this.getNewTableData('tableOne', 'tableOne');
  }
  sortinOrderByAction(data, dataSize) {
    let lowVal = 0;
    const hightVl = dataSize - 1;
    let midVal = 0;
    let tempVal = data[0];
    while (midVal <= hightVl) {
      if (data[midVal].ActiveorInactive === 'A') {
        tempVal = data[lowVal];
        data[lowVal] = data[midVal];
        data[midVal] = tempVal;
        lowVal++;
        midVal++;
      } else {
        midVal++;
      }
    }
    this.eprfiletracker.api.setGridOption("rowData", data);
  }

  private setRowData(rowData) {
    this.eprfiletracker.api.setGridOption("rowData", rowData);
  }

  private setSortOrder(tableId: string, sortOrder) {
    this.eprfiletracker.api.applyColumnState({
      state: sortOrder,
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(this.readOnly){
      this.eprfiletracker?.api.setGridOption("rowData",[]);
    } else if(!this.readOnly && (this.role === Permission.FILETRACKER || this.role === Permission.VISITOR)){
      this.eprfiletracker?.api.setGridOption("rowData",[]);
    } else{
      this.isAddNewEntry = false;
    }
  }
  ngOnDestroy(): void {
    this.getUserIdSubscription?.unsubscribe();
  }
  exportView(tableId) {
    const date = new Date();
    const fileName = 'Filetracker_' + date.getTime() + '.csv';
    const fileData = new Blob([this.eprfilemonitor.api.getDataAsCsv()], {type: 'text/csv'});
    saveAs(fileData, fileName);
  }
  fetchTableData(event) {
    if (event.value === 'Spedm') {
      this.router.navigateByUrl('spedm');
    }
  }
  createNewFiletracker(): void {
    // if (this.readOnly) {
    //   return;
    // }
    this.filetrackerService.isNew = true;
    this.store.dispatch(new ClearFiletrakcerAction());
    this.store.dispatch(new LoadFiletrackerAction(0));
    this.filetrackerService.isNew = true;
  }
}
