import { Injectable } from '@angular/core';
import { Form, FormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {environment} from "../../../environments/environment";
import {CVSHttpClient} from "angular-component-library";
import {HttpClient} from "@angular/common/http";


@Injectable({
  providedIn: 'root'
})
export class MappingFormService {
  mappingDocSelected$: Subject<any> = new Subject();
  newMapping$: Subject<any> = new Subject();
  editableMode$: Subject<any> = new Subject();
  showValidations$: Subject<any> = new Subject();
  mappinGuideValueChange$: Subject<any> = new Subject();
  disableMappingEdit$: Subject<any> = new Subject();
  importDocument$: Subject<any> = new Subject();
  addRequirementValidations$: Subject<any> = new Subject();
  requirementFormData: any = null;
  mapTypeValueChange$: Subject<any> = new Subject();
  is834Format$: Subject<any> = new Subject();
  mapStatusValueChange$: Subject<any> = new Subject();

  constructor(private fb: UntypedFormBuilder,private http: CVSHttpClient, private httpgen:HttpClient) { }

  public is834Format(data) {
    this.is834Format$.next(data);
  }
  public addRequirementValidations(data) {
    this.addRequirementValidations$.next(data);
  }
  public importDocument(data) {
    this.importDocument$.next(data);
  }
  public disableMappingEdit(data) {
    this.disableMappingEdit$.next(data);
  }
  public mappingGuideValueChange(data) {
    this.mappinGuideValueChange$.next(data);
  }
  public mapStatusValueChange(data) {
    this.mapStatusValueChange$.next(data);
  }
  public mapTypeValueChange(data) {
    this.mapTypeValueChange$.next(data);
  }

  public showValidations(showValidation) {
    this.showValidations$.next(showValidation);
  }
  public mappingDocSelected(rowData) {
    this.mappingDocSelected$.next(rowData);
  }
  public loadNewMapping(data) {
    this.newMapping$.next(data);
  }
  public editableMode(editable) {
    this.editableMode$.next(editable);
  }
  generateMappingGuideForm(): any {



    const form = new UntypedFormGroup({
      project: new UntypedFormControl(null),
      mappingStatus: new UntypedFormControl(null),
      mappingFormat : new UntypedFormControl(null),
      mapType: new UntypedFormControl(null),
      userStory: new UntypedFormControl(null, ),
      mappingVendor: new UntypedFormControl(null, ),
      multiplePli: new UntypedFormControl(null, ),
      recTypes: new UntypedFormControl(null),
      cagManagement: new UntypedFormControl(null, ),
      cagTableId: new UntypedFormControl(null, ),
      mappingBA: new UntypedFormControl(null, ),
      mappingLeader: new UntypedFormControl(null, ),
      mappingCoder: new UntypedFormControl(null, ),
      mappingQA: new UntypedFormControl(null, ),
      mappingComments: new UntypedFormControl(null, ),
      technicalContact: new UntypedFormControl(null, ),

  });
    return form;
  }
  generateToggleForm() {
    const form = this.fb.group({
      enableValidations: false,
    });
    return form;
  }

  generateMappingRequirementForm() {
    const form = new UntypedFormGroup({
      ftpId: new UntypedFormControl(null),
      fileSchedule: new UntypedFormControl(null),
      isa12: new UntypedFormControl(null),
      gs08: new UntypedFormControl(null),
    //  st03: new UntypedFormControl(null),
      valueSentInST03: new UntypedFormControl(null),
      responseTransaction999: new UntypedFormControl(null),
      typeOf999Transaction: new UntypedFormControl(null),
      moreThanOneStSeSegments: new UntypedFormControl(null),
      isa05: new UntypedFormControl(null),
      isa06: new UntypedFormControl(null),
      isa07: new UntypedFormControl(null),
      isa08: new UntypedFormControl(null),
      gs02: new UntypedFormControl(null),
      gs03: new UntypedFormControl(null),
      segmentSentOnEdi834: new UntypedFormControl(null),
      segmentSentOnEdi834Instructions: new UntypedFormControl(null),
      p5N102: new UntypedFormControl(null),
      p5N104: new UntypedFormControl(null),
      inN102: new UntypedFormControl(null),
      inN104: new UntypedFormControl(null),
      carrierIdSent: new UntypedFormControl(null),
      carrierIdInstructions: new UntypedFormControl(null),
      accountIdSent: new UntypedFormControl(null),
      accountIdInstructions: new UntypedFormControl(null),
      groupIdSent: new UntypedFormControl(null),
      groupIdInstructions: new UntypedFormControl(null),
      clientUsesPersonCode: new UntypedFormControl(null),
      isPcAutoGenerated: new UntypedFormControl(null),
      isPcAppendedToId: new UntypedFormControl(null),
      pcAppendedIdInstructions: new UntypedFormControl(null),
      memberId: new UntypedFormControl(null),
      memberIdForDependents: new UntypedFormControl(null),
      alternateIdUsed: new UntypedFormControl(null),
      alternateIdAutoGenerated: new UntypedFormControl(null),
      alternateIdGeneratedInstructions: new UntypedFormControl(null),
      alternateIdForDependents: new UntypedFormControl(null),
      thirdIdUsed: new UntypedFormControl(null),
      q3rdIdFieldType: new UntypedFormControl(null),
      thirdIdForDependents: new UntypedFormControl(null),
      individualSsnSent: new UntypedFormControl(null),
      addressForDependents: new UntypedFormControl(null),
      extendedAddressRequired: new UntypedFormControl(null),
      isLanguageSent: new UntypedFormControl(null),
      isLanguageSentNotes: new UntypedFormControl(null),
      isEthnicitySent: new UntypedFormControl(null),
      isEthnicitySentNotes: new UntypedFormControl(null),
      type5RecordsSent: new UntypedFormControl(null),
      typeEEmail: new UntypedFormControl(null),
      typeEEmailInstructions: new UntypedFormControl(null),
      typePPrivacy: new UntypedFormControl(null),
      typePPrivacyInstructions: new UntypedFormControl(null),
      typeMMemberAddress: new UntypedFormControl(null),
      typeMMemberAddressInstructions: new UntypedFormControl(null),
      typeAMemberAncillary: new UntypedFormControl(null),
      typeAMemberAncillaryInstructions: new UntypedFormControl(null),
      multipleHdLoopsSent: new UntypedFormControl(' '),
      valueSentInLoop2300Hd03: new UntypedFormControl(null),
      valueHD05SentDependents: new UntypedFormControl(null),
      overrideValuesE5DS5C: new UntypedFormControl(null),
      effectiveDateSentInLoop2300Dtp01348: new UntypedFormControl(null),
      effectiveDateSentInLoop2300Dtp01348Instructions: new UntypedFormControl(null),
      effectiveDateOverrideNeeded: new UntypedFormControl(null),
      effectiveDateOverrideInstructions: new UntypedFormControl(null),
      terminationDateSentInLoop2300Dtp01349: new UntypedFormControl(null),
      terminationDateSentInLoop2300Dtp01349Instructions: new UntypedFormControl(null),
      terminationDateOverrideNeeded: new UntypedFormControl(' '),
      terminationDateOverrideNeededInstructions: new UntypedFormControl(null),
      isTermDateAToDate: new UntypedFormControl(null),
      careFacilityRequired: new UntypedFormControl(null),
      careFacilityRequiredInstructions: new UntypedFormControl(null),
      careQualifierRequired: new UntypedFormControl(null),
      careQualifierRequiredInstructions: new UntypedFormControl(null),
      healthcareExchangeGracePeriod: new UntypedFormControl(null),
      healthcareExchangeGracePeriodInstructions: new UntypedFormControl(null),
      cob: new UntypedFormControl(' '),
      cobInstructions: new UntypedFormControl(null),
      cdd: new UntypedFormControl(null),
      cddInstructions: new UntypedFormControl(null),
      medB: new UntypedFormControl(null),
      medBInstructions: new UntypedFormControl(null),
      rds: new UntypedFormControl(null),
      rdsInstructions: new UntypedFormControl(null),
      transitionFill: new UntypedFormControl(' '),
      transitionFillInstructions: new UntypedFormControl(null),
      otherInstructions: new UntypedFormControl(null),
      type4RecordsSent: new UntypedFormControl(null),
      questMultiCarrier: new UntypedFormControl(null),
      mappingReqInstructions: new UntypedFormControl(null)
    });
    const options = {
      ftpId: [],
      fileSchedule:[],
      isa12: [],
      gs08: [],
    //  st03: [],
    valueSentInST03: [],
    responseTransaction999: ['Yes', 'No', ' '],
    typeOf999Transaction: ['Acknowledgement', 'Error', ' '],
    moreThanOneStSeSegments: ['Yes', 'No', ' '],
    isa05: ['ZZ', '30', ' '],
    isa06: [],
    isa07: ['ZZ', '30', ' '],
    isa08: [],
    gs02: [],
    gs03: [],
    segmentSentOnEdi834: ['Yes', 'No', ' '],
    segmentSentOnEdi834Instructions: [],
    p5N102: [],
    p5N104: [],
    inN102: [],
    inN104: [],
    carrierIdSent: ['Yes', 'No', ' '],
    carrierIdInstructions: [],
    accountIdSent: ['Yes', 'No', ' '],
    accountIdInstructions: [],
    groupIdSent: ['Yes', 'No', ' '],
    groupIdInstructions: [],
    clientUsesPersonCode: [],
    isPcAutoGenerated: [],
    isPcAppendedToId: [],
    pcAppendedIdInstructions: [],
    memberId: [],
    memberIdForDependents: ['Yes, but Copy-Down needed (Conditional)', 'Yes, but Copy-Down needed (Always)', 'Yes, but Copy-Down not needed (Do not copy)', 'No, but Copy-Down needed (Always)','No, but Copy-Down not needed (Do not copy)'],
    alternateIdUsed: [],
    alternateIdAutoGenerated: [],
    alternateIdGeneratedInstructions: [],
    alternateIdForDependents: ['Yes, but Copy-Down needed (Conditional)', 'Yes, but Copy-Down needed (Always)', 'Yes, but Copy-Down not needed (Do not copy)', 'No, but Copy-Down needed (Always)','No, but Copy-Down not needed (Do not copy)'],
    thirdIdUsed: [],
    q3rdIdFieldType: [],
    thirdIdForDependents: ['Yes', 'No, Cardholder only file', 'No, Copy-Down needed', ' '],
    individualSsnSent: ['Yes', 'No', ' '],
    addressForDependents: ['Yes, but Copy-Down needed (Conditional)', 'Yes, but Copy-Down needed (Always)', 'Yes, but Copy-Down NOT needed (Do not copy)', 'No, but Copy-Down needed (Always)','No, but Copy-Down NOT needed (Do not copy)'],
    extendedAddressRequired: ['Yes, but Copy-Down needed (Conditional)', 'Yes, but Copy-Down needed (Always)', 'Yes, but Copy-Down NOT needed (Do not copy)', 'No, but Copy-Down needed (Always)','No, but Copy-Down NOT needed (Do not copy)'],
    isLanguageSent:['Yes - Standard', 'Yes - Custom','No', ' '],
    isLanguageSentNotes:[],
    isEthnicitySent:['Yes - Standard', 'Yes - Custom','No', ' '],
    isEthnicitySentNotes:[], type5RecordsSent: [],
    typeEEmail: [],
    typeEEmailInstructions: [],
    typePPrivacy: [],
    typePPrivacyInstructions: [],
    typeMMemberAddress: [],
    typeMMemberAddressInstructions: [],
    typeAMemberAncillary: [],
    typeAMemberAncillaryInstructions: [],
    multipleHdLoopsSent: ['Yes', 'No', ' '],
    valueSentInLoop2300Hd03: [],
    valueHD05SentDependents: ['Yes', 'No, Cardholder only file', 'No, Copy-Down needed'],
    overrideValuesE5DS5C: ['1', '8', ' '],
    effectiveDateSentInLoop2300Dtp01348: ['Yes', 'No', 'Alight TBA', ' '],
    effectiveDateSentInLoop2300Dtp01348Instructions: [],
    effectiveDateOverrideNeeded: ['Yes', 'No', ' '],
    effectiveDateOverrideInstructions: [],
    terminationDateSentInLoop2300Dtp01349: ['Yes', 'No', 'Alight TBA', ' '],
    terminationDateSentInLoop2300Dtp01349Instructions: [],
    terminationDateOverrideNeeded: ['Yes', 'No', ' '],
    terminationDateOverrideNeededInstructions: [],
    isTermDateAToDate: [],
    careFacilityRequired: [],
    careFacilityRequiredInstructions: [],
    careQualifierRequired: [],
    careQualifierRequiredInstructions: [],
    healthcareExchangeGracePeriod: [],
    healthcareExchangeGracePeriodInstructions: [],
    cob: ['Yes', 'No', ' '],
    cobInstructions: [],
    cdd: [],
    cddInstructions: [],
    medB: [],
    medBInstructions: [],
    rds: [],
    rdsInstructions: [],
    transitionFill: ['Yes', 'No', ' '],
    transitionFillInstructions: [],
    otherInstructions: [],
      mappingReqInstructionsData: []
  };
    return {form, options};
  }
  generateImportSearch() {
    const form = this.fb.group({
      search: new UntypedFormControl()
    });
    return form;
  }

  deleteMappingsFileSent(payload: any): Observable<any> {
    return this.http.post(`${environment.baseCustomMappingUrl}/requirements/deleteMappingsFileSent`,payload);
  }
}

