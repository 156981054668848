import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AgGridAngular } from 'ag-grid-angular';
import * as moment from 'moment';
import { DropdownOption } from '../../models/DropdownOption';
import { RteService } from '../../services/rte.service';
import {saveAs} from 'file-saver';

export const rteGridColumns = [
  {
    headerName: 'Id', field: 'id', hide: true
  },
  {
    headerName: 'Carrier Number', field: 'carrier',
    editable: false,
    sortable: true,
    filter: true, lockVisible: true, lockPosition: true, resizable: true,
    width: 170
  },
  {
    headerName: 'Date/Time', field: 'accDat',
    editable: false,
    sortable: true, filter: true, lockVisible: true, lockPosition: true, resizable: true,
    width: 180,
    valueFormatter(params) {
      return moment(params.value).format('MM/DD/YYYY HH:mm:ss');
  }
  },
  {
    headerName: 'Account', field: 'account',
    editable: false,
    sortable: true, filter: true, lockVisible: true, lockPosition: true, resizable: true,
    width: 140
  },
  {
    headerName: 'Group', field: 'group',
    editable: false,
    sortable: true, filter: true, lockVisible: true, lockPosition: true, resizable: true,
    width: 140
  },
  {
    headerName: 'Member Id', field: 'memberId',
    editable: false,
    sortable: true, filter: true, lockVisible: true, lockPosition: true, resizable: true,
    width: 180
  },
  {
    headerName: 'Response', field: 'response',
    editable: false,
    sortable: true, filter: true, lockVisible: true, lockPosition: true, resizable: true,
    width: 140
  },
  {
    headerName: 'Message', field: 'message',
    editable: false,
    sortable: true, filter: true, lockVisible: true, lockPosition: true, resizable: true,
    width: 200
  },
  {
    headerName: 'Master Segment', field: 'masterSegment',
    editable: false,
    sortable: true, filter: true, lockVisible: true, lockPosition: true, resizable: true,
    width: 250
  },
  {
    headerName: 'Segment Name', field: 'segmentName',
    editable: false,
    sortable: true, filter: true, lockVisible: true, lockPosition: true, resizable: true,
    width: 250
  }
];

@Component({
  selector: 'app-rte-metrics',
  templateUrl: './rte-metrics.component.html',
  styleUrls: ['./rte-metrics.component.scss']
})
export class RteMetricsComponent implements OnInit {
  @ViewChild('gridRTEMetric') gridRTEMetric: AgGridAngular;
  rteForm: UntypedFormGroup;
  dataRows = [];
  contextObj: any;
  hlGridHeight = 350;

  masterSegmentList = [
    { value: 'person', displayName: 'Person' },
    { value: 'familyType', displayName: 'Family Type' },
    { value: 'clientDefinedData', displayName: 'Client Defined Data' },
    { value: 'additionalId', displayName: 'Additional Id' },
    { value: 'memberAlternateInsurances', displayName: 'Member Alternate Insurances' },
    { value: 'memberCareFacilities', displayName: 'Member Care Facilities' },
    { value: 'memberHealth', displayName: 'Member Health' },
    { value: 'coordinationOfBenefitsMemberLi', displayName: 'Coordination Of Benefits MemberLi' },
    { value: 'individualMaximumOutOfPocket', displayName: 'Individual MaximumOutOfPocket' },
    { value: 'medicareParticipations', displayName: 'Medicare Participations' },
    { value: 'medicarePartB', displayName: 'Medicare PartB' },
    { value: 'mapTmemberCopayOverride', displayName: 'Member Copay Override' },
    { value: 'groupDetails', displayName: 'Group Details' },
    { value: 'groupEligibilities', displayName: 'Group Eligibilities' },
    { value: 'dependentAgeEdit', displayName: 'Dependent AgeEdit' },
    { value: 'consumerDrivenHealthCare', displayName: 'Consumer Driven HealthCare' },
    { value: 'groupMedicalIntegrationFlag', displayName: 'Group Medical Integration Flag' },
    { value: 'invoiceGroupNumber', displayName: 'Invoice Group Number' },
    { value: 'groupPointOfSaleRebate', displayName: 'Group Point Of Sale Rebate' },
    { value: 'groupAncillary', displayName: 'Group Ancillary' },
    { value: 'memberPrivacy', displayName: 'Member Privacy' },
    { value: 'memberEmail', displayName: 'Member Email' },
    { value: 'memberEligibilities', displayName: 'Member Eligibilities' },
    { value: 'memberAncillaryData', displayName: 'Member Ancillary Data' },
    { value: 'lerd', displayName: 'Lerd' },
    { value: 'member', displayName: 'Member' },
    { value: 'group', displayName: 'Group' },
    { value: 'groupdetails', displayName: 'Group Details' },
  ] as DropdownOption[];

  statusList = [
    { value: 'P', displayName: 'Passed' },
    { value: 'F', displayName: 'Failed' }
  ] as DropdownOption[];

  segmentList = [
    { value: 'APMBSP', displayName: 'APMBSP' },
    { value: 'RCMIDP', displayName: 'RCMIDP' },
    { value: 'RCMFWP', displayName: 'RCMFWP' },
    { value: 'RCAMDP', displayName: 'RCAMDP' },
    { value: 'RCAIDP', displayName: 'RCAIDP' },
    { value: 'RCMEXP', displayName: 'RCMEXP' },
    { value: 'APMXWP', displayName: 'APMXWP' },
    { value: 'RCDSRP', displayName: 'RCDSRP' },
    { value: 'RCMGEP', displayName: 'RCMGEP' },
    { value: 'RCGRPP', displayName: 'RCGRPP' },
    { value: 'RCGELP', displayName: 'RCGELP' },
    { value: 'RCDAEP', displayName: 'RCDAEP' },
    { value: 'APCPFP', displayName: 'APCPFP' },
    { value: 'RCGMIP', displayName: 'RCGMIP' },
    { value: 'RCAGDP', displayName: 'RCAGDP' },
    { value: 'RCARIP', displayName: 'RCARIP' },
    { value: 'RCPPWP', displayName: 'RCPPWP' },
    { value: 'RCGACP', displayName: 'RCGACP' },
    { value: 'RCMELP', displayName: 'RCMELP' },
    { value: 'RCMACP', displayName: 'RCMACP' },
    { value: 'RCLRDP', displayName: 'RCLRDP' },
    { value: 'RCMBRP', displayName: 'RCMBRP' },
    { value: 'RCMAIP', displayName: 'RCMAIP' },
    { value: 'RCMCFP', displayName: 'RCMCFP' },
    { value: 'RCMHIP', displayName: 'RCMHIP' },
    { value: 'APMSTP', displayName: 'APMSTP' },
    { value: 'RCMPLP', displayName: 'RCMPLP' },
    { value: 'APMLXP', displayName: 'APMLXP' },
    { value: 'RCFMBP', displayName: 'RCFMBP' },
    { value: 'RCMCOP', displayName: 'RCMCOP' }
  ] as DropdownOption[];

  constructor(
    private router: Router,
    private rteMetricsService: RteService,
    private fb: UntypedFormBuilder) {
    this.contextObj = {
      componentParent: this
    };
  }

  ngOnInit() {
    this.setupForm();
    this.masterSegmentList.sort((a, b) => a.displayName.localeCompare(b.displayName));
    this.segmentList.sort((a, b) => a.displayName.localeCompare(b.displayName));
  }

  private setupForm() {
    this.rteForm = this.fb.group({
      carriers: ['', [this.validation]],
      fromDate: ['', Validators.required],
      toDate: ['', Validators.required],
      account: '',
      group: '',
      memberId: '',
      masterSegment: [[]],
      segmentName: [[]],
      response: '',
      message: '',
    });
  }

  onGridReady(params) {
    this.gridRTEMetric = params;
    this.hlGridHeight = this.dataRows.length * 51;
    this.gridRTEMetric.api.setGridOption("columnDefs", rteGridColumns);
    this.gridRTEMetric.api.redrawRows();
  }

  validation(control: AbstractControl) {
    let valid = null;
    const value = control.value;
    let carrierArray = new Array();
    if (value) {
      if (/^[^`~!@#$%\^&*()_+={}|[\]\\:';"<>?./]*$/.test(control.value)) {
        if (value.indexOf(',') !== -1) {
          carrierArray = value.split(',');
          if (carrierArray.length >= 11) {
            return valid = { required: true };
          }
          let i = 0;
          for (const carrier of carrierArray) {
            const space = carrier.split(' ').length - 1;
            if (!(carrier.replace(/\s/g, '').length >= 4 && carrier.replace(/\s/g, '').length <= 9)) {
              valid = { required: true };
            }
            // if (space > 1) {
            //   valid = { required: true };
            // }
            // if (i === 0 && space >= 1) {
            //   valid = { required: true };
            // }
            // if (i > 0) {
            //   const index = carrier.indexOf(' ');
            //   if (!(index === 0) || space > 1) {
            //     valid = { required: true };
            //   }
            // }
            i++;
          }
        } else {
          const space = value.split(' ').length - 1;
          if (!(value.replace(/\s/g, '').length >= 4 && value.replace(/\s/g, '').length <= 9)) {
            valid = { required: true };
          }
          if (space >= 1) {
            valid = { required: true };
          }
        }
      } else {
        valid = { required: true };
      }
      return valid;
    }
  }

  onSubmit() {
    this.getRTEMetrics();
  }

  getRTEMetrics() {
    this.dataRows = [];
    const rteFormObj = Object.assign({}, this.rteForm.value);
    rteFormObj.carriers = rteFormObj.carriers.split(',')
      .map(word => `'${word?.trim().toUpperCase()}'`)
      .join(',');
    rteFormObj.fromDate = this.dateFormat(rteFormObj.fromDate);
    rteFormObj.toDate = this.dateFormat(rteFormObj.toDate);
    rteFormObj.memberId = rteFormObj.memberId?.toUpperCase();
    rteFormObj.account = rteFormObj.account?.toUpperCase();
    rteFormObj.group = rteFormObj.group?.toUpperCase();
    rteFormObj.masterSegment = rteFormObj.masterSegment.map(e => `'${e}'`).join(',') || null;
    rteFormObj.segmentName = rteFormObj.segmentName.map(e => `'${e}'`).join(',') || null;
    this.rteMetricsService.getRTEMetrics(rteFormObj).subscribe(res => {
      this.dataRows = res;
      localStorage.setItem('deactivate-alert', '0');
    });
  }

  exportView(tableId) {
    const date = new Date();
    const fileName = 'RTE_Metrics' + date.getTime() + '.csv';
    if (tableId === 'tableOne') {
      const fileData = new Blob([this.gridRTEMetric.api.getDataAsCsv()], {type: 'text/csv'});
      saveAs(fileData, fileName);
    }
  }

  dateFormat(dateStr: Date) {
    const date = new Date(dateStr);
    const yyyy = date.getFullYear().toString();
    const MM = this.pad2(date.getMonth() + 1, 2);
    const dd = this.pad2(date.getDate(), 2);
    const hh = this.pad2(date.getHours(), 2);
    const mm = this.pad2(date.getMinutes(), 2);
    const ss = this.pad2(date.getSeconds(), 2);
    const ms = this.pad2(date.getMilliseconds(), 6);

    return yyyy + MM + dd + hh + mm + ss + ms;
  }

  pad2(num, length) {
    let str = '' + num;
    while (str.length < length) {
      str = '0' + str;
    }
    return str;
  }

}
