import { GridInputFormRendererComponent } from 'src/app/common/GirdInputFormControl';
import { GridSelectFormRendererComponent } from 'src/app/common/GridSelectFormControl';
import {FormFieldConfig, InputType} from '../../models/FormFieldConfig';
import { GridSelectReportFormRendererComponent } from 'src/app/common/GridSelectReportFormRenderer';
import { DeleteBtnCellRendererComponent } from 'src/app/common/DeleteBtnCellRenderer';
import { DeleteBtnLoadFormatCellRendererComponent } from 'src/app/common/DeleteBtnLoadFormatCellRenderer';
import { DeleteMenuCellRendererComponent } from 'src/app/common/DeleteMenuCellRenderer.component';

export let questionnaireHardCodedFormConfig = [
  {
    label: 'Does client use Person Codes?',
    controlName: 'personCodes',
  },
  {
    label: 'PC Reuse Validation',
    controlName: 'pCReUse',
  },
  {
    label: 'Do we auto-gen?',
    controlName: 'pcautoGen',
  },
  {
    label: 'Autogen Program?',
    controlName: 'autogenprgm',
  },
  {
    label: 'Auto Gen Prgm Match Level',
    controlName: 'autoGenPrgmMatchLevel',
  },
  {
    label: 'Ignore PC on file?',
    controlName: 'ignorePConFile',
  },
  {
    label: 'Append PC to Alt ID?',
    controlName: 'qappndPCtoXwalk',
  },
  {
    label: 'Carrier list for PC autogen',
    controlName: 'qpcautogenCarList',
  },
  {
    label: 'PC Assignment Method',
    controlName: 'pcassignMethod',
  },
  {
    label: 'Is PC appended to ID?',
    controlName: 'pconID',
  },
  {
    label: 'PC Length',
    controlName: 'pclength',
  },
// AltID section
  {
    label: 'Does Client use Alternate ID?',
    controlName: 'xwalkUsed',
  },
  {
    label: 'Alternate ID type (with PC)',
    controlName: 'xwalkIDTypeWPC',
  },
  {
    label: 'Alternate ID type (w/o PC)',
    controlName: 'xwalkIDTypeWOPC',
  },
  {
    label: 'Do we autogen?',
    controlName: 'xwalkAutoGen',
  },
  {
    label: 'Autogen base length',
    controlName: 'autoGenBaseLngth',
  },
  {
    label: 'Autogen Prefix',
    controlName: 'xwalkAutogenPrefix',
  },
  {
    label: 'Override with Inbound',
    controlName: 'xwalkAGOverride',
  },
  {
    label: 'What ID goes on EOB\'s?',
    controlName: 'eobid',
  },
  {
    label: 'What ID goes on CET\'s?',
    controlName: 'cetid',
  },
  {
    label: 'What ID goes on Reports?',
    controlName: 'reportID',
  },
  {
    label: 'What ID goes on MAB dtl?',
    controlName: 'mabdtlID',
  },
  {
    label: 'What ID goes on Cards?',
    controlName: 'cardID',
  },
  {
    label: 'What ID goes on Mail Order Forms?',
    controlName: 'mailOrderFormID',
  },
  {
    label: 'Send X-Walk for FSA?',
    controlName: 'xwalkForFSA',
  },
  {
    label: 'Alt ID Full Length',
    controlName: 'xwalkIDLength',
  },
  {
    label: 'Will the 3rd ID Field be used?',
    controlName: 'q3rdIdFieldUsed',
  },
  {
    label: 'Does this ID need to be in QL?',
    controlName: 'q3rdIDinQL',
  },
// Divider section
// File Params section
  {
    label: 'File Params',
  },
  {
    label:'SSOE Migration',
    controlName: 'consolidateProfileLogic'

  },
  {
    label: 'Vendor',
    controlName: 'vendor',
  },
  {
    label: 'Is there more than one file feed for client?',
    controlName: 'multFileFeeds',
  },
// File Format section
  {
    label: 'What file format will be sent?',
    controlName: 'format',
    mdlName: 'fileFormat',
  },
  {
    label: 'Reason for non-standard format',
    controlName: 'cstmFmtRsnCd',
  },
  {
    label: 'Will B2B be used?',
    controlName: 'gisused',
  },
  {
    label: 'Will Pre-Preload be used?',
    controlName: 'prePreLoad',
  },
  {
    label: 'Pre-Preload Program',
    controlName: 'discPrePreProgram',
  },
  {
    label: 'Mapping to format?',
    controlName: 'fileParamMapToo',
  },
// Transmit section
  {
    label: 'How will client file be transmitted?',
    controlName: 'fileTransmitionMethod',
  },
  {
    label: 'FTP ID',
    controlName: 'ftpid',
  },
  {
    label: 'File Routing',
    controlName: 'qfileRouting',
  },
// File Type section
  {
    label: 'File Type',
    controlName: 'fileType',
  },
// History method section
  {
    label: 'History Method',
    controlName: 'qhistMethod',
  },
  {
    label: 'Will Type 4 be sent by client',
    controlName: 'moveHstFile',
  },
  {
    label: 'Will Type 5 (privacy/email) be sent by client?',
    controlName: 'type5UsedQ',
  },
  {
    label: 'Type \'E-Email\'?',
    controlName: 'type5EmailUsed',
  },
  {
    label: 'Type \'M-Member Address\'?',
    controlName: 'type5MbrAddUsed',
  },
  {
    label: 'Type \'P-Privacy\'?',
    controlName: 'type5PrvcyUsed',
  },
  {
    label: 'Type 5 Validation?',
    controlName: 'type5Validation',
  },
  {
    label: 'Type 5 Retention Days?',
    controlName: 'type5RetentionDays',
  },
  {
    label: 'Privacy Moves Level',
    controlName: 'privMoveLvlQ',
  },
  {
    label: 'Is Batch Override Protection Used?',
    controlName: 'batchOvrProtect',
  },
// Load Report section
  {
    label: 'How are load reports to be delivered?',
    controlName: 'loadRptsDelMethodQ',
  },
  {
    label: 'Report NDM/FTP Profile',
    controlName: 'qloadRptNDMFTPProf1',
  },
// File Frequency section
  {
    label: 'File Frequency',
    controlName: 'fileFrequency',
  },
  {
    label: 'Exclude Holidays from Schedule?',
    controlName: 'scheduleHolidays',
  },
  {
    label: 'Is there a variance?',
    controlName: 'scheduleVariance',
  },
// Divider section
// Vendor section
  {
    label: 'Who is the Vendor?',
    controlName: 'vendorLookup',
  },
// Divider section
// Terms section
  {
    label: 'Will client send term dates for all mbrs?',
    controlName: 'participantTermDates',
  },
  {
    label: 'Is the term date a \'to\' date?',
    controlName: 'termDateThru',
  },
  {
    label: 'Will dependent terms be sent on file?',
    controlName: 'depsOnFile',
  },
  {
    label: 'Will dependent implied terms be used?',
    controlName: 'depImpTermQues',
  },
  {
    label: 'Dependent Implied Terms Method',
    controlName: 'depImpTrmMthd',
  },
// Implied terms section
  {
    label: 'Will Implied Terms be used?',
    controlName: 'impliedTerms',
  },
  {
    label: 'At what level?',
    controlName: 'impTermLevel',
  },
  {
    label: 'Move History?',
    controlName: 'moveHistQues',
  },
  {
    label: 'Move Accums?',
    controlName: 'moveAccumsQues',
  },
  {
    label: 'Move Prior Auths?',
    controlName: 'movePriorAuthsQues',
  },
  {
    label: 'Include Termed Records?',
    controlName: 'impTrmInclTermRecs',
  },
  {
    label: 'Move claims prior to effective date?',
    controlName: 'impTrmsMoveALLClaims',
  },
// Term by Absence section
  {
    label: 'Will Term by Absence be used',
    id: 112,
    controlName: 'tmr',
  },
  {
    label: 'Ignore Future Terms (< open ended)?',
    id: 113,
    controlName: 'tmrignFtrTrm',
  },
  {
    label: 'Include Future Effectives?',
    id: 114,
    controlName: 'tmrinclFtrEff',
  },
  {
    label: 'TBA Date?',
    id: 115,
    controlName: 'tmrdate',
  },
  {
    label: 'Buffer Days',
    id: 116,
    controlName: 'bufferDays',
  },
  {
    label: 'TBA Lookahead',
    id: 117,
    controlName: 'tbaLookahead',
  },
  {
    label: 'Will TBA Variance be used?',
    id: 118,
    controlName: 'tbaVarianceDays',
  },
  {
    label: 'Variance Days',
    id: 214,
    controlName: 'tbaVarianceDaysCount',
  },
  {
    label: 'Range or List used (incl/excl)',
    id:119,
    controlName: 'tmrrangeListType',
  },
  {
    label: 'Range From',
    id:217,
    controlName: 'tmrrangeFrom',
  },
  {
    label: 'Range To',
    id:218,
    controlName: 'tmrrangeTo',
  },
  {
    label: 'Account List',
    id:120,
    controlName: 'incExcListName',
  },
  {
    label: 'Group List',
    id:121,
    controlName: 'grpInclExclList',
  },
  {
    label: 'Is manual entry TBA protection used?',
    id:122,
    controlName: 'manTMRProtect',
  },
// Compare Process section
  {
    label: 'Compare Process',
    id:123,
    controlName: 'compareProcess',
  },
  {
    label: 'ROBOT Name',
    id:124,
    controlName: 'compareRobotName',
  },
  {
    label: 'Email/NDM Script',
    id:125,
    controlName: 'compareNDMScript',
  },
  {
    label: 'Compare Frequency',
    id:126,
    controlName: 'compareFrequency',
  },
// Divider section
// Threshold section
  {
    label: 'Term by Absence Threshold (count)',
    id:127,
    controlName: 'questionnaireTermThreshold',
  },
  {
    label: 'Term by Absence Threshold (percentage)',
    id:128,
    controlName: 'termByAbsenceThshldPercent',
  },
  {
    label: 'Error Threshold (percentage)',
    id:129,
    controlName: 'flerrorThreshold',
  },

  {
    label: 'Term Threshold (Incl Moves)',
    id:130,
    controlName: 'termThreshold',
  },
  {
    label: 'Term Threshold Incl Moves Count',
    id:131,
    controlName: 'termThresholdInclMovesActCount',
  },
  {
    label: 'Term Threshold Excl Moves Count',
    id:132,
    controlName: 'termThresholdExclMovesActCount',
  },
  {
    label: 'Term Threshold All Act Count',
    id:133,
    controlName: 'termThresholdAllActCount',
  },
  {
    label: 'Term Threshold (Excl Moves)',
    id:134,
    controlName: 'termThreshExcMoves',
  },
  {
    label: 'Term Threshold (Against All Actives)',
    id:135,
    controlName: 'termsVsAllActives',
  },
  {
    label: 'Include Future Terms (< open ended)',
    id:136,
    controlName: 'thrshldInclFtrTrms',
  },
  {
    label: 'Inactivate Count Threshold',
    id:137,
    controlName: 'inactivateCountThreshold',
  },
  {
    label: 'Inactivate Pct Threshold',
    id:138,
    controlName: 'inactivatePctThreshold',
  },
  {
    label: 'MLX Chg Count Threshold',
    id:139,
    controlName: 'mLXChgCountThreshold',
  },
  {
    label: 'MLX Chg Pct Threshold',
    id:140,
    controlName: 'mLXChgPctThreshold',
  },
  {
    label: 'Term Move Count Threshold',
    id:141,
    controlName: 'termMoveCountThreshold',
  },
  {
    label: 'Term Move Pct Threshold',
    id:142,
    controlName: 'termMovePctThreshold',
  },
  {
    label: 'Family ID Threshold',
    id:143,
    controlName: 'famIDThrshld',
  },
  {
    label: 'Demographic Match Level',
    id:144,
    controlName: 'matchLevel',
  },
  // { // dropdown and textbox
  //   label: 'Change in Member ID',
  //   id:146,
  //   controlName: 'chngMbrIDUOM',
  // },
  // {
  //   label: 'Change in Member ID',
  //   id:146,
  //   controlName: 'chngMbrIDThrshld',
  // },
  // 2 dropdowns and textbox
  // {
  //   label: 'Last Name Change',
  //   id:147,
  //   controlName: 'chngLNThrshldUse',
  // },
  // {
  //   label: 'Last Name Change',
  //   id:147,
  //   controlName: 'chngLNUOM',
  // },
  // {
  //   label: 'Last Name Change',
  //   id:147,
  //   controlName: 'chngLNThrshld',
  // },
  // {
  //   label: 'First Name Change',
  //   id:148,
  //   controlName: 'chngFNThrshldUse',
  // },
  // {
  //   label: 'First Name Change',
  //   id:148,
  //   controlName: 'chngFNUOM',
  // },
  // {
  //   label: 'First Name Change',
  //   id:148,
  //   controlName: 'chngFNThrshld',
  // },
  // {
  //   label: 'DOB Change',
  //   id:149,
  //   controlName: 'chngDOBThrshldUse',
  // },
  // {
  //   label: 'DOB Change',
  //   id:149,
  //   controlName: 'chngDOBUOM',
  // },
  // {
  //   label: 'DOB Change',
  //   id:149,
  //   controlName: 'chngDOBThrshld',
  // },
  // {
  //   label: 'Relationship Change',
  //   id:150,
  //   controlName: 'chngRelThrshldUse',
  // },
  // {
  //   label: 'Relationship Change',
  //   id:150,
  //   controlName: 'chngRelUOM',
  // },
  // {
  //   label: 'Relationship Change',
  //   id:150,
  //   controlName: 'chngRelThrshld',
  // },
  {
    label: 'ID Card Threshold',
    id:151,
    controlName: 'idcardThrshld',
  },
// Printed Materials section
  {
    label: 'Do we print cards for entire population',
    id:152,
    controlName: 'cardsForEntPopulation',
  },
  {
    label: 'What ID goes on the card (regardless of who prints)?',
    id:153,
    controlName: 'idonCard',
  },
  {
    label: 'What are we printing?',
    id:154,
    controlName: 'kitCrdBklt',
  },
  {
    label: 'SSN Validation Used?',
    id:155,
    controlName: 'ssnidcrdValQ',
  },
  {
    label: 'ID Card Report Output Que',
    id:156,
    controlName: 'idcardOutque',
  },
  {
    label: 'Who do they want printed on the card?',
    id:157,
    controlName: 'field178',
  },
  {
    label: 'Regardless of CAG?',
    id:158,
    controlName: 'prntFamOnCard',
  },
  {
    label: 'Do we generate cards for moved members?',
    id:159,
    controlName: 'genCardForMovedMbr',
  },
  {
    label: 'Suppression Level?',
    id:160,
    controlName: 'cardSupLevel',
  },
  {
    label: 'What should trigger a card?',
    id:161,
    controlName: 'qcardTriggers',
  },
// Misc section
  {
    label: 'Multi-Birth Indicator',
    id:162,
    controlName: 'multiBirth',
  },
  {
    label: 'Is Auto-CAG used?',
    id:163,
    controlName: 'grpOTForAutoCAG',
  },
  {
    label: 'Sort Input File?',
    id:164,
    controlName: 'sortInputFile',
  },
  {
    label: 'Validate Headers and Trailers?',
    id:165,
    controlName: 'validateHeadersTrailers',
  },
// Group files section
  {
    label: 'Will there be Group Files?',
    id:166,
    controlName: 'groupFilesSent',
  },
  {
    label: 'Will Grp PPL Pgm be used?',
    id:167,
    controlName: 'grpPplPgmUsed',
  },
  {
    label: 'Grp PPL Program?',
    id:168,
    controlName: 'grpPplProgram',
  },
  {
    label: 'Will Group Suspense be used?',
    id:169,
    controlName: 'grpSuspUsed',
  },
  {
    label: 'Retention Days?',
    id:170,
    controlName: 'grpSuspRetentionDays',
  },
  {
    label: 'Prescriber/Physician Files?',
    id:171,
    controlName: 'qphysFiles',
  },
  {
    label: 'Diagnosis Files?',
    id:172,
    controlName: 'qdiagFiles',
  },
  {
    label: 'Lab Files?',
    id:173,
    controlName: 'qlabFiles',
  },
  {
    label: 'Bypass File Dependency?',
    id:174,
    controlName: 'bypassFileDependency',
  },
  {
    label: 'Will Member Suspense be used?',
    id:175,
    controlName: 'mbrSuspUsed',
  },
  {
    label: 'Retention Days?',
    id:176,
    controlName: 'mbrSuspRetentionDays',
  },
// Client Code section
  {
    label: 'Is Client Product Code Used?',
    id:177,
    controlName: 'cltPrdctUsed',
  },
  {
    label: 'Is Client Rider Code Used?',
    id:178,
    controlName: 'cltRiderUsed',
  },
  {
    label: 'Is Care Network Used?',
    id:179,
    controlName: 'careNtwrkUsed',
  },
  {
    label: 'Is Care Facility Used?',
    id:180,
    controlName: 'careFcltyUsed',
  },
  {
    label: 'Do we create on the fly?',
    id:181,
    controlName: 'careFcltyOnTheFly',
  },
  {
    label: 'Is Care Qualifier Used?',
    id:182,
    controlName: 'careQualifierUsed',
  },
  {
    label: 'Do we create on the fly?',
    id:183,
    controlName: 'careQualifierOnTheFly',
  },
  {
    label: 'Will Physician ID\'s (PCP\'s) be used?',
    id:184,
    controlName: 'pcpsUsed',
  },
  {
    label: 'Do we create on the fly?',
    id:185,
    controlName: 'pcponTheFlyUsed',
  },
  {
    label: 'Will Member Level Plans be used?',
    id:186,
    controlName: 'mbrLvlPlansUsedQ',
  },
  {
    label: 'Will Client Defined Data (CDD) be used?',
    id:187,
    controlName: 'qcddused',
  },
  {
    label: 'Is this a Healthcare Exchange Client?',
    id:188,
    controlName: 'hcexchangeMethodology',
  },
  {
    label: 'How will Grace Period be managed?',
    id:189,
    controlName: 'gracePeriodHandling',
  },
// Divider section
  {
    label: 'When is New Benefit Plan Year?',
    id:190,
    controlName: 'openEnroll',
  },
// Open enrollment section
  {
    label: 'Will client be sending an OE file?',
    id:191,
    controlName: 'qcltSendOEFile',
  },
  {
    label: 'When is Open Enrollment Window?',
    id:192,
    controlName: 'qoewindow',
  },
  {
    label: 'What data will be on OE File?',
    id:193,
    controlName: 'qdataOnOEFile',
  },
  {
    label: 'When will this file be sent?',
    id:194,
    controlName: 'qoefileExpDate',
  },
  {
    label: 'Will ID cards need to be reissued?',
    id:195,
    controlName: 'qoeidcards',
  },
  {
    label: 'Will explicit terms be sent for current year?',
    id:196,
    controlName: 'qoecurrentTerms',
  },
  {
    label: 'Does CVSH have to term dropped Mbrs?',
    id:197,
    controlName: 'qoecvstermDrops',
  },
  {
    label: 'What data will come after OE file?',
    id:198,
    controlName: 'qoedtaAfterOEFil',
  },
  {
    label: 'When will this file be sent?',
    id:199,
    controlName: 'qoefileAfterOEDate',
  },
  {
    label: 'What will be on 1st file after new bnft yr?',
    id:200,
    controlName: 'qoenewBnftYrFile',
  },
  {
    label: 'When will this file be sent?',
    id:201,
    controlName: 'qoenewBnftYrFileDate',
  },
  {
    label: 'Does CVSH have to term dropped Mbrs?',
    id:202,
    controlName: 'qoenewBnftYrFileTerms',
  },
  {
    label: 'Will there be CAG structure changes?',
    id:203,
    controlName: 'qoecagstructrChg',
  },
  {
    label: 'Will a test file be required if no changes?',
    id:204,
    controlName: 'qoetestFile',
  },
  {
    controlName: 'fileSubmitterName',
    label: 'Contact Information - Name'
  },
  {
    controlName: 'fileSubmitterEmail',
    label: 'Contact Information- Email'
  },
  {
    controlName: 'fileSubmitterPhone',
    label: 'Contact Information - Phone Number'
  },
  {
    controlName: 'loadReportName',
    label: 'Contact Information- Name'
  },
  {
    controlName: 'loadReportEmail',
    label: 'Contact Information - Email'
  },
  {
    controlName: 'loadReportPhone',
    label: 'Contact Information- Phone Number'
  },
  {
    controlName: 'otherName',
    label: 'Contact Information - Name'
  },
  {
    controlName: 'otherEmail',
    label: 'Contact Information- Email'
  },
  {
    controlName: 'otherPhone',
    label: 'Contact Information - Phone Number'
  },
  {
    controlName: 'contactRole',
    label: 'Additional Contacts- Role'
  },
  {
    controlName: 'contactName',
    label: 'Additional Contacts - Name'
  },
  {
    controlName: 'contactEmail',
    label: 'Additional Contacts- Email'
  },
  {
    controlName: 'contactPhone',
    label: 'Additional Contacts - Phone Number'
  },
  {
    controlName: 'previousEa',
    label: 'Previous EC - Previous EC'
  },
  {
    controlName: 'previousEADateSince',
    label: 'EC Thru - EC Thru'
  },
  {
    controlName: 'ecaudited',
    label: 'Audit History- EC Audited'
  },
  {
    controlName: 'auditor',
    label: 'Audit History - Auditor'
  },
  {
    controlName: 'auditDate',
    label: 'Audit History- Date'
  },
];

export let headerConfig = {
  'Line of Business (Does file contain?)': 'Client Basics',
  'Will Coordination of Benefits be used?': 'Client Basics',
  // Dependants section
  'Is population made up of Cardholders ONLY?': 'Client Basics',
  // Phone Cards section
  'Early phone lines or web?': 'Client Basics',
  // Performance Guarantee section
  'Performance Guarantee?': 'Client Basics',
  // PC section
  'Does client use Person Codes?': 'ID Configuration',
  // AltID section
  'Does Client use Alternate ID?': 'ID Configuration',
  // File Format section
  'What file format will be sent?': 'File Params',
  // Transmit section
  'How will client file be transmitted?': 'File Params',
  // File Type section
  'File Type': 'File Params',
  // History method section
  'History Method': 'File Params',
  // Load Report section
  'How are load reports to be delivered?': 'File Params',
  // File Frequency section
  'File Frequency': 'File Params',
  // Implied terms section
  'Will Implied Terms be used?': 'Terms',
  // Term by Absence section
  'Will Term by Absence be used': 'Terms',
  'Buffer Days': 'Terms',
  'TBA Date?': 'Terms',
  // Compare Process section
  'Compare Process': 'Terms',
// Printed Materials section
  'Do we print cards for entire population':'Printed Materials',
  // Misc section
  'Misc Info': 'Misc Info',
  // Group files section
  'Will there be Group Files?': 'Misc Info',
  // Client Code section
  'Is Client Product Code Used?': 'Misc Info',
  // Open enrollment section
  'Will client be sending an OE file?': 'Open Enrollment',

};


export const questionnaireFormConfig = [
  // Header info  section
  [
    {
      label: 'Header Information',
      inputType: InputType.HEADER,
      notes: 'headerInfoNotes',
    },

    {
      label: 'Client',
      id:1,
      controlName: 'client',
      inputType: InputType.DROPDOWN,
      options: ['', 'Yes', 'No'],
      colorCode: 'internal',
      heading: true,
    },
    {
      label: 'PLI',
      id:2,
      controlName: 'pli',
      inputType: InputType.TEXT,
      colorCode: 'integrated-feeds',
      heading: true
    },
    {
      label: 'Go Live Date',
      id:3,
      controlName: 'golivedate',
      inputType: InputType.DATE,
      colorCode: 'integrated-feeds',
      heading: true
    },
    {
      label: 'QL Client Code',
      id:4,
      controlName: 'qlclientcode',
      inputType: InputType.NUMBER,
      mdlName: 'idType',
      colorCode: 'integrated-feeds',
    },
    {
      label: 'Client Code',
      id:5,
      controlName: 'clientcode',
      inputType: InputType.NUMBER,
      mdlName: 'idType',
      colorCode: 'integrated-feeds',
    },
    {
      label: 'Restricted Access Client',
      id:6,
      controlName: 'restrictedaccessclient',
      inputType: InputType.DROPDOWN,
      options: ['', 'Yes', 'No'],
      colorCode: 'integrated-feeds',
      heading: true
    },
    {
      label: 'Carrier',
      id:7,
      controlName: 'carrier',
      inputType: InputType.DROPDOWN,
      options: ['', 'Yes', 'No'],
      colorCode: 'integrated-feeds',
      heading: true
    },
    {
      label: 'Eligibility Consultant',
      id:8,
      controlName: 'eligibilityconsultant',
      inputType: InputType.DROPDOWN,
      options: ['', 'Yes', 'No'],
      colorCode: 'integrated-feeds',
      heading: true
    },
    {
      label: 'Backup',
      id:9,
      controlName: 'backup',
      inputType: InputType.DROPDOWN,
      options: ['', 'Yes', 'No'],
      colorCode: 'integrated-feeds',
      heading: true
    },
    {
      label: 'Hold Date',
      id:10,
      controlName: 'holddate',
      inputType: InputType.DROPDOWN,
      options: ['', 'Yes', 'No'],
      colorCode: 'integrated-feeds',
      heading: true
    }
  ],
  // client basics primary section
  [
    {
      label: 'Client Basics',
      inputType: InputType.HEADER,
      // notes: 'clientBasicsNotes',
    },
    {
      label: 'Eligibility Type',
      id:11,
      controlName: 'eligType',
      inputType: InputType.DROPDOWN,
      // notes:'eligTypeNotes',
      notes: 'clientBasicsNotes',
      options: ['', 'Electronic', 'Manual', 'Dynamic', 'TERMED'],
      heading: true
    },
    {
      label: 'Multiple Carriers?',
      id:12,
      controlName: 'quesMultiCarrier',
      inputType: InputType.DROPDOWN,
      options: ['', 'Yes', 'No'],
    },
    {
      label: 'Are Integrated Accumulations Used?',
      id:13,
      controlName: 'qintegAccums',
      inputType: InputType.DROPDOWN,
      options: ['', 'Yes', 'No'],
      colorCode: 'internal',
      heading: true
    },
    {
      label: 'What is the shared ID?',
      id:14,
      controlName: 'intAccmShrdID',
      inputType: InputType.DROPDOWN,
      options: ['', 'Mbr ID (CH SSN)', 'Xwalk (CH SSN)', 'Mbr ID (non--SSN)', 'Xwalk (non SSN)'],
      colorCode: 'internal',
      colorCodeShow: "qintegAccums",
      noColorShowVal: "No"
    },
    {
      label: 'Adjustment Method',
      id:15,
      controlName: 'cdhadjMethod',
      inputType: InputType.DROPDOWN,
      options: ['', 'RealTime (Wellpoint)', 'Type 7', 'Type 7 (9M Layout)', 'Manual', 'Middle Tier'],
      colorCode: 'internal',
      colorCodeShow: "qintegAccums",
      noColorShowVal: "No"
    },
  ],
  // line of business section
  [
    {
      label: 'Line of Business (Does file contain?)',
      inputType: InputType.SUB_HEADER,
      notes: 'lOBNotes',
      subHeader: true,
    },
    {
      label: 'Medicare Medicaid Program (MMP)?',
      id:16,
      controlName: 'qmmpclient',
      inputType: InputType.DROPDOWN,
      options: ['', 'Yes', 'No'],
    },
    {
      label: 'Med D?',
      id:17,
      controlName: 'medD',
      inputType: InputType.DROPDOWN,
      options: ['', 'Yes', 'No'],
    },
    {
      label: 'Medicaid?',
      id:18,
      controlName: 'qclientMedicaid',
      inputType: InputType.DROPDOWN,
      options: ['', 'Yes', 'No'],
    },
    {
      label: 'Where will client send Medicaid ID?',
      id:19,
      controlName: 'medicaidIDPosition',
      inputType: InputType.DROPDOWN,
      options: ['', 'Member ID', 'Xwalk', 'CDD'],
    },
    {
      label: 'Subsidy/RDS?',
      id:20,
      controlName: 'subsidy',
      inputType: InputType.DROPDOWN,
      options: ['', 'Yes', 'No'],
    },
    {
      label: 'Med B?',
      id:21,
      controlName: 'qmedB',
      inputType: InputType.DROPDOWN,
      options: ['', 'Yes', 'No'],
    },
    {
      label: 'Commercial?',
      id:22,
      controlName: 'qcommercial',
      inputType: InputType.DROPDOWN,
      options: ['', 'Yes', 'No'],
    },
    {
      label: 'Population Type?',
      id:23,
      controlName: 'qpopType',
      inputType: InputType.FREEFORM_MULTISELECT,
      options: ['Active', 'Cobra', 'Retiree', 'Executive', 'ACA', 'Not Defined'],
      heading: true,
    },
  ],
  // Coordination of Bene(fits section
  [
    {
      label: 'Will Coordination of Benefits be used?',
      id:24,
      controlName: 'cobused',
      inputType: InputType.DROPDOWN,
      options: ['', 'Yes', 'No'],
      heading: true,
      notes: 'cOBNotes',
      subHeader: true,
    },
    {
      label: 'PCOB/NCOB',
      id:25,
      controlName: 'pcobncobused',
      inputType: InputType.DROPDOWN,
      options: ['', 'Yes', 'No'],
    },
    {
      label: 'STCOB',
      id:26,
      controlName: 'stcob',
      inputType: InputType.DROPDOWN,
      options: ['', 'Yes', 'No'],
    },
    {
      label: 'Reject Broken Links?',
      id:27,
      controlName: 'rejectBrokenLinks',
      inputType: InputType.DROPDOWN,
      options: ['', 'Yes', 'No'],
    },
    {
      label: 'COBC',
      id:28,
      controlName: 'cobcused',
      inputType: InputType.DROPDOWN,
      options: ['', 'Yes', 'No'],
    },
    {
      label: 'Link COBC to Elig?',
      id:29,
      controlName: 'linkCobcToElig',
      inputType: InputType.DROPDOWN,
      options: ['', 'Yes', 'No'],
      colorCode: 'internal',

    },
  ],
  // Dependants section
  [
    {
      label: 'Is population made up of Cardholders ONLY?',
      id:30,
      controlName: 'crdhldrsOnlyQ',
      inputType: InputType.DROPDOWN,
      options: ['', 'Yes', 'No'],
      notes: 'dependantsNotes',
      colorCode: 'integrated-feeds',
      heading: true,
      subHeader: true,

    },
    {
      label: 'How are Surviving Spouses handled?',
      id:31,
      controlName: 'qsurvSpsMethod',
      inputType: InputType.DROPDOWN,
      options: ['', 'Elevated', 'Not Elevated', 'Cobra', 'N/A', 'Other'],
      colorCode: 'integrated-feeds',
      heading: true,
    },
    {
      label: 'Are there stand alone deps (orphans)?',
      id:32,
      controlName: 'standAloneDeps',
      inputType: InputType.DROPDOWN,
      options: ['', 'Yes', 'No'],
      colorCode: 'integrated-feeds',
      heading: true,

    },
    {
      label: 'Mbrs/Deps in different CAG (split families)?',
      controlName: 'memDepDifGrp',
      id:33,
      inputType: InputType.DROPDOWN,
      options: ['', 'Yes', 'No'],
      colorCode: 'integrated-feeds',
      heading: true,

    }
  ],
  // Phone Cards section
  [
    {
      label: 'Early phone lines or web?',
      controlName: 'qphoneLines',
      id:34,
      inputType: InputType.DROPDOWN,
      options: ['', 'Y - Test Mbrs', 'Y - Full Elig', 'No'],
      notes: 'phoneCardsNotes',
      colorCode: 'integrated-feeds',
      heading: true,
      subHeader: true,

    },
    {
      label: 'If so, when?',
      id:35,
      controlName: 'earlyPhoneDte',
      inputType: InputType.DATE,
      colorCode: 'integrated-feeds',
    },
    {
      label: 'Does CVS Health print Cards/kits?',
      id:36,
      controlName: 'qidcards',
      inputType: InputType.DROPDOWN,
      options: ['', 'Yes', 'No'],
      colorCode: 'integrated-feeds',
      heading: true,
    },
  ],
  // Performance Guarantee section
  [
    {
      label: 'Performance Guarantee?',
      id:37,
      controlName: 'pgCriteria',
      inputType: InputType.MULTI_SELECT,
      options: ['N/A', 'Timeliness', 'Load Rpts', 'Accuracy', 'Other'],
      notes: 'pGNotes',
      colorCode: 'internal',
      heading: true,
      subHeader: true,

    },
  ],
  // Divider section
  [],
  // ID Config section
  [
    {
      label: 'ID Configuration',
      inputType: InputType.HEADER,
      notes: 'iDConfigNotes',
    },
    {
      label: 'Is Exact Match BPG logic used?',
      id:50,
      controlName: 'qexactMatchBPGUsed',
      inputType: InputType.DROPDOWN,
      options: ['', 'Yes', 'No'],
      colorCode: 'internal',
      heading: true
    },
    {
      label: 'Member ID Length (min/max)',
      id:51,
      colorCode: 'integrated-feeds',
      inputType: InputType.HORIZONTAL_FIELD_SET,
      heading: true,
      subFormGroup: [
        {
          controlName: 'memIDLength',
          inputType: InputType.TEXT,
        },
        {
          controlName: 'memIDMaxLength',
          inputType: InputType.TEXT,
        }
      ]

    },
    {
      label: 'Member ID type (with PC)',
      id:52,
      controlName: 'mbrIDTypeWPC',
      inputType: InputType.MDL_DROPDOWN,
      mdlName: 'idType',
      colorCode: 'integrated-feeds',
    },
    {
      label: 'Member ID type (w/o PC)',
      id:53,
      controlName: 'mbrIDTypeWOPC',
      inputType: InputType.MDL_DROPDOWN,
      mdlName: 'idType',
      colorCode: 'integrated-feeds',
    },

    {
      label: 'Family ID Length (min/max)',
      id:215,
      colorCode: 'integrated-feeds',
      inputType: InputType.HORIZONTAL_FIELD_SET,
      heading: true,
      subFormGroup: [
        {
          controlName: 'famIDMinLength',
          inputType: InputType.NUMBER,
          acceptNumberOnly: true,
        },
        {
          controlName: 'famIDMaxLength',
          inputType: InputType.NUMBER,
          acceptNumberOnly: true,

        }
      ]

    },
    {
      label: 'Are ID\'s unique within Fam other than PC?',
      id:54,
      controlName: 'uniqueIDsInFam',
      inputType: InputType.DROPDOWN,
      options: ['', 'Yes', 'No'],
      colorCode: 'integrated-feeds',
      heading: true
    },
    {
      label: 'Is Fam ID equal to core of Mbr ID?',
      id:55,
      controlName: 'famIDMatchCoreMbrIDQ',
      inputType: InputType.DROPDOWN,
      options: ['', 'Yes', 'No'],
      colorCode: 'integrated-feeds',
      heading: true
    },
    {
      label: 'Autogen Multi Birth Indicator?',
      id: 216,
      controlName: 'autogenMultiBirthInd',
      inputType: InputType.DROPDOWN,
      options: ['', 'Yes', 'No'],
      heading: true
    },
  ],
  // PC section
  [
    {
      label: 'Does client use Person Codes?',
      id:56,
      controlName: 'personCodes',
      inputType: InputType.DROPDOWN,
      options: ['', 'Yes', 'No'],
      notes: 'pcNotes',
      colorCode: 'integrated-feeds',
      heading: true,
      subHeader: true,
    },
    {
      label: 'PC Reuse Validation',
      id:220,
      controlName: 'pCReUse',
      inputType: InputType.DROPDOWN,
      options: ['Yes', 'No'],
    },
    {
      label: 'Do we auto-gen?',
      id:57,
      controlName: 'pcautoGen',
      inputType: InputType.DROPDOWN,
      options: ['', 'Yes', 'No'],
    },
    {
      label: 'Autogen Program?',
      id:58,
      controlName: 'autogenprgm',
      inputType: InputType.MDL_DROPDOWN,
      mdlName: 'pcAutogenProgram',
    },
    {
      label: 'Auto Gen Prgm Match Level',
      id:219,
      controlName: 'autoGenPrgmMatchLevel',
      options:[{label: "LN + FN + MI + DOB + RC", bold:false},
        {label: "LN + 4FN + MI + DOB + RC", bold:false},
        {label: "LN + FN + DOB + RC", bold:false}
        ,{label: "FN + MI + DOB + RC", bold:false},
        {label: "FN + DOB + RC", bold:true},
        {label: "4FN + DOB + RC", bold:true},
        {label: "FN + RC", bold:true},
        {label: "SSN + RC", bold:false},
        {label: "DOB + RC", bold:true}]
    },
    {
      label: 'Ignore PC on file?',
      id:59,
      controlName: 'ignorePConFile',
      inputType: InputType.DROPDOWN,
      options: ['', 'Yes', 'No'],
    },
    {
      label: 'Append PC to Alt ID?',
      id:60,
      controlName: 'qappndPCtoXwalk',
      inputType: InputType.DROPDOWN,
      options: ['', 'Yes', 'No'],
    },
    {
      label: 'Carrier list for PC autogen',
      id:61,
      controlName: 'qpcautogenCarList',
      inputType: InputType.TEXT,
    },
    {
      label: 'PC Assignment Method',
      id:62,
      controlName: 'pcassignMethod',
      inputType: InputType.DROPDOWN,
      options: ['', 'N/A', '00 CH', '01 CH', 'Custom'],
    },
    {
      label: 'Is PC appended to ID?',
      id:63,
      controlName: 'pconID',
      inputType: InputType.DROPDOWN,
      options: ['', 'Yes', 'No'],
      colorCode: 'integrated-feeds',
    },
    {
      label: 'PC Length',
      id:64,
      controlName: 'pclength',
      inputType: InputType.DROPDOWN,
      options: ['', '0', '1', '2', '3'],
      colorCode: 'integrated-feeds',
    }
  ],
  // AltID section
  [
    {
      label: 'Does Client use Alternate ID?',
      id:65,
      controlName: 'xwalkUsed',
      inputType: InputType.DROPDOWN,
      options: ['', 'Yes', 'No'],
      notes: 'altIdNotes',
      colorCode: 'integrated-feeds',
      heading: true,
      subHeader: true,
    },
    {
      label: 'Alternate ID type (with PC)',
      id:66,
      controlName: 'xwalkIDTypeWPC',
      inputType: InputType.MDL_DROPDOWN,
      mdlName: 'idType',
      colorCode: 'integrated-feeds',
    },
    {
      label: 'Alternate ID type (w/o PC)',
      id:67,
      controlName: 'xwalkIDTypeWOPC',
      inputType: InputType.MDL_DROPDOWN,
      mdlName: 'idType',
      colorCode: 'integrated-feeds',
    },
    {
      label: 'Do we autogen?',
      id:68,
      controlName: 'xwalkAutoGen',
      inputType: InputType.DROPDOWN,
      options: ['', 'Yes', 'No'],
      heading: true,
    },
    {
      label: 'Autogen base length',
      id:69,
      controlName: 'autoGenBaseLngth',
      inputType: InputType.TEXT,
    },
    {
      label: 'Autogen Prefix',
      id:70,
      controlName: 'xwalkAutogenPrefix',
      inputType: InputType.TEXT,
    },
    {
      label: 'Override with Inbound',
      id:38,
      controlName: 'xwalkAGOverride',
      inputType: InputType.DROPDOWN,
      options: ['', 'Yes', 'No'],
    },
    {
      label: 'What ID goes on EOB\'s?',
      id: 39,
      controlName: 'eobid',
      inputType: InputType.DROPDOWN,
      options: ['', 'Member ID', 'Crosswalk', 'Both'],
    },
    {
      label: 'What ID goes on CET\'s?',
      id: 40,
      controlName: 'cetid',
      inputType: InputType.DROPDOWN,
      options: ['', 'Member ID', 'Crosswalk', 'Both'],
    },
    {
      label: 'What ID goes on Reports?',
      id: 41,
      controlName: 'reportID',
      inputType: InputType.DROPDOWN,
      options: ['', 'Member ID', 'Crosswalk', 'Both'],
    },
    {
      label: 'What ID goes on MAB dtl?',
      id: 42,
      controlName: 'mabdtlID',
      inputType: InputType.DROPDOWN,
      options: ['', 'Member ID', 'Crosswalk', 'Both'],
    },
    {
      label: 'What ID goes on Cards?',
      id: 43,
      controlName: 'cardID',
      inputType: InputType.DROPDOWN,
      options: ['', 'Member ID', 'Crosswalk', 'Both'],
      colorCode: 'integrated-feeds',
    },
    {
      label: 'What ID goes on Mail Order Forms?',
      id: 44,
      controlName: 'mailOrderFormID',
      inputType: InputType.DROPDOWN,
      options: ['', 'Member ID', 'Crosswalk', 'Both'],
      colorCode: 'integrated-feeds',
    },
    {
      label: 'Send X-Walk for FSA?',
      id: 45,
      controlName: 'xwalkForFSA',
      inputType: InputType.DROPDOWN,
      options: ['', 'Yes', 'No'],
    },
    {
      label: 'Alt ID Length (min/max)',
      id: 46,
      inputType: InputType.HORIZONTAL_FIELD_SET,
      colorCode: 'integrated-feeds',
      subFormGroup: [
        {
          controlName: 'xwalkIDLength',
          inputType: InputType.TEXT,
        },
        {
          controlName: 'xwalkIDMaxLength',
          inputType: InputType.TEXT,
        }
      ]

    },
    {
      label: 'Will the 3rd ID Field be used?',
      id: 47,
      controlName: 'q3rdIdFieldUsed',
      inputType: InputType.DROPDOWN,
      options: ['', 'Yes', 'No'],
      colorCode: 'integrated-feeds',
    },
    {
      label: '3rd ID Type',
      id: 48,
      controlName: 'q3rdIdFieldType',
      inputType: InputType.MDL_DROPDOWN,
      mdlName: 'idType',
      colorCode: 'integrated-feeds',

    },
    {
      label: 'Does this ID need to be in QL?',
      id: 49,
      controlName: 'q3rdIDinQL',
      inputType: InputType.DROPDOWN,
      options: ['', 'Yes', 'No'],
      colorCode: 'integrated-feeds',
    }
  ],
  // Divider section
  [],
  // File Params section
  [
    {
      label: 'File Params',
      inputType: InputType.HEADER,
      notes: 'fileParamNotes',
    },
    {
      label: 'SSOE Migration',
      id:71,
      controlName: 'consolidateProfileLogic',
      inputType: InputType.DROPDOWN,
      options: ['', 'Yes', 'No'],
      heading: true
    },
    {
      label: 'Group File Prefix',
      id: 205,
      controlName: 'groupFilePrefix',
      inputType: InputType.TEXT,
    },
    {
      label: 'Member File Prefix',
      id: 206,
      controlName: 'memberFilePrefix',
      inputType: InputType.TEXT,
    },
    {
      label: 'Vendor',
      id:72,
      controlName: 'vendor',
      inputType: InputType.DROPDOWN,
      options: ['', 'Yes', 'No'],
      heading: true,
    },
    {
      label: 'Is there more than one file feed for client?',
      id:73,
      controlName: 'multFileFeeds',
      inputType: InputType.DROPDOWN,
      options: ['', 'Yes', 'No'],
      heading: true,
    }
  ],
  // File Format section
  [
    {
      label: 'What file format will be sent?',
      id:74,
      controlName: 'format',
      inputType: InputType.MDL_DROPDOWN,
      mdlName: 'fileFormat',
      notes: 'fileFormatNotes',
      heading: true,
      subHeader: true,
    },
    {
      label: 'Reason for non-standard format',
      id:75,
      controlName: 'cstmFmtRsnCd',
      inputType: InputType.DROPDOWN,
      options: ['', 'Sales/Relationship', 'Client Resources', 'Format used elsewhere', 'Other'],
    },
    {
      label: 'Will B2B be used?',
      id:76,
      controlName: 'gisused',
      inputType: InputType.DROPDOWN,
      options: ['', 'Yes', 'No'],
    },
    {
      label: 'Will Pre-Preload be used?',
      id:77,
      controlName: 'prePreLoad',
      inputType: InputType.DROPDOWN,
      options: ['', 'Yes', 'No'],
    },
    {
      label: 'Pre-Preload Program',
      id:78,
      controlName: 'discPrePreProgram',
      inputType: InputType.TEXT,
    },
    {
      label: 'Mapping to format?',
      id:79,
      controlName: 'fileParamMapToo',
      inputType: InputType.MDL_DROPDOWN,
      mdlName: 'fileFormat',
    }
  ],
  // Transmit section
  [
    {
      label: 'How will client file be transmitted?',
      id: 80,
      controlName: 'fileTransmitionMethod',
      inputType: InputType.DROPDOWN,
      options: ['', 'Secure FTP', 'Sterling Gateway (GIS)', 'EZ Enrollment', 'Client Care Access', 'Sweep', 'Other'],
      notes: 'transmitNotes',
      heading: true,
      subHeader: true,
    },
    {
      label: 'FTP ID',
      id: 81,
      controlName: 'ftpid',
      inputType: InputType.TEXT,
    },
    {
      label: 'File Routing',
      id: 82,
      controlName: 'qfileRouting',
      inputType: InputType.MDL_DROPDOWN,
      mdlName: 'fileRouting',
    }
  ],
  // File Type section
  [
    {
      label: 'File Type',
      id: 83,
      controlName: 'fileType',
      inputType: InputType.DROPDOWN,
      options: ['', 'Transactional', 'Full', 'Both'],
      notes: 'fileTypeNotes',
      subHeader: true,
    }
  ],
  // History method section
  [
    {
      label: 'History Method',
      id: 84,
      controlName: 'qhistMethod',
      inputType: InputType.DROPDOWN,
      options: ['', 'FML', 'ATN', 'Type 4', 'Plan Controlled'],
      notes: 'historyMethodNotes',
      heading: true,
      subHeader: true,
    },
    {
      label: 'Will Type 4 be sent by client',
      id: 85,
      controlName: 'moveHstFile',
      inputType: InputType.DROPDOWN,
      options: ['', 'Yes', 'No'],
    },
    {
      label: 'Will Type 5 (privacy/email) be sent by client?',
      id: 86,
      controlName: 'type5UsedQ',
      inputType: InputType.DROPDOWN,
      options: ['', 'Yes', 'No'],
      heading: true,
    },
    {
      label: 'Type \'E-Email\'?',
      id: 87,
      controlName: 'type5EmailUsed',
      inputType: InputType.DROPDOWN,
      options: ['', 'Yes', 'No'],
    },
    {
      label: 'Type \'M-Member Address\'?',
      id: 88,
      controlName: 'type5MbrAddUsed',
      inputType: InputType.DROPDOWN,
      options: ['', 'Yes', 'No'],
    },
    {
      label: 'Type \'P-Privacy\'?',
      id: 89,
      controlName: 'type5PrvcyUsed',
      inputType: InputType.DROPDOWN,
      options: ['', 'Yes', 'No'],
    },
    {
      label: 'Type 5 Validation?',
      id: 90,
      controlName: 'type5Validation',
      inputType: InputType.DROPDOWN,
      options: ['', 'Yes', 'No'],
    },
    {
      label: 'Type 5 Retention Days?',
      id: 91,
      controlName: 'type5RetentionDays',
      inputType: InputType.NUMBER,
      options: ['', 'Yes', 'No'],
    },
    {
      label: 'Privacy Moves Level',
      id: 92,
      controlName: 'privMoveLvlQ',
      inputType: InputType.DROPDOWN,
      options: ['', 'No', 'C', 'A', 'G'],
    },
    {
      label: 'Is Batch Override Protection Used?',
      id: 93,
      controlName: 'batchOvrProtect',
      inputType: InputType.DROPDOWN,
      options: ['', 'Yes', 'No'],
      colorCode: 'internal',
      heading: true,
    }
  ],
  // Load Report section
  [
    {
      label: 'How are load reports to be delivered?',
      id: 94,
      controlName: 'loadRptsDelMethodQ',
      inputType: InputType.MULTI_SELECT,
      options: ['Email (fixed width)', 'Email (delimited)', 'NDM/FTP (fixed width)', 'NDM/FTP (delimited)',
        'Response File (fixed width)', 'Response File (delimited)'],
      notes: 'loadReportNotes',
      heading: true,
      subHeader: true,
    },
    {
      label: 'Report NDM/FTP Profile',
      id: 95,
      controlName: 'qloadRptNDMFTPProf1',
      inputType: InputType.TEXT,
    }
  ],
  // File Frequency section
  [
    {
      label: 'File Frequency',
      id: 96,
      controlName: 'fileFrequency',
      inputType: InputType.DROPDOWN,
      options: ['', 'Daily', 'Weekly', 'Monthly', 'Other', 'Bypass'],
      notes: 'fileFrequencyNotes',
      heading: true,
      subHeader: true,
    },
    {
      label: 'Exclude Holidays from Schedule?',
      id: 97,
      controlName: 'scheduleHolidays',
      inputType: InputType.DROPDOWN,
      options: ['', 'Yes', 'No'],
    },
    {
      label: 'Is there a variance?',
      id: 98,
      controlName: 'scheduleVariance',
      inputType: InputType.DROPDOWN,
      options: ['', 'Daily', 'Hourly', 'No'],
    },
  ],
  // Divider section
  [],
  // Vendor section
  [
    {
      label: 'Vendor',
      inputType: InputType.HEADER,
      notes: 'vendorNotesFeedback'
    },
    {
      label: 'Who is the Vendor?',
      id: 99,
      controlName: 'vendorLookup',
      inputType: InputType.MDL_DROPDOWN,
      mdlName: 'vendor',
      heading: true
    },
  ],
  // Divider section
  [],
  // Terms section
  [
    {
      label: 'Terms',
      inputType: InputType.HEADER,
      notes: 'termsNotes'
    },
    {
      label: 'Will client send term dates for all mbrs?',
      id: 100,
      controlName: 'participantTermDates',
      inputType: InputType.DROPDOWN,
      options: ['', 'Yes', 'No'],
      heading: true
    },
    {
      label: 'Is the term date a \'to\' date?',
      id: 101,
      controlName: 'termDateThru',
      inputType: InputType.DROPDOWN,
      options: ['', 'Yes', 'No'],
    },
    {
      label: 'Will dependent terms be sent on file?',
      id: 102,
      controlName: 'depsOnFile',
      inputType: InputType.DROPDOWN,
      options: ['', 'Yes', 'No'],
      heading: true
    },
    {
      label: 'Will dependent implied terms be used?',
      id: 103,
      controlName: 'depImpTermQues',
      inputType: InputType.DROPDOWN,
      options: ['', 'Yes', 'No'],
    },
    {
      label: 'Dependent Implied Terms Method',
      id: 104,
      controlName: 'depImpTrmMthd',
      inputType: InputType.DROPDOWN,
      options: ['', 'N', 'A', 'B', 'D'],
    }
  ],
  // Implied terms section
  [
    {
      label: 'Will Implied Terms be used?',
      id: 105,
      controlName: 'impliedTerms',
      inputType: InputType.DROPDOWN,
      options: ['', 'Yes', 'No'],
      notes: 'impliedTermsNotes',
      heading: true,
      subHeader: true,
    },
    {
      label: 'At what level?',
      id: 106,
      controlName: 'impTermLevel',
      inputType: InputType.DROPDOWN,
      options: ['', 'Carrier', 'Account', 'Group', 'X'],

    },
    {
      label: 'Move History?',
      id: 107,
      controlName: 'moveHistQues',
      inputType: InputType.DROPDOWN,
      options: ['', 'Yes', 'No'],

    },
    {
      label: 'Move Accums?',
      id: 108,
      controlName: 'moveAccumsQues',
      inputType: InputType.DROPDOWN,
      options: ['', 'Yes', 'No'],

    },
    {
      label: 'Move Prior Auths?',
      id: 109,
      controlName: 'movePriorAuthsQues',
      inputType: InputType.DROPDOWN,
      options: ['', 'Yes', 'No'],

    },
    {
      label: 'Include Termed Records?',
      id: 110,
      controlName: 'impTrmInclTermRecs',
      inputType: InputType.DROPDOWN,
      options: ['', 'Yes', 'No'],

    },
    {
      label: 'Move claims prior to effective date?',
      id: 111,
      controlName: 'impTrmsMoveALLClaims',
      inputType: InputType.DROPDOWN,
      options: ['', 'Yes', 'No'],

    }
  ],
  // Term by Absence section
  [
    {
      label: 'Will Term by Absence be used',
      controlName: 'tmr',
      id: 112,
      inputType: InputType.DROPDOWN,
      options: ['', 'Yes', 'No'],
      notes: 'termByAbsenceNotes',
      heading: true,
      subHeader: true,
    },
    {
      label: 'Ignore Future Terms (< open ended)?',
      controlName: 'tmrignFtrTrm',
      id: 113,
      inputType: InputType.DROPDOWN,
      options: ['', 'Yes', 'No'],

    },
    {
      label: 'Include Future Effectives?',
      controlName: 'tmrinclFtrEff',
      id: 114,
      inputType: InputType.DROPDOWN,
      options: ['', 'Yes', 'No'],

    },
    {
      label: 'TBA Date?',
      controlName: 'tmrdate',
      id: 115,
      inputType: InputType.MDL_DROPDOWN,
      mdlName: 'tbaDate',
    },
    {
      label: 'Buffer Days',
      controlName: 'bufferDays',
      id: 116,
      inputType: InputType.DROPDOWN,
      mdlName: 'bufferDays',
      options: [null, 1,2,3,4,5,6,7,8,9],
    },
    {
      label: 'TBA Lookahead',
      controlName: 'tbaLookahead',
      id: 117,
      inputType: InputType.DROPDOWN,
      options: [null, '1D','2D','3D','4D','5D','6D','7D','10D','14D','15D','21D','1M','2M','3M','4M','5M','6M','9M','10M','12M','18M','24M','30M','36M','48M','60M','72M','84M','96M','108M','120M'],
    },
    { // dropdown and dropdown
      label: 'Will TBA Variance be used?',
      id: 118,
      controlName: 'tbaVarianceDays',
      inputType: InputType.DROPDOWN,
      options: [null, 'ADD', 'DEDUCT']
    },
    { // dropdown and dropdown
      label: 'Variance Days',
      id: 214,
      controlName: 'tbaVarianceDaysCount',
      inputType: InputType.DROPDOWN,
      options: [null].concat(Array.from({length: 30}, (_, i) => i + 1).map(i => i.toString())),
    },
    {
      label: 'Range or List used (incl/excl)',
      id: 119,
      controlName: 'tmrrangeListType',
      inputType: InputType.DROPDOWN,
      options: ['', 'N/A', 'Acct Range (incl)', 'Grp Range (incl)', 'Account List (incl)', 'Account List (excl)',
        'Group List (incl)', 'Group List (excl)', 'Dynamic (incl)', 'Acct/Grp List (incl)',
        'Acct/Grp List (excl)', 'Acct/Grp List (incl/excl)', 'Acct/Grp List (excl/incl)'],
    subHeader: true,
    },
    {
      label: 'Range From',
      controlName: 'tmrrangeFrom',
      id: 217,
      inputType: InputType.TEXT,
    },
    {
      label: 'Range To',
      controlName: 'tmrrangeTo',
      id: 218,
      inputType: InputType.TEXT,
    },
    {
      label: 'Account List',
      controlName: 'incExcListName',
      id: 120,
      inputType: InputType.TEXT,
    },
    {
      label: 'Group List',
      controlName: 'grpInclExclList',
      id: 121,
      inputType: InputType.TEXT,
    },
    {
      label: 'Is manual entry TBA protection used?',
      controlName: 'manTMRProtect',
      id: 122,
      inputType: InputType.DROPDOWN,
      options: ['', 'Yes', 'No'],
      colorCode: 'internal',

    },
  ],
  // Compare Process section
  [
    {
      label: 'Compare Process',
      controlName: 'compareProcess',
      id: 123,
      inputType: InputType.DROPDOWN,
      options: ['', 'Compare', 'Recon', 'Other', 'TMR'],
      notes: 'compareProcessNotes',
      heading: true,
      subHeader: true,
    },
    {
      label: 'ROBOT Name',
      controlName: 'compareRobotName',
      id: 124,
      inputType: InputType.TEXT,

    },
    {
      label: 'Email/NDM Script',
      controlName: 'compareNDMScript',
      id: 125,
      inputType: InputType.TEXT,

    },
    {
      label: 'Compare Frequency',
      controlName: 'compareFrequency',
      id: 126,
      inputType: InputType.DROPDOWN,
      options: ['', 'Monthly', 'Quarterly', '2x Year', 'Annually', 'Weekly', 'Bi-Weekly', 'Daily', 'Ongoing File',
        'On Demand'],

    },
  ],
  // Divider section
  [],
  // Threshold section
  [
    {
      label: 'Threshold',
      inputType: InputType.HEADER,
      notes: 'thrshldNotes',
      heading: true
    },
    {
      label: 'Term by Absence Threshold (count)',
      controlName: 'questionnaireTermThreshold',
      id: 127,
      inputType: InputType.TEXT,
      heading: true
    },
    {
      label: 'Term by Absence Threshold (percentage)',
      controlName: 'termByAbsenceThshldPercent',
      id: 128,
      inputType: InputType.TEXT,
      heading: true
    },
    {
      label: 'Error Threshold (percentage)',
      controlName: 'flerrorThreshold',
      id: 129,
      inputType: InputType.NUMBER,
      heading: true,
      acceptNumberOnly: true,
    },
    {
      label: 'Error Threshold (count)',
      controlName: 'flerrorThresholdCnt',
      id:207,
      inputType: InputType.NUMBER,
      heading: true,
      acceptNumberOnly: true,
    },
    {
      label: 'Term Threshold (Incl Moves)',
      controlName: 'termThreshold',
      id: 130,
      inputType: InputType.TEXT,
      heading: true
    },
    {
      label: 'Term Threshold Incl Moves Count',
      controlName: 'termThresholdInclMovesActCount',
      id: 131,
      inputType: InputType.TEXT,
      heading: true
    },
    {
      label: 'Term Threshold Excl Moves Count',
      controlName: 'termThresholdExclMovesActCount',
      id: 132,
      inputType: InputType.TEXT,
      heading: true
    },
    {
      label: 'Term Threshold All Act Count',
      controlName: 'termThresholdAllActCount',
      id: 133,
      inputType: InputType.TEXT,
      heading: true
    },
    {
      label: 'Term Threshold (Excl Moves)',
      controlName: 'termThreshExcMoves',
      id: 134,
      inputType: InputType.TEXT,
      heading: true
    },
    {
      label: 'Term Threshold (Against All Actives)',
      controlName: 'termsVsAllActives',
      id: 135,
      inputType: InputType.TEXT,
      heading: true
    },
    {
      label: 'Include Future Terms (< open ended)',
      controlName: 'thrshldInclFtrTrms',
      id: 136,
      inputType: InputType.DROPDOWN,
      options: ['', 'Yes', 'No'],
    },
    {
      label: 'Inactivate Count Threshold',
      controlName: 'inactivateCountThreshold',
      id: 137,
      inputType: InputType.NUMBER,
      heading: true
    },
    {
      label: 'Inactivate Pct Threshold',
      controlName: 'inactivatePctThreshold',
      id: 138,
      inputType: InputType.NUMBER,
      heading: true
    },
    {
      label: 'MLX Chg Count Threshold',
      controlName: 'mLXChgCountThreshold',
      id: 139,
      inputType: InputType.NUMBER,
      heading: true
    },
    {
      label: 'MLX Chg Pct Threshold',
      controlName: 'mLXChgPctThreshold',
      id: 140,
      inputType: InputType.NUMBER,
      heading: true
    },
    {
      label: 'Term Move Count Threshold',
      controlName: 'termMoveCountThreshold',
      id: 141,
      inputType: InputType.NUMBER,
      heading: true
    },
    {
      label: 'Term Move Pct Threshold',
      controlName: 'termMovePctThreshold',
      id: 142,
      inputType: InputType.NUMBER,
      heading: true
    },
    {
      label: 'Family ID Threshold',
      controlName: 'famIDThrshld',
      id: 143,
      inputType: InputType.TEXT,
      heading: true
    },
    {
      label: 'Demographic Match Level',
      controlName: 'matchLevel',
      id: 144,
      inputType: InputType.DROPDOWN,
      options: ['', 'Not Used', 'Carrier', 'Account', 'Group', 'Member'],
      heading: true
    },
    {
      label: 'New Population Threshold (count)',
      // controlName: 'newPopThrshldUOM',
      controlName: 'newPopThrshldCnt',
      id: 145,
      inputType: InputType.NUMBER,
      acceptNumberOnly: true,
      heading: true
    },
    {
      label: 'New Population Threshold (percentage)',
      controlName: 'newPopThrshld',
      id: 208,
      inputType: InputType.NUMBER,
      acceptNumberOnly: true,
      heading: true
    },
    {
      label: 'Change in Mbr ID Threshold (count)',
      // controlName: 'chngMbrIDUOM',
      controlName: 'chngMbrIDThrshldCnt',
      id: 146,
      inputType: InputType.NUMBER,
      acceptNumberOnly: true,
      heading: true
    },
    {
      label: 'Change in Mbr ID Threshold (percentage)',
      controlName: 'chngMbrIDThrshld',
      id: 209,
      inputType: InputType.NUMBER,
      acceptNumberOnly: true,
      heading: true
    },
    // { // dropdown and textbox
    //   label: 'Change in Member ID',
    //   id: 146,
    //   inputType: InputType.HORIZONTAL_FIELD_SET,
    //   heading: true,
    //   subFormGroup: [
    //     {
    //       controlName: 'chngMbrIDUOM',
    //       inputType: InputType.DROPDOWN,
    //       options: ['', 'Count', 'Percentage'],
    //     },
    //     {
    //       controlName: 'chngMbrIDThrshld',
    //       inputType: InputType.TEXT,
    //     }
    //   ]
    // },
    // { // 2 dropdowns and textbox
    //   label: 'Last Name Change',
    //   id: 147,
    //   inputType: InputType.HORIZONTAL_FIELD_SET,
    //   heading: true,
    //   subFormGroup: [
    //     {
    //       controlName: 'chngLNThrshldUse',
    //       inputType: InputType.DROPDOWN,
    //       options: ['', 'Yes', 'No', 'Load but report'],
    //     },
    //     {
    //       controlName: 'chngLNUOM',
    //       inputType: InputType.DROPDOWN,
    //       options: ['', 'Count', 'Percentage'],
    //     },
    //     {
    //       controlName: 'chngLNThrshld',
    //       inputType: InputType.TEXT,
    //     }
    //   ],
    // },
    // { // 2 dropdowns and textbox
    //   label: 'First Name Change',
    //   inputType: InputType.HORIZONTAL_FIELD_SET,
    //   id: 148,
    //   heading: true,
    //   subFormGroup: [
    //     {
    //       controlName: 'chngFNThrshldUse',
    //       inputType: InputType.DROPDOWN,
    //       options: ['', 'Yes', 'No', 'Load but report'],
    //     },
    //     {
    //       controlName: 'chngFNUOM',
    //       inputType: InputType.DROPDOWN,
    //       options: ['', 'Count', 'Percentage'],
    //     },
    //     {
    //       controlName: 'chngFNThrshld',
    //       inputType: InputType.TEXT,
    //     }
    //   ],
    // },
    // {
    //   label: 'DOB Change',
    //   inputType: InputType.HORIZONTAL_FIELD_SET,
    //   id: 149,
    //   heading: true,
    //   subFormGroup: [
    //     {
    //       controlName: 'chngDOBThrshldUse',
    //       inputType: InputType.DROPDOWN,
    //       options: ['', 'Yes', 'No', 'Load but report'],
    //     },
    //     {
    //       controlName: 'chngDOBUOM',
    //       inputType: InputType.DROPDOWN,
    //       options: ['', 'Count', 'Percentage'],
    //     },
    //     {
    //       controlName: 'chngDOBThrshld',
    //       inputType: InputType.TEXT,
    //     }
    //   ],
    // },

    {
      label: 'Change in FN Threshold (count)',
      inputType: InputType.NUMBER,
      controlName: 'chngFNThrshldUse',
      id: 148,
      heading: true,
      acceptNumberOnly: true,
    },
    {
      label: 'Change in FN Threshold (percentage)',
      inputType: InputType.NUMBER,
      // controlName: 'chngFNUOM',
      controlName:'chngFNThrshld',
      id: 211,
      heading: true,
      acceptNumberOnly: true,
    },

    {
      label: 'Change in LN Threshold (count)',
      inputType: InputType.NUMBER,
      controlName: 'chngLNThrshldUse',
      id: 147,
      heading: true,
      acceptNumberOnly: true,
    },
    {
      label: 'Change in LN Threshold (percentage)',
      inputType: InputType.NUMBER,
      // controlName: 'chngLNUOM',
      controlName: 'chngLNThrshld',
      id: 210,
      heading: true,
      acceptNumberOnly: true,
    },

    {
      label: 'Change in DOB Threshold (count)',
      inputType: InputType.NUMBER,
      controlName: 'chngDOBThrshldUse',
      id: 149,
      heading: true,
      acceptNumberOnly: true,
    },
    {
      label: 'Change in DOB Threshold (percentage)',
      inputType: InputType.NUMBER,
      // controlName: 'chngDOBUOM',
      controlName: 'chngDOBThrshld',
      id: 212,
      heading: true,
      acceptNumberOnly: true,
    },
    {
      label: 'Change in REL Threshold (count)',
      inputType: InputType.NUMBER,
      controlName: 'chngRelThrshldUse',
      id: 150,
      heading: true,
      acceptNumberOnly: true,
    },
    {
      label: 'Change in REL Threshold (percentage)',
      inputType: InputType.NUMBER,
      // controlName: 'chngRelUOM',
      controlName: 'chngRelThrshld',
      id: 213,
      heading: true,
      acceptNumberOnly: true,
    },

    // {
    //   label: 'Relationship Change',
    //   inputType: InputType.HORIZONTAL_FIELD_SET,
    //   id: 150,
    //   heading: true,
    //   subFormGroup: [
    //     {
    //       controlName: 'chngRelThrshldUse',
    //       inputType: InputType.DROPDOWN,
    //       options: ['', 'Yes', 'No', 'Load but report'],
    //     },
    //     {
    //       controlName: 'chngRelUOM',
    //       inputType: InputType.DROPDOWN,
    //       options: ['', 'Count', 'Percentage'],
    //     },
    //     {
    //       controlName: 'chngRelThrshld',
    //       inputType: InputType.TEXT,
    //     }
    //   ],
    // },
    {
      label: 'ID Card Threshold',
      id: 151,
      controlName: 'idcardThrshld',
      inputType: InputType.TEXT,
      heading: true,
    },
  ],
  // Divider section
  [
    {
      label: 'Printed Materials',
      inputType: InputType.HEADER,
    },
  ],
  // Printed Materials section
  [
    {
      label: 'Do we print cards for entire population',
      controlName: 'cardsForEntPopulation',
      id: 152,
      inputType: InputType.DROPDOWN,
      options: ['', 'Yes', 'No'],
      notes: 'cardQuestNotes',
      heading: true,
      subHeader: true,
    },
    {
      label: 'What ID goes on the card (regardless of who prints)?',
      controlName: 'idonCard',
      id: 153,
      inputType: InputType.DROPDOWN,
      options: ['', 'Member', 'X-Walk'],
      colorCode: 'integrated-feeds',
      heading: true,

    },
    {
      label: 'What are we printing?',
      controlName: 'kitCrdBklt',
      id: 154,
      inputType: InputType.DROPDOWN,
      options: ['', 'ID Cards', 'Kits', 'Kits & Cards'],
      heading: true,

    },
    {
      label: 'SSN Validation Used?',
      controlName: 'ssnidcrdValQ',
      id: 155,
      inputType: InputType.DROPDOWN,
      options: ['', 'Yes', 'No'],
      heading: true,

    },
    {
      label: 'ID Card Report Output Que',
      controlName: 'idcardOutque',
      id: 156,
      inputType: InputType.TEXT,
      heading: true,

    },
    {
      label: 'Who do they want printed on the card?',
      controlName: 'field178',
      id: 157,
      inputType: InputType.DROPDOWN,
      options: ['', '1 - CH', '1-4 All', '1-4 Ind'],
      heading: true,

    },
    {
      label: 'Regardless of CAG?',
      controlName: 'prntFamOnCard',
      id: 158,
      inputType: InputType.DROPDOWN,
      options: ['', 'Yes', 'No'],
      heading: true,

    },
    {
      label: 'Do we generate cards for moved members?',
      controlName: 'genCardForMovedMbr',
      id: 159,
      inputType: InputType.DROPDOWN,
      options: ['', 'Yes', 'No'],
      heading: true,

    },
    {
      label: 'Suppression Level?',
      controlName: 'cardSupLevel',
      id: 160,
      inputType: InputType.DROPDOWN,
      options: ['', 'Carrier', 'Account', 'No Suppression'],
      heading: true,

    },
    {
      label: 'What should trigger a card?',
      controlName: 'qcardTriggers',
      id: 161,
      inputType: InputType.MULTI_SELECT,
      options: ['Add Mbr', 'Trm FMbr', 'React Trm', 'CF Chng', 'FRM Chng', 'LNM Chng', 'PCP Chng', 'DOB Chng',
        'Thru Chng', 'CN Chng', 'CQ Chng', 'CP Chng', 'FamT Chng'],
      heading: true,

    },
  ],
  // Divider section
  [],
  // Misc section
  [
    {
      label: 'Misc Info',
      inputType: InputType.HEADER,
      notes: 'miscQuestClientNotes',
    },
    {
      label: 'Multi-Birth Indicator',
      controlName: 'multiBirth',
      id: 162,
      inputType: InputType.DROPDOWN,
      options: ['', 'Sent on File', 'Autogen'],
      heading: true,
    },
    {
      label: 'Is Auto-CAG used?',
      controlName: 'grpOTForAutoCAG',
      id: 163,
      inputType: InputType.DROPDOWN,
      options: ['', 'Yes', 'No'],
      colorCode: 'internal',
      heading: true,
    },
    {
      label: 'Sort Input File?',
      controlName: 'sortInputFile',
      id: 164,
      inputType: InputType.DROPDOWN,
      options: ['', 'Yes', 'No'],
      colorCode: 'internal',
      heading: true,
    },
    {
      label: 'Validate Headers and Trailers?',
      controlName: 'validateHeadersTrailers',
      id: 165,
      inputType: InputType.DROPDOWN,
      options: ['Yes', 'No'],
      colorCode: 'internal',
      heading: false,
    },
  ],
  // Group files section
  [
    {
      label: 'Will there be Group Files?',
      controlName: 'groupFilesSent',
      id: 166,
      inputType: InputType.DROPDOWN,
      options: ['', 'Yes', 'No'],
      notes: 'groupFilesNotes',
      colorCode: 'internal',
      heading: true,
      subHeader: true,
    },
    {
      label: 'Will Grp PPL Pgm be used?',
      controlName: 'grpPplPgmUsed',
      id: 167,
      inputType: InputType.DROPDOWN,
      options: ['', 'Yes', 'No'],
      colorCode: 'internal',
    },
    {
      label: 'Grp PPL Program?',
      controlName: 'grpPplProgram',
      id: 168,
      inputType: InputType.TEXT,
    },
    {
      label: 'Will Group Suspense be used?',
      controlName: 'grpSuspUsed',
      id: 169,
      inputType: InputType.DROPDOWN,
      options: ['', 'Yes', 'No', 'Both'],
      colorCode: 'internal',
    },
    {
      label: 'Retention Days?',
      controlName: 'grpSuspRetentionDays',
      id: 170,
      inputType: InputType.TEXT,
      colorCode: 'internal',
    },
    {
      label: 'Prescriber/Physician Files?',
      controlName: 'qphysFiles',
      id: 171,
      inputType: InputType.DROPDOWN,
      options: ['', 'Yes', 'No'],
      colorCode: 'internal',
    },
    {
      label: 'Diagnosis Files?',
      controlName: 'qdiagFiles',
      id: 172,
      inputType: InputType.DROPDOWN,
      options: ['', 'Yes', 'No'],
      colorCode: 'internal',
    },
    {
      label: 'Lab Files?',
      controlName: 'qlabFiles',
      id: 173,
      inputType: InputType.DROPDOWN,
      options: ['', 'Yes', 'No'],
      colorCode: 'internal',
    },
    {
      label: 'Bypass File Dependency?',
      controlName: 'bypassFileDependency',
      id: 174,
      inputType: InputType.DROPDOWN,
      options: ['', 'Yes', 'No'],
      colorCode: 'internal',
    },
    {
      label: 'Will Member Suspense be used?',
      controlName: 'mbrSuspUsed',
      id: 175,
      inputType: InputType.DROPDOWN,
      options: ['', 'Yes - Daily', 'Yes - File', 'No'],
      colorCode: 'internal',
      heading: true,
    },
    {
      label: 'Retention Days?',
      controlName: 'mbrSuspRetentionDays',
      id: 176,
      inputType: InputType.TEXT,
      colorCode: 'internal',
    },
    {
      label: 'Will Additional Files be sent?',
      inputType: InputType.SUB_HEADER,
      colorCode: 'internal',
    },
  ],
  // Client Code section
  [
    {
      label: 'Is Client Product Code Used?',
      controlName: 'cltPrdctUsed',
      id: 177,
      inputType: InputType.DROPDOWN,
      options: ['', 'Yes', 'No'],
      notes: 'clientCodesNotes',
      colorCode: 'internal',
      heading: true,
      subHeader: true,
    },
    {
      label: 'Is Client Rider Code Used?',
      controlName: 'cltRiderUsed',
      id: 178,
      inputType: InputType.DROPDOWN,
      options: ['', 'Yes', 'No'],
      colorCode: 'internal',
      heading: true,

    },
    {
      label: 'Is Care Network Used?',
      controlName: 'careNtwrkUsed',
      id: 179,
      inputType: InputType.DROPDOWN,
      options: ['', 'Yes', 'No'],
      colorCode: 'internal',
      heading: true,

    },
    {
      label: 'Is Care Facility Used?',
      controlName: 'careFcltyUsed',
      id: 180,
      inputType: InputType.DROPDOWN,
      options: ['', 'Yes', 'No'],
      colorCode: 'internal',
      heading: true,

    },
    {
      label: 'Do we create on the fly?',
      controlName: 'careFcltyOnTheFly',
      id: 181,
      inputType: InputType.DROPDOWN,
      options: ['', 'Yes', 'No'],
      colorCode: 'internal',

    },
    {
      label: 'Is Care Qualifier Used?',
      controlName: 'careQualifierUsed',
      id: 182,
      inputType: InputType.DROPDOWN,
      options: ['', 'Yes', 'No'],
      colorCode: 'internal',
      heading: true,

    },
    {
      label: 'Do we create on the fly?',
      controlName: 'careQualifierOnTheFly',
      id: 183,
      inputType: InputType.DROPDOWN,
      options: ['', 'Yes', 'No'],
      colorCode: 'internal',

    },
    {
      label: 'Will Physician ID\'s (PCP\'s) be used?',
      controlName: 'pcpsUsed',
      id: 184,
      inputType: InputType.DROPDOWN,
      options: ['', 'Yes', 'No'],
      colorCode: 'internal',
      heading: true,

    },
    {
      label: 'Do we create on the fly?',
      controlName: 'pcponTheFlyUsed',
      id: 185,
      inputType: InputType.DROPDOWN,
      options: ['', 'Yes', 'No'],
      colorCode: 'internal',

    },
    {
      label: 'Will Member Level Plans be used?',
      controlName: 'mbrLvlPlansUsedQ',
      id: 186,
      inputType: InputType.DROPDOWN,
      options: ['', 'Yes', 'No'],
      colorCode: 'internal',
      heading: true,

    },
    {
      label: 'Will Client Defined Data (CDD) be used?',
      controlName: 'qcddused',
      inputType: InputType.DROPDOWN,
      id: 187,
      options: ['', 'Y-Mbr', 'Y-Grp', 'Y-Both', 'No'],
      colorCode: 'internal',
      heading: true,

    },
    {
      label: 'Is this a Healthcare Exchange Client?',
      controlName: 'hcexchangeMethodology',
      id: 188,
      inputType: InputType.DROPDOWN,
      options: ['', 'Yes', 'No'],
      heading: true,

    },
    {
      label: 'How will Grace Period be managed?',
      controlName: 'gracePeriodHandling',
      id: 189,
      inputType: InputType.DROPDOWN,
      options: ['', 'Grace Period Flags', 'Internally by Client'],

    },
  ],
  // Divider section
  [
    {
      label: 'Open Enrollment',
      inputType: InputType.HEADER,
    },
    {
      label: 'When is New Benefit Plan Year?',
      controlName: 'openEnroll',
      id: 190,
      inputType: InputType.DROPDOWN,
      options: ['', 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November',
        'December', 'Not Applicable'],
      heading: true,
    },
  ],
  // Open enrollment section
  [
    {
      label: 'Will client be sending an OE file?',
      controlName: 'qcltSendOEFile',
      id: 191,
      inputType: InputType.DROPDOWN,
      options: ['', 'Yes', 'No', 'Not Applicable', 'Other EPR'],
      notes: 'qOENotes',
      heading: true,
      subHeader: true,
    },
    {
      label: 'When is Open Enrollment Window?',
      controlName: 'qoewindow',
      id: 192,
      inputType: InputType.TEXT,
      heading: true,

    },
    {
      label: 'What data will be on OE File?',
      controlName: 'qdataOnOEFile',
      id: 193,
      inputType: InputType.DROPDOWN,
      options: ['', 'New Year Only', 'New and Current Year'],
      heading: true,

    },
    {
      label: 'When will this file be sent?',
      controlName: 'qoefileExpDate',
      id: 194,
      inputType: InputType.DATE,

    },
    {
      label: 'Will ID cards need to be reissued?',
      controlName: 'qoeidcards',
      id: 195,
      inputType: InputType.DROPDOWN,
      options: ['', 'All Mbrs', 'New Mbrs', 'No Cards'],

    },
    {
      label: 'Will explicit terms be sent for current year?',
      controlName: 'qoecurrentTerms',
      id: 196,
      inputType: InputType.DROPDOWN,
      options: ['', 'Yes', 'No'],

    },
    {
      label: 'Does CVSH have to term dropped Mbrs?',
      controlName: 'qoecvstermDrops',
      id: 197,
      inputType: InputType.DROPDOWN,
      options: ['', 'Yes', 'No'],

    },
    {
      label: 'What data will come after OE file?',
      controlName: 'qoedtaAfterOEFil',
      id: 198,
      inputType: InputType.DROPDOWN,
      options: ['', 'Current Only', 'Current and New Year', 'New Year Only', 'No Files Sent'],
      heading: true,

    },
    {
      label: 'When will this file be sent?',
      controlName: 'qoefileAfterOEDate',
      id: 199,
      inputType: InputType.DATE,

    },
    {
      label: 'What will be on 1st file after new bnft yr?',
      controlName: 'qoenewBnftYrFile',
      id: 200,
      inputType: InputType.DROPDOWN,
      options: ['', 'Old and New Year', 'New Year Only'],
      heading: true,

    },
    {
      label: 'When will this file be sent?',
      controlName: 'qoenewBnftYrFileDate',
      id: 201,
      inputType: InputType.DATE,

    },
    {
      label: 'Does CVSH have to term dropped Mbrs?',
      controlName: 'qoenewBnftYrFileTerms',
      id: 202,
      inputType: InputType.DROPDOWN,
      options: ['', 'Yes', 'No'],

    },
    {
      label: 'Will there be CAG structure changes?',
      controlName: 'qoecagstructrChg',
      id: 203,
      inputType: InputType.DROPDOWN,
      options: ['', 'Yes', 'No'],
      heading: true,

    },
    {
      label: 'Will a test file be required if no changes?',
      controlName: 'qoetestFile',
      id: 204,
      inputType: InputType.DROPDOWN,
      options: ['', 'Yes', 'No'],
      heading: true,
    }
  ]
] as FormFieldConfig[][];

export const contactColumns = [
  {headerName: 'Role', field: 'contactRole',
  cellRenderer:GridInputFormRendererComponent,
  sortable: true, filter: true, resizable: true,},
  {headerName: 'Name', field: 'contactName',
  cellRenderer:GridInputFormRendererComponent,
  sortable: true, filter: true, resizable: true},
  {headerName: 'Email', field: 'contactEmail',
  cellRenderer:GridInputFormRendererComponent,
  sortable: true, filter: true, resizable: true},
  {headerName: 'Phone Number', field: 'contactPhone',
  cellRenderer:GridInputFormRendererComponent,
  sortable: true, filter: true, resizable: true},
  {headerName: 'Load Report', field:'loadReport', sortable: true, filter: true, resizable: true,
  cellRendererParams: (params) => ({
    options:[{label:"None",value:"None"},{label:".CSV (delimited)",value:".CSV"}
    ,{label:".PDF", value:".PDF"},{label:".PSV (pipe)", value:".PSV"},
    {label:".RTF", value:".RTF"},{label:".TXT", value:".TXT"},{label:".XLSX", value:".XLSX"}]
  }),
  cellRenderer: GridSelectFormRendererComponent
  },
  { headerName: 'EWF Access', field: 'contactEwfAccessType', sortable: true, filter: true, resizable: true,
  cellRendererParams: (params) => ({
    options:[{label:"Link",value:"Linked"},{label:"None",value:"None"},{label:"Requested",value:"Requested"}
    ,{label:"Remove User", value:"Remove User"},{label:"Needed", value:"Needed"}]
  }),
  cellRenderer: GridSelectFormRendererComponent
  },

];
export const filePrefixColumns = [
  {headerName: 'File Type', field:'fileType', sortable: true, filter: true, resizable: true,
    cellRendererParams: (params) => ({
      options: params.context.fileTypes
    }),
    cellRenderer: GridSelectFormRendererComponent
  },
  {headerName: 'Description', field: 'description', cellRenderer:GridInputFormRendererComponent,sortable: true, filter: true, resizable: true, editable: false},
  {headerName: 'Inbound File Name', field: 'inboundFileName', cellRenderer:GridInputFormRendererComponent, sortable: true, filter: true, resizable: true, editable: false},
  {headerName: 'Prefix', field: 'prefix', cellRenderer:GridInputFormRendererComponent, sortable: true, filter: true, resizable: true, editable: false},
  {headerName: 'File Routing', field: 'fileRouting',  sortable: true, filter: true, resizable: true,
    cellRendererParams: (params) => ({
      options:params.context.fileRouting
    }),
    cellRenderer: GridSelectFormRendererComponent
  },
  {headerName: 'Client ID', field: 'clientId',  sortable: true, filter: true, resizable: true
  },
  {headerName: 'Submitter ID', field: 'submitterId',
  cellRenderer:GridInputFormRendererComponent,
  sortable: true, filter: true, resizable: true, editable: false},
  {headerName: 'Bypass TBA', field: 'bypassTba',  sortable: true, filter: true, resizable: true,
    cellRendererParams: (params) => ({
      options:[{label:"Yes",value:"Yes"},{label:"No",value:"No"}]
    }),
    cellRenderer: GridSelectFormRendererComponent
  },
  {headerName: 'Bypass Threshold', field: 'bypassThreshold',  sortable: true, filter: true, resizable: true,
    cellRendererParams: (params) => ({
      options:[{label:"Yes",value:"Yes"},{label:"No",value:"No"}]
    }),
    cellRenderer: GridSelectFormRendererComponent
  },
  {headerName: 'Status', field: 'status',  sortable: true, filter: true, resizable: true,
    cellRendererParams: (params) => ({
      options:[{label:"Active",value:"Active"},{label:"Inactive",value:"Inactive"}]
    }),
    cellRenderer: GridSelectFormRendererComponent
  },
  {headerName: '', field: 'delete',width:80,
    cellRenderer:DeleteMenuCellRendererComponent,
    }
];
export const reportFormatColumns = [
  {headerName: 'Field', field: 'reportField',
  filter: true, rowDrag:false, resizable: false},

  {headerName: 'Space Used', field: 'reportSpaceUsed', filter: true,width:140,
  cellRendererParams: (params) => ({

  options:params.context.options
  }),
  cellRenderer:GridSelectReportFormRendererComponent
  },
  {headerName: '', field: 'delete',width:80,
  cellRendererParams: (params) => ({

    loadReport:true
    }),
  cellRenderer:DeleteBtnLoadFormatCellRendererComponent,
  resizable: false
  },

];
