import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {AbstractControl, FormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig} from '@angular/material/legacy-dialog';
import {Store} from '@ngrx/store';
import {BehaviorSubject, forkJoin, Observable, of, Subject, Subscription} from 'rxjs';
import {debounce, debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {catchError} from 'rxjs/operators';
import {Permission} from 'src/app/models/Authorization';
import {AppState, getEprId, getPli, getRole, getUserId,getProjectTrackerState} from 'src/app/reducers';
import {MappingApiService} from 'src/app/services/mapping-api.service';
import {MappingFormService} from '../mapping-form.service';
import {MappingImportGuideComponent} from './mapping-import-guide/mapping-import-guide.component';
import {MAPPING_STATUS, NO_PERMISSION_MAPPING_STATUS} from '../mapping-documents.enum';
import {UsersService} from "../../../services/users.service";

@Component({
  selector: 'app-mapping-guide',
  templateUrl: './mapping-guide.component.html',
  styleUrls: ['./mapping-guide.component.scss']
})
export class MappingGuideComponent implements OnInit,  OnDestroy {
  form: UntypedFormGroup;
  list = [{value: 'akshay', id: 1}];
  mapStatusList = [
    '1 - Requested',
    '2 - Mapping',
    '3 - Submitted',
    '3.5 - Received',
    '4 - New Coding',
    '5 - Auto Coding',
    '5.5 - Dev Completed',
    '6 - QA',
    '6.5 - QA Completed',
    '7 - UAT',
    '8 - Ready to Deploy',
    '9 - Completed',
    '10 - Cancelled',
    '11 - Historic',
  ];
  allStatusList = [
    '1 - Requested',
    '2 - Mapping',
    '3 - Submitted',
    '3.5 - Received',
    '4 - New Coding',
    '5 - Auto Coding',
    '5.5 - Dev Completed',
    '6 - QA',
    '6.5 - QA Completed',
    '7 - UAT',
    '8 - Ready to Deploy',
    '9 - Completed',
    '10 - Cancelled',
    '11 - Historic',
  ];

  cagList = ['CAG Table', 'CAG List', 'CAG Translation', 'Undetermined'];
  projectList: Array<any> = [];
  mapTypeList: Array<any> = [];
  recTypeList: Array<any> = [];
  recTypes: any;
  baList = [];
  @Input() mappingDocument: any;
  showValidations: boolean;
  @Input() isNewDoc: boolean;
  @Output() updateToggleForm = new EventEmitter();
  @Output() showWarning = new EventEmitter();
  userId: any;
  eprId: number;
  vendorTechFormat: any;
  coderList: any;
  qaList: any;
  additionalData = {
    mappingTester: null,
    baselineDate: null,
    testingDate: null,
    deployDate: null,
    goLiveDate: null
  };
  leaderName: any;
  dialogConfig = new MatDialogConfig();
  showSpinner: Subject<boolean> = new BehaviorSubject(false);
  previousStatus: any = null;
  is834Format = false;
  currentRole: any;
  subs: Subscription[] = [];
  constructor(private formServ: MappingFormService,
              private api: MappingApiService,
              private usersService: UsersService,
              private store: Store<AppState>,
              private dialog: MatDialog,
  ) {

    this.subs.push(this.store.select(getEprId).subscribe(id => {
      this.eprId = id;
      }),
      this.usersService.userId.subscribe((id) => {
        this.userId = id
      }),
      this.usersService.userRole.subscribe(role =>{
        this.currentRole = role;
      }),
      // this.store.select(getUserId).subscribe(id => {
      //   this.userId = id;
      // }),
      // this.store.select(getRole).subscribe(role => {
      // this.store.select(getProjectTrackerState).subscribe(golive=>{

    );

    this.addActiveListner();
  }

  ngOnInit() {

    this.loadUI();
    if (this.mappingDocument && !this.isNewDoc) {
    this.subs.push(this.fetchMappingGuide(this.mappingDocument));
    }
    this.filterRoleBasedStatus();
    // this.filterMapBasedStatus();
  }

  addActiveListner() {
    this.subs.push(
    this.addMappingDocumentListner(),
    this.addNewMappingListner(),
    this.addEditableListner(),
    this.addValidationListner(),
    this.addImportDocument()
    );
  }

  addImportDocument() {
    return this.formServ.importDocument$.subscribe(resp => {
      if (resp) {
        // {param.clientCode}&pliCode=${param.pliCode}&documentId=${param.documentID}
        this.fetchMappingGuide(resp, {case: 'import'});
      }
    });
  }

  addValidationListner() {
    return this.formServ.showValidations$.subscribe(resp => {
      if (resp) {
        this.showValidations = true;
        this.addRemoveValidators(true);
        // re activate validation check on mapping status. to apply on missed out rec types condition.

        this.form.markAllAsTouched();
      } else {
        this.showValidations = false;
        this.addRemoveValidators(false);
      }
    });
  }

  addRemoveValidators(add: boolean) {
    Object.keys(this.form.controls).forEach(key => {
      const control = this.form.get(key);
      if (add) {

        if (key === 'project' || key === 'recTypes'
          || key === 'mappingStatus') {
          control.setValidators([Validators.required]);
          if (key === 'mappingStatus') {
            this.updateUserStory();
          }
        }

      } else {
        control.clearValidators();
      }
      control.updateValueAndValidity({emitEvent: false});
    });
    if (add) {
      // if status is coding , recheck validation to activate validation on map req .
      const mapValue = this.form.get('mappingStatus').value;
      if (mapValue === '4 - New Coding' || mapValue === '5 - Auto Coding') {
        this.updateMappingStatus(this.form.get('mappingStatus').value, {revalidate: true});
      }

      this.updateCagTable();
      this.updateUserStory();

    }

  }

  isRecArray() {
    return Array.isArray(this.form.value.recTypes);
  }

  addEditableListner() {
    return this.formServ.editableMode$.subscribe(resp => {
      if (resp) { // i.e editable mode is true.
        const values = this.form.getRawValue();

        if (!this.mapStatusList.includes(values.mappingStatus)) {
          // if mapping status isnt in the role based mapping status.
          this.form.get('mappingStatus').patchValue(null, {emitEvent: false});
        }
        this.form.enable({emitEvent: false});
        this.isMappingStatusCompleted();
      } else {
        this.form.disable({emitEvent: false});
      }
    });
  }

  isMappingStatusCompleted() {
    // used for when status is completed disable all other mapping gude fields.
    const values = this.form.getRawValue();
    if (values.mappingStatus === '9 - Completed') {
      this.form.disable({emitEvent: false});
      this.form.get('mappingStatus').enable({emitEvent: false});
    }
  }

  addNewMappingListner() {  // for new addition
    return this.formServ.newMapping$.subscribe(resp => {
      this.newMappingSetting();
    });
  }

  addMappingDocumentListner() { // for existing mapping guide population
    return this.formServ.mappingDocSelected$.subscribe(resp => {
      this.mappingDocument = resp;
      this.isNewDoc = false;
      this.fetchMappingGuide(resp);
    });
  }

  fetchMappingGuide(data, variation?) {
    return this.api.getMappingGuide(data).subscribe(resp => {
      if (resp.status === 'SUCCESS') {
        const mappingResponse = resp.response;
        if (mappingResponse.mappingStatus === '11 - Historic' ||
          mappingResponse.mappingStatus === '10 - Cancelled' ||
          mappingResponse.mappingStatus === '9 - Completed'
        ) {
          this.formServ.disableMappingEdit(true);
          // disabled edit if status is canceled or historic already saved.
        }
        if (variation && variation.case === 'import') {
          // patching value in case of import.
          // if (this.recTypeList.includes(mappingResponse.recTypes)) {
          //   this.form.get('recTypes').patchValue(mappingResponse.recTypes,
          //     { emitEvent: false });
          // }

          if (this.mapStatusList.includes(mappingResponse.mappingStatus)) {
            this.form.get('mappingStatus').patchValue(mappingResponse.mappingStatus,
              {emitEvent: false});
          }

          if (this.mapTypeList.filter(map => map.message === mappingResponse.mapType)) {
            this.form.get('mapType').patchValue(mappingResponse.mapType,
              {emitEvent: false});
          }
          this.form.get('cagManagement').patchValue(mappingResponse.cagManagement,
            {emitEvent: false});
          this.form.get('cagTableId').patchValue(mappingResponse.cagTableId,
            {emitEvent: false});
          this.form.get('mappingComments').patchValue(mappingResponse.mappingComments,
            {emitEvent: false});
        } else {
          // patching values in normal mapping guide api fetch.
          this.form.patchValue(mappingResponse, {emitEvent: false});


          // mappingResponse.format = '834'; //comment
          if (mappingResponse.mappingFormat && mappingResponse.mappingFormat?.toLowerCase().includes('834')) {
            this.is834Format = true;
          } else {
            this.is834Format = false;
          }
          this.updateUserStory();
        }
        if (this.recTypeList.length && mappingResponse.recTypes && mappingResponse.recTypes.length) {
          const savedIncludedRecords = mappingResponse.recTypes.filter(elem => {
            if (this.recTypeList.find(rec => rec.displayName === elem)) {
              return elem;
            }
          });
          if (savedIncludedRecords) {
            this.form.get('recTypes').patchValue(savedIncludedRecords,
              {emitEvent: false});
          } else {
            this.form.get('recTypes').patchValue(null,
              {emitEvent: false});
          }
        }
        if (!this.mapStatusList.includes(mappingResponse.mappingStatus)) {
          this.form.get('mappingStatus').patchValue(null,
            {emitEvent: false});
        } else {
          this.updateUserStory();
        }
        const mapType = this.mapTypeList.filter(map => map.message === mappingResponse.mapType);
        if (!mapType) {
          this.form.get('mapType').patchValue(null,
            {emitEvent: false});
        }
        
        setTimeout(()=>{
          if(this.projectList.length>0){
            const projExist = this.projectList.find(proj => proj.projectName === mappingResponse.project);
            if (!projExist) {
              this.form.get('project').patchValue(null,
                {emitEvent: false});
              this.additionalData.goLiveDate = null;
              this.additionalData.baselineDate = null;
              this.additionalData.testingDate = null;
            }else{
              this.additionalData.goLiveDate = projExist.prjGoLiveDte || null;
              this.additionalData.baselineDate = projExist.newBlDueDate || null;
              this.additionalData.testingDate = projExist.newTstDueDate || null;
            }
          }
        },3000)
        this.formServ.is834Format(this.is834Format);
      } else {
        // handel error
        this.showWarning.emit(resp.errorMessage);
      }
    });
  }

  loadUI() {
    this.form = this.formServ.generateMappingGuideForm();
    this.subs.push(
      this.form.get('recTypes').valueChanges.pipe(debounceTime(300), distinctUntilChanged()).subscribe(value => {
      if (Array.isArray(value)) {
        const selectedRecInfo = [];
        value.forEach(displayName => {
          const rec = this.recTypeList.find(recs => recs.displayName === displayName);
          if (rec) {
            selectedRecInfo.push(rec);
          }
        });
        this.formServ.mappingGuideValueChange({recTypes: selectedRecInfo});
      }
     // this.formServ.mappingGuideValueChange({recTypes:value})
    })
    );
    this.mappingFieldListner();

    if (!this.isNewDoc) {
      this.form.disable({emitEvent: false});
    }
    this.getData();
  }

  getData() {
    const apis = [];
    const errorArray = [];
    // 0
    apis.push(this.api.getProjectDetailApi(this.mappingDocument.eprId).pipe(catchError((err) => {
        errorArray.push(err);
        return of(null);
      }))
    );
    // 1
    apis.push(this.api.getMapTypesApi().pipe(catchError((err) => {
        errorArray.push(err);
        return of(null);
      }))
    );
    // 2
    apis.push(this.api.getRecTypesApi().pipe(catchError((err) => {
        errorArray.push(err);
        return of(null);
      }))
    );
    // 3  for ba
    apis.push(this.api.getRoleApi('SI_MAPPER').pipe(catchError((err) => {
      errorArray.push(err);
      return of(null);
    })));
    // // 4 for coder
    // apis.push(this.api.getRoleApi('admin').pipe(catchError((err) => {
    //   errorArray.push(err);
    //   return of(null);
    // })));
    // // 5 for qa
    // apis.push(this.api.getRoleApi('admin').pipe(catchError((err) => {
    //   errorArray.push(err);
    //   return of(null);
    // })));
    // 6
    apis.push(this.api.getVendorFormatTechCon(this.eprId).pipe(catchError((err) => {
      errorArray.push(err);
      return of(null);
    })));
    // 7
    // this.eprId
    apis.push(this.api.getTester({eprId: 3, iterationNumber: 2}).pipe(catchError((err) => {
      errorArray.push(err);
      return of(null);
    })));
    // 8
    apis.push(this.api.getDates(this.eprId).pipe(catchError((err) => {
      errorArray.push(err);
      return of(null);
    })));
    // 9
    apis.push(this.api.getLeader(this.userId).pipe(catchError((err) => {
      errorArray.push(err);
      return of(null);
    })));

    this.subs.push(
    forkJoin(apis).subscribe((resp: any) => {
      if (resp[0]) { // project
        const projects: Array<any> = resp[0] as Array<any> || [];
        projects.forEach(project => {

          const projectName = this.yymmdd(new Date(project.prjStartDte)) + ' ' + project.prjType;
          project.projectName = projectName;
        });

        this.projectList = projects;
      }
      if (resp[1]) {
        this.mapTypeList = resp[1];
      }
      if (resp[2]) {
        this.recTypeList = resp[2];

      }
      if (resp[3]) {
        const users = resp[3] as Array<any> || [];
        const names = this.getNames(users) || [];
        this.baList = names;
        this.coderList = names;
        this.qaList = names;
      }
      // if (resp[4]) {
      //   const users = resp[3] as Array<any> || [];
      //   this.coderList = this.getNames(users);
      // }
      // if (resp[5]) {
      //   const users = resp[3] as Array<any> || [];
      //   this.qaList = this.getNames(users);
      // }
      if (resp[4]) {
        this.vendorTechFormat = resp[4];
      }
      if (resp[5]) {

        const tester: any = resp[5];
        if (tester.testerFirstName && tester.testerLastName) {
          tester.name = tester.testerFirstName + ' ' + tester.testerLastName;
        } else if (tester.testerFirstName && !tester.testerLastName) {
          tester.name = tester.testerFirstName;
        } else if (!tester.testerFirstName && tester.testerLastName) {
          tester.name = tester.testerLastName;
        }
        this.additionalData.mappingTester = tester.name;
      } else {
        this.additionalData.mappingTester = null;
      }
      if (resp[6]) {
        // get dates
        if (resp[6].status === 'SUCCESS') {
          const dates: any = resp[6].response;
          // this.additionalData.baselineDate = dates.baseLineDate;
          // this.additionalData.testingDate = dates.testingDate;
          this.additionalData.deployDate = dates.qcDeployDate;
         // this.additionalData.goLiveDate = dates.goLiveDate;
        } else {
          // removing the error emit as deployDate fetched from response is not used anywhere on the UI
          // this.showWarning.emit(resp[6].errorMessage);
          // this.additionalData.baselineDate = null;
          // this.additionalData.testingDate = null;
          this.additionalData.deployDate = null;
         // this.additionalData.goLiveDate = null;
        }
      }
      if (resp[7]) {
        const leader: any = [resp[7]];
        this.getNames(leader);
        this.leaderName = leader[0].name;
      }
      if (this.isNewDoc) {
        this.newMappingSetting();
      }
    })
    );
  }

  yymmdd(date) {
    const yy = date.getUTCFullYear().toString().slice(-2); // Get 2-char year
    const mm = (date.getUTCMonth() + 1).toString(); // getMonth() is zero-based
    const dd = date.getUTCDate().toString();

    return [yy, mm.length === 2 ? '' : '0', mm, dd.length === 2 ? '' : '0', dd].join('');
  }

  getNames(users) {
    users.forEach((user => {
      let name = '';
      if (user.firstName && user.lastName) {
        name = user.firstName + ' ' + user.lastName;
      } else if (user.firstName && !user.lastName) {
        name = user.firstName;
      } else if (!user.firstName && user.lastName) {
        name = user.lastName;
      }
      user.name = name;
    }));
    return users;
  }

  newMappingSetting() {
    this.form.reset({emitEvent: false});
    this.form.enable({emitEvent: false});
    this.isNewDoc = true;
    const vendorsTechFormat = this.vendorTechFormat;
    if (vendorsTechFormat) {
      if (vendorsTechFormat.technicalContact) {
        this.form.patchValue({technicalContact: vendorsTechFormat.technicalContact}, {emitEvent: false});
      }
      if (vendorsTechFormat.format) {
        this.form.patchValue({mappingFormat: vendorsTechFormat.format}, {emitEvent: false});
        const format = vendorsTechFormat.format?.toLowerCase();
        // format = '834'; //comment
        if (format.includes('834')) {
          this.is834Format = true;
        } else {
          this.is834Format = false;
        }
      }
      if (vendorsTechFormat.vendor) {
        this.form.patchValue({mappingVendor: vendorsTechFormat.vendor}, {emitEvent: false});
      }
    }
    // add leader api
    this.form.patchValue({mappingLeader: this.leaderName}, {emitEvent: false});
    this.formServ.is834Format(this.is834Format);
  }


  import() {
    this.dialogConfig.disableClose = true;
    this.dialogConfig.autoFocus = true;
    this.dialogConfig.data = {
      currentPli: this.mappingDocument.pliCode,
      currentFileFormat: this.form.getRawValue().mappingFormat
    };
    const dialogData = this.dialog.open(MappingImportGuideComponent, this.dialogConfig);
    dialogData.afterClosed().subscribe(input => {
      if (input != null) {

        // this.api.addRercordApi(input).subscribe(resp => {
        //   if (resp) {
        //    // this.loadData();
        //    // this.logicService.updateLogicField();
        //    // this.showMessage('Record header added !');
        //   }
        // }, err => {
        //   console.error(err);
        // });
      }

    });
  }

  get isImportEnabled() {
    if (this.form && this.form.enabled) {
      if (this.form.getRawValue().mappingStatus === '2 - Mapping') {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  mappingFieldListner() {
    // method should also invoked while saving therefore will be patching up value without emitEvent false.
    this.subs.push(this.form.get('mappingStatus').valueChanges.subscribe(newStatus => {
      this.updateMappingStatus(newStatus);
    }));
    this.subs.push(this.form.get('cagManagement').valueChanges.subscribe(() => {
      this.updateCagTable();
    }));
    this.subs.push(this.form.get('mapType').valueChanges.pipe(distinctUntilChanged()).subscribe(value => {
        this.formServ.mapTypeValueChange({mapType: value});
        const mappingStatus = this.form.get('mappingStatus').value;
        this.updateMappingStatus(mappingStatus);
      }));
      this.subs.push(this.form.get('project').valueChanges.subscribe((value) => {
       // this.updateCagTable();
       if(value){
        const selectedProj = this.projectList.find(proj => proj.projectName === value);
        this.additionalData.goLiveDate = selectedProj.prjGoLiveDte || null;
        this.additionalData.baselineDate = selectedProj.newBlDueDate || null;
        this.additionalData.testingDate = selectedProj.newTstDueDate || null;
       }else{
        this.additionalData.goLiveDate = null;
       }

      }));

  }

  recExist(recList, recType) {

  const completeRecList = [];
  recList.forEach(selectedRecDisplayName => {
    const record =   this.recTypeList.find(data => data.displayName === selectedRecDisplayName);
    if (record) {
      completeRecList.push(record);
    }
    });
    // complete rec list contains selected record info with display name and rec name etc.

  const found = completeRecList.find(element => {
      if (element.recordType === recType) {
        return true;
      }
    });
  if (found) {
      return true;
    } else {
      return false;
    }
  }

  updateMappingStatus(newStatus: any, variation?) {
    this.filterRoleBasedStatus();
    if (this.mapStatusList.includes(newStatus)) {
    const recControl = this.form.get('recTypes');
    if (this.is834Format) {   // for 834 format only.
      const values = this.form.value;
      this.previousStatus = values.mappingStatus;
      const recTypes = values.recTypes;
      let allowUpdation = true;
      if (newStatus === MAPPING_STATUS.NEW_CODING || newStatus === MAPPING_STATUS.AUTO_CODING) {


        if (Array.isArray(recTypes) && recTypes.length) {
          if (
            this.recExist(recTypes, '1') &&
            this.recExist(recTypes, '3') &&
            this.recExist(recTypes, '9')
          ) {
            // includes 1,3,9 rec types.

            const requirement = this.formServ.requirementFormData;
            // requirement.type4RecordsSent = 'Yes';
            // requirement.typeAMemberAncillary = 'Yes';
            // requirement.type5RecordsSent = 'Yes';
            // requirement.quesMultiCarrier = 'Yes';
            // for US320272
            if (requirement) {
              let invalidType5 = true;
              let invalidType4 = true;
              let invalidTypeA = true;
              let invalidMulticarrier = true;
              // - 0, 8 (If Multi-Carrier is = 'Yes')
              // - 4 (If 'Type 4 sent by client' is = 'Yes')
              // - 5 (If 'Type 5 sent by client' is = 'Yes')
              // - A (If 'Type A sent by client' is = 'Yes')
              if (requirement.type5RecordsSent &&
                requirement.type5RecordsSent?.toLowerCase() === 'yes'
              ) {
                if (this.recExist(recTypes, '5')) {
                  invalidType5 = false;
                }
              } else {
                invalidType5 = false;
              }
              if (requirement.type4RecordsSent &&
                requirement.type4RecordsSent?.toLowerCase() === 'yes'

              ) {
                if (this.recExist(recTypes, '4')) {
                  invalidType4 = false;
                }
              } else {
                invalidType4 = false;
              }
              if (requirement.typeAMemberAncillary &&
                requirement.typeAMemberAncillary?.toLowerCase() === 'yes'
              ) {
                if (this.recExist(recTypes, 'A')) {
                  invalidTypeA = false;
                }
              } else {
                invalidTypeA = false;
              }
              if (requirement.quesMultiCarrier &&
                requirement.quesMultiCarrier?.toLowerCase() === 'yes'
              ) {
                if (this.recExist(recTypes, '0') && this.recExist(recTypes, '8')) {
                  invalidMulticarrier = false;
                }
              } else {
                invalidMulticarrier = false;
              }
              if (invalidMulticarrier || invalidType4 || invalidType5 || invalidTypeA) {
                recControl.setErrors({required: true});
                this.formServ.addRequirementValidations(true); // cz status is in auto coding
                allowUpdation = false;
              }
              // // error on
              // recControl.setErrors({incorrect: true});
              // this.formServ.addRequirementValidations(true); // cz status is in auto coding
              // similarly other type4 and type A condition ?

            }
          } else {
            // error on requirement.
            allowUpdation = false;
            recControl.setErrors({required: true});
          }
        } else {
          allowUpdation = false;
        }


        if (allowUpdation) {
          recControl.setErrors(null);
        } else {
          recControl.setErrors({required: true});
          this.formServ.addRequirementValidations(true);
          // this.form.get('mappingStatus').patchValue(this.previousStatus, {emitEvent: false});

        }
        if (!variation) {
          this.updateToggleForm.emit(true);
        }
      } else {
        if (!variation) {
          this.formServ.addRequirementValidations(false);
          if (values.recTypes && values.recTypes.length) {
            recControl.setErrors(null);
          }
        }
      }

    }
  } else {
    this.form.get('mappingStatus').patchValue(null, {emitEvent: false});
  }
    this.formServ.mapStatusValueChange({mappingStatus: this.form.get('mappingStatus').value});
    this.updateUserStory();
  }

  updateCagTable() {
    const control = this.form.get('cagTableId');
    if (this.is834Format) {

      const managementValue = this.form.get('cagManagement').value;
      if (managementValue === 'CAG Table') {
        control.setValidators(Validators.required);
        if (!control.value) {
          control.setErrors({required: true});
        }
        control.markAsTouched();

      } else {
        control.setErrors(null);
        control.clearValidators();
      }
    } else {
      control.clearValidators();
      this.form.updateValueAndValidity({emitEvent: false});
    }

  }

  updateUserStory() {
    const control = this.form.get('userStory');
    const currentStatus = this.form.getRawValue().mappingStatus;
    if (this.showValidations && (
      (currentStatus !== MAPPING_STATUS.REQUESTED && currentStatus !== MAPPING_STATUS.MAPPING))) {
      control.setValidators(Validators.required);
      if (!control.value ) {
        control.setErrors({required: true});
        control.markAsTouched();
      }

    } else {
      control.setErrors(null);
      control.clearValidators();
    }
    this.form.updateValueAndValidity({emitEvent: false});
  }
  isRequiredControler(control) {
    const currentStatus = this.form.getRawValue().mappingStatus;

    if (currentStatus !== this.allStatusList[0] && currentStatus !== this.allStatusList[1]) {
      return true;
    } else {
      try {
        const formControl = this.form.get(control);
        if (formControl && this.form.get(control).validator) {
        return this.form.get(control).validator({} as AbstractControl).required;
        } else {
        return false;
        }
      } catch (e) {
        return false;
      }
    }
    }

filterRoleBasedStatus() {
 this.mapStatusList = this.allStatusList;
   if(!this.usersService.permissionCodes.includes("ELG_SUBMIT_MAP")){
    const newList = this.mapStatusList.filter(elem =>{
      if( elem === MAPPING_STATUS.REQUESTED ||
        elem === MAPPING_STATUS.MAPPING ||
        elem === MAPPING_STATUS.UAT ||
        elem === MAPPING_STATUS.READY_TO_DEPLOY ||
        elem === MAPPING_STATUS.CANCELLED||
      elem === MAPPING_STATUS.HISTORIC){
        return elem
      }
    })
    this.mapStatusList = newList;
   }else{
    // this.mapStatusList ; //will be all.
   }
 

  if (this.currentRole === Permission.ADMIN ||
    this.currentRole === Permission.SI_MAPPER ||
    this.currentRole === 'BA') {
        // this.mapStatusList ; //will be all.
       } else if (this.currentRole === 'CODER') {
         const newList = this.mapStatusList.filter(elem => {
           if (
             elem === MAPPING_STATUS.REQUESTED ||
             elem === MAPPING_STATUS.MAPPING ||
             elem === MAPPING_STATUS.NEW_CODING ||
             elem === MAPPING_STATUS.AUTO_CODING ||
             elem === MAPPING_STATUS.QA ||
             elem === MAPPING_STATUS.UAT ||
             elem === MAPPING_STATUS.READY_TO_DEPLOY ||
             elem === MAPPING_STATUS.COMPLETED) {
             return elem;
           }
         });
         this.mapStatusList = newList;
       } else if (this.currentRole === 'QA') {
         const newList = this.mapStatusList.filter(elem => {
           if (
             elem === MAPPING_STATUS.REQUESTED ||
             elem === MAPPING_STATUS.MAPPING ||
             elem === MAPPING_STATUS.NEW_CODING ||
             elem === MAPPING_STATUS.AUTO_CODING ||
             elem === MAPPING_STATUS.QA ||
             elem === MAPPING_STATUS.UAT ||
             elem === MAPPING_STATUS.READY_TO_DEPLOY) {
             return elem;
           }
         });
         this.mapStatusList = newList;
       } else if (this.currentRole === 'EC') {
         const newList = this.mapStatusList.filter(elem => {
           if (
             elem === MAPPING_STATUS.REQUESTED ||
             elem === MAPPING_STATUS.MAPPING ||
             elem === MAPPING_STATUS.COMPLETED ||
             elem === MAPPING_STATUS.CANCELLED
            ) {
             return elem;
           }
         });
         this.mapStatusList = newList;
       } else {
        const newList = this.mapStatusList.filter(elem => {
           if (elem === MAPPING_STATUS.REQUESTED ||
             elem === MAPPING_STATUS.MAPPING) {
             return elem;
           }
         });
        this.mapStatusList = newList;
       }
 
     this.filterMapBasedStatus();

}
  filterMapBasedStatus() {
  const currentMapValue = this.form.get('mapType').value;
  if (
    currentMapValue?.toString().trim().toLowerCase() === 'custom') {
   this.mapStatusList = this.mapStatusList.filter(status => {
      if (status !== MAPPING_STATUS.SUBMITTED &&
        status !== MAPPING_STATUS.AUTO_CODING &&
        status !== MAPPING_STATUS.NEW_CODING ) {
         return status;
        }
    });
    }
  }
ngOnDestroy(): void {
  if (this.subs.length) {
    this.subs.forEach(sub => sub.unsubscribe());
  }
}
}


