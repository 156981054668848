import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import {MatLegacyTableDataSource as MatTableDataSource} from '@angular/material/legacy-table';
import {MappingRecTypeService} from '../../../../services/mapping-rec-type.service';
import {BehaviorSubject, Subject} from 'rxjs';
import {AddLogicCodeModalComponent} from '../add-logic-code-modal/add-logic-code-modal.component';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {InformationDialogComponent} from '../../common-components/information-dialog/information-dialog.component';

@Component({
  selector: 'app-logic-code-selection-modal',
  templateUrl: './logic-code-selection-modal.component.html',
  styleUrls: ['./logic-code-selection-modal.component.scss']
})
export class LogicCodeSelectionModalComponent implements OnInit {

  allData;
  allLogicCodes;
  dataSource;
  selectedLogicCodeFormControl;
  logicInput;

  displayedColumns: string[] = ['radio', 'logicCodes', 'mappingInstructions', 'mappingDescription'];
  selectedLogicCode: any;

  @Output() selectLogicCodeEvent = new EventEmitter<any>();

  showSpinner: Subject<boolean> = new BehaviorSubject(true);
  selectedMapType: any;

  constructor(public dialogRef: MatDialogRef<LogicCodeSelectionModalComponent>,
              public dialog: MatDialog,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private recTypesService: MappingRecTypeService) { }

  ngOnInit() {
    this.allData = this.data;
    this.selectedMapType = this.data.selectedMapType;
    this.selectedLogicCode = this.allData.selectedLogicCode;
    this.getSelectionData();
  }

  applyFilter() {
    const filterValue = this.logicInput;
    this.dataSource.filter = filterValue?.trim().toLowerCase();
  }

  updateSelectedLogicCode(element) {
    this.selectedLogicCodeFormControl = element;
    this.selectedLogicCode = element.logicCode;
   }
   resetSelection() {
    // this.selectedLogicCodeFormControl = null;
    this.selectedLogicCode = null;
   }

  getSelectionData() {
    this.recTypesService.getAllLogicCodes(this.allData.rowData.fieldName).subscribe(allLogicCodes => {
      const topItem = allLogicCodes ? allLogicCodes.filter(itm => itm.logicCode === this.selectedLogicCode) : [];
      const other = allLogicCodes ?  allLogicCodes.filter(itm => itm.logicCode !== this.selectedLogicCode) : [];
      this.allLogicCodes = [...topItem, ...other];
      this.dataSource = new MatTableDataSource(this.allLogicCodes);
      this.selectedLogicCodeFormControl = topItem[0];
      // this.dataSource.filterPredicate = (record, filter) => {
      //   return record.logicCode.toLowerCase().includes(filter);
      // };
      this.showSpinner.next(false);
    });
  }


  saveSelectedLogicCode() {
    const logicCodeData = this.selectedLogicCodeFormControl || {};
    if (this.selectedLogicCode === null) {
   //   this.allData.rowData.logicCode = null;
      logicCodeData["logicCode"] = null;
      logicCodeData["mappingInstructions"] = '';
      logicCodeData["logicCodeStatus"]=null
    }
    this.selectLogicCodeEvent.emit({
      rowData : this.allData.rowData,
      rowIndex : this.allData.rowIndex,
      logicCode : logicCodeData,
    });
    this.dialogRef.close();
  }

  showAddLogicModal() {
    if (!this.selectedMapType) {
      if (this.allData.rowData.mapType === null) {
        this.dialog.open(InformationDialogComponent, {
          height: '200px',
          width: '400px',
          data: {
            body: 'Please select a \'Map Type\' in the \'Mapping Guide\' section to proceed',
            buttonText: 'Close'
          }
        });
        return;
      }
    }

    // open new modal;
    const logicCodedialogRef = this.dialog.open(AddLogicCodeModalComponent, {
      width: '787px',
      height: '811px',
      data: {
        mappingDocument: this.allData.mappingDocument,
        rowData: {...this.allData.rowData, mapType : this.selectedMapType.mapType}
      },
    });

    logicCodedialogRef.componentInstance.addLogicCodeEvent.subscribe((data) => {
      this.allLogicCodes = [data, ...this.allLogicCodes];
      this.dataSource = new MatTableDataSource([...this.allLogicCodes]);
      this.selectedLogicCode = data.logicCode;
      this.updateSelectedLogicCode(data);
    });
  }


  closeModal() {
    this.dialogRef.close();
  }
}
