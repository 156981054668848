export const myProcessingFilesColumns = [
  {headerName: 'EC', field: 'g4', sortable: true, filter: true, resizable: true, lockVisible: true,
  lockPosition: true, filterParams: {newRowsAction: 'keep'}},
  {headerName: 'Status', field: 'g5', sortable: true, filter: true, resizable: true,
    lockVisible: true, lockPosition: true, filterParams: {newRowsAction: 'keep'},
    tooltipField: 'tRecord.softText',
    cellRenderer: params => {
      const currentValue = params.data.progressBarDetails;
      const outputLoadCompleteStr = "Load Report Send Complete"
      if (currentValue && currentValue.outputText !== "Complete" && currentValue.outputText !== outputLoadCompleteStr.trim()) {
        const progressBarPercent = (currentValue.rowSequence / currentValue.totalTranslations) * 100;
        const progressBar = '<div>' + currentValue.outputText + '</div>' +
          '<div style="border-radius: 6px; width: 100%; margin-top: -6%; background-color:' + currentValue.statusColour + '40;">' +
          '<div style="background-color: ' + currentValue.statusColour + '; ' +
          'border-radius: 2px; padding: 1%; width: ' + progressBarPercent + '%;">' +
          '</div>' +
          '</div>';
        return progressBar;
      } else if (currentValue && currentValue.outputText === "Complete") {
        const progressBarPercent = (currentValue.rowSequence / currentValue.totalTranslations) * 100;
        const progressBar = '<div>' + currentValue.outputText + '</div>' +
          '<div style="border: 0.1px solid #000000;border-radius: 6px; width: 100%; margin-top: -6%; background-color:' + currentValue.statusColour + '40;">' +
          '<div style="background-color: ' + currentValue.statusColour + '; ' +
          'border-radius: 2px; padding: 1%; width: ' + progressBarPercent + '%;">' +
          '</div>' +
          '</div>';
        return progressBar;
      }
      else if (currentValue && currentValue.outputText === outputLoadCompleteStr.trim()) {
        const progressBarPercent = (currentValue.rowSequence / currentValue.totalTranslations) * 100;
        const progressBar = '<div>' + currentValue.outputText + '</div>' +
          '<div style="border: 0.1px solid #000000;border-radius: 6px; width: 100%; margin-top: -6%; background-color:' + currentValue.statusColour + '40;">' +
          '<div style="background-color: ' + currentValue.statusColour + '; ' +
          'border-radius: 2px; padding: 1%; width: ' + progressBarPercent + '%;">' +
          '</div>' +
          '</div>';
        return progressBar;
      } else {
        return params.value;
      }
    }
  },
  {headerName: 'Library', field: 'g13', sortable: true, filter: true, resizable: true,
    lockVisible: true, lockPosition: true, filterParams: {newRowsAction: 'keep'}},
  {headerName: 'File', field: 'g14', sortable: true, filter: true, resizable: true,
    lockVisible: true, lockPosition: true, filterParams: {newRowsAction: 'keep'}},
  {headerName: 'Member', field: 'g6', sortable: true, filter: true, resizable: true,
    lockVisible: true, lockPosition: true, filterParams: {newRowsAction: 'keep'}},
  {headerName: 'Business Date', field: 'g7', sortable: true, filter: true, resizable: true,
    lockVisible: true, lockPosition: true, filterParams: {newRowsAction: 'keep'}},
  {headerName: 'Record Count', field: 'g8', sortable: true, filter: true, resizable: true,
    lockVisible: true, lockPosition: true, filterParams: {newRowsAction: 'keep'}},
  {headerName: 'PG Criteria',   field: 'g11', sortable: true, filter: true, lockVisible: true, lockPosition: true, resizable: true,
    filterParams: { newRowsAction: 'keep'}}
];
