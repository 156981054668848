import { DeleteBtnCellRendererComponent } from '../../../common/DeleteBtnCellRenderer';
import { AppState } from '../../../reducers/index';
import { Store } from '@ngrx/store';
import { CVSAlertService, CVSAlertType } from 'angular-component-library';
import { MessageSupressionService } from '../../../services/message-supression.service';
import { Component, OnInit, Output,Input, ViewChild, EventEmitter, AfterViewInit, HostListener, SimpleChanges, OnChanges } from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';


export interface MapTypeModel {
  id: any;
  message: string;
}



@Component({
  selector: 'app-version-control-data-grid',
  templateUrl: './version-control-data-grid.component.html',
  styleUrls: ['./version-control-data-grid.component.scss']
})
export class VersionControlDataGridComponent implements OnInit, AfterViewInit , OnChanges {
@ViewChild('grid') grid: AgGridAngular;
  @Output() isSaveMapTypeCalled: EventEmitter<any> = new EventEmitter();
  @Input() type : String;

  dataRows = [];
  hlGridHeight = 350;
  contextObj: any;
  selectedStatus = '';
  changedRow: any;
  readonly: boolean;
  mapTypeGridColumns = [
    {
      headerName: 'Id', field: 'id', hide: true
    },
    {
      key:'input',
      headerName: '', field: 'message',
      editable: true,
      sortable: true,
      filter: true, lockVisible: true, lockPosition: true, resizable: true,
      heigth: 300,
      width: 440,
      cellEditor: 'agLargeTextCellEditor',
      cellEditorPopup: true,
      cellEditorParams: {
        maxLength: 1000,
        rows: 10,
        cols: 50
      }
    },
    {
      headerName: 'Action',
      cellRenderer:  DeleteBtnCellRendererComponent,
      editable: false,
      width: 90
    }
  ];

constructor(private msgSupressService: MessageSupressionService, private alertService: CVSAlertService, private store: Store<AppState>) {
    this.contextObj = {
      componentParent: this
    };
  }
  ngOnInit() {
    this.loadData();
  }

  ngOnChanges(changes: SimpleChanges): void { 
    if(changes.type.currentValue !== changes.type.previousValue){
      this.loadData();
    }
  }

  loadData(){
    this.getAllRows();
    this.hlGridHeight = 350;
    this.updateColValidation();
  }

  updateColValidation() {
    const index = this.mapTypeGridColumns.findIndex(data=>data?.key === "input");
    const updateCol = this.mapTypeGridColumns[index];
    if(this.type==="isa12"){
     updateCol.headerName = "ISA12"
     updateCol.cellEditorParams.maxLength = 5;
    }else if(this.type === "gs08"){
      updateCol.headerName = "GS08";
      updateCol.cellEditorParams.maxLength = 12;
    }else if(this.type === "st03"){
      updateCol.headerName = "ST03";
      updateCol.cellEditorParams.maxLength = 35;
    }
    this.mapTypeGridColumns[index] = updateCol;
    if(this.grid && this.grid.api){
      this.grid.api.setGridOption("columnDefs", this.mapTypeGridColumns)
    }
  }
ngAfterViewInit(): void {
    setTimeout(() => { ;
    }, 500);
    this.addNewRow();
    this.saveUnSavedRecords();
    this.alertService.clearAlert('#version-alert')
  }
  getAllRows() {
    this.msgSupressService.getAllVersionData(this.type).subscribe(res => {
      this.dataRows = res;
      this.dataRows.sort((a, b) => a.id - b.id);
    });
    this.changedRow = false;
  }
  saveUnSavedRecords() {

    this.msgSupressService.saveVersionRecords$.subscribe(resp => {
      const updatedRec = [];

      if (resp) {
        this.grid.api.refreshCells();
        this.grid.api.stopEditing();
       setTimeout(()=>{
        this.grid.api.forEachNode(node => {
          if (node.data.isUpdated || node.data.isNew) {
            delete node.data.isUpdated;
            delete node.data.isNew;
            updatedRec.push(node.data);
          }
        });
        if (updatedRec && updatedRec.length) {

          updatedRec[0]['type'] = this.type;
          this.msgSupressService.saveVersionRow(updatedRec, true).subscribe(_ => {
            this.getAllRows();
            this.readonly = false;

            this.alertService.sendAlert({
              headline: 'Save successful!',
              alertType: CVSAlertType.Success,
              removedAfterMilliseconds: 5000,
              alertDocumentHolderName: '#version-alert',
            });
            // this.store.dispatch(new LoadMasterDataSetAction('holidays'));
          });
        }
       },300)
      }
    });

  }
  addNewRow() {
    this.msgSupressService.getNewVewsionRow().subscribe(newVersionRow => {
      if (newVersionRow != null) {
        this.grid.api.applyTransaction({
          addIndex: 0,
          add: [newVersionRow]
        });
        this.grid.api.setFocusedCell(0, 'id');

        this.hlGridHeight = (this.grid.api.getDisplayedRowCount() * 42) + 52;
      }
    });
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    if (this.readonly) {

      if (confirm('Changes have NOT been saved! Click \'OK\' to exit WITHOUT saving.')) {
        return true;
      } else {
        return false;
      }
    }
  }
  showAlert(alertMessage, alertType) {
    this.alertService.sendAlert({
      headline: alertMessage,
      alertType,
      removedAfterMilliseconds: 5000,
      alertDocumentHolderName: '#version-alert',
    });
  }
  onGridReady(params) {
    this.grid = params;
    this.hlGridHeight = this.dataRows.length * 51;
    this.grid.api.setGridOption("columnDefs", this.mapTypeGridColumns);
    this.grid.api.redrawRows();
    this.grid.gridOptions = { onCellValueChanged(data)  {
      this.UpdatedRow(data);
    }};
  }
  UpdatedRow(cell) {
    cell.data.isUpdated = true;
    this.readonly = true;
    }
  emitSave() {
    this.isSaveMapTypeCalled.emit();

  }
public deleteTableRecord(node, colId) {

    if (node.data.isNew) {
      this.readonly = false;
      this.grid.api.applyTransaction({remove:[node.data]});
      this.hlGridHeight = (this.grid.api.getDisplayedRowCount() * 42) + 52;
    } else {
      this.msgSupressService.deleteVersionRow([{ ...node.data }], false).subscribe(resp => {
        this.grid.api.applyTransaction({remove:[node.data]});
        this.hlGridHeight = (this.grid.api.getDisplayedRowCount() * 42) + 52;
      });
    }
  }

}
