import { Permission } from "./Authorization";

export enum DashboardViewsPermissions {
    'EPR\'s' = "ELG_ELIGIBILITY_PROCESSING_REQUIREMENTS_VIEW",
    'Projects' = "ELG_PROJECTS_VIEW",
    'Open Enrollment' = "ELG_OPEN_ENROLLMENT_VIEW",
    'Integrated Feeds' = "ELG_INTEGRATED_FEEDS_VIEW",
    'EPR Master' = "ELG_EPR_MASTER_VIEW",
    'Testing'= "ELG_TESTING_VIEW",
    'Processing Files' = "ELG_PROCESSING_FILES_VIEW", 
    'QC/Audit'='ELG_QC_AUDIT_VIEW',
    'File Mappings'="ELG_FILE_MAPPING_VIEW",
    'FT Onboarding - Eligibility'="ELG_FILE_TRACKER_VIEW",
    'CAG REF (Manual)'="ELG_CAG_REF_MANUAL_VIEW",
    'CAG REF (Batch)'="ELG_CAG_REF_BATCH_VIEW",
    '1/1 Projects' ="ELG_WELCOME_SEASON_PROJECTS_VIEW",
    //'Term By Absence' = "ELG_TBA_VIEW"
}

export const dashboardViews = {
    'EPR\'s' : { id: '', title: 'EPR\'s', seq: 101, roles: [], isLoaded: false, gridOptions: null, rowData: [], access:false},
    'Projects' : {
        id: '', title: 'Projects', seq: 107, roles: [Permission.ADMIN, Permission.MANAGER, Permission.EC,
        Permission.PROCESSOR, Permission.LEADERSHIP, Permission.ERM, Permission.TESTER_AUDITOR,
        Permission.INTFEED, Permission.SI_MAPPER, Permission.CLIENT_USER,Permission.CONSULTANT_USER,Permission.OTHER_FILE_USER], isLoaded: false, gridOptions: null, rowData: [],
        access:false
      },
    'Open Enrollment' : {
        id: '', title: 'Open Enrollment', seq: 106, roles: [Permission.ADMIN, Permission.MANAGER, Permission.EC,
        Permission.PROCESSOR, Permission.LEADERSHIP, Permission.ERM,Permission.TESTER_AUDITOR,
          Permission.INTFEED, Permission.SI_MAPPER, Permission.CLIENT_USER,Permission.CONSULTANT_USER,Permission.OTHER_FILE_USER],
        isLoaded: false, gridOptions: null, rowData: [],
        access:false
      },
    'Integrated Feeds' :  {
        id: '', title: 'Integrated Feeds', seq: 103, roles: [Permission.ADMIN, Permission.MANAGER, Permission.EC,
        Permission.PROCESSOR, Permission.LEADERSHIP, Permission.ERM, Permission.TESTER_AUDITOR,
         Permission.INTFEED, Permission.SI_MAPPER, Permission.CLIENT_USER,Permission.CONSULTANT_USER,Permission.OTHER_FILE_USER],
        isLoaded: false, gridOptions: null, rowData: [] ,
        access:false
      },
    'EPR Master' : {
        id: '', title: 'EPR Master', seq: 102, roles: [Permission.ADMIN, Permission.MANAGER, Permission.EC,
        Permission.PROCESSOR, Permission.LEADERSHIP, Permission.ERM, Permission.TESTER_AUDITOR,
         Permission.INTFEED, Permission.SI_MAPPER, Permission.CLIENT_USER,Permission.CONSULTANT_USER,Permission.OTHER_FILE_USER],
        isLoaded: false, gridOptions: null, rowData: [],
        access:false},
      
    'Testing': {
        id: '', title: 'Testing', seq: 105, roles: [Permission.ADMIN, Permission.MANAGER, Permission.EC, Permission.PROCESSOR,
        Permission.LEADERSHIP, Permission.ERM, Permission.TESTER_AUDITOR,
         Permission.INTFEED, Permission.SI_MAPPER, Permission.CLIENT_USER,Permission.CONSULTANT_USER,Permission.OTHER_FILE_USER],
        isLoaded: false, gridOptions: null, rowData: [] ,
        access:false
      },
    'Processing Files' : {
        id: '', title: 'Processing Files', seq: 110, roles: [Permission.ADMIN, Permission.MANAGER, Permission.EC,
        Permission.PROCESSOR, Permission.LEADERSHIP, Permission.ERM, Permission.TESTER_AUDITOR, Permission.INTFEED,
        Permission.SI_MAPPER, Permission.CLIENT_USER,Permission.CONSULTANT_USER,Permission.OTHER_FILE_USER], isLoaded: false, gridOptions: null, rowData: [],
        access:false
      },
    'QC/Audit':{
        id: '', title: 'QC/Audit', seq: 104, roles: [Permission.ADMIN, Permission.MANAGER, Permission.EC,
        Permission.PROCESSOR, Permission.LEADERSHIP, Permission.ERM, Permission.TESTER_AUDITOR,
         Permission.INTFEED, Permission.SI_MAPPER, Permission.CLIENT_USER,Permission.CONSULTANT_USER,Permission.OTHER_FILE_USER],
        isLoaded: false, gridOptions: null, rowData: [] ,
        access:false
      },
    'File Mappings': {
        id: '', title: 'File Mappings', seq: 111, roles: [Permission.ADMIN, Permission.MANAGER, Permission.EC,
        Permission.PROCESSOR, Permission.LEADERSHIP, Permission.ERM, Permission.TESTER_AUDITOR,
         Permission.INTFEED, Permission.SI_MAPPER, Permission.CLIENT_USER,Permission.CONSULTANT_USER,Permission.OTHER_FILE_USER], isLoaded: false, gridOptions: null, rowData: [],
        access:false
      },
    'FT Onboarding - Eligibility':{
        id: '', title: 'FT Onboarding - Eligibility', seq: 112, roles: [Permission.ADMIN, Permission.MANAGER, Permission.EC,
        Permission.PROCESSOR, Permission.LEADERSHIP, Permission.ERM, Permission.TESTER_AUDITOR,
         Permission.INTFEED, Permission.SI_MAPPER, Permission.CLIENT_USER,Permission.CONSULTANT_USER,Permission.OTHER_FILE_USER],
        isLoaded: false, gridOptions: null, rowData: [],
        access:false
      },
    'CAG REF (Manual)':{
        id: '', title: 'CAG REF (Manual)', seq: 113, roles: [Permission.ERM, Permission.ADMIN, Permission.MANAGER,
        Permission.EC, Permission.PROCESSOR, Permission.LEADERSHIP, Permission.ERM, Permission.TESTER_AUDITOR,
        Permission.INTFEED, Permission.SI_MAPPER, Permission.CLIENT_USER,Permission.CONSULTANT_USER,Permission.OTHER_FILE_USER], isLoaded: false, gridOptions: null, rowData: [],
        access:false
      },
    'CAG REF (Batch)':{
        id: '', title: 'CAG REF (Batch)', seq: 114, roles: [Permission.ERM, Permission.ADMIN, Permission.MANAGER,
        Permission.EC, Permission.PROCESSOR, Permission.LEADERSHIP, Permission.ERM, Permission.TESTER_AUDITOR,
        Permission.INTFEED, Permission.SI_MAPPER, Permission.CLIENT_USER,Permission.CONSULTANT_USER,Permission.OTHER_FILE_USER], isLoaded: false, gridOptions: null, rowData: [],
        access:false
      },
    '1/1 Projects' :{
        id: '', title: '1/1 Projects', seq: 109, roles: [Permission.ADMIN, Permission.MANAGER, Permission.EC,
        Permission.PROCESSOR, Permission.LEADERSHIP, Permission.ERM, Permission.TESTER_AUDITOR, Permission.INTFEED,
        Permission.SI_MAPPER, Permission.CLIENT_USER,Permission.CONSULTANT_USER,Permission.OTHER_FILE_USER], isLoaded: false, gridOptions: null, rowData: [],
        access:false
      },
    'Term By Absence' : { id: '', title: 'Term By Absence', seq: 115, roles: [], isLoaded: false, gridOptions: null, rowData: [], access:false}

}
export enum DashboardButtonsPermissions {
  RECENT_EPR = "ELG_RECENT_ITEMS",
  ADD_EPR = "ELG_ADD_EPR",
  INCLUDE_BACKUP = "ELG_INCLUDE_BACKUP",
  DYNAMIC_SEARCH = "ELG_DYNAMIC_SEARCH",
  ADD_CAGMANUAL = "ELG_ADD_CAG_REF_MANUAL",
  EDIT_CAGMANUAL= "ELG_EDIT_CAG_REF_MANUAL",
  DELETE_CAGMANUAL = "ELG_DELETE_CAG_REF_MANUAL",
  REQUEST_CAGBATCH="ELG_SUBMIT_CAG_REF_REQUEST",
  DOWNLOAD_TBA = "ELG_DOWNLOAD_TBA_DETAILS",
  ADD_TBA = "ELG_CREATE_TBA_FILE",
  OPEN_TBA = "ELG_OPEN_TBA_MODAL",
  MANAGE_TBA =  "ELG_MANAGE_TBA_FILE"

}
export enum DashboardButtonsList {
  RECENT_EPR = "",
  ADD_EPR = "",
  INCLUDE_BACKUP = "",
  DYNAMIC_SEARCH = "",
  ADD_CAGMANUAL = "",
  EDIT_CAGMANUAL= "",
  DELETE_CAGMANUAL = "",
  REQUEST_CAGBATCH= '',
  DOWNLOAD_TBA = '',
  ADD_TBA = '',
  OPEN_TBA = '',
  MANAGE_TBA = ''
}