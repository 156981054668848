import { AfterViewInit, Component, OnInit,ViewChild } from '@angular/core';
import {CopayOnboardingFormConfig} from './copayAccumConfig';
import {InputType} from '../models/FormFieldConfig';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import * as fromRoot from '../reducers';
import {Store} from '@ngrx/store';
import {CVSAlertService, CVSAlertType, DateRangeValidationErrors} from 'angular-component-library';
import {Actions} from '@ngrx/effects';
import {AlertType} from '../models/AlertType';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import {Observable} from 'rxjs';
import {FormType} from '../models/FormType';
import {Router} from '@angular/router';
import {Permission} from '../models/Authorization';
import {UsersService} from "../services/users.service";
import { CopayContactDialogComponent } from './copay-contact-dialog/copay-contact-dialog.component';
import {EditAction} from '../actions/copay.action';
import { CopayService } from '../services/copay.service';
import {getIsRegisteredUser, getRole, getCopay} from '../reducers';

@Component({
  selector: 'app-copay-accum-onboarding',
  templateUrl: './copay-accum-onboarding.component.html',
  styleUrls: ['./copay-accum-onboarding.component.scss']
})
export class CopayAccumOnboardingComponent implements OnInit {
  isEdited: boolean = false;
  showWeekly: boolean = false;
  showMonthly: boolean = false;
  showDaily7Days: boolean = false;
  showDailyMtoF: boolean = false;
  latefileShowAddContactInput = false;
  missedfileShowAddContactInput = false;
  isLateFileViewContactInput = false;
   ismissedFileViewContactInput = false;
   latefileShowAddContactOutput = false;
  missedfileShowAddContactOutput = false;
  isLateFileViewContactOutput = false;
   ismissedFileViewContactOutput = false;
  userId: any;
  ismissedFileOutputYes: boolean = false;
  isLateFileOutputYes: boolean = false;
  cltsetupId: any;
  contactConfirm :string[] = []
  missedFileSLAAlertInput: boolean = false;
  lateFileWarningAlertInput: boolean = false;
  missedFileSLAAlertOutput: boolean = false;
  lateFileWarningAlertOutput: boolean = false;

  constructor(private store: Store<fromRoot.AppState>, private alertService: CVSAlertService,
    private actions$: Actions,
    private usersService: UsersService,
    private router: Router,
    public dialog: MatDialog,private copayService:CopayService) { }

  formType = FormType;
  inputType = InputType;
  CopayOnboardingFormConfig = CopayOnboardingFormConfig;
  hyperlinks = [];
  form: UntypedFormGroup = new UntypedFormGroup({});
  errors = {};
  isErrorMessage = false;
  isWeeklySelected = false;
  isMonthlySelected = false;
  isNew:boolean = false;
  isAdmin:boolean = false;
  isRegisteredUser$ = this.store.select(getIsRegisteredUser) as Observable<boolean>;
  recordObject:any;

  @ViewChild('hiddenText1') hiddenText1;
  @ViewChild('hiddenText2') hiddenText2;
  @ViewChild('hiddenText3') hiddenText3;
  @ViewChild('hiddenText4') hiddenText4;

  minWidth = 200;
  width1: number = this.minWidth;
  width2: number = this.minWidth;
  width3: number = this.minWidth;
  width4: number = this.minWidth;
  width5: number = 96;

  ngOnInit() {
    this.usersService.userId.subscribe((id) => {
      this.userId = id
    })
    this.CopayOnboardingFormConfig = [...CopayOnboardingFormConfig];
    this.setupForm();
    if (!this.copayService.isNew) {
      this.copayService.getCopay(this.copayService.cltId).subscribe((res: any) => {

        let result = res.response
        if (result ) {
          this.cltsetupId = result.cltSetupID
          const copayObject: any = {
            cltSetupID: result.cltSetupID,
            clientReceiverID : result.clientReceiver,
            businessUnit: result.systemTx,
            InputFileName: result.inputFileNamePattern,
            OutputFileName: result.outputFileNamePattern,
            activeorInactiveStatus: result.activeorInactiveStatus,
            note: result.note,
            fileFrequency: result.benefitsAlertConfigModel? result.benefitsAlertConfigModel.reportFrequency:'',
            fileFrequencyVal:result.benefitsAlertConfigModel? result.benefitsAlertConfigModel.reportFrequencyVal:'',
            contractedDeliveryTime: result.benefitsAlertConfigModel? result.benefitsAlertConfigModel.contractedDeliveryTime:'',
            lateFileWarningTime: result.benefitsAlertConfigModel? result.benefitsAlertConfigModel.lateFileWarningTime:'',
            processingTime: result.benefitsAlertConfigModel? result.benefitsAlertConfigModel.outputAlertProcTimeSla:'',
            lateFileWarningAlertOutputFiles: result.benefitsAlertConfigModel? result.benefitsAlertConfigModel.outputLateFileAlertTimeSla:'',
            missedFileSLAAlertInput: result.benefitsAlertConfigModel? result.benefitsAlertConfigModel.missedFileSlaAlertForInput:'',
            lateFileWarningAlertInput: result.benefitsAlertConfigModel? result.benefitsAlertConfigModel.lateFileAlertForInput:'',
            missedFileSLAAlertOutput: result.benefitsAlertConfigModel? result.benefitsAlertConfigModel.missedFileSlaAlertForOutput:'',
            lateFileWarningAlertOutput: result.benefitsAlertConfigModel? result.benefitsAlertConfigModel.lateFileAlertForOutput:'',
    }
    this.recordObject = copayObject


    if (copayObject.clientReceiverID) {
      this.resize(1);
    }
    if (copayObject.businessUnit) {
      this.resize(2);
    }
    if (copayObject.InputFileName) {
      this.resize(3);
    }
    if (copayObject.OutputFileName) {
      this.resize(4);
    }
          this.form.patchValue(copayObject);
          this.shwcontactBtn(this.recordObject)
          if (copayObject.fileFrequency === 'Weekly') {

            this.showWeekly = true;
            this.showMonthly = false;
            this.showDailyMtoF = false;
            this.showDaily7Days = false;
            this.form.get('fileFrequencyVal').setValue(copayObject.fileFrequencyVal)
          } else if (copayObject.fileFrequency === 'Monthly') {
            this.showWeekly = false;
            this.showMonthly = true;
            this.showDailyMtoF = false;
            this.showDaily7Days = false;
          } else if (copayObject.fileFrequency === 'Daily') {
            this.showDaily7Days = true;
            this.showWeekly = false;
            this.showMonthly = false;
            this.showDailyMtoF = false;
          }  else if (copayObject.fileFrequency === 'Daily M-F') {
            this.showDailyMtoF = true;
            this.showWeekly = false;
            this.showMonthly = false;
            this.showDaily7Days = false;
          }  else {
            this.showWeekly = false;
            this.showMonthly = false;
            this.showDailyMtoF = false;
            this.showDaily7Days = false;
          }
        }
      });
    } else {
      this.isEdited = true;
      this.formEnable();
      this.form.get('businessUnit').setValue('BENEFIT')
      this.copayService.getNewCopayClientSetupId().subscribe(res => {
        this.form.get('cltSetupID').setValue(res.id);
      });
    }
    this.listenChanges();
    this.usersService.userRole.subscribe(role =>{
      if ([Permission.ADMIN, Permission.MANAGER,
           Permission.EC, Permission.PROCESSOR,
           Permission.LEADERSHIP, Permission.ERM, Permission.TESTER_AUDITOR,
           Permission.INTFEED,  Permission.FILETRACKER, Permission.SPEDM,Permission.FT_BENEFIT].includes(role)) {
        this.isAdmin = true;
      } else {
        this.isAdmin = false;
      }
    });
  }

  resize(e) {
    if (e === 1) {
      // this.minWidth = 200;
      // this.width1 = this.minWidth;
      setTimeout(() => this.width1 = Math.max(this.minWidth, this.hiddenText1.nativeElement.offsetWidth));
    } else if (e === 2) {
      // this.minWidth = 200;
      // this.width2 = this.minWidth;
      setTimeout(() => this.width2 = Math.max(this.minWidth, this.hiddenText2.nativeElement.offsetWidth));
    } else if (e === 3) {
      // this.minWidth = 200;
      // this.width3 = this.minWidth;
      setTimeout(() => this.width3 = Math.max(this.minWidth, this.hiddenText3.nativeElement.offsetWidth));
    } else {
      // this.minWidth = 200;
      // this.width4 = this.minWidth;
      setTimeout(() => this.width4 = Math.max(this.minWidth, this.hiddenText4.nativeElement.offsetWidth));
    }
  }

getHyperlink(controlName: string, hyperlinks) {
  let hyperLinkValue = null;
  if (Array.isArray(hyperlinks) && hyperlinks[0] && hyperlinks[0][controlName]) {
    hyperLinkValue =  hyperlinks[0][controlName];
  }
  return hyperLinkValue;

}
setupForm() {
  // Base form
  this.CopayOnboardingFormConfig.map(section => {
    section.map((formField: any) => {
      if (formField.notes) {
        this.form.addControl(formField.notes, new UntypedFormControl(''));
      }
      if (formField.inputType !== InputType.HEADER) {
        if (formField['required']) {
          this.form.addControl(formField.controlName, new UntypedFormControl('', formField['controller']));
        } else {
          this.form.addControl(formField.controlName, new UntypedFormControl(''));
        }

      }
      if (formField.listing === 'repFrequency') {
        this.form.addControl(formField.controlValName, new UntypedFormControl('',formField['controller']));
      }
    });
  });

  this.formDisabled();
}
startEdit() {
  this.isEdited = true;
  this.formEnable();
}
formDisabled() {
  this.CopayOnboardingFormConfig.map(section => {
    section.map(formField => {
      if (formField.inputType !== InputType.HEADER && this.form.get(formField.controlName)) {
        this.form.get(formField.controlName).disable();
        if (formField.controlName === 'fileFrequency'){
          this.form.get(formField.controlValName).disable();
        }
      }
    });
  });
}
formEnable() {
  this.CopayOnboardingFormConfig.map(section => {
    section.map(formField => {
      if (formField.inputType !== InputType.HEADER && this.form.get(formField.controlName)) {
        this.form.get(formField.controlName).enable();
        if (formField.controlName === 'fileFrequency'){
          this.form.get(formField.controlValName).enable();
        }
      }
    });
  });
}
cancelCopay() {
  this.isEdited = false;
  this.router.navigateByUrl('dashboard/Benefit');
}
saveCopay() {
  const formValue = this.form.value;
  const CopayData: any = {
    cltSetupID: formValue.cltSetupID,
    clientReceiver : formValue.clientReceiverID,
    systemTx: formValue.businessUnit,
    inputFileNamePattern: formValue.InputFileName,
    outputFileNamePattern: formValue.OutputFileName,
    activeorInactiveStatus: formValue.activeorInactiveStatus,
    note: formValue.note,
    benefitsAlertConfigModel:{
      reportFrequency: formValue.fileFrequency,
      reportFrequencyVal:formValue.fileFrequencyVal,
      contractedDeliveryTime: formValue.contractedDeliveryTime,
      lateFileWarningTime: formValue.lateFileWarningTime,
      outputAlertProcTimeSla: formValue.processingTime,
      missedFileSlaAlertForInput: formValue.missedFileSLAAlertInput,
      lateFileAlertForInput: formValue.lateFileWarningAlertInput,
      missedFileSlaAlertForOutput: formValue.missedFileSLAAlertOutput,
      lateFileAlertForOutput: formValue.lateFileWarningAlertOutput,
      outputLateFileAlertTimeSla:formValue.lateFileWarningAlertOutputFiles
    }
  };
  if ( this.copayService.isNew ) {
    this.copayService.saveCopay(CopayData, 'save').subscribe(response => {
      if(response.status === 'SUCCESS'){
        this.isEdited = false;
      this.copayService.isNew = false;
      this.showMessage('Saved Successfully', 'Success');
      this.shwcontactBtn(CopayData)
      Object.entries(CopayData.benefitsAlertConfigModel).forEach(([key, value]) => {
        this.contactBtnLog(key,value)
      });
      this.formDisabled();
      }else if(response.status === 'ERROR'){
        this.showMessage(response.errorMessage, 'Error');
      }

    });
  } else {
    // this.store.dispatch(new UpdateFiletrackerAction(this.form.getRawValue()));
    CopayData.cltSetupID = formValue.cltSetupID;
    this.copayService.saveCopay(CopayData, 'update').subscribe(response => {
      if(response.status === 'SUCCESS'){
        this.isEdited = false;
      this.showMessage('Updated Successfully', 'Success');
      this.shwcontactBtn(CopayData)
      Object.entries(CopayData.benefitsAlertConfigModel).forEach(([key, value]) => {
        this.contactBtnLog(key,value)
      });
      this.formDisabled();
      }else if(response.status === 'ERROR'){
        this.showMessage(response.errorMessage, 'error');
      }

    });
  }
  this.isEdited = false;
}
addContacts(alertfield,controlname) {
  const defaultmessage = 'ErrorMessage:';
  this.store.dispatch(new EditAction(this.isEdited));
  this.dialog.open(CopayContactDialogComponent, {
    data: {
      setupId: this.form.controls['cltSetupID'].value,
      alertField: alertfield,
      type: AlertType.STATUS,
      isEdited: this.isEdited,
      fieldName:controlname
    }
  });
}

 // eslint-disable-next-line @typescript-eslint/no-shadow
 showMessage(message, type) {
  // message = message.substring(message.indexOf('\"') + 1, message.length - 1);
  this.alertService.sendAlert({
    // headline: message ? message : 'Save successful !',
    headline: message,
    alertType: type === 'error' ? CVSAlertType.Error : CVSAlertType.Success,
    removedAfterMilliseconds: 3000,
    alertDocumentHolderName: '#Benefit-Copay',
  });
}

showoptions(event, value, field) {
  field.value = event;
  if (value === 'fileFrequency') {

    if (event === 'Weekly') {
      this.showWeekly = true;
      this.showMonthly = false;
      this.showDailyMtoF = false;
      this.showDaily7Days = false;
    } else if (event === 'Monthly') {
      this.showWeekly = false;
      this.showMonthly = true;
      this.showDailyMtoF = false;
      this.showDaily7Days = false;
    } else if (event === 'Daily') {
      this.showDaily7Days = true;
      this.showWeekly = false;
      this.showMonthly = false;
      this.showDailyMtoF = false;
    }  else if (event === 'Daily M-F') {
      this.showDailyMtoF = true;
      this.showWeekly = false;
      this.showMonthly = false;
      this.showDaily7Days = false;
    }  else {
      this.showWeekly = false;
      this.showMonthly = false;
      this.showDailyMtoF = false;
      this.showDaily7Days = false;
    }
  }
  this.contactBtnLog(value,event)
}

contactBtnLog(value,event){
  if (value === 'lateFileWarningAlertInput' && this.lateFileWarningAlertInput === false) {
    event === 'Y' ? this.latefileShowAddContactInput = true : this.latefileShowAddContactInput = false;
  } else if (value === 'lateFileWarningAlertInput' && this.lateFileWarningAlertInput === true) {
    this.isLateFileViewContactInput = true;
    this.latefileShowAddContactInput = false;
  }
  if (value === 'missedFileSLAAlertInput' && this.missedFileSLAAlertInput === false) {
    event === 'Y' ? this.missedfileShowAddContactInput = true : this.missedfileShowAddContactInput = false;
  } else if (value === 'missedFileSLAAlertInput' && this.missedFileSLAAlertInput === true) {
    this.ismissedFileViewContactInput = true ;
    this.missedfileShowAddContactInput = false;
  }
  if (value === 'lateFileWarningAlertOutput' && this.lateFileWarningAlertOutput === false) {
    event === 'Y' ? this.latefileShowAddContactOutput = true : this.latefileShowAddContactOutput = false;
  } else if (value === 'lateFileWarningAlertOutput' && this.lateFileWarningAlertOutput === true) {
    this.isLateFileViewContactOutput = true;
    this.latefileShowAddContactOutput = false;
  }
  if (value === 'missedFileSLAAlertOutput' && this.missedFileSLAAlertOutput === false) {
    event === 'Y' ? this.missedfileShowAddContactOutput = true : this.missedfileShowAddContactOutput = false;
  } else if (value === 'missedFileSLAAlertOutput' && this.missedFileSLAAlertOutput === true) {
  this.ismissedFileViewContactOutput = true ;
  this.missedfileShowAddContactOutput = false;
  }
}

listenChanges(){
  this.form.get('fileFrequency').valueChanges.subscribe(val => {
    if (val === 'Daily M-F') {
      this.form.get('fileFrequencyVal').setValue('Daily M-F');
    } else if (val === 'Daily') {
      this.form.get('fileFrequencyVal').setValue('Daily');
    } else if (val === 'Weekly'){
     this.isWeeklySelected = true;
     this.isErrorMessage = true
    } else if(val === 'Monthly'){
     this.isMonthlySelected = true;
     this.isErrorMessage = true;
    }
  });

  this.form.get('fileFrequencyVal').valueChanges.subscribe(val =>{
   if(val){
     this.isWeeklySelected = false;
     this.isMonthlySelected = false;
     this.isErrorMessage = false;
   }
  })
  this.form.get('contractedDeliveryTime').valueChanges.subscribe(val => {
    const lateFileWarningTime = this.form.get('lateFileWarningTime').value;
    let lateFileWarningHour = '';
    let contractedDeliveryHour = '';
    if (lateFileWarningTime) {
      lateFileWarningHour = lateFileWarningTime.split(':')[0];
      contractedDeliveryHour = val.split(':')[0];
      if (contractedDeliveryHour < lateFileWarningHour && val) {
        this.errors['contractedDeliveryTime'] = {message: 'contractedDeliveryTime should be greaterthan lateFileWarningTime '};
        this.errors['lateFileWarningTime'] = {message: ''};
        this.isErrorMessage = true;
      } else if (contractedDeliveryHour === lateFileWarningHour) {
        const lateFileWarningMinute = lateFileWarningTime.split(':')[1];
        const contractedDeliveryMinute = val.split(':')[1];

        if (contractedDeliveryMinute < lateFileWarningMinute && val) {
          this.errors['contractedDeliveryTime'] = {message: 'contractedDeliveryTime should be greaterthan lateFileWarningTime '};
          this.errors['lateFileWarningTime'] = {message: ''};
          this.isErrorMessage = true;
        } else {
          this.errors['contractedDeliveryTime'] = {message: ''};
          this.errors['lateFileWarningTime'] = {message: ''};
          this.isErrorMessage = false;
        }

      } else {
        this.errors['contractedDeliveryTime'] = {message: ''};
        this.errors['lateFileWarningTime'] = {message: ''};
        this.isErrorMessage = false;
      }
    }
   });
  this.form.get('lateFileWarningTime').valueChanges.subscribe(val => {
     const contractedDeliveryTime = this.form.get('contractedDeliveryTime').value;
     let lateFileWarningHour = '';
     let contractedDeliveryHour = '';
     if (contractedDeliveryTime) {
      contractedDeliveryHour = contractedDeliveryTime.split(':')[0];
      lateFileWarningHour = val.split(':')[0];
      if (contractedDeliveryHour < lateFileWarningHour && val) {
        this.errors['lateFileWarningTime'] = {message: 'lateFileWarningTime should be lesserthan contractedDeliveryTime '};
        this.errors['contractedDeliveryTime'] = {message: ''};
        this.isErrorMessage = true;
      } else if (contractedDeliveryHour === lateFileWarningHour) {
        const lateFileWarningMinute = val.split(':')[1];
        const contractedDeliveryMinute = contractedDeliveryTime.split(':')[1];

        if (contractedDeliveryMinute < lateFileWarningMinute && val) {
          this.errors['lateFileWarningTime'] = {message: 'lateFileWarningTime should be lesserthan contractedDeliveryTime '};
          this.errors['contractedDeliveryTime'] = {message: ''};
          this.isErrorMessage = true;
        } else {
          this.errors['contractedDeliveryTime'] = {message: ''};
          this.errors['lateFileWarningTime'] = {message: ''};
          this.isErrorMessage = false;
        }

      } else {
        this.errors['contractedDeliveryTime'] = {message: ''};
        this.errors['lateFileWarningTime'] = {message: ''};
        this.isErrorMessage = false;
      }
    }
   });
   this.form.get('contractedDeliveryTime').valueChanges.subscribe(contractval => {
    this.form.get('lateFileWarningTime').valueChanges.subscribe(lateval => {
       if(!contractval && !lateval){
        this.isErrorMessage = false
       }
    })
  });
  this.form.get('processingTime').valueChanges.subscribe(val => {
    if(val!=null){
    let processfield = this.form.get('processingTime').invalid
    if(val < 25){
      if(processfield && !this.isErrorMessage){
        this.errors['processingTime'] = {message: 'Decimals not allowed'};
        this.isErrorMessage = true;
      }else{
        this.errors['processingTime'] = {message: ''};
        this.isErrorMessage = false;
      }
    }
  }
  })
  this.form.get('processingTime').valueChanges.subscribe(val => {
    const lateFileTime = this.form.get('lateFileWarningAlertOutputFiles').value;
    let processingHour = val;
    let lateFileWarningOutputHour = lateFileTime;
    if (lateFileTime && !this.isErrorMessage) {
     if (lateFileWarningOutputHour >= processingHour && val) {
       this.errors['processingTime'] = {message: 'processingHour should be greaterthan lateFileWarningOutputHour '};
       this.errors['lateFileWarningAlertOutputFiles'] = {message: ''};
       this.isErrorMessage = true;
     }
      else {
       this.errors['processingTime'] = {message: ''};
       this.errors['lateFileWarningAlertOutputFiles'] = {message: ''};
       this.isErrorMessage = false;
     }
   }
  });

  this.form.get('lateFileWarningAlertOutputFiles').valueChanges.subscribe(val => {
    if (val != null){
    let lateFileWarningfield = this.form.get('lateFileWarningAlertOutputFiles').invalid
    if(val < 25){
      if(lateFileWarningfield && !this.isErrorMessage){
        this.errors['lateFileWarningAlertOutputFiles'] = {message: 'Decimals not allowed'};
        this.isErrorMessage = true;
      }else{
        this.errors['lateFileWarningAlertOutputFiles'] = {message: ''};
        this.isErrorMessage = false;
      }
    }
  }
  })

  this.form.get('lateFileWarningAlertOutputFiles').valueChanges.subscribe(val => {
    const processTime = this.form.get('processingTime').value;
    let processingHour = processTime;
    let lateFileWarningOutputHour = val;
    if (processTime  && !this.isErrorMessage) {
     if (lateFileWarningOutputHour >= processingHour && val) {
       this.errors['lateFileWarningAlertOutputFiles'] = {message: 'lateFileWarningOutputHour should be lesserthan processingTime '};
       this.errors['processingTime'] = {message: ''};
       this.isErrorMessage = true;
     }  else {
       this.errors['processingTime'] = {message: ''};
       this.errors['lateFileWarningAlertOutputFiles'] = {message: ''};
       this.isErrorMessage = false;
     }
   }
  });
  this.form.get('lateFileWarningAlertOutputFiles').valueChanges.subscribe(val => {
    const processTime = this.form.get('processingTime').value;
    let processingHour = processTime;
    let lateFileWarningOutputHour = val;
    if (processTime === 0  && !this.isErrorMessage) {
     if (lateFileWarningOutputHour >= processingHour && val) {
       this.errors['lateFileWarningAlertOutputFiles'] = {message: 'lateFileWarningOutputHour should be lesserthan processingTime '};
       this.errors['processingTime'] = {message: ''};
       this.isErrorMessage = true;
     }else{
      this.errors['lateFileWarningAlertOutputFiles'] = {message: ''};
      this.errors['processingTime'] = {message: ''};
      this.isErrorMessage = false;
     }
   }
  });

}

shwcontactBtn(copayObject){
  this.CopayOnboardingFormConfig.map(section => {
    section.map(formField => {
      if (formField.alertType) {
      this.copayService.getContact(copayObject.cltSetupID, formField.alertType).subscribe((contactData) => {
      if(contactData.response !== null){
        if(formField.controlName === 'missedFileSLAAlertInput'){
          this.missedFileSLAAlertInput = true
          this.ismissedFileViewContactInput = true
            this.missedfileShowAddContactInput = false
        }else if(formField.controlName === 'lateFileWarningAlertInput'){
          this.lateFileWarningAlertInput = true
          this.isLateFileViewContactInput = true
            this.latefileShowAddContactInput = false
        }
        else if(formField.controlName === 'missedFileSLAAlertOutput'){
          this.ismissedFileOutputYes === true
          this.missedFileSLAAlertOutput = true;
          this.ismissedFileViewContactOutput = true
            this.missedfileShowAddContactOutput = false
        }
        else if(formField.controlName === 'lateFileWarningAlertOutput'){
          this.isLateFileOutputYes =true
          this.lateFileWarningAlertOutput = true
          this.isLateFileViewContactOutput = true
            this.latefileShowAddContactOutput = false
        }
      }
    });
      }
    });
  });
}

}
