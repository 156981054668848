import { Component} from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";


@Component({
  selector: "app-select-renderer",
  template: `
      <mat-select [value]="value" [id]="rowId" [ngClass]="(readOnly$ | async)? 'showMatTrigger': ''"  [disabled]="(readOnly$ | async)"
      (selectionChange)="changeSpaceUsed($event, params.node.rowIndex)"> 
      
      <mat-option *ngFor="let i of [].constructor(80); let num = 'index+1'" [value]="num">
      {{num}}</mat-option>
      </mat-select>
  `,
})
export class GridSelectReportFormRendererComponent
  implements ICellRendererAngularComp
{
  public params: any;
  options: Array<any> = [];
  constructor() {}

  formControl;
  key;
  value;
  columnName: string;
  rowId: number;
  previousValue: any = null;
  component;
  readOnly;
  readOnly$;
  agInit(params: any) {
    this.params = params;
    this.columnName = params.colDef.headerName;
    this.key = params.colDef.field;
    this.options = params.options;
    this.value = params.getValue();
    this.component = params.context.componentParent
    this.readOnly = this.component.readOnly;
    this.readOnly$ = this.component.readOnly$;
  }
  changeSpaceUsed(event, index){
  
   this.component.changeSpaceUsed(event = event, index = index);
  }

 
  refresh(params: any): boolean {
    return false;
  }
  destroy() {

  }
}
