// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod-east.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  appName: 'Eligibility',
  auth: true,
  fetchssoeProcessingFiles: true,
  baseUrl: 'https://ewf-api-dev.mypbm.np.caremark.com',
  baseUrlSSOE: 'https://internal-qa1.pbm-np-ssoe.cvshealth.com',
  baseUrlSSOE_Ads: 'https://internal-qa1.pbm-np-ssoe.cvshealth.com',
  baseUrlSSOE_Beta: 'https://pbm-np-ssoe.cvshealth.com',
  ewf_ssoe_misc: 'https://ewf-ssmsc-dev.mypbm.np.caremark.com',
  ewf_processingFile: 'https://ewf-procf-dev.mypbm.np.caremark.com',
  ssoe_Ewf: 'ssoe-ewf-api',
  ssoe_Api: 'ssoe-api',
  ssoe_Client_Exp : 'client-experience',
  backendBaseUrl: 'https://ewf-api-dev.mypbm.np.caremark.com',
  clientId: 'ba1dc4fc-e08f-4fa3-9f6c-b872dece06f1',
  idle: 3000,
  issuer: 'https://ngamypbmnonprod.b2clogin.com/2a2762e7-d56f-47ab-a72a-df0102c4579a/v2.0/',
  localAuthToken: '',
  loginUrl: '/B2C_1A_signup_signin/oauth2/v2.0/authorize',
  logoutUrl: '/B2C_1A_signup_signin/oauth2/v2.0/logout',
  ssoBaseUrl: 'https://ngamypbmnonprod.b2clogin.com/ngamypbmnonprod.onmicrosoft.com',
  scope: 'openid offline_access',
  timeOut: 600,
  useAccessToken: false,
  region: ' - dev',
  baseProfileManagementUrl: 'https://ewf-profil-dev.mypbm.np.caremark.com',
  baseFiletrackerUrl: 'https://ewf-ftglb-dev.mypbm.np.caremark.com',
  baseCustomMappingUrl: 'https://ewf-mapper-dev.mypbm.np.caremark.com',
  cagRefUrl: 'https://ewf-cag-dev.mypbm.np.caremark.com',
  suspenseUrl: 'https://ewf-susp-dev.mypbm.np.caremark.com',
  processingTime: 6000000,
  rxClaimUrl: 'https://ewf-rxclm-dev.mypbm.np.caremark.com',
  authenticationEnabled: true,
  production: false,
  authIssuer: 'https://ngamypbmnonprod.b2clogin.com/2a2762e7-d56f-47ab-a72a-df0102c4579a/v2.0/',
  authorizationUrl: 'https://dev.api.mypbm-np.caremark.com/portal-api-dev/api/v1/auth/authorizedUser/ELG',
  tokenUrl: 'https://ngamypbmnonprod.b2clogin.com/ngamypbmnonprod.onmicrosoft.com/oauth2/v2.0/token?p=B2C_1A_signup_signin',
  myPBM: 'https://portal-web-dev.core.mypbm-np.caremark.com',
  accessDeniedMessage: 'You do not have permission to use this app please check with the Administrator of this tool',
  backendBaseUrlSSOEToken: 'https://ewf-ssoe-dev.mypbm.np.caremark.com/ssoe/getToken',
  appDynamicsKey: "AD-AAB-ACV-AFE",
  adobeAnalyticsUrl: "https://assets.adobedtm.com/99b399019383/0da3a52f37db/launch-b73104a81b43-development.min.js"
};

