<div class="row"  *ngIf="(permissionObject.addFtOnboarding && permissionObject.pbmRole)">
  <h1>File Tracker Profile</h1>
  <button
    id="edit-btn"
    class="pds-btn-primary"
    (click)="startEdit()"
    *ngIf="!isEdited && (isRegisteredUser$ | async) && isAdmin"
  >
    <mat-icon svgIcon="pencil--s"></mat-icon>
    Edit
  </button>
  <button
    id="cancel-edit-btn"
    class="pds-btn-primary"
    (click)="saveFileTracker()"
    *ngIf="isEdited"
    [disabled]="form.invalid"
  >
    <mat-icon>save</mat-icon>
    Save
  </button>
</div>

<form
  [formGroup]="form">
  <div class="grid">
    <div *ngFor="let section of filetrackerFormConfig; let i = index">
      <div class="inputs">
        <div *ngFor="let field of section; let k = index" [id]="k">
          <div [ngSwitch]="field.inputType" class="input-field" >

            <mat-grid-list cols="3" rowHeight="28px"  *ngSwitchCase="inputType.TEXT" class=" row text input" [ngClass]="field.heading ? 'heading' : ''">
              <mat-grid-tile>
              <app-label-link
                [hyperlink]="getHyperlink(field.controlName, hyperlinks)"
                [matTooltip]="field.title"
                matTooltipClass="tooltip-bg-color" matTooltipHideDelay="20"
                [labelText]="field.label">
              </app-label-link>
                  <span *ngIf="field.required" style="color: #CC0502">*</span>
              </mat-grid-tile>
              <mat-grid-tile>
              <input
                type="text"
                [id]="'input_' + k"
                [class]="field.controlName"
                [ngClass]="{'red-border-class': form.controls[field.controlName].touched && form.controls[field.controlName].errors?.required}"
                formControlName="{{field.controlName}}"
              >
              </mat-grid-tile>
              <mat-grid-tile class="error-message">
                <div *ngIf="form.controls[field.controlName].invalid && (form.controls[field.controlName].dirty || form.controls[field.controlName].touched)">
                  <span *ngIf="form.controls[field.controlName].errors?.required" style="color: #CC0502;height:300px; width:300px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis">{{field.errorMessage}}</span>
                </div>
                <div >
                  <span  *ngIf="errors[field.controlName]?.message" style="color: #CC0502;height:300px; width:300px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis">{{errors[field.controlName].message}}</span>
                </div>
              </mat-grid-tile>
            </mat-grid-list>
            <div class="container"  *ngIf="primaryUser">
            <mat-grid-list cols="3"  rowHeight="28px" *ngSwitchCase="inputType.HEADER" class="row plaintext" [ngClass]="field.heading ? 'heading' : ''">
              <ng-container  *ngIf="primaryUser">
              <mat-grid-tile  *ngIf="field.controlName === 'primaryUser'">
                <span>{{field.label}}</span>

              </mat-grid-tile>
              <mat-grid-tile class="priOrSecText" *ngIf="field.controlName === 'primaryUser'">
                <span>{{priUserName}}</span>
                <span >
                  <app-out-of-office-icon-renderer
                  *ngIf="isPriUserInactive"
                class="backup-ec-icon-filetracker"
                [fromDate]="priOooFromDate"
                [toDate]="priOooToDate"
                [fromTime]="priOooFromTime"
                [toTime]="priOooToTime"
                [isInactive]="isPriUserInactive"
                [placement]="'bottom'"
                >
              </app-out-of-office-icon-renderer>
                </span>
              </mat-grid-tile>
            </ng-container>
            <ng-container  *ngIf="secondaryUser" >
              <mat-grid-tile  *ngIf="field.controlName === 'secondaryUser'">
                <span>{{field.label}}</span>

              </mat-grid-tile>
              <mat-grid-tile class="priOrSecText" *ngIf="field.controlName === 'secondaryUser'">
                <span>{{secUserName}}</span>
                <span >
                  <app-out-of-office-icon-renderer
                      *ngIf="isSecUserInactive"
                    class="backup-ec-icon-filetracker"
                    [fromDate]="secOooFromDate"
                    [toDate]="secOooToDate"
                    [fromTime]="secOooFromTime"
                    [toTime]="secOooToTime"
                    [isInactive]="isSecUserInactive"
                    [placement]="'bottom'">
                  </app-out-of-office-icon-renderer>
                </span>
              </mat-grid-tile>
            </ng-container>
            </mat-grid-list>
          </div>
            <mat-grid-list cols="3" rowHeight="125px"  *ngSwitchCase="inputType.TEXT_AREA" class="row text text-area" [ngClass]="field.heading ? 'heading' : ''">
              <mat-grid-tile>
                <app-label-link
                  [hyperlink]="getHyperlink(field.controlName, hyperlinks)"
                  [matTooltip]="field.title"
                  matTooltipClass="tooltip-bg-color" matTooltipHideDelay="20"
                  [labelText]="field.label"
                ></app-label-link>
                <span *ngIf="field.required" style="color: #CC0502">*</span>
              </mat-grid-tile>
              <mat-grid-tile class="text-area-wt">
                <textarea
                  type="text"
                  [class]="field.controlName"
                  formControlName="{{field.controlName}}"
                ></textarea>
              </mat-grid-tile>
              <mat-grid-tile class="error-message">
                <span *ngIf="errors[field.controlName]?.message" style="color: #CC0502;height:300px; width:300px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis">{{errors[field.controlName].message}}</span>
              </mat-grid-tile>
            </mat-grid-list>
            <mat-grid-list cols="3" rowHeight="30px" *ngSwitchCase="inputType.DROPDOWN"
                           class="row dropdown input"
                           [ngClass]="field.heading ? 'heading' : ''">
              <mat-grid-tile>
              <app-label-link
                [matTooltip]="field.dropdownTitle"
                 matTooltipClass="tooltip-bg-color" matTooltipHideDelay="20"
                [hyperlink]="getHyperlink(field.controlName, hyperlinks)"
                [labelText]="field.label"
              ></app-label-link>
                <span *ngIf="field.required" style="color: #CC0502">*</span>
              </mat-grid-tile>
              <mat-grid-tile>
              <select [ngClass]="{'red-border-class': form.controls[field.controlName].touched && form.controls[field.controlName].errors?.required}" formControlName="{{field.controlName}}" (change)="dropValue(field.controlName)">
                <option *ngFor="let option of field.options" [value]="option.value">{{option.name}}</option>
                <span  *ngIf="errors[field.controlName]?.message" style="color: #CC0502">{{errors[field.controlName].message}}</span>
              </select>
              </mat-grid-tile>

              <mat-grid-tile class="error-message">
                <div *ngIf="form.controls[field.controlName].invalid && (form.controls[field.controlName].dirty || form.controls[field.controlName].touched)">
                  <span *ngIf="form.controls[field.controlName].errors?.required" style="color: #CC0502;height:300px; width:300px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis">{{field.errorMessage}}</span>
                </div>
                <div >
                  <div  *ngIf="errors[field.controlName]?.message" style="color: #CC0502;width:600px;white-space: initial;">{{errors[field.controlName].message}}</div>
                </div>
                <button   *ngIf="field.notes " (click)="addContacts(field.alertType)"
                        [id]="field.notes + '_' + k"
                        class=" {{field.controlName}}-notes"
                        >
                  Add Contact
                </button>
                <button class= "dummy_comp" [id]="'dummy_'+k"  (click)="noAction()"></button>
                <mat-checkbox  *ngIf="field.notes && field.checkBox" id="{{field.notes}}"
                              style="margin-left: 25px;"  class="checkbox"
                              color="primary"
                               [matTooltip]="field.title"
                              formControlName="{{field.notes}}">
                </mat-checkbox>
              </mat-grid-tile>
            </mat-grid-list>

            <div *ngSwitchCase="inputType.MDL_DROPDOWN" class="dropdown input"
                 [ngClass]="field.heading ? 'heading' : ''">
              <app-label-link
                [hyperlink]="getHyperlink(field.controlName, hyperlinks)"
                [labelText]="field.label"
              ></app-label-link>
              <select formControlName="{{field.controlName}}">
                <option *ngFor="let option of (field.mdlName | async)?.options"
                        [value]="option">{{option}}</option>
              </select>
            </div>

            <div *ngSwitchCase="inputType.FREEFORM_MULTISELECT" class="auto-complete multi-select input"
                 [ngClass]="field.heading ? 'heading' : ''">
              <app-label-link
                [hyperlink]="getHyperlink(field.controlName, hyperlinks)"
                [labelText]="field.label"
              ></app-label-link>
<!--              <button *ngIf="field.notes" (click)="addNote(i, field.notes)" [id]="field.notes" class="cvs-icon-only-btn"-->
<!--                      [disabled]="isANoteFieldFocused || (readOnly$ | async)">-->
<!--                <mat-icon>add_circle</mat-icon>-->
<!--              </button>-->
              <app-freeform-multiselect [formControlName]="field.controlName"
                                        [defaultOptions]="field.options"></app-freeform-multiselect>
            </div>

            <div *ngSwitchCase="inputType.MULTI_SELECT" class="multi-select input"
                 [ngClass]="field.heading ? 'heading' : ''">
              <app-label-link
                [hyperlink]="getHyperlink(field.controlName, hyperlinks)"
                [labelText]="field.label"
              ></app-label-link>
<!--              <button *ngIf="field.notes" (click)="addNote(i, field.notes)" [id]="field.notes" class="cvs-icon-only-btn"-->
<!--                      [disabled]="isANoteFieldFocused || (readOnly$ | async)">-->
<!--                <mat-icon>add_circle</mat-icon>-->
<!--              </button>-->
              <mat-select multiple formControlName="{{field.controlName}}">
                <mat-option *ngFor="let option of field.options" [value]="option">{{option}}</mat-option>
              </mat-select>
            </div>

            <div *ngSwitchCase="inputType.HORIZONTAL_FIELD_SET"
                 class="horizontal-field-set"
                 [ngClass]="field.heading ? 'heading' : ''">
              <app-label-link
                [hyperlink]="getHyperlink(field.subFormGroup[0].controlName, hyperlinks)"
                [labelText]="field.label"
              ></app-label-link>
              <div class="input-fields">
                <div *ngFor="let subForm of field.subFormGroup">
                  <ng-container [ngSwitch]="subForm.inputType">
                    <div *ngSwitchCase="inputType.TEXT" class="text input">
                      <input
                        type="text"
                        matInput
                        formControlName="{{subForm.controlName}}"
                      >
                    </div>

                    <div *ngSwitchCase="inputType.DROPDOWN" class="dropdown input">
                      <select formControlName="{{subForm.controlName}}">
                        <option *ngFor="let option of subForm.options" [value]="option">{{option}}</option>
                      </select>
                    </div>

                  </ng-container>
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>
    </div>
  </div>
</form>

