import { ThresholdTranslationsModel } from './../../../models/ThresholdTranslationsList';
import { ThresholdTranslationsService } from './../../../services/threshold-translations.service';
import { DeleteBtnCellRendererComponent } from './../../../common/DeleteBtnCellRenderer';
import { AgGridAngular } from 'ag-grid-angular';
import { CVSAlertService, CVSAlertType } from 'angular-component-library';
import { ThresholdService } from './../../../services/threshold.service';
import { Component, OnInit, AfterViewInit, ViewChild, Output, EventEmitter, HostListener } from '@angular/core';


export const thresholdGridColumns = [
  {
    headerName: 'Id', field: 'id', hide: true
  },
  {
    headerName: 'Input', field: 'input',
    editable: true,
    sortable: true,
    filter: true, lockVisible: true, lockPosition: true, resizable: true,
    width: 140
  },
  {
    headerName: 'Output', field: 'output',
    editable: true,
    sortable: true, filter: true, lockVisible: true, lockPosition: true, resizable: true,
    width: 140
  },
  {
    headerName: 'Type', field: 'type',
    editable: true,
    sortable: true, filter: true, lockVisible: true, lockPosition: true, resizable: true,
    width: 140
  },
  {
    headerName: 'Message', field: 'message',
    editable: true,
    sortable: true, filter: true, lockVisible: true, lockPosition: true, resizable: true,
    width: 140
  },
  {
    headerName: 'Action',
    cellRenderer:  DeleteBtnCellRendererComponent,
    editable: false,
    width: 90
  }
];


@Component({
  selector: 'app-thresholds-data-grid',
  templateUrl: './thresholds-data-grid.component.html',
  styleUrls: ['./thresholds-data-grid.component.scss']
})
export class ThresholdsDataGridComponent implements OnInit, AfterViewInit {
  @ViewChild('thresholdGrid') thresholdGrid: AgGridAngular;
  @Output() isSaveCalled: EventEmitter<any> = new EventEmitter();



  dataRows = [];
  rowsTobeDeleted = [];
  rowsTobeUpdated = [];
  sequenceColumnIDs = [1];
  payloadRequest = [];
  hlGridHeight = 350;
  contextObj: any;

  readonly: boolean;

  selectedStatus = '';

  translationAddMessage: any;
  translationEditMessage: any;
  translationDeleteMessage: any;
  changedRow: any;

  constructor(private thresholdService: ThresholdTranslationsService, private alertService: CVSAlertService) {

    this.contextObj = {
      componentParent: this
    };

  }

  ngOnInit() {
    this.getAllTranslations();
    this.hlGridHeight = 350;
    localStorage.setItem('deactivate-threshold', '0');
  }

  ngAfterViewInit(): void {
    setTimeout(() => { this.addNewRow(); this.saveUnSavedRecords();
                       this.alertService.clearAlert('#threshold-alerts');
    }, 500);

  }
  saveUnSavedRecords() {

    this.thresholdService.saveNewRecords$.subscribe(resp => {
      const updatedRec = [];

      if (resp) {
        this.thresholdGrid.api.forEachNode(node => {
          if (node.data.isUpdated || node.data.isNew) {
            delete node.data.isUpdated;
            delete node.data.isNew;
            updatedRec.push(node.data);
          }
        });

        this.thresholdService.saveThresholdTranslationRow(updatedRec).subscribe(afterSave => {
        this.readonly = false;
        localStorage.setItem('deactivate-threshold', '0');
        this.alertService.sendAlert({
            headline: 'Save successful!',
            alertType: CVSAlertType.Success,
            removedAfterMilliseconds: 5000,
            alertDocumentHolderName: '#threshold-alerts',
          });
        this.getAllTranslations();
          // this.store.dispatch(new LoadMasterDataSetAction('holidays'));
        });
      }
    });

  }
  addNewRow() {
    this.thresholdService.onMessage().subscribe(newRow => {

      if (newRow != null) {
        this.thresholdGrid.api.applyTransaction({
          addIndex: 0,
          add: newRow
        });
        this.thresholdGrid.api.setFocusedCell(0, 'id');

        this.hlGridHeight = (this.thresholdGrid.api.getDisplayedRowCount() * 42) + 52;
      }
    });
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    if (this.readonly) {

      if (confirm('Changes have NOT been saved! Click \'OK\' to exit WITHOUT saving.')) {
        return true;
      } else {
        return false;
      }
    }
  }
  getAllTranslations() {

    this.thresholdService.getAllThresholdTranslations().subscribe(res => {
      this.dataRows = res;
      this.payloadRequest = [];
      this.dataRows.sort((a, b) => a.id - b.id);
      localStorage.setItem('deactivate-threshold', '0');
    });
    this.changedRow = false;
  }
  showAlert(alertMessage, alertType) {
    this.alertService.sendAlert({
      headline: alertMessage,
      alertType,
      removedAfterMilliseconds: 5000,
      alertDocumentHolderName: '#threshold-alerts',
    });
  }
  onGridReady(params) {
    this.thresholdGrid = params;
    this.hlGridHeight = this.dataRows.length * 51;
    this.thresholdGrid.api.setGridOption("columnDefs", thresholdGridColumns);
    this.thresholdGrid.api.redrawRows();
  }
  thresholdRecUpdated(cell) {
    cell.data.isUpdated = true;
    this.readonly = true;
    localStorage.setItem('deactivate-threshold', '1');
  }
  emitSave() {
    this.isSaveCalled.emit();
  }
  public deleteTableRecord(node, colId) {
    if (node.data.isNew) {
    localStorage.setItem('deactivate-threshold', '0');
    this.readonly = false;
    this.thresholdGrid.api.applyTransaction({remove:[node.data]});
    this.hlGridHeight = (this.thresholdGrid.api.getDisplayedRowCount() * 42) + 52;
    } else {
      this.thresholdService.deleteThresholdTranslationRow([{ ...node.data }]).subscribe(resp => {
        if (resp > 0) {
          this.thresholdGrid.api.applyTransaction({remove:[node.data]});
          this.hlGridHeight = (this.thresholdGrid.api.getDisplayedRowCount() * 42) + 52;
        }
        this.getAllTranslations();
      });
    }
  }
}
