<div class="modal-backdrop">
  <div class="modal">
    <div class="modal-header">
      <h5 class="modal-title">Destination Name</h5>
      <button type="button" class="close-btn" aria-label="Close" (click)=closeModal($event)>X</button>
    </div>
    <div class="modal-body">
        <p id="instructions">
          Select and drag pills to determine naming convention and order.
          <span id="hint">Note: periods and extensions are implied. Changing naming convention may impact existing COLA processes. Be sure to verify compatibility before changing existing setups.</span>
        </p>
        <div  id="pillBox">
          <div #container id="pillList">
              <mat-basic-chip class="mat-mdc-chip mat-mdc-basic-chip" *ngFor="let pill of pills" (click)="selectPill(pill)" 
                cdkDrag
                (cdkDragMoved)="dragMoved($event)"
                (cdkDragStarted)="dragStarted($event)"
                (cdkDragEnded)="dragEnded($event)"
                [class.animation]="enableTransition"
                [class.selected] = "pill.selected"
                >{{pill.text}}
              </mat-basic-chip>
          </div>
          <div id="otherLabel" *ngIf="isOtherSelected">
            <mat-label>Other*</mat-label>
            <input matInput type="text" (keydown)="checkText($event)" [(ngModel)]="otherValue" (ngModelChange)="setPreview(true)">
            <div id="inputBottom"></div>
            <mat-hint>No Spaces or Special Characters</mat-hint>
          </div>
          <div id="preview">
            <p><strong>Preview:</strong> {{preview}} </p>
           </div>
        </div>
       
      <div class="modal-footer">
        <button type="button" class= "btn-cancel pds-btn-white"(click)="onCancel($event)">Cancel</button>
        <button type="button"  class="pds-btn-primary" (click)="onSave($event)" [disabled]="saveButtonDisable">Save</button>
      </div>
    </div>
</div>
