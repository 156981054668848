<div [ngClass]="currentUserId ? 'dashboard-body-container' : ''">
  <div id="right-panel">
    <div class="dashboard-heading">
      <div style="display: flex; align-items: center; height: 36px">
        <h1 id="right-pane-header">Filetracker</h1>
        <mat-form-field id="filter-text-box" style="margin-left: 16px">
          <input matInput type="text" placeholder="Search" (input)="filterTables($event.target.value)" />
          <mat-icon matSuffix>search</mat-icon>
        </mat-form-field>
      </div>
      <div style="display: flex !important; ">

      <div id="dashboard-dropdown" *ngIf="(isRegisteredUser$ | async) && dashboardRole" class="dashboard-dropdownalign matformfieldAlign" style="margin-right: 30px;">
        <mat-form-field>
          <mat-select  [(value)]="selectedDashboardOption" (selectionChange)="dashboardOption()">
            <mat-option *ngFor="let viewOption of dashboardOptions" [value]="viewOption">
              <b>{{viewOption}}</b>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div>
        <button *ngIf="(isRegisteredUser$ | async) && isAdmin" id="createNewFiletracker" class="pds-btn-primary"
          (click)="createNewSpedm()">
          <mat-icon svgIcon="folder--s"></mat-icon>
          New Entry
        </button>
      </div>
      </div>
    </div>
    <cvs-alert-placeholder id="speedm-dashboard"></cvs-alert-placeholder>
    <div class="d-flex" >
      <h2 style="justify-content: flex-start;">OnBoarding</h2>
      <div style="justify-content: flex-end;">
        <span  style="display:inline-flex" class=dashboard-hdr >
          <span  *ngIf="allowOnboardingDelete && spedmAdmin"
          cvsDialog
          id="modalConfirmation"

          customPanelClass="example-panel-class"
          headline="Please Confirm"
          body="Are you sure you want to delete the selected profiles?"
          actionLabel="Yes"
          cancelLabel="No"
          [noCloseX]="false"
          (onCancelClick)="deleteclientonboarding(false)"
          (onConfirmClick)="deleteclientonboarding(true)"
          (onBackdropClick)="deleteclientonboarding(false)">

              <mat-icon svgIcon="trash--s" class="refresh-btn" id="tableOneRefreshProcessorBtn" style="cursor:pointer;"></mat-icon>
          <span class="export-txt"> Delete </span>
        </span>
        <ng-container *ngIf="isAdmin">
          <span style="cursor:pointer;" (click)="refreshFiles('tableOne')">
            <mat-icon svgIcon="refresh--s" class="refresh-btn" id="tableOneRefreshProcessorBtn">
        Refresh
       </mat-icon>
        <span class="export-txt"> Refresh </span>
      </span>
       <span *ngIf="currentDateGrid1 "><span class="dashboard-text">Last on </span>{{currentDateGrid1 | date:' h:mm:ss'}}</span>
      
      <span style="cursor:pointer;"  (click)="exportView('tableOne')">
  <mat-icon
    id="tableOneExportViewBtn"
    svgIcon="upload-arrow--s"   class="refresh-btn">
    Export View
  </mat-icon>
  <span class="export-txt"> Export </span>
</span>
</ng-container>
</span>
</div>
    </div>
    <div id="dashboard-table-one" class="dashboard-table">
      <ag-grid-angular
        class="ag-theme-alpine"
        #grid1
        (dragStopped)="onDragStopped('tableOne')"
        (sortChanged)="onSortChange('tableOne')"
        (filterChanged)="onFilterChange('tableOne')"
        (cellClicked)="oncellCLicked($event)"
        [gridOptions] = "gridOptions"
      >
      </ag-grid-angular>
    </div>
    <div class="d-flex" style="padding: 10px;">
    <h2 style="padding: 10px;">File Monitor</h2>
    <div  style="justify-content: flex-end;">
      <span style="display:inline-flex" class=dashboard-hdr >
        <span class="slide-toggle">
          <mat-slide-toggle [checked]="islatest" (change)="onChange($event)" *ngIf="islatest">
            <span  >Latest 7 Days</span>
          </mat-slide-toggle>
          <mat-slide-toggle [checked]="islatest" (change)="onChange($event)" *ngIf="!islatest">
            Older Than 7 Days
          </mat-slide-toggle>
          </span>
        <span >
          <span style="cursor:pointer;" (click)="refreshFiles('tableTwo')">
          <mat-icon svgIcon="refresh--s" class="refresh-btn" id="tableTwoRefreshProcessorBtn"   >
      Refresh
     </mat-icon>
      <span class="export-txt"> Refresh </span>
    </span>
     <span *ngIf="currentDateGrid2"><span class="dashboard-text">Last on </span>{{currentDateGrid2 | date:' h:mm:ss'}}</span>
    </span>
    <span style="cursor:pointer;" (click)="exportView('tableTwo')">
<mat-icon
  id="tableTwoExportViewBtn"
   svgIcon="upload-arrow--s"   class="refresh-btn">
  Export View
</mat-icon>
<span class="export-txt"> Export </span>
</span>
</span>
</div>
    </div>
    <div id="dashboard-table-two" class="dashboard-table">
      <ag-grid-angular
        class="ag-theme-alpine"
        #grid2
        (rowClicked)='onRowClicked_Table2($event)'
        (dragStopped)="onDragStopped('tableTwo')"
        (sortChanged)="onSortChange('tableTwo')"
        (filterChanged)="onFilterChange('tableTwo')"
      >
      </ag-grid-angular>
</div>
  </div>
</div>
