import {AfterViewInit, Component, Inject, OnInit, ViewChild} from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import {AlertType} from '../../models/AlertType';
import {AgGridAngular} from 'ag-grid-angular';
import * as fromRoot from '../../reducers';
import {Observable} from 'rxjs';
import {Store} from '@ngrx/store';
import {Contact} from '../../models/Contact';
import {LoadContactAction} from '../../actions/filetracker.action';
import {DeleteBtnCellRendererComponent} from '../../common/DeleteBtnCellRenderer';
import {SpedmtrackerColumns} from './spedm-contact-columnSets';
import {SpedmService} from '../../services/spedm.service';
import {FiletrackerService} from '../../services/filetracker.service';
import {CellEditingStartedEvent} from "ag-grid-community";
import {CVSAlertService, CVSAlertType} from "angular-component-library";
export interface ConfirmationDialogData {
  setupId: any;
  alertField: any;
  type: AlertType;
  isEdited: boolean;
}

@Component({
  selector: 'app-spedm-contact-dialog',
  templateUrl: './spedm-contact-dialog.component.html',
  styleUrls: ['./spedm-contact-dialog.component.scss']
})
export class SpedmContactDialogComponent implements AfterViewInit {

  tooltipShowDelay: any;
  rowSelection: any;
  @ViewChild('spedmtrackerContact') spedmtrackerContact: AgGridAngular;
  isLoading = false;
  contextObj: any;
  contacts$ = this.store.select(fromRoot.getContact) as Observable<Contact[]>;
  savedisabled: boolean = true;
  defaultColDef = {
    flex: 0,
    minWidth: 130,
    editable: true,
    resizable: false
  };
  constructor(@Inject(MAT_DIALOG_DATA) public data: ConfirmationDialogData,private alertService: CVSAlertService,
              public dialogRef: MatDialogRef<ConfirmationDialogData>,
              private store: Store<fromRoot.AppState>,
              private spedmService: SpedmService,
              private filetrackerService: FiletrackerService
  ) {
    this.contextObj = {
      componentParent: this,
      name:"SpedmContactDialogComponent"
    };
  }
  contactData: any;

  ngAfterViewInit() {

    // this.store.dispatch(new LoadContactAction({id: this.data.setupId, alertType: this.data.alertField}));
    this.isLoading = true;

    // @ts-ignore
    this.spedmtrackerContact.api.setGridOption("columnDefs", SpedmtrackerColumns);
    // this.contacts$.subscribe((contactData) => {
    //   this.contactData = contactData;
    //   this.spedmtrackerContact.api.setGridOption('rowData',this.contactData);
    // });
   this.getcontact();
   }
  public deleteSpedmTracker(node) {
    if(node.data.emailId){
      this.spedmService.deleteContact(node.data.emailId).subscribe(resp => {
        if (resp > 0) {
          this.spedmtrackerContact.api.applyTransaction({remove:[node.data]});
        }
      });
    }else{
      this.spedmtrackerContact.api.applyTransaction({remove:[node.data]});
      this.getcontact()
    }
   
  }

  getcontact(){
    this.filetrackerService.getContact(this.data.setupId,  this.data.alertField).subscribe((contactData) => {
      this.contactData = contactData;
      this.spedmtrackerContact.api.setGridOption("rowData", this.contactData);
    });
  }
  closeHandler() {
    this.contactData = [];
    this.dialogRef.close();
  }

  onCellClicked($event: any) {
    if($event.colDef.headerName === 'Action'){
      this.deleteSpedmTracker($event.node)
    }else{
      return
    }
  }

  onDragStopped() {

  }

  onSortChange() {

  }

  onFilterChange() {

  }

  onCellEditingStarted(event: CellEditingStartedEvent) {
  if(event.data.statusCd == 'Active' || 'Inactive'){
    this.savedisabled = false;
  }
  }

  save() {
    this.spedmtrackerContact.api.stopEditing();
   this.spedmService.editContact(this.contactData, this.data.alertField)
       .subscribe(resp => {
        this.getcontact();
        this.savedisabled = true;
         this.showMessage('Added Contact Successfully', 'Success');
     });
  }
  cancel() {
    this.contactData = [];
    this.dialogRef.close();
  }
  add() {
    const contact = new Contact();
    contact.contactId = 'save';
    contact.setupId = this.data.setupId;
    contact.emailId = '';
    contact.alertTypeCd = 'Email';
    contact.cmnctTypeCd = 'Internal';
    contact.statusCd = 'Active'
    this.contactData.push(contact);
    this.spedmtrackerContact.api.setGridOption("rowData", this.contactData);
  }

  showMessage(message, type) {
    this.alertService.sendAlert({
      // headline: message ? message : 'Save successful !',
      headline: message,
      alertType: type === 'error' ? CVSAlertType.Error : CVSAlertType.Success,
      removedAfterMilliseconds: 3000,
      alertDocumentHolderName: '#file-layout',
    });
  }
}
