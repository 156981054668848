<div class="divider"></div>

<div class="grid-header">
    Suspense Report Result
</div>

<div class="grid-btn-container">
    <button class="pds-btn-text-only" [ngClass]="{'btn-disabled':!isRowSelected}" (click)="deleteRecords()">
        <mat-icon svgIcon="trash--s"></mat-icon>
        Delete Selected Record(s)
    </button>
    <button class="pds-btn-text-only" (click)="exportFile()">
        <mat-icon svgIcon="file-csv--s"></mat-icon>
        Export
    </button>
</div>

<ag-grid-angular class="ag-theme-alpine" (gridReady)="onGridReady($event)" [gridOptions]="gridOptions" [rowData]="gridRowData" 
(selectionChanged)="onSelectionChanged($event)" [columnDefs]="columnDefs">
</ag-grid-angular>
<!-- 
            (cellValueChanged)="updateModificationData($event)"
 -->