/* eslint-disable max-len */
import { Component, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { AppState, getIsRegisteredUser, getPermissionsDashboardObject,
   getPermissionsProcessingFilesObject, getPermissionsFiletrackerObject } from '../reducers';
import { Store } from '@ngrx/store';
import { ClearEprAction, LoadEprAction } from '../actions/epr.action';
import { Router } from '@angular/router';
import { EprService } from '../services/epr.service';
import { Observable, of } from 'rxjs';
import { UntypedFormControl } from '@angular/forms';
import { UsersService } from '../services/users.service';
import * as ColumnSets from './dashboardColumnSets';
import { masterEprColumns } from './dashboardColumnSets';
import { Permission } from '../models/Authorization';
import { CookieService } from 'ngx-cookie-service';
import { saveAs } from 'file-saver';
import { EprFeedService } from '../services/eprfeed.service';
import { DatePipe } from '@angular/common';
import { EprFeed } from '../models/EprFeed';
import { CellRendererComponent } from '../admin/extract-epr-feed-gui/cell-renderer.component';
import { GlobalSubjectService } from '../services/globalsubject.service';
import { TranslationsService } from '../services/translations.service';
import { StatusPopoverComponent } from './status-popover/status-popover.component';
import { ThreasholdDialogComponent } from '../threshold-dialog/threshold-dialog.component';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { orderBy } from 'lodash';
import { FiletrackerService } from "../services/filetracker.service";
import { MatLegacySlideToggleChange as MatSlideToggleChange } from "@angular/material/legacy-slide-toggle";
import { SpedmService } from "../services/spedm.service";
import { LoadFiletrackerAction } from "../actions/filetracker.action";
import { EligibilityDialogComponent } from "../eligibility-dialog/eligibility-dialog.component";
import { LoadSpedmAction } from "../actions/spedm.action";
import { CagRefBatchUploadComponent } from './cagRefBatchUpload/cagRefBatchUpload.component';
import { catchError, switchMap } from 'rxjs/operators';
import { CVSAlertModule, CVSAlertService, CVSAlertType } from 'angular-component-library';
import { InfoDialogComponent } from '../epr/info-dialogue/info-dialog.component';
import { DashboardObject, DashboardViews, ProcessingFileDB } from '../models/PermissionsObject';
import { EligibilityAgreementComponent } from '../epr/eligibility-agreement/eligibility-agreement.component';
import * as fromRoot from '../reducers';
import { GridApi, RowClassRules } from '@ag-grid-community/core';
import { AgGridAngular } from 'ag-grid-angular';

const BACKUP_TASKS = ['Processing Files', '1/1 Projects', 'Projects', 'EPR\'s'];


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {

  @ViewChildren(AgGridAngular) agGrids: QueryList<AgGridAngular>;;

  gridAPis:GridApi[]=[]
  @ViewChild('sideNav') sideNav;
  @ViewChild('tooltip') tooltip;
  private filterGrids = [];
  private processingFilesModalOpen = false;
  moreDataErrorGrid1: string;
  dialogConfig:any = new MatDialogConfig();
  isRegisteredUser$ = this.store.select(getIsRegisteredUser) as Observable<boolean>;
  dashboardObject$ = this.store.select(getPermissionsDashboardObject)
  viewOptions = ['EPR\'s', 'Projects',
    '1/1 Projects', 'Testing', 'QC/Audit', 'Integrated Feeds', 'Open Enrollment', 'EPR Master', 'Processing Files',
    'File Mappings'];
  tableOneFormControl: UntypedFormControl;
  isAdmin = false;
  filterObject: any = [];
  columnId: any;
  profileupdateDialogData: any;
  epr: any;
  includeBackups = false;
  includeBackupItems: string[] = [];
  message: string;
  processingFileRefreshDate: Date;
  userId: any;
  defaultMyprocessingFilter = { g5: { filterType: 'text', type: 'notContains', filter: 'Complete' } };

  eprId: any;
  eprfeed: EprFeed;
  testingEntities: any;
  translationsRecords: any;
  plisList: any = [];
  pliAndEcNames: any;
  wfGreenCount = 0;
  wfYellowCount = 0;
  wfRedCount = 0;
  wfBlackCount = 0;

  qcGreenCount = 0;
  qcDueSoonCount = 0;
  qcPastDueCount = 0;

  pfErrorCount = 0;
  pfProcessingCount = 0;
  pfPendingCount = 0;

  eprBatchFileCount = 0;
  eprManualCount = 0;
  eprRealTimeCount = 0;
  eprNotDefinedCount = 0;
  eprTermedCount = 0;

  eprMasterTotalCount = 0;
  eprMasterBatchFileCount = 0;
  eprMasterManualCount = 0;
  eprMasterRealTimeCount = 0;
  eprMasterNotDefinedCount = 0;
  eprMasterTermedCount = 0;

  tbaExceeded = 0;
  tbaComplete = 0;

  sortConfig: any;
  role: Permission;
  isInSwappingMode: boolean = false;
  recentlyViewedEprs: any;
  globalFilterText = '';
  defaultPF: boolean;
  loggedInUserName: any;
  userLdapID: any;
  showCagNote: boolean;
  clickButton: boolean;
  processingFilesCount: Number = 0;
  openedAccord: any = [];
  // isAds: boolean = true;
  db = {processingFiles:ProcessingFileDB.ADS}
  PFLoaded: boolean;
  PWLoaded: boolean;
  processingfilesFilter: any;
  processRefresh: boolean;
  gridColumnsList: any = []
  PreferencepayLoad: { userId: any; orderOfColumn: string; columnWidth: string; dashboardComponent: string; userFilter: any }[];
  changesDone: boolean = false;
  allPreferenceList: any = []
  updatePreference: boolean = true;
  eprMasterFilter: any = null;
  IntFeeds_Overdue: number = 0;
  IntFeeds_AtRisk: number = 0;
  IntFeeds_SetupNeeded: number = 0;
  IntFeeds_Completed: number = 0;
  isRowClicked: boolean;
  readOnly$ = this.store.select(fromRoot.getReadOnly) as Observable<boolean>;
  pfLoading: boolean;
  popupParent: HTMLElement | null = document.querySelector("body");

  constructor(private store: Store<AppState>, private router: Router, private eprService: EprService,
    private globalSubjectService: GlobalSubjectService,
    private usersService: UsersService,
    private spedmService: SpedmService, private cookieService: CookieService, public dialog: MatDialog,
    private eprFeedService: EprFeedService, public datepipe: DatePipe,
    private translationsService: TranslationsService,
    private fileTrackerService: FiletrackerService,
    public dialogRef: MatDialogRef<EligibilityAgreementComponent>,
    private alertService: CVSAlertService) {
      this.eprService.includeBackup = this.includeBackups;
  }
  /////////////////////////////////////////////////// New work
  dashboardObject:DashboardObject
  dashboardViews: DashboardViews[]
  // Permission.FILETRACKER
  islatestEligibility = false;
  islatestSpedm = false;
  isAnyExpanded = false;
  editCagRefManual: boolean = false;
  addCagRefManual: boolean = false;
  disableEditButton1: boolean = true;
  SI_MapperRole: boolean = false
  processFilespermissionObject$ = this.store.select(getPermissionsProcessingFilesObject)
  processFilespermissionObject:any
  fileTrackerpermissionObject$ = this.store.select(getPermissionsFiletrackerObject) as Observable<Object>;
  fileTrackerpermissionObject:any;
  filterTable = false;
  getIsAnyExpanded(title = null) {
    this.isAnyExpanded = document.getElementsByClassName('mat-expanded').length > 0;
    const found = this.dashboardViews.find(x => x.title === title);
    if (found && !found.gridOptions?.rowData?.length) {
      if (title === 'EPR Master') {
        this.gridAPis[title].setGridOption("loading", true);
        this.loadEprMasterData(true);

      } else if (title === 'CAG REF (Manual)') {
         this.gridAPis[title].setGridOption("loading", true);
        this.loadCagRefManualData(true);
      }
      // else if(title === 'CAG REF (Batch)') {
      //   console.log('!!!!!!!!!!!!', found);

      //   // found.gridOptions.api.showLoadingOverlay();
      //   this.loadCagRefBatchData(true);
      // }
    }
  }
  accordStatus(title, remove?) {
    if (remove) {
      this.openedAccord = this.openedAccord.filter(data => data !== title);
    } else {
      this.openedAccord.includes(title) ? null : this.openedAccord.push(title);
    }
  }

  accordionClosed(title) {
    return !this.openedAccord.includes(title);
  }

  onToggleChange($event: MatSlideToggleChange, title: string) {
    if (title == 'FT File Monitor - Eligibility') {
      this.islatestEligibility = $event.checked;
      this.loadFTFileMonitorEligibilityData(true);
    } else {
      this.islatestSpedm = $event.checked;
      this.loadFTFileMonitorSpedmData(true);
    }
  }
  loadSortConfig() {
      this.eprService.getDashboard(this.userId).subscribe((sortConfig: any) => {
        this.sortConfig = sortConfig;
        this.sortConfig.forEach(e => {
          const found = this.dashboardViews.find(x => x.title == e.viewNm);
          if (found) {
            found.seq = e.seqNo;
          }
        });
        this.loadData()
    })
  }

  get allLoaded(): boolean {
    if (!this.role) {
      return false;
    }
    return !this.dashboardViews.find(x => (x.roles.length === 0 || x.roles.includes(this.role)) && !x.isLoaded)
  }

  ngOnInit() {
    this.eprService.dashboardRowClicked.subscribe(res=>{
      this.isRowClicked = res;
    });
    this.getTranslationsRecords();
    this.dashboardObject$.subscribe(res=>{
      this.dashboardObject = res
    })
    this.dashboardViews = this.dashboardObject?.dashboardViews
      this.usersService.userRole.subscribe(role => {
        if (role) {
          this.role = role;
          this.fileTrackerService.role = this.role;
          if (this.cookieService.check('ewf-table-one-selection') && this.cookieService.check('ewf-table-two-selection')) {
            this.tableOneFormControl = new UntypedFormControl(this.cookieService.get('ewf-table-one-selection'));
            if (!role || role === Permission.VISITOR ) {
              this.tableOneFormControl.disable();
            }
          } else {
            this.setDefaultSelectFormControlsBasedOnRole(role);
          }


            if ([Permission.ADMIN, Permission.MANAGER,
              Permission.EC, Permission.PROCESSOR,
              Permission.LEADERSHIP, Permission.ERM, Permission.TESTER_AUDITOR,
              Permission.INTFEED].includes(role)) {
                this.isAdmin = true;
              } else {
                this.isAdmin = false;
              }
              if ([Permission.SI_MAPPER].includes(role)) {
                this.SI_MapperRole = true
              }

            if (this.cookieService.check('ewf-table-one-selection') && this.cookieService.check('ewf-table-two-selection')) {
              this.tableOneFormControl = new UntypedFormControl(this.cookieService.get('ewf-table-one-selection'));}
              this.setDefaultSelectFormControlsBasedOnRole("" as any)
          }
      });

    this.usersService.user.subscribe(resp => {
      //this.router.isActive('/home', true) is there to limit calls to only when on /home
      if (resp && this.router.isActive('/home', true)) {
        this.userId = resp.id;
        this.userLdapID = resp.ldapId;
        this.fileTrackerService.userId = this.userId;

        this.getPlis();
        this.globalSubjectService.userId.next(resp);
          this.loadSortConfig()
        this.getUserPreferences()
    if (this.cookieService.check('columnList')) {
      const savedPreferencelist = JSON.parse(this.cookieService.get('columnList'))
      this.saveUserPreferences(savedPreferencelist)
      this.getUserPreferences()
    }
      }
    });

    this.eprService.getAllPliAndEcNames().subscribe(res => {
      this.pliAndEcNames = res;
    });

    this.usersService.userFullName.subscribe(resp => {
      this.loggedInUserName = resp;
    })

    if (this.cookieService.check('ewf-eprmaster-filter-model')) {
      this.eprMasterFilter = JSON.parse(this.cookieService.get('ewf-eprmaster-filter-model'));
    } else {
      this.gridColumnsList.find(rec => {
        if (rec['dashboardComponent'] === 'EPR Master') {
          this.eprMasterFilter = rec['userFilter']
        }
        else {
          this.eprMasterFilter = null
        }
      })
    }
    this.processFilespermissionObject$.subscribe(res=>{
      this.processFilespermissionObject = res
    });
    this.fileTrackerpermissionObject$.subscribe(res=>{
      this.fileTrackerpermissionObject = res
    });

    this.usersService.betaTogglePermission = this.processFilespermissionObject.betaToggle
  }

  private getPlis() {
    this.usersService.getPlisByUserId(this.userId).subscribe(res => {
      if(res){
        this.plisList = res;
        this.eprService.usersPliList.next(res);
      }
    });

  }


  getRecentlyViewedEprs() {
    if (this.userId) {
      this.usersService.getAllRecentlyViewedEprs(this.userId).subscribe(res => {
        if (res) {
          this.recentlyViewedEprs = res;
          this.sideNav.toggle();
        }
      });
    }
  }

  loadData() {

      this.dashboardViews.forEach((d) => {
          switch (d.title) {
            case 'EPR\'s':
              this.loadEprData();
              break;
            case 'EPR Master':
              //this.loadEprMasterData();
              this.loadDashboardViewsArray("11", "EPR Master", [], [],
                ColumnSets.masterEprColumns, this.eprMasterFilter, null, null, null);
              break;
            case 'Integrated Feeds':
              this.loadIntegratedFeedsData();
              break;
            case 'QC/Audit':
              this.loadQCAuditData();

              break;
            case 'Testing':
              this.loadTestingData();
              break;
            case 'Open Enrollment':
              this.loadOpenEnrollmentData();
              break;
            case 'Projects':
              this.loadProjectsData();
              break;
            case '1/1 Projects':
              this.loadOneOneProjectsData();
              break;
            case 'Processing Files':
              this.loadProcessingFilesData();
              break;
            case 'File Mappings':
              this.loadFileMappingData();
              break;
            case 'FT Onboarding - Eligibility':
              this.loadFTOnboardingEligibilityData();
              break;
            case 'FT File Monitor - Eligibility':
              this.loadFTFileMonitorEligibilityData();
              break;
            case 'FT Onboarding - Spedm':
              this.loadFTOnboardingSpedmData();
              break;
            case 'FT File Monitor - Spedm':
              this.loadFTFileMonitorSpedmData();
              break;
            case 'CAG REF (Manual)':
               this.loadCagRefManualData();
              this.loadDashboardViewsArray("14", 'CAG REF (Manual)', [], [],
                 this.checkColumnPermissions(), null, null, null, null);
              break;
            case 'CAG REF (Batch)':
              this.loadCagRefBatchData();
              // this.loadDashboardViewsArray("15", 'CAG REF (Batch)', [], [],
              // ColumnSets.cagRefBatch, null, null, null, null);
              break;
            case 'Term By Absence':
              this.loadTBA();
              break;
          }

      });

  }
  populateQcFocusNumbers(event = null) {
    let grid = this.dashboardViews.find(data => data.id == '6');

    if (event) {
      event.api.forEachNodeAfterFilter(node => {
        if (node.data.qcPrjStatus === 'Green') this.qcGreenCount++
        if (node.data.qcPrjStatus === 'Red') this.qcPastDueCount++
        if (node.data.qcPrjStatus === 'Yellow') this.qcDueSoonCount++
      });


    } else {

      grid.rowData.response.forEach(
        node => {

          if (node.qcPrjStatus === 'Green') this.qcGreenCount++
          if (node.qcPrjStatus === 'Red') this.qcPastDueCount++
          if (node.qcPrjStatus === 'Yellow') this.qcDueSoonCount++

        }
      )


    }


  }




  checkColumnPermissions(){
    //Created to remove checkboxes if not edit Button permission
    if(this.dashboardObject.buttons.EDIT_CAGMANUAL){
      return ColumnSets.cagRef
    }else{
      let columnSet = ColumnSets.cagRef.filter(col =>{ return col.field !== 'cagRefManualCheck'})

      return columnSet
  }}

  drop(event: CdkDragDrop<DashboardViews[]>) {
    if (event.currentIndex == event.previousIndex) {
      return;
    }
    const sortedArray = orderBy(event.container.data, 'seq', 'asc');
    if (event.currentIndex < event.previousIndex) {
      let inc = 1;
      sortedArray.forEach((item, i) => {
        if (item.roles.length > 0 && !item.roles.includes(this.role)) {
          inc += 1;
        }
        if (i >= event.currentIndex && i < event.previousIndex) {
          sortedArray[i].seq = i + inc;
        }
      });
      sortedArray[event.previousIndex].seq = event.currentIndex;
    } else if (event.currentIndex > event.previousIndex) {
      let dec = 1;
      sortedArray.forEach((item, i) => {
        if (item.roles.length > 0 && !item.roles.includes(this.role)) {
          dec += 1;
        }
        if (i <= event.currentIndex) {
          sortedArray[i].seq = i < event.previousIndex ? i : i - dec;
        }
      });
      sortedArray[event.previousIndex].seq = event.currentIndex;
    }
    this.dashboardViews = sortedArray;
    this.updateSort([...event.container.data]);
    this.isInSwappingMode = false;
  }

  dragStarted($event) {
    this.isInSwappingMode = true;
  }
  updateSort(data: any[]) {
    const req = data.filter(x => x.seq < 99).map((x, i) => ({ userId: this.userId, viewNm: x.title, seqNo: x.seq }));
    this.eprService.saveDashboard(req).subscribe();
  }

  getTranslationsRecords() {
    this.translationsService.getTranslations().subscribe(response => {
      this.translationsRecords = response;
    });
  }

  isNotToShowBold(rowValue: any = null) {
    if (
      rowValue !== 'Undefined' &&
      rowValue !== undefined &&
      rowValue !== null &&
      rowValue !== 'N/A' &&
      rowValue?.trim() !== '') {
      return true;
    }
    return false;
  }

  getPGCriteriaField(x, gridApi) {
    return x.filter(el => gridApi.getColumnDef(el) ? gridApi.getColumnDef(el).headerName === 'PG Criteria' : null)[0];
  }

  boldInRowsBasedOnPGCriteria = (params) => {
    if (params.data.f11 !== 'Undefined' || params.data.g11 !== 'Undefined' || params.data.f11 != null || params.data.g11 != null) {
      return { 'font-weight': '700' };
    }
    return null;
  }

  createNewEpr() {
    this.store.dispatch(new ClearEprAction());
    this.router.navigateByUrl('epr');
    this.eprService.isNewCopy = true;
  }
  public rowClassRules: RowClassRules = {
    // row style function
    "disbale-ssoe-processingfiles": (params) => {
      return params.data.ssoeFlag;
    }
  };
  onRowClicked(title: string, $event: any) {
    this.eprService.dashboardRowClicked.next(true);
    if (title === 'Processing Files') {
      let obj = this.processFilespermissionObject
      if((obj["openthresholdModal"] && obj["pbmRole"])){
        const payload = this.prepareProcessingFilesRecentlyViewedEprPayload($event.data);
        this.usersService.saveRecentlyProcessingFilesViewedEprs(payload).subscribe(res => {
          if(res) {
            this.eprService.dashboardRowClicked.next(false);
          }
        }, error => {
          this.eprService.dashboardRowClicked.next(false);
        });


        if (!this.processingFilesModalOpen && !this.pfLoading) {
          this.processingFilesModalOpen = true;
          const dialogRef = this.dialog.open(ThreasholdDialogComponent, {
            width: '60%',
            height: '70vh',
            disableClose: true,
            panelClass: 'my-dialog',
            data: {
              selected: 'Processing Files',
              body: $event.data,
              db: this.db.processingFiles,
              translationsRecords: this.translationsRecords
            }
          });
          dialogRef.afterClosed().subscribe((confirmed: boolean) => {
            this.processingFilesModalOpen = false;
            let task = this.dashboardViews.find(data => data.title === 'Processing Files');
              this.gridAPis[title].setGridOption("loading", true);
            this.updateMyProcessingFilesWithOnFilterChange($event, $event.api.getFilterModel());
          });

        }
      }else{
        this.eprService.dashboardRowClicked.next(false);
      }


    }
    else if (title === 'FT Onboarding - Eligibility') {
      if((this.fileTrackerpermissionObject.viewFtOnboarding
        && this.fileTrackerpermissionObject.pbmRole)){

         this.fileTrackerService.isNew = false;
         const payload = this.prepareFTOnboardingRecentlyViewedEprPayload($event.data);
         this.usersService.saveRecentlyProcessingFilesViewedEprs(payload).subscribe(res => {       if (res) {
          this.eprService.dashboardRowClicked.next(false);
        }
      });
      this.fileTrackerService.cltSetupId = $event.data.cltSetupID
      this.store.dispatch(new LoadFiletrackerAction($event.data.cltSetupID));
      this.fileTrackerService.fileTrackerRowData = $event.data
    }else{
      return
    }
    } else if (title === 'FT File Monitor - Eligibility') {
      $event.data.screenName = true;
      const dialogRef = this.dialog.open(EligibilityDialogComponent, {
        width: '1400px',
        height: '750px',
        panelClass: 'my-dialog',
        disableClose: true,
        data: {
          selected: 'Eligibility',
          body: $event.data,
        }
      });
    } else if (title === 'FT Onboarding - Spedm') {
      this.spedmService.isNew = false;
      this.store.dispatch(new LoadSpedmAction($event.data.cltSetupID));
    } else if (title === 'FT File Monitor - Spedm') {
      $event.data.screenName = false;
      const dialogRef = this.dialog.open(EligibilityDialogComponent, {
        width: '1400px',
        height: '750px',
        disableClose: true,
        panelClass: 'my-dialog',
        data: {
          selected: 'Eligibility',
          body: $event.data,
        }
      });
    } else if (title === 'CAG REF (Manual)' || title === 'CAG REF (Batch)') {

    }
    else {

        if(!this.dashboardObject.viewEPR){

          this.eprService.dashboardRowClicked.next(false);
          return
        }else{
          if($event?.data.colDef?.field === "download") {
            return
          }
          this.store.dispatch(new LoadEprAction($event.data.eprId, title));

        }


    }
  }

  onCellClicked(event: any, title,downloadForm = false) {
    if(title === "EPR's"){
      this.isRowClicked = true
        if(event?.colDef?.field == "download") {
          let dialogRef: MatDialogRef<EligibilityAgreementComponent>
          this.dialogConfig.data = {};
          this.dialogConfig.data.actionType = { type: "TEST", mode: "Add" }
          this.dialogConfig.height= '98%';
          this.dialogConfig.width=  '860px';
          this.dialogConfig.id = "dialog"

          // Creates call to store to repopulate subscriptions for Eligibility Form
          this.store.dispatch(new LoadEprAction(event.data.eprId, "EPR's", true))

          dialogRef= this.dialog.open(EligibilityAgreementComponent, this.dialogConfig);
          dialogRef.componentInstance.readOnly = true;
          dialogRef.componentInstance.showEmailBtn = this.usersService.permissionCodes.includes("ELG_EMAIL_EA");
          dialogRef.componentInstance.modal = true;
          dialogRef.componentInstance.showSpinner$ = true;
          //fullyLoaded: A behavior subject that changes when the Load EPR Effect is finished
            let finished = this.eprService.fullyLoaded
            .subscribe((res)=>{
              if(res){
                dialogRef.componentInstance.ea_Form = document.querySelector('app-eligibility-agreement').querySelector("#ea-form")
                dialogRef.componentInstance.closeIcon = true;              }
          });
          dialogRef.afterClosed().subscribe(result => {
            this.isRowClicked = false
            dialogRef.componentInstance.modal = false
            dialogRef.componentInstance.closeIcon = false;
            dialogRef.componentInstance.showSpinner$ = false
            this.eprService.fullyLoaded.next(false)
            this.store.dispatch(new ClearEprAction())
            finished.unsubscribe()

      });
        }
      }

    let gridOptions = this.dashboardViews.find(data => data.title == title).gridOptions;
    const fieldType = event.colDef.field;
    let rowIndex = event.rowIndex;
    if (fieldType == 'cagRefManualCheck') {
      if (event.data.id !== null) {
        this.gridAPis[title].forEachNode(rowNode => {
          if (rowNode.data.cagRefManualCheck) {
            rowNode?.setSelected(true);
          } else {
            rowNode?.setSelected(false);
          }
          if (rowNode.data.id === event.data.id) {
            rowIndex = rowNode.rowIndex;
          }
        })
      
      } else {
        rowIndex = event.rowIndex;
      }
      // this.table1Records[rowIndex][fieldType] = !this.table1Records[rowIndex][fieldType];
      let rowNode;
       rowNode =  this.gridAPis[title].getRowNode(rowIndex);
      if (this.addCagRefManual) {
        if (event.data.id === null)
          event.data.cagRefManualCheck = !event.data.cagRefManualCheck
        // event.api.setDataValue('cagRefManualCheck',!rowNode.data.cagRefManualCheck)
      } else {
        event.data.cagRefManualCheck = !event.data.cagRefManualCheck
        //event.api.setDataValue('cagRefManualCheck',!rowNode.data.cagRefManualCheck)
        // rowNode.setDataValue('cagRefManualCheck',!rowNode.data.cagRefManualCheck);
        this.disableCagEdit();
      }
      this.gridAPis[title].redrawRows()
      // gridOptions.api.refreshCells();
    } else if (fieldType === "outputFileName") {
       let rowNode =  this.gridAPis[title].getRowNode(rowIndex);
      if (rowNode.data.id) {
        this.eprService.downloadCagRefOutputFile(rowNode.data.id).pipe(switchMap(resp => {
          const fileName = rowNode.data.outputFileName;
          const fileData = new Blob([resp.response], { type: 'text/csv' });
          saveAs(fileData, fileName);
          // saveAs(resp.response,'')
          return of(resp);
        })).subscribe();
      }
    }
    this.gridAPis[title].getRowNode(rowIndex)?.setSelected(event?.data?.cagRefManualCheck);
  }

  onDragStopped(event: any, title: string) {
    const columns = event.api.getAllDisplayedColumns()
    const ColDefs = [];
    const ColSize = [];
    this.changesDone = true;
    columns.forEach(col => {
      ColDefs.push(col.colDef.field)
      ColSize.push(col.actualWidth)
    })
    let userFilter = {}
    if (title === 'EPR Master') {
      this.cookieService.set('ewf-table-one-column-state', JSON.stringify(event.api.getAllDisplayedColumns().map(column => column.getColId())));
      userFilter = this.eprMasterFilter
    }
    // we are adding filter of only epr master for now, if we want to add filters of other views also,
    //then just update userFilter.
    this.PreferencepayLoad = [{
      "userId": this.userId,
      "orderOfColumn": ColDefs.join(),
      "columnWidth": ColSize.join(),
      "dashboardComponent": title,
      "userFilter": userFilter
    }
    ]

    // if our list already have the dashboardcompoent on which columns are changed then we just need to update that record.
    this.allPreferenceList.map((col) => {
      if (col[0]['dashboardComponent'] === title) {
        col[0]['orderOfColumn'] = this.PreferencepayLoad[0]['orderOfColumn'];
        col[0]['columnWidth'] = this.PreferencepayLoad[0]['columnWidth'];
        this.updatePreference = false;
      } else {
        this.updatePreference = true;
      }
    })
    // if new dashboardcomponent views are changed then we push that new record to our list.
    if (this.updatePreference) {
      this.allPreferenceList.push(this.PreferencepayLoad)
    }
    this.cookieService.set('columnList', JSON.stringify(this.allPreferenceList))
  }

  onSortChange(event: any, title: string) {
    if (title === 'EPR Master') {
      var colState = event.api.getColumnState();
      this.cookieService.set('ewf-table-one-sort-model', JSON.stringify(colState));
    }

  }

  onFilterChange(event: any, title: any, gridoptions: any, grid: any) {
    this.filterObject = event.api.getFilterModel();
    if (!this.PFLoaded && title === 'Processing Files' && this.globalFilterText.length === 0) {
      if (event.columns.length > 0) {
        this.columnId = event.columns[0].colId;
      }
      if (this.columnId !== "g11") {
        if (this.filterObject[this.columnId]?.conditions && this.filterObject[this.columnId]?.conditions[0] && this.filterObject[this.columnId]?.conditions[0].filter) {
          this.filterObject[this.columnId].conditions[0].filter = this.filterObject[this.columnId].conditions[0].filter?.toUpperCase();
          this.filterObject[this.columnId].condition1 = this.filterObject[this.columnId].conditions[0]
        } else {
          if (this.filterObject[this.columnId]?.filter)
            this.filterObject[this.columnId].filter = this.filterObject[this.columnId].filter?.toUpperCase();
        }
        if (this.filterObject[this.columnId]?.conditions && this.filterObject[this.columnId]?.conditions[1] && this.filterObject[this.columnId]?.conditions[1]?.filter) {
          this.filterObject[this.columnId].conditions[1].filter = this.filterObject[this.columnId].conditions[1].filter?.toUpperCase();
          this.filterObject[this.columnId].condition2 = this.filterObject[this.columnId].conditions[1]
        }
        this.updateMyProcessingFilesWithOnFilterChange(event, this.filterObject);
      }

    }

    if (title === 'CAG REF (Manual)' && this.globalFilterText.length === 0) {
      this.filterCagRefManualData({ filter: this.filterObject, event, grid });
    }
    if (this.PFLoaded) {
      this.PFLoaded = false;
    }
    if (this.PWLoaded) {
      this.PWLoaded = false;
    }
    if (title === "EPR Master") {
      this.cookieService.set('ewf-eprmaster-filter-model', JSON.stringify(event.api.getFilterModel()));
      this.gridColumnsList.find(rec => {
        if (rec['dashboardComponent'] === 'EPR Master') {
          rec['userFilter'] = event.api.getFilterModel()
        }
        else {
          rec['userFilter'] = {}
        }
      })
    }


    this.recalculateCount(title, event, grid);


  }
  recalculateCount(title, event, grid) {
  // console.log("Innn...... Recalculate", title)
    if (title === "Testing") {
      grid.rowData.currentRowCount = this.gridAPis[title]?.getDisplayedRowCount()
      grid.rowData.failedStatusRecordsCount = 0;
      grid.rowData.insuffcientDataRecordsCount = 0;
      grid.rowData.nullRecordsCount = 0;
      grid.rowData.passRecordsCount = 0;
      this.gridAPis[title]?.forEachNodeAfterFilter((rowData, index) => {
        switch (rowData.data.t9) {
          case "Fail": {
            grid.rowData.failedStatusRecordsCount++;
            break;
          }
          case "Pass": {
            grid.rowData.passRecordsCount++;
            break;
          }
          case "Insufficient Data": {
            grid.rowData.insuffcientDataRecordsCount++;
            break;
          }
          case null: {
            grid.rowData.nullRecordsCount++;
            break;
          }
          default: {
            break;
          }
        }
      })

    }
    else if (title == 'Open Enrollment') {
      grid.rowData.currentRowCount = this.gridAPis[title]?.getDisplayedRowCount()
      grid.rowData.clientNotSendRecordsCount = 0;
      grid.rowData.clientSendRecordsCount = 0;
      grid.rowData.otherRecords = 0;
      grid.rowData.nullRecords = 0;
      this.gridAPis[title]?.forEachNodeAfterFilter((rowData, index) => {
        switch (rowData.data.oe10) {
          case ("Yes"): {
            grid.rowData.clientSendRecordsCount++;
            break;
          }
          case ("No"): {
            grid.rowData.clientNotSendRecordsCount++;
            break;
          }
          case ("Not Applicable"): {
            grid.rowData.clientNotSendRecordsCount++;
            break;
          }
          case (null): {
            grid.rowData.nullRecords++;
            break;
          }
          default: {
            grid.rowData.otherRecords++;
            break;
          }
        }
      })
    }
    if (title === 'QC/Audit') {
      grid.rowData.currentRowCount = this.gridAPis[title]?.getDisplayedRowCount()
      this.qcGreenCount = 0;
      this.qcDueSoonCount = 0;
      this.qcPastDueCount = 0;

      this.populateQcFocusNumbers(event);

    } else if (title === "Integrated Feeds") {
      grid.rowData.currentRowCount = this.gridAPis[title]?.getDisplayedRowCount()
      this.configIfeedsLabels(event);
    } else if (
      title === "CAG REF (Batch)" ||
      (title === "CAG REF (Manual)" && this.globalFilterText.length !== 0)
    ) {
      grid.rowData.currentRowCount = this.gridAPis[title]?.getDisplayedRowCount()
    } else if (title === "FT Onboarding - Eligibility") {
      grid.rowData.currentRowCount = this.gridAPis[title]?.getDisplayedRowCount()
      grid.rowData.activeCount = 0;
      grid.rowData.inActiveCount = 0;
      this.gridAPis[title]?.forEachNodeAfterFilter((rowData) => {
        switch (rowData.data.ActiveorInactive) {
          case "A": {
            grid.rowData.activeCount++;
            break;
          }
          case "I": {
            grid.rowData.inActiveCount++;
            break;
          }
          default: {
            break;
          }
        }
      });
    }
    else if (title === "EPR's") {
      grid.rowData.currentRowCount = this.gridAPis[title]?.getDisplayedRowCount()
      this.eprBatchFileCount = 0;
      this.eprManualCount = 0;
      this.eprRealTimeCount = 0;
      this.eprNotDefinedCount = 0;
      this.eprTermedCount = 0

      this.gridAPis[title]?.forEachNodeAfterFilter((rowData, index) => {

        switch (rowData.data.e5) {
          case ("Electronic"): {
            ++this.eprBatchFileCount;
            break;
          }
          case ("Manual"): {
            ++this.eprManualCount;
            break;
          }
          case ("Real Time"): {
            ++this.eprRealTimeCount
            break;
          }
          case ("TERMED"): {
            ++this.eprTermedCount;
            break;
          }
          case (null): {
            ++this.eprNotDefinedCount;
            break;
          }
          default: {
            break;
          }
        }
      })
    }
    else if (title === "EPR Master") {
      grid.rowData.currentRowCount = (this.gridAPis[title]?.getDisplayedRowCount()) ? this.gridAPis[title]?.getDisplayedRowCount() : 0;
      this.eprMasterBatchFileCount = 0;
      this.eprMasterManualCount = 0;
      this.eprMasterRealTimeCount = 0;
      this.eprMasterNotDefinedCount = 0;
      this.eprMasterTermedCount = 0
      this.eprMasterTotalCount = 0;
      this.gridAPis[title]?.forEachNodeAfterFilter((rowData, index) => {
        ++this.eprMasterTotalCount;
        const res = rowData.data[28];
        switch (res) {
          case ("Electronic"): {
            ++this.eprMasterBatchFileCount;
            break;
          }
          case ("Manual"): {
            ++this.eprMasterManualCount;
            break;
          }
          case ("Real Time"): {
            ++this.eprMasterRealTimeCount
            break;
          }
          case ("TERMED"): {
            ++this.eprMasterTermedCount;
            break;
          }
          case (null): {
            ++this.eprMasterNotDefinedCount;
            break;
          }
          default: {
            break;
          }
        }
      })
    } else if (title === "File Mappings") {
      grid.rowData.currentRowCount = this.gridAPis[title]?.getDisplayedRowCount();
      grid.rowData.baselinedatePastRecordCount = 0;
      grid.rowData.baselinedateFutureRecordCount = 0;
      grid.rowData.baselinedateAfter7daysRecordCount = 0;
      grid.rowData.noBaselinedateRecordCount = 0;
      grid.rowData.historicRecordCount = 0;
      grid.rowData.completedRecordCount = 0;
      grid.rowData.cancelledRecordCount = 0;
      this.gridAPis[title]?.forEachNodeAfterFilter(rowData => {
        switch (rowData.data.focusStatus) {
          case "PastBaselinedate": {
            grid.rowData.baselinedatePastRecordCount++;
            break;
          }
          case "FutureBaselinedate": {
            grid.rowData.baselinedateFutureRecordCount++;
            break;
          }
          case "BaselinedateAfter7days": {
            grid.rowData.baselinedateAfter7daysRecordCount++;
            break;
          }
          case "NoBaselinedate": {
            grid.rowData.noBaselinedateRecordCount++;
            break;
          }
          case "Historic": {
            grid.rowData.historicRecordCount++;
            break;
          }
          case "Completed": {
            grid.rowData.completedRecordCount++;
            break;
          }
          case "Cancelled": {
            grid.rowData.cancelledRecordCount++;
            break;
          }
          default: { break; }
        }
      })
    } else if (title === "Processing Files") {
      grid.rowData.currentRowCount = this.gridAPis[title]?.getDisplayedRowCount();
      this.pfErrorCount = 0;
      this.pfProcessingCount = 0;
      this.pfPendingCount = 0;
      this.gridAPis[title]?.forEachNodeAfterFilter(rowData => {
        switch (rowData.data.transStatus) {
          case "Processing": {
            this.pfProcessingCount++;
            break;
          }
          case "Error": {
            this.pfErrorCount++;
            break;
          }
          case "Pending": {
            this.pfPendingCount++;
            break;
          }
          default:  break;
        }
      })

    } else if (title === "Projects" || title === '1/1 Projects') {
      grid.rowData.currentRowCount = this.gridAPis[title]?.getDisplayedRowCount();
      grid.rowData.redRecordsCount = 0;
      grid.rowData.greenRecordsCount = 0;
      grid.rowData.yellowRecordsCount = 0;
      grid.rowData.greyRecordsCount = 0;
      grid.rowData.purpleRecordsCount = 0;
      this.gridAPis[title]?.forEachNodeAfterFilter(rowData => {
        switch (rowData.data.focusStatus) {
          case 'Green':
            grid.rowData.greenRecordsCount++;
            break;
          case 'Red':
            grid.rowData.redRecordsCount++;
            break;
          case 'Yellow':
            grid.rowData.yellowRecordsCount++;
            break;
          case 'Grey':
            grid.rowData.greyRecordsCount++;
            break;
          case 'Purple':
            grid.rowData.purpleRecordsCount++;
            break;
        case "Completed" :{
          grid.rowData.completedRecordCount++;
          break;
        }
        case "Cancelled" :{
          grid.rowData.cancelledRecordCount++;
          break;
        }
        default : {break;}
      }
    })
  //  }
  //  else if(title === "Processing Files"){
  //   grid.rowData.currentRowCount = this.gridAPis[title].getDisplayedRowCount();
  //   console.log(grid)
  //   this.pfErrorCount = 0;
  //   this.pfProcessingCount = 0;
  //   this.pfPendingCount = 0;
  //   this.gridAPis[title].forEachNodeAfterFilter(rowData=>{
  //     switch(rowData.data.transStatus){
  //       case "Processing" :{
  //         this.pfProcessingCount++;
  //         break;
  //       }
  //       case "Error" :{
  //         this.pfErrorCount++;
  //         break;
  //       }
  //       case "Pending" :{
  //         this.pfPendingCount++;
  //         break;
  //       }
  //       default: {break;}
  //     }
  //   })
   }else if(title === "Projects" || title === '1/1 Projects') {
    grid.rowData.currentRowCount = this.gridAPis[title]?.getDisplayedRowCount();
    grid.rowData.redRecordsCount = 0;
    grid.rowData.greenRecordsCount = 0;
    grid.rowData.yellowRecordsCount = 0;
    grid.rowData.greyRecordsCount = 0;
    grid.rowData.purpleRecordsCount = 0;
    this.gridAPis[title]?.forEachNodeAfterFilter(rowData=>{
      switch(rowData.data.focusStatus){
        case 'Green':
          grid.rowData.greenRecordsCount++;
          break;
        case 'Red':
          grid.rowData.redRecordsCount++;
          break;
        case 'Yellow':
          grid.rowData.yellowRecordsCount++;
          break;
        case 'Grey':
          grid.rowData.greyRecordsCount++;
          break;
        case 'Purple':
          grid.rowData.purpleRecordsCount++;
          break;
        default:
          break;
      }
    })
  }
  if(title === 'Term By Absence'){
    this.tbaComplete = 0;
    this.tbaExceeded = 0;
    this.gridAPis[title]?.forEachNodeAfterFilter((rowData, index) => {
      switch (rowData.data?.status) {
        case "Complete": {
          this.tbaComplete++;
        }
        case "Exceeded": {
          this.tbaExceeded++;
          break;
        }
        default: {
          break;
        }
      }
    })
  }


  }


  loadDashboardViewsArray(id: string, title: string, response, rowData, columnSet, filterModel: any, sortModel: any, rowStyle, components, isRefresh = false) {
    let idx = this.dashboardViews.findIndex(x => x.title == title);
    let externalUsers = [Permission.CLIENT_USER, Permission.CONSULTANT_USER];
    if (idx > -1) {
      response.currentRowCount = rowData?.length || 0;
      if (isRefresh) {
        this.dashboardViews[idx].rowData = response;
       // console.log("DATA: ", this.dashboardViews[idx]);
       // this.dashboardViews[idx].api.setGridOption("rowData",rowData);
       this.gridAPis[title].setGridOption("rowData",rowData);
       this.gridAPis[title].setGridOption('loading',false) 
       this.dashboardViews[idx].gridOptions.rowData = rowData == null ? [] : rowData
        const bkpIdx = this.includeBackupItems.indexOf(title);
         response.currentRowCount =   this.gridAPis[title].getDisplayedRowCount();
        if (bkpIdx > -1) {
          this.includeBackupItems.splice(bkpIdx, 1);
        }
        if (title === "EPR Master") {
          // const found = this.dashboardViews.find(x => x.title === "EPR Master");
           this.gridAPis[title].setFilterModel(this.eprMasterFilter);
        }
      } else {
        this.dashboardViews[idx] = {
          id: id,
          title: title,
          seq: this.dashboardViews[idx].seq,
          roles: this.dashboardViews[idx].roles,
          isLoaded: true,
          rowData: response,
          gridOptions: {
            rowData: rowData == null ? [] : rowData,
            columnDefs: externalUsers.includes(this.role)?
            columnSet.filter(col=>{return col.headerName !== 'SSOE Migrated' && col.headerName !== 'PG Criteria'})
            :columnSet,
            getRowStyle: null,
            rowSelection: 'single',
            components:{
              R: CellRendererComponent,
              customTooltip: StatusPopoverComponent
            },
            onRowClicked: event => {
              if(!this.isRowClicked){
                this.onRowClicked(title, event);
              }
            },
            onGridReady: (event) =>{
              this.setFilterColumns(event, filterModel, sortModel, title)
            } ,

            ...((title === 'CAG REF (Manual)' || title === 'CAG REF (Batch)') && { onCellClicked: event => {this.onCellClicked(event, title)

            } }),
            context: { permissions: this.usersService.permissionCodes,
            componentParent: this}
          },
          access:false,
          showToggles: false,
          ads:false,
          prd:false,
          beta:false
        };
        if(title === "Processing Files"){
          this.dashboardViews[idx].showToggles = this.processFilespermissionObject.adsPrdToggle || this.processFilespermissionObject.betaToggle;
          this.dashboardViews[idx].prd =  this.processFilespermissionObject.adsPrdToggle;
          this.dashboardViews[idx].beta = this.processFilespermissionObject.betaToggle;

        }
        if(title === 'CAG REF (Manual)'){
          this.dashboardViews[idx].gridOptions.rowSelection = 'multiple'
        }
        // console.log("PI ---", this.dashboardViews[idx])
      }
      if (title === "EPR Master" || title === "Processing Files") {
        this.recalculateCount(title, this.dashboardViews[idx].gridOptions, this.dashboardViews[idx]);
      }

    }
  }

  showErrorMessage(ErrorMsg) {
      this.alertService.sendAlert({
        headline: "Error occured while fetching Processing files", body: "Processing files API failed for "+ ErrorMsg,
        alertType: CVSAlertType.Error, removedAfterMilliseconds: 5000,
        alertDocumentHolderName: '#dashboard-notification',
      });
      document.getElementById('dashboard-notification').scrollIntoView()
  }


  updateMyProcessingFilesWithOnFilterChange(event: any, filter: any) {
    let rowStyle = null;
    let components = {
      R: CellRendererComponent,
      customTooltip: StatusPopoverComponent
    };
    this.pfLoading= true;
    this.eprService.getMyProcessingFilesByFilter(filter, this.userId, this.includeBackups, this.db.processingFiles).subscribe(
      rowData => {
        this.gridAPis['Processing Files'].setGridOption('loading',false)
        this.pfLoading= false;
        if(rowData.errorResp) {
          this.showErrorMessage(rowData.errorResp);
        }
        let response= rowData.response?rowData.response:[]
        this.setStatusToolTip(response);
        this.processResponse(response);
        this.gridAPis['Processing Files'].setGridOption("rowData",response);

        this.loadDashboardViewsArray("10", "Processing Files", rowData, response,
          ColumnSets.processFilesColumns, filter, null, rowStyle, components, true);
        this.moreDataErrorGrid1 = '';
        if (rowData.status === 201) {
          this.moreDataErrorGrid1 = 'More data to display, use filters to narrow down the results';
        }
      }, error => {
        this.pfLoading= false;
        this.moreDataErrorGrid1 = error.error.message;
        this.loadDashboardViewsArray("10", "Processing Files", [], [],
          ColumnSets.processFilesColumns, filter, null, rowStyle, components, true);
      });
  }

  getMyProcessingFilesWithFilter(filter: any, isRefresh = false, db: string) {
    let rowStyle = null;
    let components = {
      R: CellRendererComponent,
      customTooltip: StatusPopoverComponent
    };
    this.eprService.getMyProcessingFilesByFilter(filter, this.userId, this.includeBackups, this.db.processingFiles).subscribe(
      rowData => {
        this.pfLoading = false
        let response= rowData?.response ? rowData.response:[]
        if(rowData.errorResp) {
          this.showErrorMessage(rowData.errorResp);
        }
        this.processResponse(response)

        this.loadDashboardViewsArray("10", "Processing Files", rowData, response,
          ColumnSets.processFilesColumns, filter, null, rowStyle, components, isRefresh);
        this.moreDataErrorGrid1 = '';
        if (rowData.status === 201) {
          this.moreDataErrorGrid1 = 'More data to display, use filters to narrow down the results';
        }
      }, error => {
        this.moreDataErrorGrid1 = error?.error?.message;
        this.loadDashboardViewsArray("10", "Processing Files", [], [],
          ColumnSets.processFilesColumns, filter, null, null, null, isRefresh);
      });

  }



  fetchRecentEpr(eprId: any) {
    this.store.dispatch(new LoadEprAction(eprId, 'noView'));
  }

  filterTables(query: any) {
    this.globalFilterText = query;
    if (this.filterGrids.length > 0) {
      this.filterGrids.forEach(grid => {
        grid.api.setGridOption("quickFilterText",query);
      })
    }
  }

  resetFilters(title: string) {
    this.clickButton = true;
    this.gridAPis[title].setFilterModel(null);
    this.gridAPis[title].applyColumnState({
      defaultState: { sort: null },
    });
  }

  resetFilter() {
    this.db.processingFiles = ProcessingFileDB.ADS;
  }

  refresh(task: any) {
    this.clickButton = true;
    let dateText = 'Last On:'
    const processDate = new Date().toTimeString()
    task.RefreshDate = dateText + " " + processDate.slice(0, 8);
    this.gridAPis[task.title].setGridOption('loading',true)
    switch (task.title) {
      case 'Processing Files': {
        this.getMyProcessingFilesWithFilter(this.defaultMyprocessingFilter, true, this.db.processingFiles);
        this.gridAPis[task.title].setFilterModel(this.defaultMyprocessingFilter)
        this.processRefresh = true;
        break;
      }
      case 'EPR\'s': {
        this.loadEprData(true);
        break;
      }
      case 'EPR Master': {
        this.loadEprMasterData(true)
        break;
      }
      case 'Integrated Feeds': {
        this.loadIntegratedFeedsData(true);
        break;
      }
      case 'QC/Audit': {
        this.loadQCAuditData(true);
        break;
      }
      case 'Testing': {
        this.loadTestingData(true);
        break;
      }
      case 'Open Enrollment': {
        this.loadOpenEnrollmentData(true);
        break;
      }
      case 'Projects': {
        this.loadProjectsData(true);
        break;
      }
      case '1/1 Projects': {
        this.loadOneOneProjectsData(true);
        break;
      }
      case 'File Mappings': {
        this.loadFileMappingData(true);
        break;
      }
      case 'FT Onboarding - Eligibility': {
        this.loadFTOnboardingEligibilityData(true);
        break;
      }
      case 'FT File Monitor - Eligibility': {
        this.loadFTFileMonitorEligibilityData(true);
        break;
      }
      case 'FT Onboarding - Spedm': {
        this.loadFTOnboardingSpedmData(true);
        break;
      }
      case 'FT File Monitor - Spedm': {
        this.loadFTFileMonitorSpedmData(true);
        break;
      }
      case 'CAG REF (Manual)': {
        this.loadCagRefManualData(true);
        break;
      }
      case 'CAG REF (Batch)': {
        this.loadCagRefBatchData(true);
        break;
      }
      case 'Term By Absence': {
        this.loadTBA(true);
        break;
      }
    }
  }

  exportView(title) {
    this.clickButton = true;
    const fileName = title + '.csv';
    const fileData = new Blob([this.gridAPis[title].getDataAsCsv()], { type: 'text/csv' });
    saveAs(fileData, fileName);

  }


  loadFTOnboardingEligibilityData(isRefresh = false) {
    const ftOnboardingEligFilter = {
      ActiveorInactive: {
        type: 'Contains',
        filterType: 'text',
        filter: 'A'
      }
    };
    this.fileTrackerService.getAll(this.userId, this.role, this.includeBackups).subscribe(rowData => {
      this.loadDashboardViewsArray("13", "FT Onboarding - Eligibility", rowData, rowData,
        ColumnSets.ftOnboardingEligibilityColumns, ftOnboardingEligFilter, null, null, null, isRefresh);
    }
    );
  }

  loadFTFileMonitorEligibilityData(isRefresh = false) {
    const filter = {
      completed: {
        type: 'contains',
        filterType: 'text',
        filter: 'No'
      }
    };
    this.fileTrackerService.getFileTrackerEligibility(this.islatestEligibility ? -1 : 0).subscribe(rowData => {
      this.loadDashboardViewsArray("12", "FT File Monitor - Eligibility", rowData, rowData,
        ColumnSets.ftFileMonitorEligibilityColumns, filter, null, null, null, isRefresh);
    }
    );
  }

  loadFTOnboardingSpedmData(isRefresh = false) {
    this.spedmService.getAll().subscribe(rowData => {
      this.loadDashboardViewsArray("14", "FT Onboarding - Spedm", rowData, rowData,
        ColumnSets.ftOnboardingSpedmColumns, null, null, null, null, isRefresh);
    }
    );
  }


  loadFTFileMonitorSpedmData(isRefresh = false) {
    const filter = {
      completed: {
        type: 'contains',
        filterType: 'text',
        filter: 'No'
      }
    };
    this.spedmService.getFileTrackerSpeedm(this.islatestSpedm ? -1 : 0).subscribe(rowData => {
      this.loadDashboardViewsArray("15", "FT File Monitor - Spedm", rowData, rowData,
        ColumnSets.ftFileMonitorSpedmColumns, filter, null, null, null, isRefresh);
    }
    );
  }



  loadEprData(isRefresh = false) {

    const eprFilter = {
      e5: {
        type: 'notContains',
        filterType: 'text',
        filter: 'Termed'
      }
    };
    let sortOrder = [
      {
        colId: 'e2',
        sort: 'asc',
      },
      {
        colId: 'e3',
        sort: 'asc'
      }
    ];
    this.eprService.getAllEprs(this.userId, this.includeBackups).subscribe(rowData => {

      this.loadDashboardViewsArray("1", "EPR's", rowData, rowData.response,
        ColumnSets.eprColumns, eprFilter, sortOrder, null, null, isRefresh);

    }
    );



  }
  loadTBA(isRefresh = false){
    const tbaFilter = {
      status: {
        type: 'notContains',
        filterType: 'text',
        filter: 'Complete'
      }
    };
    this.eprService.getTBAData(this.userId, tbaFilter).subscribe(rowData=>{
      if(rowData){
        let response= rowData.response?rowData.response:[] 
        this.loadDashboardViewsArray("16", "Term By Absence", rowData, response,
          ColumnSets.tbaColumns, tbaFilter
          , null, null, null, isRefresh);
      }
     
    }, error => {
      this.loadDashboardViewsArray("16", "Term By Absence", [], [],
      ColumnSets.tbaColumns, tbaFilter, null, null, null, isRefresh);
    })
  }
  filterCagRefManualData(additionalData?) {
    const updatedFilter = { "filters": [] };
    let filter, event, grid = null;
    // let event = null;
    if (additionalData) {
      filter = additionalData.filter;
      event = additionalData.event;
      grid = additionalData.grid;
    }
    if (filter) {
      const colName = Object.keys(filter);
      //let exp = new RegExp(/^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/gm);
      colName.forEach((col, index) => {
        const actualFilters = filter[col];
        const conditions = Object.keys(actualFilters);

        const updated: any = {};
        let isSingleConditionBased = true;
        for (let i = 0; i < conditions.length; i++) {
          const cond = conditions[i];
          if (cond.includes('condition')) {
            isSingleConditionBased = false;
            actualFilters[cond].forEach((condition, index)=>{
              let newKey = 'filterRequest' + (index + 1);
            updated[newKey] = {
              "key": col,
              "operator": this.getBackendMapped(actualFilters[cond][index].type, col),
              "value": condition.filter
            }
            })
            

          } else if (cond === 'operator') {
            updated.operatorType = actualFilters[cond];
          }
        }
        if (isSingleConditionBased) {

          if ((col === "fromDate" || col === "termDate")) {
            const dateFrom = (actualFilters.dateFrom).split(" ")?.[0];
            const dateTo = (actualFilters.dateTo)?.split(" ")?.[0] || null;
            if (actualFilters.type == "inRange") {
              updated.filterRequest1 = {
                "key": col,
                "operator": "GREATER_THAN",
                "value": dateFrom
              }
              updated.filterRequest2 = {
                "key": col,
                "operator": "LESS_THAN",
                "value": dateTo
              }
              updated.operatorType = "BETWEEN";

            } else {
              updated.filterRequest1 = {
                "key": col,
                "operator": this.getBackendMapped(actualFilters.type, col),
                "value": dateFrom
              }
            }
          } else {

            updated.filterRequest1 = {
              "key": col,
              "operator": this.getBackendMapped(actualFilters.type, col),
              "value": actualFilters.filter
            }
          }

        }
        if (Object.keys(updated).length) {
          updatedFilter.filters.push(updated);
        }

      })
    }

    event.api.showLoadingOverlay();
    this.eprService.getCagRefManualData((filter && updatedFilter.filters.length) ? updatedFilter : null)
      .pipe(switchMap(resp => {
        if (resp.status === "SUCCESS") {
          if (resp.totalRecords > 5000) {
            this.showCagNote = true;
          } else {
            this.showCagNote = false;
          }
        } else if (resp.status == "ERROR") {
          console.error(resp.message);
        }
        event.api.setGridOption("rowData",resp.response);
        grid.rowData.currentRowCount = event.api.getDisplayedRowCount();
        return of(resp);
      }), catchError(err => { console.error(err); return of(err); }))
      .subscribe(() => { event.api.hideOverlay(); });

  }
  loadCagRefManualData(isRefresh = false) {
    const title = 'CAG REF (Manual)';

    this.eprService.getCagRefManualData(null).subscribe(rowData => {
      if (rowData.totalRecords > 5000) {
        this.showCagNote = true;
      } else {
        this.showCagNote = false;
      }

      if(isRefresh){
        this.editCagRefManual = false;
        this.addCagRefManual = false;
      }
      this.gridAPis[title].setGridOption("loading", false);
      this.loadDashboardViewsArray("14", title, rowData, rowData.response,this.checkColumnPermissions(), null, null, null, null, isRefresh);


    });
  }

  loadCagRefBatchData(isRefresh = false) {
    this.eprService.getCagRefBatchData(this.userId).subscribe(rowData => {
      this.loadDashboardViewsArray("15", "CAG REF (Batch)", rowData, rowData.response,
        ColumnSets.cagRefBatch, null, null, null, null, isRefresh);
    });
    // const title = 'CAG REF (Batch)';
    // this.eprService.getCagRefBatchData(this.userId).subscribe(rowData => {
    //   this.loadDashboardViewsArray("15", title, rowData, rowData.response,
    //     ColumnSets.cagRefBatch, null, null, null, null , isRefresh);
    // });
  }

  loadFileMappingData(isRefresh = false) {

    const mappingStatusFilterForSI_Mapper = {
      e5: {
        filterType: 'text',
        operator: 'AND',
        conditions: [{
          filterType: 'text',
          type: 'notContains',
          filter: '10 - Cancelled'
        },
        {
          filterType: 'text',
          type: 'notContains',
          filter: '11 - Historic'
        }]
      },
      format: { filterType: 'text', type: 'notContains', filter: 'RX 51' }
    };
    const mappingStatusFilter = {
      e5: {
        filterType: 'text',
        operator: 'AND',
        conditions:[ {
          filterType: 'text',
          type: 'notContains',
          filter: '10 - Cancelled'
        },
        {
          filterType: 'text',
          type: 'notContains',
          filter: '11 - Historic'
        }]
      }
    };


    if (!this.SI_MapperRole) {
      this.eprService.getFileMappingData(this.userId, this.includeBackups).subscribe(rowData => {
        this.loadDashboardViewsArray("2", "File Mappings", rowData, rowData.response,
          ColumnSets.myMappingColumns, mappingStatusFilter, null, null, null, isRefresh);
      });
    } else if (this.SI_MapperRole) {
      this.eprService.getFileMappingData(this.userId, this.includeBackups).subscribe(rowData => {
        this.loadDashboardViewsArray("2", "File Mappings", rowData, rowData.response,
          ColumnSets.myMappingColumns, mappingStatusFilterForSI_Mapper, null, null, null, isRefresh);
      });
    }
  }

  loadProjectsData(isRefresh = false) {

    let sortOrder = [
      {
        colId: 'a7',
        sort: 'asc'
      },
      {
        colId: 'a3',
        sort: 'asc'
      }
    ];
    const projectPhaseFilter = {
      a6: {
        filterType: 'text',
        operator: 'AND',
        conditions:[ {
          filterType: 'text',
          type: 'notContains',
          filter: 'Completed'
        },
        {
          filterType: 'text',
          type: 'notContains',
          filter: 'Cancelled'
        }]
      }
    };

    const eligibilityFilter = {
      a11: {
        filterType: 'text',
        type: 'notContains',
        filter: 'TERMED'
      }
    };


    const filter = { ...projectPhaseFilter, ...eligibilityFilter };

    this.eprService.getAllActiveProjects(this.userId, this.includeBackups).subscribe(rowData => {
      this.loadDashboardViewsArray("3", "Projects", rowData, rowData.response,
        ColumnSets.activeProjectColumns, filter, sortOrder, null, null, isRefresh);

    });
  }

  loadOneOneProjectsData(isRefresh = false) {

    let sortOrder = [
      {
        colId: 'carrier',
        sort: 'asc'
      },
      {
        colId: 'pli',
        sort: 'asc'
      },
      {
        colId: 'projectType',
        sort: 'asc'
      },
    ];

    const oneoneFilter = {
      prjPhase: {
        filterType: 'text',
        operator: 'AND',
        conditions:[{
          filterType: 'text',
          type: 'notContains',
          filter: 'Completed'
        },
        {
          filterType: 'text',
          type: 'notContains',
          filter: 'Cancelled'
        }]
      }
    };

    const eligibilityFilter = {
      eligibilityType: {
        filterType: 'text',
        type: 'notContains',
        filter: 'TERMED'
      }
    };


    const filter = { ...oneoneFilter, ...eligibilityFilter };

    this.eprService.getOneOneProjects(this.userId, this.includeBackups).subscribe(rowData => {
      this.loadDashboardViewsArray("4", "1/1 Projects", rowData, rowData.response,
        ColumnSets.projectColumns, filter, sortOrder, null, null, isRefresh);


    });
  }

  loadTestingData(isRefresh = false) {

    let sortOrder = [
      {
        colId: 't6',
        sort: 'asc'
      },
      {
        colId: 't2',
        sort: 'asc'
      },
      {
        colId: 't3',
        sort: 'asc'
      }
    ];
    this.eprService.getTestingProjects().subscribe(rowData => {
      this.loadDashboardViewsArray("5", "Testing", rowData, rowData.response,
        ColumnSets.testingProjectColumns, null, sortOrder, null, null, isRefresh);

    });

  }

  loadQCAuditData(isRefresh = false) {

    let sortOrder = [
      {
        colId: 'qcPrjGoLiveDte',
        sort: 'asc'
      },
      {
        colId: 'qcCarrier',
        sort: 'asc'
      },
      {
        colId: 'qcPli',
        sort: 'asc'
      }
    ];

    this.eprService.getQcProjects().subscribe(rowData => {
      this.loadDashboardViewsArray("6", "QC/Audit", rowData, rowData.response,
        ColumnSets.qcProjectColumns, null, sortOrder, null, null, isRefresh);
      this.populateQcFocusNumbers();

    });

  }

  loadIntegratedFeedsData(isRefresh = false) {

    let sortOrder = [
      {
        colId: 'i6',
        sort: 'asc'
      },
      {
        colId: 'i2',
        sort: 'asc'
      },
      {
        colId: 'i3',
        sort: 'asc'
      }
    ];
    const defaultFilter = {
      i15: {
        filterType: 'text',
        type: 'default',
        filter: 'Null'
      }
    };
    const filter = { ...defaultFilter };
    this.eprService.getIFeedsProjects().subscribe(rowData => {

        if(rowData){
          this.loadDashboardViewsArray("7", "Integrated Feeds", rowData, rowData.response,
          ColumnSets.intFeedsProjectColumns, filter, sortOrder, null, null, isRefresh);
        }
    });

  }

  loadOpenEnrollmentData(isRefresh = false) {
    let sortOrder = [
      {
        colId: 'oe2',
        sort: 'asc'
      },
      {
        colId: 'oe3',
        sort: 'asc'
      }
    ];

    this.eprService.getOEEprs(this.userId, this.includeBackups).subscribe(rowData => {
      rowData.response.map((row) => {
        if (row) {
          row.oe13 = row?.oe13?.split("", 10).join("")
          row.oe19 = row?.oe19?.split("", 10).join("")
          row.oe22 = row?.oe22?.split("", 10).join("")
        }
      })
      this.loadDashboardViewsArray("8", "Open Enrollment", rowData, rowData.response,
        ColumnSets.openEnrollmentColumns, null, sortOrder, null, null, isRefresh);
    });
  }

  loadEprMasterData(isRefresh = false) {
    this.cookieService.set('ewf-table-one-column-state', JSON.stringify(masterEprColumns.map(column => column.field)));
    this.cookieService.set('ewf-table-one-sort-model', JSON.stringify([{ colId: '1', sort: 'asc' }, { colId: '2', sort: 'asc' }]));

    this.eprService.getMasterViewEprs(this.prepareColumnsForServiceCall()).subscribe(rowData => {
      let sortModel = [{ colId: '1', sort: 'asc' }, { colId: '2', sort: 'asc' }];
      this.gridAPis['EPR Master'].setGridOption("loading", false);
      this.loadDashboardViewsArray("11", "EPR Master", rowData, rowData,
        ColumnSets.masterEprColumns, this.eprMasterFilter, sortModel, null, null, isRefresh);


    });

  }


  loadProcessingFilesData(isRefresh = false) {
    this.getMyProcessingFilesWithFilter(this.defaultMyprocessingFilter, isRefresh, this.db.processingFiles);

    if (isRefresh) {
      return;
    }

    /*    timer(environment.processingTime, environment.processingTime).subscribe((x => {
          if(!this.isInSwappingMode) {
            this.getMyProcessingFilesWithFilter(this.defaultMyprocessingFilter, true);
          }
        }));*/

  }

  setStatusToolTip(rowData: any) {
    let updatedRowData = [];
    if (this.translationsRecords && this.translationsRecords.length > 0) {
      this.translationsRecords = this.translationsRecords.sort((a, b) => a.translationId - b.translationId);
      this.translationsRecords.forEach((tRecord, i) => {
        rowData.forEach((rRecord) => {
          if (rRecord?.g12?.trim() === tRecord?.input?.trim()) {
            rRecord.tRecord = tRecord;
            rRecord.progressBarDetails = {
              outputText: tRecord.output !== null ? tRecord?.output?.trim() : '',
              softText: tRecord.softText !== null ? tRecord?.softText?.trim() : '',
              totalTranslations: this.translationsRecords.length,
              rowSequence: Number(i) + 1,
              statusColour: this.getProgressBarColor(tRecord.status)[0],
              statusBorderColour: this.getProgressBarColor(tRecord.status)[1],
            };
          }
          rRecord.translationsRecords = this.translationsRecords;
          updatedRowData = rowData;
        });
      });
    }
    return updatedRowData;
  }

  getProgressBarColor(status) {
    let color = '';
    let statusBorder = '';
    if (status === 'Pending') {
      color = '#AD8E03';
      statusBorder = 'grey';
    } else if (status === 'Processing') {
      color = '#006400';
      statusBorder = 'darkseagreen';
    } else if (status === 'Error') {
      color = '#CC0000';
      statusBorder = 'lightgrey';
    }
    return [color, statusBorder];
  }

  private processResponse(rowData: any) {
    rowData = this.setStatusToolTip(rowData);
    this.processingFileRefreshDate = new Date();
    if (Array.isArray(rowData)) {
      // this.setRowData(tableId, rowData);
    }
  }

  private setDefaultSelectFormControlsBasedOnRole(role: Permission) {
    switch (role) {
      case Permission.VISITOR:
        this.tableOneFormControl = new UntypedFormControl({ value: this.viewOptions[0], disabled: true });
        this.cookieService.set('ewf-table-one-selection', this.viewOptions[0]);
        break;

      case Permission.EC:
        this.tableOneFormControl = new UntypedFormControl(this.viewOptions[1]);
        this.cookieService.set('ewf-table-one-selection', this.viewOptions[1]);
        break;

      case Permission.ADMIN:
      case Permission.LEADERSHIP:
        this.tableOneFormControl = new UntypedFormControl(this.viewOptions[1]);
        this.cookieService.set('ewf-table-one-selection', this.viewOptions[1]);
        break;

      case Permission.TESTER_AUDITOR:
        this.tableOneFormControl = new UntypedFormControl(this.viewOptions[4]);
        this.cookieService.set('ewf-table-one-selection', this.viewOptions[4]);
        break;

      case Permission.ERM:
        this.tableOneFormControl = new UntypedFormControl(this.viewOptions[1]);
        this.cookieService.set('ewf-table-one-selection', this.viewOptions[1]);
        break;

      case Permission.MANAGER:
        this.tableOneFormControl = new UntypedFormControl(this.viewOptions[2]);
        this.cookieService.set('ewf-table-one-selection', this.viewOptions[2]);
        break;

      case Permission.INTFEED:
        this.tableOneFormControl = new UntypedFormControl(this.viewOptions[5]);
        this.cookieService.set('ewf-table-one-selection', this.viewOptions[5]);
        break;

      case Permission.PROCESSOR:
        this.tableOneFormControl = new UntypedFormControl(this.viewOptions[0]);
        this.cookieService.set('ewf-table-one-selection', this.viewOptions[0]);
        break;

      default:
        this.tableOneFormControl = new UntypedFormControl({ value: this.viewOptions[0], disabled: true });
        this.cookieService.set('ewf-table-one-selection', this.viewOptions[0]);
    }

  }

  private prepareColumnsForServiceCall(): string[] {
    let masterViewColumnsForBackend;
    masterViewColumnsForBackend = masterEprColumns.map(column => column.field);

    const prjStatusIndex = masterViewColumnsForBackend.indexOf('mvPrjStatus');
    if (prjStatusIndex !== -1) {
      masterViewColumnsForBackend.splice(prjStatusIndex, 1);
    }
    const mvNewDiscDueDateIndex = masterViewColumnsForBackend.indexOf('mvNewDiscDueDate');
    if (mvNewDiscDueDateIndex === -1) {
      masterViewColumnsForBackend.push('mvNewDiscDueDate');
    }
    const mvNewDiscCmpltDateIndex = masterViewColumnsForBackend.indexOf('mvNewDiscCmpltDate');
    if (mvNewDiscCmpltDateIndex === -1) {
      masterViewColumnsForBackend.push('mvNewDiscCmpltDate');
    }
    const mvNewSetupDueDateIndex = masterViewColumnsForBackend.indexOf('mvNewSetupDueDate');
    if (mvNewSetupDueDateIndex === -1) {
      masterViewColumnsForBackend.push('mvNewSetupDueDate');
    }
    const mvNewSetupCmpltDateIndex = masterViewColumnsForBackend.indexOf('mvNewSetupCmpltDate');
    if (mvNewSetupCmpltDateIndex === -1) {
      masterViewColumnsForBackend.push('mvNewSetupCmpltDate');
    }
    const mvNewTstDueDateIndex = masterViewColumnsForBackend.indexOf('mvNewTstDueDate');
    if (mvNewTstDueDateIndex === -1) {
      masterViewColumnsForBackend.push('mvNewTstDueDate');
    }
    const mvNewTstCmpltDateIndex = masterViewColumnsForBackend.indexOf('mvNewTstCmpltDate');
    if (mvNewTstCmpltDateIndex === -1) {
      masterViewColumnsForBackend.push('mvNewTstCmpltDate');
    }
    const mvNewBLDueDateIndex = masterViewColumnsForBackend.indexOf('mvNewBLDueDate');
    if (mvNewBLDueDateIndex === -1) {
      masterViewColumnsForBackend.push('mvNewBLDueDate');
    }
    const mvNewBLCmpltDateIndex = masterViewColumnsForBackend.indexOf('mvNewBLCmpltDate');
    if (mvNewBLCmpltDateIndex === -1) {
      masterViewColumnsForBackend.push('mvNewBLCmpltDate');
    }
    const mvNewIDCrdsDueDateIndex = masterViewColumnsForBackend.indexOf('mvNewIDCrdsDueDate');
    if (mvNewIDCrdsDueDateIndex === -1) {
      masterViewColumnsForBackend.push('mvNewIDCrdsDueDate');
    }
    const mvNewIDCrdsCmpltDateIndex = masterViewColumnsForBackend.indexOf('mvNewIDCrdsCmpltDate');
    if (mvNewIDCrdsCmpltDateIndex === -1) {
      masterViewColumnsForBackend.push('mvNewIDCrdsCmpltDate');
    }

    const backupActiveEcIndex = masterViewColumnsForBackend.indexOf('backupActiveEc');
    if (backupActiveEcIndex === -1) {
      masterViewColumnsForBackend.push('backupActiveEc');
    }

    const backupEcOooFromDateIndex = masterViewColumnsForBackend.indexOf('backupEcOooFromDate');
    if (backupEcOooFromDateIndex === -1) {
      masterViewColumnsForBackend.push('backupEcOooFromDate');
    }

    const backupEcOooToDateIndex = masterViewColumnsForBackend.indexOf('backupEcOooToDate');
    if (backupEcOooToDateIndex === -1) {
      masterViewColumnsForBackend.push('backupEcOooToDate');
    }

    const backupEcOooFromDateTimestampIndex = masterViewColumnsForBackend.indexOf('backupEcOooFromDateTimestamp');
    if (backupEcOooFromDateTimestampIndex === -1) {
      masterViewColumnsForBackend.push('backupEcOooFromDateTimestamp');
    }

    const backupEcOooToDateTimestampIndex = masterViewColumnsForBackend.indexOf('backupEcOooToDateTimestamp');
    if (backupEcOooToDateTimestampIndex === -1) {
      masterViewColumnsForBackend.push('backupEcOooToDateTimestamp');
    }

    const activeEcIndex = masterViewColumnsForBackend.indexOf('activeEc');
    if (activeEcIndex === -1) {
      masterViewColumnsForBackend.push('activeEc');
    }


    const ecOOOFromDateIndex = masterViewColumnsForBackend.indexOf('ecOOOFromDate');
    if (ecOOOFromDateIndex === -1) {
      masterViewColumnsForBackend.push('ecOOOFromDate');
    }

    const ecOOOToDateIndex = masterViewColumnsForBackend.indexOf('ecOOOToDate');
    if (ecOOOToDateIndex === -1) {
      masterViewColumnsForBackend.push('ecOOOToDate');
    }

    const ecOOOFromDateTimestampIndex = masterViewColumnsForBackend.indexOf('ecOOOFromDateTimestamp');
    if (ecOOOFromDateTimestampIndex === -1) {
      masterViewColumnsForBackend.push('ecOOOFromDateTimestamp');
    }

    const ecOOOToDateTimestampIndex = masterViewColumnsForBackend.indexOf('ecOOOToDateTimestamp');
    if (ecOOOToDateTimestampIndex === -1) {
      masterViewColumnsForBackend.push('ecOOOToDateTimestamp');
    }

    const backupToEcIndex = masterViewColumnsForBackend.indexOf('backupToEc');
    if (backupToEcIndex === -1) {
      masterViewColumnsForBackend.push('backupToEc');
    }

    const consolidateProfilelogicIndex = masterViewColumnsForBackend.indexOf('235');
    if (consolidateProfilelogicIndex === -1) {
      masterViewColumnsForBackend.push('235');
    }
    const flErrorThresholdCntIndex = masterViewColumnsForBackend.indexOf('flErrorThresholdCnt');
    if (flErrorThresholdCntIndex === -1) {
      masterViewColumnsForBackend.push('flErrorThresholdCnt');
    }
    return masterViewColumnsForBackend;
  }


  setFilterColumns(event, filter, sort, title) {
    if (title === 'Processing Files') {
      this.PFLoaded = true;
    }
    event.api.setFilterModel(filter);
    event.api.applyColumnState({
      state: sort,
    });
    
    this.filterGrids.push(event);

    event.api.applyColumnState({
      state: sort,
    });
  }

  includeBackupUserData() {
    this.includeBackups = !this.includeBackups;
    this.eprService.includeBackup = this.includeBackups;
    this.includeBackupItems = [...BACKUP_TASKS];
    this.dashboardViews.forEach((task) => {
      if (BACKUP_TASKS.includes(task.title)) {
        this.gridAPis[task.title].showLoadingOverlay();
      }
    });
    this.loadProcessingFilesData(true);
    this.loadOneOneProjectsData(true);
    this.loadProjectsData(true);
    this.loadEprData(true);
    this.loadOpenEnrollmentData(true);
    this.loadFileMappingData(true);
    this.loadFTOnboardingEligibilityData(true)
  }

  enableEdit(cancel?) {
    let gridOptions = this.dashboardViews.find(data => data.id == '14').gridOptions
    let title = this.dashboardViews.find(data => data.id == '14').title

    if (cancel) {
      this.editCagRefManual = false;

      this.gridAPis[title].forEachNode(rowNode => rowNode.data.cagRefManualCheck = false);
      this.gridAPis[title].refreshCells();
      this.disableCagEdit();
      this.loadCagRefManualData(true);
    }
    if (!this.editCagRefManual && !cancel) {
      this.editCagRefManual = true;
    }
    this.gridAPis[title].setGridOption("context", {
      allowEdit: this.editCagRefManual,
    })
    if (!this.editCagRefManual) {
      this.gridAPis[title].stopEditing();
    }

  }
  addCagRef(cancel?) {
    let gridOptions = this.dashboardViews.find(data => data.id == '14').gridOptions;
    let title = this.dashboardViews.find(data => data.id == '14').title
    if (!this.addCagRefManual) {
      //initial add
      this.gridAPis[title].forEachNode(rowNode => {
        rowNode.data.cagRefManualCheck = false; //this will uncheck previously checked rows.
      })

    }
    if (cancel) {
      this.addCagRefManual = false;
      const deletingRowNodes = [];
      this.gridAPis[title].forEachNode((rowNode, index) => {
        if (rowNode.data.id === null) {
          this.gridAPis[title].applyTransaction({remove:[rowNode.data]});
        }
      })
      this.disableCagEdit();

    } else {

      this.addCagRefManual = true;
      this.editCagRefManual = false;
      const addedRow = this.gridAPis[title].applyTransaction({
        add: [{
          cagRefManualCheck: true,
          cagTableId: '',
          cagReferenceId: '',
          carrier: '',
          careQual: '',
          careNetwork: '',
          // ec:this.loggedInUserName,
          ec: '',
          account: '',
          group: '',
          fromDate: '',
          termDate: '',
          status: '',
          id: null,
          clientProd: '',
          createdBy: this.userLdapID,
          createdTime: '',
          lastUpdatedBy: this.userLdapID,
          lastUpdatedTime: ''

        }]
      });
      addedRow.add[0].setSelected(true);
      this.gridAPis[title].ensureIndexVisible(addedRow.add[0].rowIndex, 'bottom');
      // show msg
      // this.alertService.sendAlert({
      //   // headline: message ? message : 'Save successful !',
      //   headline: "CAG Added Successfully (CAG Manual add) ",
      //   alertType:  CVSAlertType.Success,
      //   removedAfterMilliseconds: 5000,
      //   alertDocumentHolderName: '#dashboard-notification',
      // });
    }
    this.gridAPis[title].setGridOption("context", {
      addTable: this.addCagRefManual,
      saveTriggered: false
    })
    this.gridAPis[title].refreshCells({ force: true });
  }

  deleteCag() {
    const infoDialog = this.dialog.open(InfoDialogComponent, {
      data: {
        body: 'Are you sure want to delete the selected item(s)?  This action cannot be undone.',
        type: '',
        buttonText: 'Delete',
        cancelBtnText: 'Cancel',
        onConfirm: () => {
          infoDialog.close();
          this.deleteCagAfter();
        }
      }
    });
  }

  deleteCagAfter() {
    let gridOptions = this.dashboardViews.find(data => data.id == '14').gridOptions;
    let title= this.dashboardViews.find(data => data.id == '14').title;
    const selectedNodes = [];
    this.gridAPis[title].forEachNode(rowNode => {
      if (rowNode.data.cagRefManualCheck) {
        selectedNodes.push(rowNode.data);
      }
    })
    this.gridAPis[title].applyTransaction({ remove: [selectedNodes] })
    this.eprService.deleteCagRefManual(selectedNodes).subscribe(resp => {
      if (resp.status === "SUCCESS") {
        this.showMessage({ header: "CAG Deleted Successfully" })
        this.loadCagRefManualData(true);
      } else {
        this.showMessage({ header: "ERROR !", body: resp.message }, 'error')
      }
      this.disableEditButton1 = true;
    }, (err) => this.showMessage({ header: "ERROR !", body: "Something went wrong. Please try again." }, 'error'));
  }

  saveCag() {
    let gridOptions = this.dashboardViews.find(data => data.id == '14').gridOptions;
    let title = this.dashboardViews.find(data => data.id == '14').title;
    function isValid(param, key) {
      if ((param != undefined && param != null && param?.toString().trim() !== '')
         || (key === 'careQual' && (param === '' || param?.toString().trim() === ''))
         || (key === 'careNetwork' && (param === '' || param?.toString().trim() === ''))
         || (key === 'clientProd' && (param === '' || param?.toString().trim() === ''))) {
        return true;
      } else {
        return false;
      }
    }
    const currentColDefs = gridOptions.columnDefs.filter((data: any) => data.field !== "cagRefManualCheck")
      .map((data: any) => {
        if (data.field !== 'id' && data.field !== 'ec') {
          return data.field;
        }

      });
      this.gridAPis[title].stopEditing();
    gridOptions.context['saveTriggered'] = true;
    this.gridAPis[title].refreshCells({ force: true });
    let isInvalidDataSet = false;
    const rowDataArray = [];
    this.gridAPis[title].forEachNode(rowNode => {
      if (rowNode.data.cagRefManualCheck) {
        const dataObj = rowNode.data;
        const keys = Object.keys(dataObj);
        for (let i = 0; i < keys.length; i++) {
          if (currentColDefs.includes(keys[i]) && !isValid(dataObj[keys[i]], keys[i])) { //data missing
            isInvalidDataSet = true;
          }
        }
        rowDataArray.push(rowNode.data);
      }
    })
    if (!isInvalidDataSet) {
      if (this.addCagRefManual) {
        this.eprService.addCagRefManual(rowDataArray).subscribe(resp => {
          if (resp.status === "SUCCESS") {
            this.showMessage({ header: "CAG Added Successfully" })
            this.loadCagRefManualData(true);
          } else {
            this.showMessage({ header: "ERROR !", body: resp.message }, 'error')
          }
        });
      } else {
        this.eprService.updateCagRefManual(rowDataArray).subscribe(resp => {
          if (resp.status === "SUCCESS") {
            this.showMessage({ header: "CAG Updated Successfully" })
            this.loadCagRefManualData(true);
          } else {
            this.showMessage({ header: "ERROR !", body: resp.message }, 'error')
          }
        });
      }
    }
    this.editCagRefManual = false;
    this.addCagRefManual = false;
  }


  disableCagEdit() {
    let gridOptions = this.dashboardViews.find(data => data.id == '14').gridOptions;
    let title = this.dashboardViews.find(data => data.id == '14').title;
    const selectedNodes = [];
    this.gridAPis[title].forEachNode(rowNode => {
      if (rowNode.data.cagRefManualCheck) {
        selectedNodes.push(rowNode);
      }
    })
    if (selectedNodes.length) {
      this.disableEditButton1 = false;
    } else {
      this.disableEditButton1 = true;
    }
  }

  openCagBatchModel() {
    const dialogData = this.dialog.open(CagRefBatchUploadComponent, {});
    dialogData.afterClosed().subscribe(resp => {
      if (resp?.status === "SUCCESS") {
        this.showMessage({ header: "CAG Batch Request Initiated" });
        this.loadCagRefBatchData(true);
      } else if (resp?.status === "ERROR") {
        this.showMessage({ header: "Cag File Uploaded Errored !", body: resp?.message }, 'error');
      } else {
        return;
      }
    })
  }
 
  getBackendMapped(value, cond) {
    if (cond === "fromDate" || cond === "termDate") {
      switch (value) {
        case "equals":
          return 'EQUAL';
          break;
        case "notEqual":
          return 'NOT BETWEEN';
          break;
        case "greaterThan":
          return 'GREATER_THAN'
          break;
        case "lessThan":
          return 'LESS_THAN'
          break;
        default: return null
      }
    } else {
      switch (value) {
        case 'contains':
          return 'LIKE'
          break;
        case "notContains":
          return 'NOT_LIKE';
          break;
        case "equals":
          return 'EQUAL';
          break;
        case "notEqual":
          return 'NOT_EQUAL';
          break;
        case "startsWith":
          return 'STARTS_WITH'
          break;
        case "endsWith":
          return 'ENDS_WITH'
          break;
        default: return null
      }
    }

  }

  prepareProcessingFilesRecentlyViewedEprPayload(data: any) {
    if (data) {
      const obj = {
        userId: this.userId,
        recentlyViewedEpr: 0,
        clientName: data.g1,
        viewName: 'Processing Files',
        sequence: 0,
        userUniqueKey: data.g6,
        data: JSON.stringify(data),
      };
      return obj;
    }
  }
  prepareFTOnboardingRecentlyViewedEprPayload(data: any) {
    if (data) {
      const obj = {
        userId: this.userId,
        recentlyViewedEpr: 0,
        clientName: data.clientName,
        viewName: 'FT Onboarding - Eligibility',
        sequence: 0,
        userUniqueKey: data.cltSetupID,
        data: JSON.stringify(data),
      };
      return obj;
    }
  }
  fetchProcessingFiles(epr: any) {
    if (!this.processingFilesModalOpen) {
      this.processingFilesModalOpen = true;
      const dialogRef = this.dialog.open(ThreasholdDialogComponent, {
        width: '60%',
        height: '70vh',
        disableClose: true,
        panelClass: 'my-dialog',
        data: {
          selected: 'Processing Files',
          body: JSON.parse(epr.data),
        }
      });

      dialogRef.afterClosed().subscribe((confirmed: boolean) => {
        this.processingFilesModalOpen = false;
        // this.updateMyProcessingFilesWithOnFilterChange($event, $event.api.getFilterModel());
      });

    }
  }
  fetchFTOnboardingEligibility(epr: any) {
    this.store.dispatch(new LoadFiletrackerAction(epr.userUniqueKey));
  }

  showMessage(message, type = null) {
    this.alertService.sendAlert({
      // headline: message ? message : 'Save successful !',
      headline: message.header,
      body: message.body,
      alertType: type === 'error' ? CVSAlertType.Error : CVSAlertType.Success,
      removedAfterMilliseconds: 5000,
      alertDocumentHolderName: '#dashboard-notification',
    });
    document.getElementById('dashboard-notification').scrollIntoView();
  }

  dbToggle(task: any,event) {
  const db = event.value;
    if(task.title === 'Processing Files'){
      this.db.processingFiles = db;
      let dateText = 'Last On:'
      const processDate = new Date().toTimeString()
      task.RefreshDate = dateText + " " + processDate.slice(0, 8);
      this.gridAPis[task.title].setGridOption('loading', true)
      const myProcessingFilter = this.gridAPis[task.title].getFilterModel()
      this.getMyProcessingFilesWithFilter(myProcessingFilter, true, this.db.processingFiles);
      this.gridAPis[task.title].setFilterModel(myProcessingFilter)
    }

  }
  getDb(task){
    if(task.title === 'Processing Files'){
    return this.db.processingFiles;
    }
  }
  getUserPreferences() {
    this.usersService.getPreferences(this.userId).subscribe(res => {
      if (res.response !== null) {
        this.gridColumnsList = res.response
      }
    })
  }

  saveUserPreferences(payload) {
    const observables = payload.map(columnList =>
      this.usersService.savePreference(columnList).pipe(
        catchError(error => {
          return of(null);
        })
      ).subscribe(res => {
        let count = 0
        if (res) {
          count++
        }
        if (count === payload.length) {
          this.cookieService.delete('columnList')
        }
      })
    );
  }

  onGridReady = (params,title) => {
    this.gridAPis[title] = params.api
    if(title === 'CAG REF (Manual)'){
      this.gridAPis[title].setGridOption('rowClassRules',{'selected': (params)=>{return params.data.cagRefManualCheck}})
    }
     let listOfCols:any = []

        this.gridColumnsList.forEach(col=>{
          listOfCols = col['orderOfColumn'].split(',')
          if(col['dashboardComponent'] === title){
            // col[0]['orderOfColumn'] = this.PreferencepayLoad[0]['orderOfColumn'];
            // params.columnApi.moveColumns(listOfCols,0)
          }
        })

}

configIfeedsLabels(params:any){
    this.IntFeeds_AtRisk = 0
    this.IntFeeds_Overdue = 0
    this.IntFeeds_SetupNeeded = 0
    this.IntFeeds_Completed = 0;
    params.api.forEachNodeAfterFilter((rowData) => {
      // records.push(rowData.data)
      const record = rowData.data;
      if (record.i15 === null) {
        const today = new Date();
        const duedate = new Date(record.i6);
        const daysDifference = Math.floor(
          (duedate.getTime() - today.getTime()) / (1000 * 3600 * 24)
        );
        if (daysDifference <= 0) {
          this.IntFeeds_Overdue++;
        } else if (daysDifference >= 1 && daysDifference <= 30) {
          this.IntFeeds_AtRisk++;;
        } else {
          this.IntFeeds_SetupNeeded++
        }
      } else {
        this.IntFeeds_Completed++;
      }
  })

}

  ngOnDestroy() {
    if (this.changesDone) {
      this.saveUserPreferences(this.allPreferenceList)
    }

  }

}
