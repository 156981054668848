<cvs-alert-placeholder id="master-data-alert"></cvs-alert-placeholder>
<!--<div [ngClass]="currentDropdownSelection && (currentDropdownSelection=='fieldActions' || currentDropdownSelection =='validations' ||  currentDropdownSelection =='rejectActions')? 'dm-outerClass' : ''">
-->
<div [ngClass]="showInlineItems() ? 'dm-outerClass': ''">
<!--  <mat-form-field *ngIf="!showInlineItems()">-->
<!--  <mat-label for="currentDropdownSelection">Select Master Data List</mat-label>-->
<!--  <mat-select [(value)]="currentDropdownSelection" id="currentDropdownSelection" (selectionChange)="onSelectionChange($event)">-->
<!--    <mat-option *ngFor="let datum of masterDataList" [value]="datum.value">-->
<!--      {{datum.displayName}}-->
<!--    </mat-option>-->
<!--  </mat-select>-->
<!--</mat-form-field>-->

  <div style="display: flex;gap:11px">
    <div style="border-right: 1px solid #CCC9C6">
      <div class="accordion-filter-gap">
        <div class="accordion-heading">Filter</div>
        <div style="display: flex;align-items: center">
          <input type="text" (input)="search($event)" style="height:32px;border-radius: 8px;border:1px solid #1A1A19; padding:0px 25px 0px 8px;"/>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" style="margin-left: -30px">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M3.65421 3L11.333 12.282V20.176L12.6661 18.842V12.282L20.3448 3H3.65421ZM10.7499 23C10.5619 23 10.3789 22.964 10.2069 22.893C9.6758 22.673 9.33277 22.158 9.33277 21.583V13.003L1.32398 3.32C0.975947 2.897 0.90194 2.307 1.13296 1.815C1.36599 1.319 1.86903 1 2.41509 1H21.584C22.127 1 22.6301 1.317 22.8631 1.81L22.8661 1.815C23.0981 2.307 23.0241 2.897 22.6791 3.317L14.6663 13.003V19.083C14.6663 19.465 14.5173 19.822 14.2472 20.09L11.752 22.585C11.479 22.855 11.1249 23 10.7499 23Z" fill="#1A1A19"/>
          </svg>
        </div>
      </div>
      <mat-accordion [multi]="true" class="data-accordion">
        <mat-expansion-panel #expansionPanel [expanded]="item.expanded" *ngFor="let item of accordionData " style="width: 220px">
          <mat-expansion-panel-header #header style="width:190px;padding: 0px 12px !important;"
                                      [ngStyle]="{'background':expansionPanel.expanded ? '#F2EFEB' :'#FFF', 'border-left': expansionPanel.expanded ? '5px solid #DB3321' : ''}">
            <mat-panel-title>
              <div [attr.aria-expanded]="expansionPanel.expanded" style="display: flex;flex-direction: column;gap:4px;cursor: pointer">
                <div class="accordion-heading" [ngStyle]="{'color':expansionPanel.expanded ? '#DB3321' :'#1A1A19'}">{{item.name}}</div>
              </div>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div *ngFor="let data of item.listData" (click)="showSelectedData(data.value)" style="width:256px;padding: 8px 16px 8px 12px;border-bottom: 1px solid #CCC9C6;cursor:pointer;">
            <div class="accordion-line-item">{{data.displayName}}</div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>

    <div *ngIf="this.currentlyDisplayingOptions" class="" [ngClass]="showInlineItems() ? 'dm-outergrid' : 'dropdown-options'" >
      <div *ngIf="this.currentDropdownSelection !== 'translations'">
        <div class="selected-options" *ngIf="selectedDataListOptions.length > 0 && showDelete">
          <span id="num-selected">{{this.selectedDataListOptions.length}} Selected</span>
          <button id="delete-btn"
                  class="pds-btn-text-only"
                  (click)="deleteOptions()"
          ><mat-icon svgIcon="trash--s"></mat-icon>Delete</button>
        </div>

        <div id="save-and-add" style="width: 700px; margin-top: 10px;">
          <mat-form-field *ngIf="showInlineItems() && !isFieldLookup()">
            <mat-label for="currentDropdownSelection">Select Master Data List</mat-label>
            <mat-select [(value)]="currentDropdownSelection" id="currentDropdownSelection" (selectionChange)="onSelectionChange($event)">
              <mat-option *ngFor="let datum of masterDataList" [value]="datum.value">
                {{datum.displayName}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <button *ngIf= "!isFieldLookup() && showInlineItems()" style="cursor:pointer;" (click)="exportView(currentDropdownSelection)" class="add-export-btn">
            <mat-icon svgIcon="file-csv--s"  id="dataExportBtn" >
              Export View
            </mat-icon>
            <span class="export-txt"> Export </span>
          </button>

          <button id="add-btn1"  [ngClass]="showInlineItems() ? 'add-record-btn' : 'pds-btn-text-only'"
                  (click)="addButton()" *ngIf="!isFieldLookup() && currentDropdownSelection !=='mappingInstructions'">
           <span style="gap:10px; font-weight: 800" class="icon-text"><mat-icon svgIcon="plus--s"></mat-icon>Add Record</span></button>

          <button
            class="pds-btn-primary"
            id="dataSaveBtn"
            *ngIf="!isFieldLookup()" (click)="saveMasterDataList()" [disabled]="isSaveDisabled">Save</button>
        </div>
      </div>

      <div cdkDropList class="example-list" (cdkDropListDropped)="drop($event)">
        <div *ngIf="addOptionDialog && !externalComponentList.includes(currentDropdownSelection)"
             id="new-option1"
             class="example-box">
          <div>
            <mat-icon id="new-option-checkbox" svgIcon="drag-drop-dot--s"></mat-icon>
          </div>
          <div>
            <mat-checkbox [disabled]="true"></mat-checkbox>
          </div>
          <textarea id="options-to-add"
                    style="margin-left: 9px; padding-left: 3px; border: solid 1px #02a192"
                    #addOptionSpan
                    matInput
                    [(ngModel)]="optionsToAdd"
                    cdkTextareaAutosize
                    cdkAutosizeMinRows="1">
      </textarea>
        </div>
        <div mt={2} *ngIf="currentDropdownSelection === 'holidays'">
          <ag-grid-angular class="ag-theme-alpine"
                           [ngStyle]="{'height': hlGridHeight + 'px', width:'525px' }"
                           id="holidayGrid"
                           (cellValueChanged)="holidayRecUpdated($event)"
                           [context]="contextObj"
                           [stopEditingWhenCellsLoseFocus]="true"
                           [stopEditingWhenGridLosesFocus]="true"
                           (gridReady)="onGridReady($event)"
                           [rowData]="holidaysData">

          </ag-grid-angular>
        </div>

        <div *ngIf="currentDropdownSelection === 'mappingInstructions'"
             id="new-option"
             class="example-box">
      <textarea id="mappingInstructionsText"
                style="margin-left: 9px;font-size: 16px; padding-left: 3px; border: solid 1px #02a192"
                matInput
                cdkTextareaAutosize
                cdkAutosizeMinRows="1"
                appBulletCreater
                [(stringArray)]="mappingComments"
                (input)="editComments()"
                *ngIf="commentsReady"
      >
 </textarea>

        </div>

        <!-- Translations Grid -->
        <app-translations-data-grid *ngIf="currentDropdownSelection === 'translations'"></app-translations-data-grid>

        <!-- thresholds Grid -->
        <app-thresholds-data-grid (isSaveCalled)="savedInfo()" *ngIf="currentDropdownSelection === 'threshold'"></app-thresholds-data-grid>

        <!-- Admin Alert Type Grid-->
        <app-admin-alert-type (isSaveCalled)="savedInfo()" *ngIf="currentDropdownSelection === 'adminAlertType'"></app-admin-alert-type>

        <!--Message Supress Grid -->
        <app-msg-supress-data-grid  *ngIf="currentDropdownSelection === 'msgSupression'" (isSaveMsgCalled)="savedInfo()" ></app-msg-supress-data-grid>
        <!--Map Type Grid -->
        <app-map-type  *ngIf="currentDropdownSelection === 'mapType'" (isSaveMapTypeCalled)="savedInfo()" ></app-map-type>
        <!--Reject Action -->
        <app-reject-action *ngIf="currentDropdownSelection === 'rejectActions'" (isRejectActionCalled)="savedInfo()"></app-reject-action>
        <app-validation  *ngIf="currentDropdownSelection === 'validations'" (isSaveValidationCalled)="savedInfo()" ></app-validation>
        <!--Field Action -->
        <app-field-actions  *ngIf="currentDropdownSelection === 'fieldActions'" (isFieldActionsCalled)="savedInfo()" ></app-field-actions>
        <app-reporting-message-types *ngIf="currentDropdownSelection === 'reportingMessageTypes'" (isSaveReportingMessageTypesCalled)="savedInfo()" ></app-reporting-message-types>
        <app-reporting-message-codes  *ngIf="currentDropdownSelection === 'reportingMessageCodes'" (isReportingMessageCodes)="savedInfo()" ></app-reporting-message-codes>
        <app-relationship-code *ngIf="currentDropdownSelection === 'relationshipCode'"></app-relationship-code>
        <app-sex-code *ngIf="currentDropdownSelection === 'sexCode'"></app-sex-code>
        <app-family-type *ngIf="currentDropdownSelection === 'familyType'"></app-family-type>
        <app-print-format-code *ngIf="currentDropdownSelection === 'printFormatCode'"></app-print-format-code>
        <app-member-type *ngIf="currentDropdownSelection === 'memberType'"></app-member-type>
        <app-med-coverage-type *ngIf="currentDropdownSelection === 'medCoverageType'"></app-med-coverage-type>
        <app-aid-type *ngIf="currentDropdownSelection === 'aidType'"></app-aid-type>
        <app-lics *ngIf="currentDropdownSelection === 'lics'"></app-lics>
        <app-ethnicity *ngIf="currentDropdownSelection === 'ethnicity'"></app-ethnicity>
        <app-languages *ngIf="currentDropdownSelection === 'languages'"></app-languages>
        <app-reason-codes  *ngIf="currentDropdownSelection === 'reasonCodes'" (isSaveReasonCodesCalled)="savedInfo()" ></app-reason-codes>


        <button id="add-btn"  class="pds-btn-text-only fieldLookupAdd"
                (click)="addButton()" *ngIf="isFieldLookup()">
          <span style="gap:10px; font-weight: 800" class="icon-text"><mat-icon svgIcon="plus--s"></mat-icon>Add Record</span></button>

        <!--Version Typr -->

        <app-version-control-data-grid [type]="currentDropdownSelection" *ngIf="currentDropdownSelection === 'isa12' || currentDropdownSelection === 'gs08' || currentDropdownSelection === 'st03'" (isSaveMapTypeCalled)="savedInfo()" >
        </app-version-control-data-grid>
        <!-- <ng-template #commonGrid>
        </ng-template> -->
        <div *ngIf="hideoverlapListOptions()">
          <div class="example-box" *ngFor="let datum of dataListOptions; let indexOfDatum = index" cdkDrag>
            <mat-icon svgIcon="drag-drop-dot--s"></mat-icon>
            <mat-checkbox (change)="updateSelectedList(indexOfDatum)"></mat-checkbox>
            <span>{{datum}}</span>
          </div>
        </div>

      </div>

    </div>
  </div>
</div>
