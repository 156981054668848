

<mat-dialog-content class="row seperate mb-m">
   
    <header>
        <h1>{{data?.label}}</h1>
    </header>
    <mat-icon svgIcon="close-btn--s" (click)="close()"></mat-icon>
</mat-dialog-content>


<div class="row mb-s">Carrier Levels</div>
<div class="grid">
    <ag-grid-angular class="ag-theme-alpine pds-ag-grid" #pliClientgrid 
    [context]="context"
    (gridReady)="onGridReady($event)"
    (cellValueChanged) ="verifyChanges()"
   
   >
    </ag-grid-angular>
</div>
<div style="float: right;">
    <button class="pds-btn-text-only" id="add-addt-contact-btn" (click)="addRow()" *ngIf="!isEprReadOnly">
<mat-icon svgIcon="plus--s"></mat-icon>Add Row</button>
</div>
<mat-dialog-actions align="end" class="mtb-m row" *ngIf="!data?.ssoeData">
   <button class="pds-btn-ghost" (click)="close()">Cancel</button>
   <button class="pds-btn-primary" *ngIf="!isEprReadOnly" [disabled]="(isEprReadOnly? true :readOnly  )" (click)="save()">Save</button>
</mat-dialog-actions>