
<ng-container *ngIf="!isDelete">
    <h2>{{ title }}
      <button mat-icon-button class="close-button" mat-dialog-close>
        <mat-icon mat-dialog-close class="close-icon">close</mat-icon>
      </button>    
    </h2>
    <p class="msg">Input the following to {{mode}} {{type}} line item</p>
    <form [formGroup]="addEditItem">
    <mat-dialog-content class="mat-typography">
       <mat-form-field>
        <label>{{fieldType}}</label>
        <input matInput placeholder="" formControlName="message">
        <mat-error *ngIf="addEditItem?.controls['message'].errors?.required"
      >Message is required</mat-error>
      </mat-form-field>
      <label>Member or Group</label>
        <mat-form-field appearance="outline">
          <mat-select  formControlName="reportMessageType" class="mat-select-dropdown"> 
              <mat-option [value]="o.name" *ngFor="let o of options">
                 {{o.name}}
              </mat-option>
          </mat-select>
          <mat-error *ngIf="addEditItem?.controls['reportMessageType'].errors?.required"
      >Drop Down Value is required</mat-error>
        </mat-form-field>
   
      <label>Description</label>
      <mat-form-field appearance="outline">
        <textarea matInput (keyup)="findcharacterCount($event)" formControlName="messageDescription"></textarea>
      </mat-form-field>
      <span [ngClass]="characterCount < 61 ? 'character-count-gray': 'character-count-red'"> Character Count: <span>{{ characterCount }}</span></span>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button mat-button mat-dialog-close class="btn-cancel">Cancel</button>
      <button mat-button class="pds-btn-primary" (click)="save()" cdkFocusInitial>Save</button>
    </mat-dialog-actions>
    </form>
   </ng-container>
   
  <ng-container *ngIf="isDelete">
    <button mat-icon-button class="close-button" mat-dialog-close>
      <mat-icon class="close-icon">close</mat-icon>
    </button> 
    <mat-dialog-content class="mat-typography">
      <h2 class="msg">Delete Row</h2>
      <p class="msg">Are you sure you want to delete this row ? </p>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button mat-button mat-dialog-close class="btn-cancel">Cancel</button>
      <button mat-button (click)="deleteRecord()" class="pds-btn-primary" cdkFocusInitial>Delete</button>
    </mat-dialog-actions>
   </ng-container>