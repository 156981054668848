<div>
<cvs-alert-placeholder id="dashboard-notification"></cvs-alert-placeholder>
  <mat-drawer-container class="sidenav-container" hasBackdrop="false">
    <mat-drawer #sideNav mode="over" position="end">
      <mat-icon style="cursor:pointer;" class="sidebar-close-btn" (click)="sideNav.toggle()">close</mat-icon>
      <h1 class="recent-eprs-header">Recent Items</h1>
      <ng-container *ngFor="let epr of recentlyViewedEprs">
        <div *ngIf="epr.viewName!== 'Processing Files' && epr.viewName !== 'FT Onboarding - Eligibility'"
          style="cursor:pointer;" class="recently-viewed-epr-record" (click)="fetchRecentEpr(epr.recentlyViewedEpr)">
          <b #tooltip="matTooltip" [matTooltip]="epr.clientName" matTooltipClass="tooltip-bg-color"
            matTooltipHideDelay="500">{{(epr.clientName && epr.clientName.length > 22) ? (epr.clientName | slice:0:22) +
            '...' : epr.clientName }}</b> - <u>{{epr.viewName}}</u>
        </div>
        <div *ngIf="epr.viewName === 'Processing Files'" style="cursor:pointer;" class="recently-viewed-epr-record"
          (click)="fetchProcessingFiles(epr)">
          <b #tooltip="matTooltip" [matTooltip]="epr.clientName" matTooltipClass="tooltip-bg-color"
            matTooltipHideDelay="500">{{(epr.clientName && epr.clientName.length > 22) ? (epr.clientName | slice:0:22) +
            '...' : epr.clientName }}</b> - <u>{{epr.viewName}}</u>
        </div>
        <div *ngIf="epr.viewName === 'FT Onboarding - Eligibility'" style="cursor:pointer;"
          class="recently-viewed-epr-record" (click)="fetchFTOnboardingEligibility(epr)">
          <b #tooltip="matTooltip" [matTooltip]="epr.clientName" matTooltipClass="tooltip-bg-color"
            matTooltipHideDelay="500">{{(epr.clientName && epr.clientName.length > 22) ? (epr.clientName | slice:0:22) +
            '...' : epr.clientName }}</b> - <u>{{epr.viewName}}</u>
        </div>
      </ng-container>
    </mat-drawer>
    <mat-drawer-content>
      <div id="right-panel">
        <div class="dashboard-heading">
          <div style="display: flex; align-items: center; height: 36px">
            <h1 id="right-pane-header">Dashboard</h1>
            <mat-form-field id="filter-text-box" *ngIf="dashboardObject?.buttons.DYNAMIC_SEARCH" style="margin-left: 16px">
              <input style="cursor:pointer;" [disabled]="!allLoaded" matInput type="text" placeholder="Search"
                (input)="filterTables($event.target.value)" />
              <mat-icon style="margin-top: 10px" matSuffix>search</mat-icon>
            </mat-form-field>
            <mat-slide-toggle id="include-backups-toggle" *ngIf="dashboardObject?.buttons.INCLUDE_BACKUP" [disabled]="!allLoaded" [checked]="this.includeBackups"
              (change)="includeBackupUserData()">
            </mat-slide-toggle>
            <span class="include-backups-toggle-lable" *ngIf="dashboardObject?.buttons.INCLUDE_BACKUP">Include Backup</span>
          </div>
          <div>
            <!--
        <button *ngIf="currentUserId" id="fetchRecentEprs" class="pds-btn-white" (click)="sideNav.toggle();fetchRecentEpr(epr.eprId)">
-->

            <button id="fetchRecentEprs" *ngIf=" dashboardObject?.buttons.RECENT_EPR"   class="pds-btn-white" (click)="getRecentlyViewedEprs();">
              <mat-icon svgIcon="list--s"></mat-icon>
              Recent Items
            </button>


          </div>
        </div>
        <div id="dashboard-table-one" class="dashboard-table">

          <div class="row">
            <div class="col-md-6">

              <mat-accordion cdkDropList [cdkDropListData]="dashboardViews" (cdkDropListDropped)="drop($event)"
                multi="true">
                <ng-container *ngFor="let task of dashboardViews | orderBy : 'seq':'asc'">
                  <mat-expansion-panel [togglePosition]="'before'" [disabled]="clickButton" (click)="clickButton=false"
                     (cdkDragStarted)="dragStarted($event)"
                    (opened)="accordStatus(task.title)" (closed)="accordStatus(task.title,true)" cdkDrag
                    (afterExpand)="getIsAnyExpanded(task.title)" (afterCollapse)="getIsAnyExpanded()"
                    [cdkDragDisabled]="!allLoaded || isAnyExpanded || includeBackupItems.length>0">
                    <mat-expansion-panel-header *ngIf="!task.isLoaded">
                      <mat-panel-title>
                        <!-- <mat-icon id="new-translations-option-checkbox" svgIcon="drag-drop-dot--s"></mat-icon> -->
                        <b>&nbsp;{{ task.title }}</b>&nbsp;&nbsp;&nbsp;&nbsp; Loading...
                      </mat-panel-title>
                    </mat-expansion-panel-header>

                    <mat-expansion-panel-header *ngIf="task.isLoaded">
                      <mat-panel-title style="width: 20em">
                        <!-- <span><mat-icon id="new-translations-option-checkbox1" svgIcon="drag-drop-dot--s"></mat-icon> </span>&nbsp; -->
                        <span><b>{{ task.title }}</b></span> &nbsp;&nbsp;
                        <span >({{(task.gridOptions &&
                          task.gridOptions.rowData && task.rowData.currentRowCount) ? task.rowData.currentRowCount : 0}})</span>
                      </mat-panel-title>

                      <mat-panel-description style="flex: auto;"
                        *ngIf="(task.title === 'Projects' || task.title === '1/1 Projects')">
                        <!-- Projects, 11/Projects-->
                        <span *ngIf="(task.rowData.redRecordsCount > 0)" class="indicator red-indicator"></span>
                        <span *ngIf="(task.rowData.redRecordsCount > 0)">Past Due
                          {{task.rowData.redRecordsCount}}&nbsp;&nbsp;&nbsp;</span>
                        <span *ngIf="(task.rowData.yellowRecordsCount > 0)" class="indicator yellow-indicator"></span>
                        <span *ngIf="(task.rowData.yellowRecordsCount > 0)">Due Soon
                          {{task.rowData.yellowRecordsCount}}&nbsp;&nbsp;&nbsp;</span>
                        <span *ngIf="(task.rowData.greenRecordsCount > 0)" class="indicator green-indicator"></span>
                        <span *ngIf="(task.rowData.greenRecordsCount > 0)">On Track
                          {{task.rowData.greenRecordsCount}}</span>
                          <span *ngIf="(task.rowData.greyRecordsCount > 0)" class="indicator gray-indicator"></span>
                          <span *ngIf="(task.rowData.greyRecordsCount > 0)">Completed
                            {{task.rowData.greyRecordsCount}}</span>
                          <span *ngIf="(task.rowData.purpleRecordsCount > 0)" class="indicator violet-indicator"></span>
                          <span *ngIf="(task.rowData.purpleRecordsCount > 0)">Cancelled
                            {{task.rowData.purpleRecordsCount}}</span>
                      </mat-panel-description>


                      <!-- Testing-->
                      <mat-panel-description style="flex: auto;" *ngIf="(task.title === 'Testing')">
                        <span *ngIf="(task.rowData.failedStatusRecordsCount > 0)"
                          class="indicator red-indicator"></span>
                        <span *ngIf="(task.rowData.failedStatusRecordsCount > 0)">Fail
                          {{task.rowData.failedStatusRecordsCount}}&nbsp;&nbsp;&nbsp;</span>

                        <span *ngIf="(task.rowData.insuffcientDataRecordsCount > 0)"
                          class="indicator orange-indicator"></span>
                        <span *ngIf="(task.rowData.insuffcientDataRecordsCount > 0)">Insufficient Data
                          {{task.rowData.insuffcientDataRecordsCount}}&nbsp;&nbsp;&nbsp;</span>

                        <span *ngIf="(task.rowData.passRecordsCount > 0)" class="indicator green-indicator"></span>
                        <span *ngIf="(task.rowData.passRecordsCount > 0)">Pass
                          {{task.rowData.passRecordsCount}}&nbsp;&nbsp;&nbsp;</span>

                        <span *ngIf="(task.rowData.nullRecordsCount > 0)" class="indicator yellow-indicator"></span>
                        <span *ngIf="(task.rowData.nullRecordsCount > 0)">In Progress {{task.rowData.nullRecordsCount}}</span>
                      </mat-panel-description>

                      <mat-panel-description style="flex: auto;" *ngIf="(task.title === 'FT Onboarding - Eligibility')">
                        <!--FT Onboarding - Eligibility-->
                        <span *ngIf="(task.rowData.inActiveCount > 0)" class="indicator red-indicator"></span>
                        <span *ngIf="(task.rowData.inActiveCount > 0)">Inactive {{task.rowData.inActiveCount}}</span>

                        <span *ngIf="(task.rowData.activeCount > 0)" class="indicator green-indicator"></span>
                        <span *ngIf="(task.rowData.activeCount > 0)">Active {{task.rowData.activeCount}}&nbsp;&nbsp;&nbsp;</span>


                      </mat-panel-description>

                      <!-- QC/Audit-->
                      <mat-panel-description style="flex: auto;" *ngIf="(task.title === 'QC/Audit')">
                        <span *ngIf="qcPastDueCount > 0" class="indicator red-indicator"></span>
                        <span *ngIf="qcPastDueCount > 0">Past Due
                          {{qcPastDueCount}}&nbsp;&nbsp;&nbsp;</span>

                        <span *ngIf="qcGreenCount > 0" class="indicator green-indicator"></span>
                        <span *ngIf="qcGreenCount > 0">On Track {{qcGreenCount}}&nbsp;&nbsp;&nbsp;</span>

                        <span *ngIf="qcDueSoonCount > 0" class="indicator yellow-indicator"></span>
                        <span *ngIf="qcDueSoonCount > 0" >Due Soon {{qcDueSoonCount}}&nbsp;&nbsp;&nbsp;</span>

                      </mat-panel-description>

                      <!-- Integrated Feeds-->
                      <mat-panel-description style="flex: auto;" *ngIf="(task.title === 'Integrated Feeds')">
                        <span *ngIf="(IntFeeds_Overdue > 0)" class="indicator red-indicator"></span>
                        <span *ngIf="(IntFeeds_Overdue > 0)">Past Due
                          {{IntFeeds_Overdue}}&nbsp;&nbsp;&nbsp;</span>
                        <span *ngIf="(IntFeeds_AtRisk > 0)" class="indicator orange-indicator"></span>
                        <span *ngIf="(IntFeeds_AtRisk > 0)">At Risk
                          {{IntFeeds_AtRisk}}&nbsp;&nbsp;&nbsp;</span>
                        <span *ngIf="(IntFeeds_SetupNeeded > 0)" class="indicator yellow-indicator"></span>
                        <span *ngIf="(IntFeeds_SetupNeeded > 0)">Setup Needed
                          {{IntFeeds_SetupNeeded}}&nbsp;&nbsp;&nbsp;</span>
                        <span *ngIf="(IntFeeds_Completed > 0)" class="indicator green-indicator"></span>
                        <span *ngIf="(IntFeeds_Completed > 0)">Completed
                          {{IntFeeds_Completed}}&nbsp;&nbsp;&nbsp;</span>
                      </mat-panel-description>

                      <!-- Processing Files-->
                      <mat-panel-description style="flex: auto;" *ngIf="(task.title === 'Processing Files')">
                        <span *ngIf="(pfErrorCount > 0)" class="indicator red-indicator"></span>
                        <span *ngIf="(pfErrorCount > 0)"> Error {{pfErrorCount}}&nbsp;&nbsp;&nbsp;</span>

                        <span *ngIf="(pfProcessingCount > 0)" class="indicator green-indicator"></span>
                        <span *ngIf="(pfProcessingCount > 0)">Processing
                          {{ pfProcessingCount }}&nbsp;&nbsp;&nbsp;</span>

                        <span *ngIf="(pfPendingCount > 0)" class="indicator yellow-indicator"></span>
                        <span *ngIf="(pfPendingCount > 0)">Pending {{pfPendingCount}}</span>
                      </mat-panel-description>

                      <!-- Faile Mapping -->
                      <mat-panel-description style="flex: auto;" *ngIf="(task.title === 'File Mappings')">
                        <span *ngIf="(task.rowData.baselinedatePastRecordCount > 0)"
                          class="indicator red-indicator"></span>
                        <span *ngIf="(task.rowData.baselinedatePastRecordCount > 0)">Past Due
                          {{task.rowData.baselinedatePastRecordCount}}&nbsp;&nbsp;&nbsp;</span>

                        <span *ngIf="(task.rowData.baselinedateFutureRecordCount > 0)"
                          class="indicator yellow-indicator"></span>
                        <span *ngIf="(task.rowData.baselinedateFutureRecordCount > 0)">Due Soon
                          {{task.rowData.baselinedateFutureRecordCount}}&nbsp;&nbsp;&nbsp;</span>

                        <span *ngIf="(task.rowData.baselinedateAfter7daysRecordCount > 0 || task.rowData.noBaselinedateRecordCount > 0)"
                          class="indicator green-indicator"></span>
                        <span *ngIf="(task.rowData.baselinedateAfter7daysRecordCount > 0 || task.rowData.noBaselinedateRecordCount > 0)">On Track
                          {{task.rowData.baselinedateAfter7daysRecordCount + task.rowData.noBaselinedateRecordCount}}&nbsp;&nbsp;&nbsp;</span>

                        <span *ngIf="(task.rowData.completedRecordCount > 0 || task.rowData.historicRecordCount > 0)"
                          class="indicator gray-indicator"></span>
                        <span *ngIf="(task.rowData.completedRecordCount > 0 || task.rowData.historicRecordCount > 0)">Completed
                          {{task.rowData.completedRecordCount + task.rowData.historicRecordCount}}</span>
                        <span *ngIf="(task.rowData.historicRecordCount > 0)"
                          class="indicator blue-indicator"></span>
                        <span *ngIf="(task.rowData.historicRecordCount > 0)">Historic
                          {{task.rowData.historicRecordCount}}</span>
                          <span *ngIf="(task.rowData.cancelledRecordCount > 0)"
                            class="indicator violet-indicator"></span>
                          <span *ngIf="(task.rowData.cancelledRecordCount > 0)">Cancelled
                            {{task.rowData.cancelledRecordCount}}</span>
                      </mat-panel-description>

                      <mat-panel-description style="flex: auto;" *ngIf="(task.title === 'EPR\'s')">

                        <span *ngIf="(eprNotDefinedCount > 0)" class="indicator red-indicator"></span>
                        <span *ngIf="(eprNotDefinedCount > 0)">Not Defined {{eprNotDefinedCount}}&nbsp;&nbsp;&nbsp;</span>
                        <span *ngIf="(eprBatchFileCount > 0)" class="indicator green-indicator"></span>
                        <span *ngIf="(eprBatchFileCount > 0)">Batch File {{eprBatchFileCount}}&nbsp;&nbsp;&nbsp;</span>
                        <span *ngIf="(eprManualCount > 0)" class="indicator blue-indicator"></span>
                        <span *ngIf="(eprManualCount > 0)">Manual {{eprManualCount}}&nbsp;&nbsp;&nbsp;</span>
                        <span *ngIf="(eprRealTimeCount > 0)" class="indicator blue-indicator"></span>
                        <span *ngIf="(eprRealTimeCount > 0)">Real Time {{eprRealTimeCount}}&nbsp;&nbsp;&nbsp;</span>
                        <span *ngIf="(eprTermedCount > 0)" class="indicator gray-indicator"></span>
                        <span *ngIf="(eprTermedCount > 0)">Termed {{eprTermedCount}}&nbsp;&nbsp;&nbsp;</span>
                      </mat-panel-description>
                      <!-- Term By Absence (TBA) -->
                      <mat-panel-description style="flex: auto;" *ngIf="(task.title === 'Term By Absence')">
                        <span *ngIf="(tbaExceeded > 0)" class="indicator red-indicator"></span>
                        <span *ngIf="(tbaExceeded > 0)">Exceeded {{tbaExceeded}}&nbsp;&nbsp;&nbsp;</span>
                        <span *ngIf="(tbaComplete > 0)" class="indicator gray-indicator"></span>
                        <span *ngIf="(tbaComplete > 0)">Complete {{tbaComplete}}&nbsp;&nbsp;&nbsp;</span>
                      </mat-panel-description>

                      <mat-panel-description style="flex: auto;" *ngIf="(task.title === 'EPR Master')">
                        <span *ngIf="(eprMasterNotDefinedCount > 0)" class="indicator red-indicator"></span>
                        <span *ngIf="(eprMasterNotDefinedCount > 0)">Not Defined {{eprMasterNotDefinedCount}}&nbsp;&nbsp;&nbsp;</span>
                        <span *ngIf="(eprMasterBatchFileCount > 0)" class="indicator green-indicator"></span>
                        <span *ngIf="(eprMasterBatchFileCount > 0)">Batch File {{eprMasterBatchFileCount}}&nbsp;&nbsp;&nbsp;</span>
                        <span *ngIf="(eprMasterManualCount > 0)" class="indicator blue-indicator"></span>
                        <span *ngIf="(eprMasterManualCount > 0)">Manual {{eprMasterManualCount}}&nbsp;&nbsp;&nbsp;</span>
                        <span *ngIf="(eprMasterRealTimeCount > 0)" class="indicator blue-indicator"></span>
                        <span *ngIf="(eprMasterRealTimeCount > 0)">Real Time {{eprMasterRealTimeCount}}&nbsp;&nbsp;&nbsp;</span>
                        <span *ngIf="(eprMasterTermedCount > 0)" class="indicator gray-indicator"></span>
                        <span *ngIf="(eprMasterTermedCount > 0)">Termed {{eprMasterTermedCount}}&nbsp;&nbsp;&nbsp;</span>
                      </mat-panel-description>

                      <!-- Open Enrollment'-->
                      <mat-panel-description style="flex: auto;" *ngIf="(task.title === 'Open Enrollment')">
                        <span *ngIf="(task.rowData.clientNotSendRecordsCount > 0)"
                          class="indicator gray-indicator"></span>
                        <span *ngIf="(task.rowData.clientNotSendRecordsCount > 0)">No OE File
                          {{task.rowData.clientNotSendRecordsCount}}&nbsp;&nbsp;&nbsp;</span>

                        <span *ngIf="(task.rowData.clientSendRecordsCount > 0)"
                          class="indicator green-indicator"></span>
                        <span *ngIf="(task.rowData.clientSendRecordsCount > 0)">Sends OE File
                          {{task.rowData.clientSendRecordsCount}}&nbsp;&nbsp;&nbsp;</span>

                        <span *ngIf="(task.rowData.otherRecords > 0)" class="indicator violet-indicator"></span>
                        <span *ngIf="(task.rowData.otherRecords > 0)">Other EPR
                          {{task.rowData.otherRecords}}&nbsp;&nbsp;&nbsp;</span>

                        <span *ngIf="(task.rowData.nullRecords > 0)" class="indicator yellow-indicator"></span>
                        <span *ngIf="(task.rowData.nullRecords > 0)">Not Defined {{task.rowData.nullRecords}}</span>
                      </mat-panel-description>
                      <mat-panel-description style="flex: auto;">
                        <div *ngIf="task.title === 'CAG REF (Manual)' && showCagNote" style="display: flex;">
                          <mat-icon class="cag-warning-icon">error</mat-icon>
                          <span class="cag-warning-text">Apply filters to show additional results</span>
                        </div>
                      </mat-panel-description>

                      <mat-panel-description class="dashboardBtn" style="flex: auto;">
                        {{task.title.expanded}}
                        
                        <span>
                          <mat-button-toggle-group role="radiogroup" (change)="dbToggle(task,$event)"
                          *ngIf="task.showToggles"
                          (click)="$event.stopPropagation()"
                          [value]="getDb(task)"
                            aria-label="please select an option">
                            <mat-button-toggle value="ads" role="radio">
                              ADS</mat-button-toggle>
                            <mat-button-toggle value="prd"  role="radio" *ngIf="task.prd">
                              PRD</mat-button-toggle>
                            <mat-button-toggle value="beta" role="radio" *ngIf="task.beta">
                              BETA</mat-button-toggle>
                          </mat-button-toggle-group>
                        </span>
                        <span *ngIf="task.title === 'EPR\'s'" >
                          <button  *ngIf="(isAdmin) || dashboardObject?.buttons.ADD_EPR" id="createNewEpr" (click)="createNewEpr()"
                          [disabled]="accordionClosed(task.title)" style="cursor:pointer;">
                            <mat-icon class="epr-icon" svgIcon="plus--s"></mat-icon>
                            <span>Add</span>
                          </button>
                        </span>
                        <div *ngIf="task.title === 'EPR Master'">
                          <button class="cag-button" (click)="resetFilters(task.title)" [disabled]="accordionClosed(task.title)" style="cursor:pointer;">
                            <mat-icon class="epr-icon" svgIcon="filter--s"
                              ></mat-icon>
                            <span>Clear Filters</span>
                          </button>
                      </div>
                        <div *ngIf="task.title === 'CAG REF (Batch)'" (click)="$event.stopPropagation();">
                          <!-- <button class="cag-button" (click)="refresh(task)" style="cursor:pointer;">
                            <mat-icon svgIcon="refresh--s" (click)="refresh(task)">
                              Refresh
                            </mat-icon>
                            &nbsp;
                            <span class="refresh-txt"> Refresh </span>
                          </button> -->

                          <button  *ngIf="(dashboardObject?.buttons.REQUEST_CAGBATCH)"    class="cag-button" (click)="openCagBatchModel()"
                            [disabled]="accordionClosed(task.title)" style="cursor:pointer;">
                            <mat-icon class="cag-icon" svgIcon="plus--s"></mat-icon>
                            <span>Request</span>
                          </button>
                        </div>
                        <div *ngIf="task.title === 'CAG REF (Manual)'" (click)="$event.stopPropagation();">

                          <button *ngIf="dashboardObject?.buttons.DELETE_CAGMANUAL"   class="cag-button" (click)="deleteCag()"
                            [disabled]="accordionClosed(task.title) ? true  : disableEditButton1 || editCagRefManual"
                            style="cursor:pointer;">
                            <mat-icon class="cag-icon" svgIcon="trash--s"></mat-icon>
                            <span>Delete</span>
                          </button>

                          <button class="cag-button" (click)="saveCag()" *ngIf="(dashboardObject?.buttons.EDIT_CAGMANUAL)"
                            [disabled]=" accordionClosed(task.title) ? true: (!editCagRefManual && !addCagRefManual)"
                            style="cursor:pointer;">
                            <mat-icon class="cag-icon" svgIcon="arrow-down--s"></mat-icon>
                            <span>Save Table</span>
                          </button>

                          <button class="cag-button" (click)="addCagRef(true)"
                            *ngIf="!editCagRefManual && addCagRefManual" [disabled]="accordionClosed(task.title)"
                            style="cursor:pointer;">
                            <mat-icon class="cag-icon" svgIcon="close-btn--s"></mat-icon>
                            <span>Cancel</span>
                          </button>

                          <button class="cag-button" (click)="addCagRef()" *ngIf="(dashboardObject?.buttons.ADD_CAGMANUAL) && !editCagRefManual"
                            [disabled]="accordionClosed(task.title) || addCagRefManual" style="cursor:pointer;">
                            <mat-icon class="cag-icon" svgIcon="plus--s"></mat-icon>
                            <span>Add</span>
                          </button>

                          <button class="cag-button" (click)="enableEdit(true)"
                            *ngIf="editCagRefManual && !addCagRefManual" [disabled]="accordionClosed(task.title)"
                            style="cursor:pointer;">
                            <mat-icon class="cag-icon" svgIcon="close-btn--s"></mat-icon>
                            <span>Cancel</span>
                          </button>

                          <button class="cag-button"
                            [disabled]="accordionClosed(task.title) ? true  : disableEditButton1 || editCagRefManual"
                            *ngIf="(dashboardObject?.buttons.EDIT_CAGMANUAL)  && !editCagRefManual" (click)="enableEdit()" style="cursor:pointer;">
                            <mat-icon class="cag-icon" svgIcon="pencil--s">

                            </mat-icon>
                            <span>Edit</span>
                          </button>
                        </div>
                        <div *ngIf="task.title === 'Term By Absence'" (click)="$event.stopPropagation();">
                          <button  *ngIf="dashboardObject?.buttons.ADD_TBA" id="createNewEpr"
                          disabled style="cursor:pointer;">
                            <mat-icon class="epr-icon" svgIcon="plus--s"></mat-icon>
                            <span>Add</span>
                          </button>
                        </div>
                        <span class="refresh-btn" #tooltip="matTooltip"
                         [matTooltip]="task.RefreshDate"
                         (click)="refresh(task)"
                         style="cursor:pointer;">
                          <mat-icon  [ngClass]="task.title === 'Processing Files' && pfLoading? 'PFSpin': ''" 
                          svgIcon="refresh--s"
                           id="tableOnerefreshProcessorBtn" 
                             matTooltipClass="refreshTooltip"
                            matTooltipHideDelay="500">
                            Refresh
                          </mat-icon>
                          <span class="refresh-txt"
                           matTooltipClass="refreshTooltip"
                           matTooltipHideDelay="500"> Refresh </span>
                        </span>
                        <span class="export-btn" style="cursor:pointer;" id="tableOneExportViewBtn"
                        (click)="exportView(task.title)">
                          <mat-icon svgIcon="file-csv--s" >
                            Export View
                          </mat-icon>
                          <span class="export-txt"> Export </span>
                        </span>

                      </mat-panel-description>
                    </mat-expansion-panel-header>
                    <ng-container *ngIf="task.isLoaded">

                      <span *ngIf="moreDataErrorGrid1 && (task.title === 'Processing Files' )"
                        class="more-data-error">{{moreDataErrorGrid1}}
                      </span>

                      <span *ngIf="task.title === 'FT File Monitor - Eligibility'" class="filter-btn">
                        <span>
                          <mat-slide-toggle [checked]="islatestEligibility"
                            (change)="onToggleChange($event, task.title)">
                            <span class="slide-toggle-text">{{islatestEligibility?'Latest':'Older Than'}} 7 Days</span>
                          </mat-slide-toggle>
                        </span>
                      </span>
                      <span *ngIf="task.title === 'FT File Monitor - Spedm'" class="filter-btn">
                        <span>
                          <mat-slide-toggle [checked]="islatestSpedm" (change)="onToggleChange($event, task.title)">
                            <span class="slide-toggle-text">{{islatestSpedm?'Latest':'Older Than'}} 7 Days</span>
                          </mat-slide-toggle>
                        </span>
                      </span>

                      <div [id]="task.title" class="grid" *ngIf="task.isLoaded">

                        <ag-grid-angular class="ag-theme-alpine" [gridOptions]="task.gridOptions"
                        (gridReady)="onGridReady($event,task.title)"
                          (dragStopped)="onDragStopped($event, task.title)"
                          (sortChanged)="onSortChange($event, task.title)"
                          (filterChanged)="onFilterChange( $event, task.title,task.gridOptions,task)"
                          [popupParent]="popupParent" 
                          [suppressScrollOnNewData]="true"
                          [suppressMaxRenderedRowRestriction]="true">
                          
                        </ag-grid-angular>
                      </div>
                    </ng-container>
                  </mat-expansion-panel>

                </ng-container>
              </mat-accordion>
            </div>

          </div>
        </div>
      </div>
    </mat-drawer-content>
  </mat-drawer-container>
</div>
