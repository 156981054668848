import { DeleteBtnCellRendererComponent } from './../../../common/DeleteBtnCellRenderer';
import { AppState } from './../../../reducers/index';
import { Store } from '@ngrx/store';
import { CVSAlertService, CVSAlertType } from 'angular-component-library';
import { MessageSupressionService } from './../../../services/message-supression.service';
import { Component, OnInit, Output, ViewChild, EventEmitter, AfterViewInit, HostListener, OnDestroy } from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import {Subscription} from "rxjs";
import { EditBtnCellRendererComponent } from 'src/app/common/EditBtnCellRenderer';
import {DataManagementService} from "../../../services/data-management.service";

export const validationsGridColumns = [
  {
    headerName: 'Id', field: 'id', hide: true
  },
  {
    headerName: 'Validations', field: 'message',
    editable: false, sortable: true,
    filter: true, lockVisible: true, lockPosition: true, resizable: true,
    heigth: 300, width: 150,
    cellEditor: 'agLargeTextCellEditor',
    cellEditorPopup: true,
    cellRenderer: (params) => {
      return `<mat-icon class="mat-icon md-16 material-symbols-outlined"
      style="margin-right: 5px;"
      role="img" aria-hidden="true">
      drag_indicator</mat-icon>${params.data?.message}`;
    },
    cellEditorParams: { maxLength: 1000, rows: 10, cols: 50 },
    unSortIcon: true
  },
  {
    headerName: 'Description', field: 'messageDescription',
    editable: false,
    sortable: true,
    filter: true, lockVisible: true, lockPosition: true, resizable: true,
    heigth: 300, width: 350,
    cellEditor: 'agLargeTextCellEditor',
    cellEditorPopup: true,
    cellEditorParams: { maxLength: 1000, rows: 10, cols: 50 },
    unSortIcon: true
  },
  {
    headerName: '',
    field:"action",
    cellRenderer: EditBtnCellRendererComponent,
    editable: true,
    width: 90,
  },
  {
    headerName: 'sortOrder', field: 'sortOrder', hide: true
  },
];
@Component({
  selector: 'app-validation',
  templateUrl: './validation.component.html',
  styleUrls: ['./validation.component.scss']
})
export class ValidationComponent implements OnInit, AfterViewInit, OnDestroy {
@ViewChild('validationGrid') validationGrid: AgGridAngular;
@Output() isSaveValidationCalled: EventEmitter<any> = new EventEmitter();

  dataRows = [];
  hlGridHeight = 350;
  contextObj: any;
  selectedStatus = '';
  changedRow: any;
  readonly: boolean;
  validationSub: Subscription;
  updatedRec: any;
  isRowDragged: boolean = false;
  isItemDeleted: boolean = false;
  filteredData: any=[];
  constructor(private msgSupressService: MessageSupressionService, private alertService: CVSAlertService, private store: Store<AppState>,
              private dataManagementService: DataManagementService) {
    this.contextObj = {  componentParent: this, currentPage: "validations"};
  }
  ngOnInit() {
    this.getAllRows();/*data loading*/
    this.hlGridHeight = 350;
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      /*add new record clicked in parent Component*/
      this.addNewRow();
      /*refresh the grid based on dropdown selction in parent component*/
      this.saveUnSavedRecords();
      this.alertService.clearAlert('#map-alert');
    }, 500);
  }
  getAllRows() {
    this.msgSupressService.getValidationRows().subscribe(res => {
      if(res && res.length > 0){
      //  res.map((item: any, index)=> item.sortOrder = (item.sortOrder>=0 ? item.sortOrder: index))
        this.dataRows = res;
        this.dataManagementService.addRowdata.next(this.dataRows);
      }

    });
    this.changedRow = false;

  }
  saveUnSavedRecords() {
    this.msgSupressService.saveNewValidationRecords$.subscribe(resp => {
      let nodeData: any = {}
      this.updatedRec = [];
      if (resp) {
        let isAddorEditNode =  this.validationGrid.api.getRenderedNodes()?.some(node=> (node.data.isNew || node.data.isUpdated));
        this.validationGrid.api.forEachNode((node) => {
            if(node.data?.isNew || node.data?.isUpdated) {
              delete node.data?.isUpdated;
              delete node.data?.isNew
              delete node.data?.action;
              nodeData = node.data;
              nodeData.type= 'validations';
              nodeData.sortOrder = nodeData.sortOrder ? nodeData.sortOrder: node.rowIndex;
              this.updatedRec.push(nodeData);
            }
            if(this.isRowDragged || this.isItemDeleted) {
              nodeData = node.data;
              nodeData.sortOrder = node.rowIndex;
              this.updatedRec.push(nodeData);
            }
        });
        if (this.updatedRec && this.updatedRec.length) {
          if(isAddorEditNode) {
            this.msgSupressService.saveMsgSupressRow(this.updatedRec, true).subscribe(_ => {
              this.getAllRows();
              this.readonly = false;
              this.showAlert('Save successful!', CVSAlertType.Success)
            });
          }
           if(this.isRowDragged || this.isItemDeleted) {
            this.msgSupressService.saveSortedRecord(this.updatedRec, true).subscribe(_ => {
              this.getAllRows();
              this.isRowDragged = false;
              this.readonly = false;
              this.showAlert(this.isItemDeleted? 'Delete successful!': 'Save successful!', CVSAlertType.Success)
              this.isItemDeleted = false;
            });
          }
        }
      }
    });

  }
  addNewRow() {
   this.validationSub= this.msgSupressService.onValidationMessage().subscribe(newRow => {
    const nextItemIndex = this.validationGrid?.api?.getDisplayedRowCount();
      if (newRow != null) {
        this.validationGrid?.api?.applyTransaction({
          addIndex: nextItemIndex,
          add: newRow
        });
        this.validationGrid.api.setFocusedCell(0, 'id');
        this.hlGridHeight = (this.validationGrid.api?.getModel()?.getRowCount() * 42) + 52;
      }
    });
  }

  private showAlert(alertMessage, alertType) {
    this.alertService.sendAlert({
      headline: alertMessage,
      alertType: alertType,
      removedAfterMilliseconds: 5000,
      alertDocumentHolderName: '#map-alert',
    });
  }
  ngOnDestroy(){
  this.validationSub.unsubscribe();
  }
  onGridReady(params) {
    this.validationGrid = params;
    this.hlGridHeight = this.dataRows.length * 51;
    this.validationGrid.api.setGridOption("columnDefs", validationsGridColumns);
    this.validationGrid.api.redrawRows();
    this.validationGrid.api.sizeColumnsToFit();
    this.validationGrid.gridOptions = { onCellValueChanged(data)  {
      this.UpdatedRow(data);
    }};
  }
  UpdatedRow(cell) {
    cell.data.isUpdated = true;
    this.readonly = true;
  }
  emitSave() {
    this.isSaveValidationCalled.emit();
  }
  sortChange(event){
      const sortOrder = event.api.getSortModel();
    this.validationGrid.api.applyColumnState({
      state: sortOrder,
    });
  }
  onRowDragEnd(event) {
    this.isRowDragged = true;
    this.emitSave();
  }
  public updateRecord(node, record) {
      const currentRecordId = node?.data?.id;
      this.dataRows.map((item)=>  {
        if(item.id === currentRecordId) {
          node.data.isUpdated = true;
          item.message = record[0].message;
          item.messageDescription = record[0].messageDescription
          return
        }
      });
    this.msgSupressService.saveNewValidationRecords$.next(true);
    this.validationGrid.api.setGridOption("rowData", this.dataRows);
  }
  public deleteTableRecord(node, colId) {
    if (node.data.isNew) {
      this.readonly = false;
      this.validationGrid.api.applyTransaction({remove:[node.data]});
      this.hlGridHeight = (this.validationGrid.api.getDisplayedRowCount() * 42) + 52;
    } else {
      this.msgSupressService.deleteMsgSupressRow([{ ...node.data }], false).subscribe(resp => {
        this.validationGrid.api.applyTransaction({remove:[node.data]});
        this.isItemDeleted = true;
        this.msgSupressService.saveNewValidationRecords$.next(true);
        this.hlGridHeight = (this.validationGrid.api.getDisplayedRowCount() * 42) + 52;
      });
    }
  }

  onFilterChange(event: any) {
    this.filteredData = [];
    event.api.forEachNodeAfterFilter((node) =>{
      this.filteredData.push(node.data);
    })
    this.dataManagementService.addRowdata.next(this.filteredData);
  }
}
