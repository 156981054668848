import { Injectable } from '@angular/core';
import {CVSHttpClient} from 'angular-component-library';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {environment} from '../../environments/environment';
import { catchError, tap } from 'rxjs/operators';
import { ProcessingFileDB } from '../models/PermissionsObject';

@Injectable({
  providedIn: 'root'
})
export class ThresholdService {

  constructor(private http: CVSHttpClient) {  }

  getThresholdData(request: any, ssoeFlag= false, db = null): Observable<any> {
    const baseURL = environment.ewf_processingFile;
    let endPoint = ssoeFlag ? `ssoe/threshold?db=${db}`: 'threshold'
    return this.http.post(`${baseURL}/${endPoint}`, request);
  }

  createThresholdRequest(request: any, ssoeFlag= false,db = null): Observable<any> {
    let ssoeBaseUrl = `${environment.baseUrlSSOE}/${environment.ssoe_Ewf}`;
    if(ssoeFlag && db){
    ssoeBaseUrl = this.getSSoeBaseUrl(db);
    }
    const baseURL = ssoeFlag ? ssoeBaseUrl: environment.rxClaimUrl;
    if(ssoeFlag) {
      let url = new URL(baseURL);
      /* enable this api once api at the backend is ready*/
      return this.http.put(`${url.origin}/ssoe-fast-api/composer/dags/state`, request)
      .pipe(
        catchError((error: HttpErrorResponse)=>{
          return of(error)
        })
      )

    }else{
      return this.http.post(`${baseURL}/dataExtract/thresholdData`, request);
    }
  }

  getThresholdSummaryReport(request: any, ssoeFlag= false, db = null): Observable<any> {
    let ssoeBaseUrl = `${environment.baseUrlSSOE}/${environment.ssoe_Ewf}`;
    if(ssoeFlag && db){
    ssoeBaseUrl = this.getSSoeBaseUrl(db);
    }
    const baseURL = ssoeFlag ? ssoeBaseUrl: environment.rxClaimUrl;
    if(ssoeFlag){
      let url = new URL(baseURL);
      return this.http.downloadFile(`${url.origin}/reporting-engine/media/report/adhoc?fastLinkMediaId=${request.auditNumber}&pli=${request.pli}&reportType=SUMMARY`);
    }
    else{
      return this.http.post(`${baseURL}/threshold/summary`, request);
    }
  }

  getThresholdDetailsReport(request: any, ssoeFlag= false,db =null): Observable<any> {
    let ssoeBaseUrl = `${environment.baseUrlSSOE}/${environment.ssoe_Ewf}`;
    if(ssoeFlag && db){
    ssoeBaseUrl = this.getSSoeBaseUrl(db);
    }
    const baseURL = ssoeFlag ? ssoeBaseUrl: environment.rxClaimUrl;
    if(ssoeFlag){
      let url = new URL(baseURL);
      return this.http.downloadFile(`${url.origin}/reporting-engine/media/report/adhoc?fastLinkMediaId=${request.auditNumber}&pli=${request.pli}&reportType=DETAIL`);
    }
    else{
      return this.http.post(`${baseURL}/threshold/details`, request);
    }
  }

  getThresholdTypeReport(request: any, ssoeFlag= false): Observable<any> {
    const baseURL = ssoeFlag ? `${environment.baseUrlSSOE}/${environment.ssoe_Ewf}`: environment.rxClaimUrl;
    return this.http.post(`${baseURL}/threshold/type`, request);
  }
  getThresholdRowReport(request): Observable<any>{
    return this.http.post(`${environment.ewf_processingFile}/ssoe/threshold/download`, request);
  }
  getSSoeBaseUrl(db: ProcessingFileDB): string {
    let ssoeBaseUrl;
    if(db === ProcessingFileDB.ADS){
      ssoeBaseUrl = environment.baseUrlSSOE_Ads
     }else if(db === ProcessingFileDB.PRD){
      ssoeBaseUrl = environment.baseUrlSSOE
     }else if(db === ProcessingFileDB.BETA){
      ssoeBaseUrl = environment.baseUrlSSOE_Beta
     }
     return `${ssoeBaseUrl}/${environment.ssoe_Ewf}`;
  }
}
