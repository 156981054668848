<div class="mr">
  <div class="header">
    <div class="toggles">
      <mat-button-toggle-group role="radiogroup" (change)="dbToggle($event)"
      *ngIf="this.processFilespermissionObject.adsPrdToggle || this.processFilespermissionObject.betaToggle;"
      (click)="$event.stopPropagation()"
      [(ngModel)]="db"
        aria-label="please select an option">
        <mat-button-toggle value="ads" role="radio">
          ADS</mat-button-toggle>
        <mat-button-toggle value="prd"  role="radio" *ngIf="processFilespermissionObject.adsPrdToggle">
          PRD</mat-button-toggle>
        <mat-button-toggle value="beta" role="radio" *ngIf="processFilespermissionObject.betaToggle">
          BETA</mat-button-toggle>
      </mat-button-toggle-group>
    </div>
   </div>
  
<!--<mat-grid-list cols="5">
    <mat-grid-tile></mat-grid-tile>
    <mat-grid-tile></mat-grid-tile>
    <mat-grid-tile></mat-grid-tile>
    <mat-grid-tile></mat-grid-tile>
    <mat-grid-tile>
    </mat-grid-tile>
  </mat-grid-list>-->
        
          <ag-grid-angular
           (rowClicked)='onRowClicked($event)'
            #myprcgrid class="ag-theme-alpine epr-feed-gui-grid"
            [rowData]="data"
            domLayout="autoHeight"
            (filterChanged)="onFilterChange($event)">
          </ag-grid-angular>
        

</div>
