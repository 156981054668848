import {AfterViewInit, Component, OnDestroy, ViewChild, ElementRef, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {Router} from '@angular/router';
import {AgGridAngular} from 'ag-grid-angular';
import * as fromRoot from '../../reducers';
import * as ColumnSets from './historyColumnSets';
import { HistoryService } from 'src/app/services/history.service';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import {animate, state, style, transition, trigger} from '@angular/animations';
import { MatLegacyTabChangeEvent as MatTabChangeEvent } from '@angular/material/legacy-tabs';
import {DateUtilService} from "../../services/dateUtil.service";
import {getEprId} from "../../reducers";
import * as moment from "moment/moment";

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class HistoryComponent implements OnInit {
  @ViewChild('historygrid') historygrid: AgGridAngular;

  subgriddata: any;
  ObjMap:any={};
  mainGrid:any = [];
  columnsToDisplay = ['Action','User', 'TimeStamp','TotalChanges'];
  expandedElement: ColumnSets.IAccount | null;
  dataSource = new MatTableDataSource<any>();
  columnDefs = ColumnSets.historyColumns
  historyApi: any;
  private eprId: number;



  constructor(private store: Store<fromRoot.AppState>, private router: Router,
     private historyService: HistoryService,
              private dateUtilService: DateUtilService) {}


ngOnInit(){
    this.store.select(getEprId).subscribe(id => {
    if(id){
      this.eprId = id;
    }
  });
  this.loadData();
}
// ngAfterViewInit(){
//   this.loadData();
// }

loadData(){
  this.historyService.getHistory(this.eprId).subscribe(res =>{
    let dateData = this.dateUtilService.convertToCst(res);
    dateData.forEach(rec => {
      if ( rec && rec.eprHistoryField && !rec.eprHistoryField.includes('Epr ID') &&
        !rec.eprHistoryField.includes('Updated On') &&
        !rec.eprHistoryField.includes('backupec') &&
        !rec.eprHistoryField.includes('backupecDefault') && !rec.eprHistoryField.includes('Update User ID')) {
        if (rec.eprHistoryField.includes('Date') || rec.eprHistoryField.includes('Go Live')) {
          rec.previousValue = rec.previousValue ? moment(new Date(Number(rec.previousValue))).add(1, 'days').format("MM/DD/YYYY") : '';
          rec.newValue = rec.newValue ? moment(new Date(Number(rec.newValue))).add(1, 'days').format("MM/DD/YYYY") : '';
        }
        var userKey = rec.timeStamp;
        if (!this.ObjMap[userKey]) {
          this.ObjMap[userKey] = [];
          this.mainGrid.push(rec);
        }
        this.ObjMap[userKey].push(rec);
      }
    });
    let sortedTime = this.mainGrid.sort((a,b)=>(new Date(a.timeStamp) > new Date(b.timeStamp))? -1 : 1)
    this.dataSource.data = sortedTime
    this.mainGrid.map(ele => {
      let count = this.ObjMap[ele.timeStamp].length
      ele['totalchanges']  = count
    });
  })
}
onGridReady(params): void {
  this.historyApi = params.api;
  this.historyApi.setGridOption("domLayout", 'autoHeight');
}

onFirstDataRendered(params){
  params.api.sizeColumnsToFit();
}
  onFilterChange(event: any) {
  }

  filterTables(filterValue: any) {
    filterValue = filterValue.trim();
       filterValue = filterValue?.toLowerCase();
       this.dataSource.filter = filterValue;
  }

  onRowClicked(rowclicked: any) {
       this.subgriddata = this.ObjMap[rowclicked.timeStamp]
    }
}

