<cvs-alert-placeholder id="Benefit-Copay"></cvs-alert-placeholder>
<div class="row mb-10">
  <h1>File Tracker Profile</h1>
  <div>
    <button id="edit-btn" class="pds-btn-primary" *ngIf="!isEdited && (isRegisteredUser$ | async) && isAdmin" (click)="startEdit()">
    <mat-icon svgIcon="pencil--s"></mat-icon>
    Edit
  </button>
    <button id="cancel-edit-btn" class="pds-btn-primary" *ngIf="isEdited" (click)="saveCopay()" [disabled]="form.invalid || isErrorMessage">
    <mat-icon>save</mat-icon>
    Save
    </button>
  </div>
  <div>
    <button id="cancel-btn" style="margin-left: 25px;" (click)="cancelCopay()"  class="pds-btn-primary">
    <mat-icon>cancel</mat-icon>
    Cancel
    </button>
  </div>
</div>
<form [formGroup]="form">
  <div class="grid">
    <div *ngFor="let section of CopayOnboardingFormConfig; let i = index">
      <div class="inputs">
        <div *ngFor="let field of section; let k = index" [id]="k">
          <div [ngSwitch]="field.inputType" class="input-field">

            <mat-grid-list cols="8" rowHeight="28px" *ngSwitchCase="inputType.sinkInput1" class="text input" [ngClass]="field.heading ? 'heading' : ''">
              <mat-grid-tile [colspan]="4" [rowspan]="1">
                <app-label-link  [hyperlink]="getHyperlink(field.controlName, hyperlinks)" matTooltip="Incoming user id folder" matTooltipClass="tooltip-bg-color"
                  matTooltipHideDelay="20" labelText="Client Receiver ID">
                </app-label-link>
                <span style="color: #CC0502">*</span>
              </mat-grid-tile>
              <mat-grid-tile [colspan]="4" [rowspan]="1" class="auto_width" [ngStyle]="{'width': width1 + 'px'}">
                <input type="text" #elasticInput1 style="margin-right: 100px;" [ngStyle]="{'minWidth': width1 + 'px'}" (input)="resize(1)" maxlength="100"  [id]="'input_' + k" class="clientReceiverID" formControlName="clientReceiverID">
                <span #hiddenText1 style="visibility: hidden; white-space: pre;">{{elasticInput1.value}}</span>
              </mat-grid-tile>
            </mat-grid-list>
            <mat-grid-list cols="8" rowHeight="28px" *ngSwitchCase="inputType.sinkInput2" class="text input" [ngClass]="field.heading ? 'heading' : ''">
              <mat-grid-tile [colspan]="4" [rowspan]="1">
                <app-label-link [hyperlink]="getHyperlink(field.controlName, hyperlinks)" matTooltip="Subfolder under mnt273" matTooltipClass="tooltip-bg-color"
                  matTooltipHideDelay="20" labelText="Business Unit">
                </app-label-link>
                <span style="color: #CC0502">*</span>
              </mat-grid-tile>
              <mat-grid-tile [colspan]="4" [rowspan]="1" class="auto_width" [ngStyle]="{'width': width2 + 'px'}">
                <input type="text" #elasticInput2 style="margin-right: 100px;" [ngStyle]="{'minWidth': width2 + 'px'}" (input)="resize(2)" maxlength="100"  [id]="'input_' + k" class="businessUnit" formControlName="businessUnit">
                <span #hiddenText2 style="visibility: hidden; white-space: pre;">{{elasticInput2.value}}</span>
              </mat-grid-tile>
            </mat-grid-list>
            <mat-grid-list cols="8" rowHeight="28px" *ngSwitchCase="inputType.sinkInput3" class="text input" [ngClass]="field.heading ? 'heading' : ''">
              <mat-grid-tile [colspan]="4" [rowspan]="1">
                <app-label-link [hyperlink]="getHyperlink(field.controlName, hyperlinks)" matTooltip="Substring of input file name" matTooltipClass="tooltip-bg-color"
                  matTooltipHideDelay="20" labelText="Input File Name">
                </app-label-link>
                <span style="color: #CC0502">*</span>
              </mat-grid-tile>
              <mat-grid-tile [colspan]="4" [rowspan]="1" class="auto_width" [ngStyle]="{'width': width3 + 'px'}">
                <input type="text" #elasticInput3 style="margin-right: 100px;" [ngStyle]="{'minWidth': width3 + 'px'}" (input)="resize(3)" maxlength="100"  [id]="'input_' + k" class="InputFileName" formControlName="InputFileName">
                <span #hiddenText3 style="visibility: hidden; white-space: pre;">{{elasticInput3.value}}</span>
              </mat-grid-tile>
            </mat-grid-list>
            <mat-grid-list cols="8" rowHeight="28px" *ngSwitchCase="inputType.sinkInput4" class="text input" [ngClass]="field.heading ? 'heading' : ''">
              <mat-grid-tile [colspan]="4" [rowspan]="1">
                <app-label-link [hyperlink]="getHyperlink(field.controlName, hyperlinks)" matTooltip="Substring of output file name" matTooltipClass="tooltip-bg-color"
                  matTooltipHideDelay="20" labelText="Output File Name">
                </app-label-link>
                <span style="color: #CC0502">*</span>
              </mat-grid-tile>
              <mat-grid-tile [colspan]="4" [rowspan]="1" class="auto_width" [ngStyle]="{'width': width4 + 'px'}">
                <input type="text" #elasticInput4 style="margin-right: 100px;" [ngStyle]="{'minWidth': width4 + 'px'}" (input)="resize(4)" maxlength="100"  [id]="'input_' + k" class="OutputFileName" formControlName="OutputFileName">
                <span #hiddenText4 style="visibility: hidden; white-space: pre;">{{elasticInput4.value}}</span>
              </mat-grid-tile>
            </mat-grid-list>
            <mat-grid-list cols="8" rowHeight="28px" *ngSwitchCase="inputType.sinkInput5" class="text input" [ngClass]="field.heading ? 'heading' : ''">
              <mat-grid-tile [colspan]="4" [rowspan]="1">
                <app-label-link [hyperlink]="getHyperlink(field.controlName, hyperlinks)" matTooltip="Total hours in numeric. Number of hours to process the file since the input file was received." matTooltipClass="tooltip-bg-color"
                  matTooltipHideDelay="20" labelText="Processing Time SLA For Output files">
                </app-label-link>
                <span style="color: #CC0502">*</span>
              </mat-grid-tile>
              <mat-grid-tile [colspan]="4" [rowspan]="1" class="auto_width" [ngStyle]="{'width': width5 + 'px'}">
                <input type="number" step="1" min="0" max="24" maxlength="2" maxlength="2" onKeyPress="if(this.value.length==2) return false;" pattern="[0-9]{1}[0-9]{0,4}" [ngStyle]="{'maxWidth': width5 + 'px'}"  [id]="'input_' + k" class="processingTime" formControlName="processingTime">
                <span><label style="margin-left:5px">Hours</label></span>
              </mat-grid-tile>
              <div style="margin-left: 500px">
                <span  *ngIf="errors[field.controlName]?.message"  style="color: #CC0502;height:300px; width:300px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis">{{errors[field.controlName].message}}</span>
              </div>
            </mat-grid-list>
            <mat-grid-list cols="8" rowHeight="28px" *ngSwitchCase="inputType.sinkInput6" class="text input" [ngClass]="field.heading ? 'heading' : ''">
              <mat-grid-tile [colspan]="4" [rowspan]="1">
                <app-label-link  [hyperlink]="getHyperlink(field.controlName, hyperlinks)" matTooltip="Total hours in numeric. Number of hours to trigger a warning alert if the file is not processed." matTooltipClass="tooltip-bg-color"
                  matTooltipHideDelay="20" labelText="Late file warning SLA For Output files">
                </app-label-link>
                <span style="color: #CC0502">*</span>
              </mat-grid-tile>
              <mat-grid-tile [colspan]="4" [rowspan]="1" class="auto_width" [ngStyle]="{'width': width5 + 'px'}">
                <input type="number" step="1" min="0" max="24" maxlength="2" onKeyPress="if(this.value.length==2) return false;"   pattern="[0-9]?[0-9]" [ngStyle]="{'maxWidth': width5 + 'px'}" [id]="'input_' + k" class="lateFileWarningAlertOutputFiles" formControlName="lateFileWarningAlertOutputFiles">
                <span><label style="margin-left:5px">Hours</label></span>
              </mat-grid-tile>
              <div style="margin-left: 500px">
                <span  *ngIf="errors[field.controlName]?.message"  style="color: #CC0502;height:300px; width:300px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis">{{errors[field.controlName].message}}</span>
              </div>
            </mat-grid-list>

            <mat-grid-list cols="8" rowHeight="125px" *ngSwitchCase="inputType.TEXT_AREA" class="row text-area" [ngClass]="field.heading ? 'heading' : ''">
              <mat-grid-tile [colspan]="4" [rowspan]="1">
                <app-label-link [hyperlink]="getHyperlink(field.controlName, hyperlinks)" [matTooltip]="field.title" matTooltipClass="tooltip-bg-color"
                  matTooltipHideDelay="20" [labelText]="field.label"></app-label-link>
                <span *ngIf="field.required" style="color: #CC0502">*</span>
              </mat-grid-tile>
              <mat-grid-tile [colspan]="4" [rowspan]="1" class="text-area-wt">
                <textarea type="text" [class]="field.controlName" maxlength="1000"   formControlName="{{field.controlName}}"></textarea>
              </mat-grid-tile>
            </mat-grid-list>

            <mat-grid-list cols="8" rowHeight="28px" *ngSwitchCase="inputType.DROPDOWN" class="row dropdown input" [ngClass]="field.heading ? 'heading' : ''">
              <mat-grid-tile [colspan]="4" [rowspan]="1">
                <app-label-link [matTooltip]="field.dropdownTitle" matTooltipClass="tooltip-bg-color" matTooltipHideDelay="20" [hyperlink]="getHyperlink(field.controlName, hyperlinks)"
                  [labelText]="field.label"></app-label-link>
                <span *ngIf="field.required" style="color: #CC0502">*</span>
              </mat-grid-tile>
              <mat-grid-tile [colspan]="3" [rowspan]="1">
                <select formControlName="{{field.controlName}}" (ngModelChange)="showoptions($event, field.controlName,field)">
                <option *ngFor="let option of field.options" [value]="option.value">{{option.name}}</option>
              </select>
              </mat-grid-tile>
              <mat-grid-tile [colspan]="1" [rowspan]="1" class="subDiv">
                <button type="button" *ngIf="(field.InputlateFiles  && isLateFileViewContactInput) && !latefileShowAddContactInput "  (click)="addContacts(field.alertType,field.controlName)" [id]="field.notes + '_' + k"
                        class=" {{field.controlName}}-notes">
                <span *ngIf="!isEdited">View Contact</span>
                <span *ngIf="isEdited">Edit Contact</span>
                </button>
                <button type="button" *ngIf="(field.InputlateFiles && latefileShowAddContactInput) && !isLateFileViewContactInput" (click)="addContacts(field.alertType,field.controlName)" [id]="field.notes + '_' + k"
                        class=" {{field.controlName}}-notes">
                Add Contact
                </button>

                <button type="button" *ngIf="(field.Inputnotes && ismissedFileViewContactInput) && !missedfileShowAddContactInput" (click)="addContacts(field.alertType,field.controlName)" [id]="field.notes + '_' + k"
                  class=" {{field.controlName}}-notes">
                  <span *ngIf="!isEdited">View Contact</span>
                  <span *ngIf="isEdited">Edit Contact</span>
                </button>
                <button type="button" *ngIf="(field.Inputnotes && missedfileShowAddContactInput) && !ismissedFileViewContactInput" (click)="addContacts(field.alertType,field.controlName)" [id]="field.notes + '_' + k"
                  class=" {{field.controlName}}-notes">
                  Add Contact
                </button>
                <button type="button" *ngIf="(field.OutputlateFiles  && isLateFileViewContactOutput) && !latefileShowAddContactOutput "  (click)="addContacts(field.alertType,field.controlName)" [id]="field.notes + '_' + k"
                        class=" {{field.controlName}}-notes">
                <span *ngIf="!isEdited">View Contact</span>
                <span *ngIf="isEdited">Edit Contact</span>
                </button>
                <button type="button" *ngIf="(field.OutputlateFiles && latefileShowAddContactOutput) && !isLateFileViewContactOutput" (click)="addContacts(field.alertType,field.controlName)" [id]="field.notes + '_' + k"
                        class=" {{field.controlName}}-notes">
                Add Contact
                </button>

                <button type="button" *ngIf="(field.Outputnotes && ismissedFileViewContactOutput) && !missedfileShowAddContactOutput" (click)="addContacts(field.alertType,field.controlName)" [id]="field.notes + '_' + k"
                  class=" {{field.controlName}}-notes">
                  <span *ngIf="!isEdited">View Contact</span>
                  <span *ngIf="isEdited">Edit Contact</span>
                </button>
                <button type="button" *ngIf="(field.Outputnotes && missedfileShowAddContactOutput) && !ismissedFileViewContactOutput" (click)="addContacts(field.alertType,field.controlName)" [id]="field.notes + '_' + k"
                  class=" {{field.controlName}}-notes">
                  Add Contact
                </button>
                <span *ngIf="field.listing">
                  <select
                  formControlName="{{field.controlValName}}" *ngIf="showWeekly" [ngStyle]="{'border':(isWeeklySelected)? '2px solid #FF0000FF':''}">
                <option *ngFor="let option of field.listingoptionsweekly" [value]="option.value">{{option.name}}</option>
              </select>
              <select
                  formControlName="{{field.controlValName}}" *ngIf="showMonthly" [ngStyle]="{'border':(isMonthlySelected)? '2px solid #FF0000FF':''}">
                <option *ngFor="let option of field.listingoptionsmonthly" [value]="option.value">{{option.name}}</option>
              </select>
                </span>
              </mat-grid-tile>
            </mat-grid-list>

            <mat-grid-list cols="8"  rowHeight="28px" *ngSwitchCase="inputType.TIME" class="row dropdown input" [ngClass]="field.heading ? 'heading' : ''">
              <mat-grid-tile [colspan]="4" [rowspan]="1">
                <app-label-link style="white-space: nowrap;" [hyperlink]="getHyperlink(field.controlName, hyperlinks)" [matTooltip]="field.title" matTooltipClass="tooltip-bg-color"
                  matTooltipHideDelay="20" [labelText]="field.label">
                </app-label-link>
                <span *ngIf="field.required" style="color: #CC0502">*</span>
              </mat-grid-tile>
              <mat-grid-tile [colspan]="4" [rowspan]="1">
                <input type="time" [id]="'input_' + k" [class]="field.controlName" formControlName="{{field.controlName}}">
              </mat-grid-tile>
              <div style="margin-left: 500px">
                <span  *ngIf="errors[field.controlName]?.message"  style="color: #CC0502;height:300px; width:300px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis">{{errors[field.controlName].message}}</span>
              </div>
            </mat-grid-list>


            <div *ngSwitchCase="inputType.MDL_DROPDOWN" class="dropdown input" [ngClass]="field.heading ? 'heading' : ''">
              <app-label-link [hyperlink]="getHyperlink(field.controlName, hyperlinks)" [labelText]="field.label"></app-label-link>
              <select formControlName="{{field.controlName}}">
                <option *ngFor="let option of (field.mdlName | async)?.options"
                        [value]="option">{{option}}</option>
              </select>
            </div>

            <div *ngSwitchCase="inputType.FREEFORM_MULTISELECT" class="auto-complete multi-select input" [ngClass]="field.heading ? 'heading' : ''">
              <app-label-link [hyperlink]="getHyperlink(field.controlName, hyperlinks)" [labelText]="field.label"></app-label-link>

              <app-freeform-multiselect [formControlName]="field.controlName" [defaultOptions]="field.options"></app-freeform-multiselect>
            </div>

            <div *ngSwitchCase="inputType.MULTI_SELECT" class="multi-select input" [ngClass]="field.heading ? 'heading' : ''">
              <app-label-link [hyperlink]="getHyperlink(field.controlName, hyperlinks)" [labelText]="field.label"></app-label-link>

              <mat-select multiple formControlName="{{field.controlName}}">
                <mat-option *ngFor="let option of field.options" [value]="option">{{option}}</mat-option>
              </mat-select>
            </div>

            <div *ngSwitchCase="inputType.HORIZONTAL_FIELD_SET" class="horizontal-field-set" [ngClass]="field.heading ? 'heading' : ''">
              <app-label-link [hyperlink]="getHyperlink(field.subFormGroup[0].controlName, hyperlinks)" [labelText]="field.label"></app-label-link>
              <div class="input-fields">
                <div *ngFor="let subForm of field.subFormGroup">
                  <ng-container [ngSwitch]="subForm.inputType">
                    <div *ngSwitchCase="inputType.TEXT" class="text input">
                      <input type="text" matInput  formControlName="{{subForm.controlName}}">
                    </div>

                    <div *ngSwitchCase="inputType.DROPDOWN" class="dropdown input">
                      <select formControlName="{{subForm.controlName}}">
                        <option *ngFor="let option of subForm.options" [value]="option">{{option}}</option>
                      </select>
                    </div>

                  </ng-container>
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>
    </div>
  </div>
</form>

