import { Injectable } from "@angular/core";
import {Resolve, Router} from '@angular/router';
import { forkJoin, Observable, of } from "rxjs";
import { UsersService } from "../services/users.service";


@Injectable({
    providedIn: 'root'
})

export class MemberSearchDataResolver implements Resolve<any> {
    constructor(private usersService:UsersService,
        private router: Router
    ){}
    

    resolve(): Observable<any> {
        return this.usersService.betaTogglePermission
    }
}