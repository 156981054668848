import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormArray, FormControl, UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { forkJoin, fromEvent, Observable, of, Subject } from 'rxjs';
import { map, filter, debounceTime, distinctUntilChanged, catchError, switchMap } from 'rxjs/operators';
import { LoaderService } from 'src/app/loader.service';
import { FileLayoutApiService } from 'src/app/services/file-layout-api.service';
import { AuditHistoryComponent } from './audit-history/audit-history.component';
import { DeleteRecordConfirmationComponent } from './delete-record-confirmation/delete-record-confirmation.component';
import { FileLayoutDialogComponent } from './file-layout-dialog/file-layout-dialog.component';
import { FileLayoutService } from './file-layout.service';
import { CVSAlertService , CVSAlertType } from 'angular-component-library';
import { LogicCodeService } from '../logic-code/logic-code.service';
import {UsersService} from "../../services/users.service";
import {MessageSupressionService} from "../../services/message-supression.service";
import { MessageCodePopupComponent } from './message-code-popup/message-code-popup.component';
@Component({
  selector: 'app-file-layout',
  templateUrl: './file-layout.component.html',
  styleUrls: ['./file-layout.component.scss']
})
export class FileLayoutComponent implements OnInit {
  [x: string]: any;
  searchForm: UntypedFormGroup;
  completeFileTableData: any;

  constructor(private flService: FileLayoutService,
              private msgSupressService: MessageSupressionService,
              private dialog: MatDialog,
              public api: FileLayoutApiService,
              private alertService: CVSAlertService,
              public logicService: LogicCodeService,
              private usersService: UsersService
  ) { }

  leftpanelVisible = true;
  expansionIcon = 'angle-left--s';
  recordData: Array<any> = [];
  recordForm: UntypedFormGroup;
  dialogConfig:any = new MatDialogConfig();
  allRecordData: any[];
  fileTableData: any[];
  fileLayoutForm: UntypedFormGroup;
  isEditable = false;
  selectedRecordData: any;
  currentCheckboxIndex: any = null;
  searchRecordForm: UntypedFormGroup;
  displayedColumns = ['check', 'fieldNumber', 'fieldName', 'position', 'length',
    'use', 'type', 'fieldInformation', 'internalNotes','rejectLevel','fieldActions','actionValues','fieldValidation','fieldValues',  'rejectActions','rejectActionValues', 'messageCode', 'profile',
    'memberDetails', 'sendToSi', 'auditHistory'];
  userName: string;
  showSpinner: Subject<boolean> = new Subject();
  sendToSiOption: Array<any> = ['Yes', 'No', null];
  rejectActionsData:any = [];
  validationsData:any = [];
  fieldActionsData: any =[];
  rejectActionsApiPending:boolean;
  fieldConfig: any = [
    { fieldName: 'rejectActions', defaultField: 'rejectActionsDefault', fieldNameValues: this.rejectActionsData},
    { fieldName: 'fieldValidation', defaultField: 'validationDefault', fieldNameValues: this.validationsData},
    { fieldName: 'fieldActions', defaultField: 'fieldActionsDefault', fieldNameValues: this.fieldActionsData},
  ]

  ngOnInit() {
    this.getUser();
    this.getRejectActionsData();
    this.getValidationData();
    this.getFieldActionsData();
    this.loadData();
  }

  get filelayout(): UntypedFormArray {
    if (this.fileLayoutForm) {
      return this.fileLayoutForm.get('filelayout') as UntypedFormArray;
    } else {
      return null;
    }
  }

  get isRowSelected(): boolean {
    const fileLayoutArray: UntypedFormArray = this.filelayout;
    // if (!defaulterCase) {
    //   this.currentCheckboxIndex = index;
    // }


    if (fileLayoutArray) {
       const checkedGroup = (fileLayoutArray.controls as Array<any>).find((group, groupIndex) => {
          if (group.value.check) {
            return group;
          }
        });
       const search =  this.searchForm.get('search').value;
       if (checkedGroup) {
        return false;
       } else {
        return true;
       }
      } else {
        return true;
      }

  }

  getUser() {
    this.usersService.user.subscribe(resp => {
      if (resp.lastName) {
        this.userName = resp.lastName;
        if (resp.firstName) {
        this.userName = this.userName + ', ' + resp.firstName;
        }
      } else if (resp.firstName) {
        this.userName = resp.firstName;
      } else {
        this.userName = 'Visitor';
      }
    });
  }

  getRejectActionsData() {
    this.rejectActionsApiPending = true;
    this.msgSupressService.getRejectActionsRows().subscribe(res => {
      this.rejectActionsApiPending = false;
      this.rejectActionsData = res;
      this.fieldConfig[0].fieldNameValues = this.rejectActionsData;
      this.rejectActionsData.sort((a, b) => a.id - b.id);
  });
  }
  getValidationData(){
    this.rejectActionsApiPending = true;
    this.msgSupressService.getValidationRows().subscribe(res => {
      this.rejectActionsApiPending = false;
      this.validationsData = res;
      this.fieldConfig[1].fieldNameValues = this.validationsData;
      this.validationsData.sort((a, b) => a.id - b.id);
    });
  }
  getFieldActionsData(){
    this.rejectActionsApiPending = true;
    this.msgSupressService.getFieldActionsRows().subscribe(res => {
      this.rejectActionsApiPending = false;
      this.fieldActionsData = res;
      this.fieldConfig[2].fieldNameValues = this.fieldActionsData;
      this.fieldActionsData.sort((a, b) => a.id - b.id);
    });
  }

  loadData() {
    LoaderService.show();
    this.isEditable = false;

    this.api.getRecordApi().subscribe(resp => {
      if (Array.isArray(resp)) {
        this.allRecordData = resp;
        this.recordData = resp;
        if (resp.length) {
          this.sortRecords();
        } else {
          this.fileTableData = [];
          this.selectedRecordData = null;
        }

       // this.loadRecord(this.recordData[0], 0);

      }
    });
    this.createSearchForm();
    this.addSearch();
    this.addSubscribers();
  }

  addSubscribers() {
    (this.searchRecordForm.get('recordSearch')).valueChanges.pipe(debounceTime(200), distinctUntilChanged()).subscribe(input => {
      this.searchRecord(input);
    });

    this.flService.tabClicked$.subscribe(update => {
      if (update) {
        this.validatePositionField();
      }
    });
  }
  createSearchForm() {
    this.searchRecordForm = this.flService.createRecordSearch();
  }
  getSelectedValue(element, fieldName) {
    const currentSelected = this.fieldConfig.filter((item)=> item.fieldName === fieldName)
    if(currentSelected &&  currentSelected.length > 0) {
      let data=[];
      const actions = element.get(fieldName)?.value;
      const defaultId = element.get(currentSelected[0].defaultField)?.value;
      if(!actions){
        return '';
      }
      currentSelected[0].fieldNameValues?.forEach(t=>{
        if(actions.includes(t.id) && t.id === defaultId){
          data.push(t.message);
        }
      });
      return data.join();
     }

  }
  updateFileTable(record) {

    this.selectedRecordData = record;
    this.api.getFileLayoutData(record).subscribe(resp => {
      this.fileTableData = resp;
      for (let i=0; i< this.fileTableData.length; i++) {
        this.fileTableData[i].highlighted = false;
      }
      this.completeFileTableData = resp;
      this.createForm(this.fileTableData);
      this.validatePositionField();
      LoaderService.hide();
    },
      err => {
        LoaderService.hide();
        console.error(err);
      });


  }
  validatePositionField(hide?) {
    if (hide) {
      this.showWarning(false);
      return;
    }
    if (this.filelayout && this.filelayout.controls) {
      const form = (this.filelayout.controls as Array<any>);
      let showWarning = false;
      form.forEach((group, index) => {
        if (index > 0) {

          const value = group.getRawValue();
          const previousGroupValue = form[index - 1].getRawValue();
          if (value.position === (previousGroupValue.position + previousGroupValue.length)) {
            group.get('position').showWarning = false;
          } else {
            group.get('position').showWarning = true;
            showWarning = true;
          }
        }
      });
      if (showWarning) {
        this.showWarning(true);
      } else {
        this.showWarning(false);
      }
    } else {
      this.showWarning(false);
    }
  }
  createForm(data) {
    this.recordForm = this.flService.createRecordGroup(this.recordData);
    this.fileLayoutForm = this.flService.createFileLayoutGroup(data);
  }

  setDialogConfig(id) {
    switch (id) {
      case 0:
        this.dialogConfig.data = {
          id: 1,
          title: 'Record Type',
          type: 'add_record'

        };
        break;
      case 1:
        this.dialogConfig.data = {
          id: 1,
          title: 'Confirmation',
          type: 'delete'
        };
        break;
      case 2:
        this.dialogConfig.data = {
          id: 2,
          title: 'Add File Layout ',
          type: 'add_file_layout'
        };
        break;
      case 3:
        this.dialogConfig.data = {
          id: 1,
          title: 'Add File Layout',
          type: 'rename_record'
        };
        break;
      case 4:
        this.dialogConfig.data = {
          id: 1,
          title: 'Audit History',
          type: 'audit_History'
        };
        break;
      case 5:
        this.dialogConfig.data = {
          id: 1,
          title: 'New Record Information',
          type: 'add_record'
        };
        break;
      case 6:
        this.dialogConfig.data = {
          id: 1,
          title: 'Audit History',
          type: 'show_history'
        };


    }
    this.dialogConfig.disableClose = true;
    this.dialogConfig.autoFocus = true;
    this.dialogConfig.data.rejectActionsData = this.rejectActionsData;
    this.dialogConfig.data.validationsData = this.validationsData;
    this.dialogConfig.data.fieldActionsData = this.fieldActionsData;
  }
  addRecord() {
    this.setDialogConfig(0);
    const dialogData = this.dialog.open(FileLayoutDialogComponent, this.dialogConfig);
    dialogData.afterClosed().subscribe(input => {
      if (input != null) {
        this.api.addRercordApi(input).subscribe(resp => {
          if (resp) {
            this.loadData();
            this.logicService.updateLogicField();
            this.showMessage('Record header added !');
          }
        }, err => {
          console.error(err);
        });
      }

    });
  }
  showMessage(message?) {
    this.alertService.sendAlert({
      headline: message ? message : 'Save successful !',
      alertType: CVSAlertType.Success,
      removedAfterMilliseconds: 3000,
      alertDocumentHolderName: '#file-layout',
    });
  }

  showWarning(show) {
    if (document.getElementById('file-layout-warning')) {
    const element = document.getElementById('file-layout-warning');
    if (show) {
       element.style.display = 'block';
      } else {
        element.style.display = 'none';
      }

    }

  }

  loadRecord(record, recIndex) {
    LoaderService.show();
    this.selectedRecordData = record;
    this.selectedRecordData.recIndex = recIndex;
    this.updateFileTable(record);

  }

  searchRecord(input) {
    if (input === '') {
      this.recordData = this.allRecordData;
      this.selectedRecordData = this.recordData[0];
      this.selectedRecordData.recIndex = 0;
      this.updateFileTable(this.selectedRecordData);

    } else {
      const value = input.trim();
      const searchedRecords = this.allRecordData.filter(data => {
        if (data.recordType?.toLowerCase().includes(value?.toLowerCase())) {
          return data;
        }
      });

      this.recordData = searchedRecords;
      if (this.recordData.length) {
        this.selectedRecordData = this.recordData[0];
        this.selectedRecordData.recIndex = 0;
        this.updateFileTable(this.selectedRecordData);
      } else {
        this.fileTableData = [];
      }

    }

  }

  sortRecords() {
    function compare(a, b) {
      // Use toUpperCase() to ignore character casing
      const value1 = a.recordType?.toUpperCase();
      const value2 = b.recordType?.toUpperCase();

      let comparison = 0;
      if (value1 > value2) {
        comparison = 1;
      } else if (value1 < value2) {
        comparison = -1;
      }
      return comparison;
    }

    this.recordData.sort(compare);
    this.loadRecord(this.recordData[0], 0);
    this.isEditable = false;
  }

  setEditable() {
    if (!this.isEditable) {
      this.isEditable = true;

    } else {
      this.isEditable = false;
    }
  }

  saveFileLayout() {

    if (this.fileLayoutForm.invalid) {
      this.fileLayoutForm.markAllAsTouched();
    } else {

    this.setDialogConfig(4);
    const dialogData = this.dialog.open(AuditHistoryComponent, this.dialogConfig);
    dialogData.afterClosed().subscribe(
      (resp) => {
        if (resp !== null) {
          const updatedValues = this.fileLayoutForm.getRawValue().filelayout;
          const updateBody = [];
          const auditHistoryPayload =  [];
          
          updatedValues.forEach((ele, index) => {
          if (ele.check === true) {
          updateBody.push( {
            ...ele,
            fileLayoutPKRecord: {
              recordType: this.selectedRecordData.recordType,
              recordName: this.selectedRecordData.recordName,
              fieldNumber: this.fileTableData[index].fieldNumber  // previous field number
          },
          fieldValues:ele.fieldValues,
          actionValues:ele.actionValues,
          fieldValidation: this.validationsData.filter((t)=>ele.fieldValidation?.includes(t.id)).map(d=>{
           
            const object2 = Object.assign({}, d, {isdefaultOption: (d.id === ele.validationDefault)});
            return object2;
          }),
          rejectActions:this.rejectActionsData.filter(t=> ele.rejectActions?.includes(t.id)).map(d=> {
            let messageCode = ele.rejectActionsMessage.find(data=>data.id === d.id)?.messageCode
            const object2 = Object.assign({}, d, {isdefaultOption: (d.id === ele.rejectActionsDefault), messageCode});
            return object2;
            // d.messageCode = d.msgCode === ele.rejectActionsMessageCode;

          }),

          fieldActions:this.fieldActionsData.filter(t=> ele.fieldActions?.includes(t.id)).map(d=> {
            
            const object2 = Object.assign({}, d, {isdefaultOption: (d.id === ele.fieldActionsDefault)});
            return object2;
            
          }),

          recordType: this.selectedRecordData.recordType,
          recordName: this.selectedRecordData.recordName,
          });
           // multiple obj for edit file layout table
          auditHistoryPayload.push( {
            recordType: this.selectedRecordData.recordType,
            recordName: this.selectedRecordData.recordName,
            fieldNumber: ele.fieldNumber, // updated field number.
            auditUser: this.userName,
            auditComment: resp.auditComment
          });
          // multiple comment body for audit history
          }
          } );

          this.api.editFileLayoutDetail(updateBody).subscribe((edit) => {
            if (edit) {
              this.api.addAudit(auditHistoryPayload).subscribe(() => {
                this.isEditable = false;
                this.updateFileTable(this.selectedRecordData);
                this.logicService.updateLogicField();
                this.showMessage('Field Number(s) modified !');
              });
            }

            });

          } else {
            // do nothing keep it editable.
          }
        }
      );
  }
    this.searchForm.controls['search'].setValue(null);
    }


  exitSaveFileLayout() {
    this.isEditable = false;

    this.fileTableData = [];

    this.api.getFileLayoutData(this.selectedRecordData).subscribe(resp => {
      this.fileTableData = resp;
      this.fileLayoutForm = this.flService.createFileLayoutGroup(this.fileTableData);
      this.validatePositionField();
    },
      err => console.error(err));
    this.searchForm.controls['search'].setValue(null);

  }

  deleteFileLayout() {
    this.setDialogConfig(1);
    const dialogData = this.dialog.open(DeleteRecordConfirmationComponent, this.dialogConfig);
    dialogData.afterClosed().subscribe(
      (resp) => {
        if (resp != null) {
          const layoutValues = this.fileLayoutForm.getRawValue().filelayout;
          const deleteBody = [];
          layoutValues.forEach(value => {
            if (value.check) {
              deleteBody.push({ ...value, ...this.selectedRecordData });
            }
          });

          this.api.deleteFileLayoutRecord(deleteBody).subscribe((resp1) => {
            if (resp1 !== null) {
              this.updateFileTable(this.selectedRecordData);
              this.logicService.updateLogicField();
              this.showMessage('Field Number(s) deleted !');
            }

          }, err => {
            console.error(err);
          });
        }
      }
    );
  }

  deleteRecord(record) {
    // /deleterecordtype/File Header/9 Rec Type
    this.setDialogConfig(1);
    const dialogData = this.dialog.open(DeleteRecordConfirmationComponent, this.dialogConfig);

    dialogData.afterClosed().subscribe(
      (resp) => {
        if (resp != null) {
          this.api.deleteRecord(record).subscribe((resp1) => {
            if (resp1) {
              this.loadData();
              this.showMessage('Record header deleted !');
            }
          }, err => {
            console.error(err);
          });
        }
      }
    );

  }

  renameRecord(record) {
    this.setDialogConfig(3);
    this.dialogConfig.data.currentRecordData =  this.selectedRecordData;
    const dialogData = this.dialog.open(FileLayoutDialogComponent, this.dialogConfig);
    dialogData.afterClosed().subscribe(input => {
      if (input !== null) {
        this.api.renameRecord(record, input).subscribe(resp => {
          if (resp !== null) {
            this.loadData();
            this.showMessage();
          }

        }, err => {
          console.error(err);
        });
      }
    });
  }

  copyRecord(record) {
    this.setDialogConfig(5);
    const dialogData = this.dialog.open(FileLayoutDialogComponent, this.dialogConfig);
    dialogData.afterClosed().subscribe(input => {
      if (input != null) {
        this.api.addRercordApi(input).subscribe(() => {

          const apis = [];
          this.api.getFileLayoutData(record).subscribe(resp => {
            const fileTableData = resp;
            fileTableData.forEach((fieldRecord) => {
              fieldRecord.recordType = input.recordType;
              fieldRecord.recordName = input.recordName;
              apis.push(this.api.addFileLayoutRecord(fieldRecord).pipe(catchError((e) => of(e))));
            });
            forkJoin(apis).subscribe(() => {
              this.loadData();
            });
          }, err => {
            console.error(err);
          });
        });
      }
    });
  }

  addFileLayout() {
    if(this.rejectActionsApiPending){
      return;
    }
    this.setDialogConfig(2);
    const dialogData = this.dialog.open(FileLayoutDialogComponent, this.dialogConfig);
    dialogData.afterClosed().subscribe(input => {

      if (input != null) {

        const body = { ...input, ...this.selectedRecordData };

        this.setDialogConfig(4);
        const dialogData1 = this.dialog.open(AuditHistoryComponent, this.dialogConfig);
        dialogData1.afterClosed().subscribe(
            (resp) => {
              if (resp != null) {
                const auditHistoryPayload = [{
                  recordType: this.selectedRecordData.recordType,
                  recordName: this.selectedRecordData.recordName,
                  fieldNumber: body.fieldNumber,
                  auditUser: this.userName,
                  auditComment: resp.auditComment
                }];
                this.api.addFileLayoutRecord(body).subscribe((resp1) => {
                  if (resp1 !== null) {
                    this.api.addAudit(auditHistoryPayload).subscribe((resp2) => {
                      if (resp2) {
                        this.updateFileTable(this.selectedRecordData);
                        this.logicService.updateLogicField();
                        this.showMessage('Field Number added !');
                      }
                    });
                  }
                });
              }
            });
      }
    });
  }

  addSearch() {
    this.searchForm =  this.flService.generateImportSearch();
   // this.subs.push(
    this.searchForm.get('search').valueChanges.pipe(
         debounceTime(100),
         distinctUntilChanged(),
        switchMap(resp => this.filterData(resp)) ).subscribe();
   // );
   }
    filterData(resp): Observable<any> {
      this.showSpinner.next(true);
      if (!resp) {
       this.createForm(this.completeFileTableData);
      } else {

        const filteredArray = [];
        this.completeFileTableData.forEach(data => {
          const keys  = Object.keys(data);

          for (const key of keys) {
            if (data[key] &&
              (data[key]?.toString().toLowerCase().includes(resp?.trim().toLowerCase()))) {
              filteredArray.push(data);
              return;
            }
          }
        });
        this.createForm(filteredArray);
      }
      setTimeout(() => {
        this.showSpinner.next(false);

      }, 100);
      return of(null);
    }

  /*

  onCheckBoxSelection(event, index) {

    const fileLayoutArray: FormArray = this.filelayout;


    if (this.isEditable) {

      (fileLayoutArray.controls as Array<any>).forEach((group, groupIndex) => {

        // if (!defaulterCase) {
        //   this.currentCheckboxIndex = groupIndex;
        //   // last checkbox remaining make it bydefault currently selected
        // }
        if (group.value.check) {
           enable file layout fields.
          group.enable();
        } else {
          group.disable();
          group.patchValue({ check: false });
          group.get('check').enable();
          group.get('auditHistory').enable();
        }
         creates a toggle behaviour.
      });


    }
    // else if (event && !event.checked) {
    //    setting the last checkbox as currently selected eg: select 3 cehckbox and then uncheck
    //    them one by one till last one remains
    //   technically that should be the currently selected checkbox . but as per previous conditions on
    //   click selected we change it not in anyother case.
    //    SO this is to handel the edit scenerio for same.
    //
      (fileLayoutArray.controls as Array<any>).forEach((group, groupIndex) => {
        if (group.value.check === true) {
          this.currentCheckboxIndex = groupIndex;
        }
      });

    }
   */


  seeHistory(rowIndex) {
    const fileLayoutArray: UntypedFormArray = this.filelayout;
    const group = (fileLayoutArray.controls as Array<any>)[rowIndex];
    const payload = {
      fieldNumber: group.getRawValue().fieldNumber,
      recordType: this.selectedRecordData.recordType,
      recordName: this.selectedRecordData.recordName
    };
    this.api.getAuditHistory(payload).subscribe(resp => {
      this.setDialogConfig(6);
      this.dialogConfig.data.history = resp;
      const dialogData = this.dialog.open(AuditHistoryComponent, this.dialogConfig);
      dialogData.afterClosed().subscribe(
        () => {

        });
    });
  }


  HandleRadioEvent($event: any) {
    $event.stopPropagation();
  }

  HandleSearchEvent($event: any) {
    $event.stopPropagation();
  }
  dataItemChecked(item: any, element: any, defaultVal: string) {
    const defaultId = element.get(defaultVal).value;
    return item.id=== defaultId;
  }
  isSelected(item: any, element: any, listArrName){
    return element.get(listArrName).value?.includes(item.id)
  }
  radioChange($event: any, element, defaultVal: string) {
    element.get(defaultVal).setValue($event.value);
  }

  selectionChange(controlValue, element , controlName, messageControlName){
  let messageCodeArray = element.get(messageControlName).value;
 
  let coordinatedAction = [];
  controlValue.forEach(id=>{
   if(messageCodeArray?.find(data => data?.id === id)){
    coordinatedAction.push(messageCodeArray.find(data => data.id === id));
   }else{
    coordinatedAction.push({...this.rejectActionsData.find(data=>data.id === id), messageCode:''});
   }
  })
  element.get(messageControlName).patchValue(coordinatedAction,{emitEvent:false});
  }

  getMessageCode(element , item ,controlName){
   let value = element.get(controlName)?.value?.find(data=>data?.id === item.id);
   return value ? (value?.messageCode? value?.messageCode : false) : false;
  }

  getRejectActionsMessage(element, fieldName) {
    const currentSelected = this.fieldConfig.filter((item)=> item.fieldName === fieldName);
    if(currentSelected &&  currentSelected.length > 0) {
      const defaultId = element.get(currentSelected[0].defaultField)?.value;
      const item = { id:defaultId };
      return this.getMessageCode(element, item, 'rejectActionsMessage');
      }
  }
  
  toggleLeftPanel() {
    this.expansionIcon = this.leftpanelVisible ? 'angle-right--s':'angle-left--s';
    this.leftpanelVisible = this.leftpanelVisible ? false: true;
  }

  openDialog(item,type,element?) {

    const dialogRef = this.dialog.open(MessageCodePopupComponent, this.dialogConfig)
    dialogRef.afterClosed().subscribe(res => {
      if(type === 'rejectActions'){

       let messageCodeArray = element.get('rejectActionsMessage').value;
      if( messageCodeArray?.find(action=>action.id == item.id)){
        messageCodeArray.map(action=>{
          if(action.id==item.id){
            action.messageCode = res?.message
          }
        })
      }else{
        messageCodeArray.push({...item,messageCode:res?.message})
      }
      element.get('rejectActionsMessage').patchValue(messageCodeArray,{emitEvent:false});
      
      } else if(type === 'fieldValidation'){
        this.validationsData = this.validationsData.map(t=>{
          if(t.id === item.id){
            t.messageCode = res?.message;
          }
          return t;
        })
      } else if(type === 'fieldActions'){
        this.fieldActionsData = this.fieldActionsData.map(t=>{
          if(t.id === item.id){
            t.messageCode = res?.message;
          }
          return t;
        })
      }
    });
  }

}

