
<div style="display: flex;justify-content: end;">
  <button id="register-user-btn" class="pds-btn-text" (click)="import()"
   [disabled]="isImportEnabled"
   >
    <mat-icon svgIcon="upload-arrow--s"   id="import-icon" >
      Export PDF
  </mat-icon>
   Import
  </button>
</div>
<!-- <cvs-loader-overlay-container [isLoading]="showSpinner | async" loadingMessage="Loading"> -->
<ng-container [formGroup]="form">
  <div class="row">

    <mat-form-field class="col-md-4  custom-select">
      <mat-label>Project</mat-label>
      <mat-select  formControlName="project" >
        <ng-container *ngIf="projectList && projectList.length">
          <mat-option *ngFor="let option of projectList" [value]="option.projectName" >{{ option.projectName }}</mat-option>
        </ng-container>
      </mat-select>
      <mat-error *ngIf="!form.value.project && showValidations">Required Field</mat-error>
      <mat-hint >Required Field</mat-hint>
    </mat-form-field>
    <mat-form-field class="col-md-4  custom-select">
      <mat-label>Mapping Status</mat-label>
      <mat-select formControlName="mappingStatus" >
        <ng-container *ngIf="mapStatusList && mapStatusList.length">
          <mat-option *ngFor="let option of mapStatusList" [value]="option" >{{ option }}</mat-option>
        </ng-container>
      </mat-select>
      <mat-error *ngIf="!form.value.mappingStatus && showValidations">Required Field</mat-error>
      <mat-hint >Required Field</mat-hint>
    </mat-form-field>
    <div class="col-md-4">
      <label class="form-label">Format</label>
      <div >{{form.get('mappingFormat').value}}</div>
    </div>
    <mat-form-field class="col-md-4  custom-select">
      <mat-label>Map Type</mat-label>
      <mat-select  formControlName="mapType" >
        <ng-container *ngIf="mapTypeList && mapTypeList.length">
          <mat-option *ngFor="let option of mapTypeList" [value]="option.message" >{{ option.message}}</mat-option>
        </ng-container>
      </mat-select>

    </mat-form-field>
    <mat-form-field class="col-md-4  custom-select">
      <input matInput placeholder="User Story"  formControlName="userStory">
      <mat-error *ngIf="form.controls.userStory.errors?.required && showValidations">Required Field</mat-error>
      <mat-hint *ngIf="(isRequiredControler('userStory') )"
      >Required Field</mat-hint>
      <!--      &lt;!&ndash; <mat-error *ngIf="!form.value.userStory && showValidations">Required Field</mat-error>-->
    </mat-form-field>
  </div>

  <div class="row">
    <div class="col-md-4">
      <label class="form-label">Vendor</label>
      <div  class="form-div">{{form.get('mappingVendor').value}}</div>
    </div>

    <mat-form-field class="col-md-4  custom-select">
      <input matInput placeholder="Multiple PLI`s"  formControlName="multiplePli">
      <!-- <mat-error *ngIf="!form.value.multiplePli && showValidations">Required Field</mat-error>
       -->
    </mat-form-field>
    <mat-form-field class="col-md-4  custom-select">
      <mat-label>Rec Types</mat-label>
      <mat-select  formControlName="recTypes" multiple>
        <ng-container *ngIf="recTypeList && recTypeList.length">
          <!-- <mat-option *ngFor="let option of recTypeList" [value]="option" >{{ option }}</mat-option> -->
          <mat-option *ngFor="let option of recTypeList" [value]="option.displayName" >{{ option.displayName }}</mat-option>
        </ng-container>
      </mat-select>
      <!-- <mat-error *ngIf="( isRecArray()? !form.value.recTypes.length :
      !form.value.recTypes) && showValidations">Required Field</mat-error> -->
      <mat-error *ngIf="form.controls.recTypes.errors?.required && showValidations">Required Field</mat-error>
      <mat-hint>Required Field</mat-hint>
      
    </mat-form-field>
    <mat-form-field class="col-md-4  custom-select">
      <mat-label>Cag Management</mat-label>
      <mat-select  formControlName="cagManagement" >
        <ng-container *ngIf="cagList && cagList.length">
          <mat-option *ngFor="let option of cagList" [value]="option" >{{ option }}</mat-option>
        </ng-container>
      </mat-select>
      <!-- <mat-error *ngIf="!form.value.cagManagement && showValidations">Required Field</mat-error>
       -->
    </mat-form-field>
    <mat-form-field class="col-md-4  custom-select">

      <input matInput placeholder="CAG Table ID"  formControlName="cagTableId">
      <mat-error *ngIf="form.controls.cagTableId.errors?.required && showValidations">Required Field</mat-error>
      <mat-hint *ngIf="form.get('cagManagement').value === 'CAG Table' && !showValidations && is834Format"
      >Required Field</mat-hint>
      <!-- <mat-error *ngIf="!form.value.cagTableId && showValidations">Required Field</mat-error>
      -->
    </mat-form-field>
  </div>

  <div class="row">
    <mat-form-field class="col-md-4  custom-select">
      <mat-label>BA</mat-label>
      <mat-select  formControlName="mappingBA" >
        <ng-container *ngIf="baList && baList.length">
          <mat-option *ngFor="let option of baList" [value]="option.name" >{{ option.name }}</mat-option>
        </ng-container>
      </mat-select>
      <!-- <mat-error *ngIf="!form.value.mappingBA && showValidations">Required Field</mat-error>
       -->
    </mat-form-field>
    <!-- <mat-form-field class="col-md-4  custom-select">
        <mat-label>Leader</mat-label>
        <input matInput placeholder="Leader"  formControlName="mappingLeader">
        <mat-error *ngIf="!form.value.mappingLeader && showValidations">Required Field</mat-error>
    </mat-form-field> -->
    <div class="col-md-4">
      <label class="form-label">Leader</label>
      <div class="form-div" >{{form.get('mappingLeader').value}}</div>
    </div>
    <mat-form-field class="col-md-4  custom-select">
      <mat-label>Coder</mat-label>
      <mat-select  formControlName="mappingCoder" >
        <ng-container *ngIf="coderList && coderList.length">
          <mat-option *ngFor="let option of coderList" [value]="option.name" >{{ option.name }}</mat-option>
        </ng-container>
      </mat-select>
      <!-- <mat-error *ngIf="!form.value.mappingCoder && showValidations">Required Field</mat-error>
       -->
    </mat-form-field>
    <mat-form-field class="col-md-4  custom-select">
      <mat-label>QA</mat-label>
      <mat-select  formControlName="mappingQA" >
        <ng-container *ngIf="qaList && qaList.length">
          <mat-option *ngFor="let option of qaList" [value]="option.name" >{{ option.name }}</mat-option>
        </ng-container>
      </mat-select>
      <!-- <mat-error *ngIf="!form.value.mappingQA && showValidations">Required Field</mat-error>
       -->
    </mat-form-field>


      
      <mat-form-field class="col-md-4  custom-select">
        <mat-label class="form-label">Technical Contact</mat-label>
        <input matInput formControlName="technicalContact">
      </mat-form-field>


  </div>
  <mat-form-field style="width: 100%;" appearance="outline">
        <textarea matInput formControlName="mappingComments" maxlength="4000" placeholder="Comments" style="min-height: 6rem;"
        ></textarea>
        <mat-hint >*Maximum 4000 characters</mat-hint>
    <!-- <mat-error *ngIf="!form.value.mappingComments && showValidations">Required Field</mat-error> -->
  </mat-form-field>
</ng-container>
<!-- </cvs-loader-overlay-container> -->