<div class="popup">
    <mat-dialog-content class="dialog-header">
      <div class="icon-and-title">
        <h2>{{data.type}}</h2>
      </div>
      <button id="closeAction" class="pds-icon-only-btn" mat-dialog-close (click)="closeHandler()" >
        <mat-icon svgIcon="close-btn--s"></mat-icon>
      </button>
    </mat-dialog-content>
    <mat-dialog-content id="bodyTextContent">
      <ag-grid-angular
        class="ag-theme-alpine epr-feed-gui-grid" style="height: 300px !important;"
        #CopayContact
        [context]="contextObj"
        (cellClicked)='onCellClicked($event)'
        (dragStopped)="onDragStopped()"
        (sortChanged)="onSortChange()"
        (filterChanged)="onFilterChange()"
        [rowSelection]="rowSelection"
        [tooltipShowDelay]="tooltipShowDelay"
        (cellEditingStarted)="onCellEditingStarted($event)"
        [defaultColDef]="defaultColDef"
      ></ag-grid-angular>
  
    </mat-dialog-content>
    <mat-dialog-content  class="dialog-footer">
      <button id="addContact" class="pds-btn-primary" (click)="add()" [disabled]="!data.isEdited">
        <mat-icon svgIcon="folder--s"></mat-icon>
       Add
      </button>
      <button id="saveContact" class="pds-btn-primary" (click)="save()" [disabled]="savedisabled">
        <mat-icon svgIcon="folder--s"></mat-icon>
       Save
      </button>
      <button id="cancelContact" class="pds-btn-primary" (click)="cancel()">
        <mat-icon svgIcon="folder--s"></mat-icon>
        Cancel
      </button>
    </mat-dialog-content>
  </div>
  