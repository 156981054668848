/* eslint-disable max-len */
import {Component, OnInit} from '@angular/core';
import {UsersService} from "../services/users.service";


@Component({
  selector: 'app-rte',
  templateUrl: './rte.component.html',
  styleUrls: ['./rte.component.scss']
})
export class RteComponent  {
  constructor(private usersService: UsersService) {
  }
  selectedTab: any = null;
  
  
  tabchanged(tab: any, event: any) {
    this.selectedTab = event.tab.textLabel;
  }

  RteTabPermission(tab: string) :boolean{
    let isAllowed: boolean = false
    switch (tab) {
      case "metrics":
        if(this.usersService.permissionCodes.includes("ELG_RTE_METRICS")){
          isAllowed = true;
          if(!this.selectedTab){
            this.selectedTab = "RTE Metrics";
          }
        }
        break;
      case "suspense":
        if(this.usersService.permissionCodes.includes("ELG_SUSPENSE_REPORT")){
          isAllowed = true;
          if(!this.selectedTab){
            this.selectedTab = "Suspense";
          }
        }
        break;
      case "history":
        if(this.usersService.permissionCodes.includes("ELG_EPR_AUDIT_REPORT")){
          isAllowed = true;
          if(!this.selectedTab){
            this.selectedTab = "EPR History";
          }
        }
        break;
    
    }
    return isAllowed
  }

}

