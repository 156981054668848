import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import {Store} from '@ngrx/store';
import * as fromRoot from '../reducers';

@Injectable()
export class onRefreshActivateGuard  {
  private router : Router;

  constructor(private store: Store<fromRoot.AppState>, router: Router) {
    this.router = router;
  }

  public canActivate(activatedRouteSnapshot: ActivatedRouteSnapshot,
                     routerStateSnapshot: RouterStateSnapshot): boolean {
    if(this.isPageRefresh()){
      console.log(this.router,activatedRouteSnapshot,routerStateSnapshot)
      if(activatedRouteSnapshot.routeConfig.path === "filetracker"){
        this.router.navigateByUrl('dashboard/filetracker');
      }else  if(activatedRouteSnapshot.routeConfig.path === "dashboard/onboardingspedm"){
        this.router.navigateByUrl('dashboard/spedm');
      }else  if(activatedRouteSnapshot.routeConfig.path === "dashboard/onboardingcopay"){
        this.router.navigateByUrl('dashboard/Benefit');
      }else{
        this.router.navigateByUrl('home');
      }
      return false;
    }
    return true;
  }

  private isPageRefresh() : boolean {
    return (!this.router.navigated);
  }
}
