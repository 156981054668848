/* eslint-disable max-len */
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { testCriteria, testResultsFormConfig } from './TestingConfig';
import { InputType } from '../../models/FormFieldConfig';
import { FormType } from '../../models/FormType';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { CVSAlertService, CVSAlertType } from 'angular-component-library';
import {
  AppState,
  getDrivers,
  getProjectName,
  getReadOnly,
  getSelectedTestIteration,
  getTestIterationCount,
  getCurrentProjectTestIterations,
  getRole,
  getClientName,
  getPli,
  getCarriers, getEPRState, getEprId,
  getHeaderState,
  getActiveUsers
} from '../../reducers';
import { Store } from '@ngrx/store';
import {
  AddTestIterationAction,
  ChangeSelectedTestIterationAction,
  DeleteCustomTestCriteriaAction,
  DeleteTestIterationAction
} from '../../actions/testing.action';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyTabGroup as MatTabGroup } from '@angular/material/legacy-tabs';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Direction } from 'angular-component-library';
import * as html2pdf from 'html2pdf.js';
import { ConfirmationDialogComponent } from '../confirmation-dialogue/confirmation-dialog.component';
import { AlertType } from '../../models/AlertType';
import { ConfirmationDialogData } from '../questionnaire/confirmation-dialog-content/confirmation-dialog-content.component';
import { Permission } from '../../models/Authorization';
import { UsersService } from "../../services/users.service";
import { EprService } from 'src/app/services/epr.service';
import {ProjectOrTestingEdit, SelectProjectAction, StartEditAction, StopEditAction} from "../../actions/epr.action";
import {BackupEcCellRendererComponent} from "../../common/BackupEcCellRenderer";
import * as moment from "moment/moment";
import { ProjectConfirmationDialogComponent } from '../project-tracker/project-confirmation-dialogue/project-confirmation-dialog.component';
import { User } from 'src/app/models/User';

@Component({
  selector: 'app-testing',
  templateUrl: './testing.component.html',
  styleUrls: ['./testing.component.scss']
})
export class TestingComponent implements OnInit , AfterViewInit, OnDestroy {

  @ViewChild(MatTabGroup) tabGroup: MatTabGroup;
  @ViewChild('projectInfoGrid') projectInfoGrid;
  Permission = Permission;
  public rowSelection: "single" | "multiple" = "single";
  public rowSelectedData: any;
  projectName$ = this.store.select(getProjectName);
  currentTestIterIndex$ = this.store.select(getSelectedTestIteration);
  testIterCount$ = this.store.select(getTestIterationCount);
  testingIterationCount:number;
  testIterList$ = this.store.select(getCurrentProjectTestIterations);
  clientName$ = this.store.select(getClientName);
  carriers$ = this.store.select(getCarriers);
  pli$ = this.store.select(getPli);
  eprId$ = this.store.select(getEprId);

  currentTestIterIndex: number=0;
  currentTestIterList: any[] = [];
  currentTestDetail;
  totalTestIterList: any;
  currentTestIterIndexSubscription: Subscription;
  testIterListSubscription: Subscription;
  activeUsers$ = this.store.select(getActiveUsers) as Observable<User[]>;



  formType = FormType;
  inputType = InputType;
  popoverDirection = Direction;


  drivers$ = this.store.select(getDrivers);
  drivers ;
  testingForm: UntypedFormGroup = new UntypedFormGroup({});
  customTestCriteriaFormArray: UntypedFormArray = new UntypedFormArray([]);

  readOnly$ = this.store.select(getReadOnly) as Observable<boolean>;

  testResultsFormConfig = testResultsFormConfig;
  testingCriteria = testCriteria;

  statusOptions = ['', 'Not Applicable', 'Not Tested', 'EC Discretion', 'Fail', 'Insufficient Data', 'Pass'];
  severityOptions = ['', 'LOW', 'MED', 'HIGH'];
  testingMethods = ['None', 'Visual', 'Informatica', 'ACE', 'PROD', 'Other'];

  html2pdf = html2pdf();
  role: any;
  isAddIteration: boolean;
  hlGridHeight = 350;


  readOnly: boolean = true;
  isTestResultsClicked: boolean = false;
  isEprReadOnly
  disableSave: boolean = false;
  readOnlySubscription: Subscription;
  carriers: string;
  clientName: string;
  pli: string;
  eprId: any;
  eprName:any;
  clientCode: string;
  eligibilityConsultant:any;
  goLiveDate:any;
  holdDate:any;
  editorId:any;
  qlClientCode1:any;
  obec:boolean;
  isEditTestingResults: boolean;
  isRegisteredUser$= this.usersService.userRole.subscribe(res => this.isRegisteredUser$ =res)
  isAdmin = false;
  editAdminAccess = true;
  userId: number;
  //showEditButton = true;
  headerId:any;
  ec: any;
  tester: any;
  usersList: any= [];
  users: any;
  payloadTestingEntityList:any=[];
  projectId: any;
  testingId:0;
  selectedTestIteration:any;
  displayProjectForTesting: any;
  loadingTemplate: any = `<div aria-live="polite" aria-atomic="true" style="display: flex;justify-content: center;align-items: center;font-size: 20px;opacity: 0.5">Loading...</div>`;
  newIteration: any={
  "id": null,
  "createdOn": new Date(),
  "testIterationNbr": 0,
  "testIterationDate": null,
  "testFileType": null,
  "testIterationFileName": null,
  "testFileVolume": null,
  "tester": 0,
  "iterationTstCaseSFDC": null,
  "testIterationStatus": null,
  "tstCltAprvl": null,
  "testIterationMethodology": [],
  "tCHeaderTrailerStatus": null,
  "tCHeaderTrailerSvrty": null,
  "tCHeaderTrailerNotes": null,
  "tCEndofRecordStatus": null,
  "tCEndofRecordSvrty": null,
  "tCEndofRecordNotes": null,
  "tCCAGStatus": null,
  "tCCAGSvrty": null,
  "tCCAGNotes": null,
  "tCIDPCStatus": null,
  "tCIDPCSvrty": null,
  "tCIDPCNotes": null,
  "tCFamTypeStatus": null,
  "tCFamTypeSvrty": null,
  "tCFamTypeNotes": null,
  "tCDatesStatus": null,
  "tCDatesSvrty": null,
  "tCDatesNotes": null,
  "tCNumericFieldStatus": null,
  "tCNumericFieldSvrty": null,
  "tCNumericFieldNotes": null,
  "tCSpecialCharLwrCaseStatus": null,
  "tCSpecialCharLwrCaseSvrty": null,
  "tCSpecialCharLwrCaseNotes": null,
  "tCAddrStatus": null,
  "tCAddrSvrty": null,
  "tCAddrNotes": null,
  "tCDemographicStatus": null,
  "tCDemographicSvrty": null,
  "tCDemographicNotes": null,
  "tCLanguageStatus": null,
  "tCLanguageSvrty": null,
  "tCLanguageNotes": null,
  "tCOtherEligStatus": null,
  "tCOtherEligSvrty": null,
  "tCOtherEligNotes": null,
  "tCType4Status": null,
  "tCType4Svrty": null,
  "tCType4Notes": null,
  "tCType5Status": null,
  "tCType5Svrty": null,
  "tCType5Notes": null,
  "tCCOBStatus": null,
  "tCCOBSvrty": null,
  "tCCOBNotes": null,
  "tCExchangeStatus": null,
  "tCExchangeSvrty": null,
  "tCExchangeNotes": null,
  "tCIntegAccumsStatus": null,
  "tCIntegAccumsSvrty": null,
  "tCIntegAccumsNotes": null,
  "tCMedBRDSStatus": null,
  "tCMedBRDSSvrty": null,
  "tCMedBRDSNotes": null,
  "tCMedDStatus": null,
  "tCMedDSvrty": null,
  "tCMedDNotes": null,
  "tCMbrLvlPlanStatus": null,
  "tCMbrLvlPlanSvrty": null,
  "tCMbrLvlPlanNotes": null,
  "iterationFlag": null,
  "customTestCriteria": null
}

  rowdata: any = [];
  columnDefs: any = [

    { headerName: 'Project', field: 'projectName', sortable: true, filter: true, resizable: true },

    { headerName: 'Project Type', field: 'prjType', sortable: true, filter: true, resizable: true },
    { headerName: 'Prj Go-Live Date', field: 'prjGoLiveDate', sortable: true, filter: true, resizable: true },
    { headerName: 'Prj Status', field: 'prjStatus', sortable: true, filter: true, resizable: true },

    {
      headerName: 'Tester',
      field: 'tester',
      sortable: true,
      filter: true,
      resizable: true,
      // cellRenderer: BackupEcCellRendererComponent
    },
    { headerName: 'Test Case #', field: 'testCase', sortable: true, filter: true, resizable: true },
    { headerName: 'Overall Status', field: 'overallStatus', sortable: true, filter: true, resizable: true },
    { headerName: 'Test File Name', field: 'testFileName', sortable: true, filter: true, resizable: true },
    { headerName: 'Client/Vendor Approval', field: 'clientVendorApproval', sortable: true, filter: true, resizable: true },

    { headerName: 'Testing Due Date', field: 'testingDueDate', sortable: true, filter: true, resizable: true },
    { headerName: 'Testing Cmplt Date', field: 'testingCompleteDate', sortable: true, filter: true, resizable: true },
    { headerName: 'Baseline Due Date', field: 'baselineDueDate', sortable: true, filter: true, resizable: true },
    { headerName: 'Baseline Cmplt Date', field: 'baselineCompleteDate', sortable: true, filter: true, resizable: true },
    { headerName: 'ID Cards Due Date', field: 'idCardsDueDate', sortable: true, filter: true, resizable: true },
    { headerName: 'ID Cards Cmplt Date', field: 'idCardsCompleteDate', sortable: true, filter: true, resizable: true },

    { headerName: 'Eligibility Type', field: 'eligibilityType', sortable: true, filter: true, resizable: true },
    { headerName: 'FTP ID', field: 'ftpId', sortable: true, filter: true, resizable: true },
    { headerName: 'Vendor', field: 'vendor', sortable: true, filter: true, resizable: true },
    { headerName: 'PG Criteria', field: 'pgCriteria', sortable: true, filter: true, resizable: true },
    {headerName: 'SSOE Migrated', field: 'ssoeMigrated', sortable: true, filter: true, resizable: true, lockVisible: true},
  ];
  isProjectClicked: boolean;
  headerState: any;
  eprState: any;
  subs : Subscription[] = [];


  constructor(private store: Store<AppState>, private dialog: MatDialog, private usersService: UsersService,
              private eprService: EprService,private alertService: CVSAlertService) {

  }


  ngOnInit() {
    this.subs.push(this.store.select(getHeaderState).subscribe(resp=>{this.headerState = resp}),
    this.store.select(getEPRState).subscribe(resp=>this.eprState= resp),
    this.drivers$.subscribe(resp=>this.drivers = resp),
    this.eprService.triggerProjectTestingConfirmation$.subscribe(resp=>{
      if(resp && !this.readOnly){
        this.openConfirmationModal();
      }
    })
   )
    this.usersService.userRole.subscribe(role => {
      if (role) {
        this.role = role;
      }
    });

    this.usersService.userId.subscribe(userid => {
      this.userId = userid;
    })

    combineLatest(this.pli$, this.carriers$, this.clientName$,this.eprId$).subscribe(([pli, carrier, clientName,eprId]) => {
      this.pli = pli;
      this.carriers = carrier;
      this.clientName = clientName;
      this.eprId = eprId;

    })

    this.setupTestingForm();

    // this.currentTestIterIndexSubscription = this.currentTestIterIndex$.subscribe(index => {
    //   this.currentTestIterIndex = index;
    // });
    // this.testIterListSubscription = this.testIterList$.subscribe(iterList => {
    //   this.currentTestIterList = iterList;
    // });

    this.getRowData();
    this.eprService.reloadTest.subscribe((reload)=>{
      if(reload){
        // this.isRefresh = true
        this.getRowData(true)


      }
    })

    this.readOnlySubscription = this.readOnly$.subscribe(readOnly => {
      this.isEprReadOnly = readOnly;
      if(!this.isEprReadOnly){  //epr in edit mode
        this.readOnly = true;
        this.testingForm.disable({emitEvent:false});
        this.store.dispatch( new ProjectOrTestingEdit(!this.readOnly));
      }
      });
  }
  ngAfterViewInit(): void {
   this.subs.push(
     this.testingForm.valueChanges.subscribe(val =>{
      if(!val){
        return;
      }
      let payloadEntity = this.currentTestIterList[this.currentTestIterIndex];
      // payloadEntity.id = payloadEntity.id || 'dummy' + this.projectId+''+this.currentTestIterIndex;
      let changedEntity = this.payloadTestingEntityList.find(data => data.id === payloadEntity?.id);
      if (changedEntity) {
        payloadEntity = changedEntity;
      }
      payloadEntity = {...payloadEntity,...val, projectTrackerId: this.projectId};
      if(changedEntity){
        this.payloadTestingEntityList = this.payloadTestingEntityList.filter(rec => rec.id !== changedEntity.id);
      }
      this.payloadTestingEntityList.push(payloadEntity);
    })
  );
  }


  getRowData(refresh =false) {
    this.eprService.getTestingProjectsByEprId(this.eprId).subscribe((res: any) => {
      if (res && res.response) {
        if(refresh){
          this.rowdata = []
        }
        res.response = res.response.sort((a,b) => b.projectId -a.projectId);
        res?.response?.forEach((project:any)=>{
          let formattedDate =  project.projStartDate ? moment(project.projStartDate).format('YYMMDD'): "";
          const obj = {
            projectName: "Project - "+ formattedDate +" "+ (project.projectType? project.projectType : ""),
            projectId: project.projectId,
            prjType: project.projectType,
            prjGoLiveDate: project.prjGoLiveDte,
            prjStatus: project.tPrjStatus,
            tester: project.tester,
            testCase: project.iterationTstCaseSFDC,
            overallStatus: project.testIterationStatus,
            testFileName: project.testIterationFileName,
            clientVendorApproval: project.tstCltAprvl,
            testingDueDate: project.newTstDueDate,
            testingCompleteDate: project.newTstCmpltDate,
            baselineDueDate: project.newBLDueDate,
            baselineCompleteDate: project.newBLCmpltDate,
            idCardsDueDate: project.newIDCrdsDueDate,
            idCardsCompleteDate: project.newIDCrdsCmpltDate,
            eligibilityType: project.eligibilityType,
            ftpId: project.ftpId,
            vendor: project.vendorLookup,
            pgCriteria: project.pgCriteria,
            ssoeMigrated: project.consolidateProfileLogic,
            projectStartDate: project.projStartDate,
            projectDisplayName: formattedDate +" " + (project.projectType? project.projectType : "") + " " + 'Testing'
          }
          this.rowdata.push({...obj});
          if(this.rowdata.length){
            this.displayProjectForTesting= this.rowdata[0].projectDisplayName;
          }
        })
          if(refresh){
            this.eprService.reloadTest.next(false)
            this.projectInfoGrid?.api.setGridOption("rowData",[]);
            this.projectInfoGrid?.api.setGridOption("rowData",this.rowdata);
            
          }else{
            this.projectInfoGrid?.api.setGridOption("rowData",this.rowdata);
          }





        // if(this.rowdata.length > 0){
        //   this.fetchTestIterationsByPrjId(this.rowdata[0]?.projectId);
        //   this.projectInfoGrid?.api.hideOverlay();
        // }
      }
    });
  }

  getName(id) {
         const filteredUsers = this.usersList.filter(user => user.id === id);
         let tester = filteredUsers.length >= 1 ? filteredUsers[0].firstName + ' ' + filteredUsers[0].lastName : '';
         return tester;
  }

  changeMatLabel() {
    const testingDocument = document.getElementById('testing-form');

  }

  public get emailBody(): string {
    const bodyText= `Attached you will find the Client Eligibility Test Results document. This includes the status, severity, and comments related to each specific criteria that were included in testing, as well as the overall file characteristics and test status. %0A%0AIt’s important that you review this information to ensure CVS Health is meeting your testing needs associated with processing your eligibility files.  %0A%0AIf you agree, please reply to this email with your approval. However, if you would like to discuss anything feel free to set up a conference call and we will review and make any changes needed. Additionally, as changes are made going forward, we can perform additional testing, and will provide you with a new document for approval.`;
    const subject = `${this.clientName ? this.clientName?.trim() : ''}${this.pli ? ' | ' + this.pli?.trim() : ''}${this.carriers ? ' | ' + this.carriers?.trim() : ''} Test Results`;
    return `mailto:?subject=${subject}&cc=EligAdminApprovals&body=${bodyText}`;
  }

  setupTestingForm() {
    this.testResultsFormConfig.map(formField => {
      if (formField.inputType !== InputType.HEADER) {
        this.setFormField(formField);
      }
    });

    this.testingCriteria.map(criteria => {
      const statusFormField = { controlName: criteria.baseControlName + 'Status' };
      const severityFormField = { controlName: criteria.baseControlName + 'Svrty' };
      const notesFormField = { controlName: criteria.baseControlName + 'Notes' };

      this.setFormField(statusFormField);
      this.setFormField(severityFormField);
      this.setFormField(notesFormField);
    });

    this.testingForm.addControl('customTestCriteria', this.customTestCriteriaFormArray);
    this.testingForm.disable({emitEvent:false});
  }

  setFormField(formField) {
    this.testingForm.addControl(formField.controlName, new UntypedFormControl(''));
  }

  getNewCustomCriteriaGroup(): UntypedFormGroup {
    return new UntypedFormGroup({
      cstmTC: new UntypedFormControl(''),
      cstmTCStatus: new UntypedFormControl(''),
      cstmTCSvrty: new UntypedFormControl(''),
      cstmTCNotes: new UntypedFormControl('')
    });
  }

  addCustomCriterion() {
    const newCriterion = this.getNewCustomCriteriaGroup();
    const customTestCriteria = this.testingForm.get('customTestCriteria') as UntypedFormArray;

    customTestCriteria.push(newCriterion);
  }

  deleteCustomTestCriteria(index: number) {
    const customTestCriterias = this.testingForm.get('customTestCriteria') as UntypedFormArray;
    customTestCriterias.removeAt(index);

   // this.store.dispatch(new DeleteCustomTestCriteriaAction({ index, testIteration: this.currentTestIterIndex }));
  }

  addIteration() {
    //this.store.dispatch(new AddTestIterationAction());
     //const newId = 0;
     const newData = {...this.newIteration};
     let findfirstindex=  this.currentTestIterList?.find(t => t.id === null);
     if(findfirstindex !== undefined){
     if(findfirstindex?.id == null){
      this.dialog.open(ProjectConfirmationDialogComponent, {
        data: {
          body: 'Please save the added iteration.',
          additionalInfo: "Warning: This cannot be undone.",
          type: AlertType.CONFIRMATION,
          header:"Test Results Iteration" ,
          width:"25rem",
          onConfirm: () => {return;}
        } as ConfirmationDialogData
      })
      return;
     }
    }
     this.currentTestIterList.unshift(newData);
     this.currentTestIterIndex=0;
     this.testingIterationCount=this.testingIterationCount +1 ;
     this.patchTestingForm();
   }

  changeSelectedTestIteration($event: MatSelectChange) {
  //this.store.dispatch(new ChangeSelectedTestIterationAction($event.value));
     this.currentTestIterIndex= $event.value
     this.patchTestingForm();
  }


  download(): void {
    const testingDocument = document.getElementById('testing-form');
    const testingDocumentCopy = testingDocument.cloneNode(true) as HTMLElement;
    try {
      let matInputElement = testingDocumentCopy.querySelectorAll('.mat-form-field-infix')
      let elemToReplace = testingDocumentCopy.querySelectorAll('.mat-form-field-infix .mat-form-field-label-wrapper .mat-form-field-label')
      const elemLength = elemToReplace.length;
      const matInputElementLength = matInputElement.length;

      for (let i = 0; i < matInputElementLength; i++) {
        let inputElem = matInputElement[i].querySelector('input')
        if (inputElem) {
          inputElem.style.marginTop = '10px'
        }
      }
      for (let i = 0; i < elemLength; i++) {
        elemToReplace[i].classList.remove('mat-form-field-label')
        elemToReplace[i].querySelector('span').style.color = 'rgba(0, 0, 0, 0.38)';
        elemToReplace[i].querySelector('span').style.fontSize = '1rem';
        elemToReplace[2].querySelector('span').style.fontSize = '13px';
      }
    } catch (e) {
    }
    const editButton = testingDocumentCopy.getElementsByClassName('pds-btn-editTest-btn')[0];
    const downloadButton = testingDocumentCopy.getElementsByClassName('pds-btn-exportTest-pdf')[0];
    const addIterationButton = testingDocumentCopy.getElementsByClassName('pds-btn-addTest-btn')[0];
    const emailBtn = testingDocumentCopy.getElementsByClassName('pds-btn-email')[0]
    // const addCustomCriteriaButton = testingDocumentCopy.getElementsByClassName('pds-btn-text-only')[1];
    editButton?.remove();
    downloadButton?.remove();
    addIterationButton?.remove();
    emailBtn?.remove();
    // addCustomCriteriaButton.remove();

    const fileOptions = {
      margin: [0, 2, 2, 5],
      name: 'output.pdf',
      enableLinks: true,
      image: { type: 'jpeg' },
      pagebreak: { mode: 'css' },
      html2canvas: { dpi: 192 },
      jsPDF: { orientation: 'portrait', format: 'letter' }
    };

    this.html2pdf.from(testingDocumentCopy).set(fileOptions).save('Testing.pdf');
    this.html2pdf = html2pdf(); // Set the variable to a fresh instance to alleviate caching issues within the library
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub=>sub.unsubscribe());
  }


  selectChip(method: string) {
    const methodologyControl = this.testingForm.controls['testIterationMethodology'];
    const copiedValue = [...methodologyControl?.value];
    const indexOf = copiedValue.indexOf(method);
    if (indexOf > -1) {
      copiedValue.splice(indexOf, 1);
    } else {
      copiedValue.push(method);
    }
    methodologyControl.patchValue(copiedValue);
  }

  openDeleteTestIterationModal(e: Event, iteration: number) {
    e.stopPropagation();
    this.dialog.open(ConfirmationDialogComponent, {
      data: {
        body: `Are you sure you want to DELETE: Test Iteration ${this.testingIterationCount-iteration}`,
        type: AlertType.CONFIRMATION,
        buttonText: 'Confirm Delete',
        onConfirm: () => this.deleteTestIteration1(iteration)
      } as ConfirmationDialogData
    });
  }
  disableDeleteIteration(itration){
  if(this.currentTestIterList.length>1 && ((this.role) === Permission.ADMIN ||
  (this.role) === Permission.EC ||
  (this.role) === Permission.TESTER_AUDITOR)){
    if(this.currentTestIterList.filter(itr=>itr.id!== null).length>=2){
     return false;
    }else{
      if(this.currentTestIterList[itration]?.id){
        return true;
      }else{
        return false;
      }

    }
  }else{
    return true;
  }
  }

  deleteTestIteration(iteration: number) {
    if (this.currentTestIterList.length <= 1) {
    } else {
      if (this.currentTestIterList[iteration].id === undefined) {
        this.store.dispatch(new DeleteTestIterationAction(null, iteration));
      } else {
        this.store.dispatch(new DeleteTestIterationAction(this.currentTestIterList[iteration].id, iteration));
      }
    }
    this.dialog.closeAll();
  }
  deleteTestIteration1(iteration: number) {
    if (this.currentTestIterList.length) {
      const id = this.currentTestIterList[iteration].id;
      const payload = this.currentTestIterList.find(t=> t.id === id);
      payload.projectId= this.projectId;
      this.currentTestIterList = this.currentTestIterList.filter(t=> t.id !== id);
      //this.currentTestIterList = this.currentTestIterList.filter(t=> t.id !== id);
      if (id) {
        //this.store.dispatch(new DeleteTestIterationAction(id, iteration));
        // this.eprService.deleteTestingRecords(payload).subscribe((res: any) => {
        // });
        this.eprService.deleteTestIteration([id]).subscribe((res: any) => {
         this.fetchTestResultsByProjectId(this.projectId);
        });
        } else{
        //this.store.dispatch(new DeleteTestIterationAction(null, iteration));
        this.fetchTestResultsByProjectId(this.projectId);
      }
      // this.store.dispatch(new DeleteTestIterationAction(null, iteration));
      // this.store.dispatch(new DeleteTestIterationAction(this.currentTestIterList[iteration].id, iteration));
    }
    this.dialog.closeAll();
  }



  onGridReady(params) {
    if(this.projectInfoGrid) {
      this.projectInfoGrid.api.setGridOption("columnDefs", this.columnDefs);
      this.projectInfoGrid.api.showLoadingOverlay();
    }
  }

  onRowClicked($event: any) {

    if(!this.readOnly){
      this.openConfirmationModal();
      return;
    }
     
    //this.store.dispatch(new SelectProjectAction($event.rowIndex));
    this.rowSelectedData = this.projectInfoGrid.api.getSelectedRows()[0];
    $event.node.setSelected(true);
    this.projectId =$event.data?.projectId;
    // this.fetchTestIterationsByPrjId(prjId);
    this.displayProjectForTesting =  this.rowdata[$event.rowIndex].projectDisplayName
    this.fetchTestResultsByProjectId(this.projectId);
    // let formattedStartDate =  $event.data.projectStartDate  ? moment($event.data.projectStartDate ).format('YYMMDD'): "";
    // this.displayProjectForTesting= formattedStartDate +" " + ($event.data.prjType? $event.data.prjType : "") + " " + 'Testing';
  }
  openConfirmationModal() {
    this.dialog.open(ProjectConfirmationDialogComponent, {
      data: {
        body: 'Save or discard Test Results changes to continue.',
        additionalInfo: "Warning: This cannot be undone.",
        type: AlertType.CONFIRMATION,
        header:"Testing Changes Unsaved" ,
        width:"30rem",
        onConfirm: () => {return;}
      } as ConfirmationDialogData
    });
  }

  fetchTestIterationsByPrjId(prjId: any){
    this.projectId= prjId;
    this.eprService.getTestingRecordsByProjectId(prjId).subscribe((res: any) => {
      if(res){
        this.currentTestIterList= res;
        if(this.currentTestIterList.length === 0){
          this.currentTestIterList.push(this.newIteration)
        }
        this.currentTestIterIndex= 0;
        this.testingIterationCount= this.currentTestIterList.length;
        this.currentTestIterList.forEach((data,index) =>{
          if(data.iterationFlag === 'Yes'){
            this.currentTestIterIndex= index;
          }
        });
        this.patchTestingForm();
      }
    });
  }

  patchTestingForm() {

    if (this.currentTestIterList.length === 0) {
      return;
    }
    let projectTestFormData = this.currentTestIterList[this.currentTestIterIndex];
    this.currentTestDetail=projectTestFormData;
    this.selectedTestIteration=this.currentTestIterIndex;

    this.testingId=projectTestFormData?.id;
    // projectTestFormData.id = projectTestFormData.id || 'dummy' + this.projectId+''+this.currentTestIterIndex;
    let preloadData = this.payloadTestingEntityList?.find(t => t.id === projectTestFormData.id);
    if (preloadData) {
      projectTestFormData = preloadData;
    }

    const customTestCriteria = this.testingForm.controls['customTestCriteria'] as UntypedFormArray;
    customTestCriteria.clear();

    projectTestFormData?.customTestCriteria?.map(value => {
      customTestCriteria.push(new UntypedFormGroup({
        cstmTC: new UntypedFormControl({value: value.cstmTC, disabled: this.readOnly}),
        cstmTCStatus: new UntypedFormControl({value: value.cstmTCStatus, disabled: this.readOnly}),
        cstmTCSvrty: new UntypedFormControl({value: value.cstmTCSvrty, disabled: this.readOnly}),
        cstmTCNotes: new UntypedFormControl({value: value.cstmTCNotes, disabled: this.readOnly})
      }));
    });
    this.testingForm.patchValue(projectTestFormData, {emitEvent: false});
    // this.testingForm.valueChanges.subscribe(val =>{
    let payloadEntity = this.currentTestIterList[this.currentTestIterIndex];
    // payloadEntity.id = payloadEntity.id || 'dummy' + this.projectId+''+this.currentTestIterIndex;

    let changedEntity = this.payloadTestingEntityList.find(data => data.id === payloadEntity.id);
    if (changedEntity) {
      payloadEntity = changedEntity;
    }
    payloadEntity = {...payloadEntity, projectId: this.projectId};
    if(changedEntity){
        this.payloadTestingEntityList = this.payloadTestingEntityList.filter(rec => rec.id !== changedEntity.id);
      }
    // payloadEntity.id = payloadEntity.id || 'dummy'+  this.projectId+''+this.currentTestIterIndex;
      this.payloadTestingEntityList.push(payloadEntity);
      this.payloadTestingEntityList= this.payloadTestingEntityList.map(t=>{
        if(this.projectId === t.projectId){
          if(t.id === payloadEntity.id){
            t.iterationFlag = 'Yes';
          }else{
            t.iterationFlag= null;
          }
        }

        return t;
      })


  }

  saveTestResults() {

    let testResultsPalyload = {
      eprId : this.eprState.id,
      eprName : "string",
      editorId : this.eprState?.editorId,
       header:{...this.headerState},
      testing: this.payloadTestingEntityList,
      eprHistory: this.eprService.getCommonFiledsHistoryObj(this.eprState.id, this.headerState, new Date(), 'Update')
    };
      this.eprService.saveTestResultsBySeprateApi(testResultsPalyload).subscribe(res=>{
       if(res && res?.status === 'SUCCESS'){
        this.alertService.sendAlert({
          headline: 'Data Saved successfully',
          alertType: CVSAlertType.Success,
          alertDocumentHolderName: '#test-results-alert',
          removedAfterMilliseconds: 4000,
        })
        this.fetchTestResultsByProjectId(this.projectId,this.selectedTestIteration);
        this.getRowData(true);
        this.changeReadMode(true);
       }
      }, error =>{
      });
      this.isEditTestingResults = false;
    }

    fetchTestResultsByProjectId(prjId: any,updateIndex?){
      this.projectId= prjId;
      this.eprService.getTestingResultsByProjectId(prjId).subscribe((res: any) => {
        if(res){
          this.currentTestIterList= res?.testing || [];
          if(this.currentTestIterList.length === 0){
            this.currentTestIterList.push(this.newIteration)
          }

          if(updateIndex){
            this.currentTestIterIndex= updateIndex;
          this.currentTestIterList.forEach((data,index) =>{
            if(data.iterationFlag === 'Yes'){
              this.currentTestIterIndex= index;
            }
          });
          }
          else{
          this.currentTestIterIndex= 0;
          this.currentTestIterList.forEach((data,index) =>{
            if(data.iterationFlag === 'Yes'){
              this.currentTestIterIndex= index;
            }
          });
        }
        this.testingIterationCount= this.currentTestIterList.length;
          this.payloadTestingEntityList = this.currentTestIterList;
          this.patchTestingForm();
        }
      });
    }

    discardChanges(){
      this.fetchTestResultsByProjectId(this.projectId);
      this.changeReadMode(true);
    }
    changeReadMode(readOnly){

      this.readOnly = readOnly;
      this.store.dispatch( new ProjectOrTestingEdit(!this.readOnly));
            if (this.readOnly) {
              this.patchValue();
              this.testingForm.disable({ emitEvent: false });
              this.readOnly = true;
            } else if (!this.readOnly && (this.role === Permission.FILETRACKER || this.role === Permission.VISITOR)) {
              this.testingForm.disable({ emitEvent: false });
              this.readOnly = true;
            } else {
              this.testingForm.enable({ emitEvent: false });
              if (!this.drivers.moveHistoryFile) {
                this.testingForm.controls['tCType4Status'].disable({ emitEvent: false });
                this.testingForm.controls['tCType4Svrty'].disable({ emitEvent: false });
                this.testingForm.controls['tCType4Notes'].disable({ emitEvent: false });
              }
              if (!this.drivers.isType5Used) {
                this.testingForm.controls['tCType5Status'].disable({ emitEvent: false });
                this.testingForm.controls['tCType5Svrty'].disable({ emitEvent: false });
                this.testingForm.controls['tCType5Notes'].disable({ emitEvent: false });
              }
              if (!this.drivers.isCobUsed && !this.drivers.isCobcUsed && !this.drivers.isStCobUsed) {
                this.testingForm.controls['tCCOBStatus'].disable({ emitEvent: false });
                this.testingForm.controls['tCCOBSvrty'].disable({ emitEvent: false });
                this.testingForm.controls['tCCOBNotes'].disable({ emitEvent: false });
              }
              if (!this.drivers.usesHcExchange) {
                this.testingForm.controls['tCExchangeStatus'].disable({ emitEvent: false });
                this.testingForm.controls['tCExchangeSvrty'].disable({ emitEvent: false });
                this.testingForm.controls['tCExchangeNotes'].disable({ emitEvent: false });
              }
              if (!this.drivers.usesIntegAccums) {
                this.testingForm.controls['tCIntegAccumsStatus'].disable({ emitEvent: false });
                this.testingForm.controls['tCIntegAccumsSvrty'].disable({ emitEvent: false });
                this.testingForm.controls['tCIntegAccumsNotes'].disable({ emitEvent: false });
              }
              if (!this.drivers.isQMedBUsed && !this.drivers.isSubsidyUsed) {
                this.testingForm.controls['tCMedBRDSStatus'].disable({ emitEvent: false });
                this.testingForm.controls['tCMedBRDSSvrty'].disable({ emitEvent: false });
                this.testingForm.controls['tCMedBRDSNotes'].disable({ emitEvent: false });
              }
              if (!this.drivers.isMedDUsed) {
                this.testingForm.controls['tCMedDStatus'].disable({ emitEvent: false });
                this.testingForm.controls['tCMedDSvrty'].disable({ emitEvent: false });
                this.testingForm.controls['tCMedDNotes'].disable({ emitEvent: false });
              }
              if (!this.drivers.isMbrLvlPlansUsed) {
                this.testingForm.controls['tCMbrLvlPlanStatus'].disable({ emitEvent: false });
                this.testingForm.controls['tCMbrLvlPlanSvrty'].disable({ emitEvent: false });
                this.testingForm.controls['tCMbrLvlPlanNotes'].disable({ emitEvent: false });
              }
            }



    }

    patchValue() {
      this.testingForm.patchValue(this.currentTestDetail, { emitEvent: false });
    }

}
