import {AfterViewInit, Component, HostListener, OnInit, ViewChild} from '@angular/core';
import {questionnaireFormConfig} from '../../../epr/questionnaire/QuestionnaireConfig';
import {InputType} from '../../../models/FormFieldConfig';
import {AgGridAngular} from 'ag-grid-angular';
import {QuestionnaireSettingsService} from '../../../services/questionnaire-settings.service';
import {CVSAlertService, CVSAlertType} from 'angular-component-library';
import { ISelectCellEditorParams } from 'ag-grid-community';
import {DropdownRendererComponent} from "./dropdown-renderer/dropdown-renderer.component";
import { GridOptions } from '@ag-grid-community/core';
const consolidateProfileLogicValues = ['No', 'Yes'];
const statusValues = ['Active', 'InActive'];
const usageValues=['Internal','External']
const sectionValues = ['Client Basics', 'ID Configuration', 'File Params', 'Vendor',
'Terms', 'Threshold', 'Printed Materials', 'Misc Info', 'Open Enrollment' ]
const dropdownValues = [{label:'Yes',value:'yes',default:false},{label:'No',value:'no',default:true}];

@Component({
  selector: 'app-questionnaire-metadata',
  templateUrl: './questionnaire-settings.component.html',
  styleUrls: ['./questionnaire-settings.component.scss']
})
export class QuestionnaireSettingsComponent implements OnInit, AfterViewInit {
  previousData: any=[];
  contextObj: any;
  isRowDragged: boolean;
  dataRowsFileParams: any[];
  constructor(private questionnaireSettingsService: QuestionnaireSettingsService,
              private alertService: CVSAlertService) {

    this.contextObj = { componentParent: this };
  }
  ngOnInit(): void {
    this.columnDefs = this.generateColumnDefs();   
  }
  onRowDragEnd(event) {
    this.isRowDragged = true;
    this.questionnaireSettingsService.isQuestionnaireSettingSaveDisabled.next(true);
  }
  sortChange(event, index){
    const sortOrder = event.api.getSortModel();
    this.grids[index].api.applyColumnState({
      state: sortOrder,
    });
  }
  onFilterChange(event) {

  }

  @ViewChild('headerInfoGrid') headerInfoGrid;
  @ViewChild('clientBasicsGrid') clientBasicsGrid;
  @ViewChild('idConfigGrid') idConfigGrid;
  @ViewChild('fileParamsGrid') fileParamsGrid;
  @ViewChild('vendorGrid') vendorGrid;
  @ViewChild('termsGrid') termsGrid;
  @ViewChild('thresholdGrid') thresholdGrid;
  @ViewChild('printedMatsGrid') printedMatsGrid;
  @ViewChild('miscInfoGrid') miscInfoGrid;
  @ViewChild('openEnrollGrid') openEnrollGrid;

  grids: AgGridAngular[] = [];
  gridNames: any[] = ['Header Information', 'Client Basics', 'ID Configuration', 'File Params', 'Vendor',
    'Terms', 'Threshold', 'Printed Materials', 'Misc Info', 'Open Enrollment'];
  rowData;
  columnDefs: any[];
  panelOpenState = false;
  isQuestionnaireSettingSaveDisabled: boolean;
  gridOptions: GridOptions;



  private id = [0, 1, 2, 3, 4, 5, 6];

  private generateColumnDefs() {
    const hyperlinkColumn = {
      headerName: 'Hyperlink',
      field: 'hyperlink',
      editable: true,
      tooltipField: 'hyperlink',
      resizable: true,
      width: 350,
    cellRenderer: params =>
        `<a href="${params.value}" target="_blank">${params.value ? params.value : ''}</a>`
    };
    const integratedFeeds = {
      headerName: 'Integrated Feeds',
      editable: true,
      showButtonMode: 'ShowAlways',
      width: 300,
      resizable: true,
      field: 'integratedFeeds',
      rowDataChanged: this.dataChanged,
      cellRenderer: function(params) {
        return '<span style="display:flex;align-items:center;justify-content: space-between;">' + params.value + '<mat-icon class="material-icons">arrow_drop_down</mat-icon></span>'
      },

      cellEditor: 'agSelectCellEditor',
      cellEditorParams: {
        values: consolidateProfileLogicValues,
      } as ISelectCellEditorParams,
    }
    const sendToSSOE = {
      headerName: 'Send to SSOE',
      editable: true,
      width: 300,
      resizable: true,
      field: 'sendToSsoe',
      rowDataChanged: this.dataChanged,
      cellRenderer: function(params) {
        return '<span style="display:flex;align-items:center;justify-content: space-between;">' + params.value + '<mat-icon class="material-icons">arrow_drop_down</mat-icon></span>'
      },
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: {
        values: consolidateProfileLogicValues,
      } as ISelectCellEditorParams,
    }
    const status = {
      headerName: 'Status',
      editable: true,
      field: 'status',
      width: 300,
      resizable: true,
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: {
        values: statusValues,
      } as ISelectCellEditorParams,
    }
    const usage = {
      headerName: 'Usage',
      editable: true,
      field: 'usage',
      resizable: true,
      width: 300,
      rowDataChanged: this.dataChanged,
      cellRenderer: function(params) {
        return '<span style="display:flex;align-items:center;justify-content: space-between;">' + params.value + '<mat-icon class="material-icons">arrow_drop_down</mat-icon></span>'
      },
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: {
        values: usageValues,
      } as ISelectCellEditorParams,
    }
    const msection = {
      headerName: 'Section',
      editable: true,
      field: 'msection',
      resizable: true,
      width: 500,
      tooltipField: 'Section',
      rowDataChanged: this.dataChanged,
      cellRenderer: function(params) {
        return '<span style="display:flex;align-items:center;justify-content: space-between;">' + params.value + '<mat-icon class="material-icons">arrow_drop_down</mat-icon></span>'
      },
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: {
        values: sectionValues,
      } as ISelectCellEditorParams,
    }
    const carrierLevel = {
      headerName: 'Carrier Level',
      editable: true,
      field: 'carrierLevel',
      width:300,
      resizable: true,
      cellRenderer: function(params) {
        return '<span style="display:flex;align-items:center;justify-content: space-between;">' + params.value + '<mat-icon class="material-icons">arrow_drop_down</mat-icon></span>'
      },
      rowDataChanged: this.dataChanged,
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: {
        values: consolidateProfileLogicValues,
      } as ISelectCellEditorParams,
    }
    const description = {
      headerName: 'Help Text / Description',
      field: 'description',
      editable: true,
      resizable: true,
      tooltipField: 'description',
      rowDataChanged: this.dataChanged,
      width: 400,
      cellRenderer: params =>
        `<span>${params.value ? params.value : ''}</span>`
      // `<a href="${params.value}" target="_blank">${params.value ? params.value : ''}</a>`
    };
    const techincalName = {
      headerName: 'Technical Name',
      field: 'technicalName',
      editable: false,
      resizable: true,
      width: 450,
      tooltipField: 'technicalName',
      cellRenderer: params =>
        `<span>${params.value ? params.value : ''}</span>`
      // `<a href="${params.value}" target="_blank">${params.value ? params.value : ''}</a>`
    };
    const hyperLink = {
      headerName: 'HyperLink',
      field: 'hyperlink',
      rowDataChanged: this.dataChanged,
      editable: true,
      resizable: true,
      width: 350,
      tooltipField: 'hyperlink',
      cellRenderer: params =>
        `<span>${params.value ? params.value : ''}</span>`
      // `<a href="${params.value}" target="_blank">${params.value ? params.value : ''}</a>`
    };


    const columnDefs = [];
    questionnaireFormConfig.map(section => {
      section.map(field => {
        if (field.inputType === InputType.HEADER) {
          if (field.label === 'Header Information') {
            columnDefs.push([
              {
                headerName: 'Field Label', field: 'fieldLabel', tooltipField: 'label', minWidth: 300,
                resizable: true
              },
              description,
              integratedFeeds,
              sendToSSOE,
              hyperLink,
              techincalName,
              status
            ]);
          } else {
            columnDefs.push([
              { headerName: '', field: 'dragColumn', tooltipField: '', editable: false,
              cellRenderer: (params) => {
                return `<mat-icon class="mat-icon md-16 material-icons small" style="width:20px;color:#262626 !important;padding-top:8px;display:flex;justify-content:center;role="img" aria-hidden="true">
                drag_indicator</mat-icon>`;
              },
                resizable: false,
              unselectable: 'on',
              role: 'presentation'
              },
              {
                headerName: 'Field Label', field: 'fieldLabel', tooltipField: 'label', minWidth: 300, editable: true,
                resizable: true
              },
              description,
              usage,
              msection,
              integratedFeeds,
              sendToSSOE,
              carrierLevel,
              hyperLink,
              techincalName,
              status
            ]);
          }

        }
      });
    });

    return columnDefs;
  }
  dataChanged(event: any) {
    let newval = event.newValue == undefined ? null : event.newValue;
    if (event.oldValue !== newval) {
      this.isQuestionnaireSettingSaveDisabled = true;
      this.questionnaireSettingsService.isQuestionnaireSettingSaveDisabled.next(true);
    }
    if (event && event.node && event.node.data) {
      event.node.data.headerLabel = event?.node?.data?.msection;
      if (event && event.node && event.node.data) {
        event.node.data.section = event?.node?.data?.msection;
      }
      event.node.data.changed = true;

    }
  }
  rowColor(row) {
    if (row.data.status === 'InActive') {
      return {'border-left': '1px solid #dde2eb', 'background-color': 'lightgray'};
    } else if (row.data.status === 'Active') {
      return {'border-left': '1px solid #dde2eb', 'background-color': 'white'}
    }
  }
 
  isValueDuplicate(params, colToCheck): boolean {
    if (params.value === null || params.value === undefined) {
      return false;
    }
    const rowIndex = params.node.rowIndex;
    let gridId;
    if (params.context && params.context.gridId >= 0) {
      gridId = params.context.gridId;
    }

    let numberOfTimes = 0;
    const rowData = JSON.parse(localStorage.getItem('rowData'));

    if (Array.isArray(rowData)) {
      const allRows = [];
      for (let i = 0; i < rowData.length; i++) {
        const innerArray = rowData[i];
        if (Array.isArray(innerArray)) {
          for (let j = 0; j < innerArray.length; j++) {
            if (j === rowIndex && gridId === i) {
              innerArray[j][colToCheck] = params.value;
            }
            allRows.push(innerArray[j]);
          }
          rowData[i] = innerArray;
        }
      }
      localStorage.setItem('rowData', JSON.stringify(rowData));
      const totalDuplicate = allRows.filter(data => data[colToCheck] === params.value);
      numberOfTimes = totalDuplicate.length;
    }

    return (numberOfTimes >= 2);
  }
  onGridReady(params) {
    params.api.sizeColumnsToFit();
    this.grids[0].api.setGridOption("columnDefs", this.columnDefs[0]);
  }
  onQuestionsGridReady(params, gridIndex) {
    this.grids[gridIndex] = params;  
    if(this.grids && this.grids.length > 0) {
      this.grids[gridIndex].api.setGridOption("columnDefs", this.columnDefs[gridIndex]);
      this.grids[gridIndex].api.sizeColumnsToFit();
      this.grids[gridIndex].api.redrawRows();
      this.grids[gridIndex].suppressMoveWhenRowDragging   = false;
      this.grids[gridIndex].gridOptions = {
        editType: "fullRow",
        onCellValueChanged(data) { this.dataChanged(data); }
      };
      this.grids[gridIndex].api.forEachNode((node) => {
        Object.keys(node.data).forEach((key) => {
          this.grids[gridIndex].api.startEditingCell({
            rowIndex: node.rowIndex,
            colKey: key
          });
          //grid.gridOptions.api.setFocusedCell(node.rowIndex, key);
        });
      }
    );
     // this.grids[gridIndex].api.startEditingCell({rowIndex:0, colKey: 'fieldLabel', keyPress: null, charPress: null})
    }    
  }
  ngAfterViewInit(): void {
    this.grids.push(this.headerInfoGrid, this.clientBasicsGrid, this.idConfigGrid, this.fileParamsGrid,
      this.vendorGrid, this.termsGrid, this.thresholdGrid, this.printedMatsGrid,
      this.miscInfoGrid, this.openEnrollGrid);

    this.questionnaireSettingsService.getQuestionnaireSettingsRowData().subscribe(res => {
      if (res) {
          this.setGridData(res);
      }
    });
  }
  
 
  setGridData(res) {
    for (let i = 0; i < this.grids.length; i++) {
      let rowData = res.filter(t => t.section === this.gridNames[i]).map(t => {
        if(i!=0){
          t.msection = t.section;
        }
        return t;
      });
      rowData = rowData.sort((a, b) => a.sortOrder - b.sortOrder);
      const columnDefs = this.generateColumnDefs();
         
      this.grids[i].api.setGridOption("rowData",rowData);
    }
    setTimeout(() =>{
      this.questionnaireSettingsService.isQuestionnaireSettingSaveDisabled.next(false);
    },2000)
  }


  private validateFormData(sectionData: any, j: number, isFormValid: boolean, FormFieldValues: any) {
    // Duplicate check for preload column
    if (FormFieldValues.preload.includes(sectionData[j].preload) && FormFieldValues.preLoadDataValid !== false) {
      FormFieldValues.preLoadDataValid = false;
    } else if (sectionData[j].preload !== '' && sectionData[j].preload != null) {
      FormFieldValues.preload.push(sectionData[j].preload);
    }
    // Duplicate check for SSOE column
    if (FormFieldValues.consolidateProfileLogic.includes(sectionData[j].consolidateProfileLogic) && FormFieldValues.consolidateProfileLogicValid !== false) {
      FormFieldValues.consolidateProfileLogicValid = false;
    } else if (sectionData[j].consolidateProfileLogic !== '' && sectionData[j].consolidateProfileLogic != null) {
      FormFieldValues.consolidateProfileLogic.push(sectionData[j].consolidateProfileLogic);
    }
    // Duplicate check for stage column
    if (FormFieldValues.stage.includes(sectionData[j].stage) && FormFieldValues.stageDatavalid !== false) {
      FormFieldValues.stageDatavalid = false;
    } else if (sectionData[j].stage !== '' && sectionData[j].stage != null) {
      FormFieldValues.stage.push(sectionData[j].stage);
    }

    // Duplicate check for adtlPrms column
    if (FormFieldValues.adtlPrms.includes(sectionData[j].adtlPrms) && FormFieldValues.adtlPrmsDataValid !== false) {
      FormFieldValues.adtlPrmsDataValid = false;
    } else if (sectionData[j].adtlPrms !== '' && sectionData[j].adtlPrms != null) {
      FormFieldValues.adtlPrms.push(sectionData[j].adtlPrms);
    }

    // Duplicate check for intRcd column
    if (FormFieldValues.intRcd.includes(sectionData[j].intRcd) && FormFieldValues.intRcdDataValid !== false) {
      FormFieldValues.intRcdDataValid = false;
    } else if (sectionData[j].intRcd !== '' && sectionData[j].intRcd != null) {
      FormFieldValues.intRcd.push(sectionData[j].intRcd);
    }

    // Duplicate check for crosswalk column
    if (FormFieldValues.crosswalk.includes(sectionData[j].crosswalk) && FormFieldValues.crosswalkDataValid !== false) {
      FormFieldValues.crosswalkDataValid = false;
      // eslint-disable-next-line eqeqeq
    } else if (sectionData[j].crosswalk != '' && sectionData[j].crosswalk != null) {
      FormFieldValues.crosswalk.push(sectionData[j].crosswalk);
    }

    // Duplicate check for mbrrqflds column
    if (FormFieldValues.mbrrqflds.includes(sectionData[j].mbrrqflds) && FormFieldValues.mbrrqfldsDataValid !== false) {
      FormFieldValues.mbrrqfldsDataValid = false;
    } else if (sectionData[j].mbrrqflds !== '' && sectionData[j].mbrrqflds != null) {
      FormFieldValues.mbrrqflds.push(sectionData[j].mbrrqflds);
    }

    // To check for the preload Column if no duplicate found and the value i.e 2-99
    if (FormFieldValues.preLoadDataValid && sectionData[j].preload !== '' && sectionData[j].preload != null && sectionData[j].preload !== ''
      && (sectionData[j].preload < 2 || sectionData[j].preload > 99)) {
      isFormValid = false;
    }
    if (FormFieldValues.stageDataValid && sectionData[j].stage !== '' && sectionData[j].stage != null && sectionData[j].stage !== ''
      && (sectionData[j].stage < 2 || sectionData[j].stage > 99)) {
      isFormValid = false;
    }
    if (FormFieldValues.consolidateProfileLogicValid && sectionData[j].consolidateProfileLogic !== '' && sectionData[j].consolidateProfileLogic != null && sectionData[j].consolidateProfileLogic !== ''
      && (sectionData[j].consolidateProfileLogic < 2 || sectionData[j].consolidateProfileLogic > 99)) {
      isFormValid = false;
    }
    if (FormFieldValues.intRcdDataValid && sectionData[j].intRcd !== '' && sectionData[j].intRcd != null && sectionData[j].intRcd !== ''
      && (sectionData[j].intRcd < 2 || sectionData[j].intRcd > 99)) {
      isFormValid = false;
    }

    if (FormFieldValues.adtlPrmsDataValid && sectionData[j].adtlPrms !== ''
      && sectionData[j].adtlPrms != null && sectionData[j].adtlPrms !== ''
      && (sectionData[j].adtlPrms < 2 || sectionData[j].adtlPrms > 99)) {
      isFormValid = false;
    }

    if (FormFieldValues.crosswalkDataValid && sectionData[j].crosswalk !== ''
      && sectionData[j].crosswalk != null && sectionData[j].crosswalk !== ''
      && (sectionData[j].crosswalk < 2 || sectionData[j].crosswalk > 99)) {
      isFormValid = false;
    }

    if (FormFieldValues.mbrrqfldsDataValid && sectionData[j].mbrrqflds !== ''
      && sectionData[j].mbrrqflds != null && sectionData[j].mbrrqflds !== ''
      && (sectionData[j].mbrrqflds < 2 || sectionData[j].mbrrqflds > 99)) {
      isFormValid = false;
    }
    return isFormValid
      && FormFieldValues.preLoadDataValid && FormFieldValues.consolidateProfileLogic && FormFieldValues.stageDatavalid
      && FormFieldValues.intRcdDataValid && FormFieldValues.adtlPrmsDataValid
      && FormFieldValues.crosswalkDataValid && FormFieldValues.mbrrqfldsDataValid;
  }

  /*Changes part of US101017 - March 2021 Release
    validating the formdata based on conditions like  duplicate check and value i.e 2-99 */
  isQuestionaireSettingFormValid() {

    let isFormValid = true;
    const FormFieldValues = {
      preload: [],
      preLoadDataValid: true,
      consolidateProfileLogic: [],
      consolidateProfileLogicValid: true,
      stage: [],
      stageDatavalid: true,
      intRcd: [],
      intRcdDataValid: true,
      adtlPrms: [],
      adtlPrmsDataValid: true,
      crosswalk: [],
      crosswalkDataValid: true,
      mbrrqflds: [],
      mbrrqfldsDataValid: true,
    };
    if (Array.isArray(this.rowData) && this.rowData.length > 0) {
      // eslint-disable-next-line @typescript-eslint/prefer-for-of
      for (let i = 0; i < this.rowData.length; i++) {
        const sectionData = this.rowData[i];
        if (sectionData.length > 0) {
          for (let j = 0; (j < sectionData.length && isFormValid); j++) {
            isFormValid = this.validateFormData(sectionData, j, isFormValid, FormFieldValues);
          }
        }
      }
    }

    if (isFormValid === false) {
      this.alertService.sendAlert({
        headline: 'Value Must be Blank or 2-99, And NOT Repeated within a Record Type',
        alertType: CVSAlertType.Error,
        removedAfterMilliseconds: 5,
        alertDocumentHolderName: '#settings-alert',
      });
    }
    return isFormValid;
  }

  togglePanels() {
    this.panelOpenState = !this.panelOpenState;
  }


  save() {

    const payload = {
      "id": null,
      "sendToSsoe": null,
      "fieldLabel": null,
      "section": null,
      "description": null,
      "usage": null,
      "msection": null,
      "integratedFeeds": null,
      "carrierLevel": null,
      "hyperlink": null,
      "technicalName": null,
      "status": null,
      "sortOrder": 0,
    }
    const rowData = [];
    let clientBasicCount = 0;
    let idConfigCount = 0;
    let fileParamsCount = 0;
    let vendorCount = 0;
    let termsCount = 0;
    let thresholdCount = 0;
    let printedMaterialCount = 0;
    let miscInfoCount = 0;
    let openEnrollmentCount = 0;
    let sortCount = 0;

    for (let i = 0; i < this.grids.length; i++) {
      this.grids[i]?.api.forEachNode(res => {
        switch (res.data.section) {
          case 'Client Basics':
            sortCount = clientBasicCount++;
            break;
          case 'ID Configuration':
            sortCount = idConfigCount++;
            break;
          case 'File Params':
            sortCount = fileParamsCount++;
            break;
          case 'Vendor':
            sortCount = vendorCount++;
            break;
          case 'Terms':
            sortCount = termsCount++;
            break;
          case 'Threshold':
            sortCount = thresholdCount++;
            break;
          case 'Printed Materials':
            sortCount = printedMaterialCount++;
            break;
          case 'Misc Info':
            sortCount = miscInfoCount++;
            break;
          case 'Open Enrollment':
            sortCount = openEnrollmentCount++;
            break;
          default:
            break;
        }

          rowData.push({
            ...payload, ...res.data,
            section: res.data.section?res.data.section:this.gridNames[i],
            description: res.data.description,
            sortOrder: sortCount,
            integratedFeeds:res.data.integratedFeeds
          });
      });
    }
    const finalData = rowData.filter(d => d.changed === true);
    this.questionnaireSettingsService.saveQuestionnaireHyperlinks(rowData).subscribe((res) => {
      this.setGridData(res);
      this.alertService.sendAlert({
        headline: 'Save successful!',
        alertType: CVSAlertType.Success,
        removedAfterMilliseconds: 5000,
        alertDocumentHolderName: '#settings-alert',
      });
    });
    }
  }



