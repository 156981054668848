<cvs-alert-placeholder id="map-alert">
</cvs-alert-placeholder>
<div class="outer-grid">
    <h2>Reporting Message Types</h2>
<ag-grid-angular class="ag-theme-alpine" [rowDragManaged]="true"
    [rowDragEntireRow]="true"
    [rowDragMultiRow]="true" [suppressMenuHide]="true"
    [rowSelection]="rowSelection" (filterChanged)="onFilterChange( $event)"
    (rowDragEnd)="onRowDragEnd($event)" domLayout='autoHeight' (sortChanged)="sortChange($event)" id="reportingMessageTypesGrid" (keyup)="emitSave()"  (cellValueChanged)="UpdatedRow($event)"
    [context]="contextObj" [stopEditingWhenCellsLoseFocus]="true" [stopEditingWhenGridLosesFocus]="true"
    (gridReady)="onGridReady($event)" [rowData]="dataRows">
</ag-grid-angular>
</div>
