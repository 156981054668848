<cvs-loader-overlay-container [isLoading]="showSpinner$" loadingMessage="Loading">
<div class="ea-wrapper">
  <div id="ea-form" class="ea-form">
    <button class="pds-btn-close" *ngIf="closeIcon" style="float: right; padding-bottom: 30px;" mat-icon-button aria-label="close dialog" mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
    <div class="logo-and-btn-div">
      <img src="assets/cvs-health-logo-old.png" class="logo-icon"/>
      <div class="cvs-email-wrapper">
        <a *ngIf="showEmailBtn" class="pds-btn-email" [href]="emailBody">Send Email</a>
        <button *ngIf="readOnly; else noExport" id="downloadPDF" class="pds-btn-text-only" (click)="download(modal)">
          <mat-icon svgIcon="file-pdf--s"></mat-icon>
          Export
        </button>
      </div>
      <ng-template #noExport><span id="saveToExport" class="download-btn">Save changes to export</span></ng-template>
    </div>
    <div [formGroup]="eligAgreementForm"
         [appConnectFormValues]="FormType.ELIGIBILITY_AGREEMENT">
      <div class="heading" style="margin-top: 5px">
        <h2>Client Info</h2>
      </div>

      <div style="display: flex;">
        <div class="client-info-field">
          <span><b>Client Name</b></span>
          <span id="client-name">{{clientName$ | async}}</span>
        </div>

        <div class="client-info-field">
          <span><b>PLI</b></span>
          <span id="pli">{{pli$ | async}}</span>
        </div>

        <div class="client-info-field">
          <span><b>Eligibility Consultant</b></span>
          <span id="ec">{{eligibilityConsultant$ | async}}</span>
        </div>

        <div class="client-info-field">
          <span><b>Sign Off Status</b></span>
          <span id="sign-off-status">{{signOffStatus$ | async}}</span>
        </div>

        <div class="client-info-field">
          <span><b>Line of Business</b></span>
          <span id="line-of-business">{{lineOfBusinessValues$ | async}}</span>
        </div>

        <div class="client-info-field">
          <span><b>Population</b></span>
          <span id="population">{{populationValues$ | async}}</span>
        </div>
      </div>

      <div style="display: flex; margin-top: 14px;">
        <div class="client-info-field">
          <span><b>Carrier(s)</b></span>
          <span id="carriers">{{carriers$ | async}}</span>
        </div>
      </div>

      <h2>Change Log</h2>
      <div id="changelog">
        <table>
          <colgroup>
            <col span="1" style="width: 10%">
            <col span="1" style="width: 90%">
          </colgroup>
          <colgroup>
          <col span="1" style="width: 10%">
          <col span="1" style="width: 90%">
        </colgroup>
        <tr>
            <th>Date</th>
            <th>Action Taken</th>
          </tr>
          <ng-container formArrayName="changeLog" *ngIf="changeLogFormArray.length > 0">
            <tr *ngFor="let _ of changeLogFormArray.controls; let i = index;" [formGroupName]="i">
              <td>
                <mat-form-field class="table-date-input" appearance="none">
                  <input matInput [matDatepicker]="changeLogDatepicker" formControlName="dateCreated">
                  <mat-datepicker-toggle matSuffix [for]="changeLogDatepicker"></mat-datepicker-toggle>
                  <mat-datepicker #changeLogDatepicker></mat-datepicker>
                  <mat-error *ngIf="this.changeLogFormArray.get(i.toString()).invalid">Invalid Date</mat-error>
                </mat-form-field>
              </td>
              <td>
                <mat-form-field *ngIf="!readOnly; else readOnlyChange" appearance="none">
                  <textarea
                    cdkTextareaAutosize
                    cdkAutosizeMinRows="1"
                    formControlName="change"
                    matInput
                    maxLength="255"
                  ></textarea>
                </mat-form-field>
                <ng-template #readOnlyChange><p>{{ this.changeLogFormArray.controls[i].get('change').value }}</p></ng-template>
              </td>
              <button *ngIf="!readOnly" class="cvs-icon-only-btn" (click)="deleteChangeLogEntry(i)">
                <mat-icon svgIcon="trash--s"></mat-icon>
              </button>
            </tr>
          </ng-container>
        </table>
        <button *ngIf="!readOnly" class="pds-btn-text-only" id="add-btn"
                (click)="addChange()"
                [disabled]="isAddChange || this.changeLogFormArray.invalid">
          <mat-icon svgIcon="plus--s"></mat-icon>
          Add Change
        </button>
      </div>
      <h2>File Parameters</h2>
      <div id="file-parameters-ea">
        <table>
          <tr>
            <th>File</th>
            <th>Naming Conventions</th>
            <th>Notes</th>
          </tr>

          <tr>
            <td>Sent By</td>
            <td *ngIf="(vendor$ | async) === 'Yes'">Vendor</td>
            <td *ngIf="(vendor$ | async) !== 'Yes'">Client</td>
            <td *ngIf="(vendor$ | async) === 'Yes'">{{vendorLookup$ | async}}</td>
            <td *ngIf="(vendor$ | async) !== 'Yes'"></td>
          </tr>

          <tr>
            <td>FTP ID</td>
            <td>{{this.ftpId$ | async}}</td>
            <td *ngIf="(fileTransmitionMethod$ | async) === 'Secure FTP'">
              IMPORTANT: Eligibility files received by means other than agreed upon SFTP are unbound to defined
              SLA's/Performance Guarantees.
            </td>
            <td *ngIf="(fileTransmitionMethod$ | async) !== 'Secure FTP'"></td>
          </tr>

          <tr>
            <td>Member Files</td>
            <td id="fileNamingProd">
              <mat-form-field *ngIf="!readOnly; else readOnlyFileNamingProd" appearance="none">
                <textarea matInput
                          cdkTextareaAutosize
                          cdkAutosizeMinRows="1"
                          type="text"
                          [value]="fileNamingProd$ | async"
                          formControlName="fileNamingProd">
                </textarea>
              </mat-form-field>
              <ng-template #readOnlyFileNamingProd><p>{{ fileNamingProd$ | async }}</p></ng-template>
            </td>
            <td>
              Member Eligibility File will be submitted by the client/vendor in the {{format$ | async}} format
              via {{fileTransmitionMethod$ | async}}.
              <br/>{{fileFrequencyNotes$ | async}}
            </td>
          </tr>

          <tr>
            <td>Group Files</td>
            <td id="grpFileNamingProd">
              <mat-form-field *ngIf="!readOnly; else readOnlyGrpFileNamingProd" appearance="none">
                <textarea matInput
                          cdkTextareaAutosize
                          cdkAutosizeMinRows="1"
                          type="text"
                          [value]="grpFileNamingProd$ | async"
                          formControlName="grpFileNamingProd">
                </textarea>
              </mat-form-field>
              <ng-template #readOnlyGrpFileNamingProd><p>{{ grpFileNamingProd$ | async }}</p></ng-template>
            </td>
            <td *ngIf="(groupFilesSent$ | async) === 'Yes'">
              Group file must precede all member files even if no actual Groups are present on the file.
            </td>
            <td *ngIf="(groupFilesSent$ | async) !== 'Yes'">
              Not Applicable
            </td>
          </tr>

          <tr>
            <td>Test Files</td>
            <td id="fileNamingTest">
              <mat-form-field *ngIf="!readOnly; else readOnlyFileNamingTest" appearance="none">
                <textarea matInput
                          cdkTextareaAutosize
                          cdkAutosizeMinRows="1"
                          type="text"
                          [value]="fileNamingTest$ | async"
                          formControlName="fileNamingTest">
                </textarea>
              </mat-form-field>
              <ng-template #readOnlyFileNamingTest><p>{{ fileNamingTest$ | async }}</p></ng-template>
            </td>
            <td>
              Contact your Eligibility Contact prior to submitting test file.
            </td>
          </tr>

          <tr>
            <td>Compare Files</td>
            <td id="fileNamingCompare">
              <mat-form-field *ngIf="!readOnly; else readOnlyGrpFileNamingCompare" appearance="none">
                <textarea matInput
                          cdkTextareaAutosize
                          cdkAutosizeMinRows="1"
                          type="text"
                          [value]="fileNamingCompare$ | async"
                          formControlName="fileNamingCompare">
                </textarea>
              </mat-form-field>
              <ng-template #readOnlyGrpFileNamingCompare><p>{{ fileNamingCompare$ | async }}</p></ng-template>
            </td>
            <td>
              {{compareProcess$ | async}} {{compareFrequency$ | async}}
            </td>
          </tr>

          <tr>
            <td>Additional Files</td>
            <td></td>
            <td>
              <span *ngIf="(qphysFiles$ | async) === 'Yes'">Prescriber/Physician; </span>
              <span *ngIf="(qdiagFiles$ | async) === 'Yes'">Diagnosis; </span>
              <span *ngIf="(qlabFiles$ | async) === 'Yes'">Lab; </span>
            </td>
          </tr>
        </table>
      </div>

      <h2>Member Identification</h2>
      <div id="member-identification-ea">
        <table>
          <tr>
            <th class="no-wrap">Type</th>
            <th>Description</th>
          </tr>

          <tr>
            <td class="no-wrap">Member ID</td>
            <td >
              {{this.mbrIDTypeWPC$ | async}}
            </td>
          </tr>
          <tr>
            <td class="text-style">Min Length</td>
            <td >
              {{this.memIDLength$ | async}}
            </td>
          </tr>
          <tr>
            <td class="text-style">Max Length</td>
            <td>
              {{this.memIDMaxLength$ | async}}
            </td>
          </tr>

          <tr>
            <td class="no-wrap">Alternate ID (Xwalk)</td>
            <td id="cEPRFAltIdCode">
              <mat-form-field *ngIf="!readOnly; else readOnlyCeprFAltIdCode" appearance="none">
                <textarea matInput
                          cdkTextareaAutosize
                          cdkAutosizeMinRows="1"
                          type="text"
                          [value]="cEPRFAltIdCode$ | async"
                          formControlName="cEPRFAltIdCode">
                </textarea>
              </mat-form-field>
              <ng-template #readOnlyCeprFAltIdCode><p>{{ cEPRFAltIdCode$ | async }}</p></ng-template>
            </td>
          </tr>
          <tr>
            <td class="text-style">Min Length</td>
            <td>
              {{this.xwalkIDLength$ | async}}
            </td>
          </tr>
          <tr>
            <td class="text-style">Max Length</td>
            <td>
              {{this.xwalkIDMaxLength$ | async}}
            </td>
          </tr>

          <tr>
            <td>Family ID</td>
            <td id="cEPRFamID">
              <mat-form-field *ngIf="!readOnly; else readOnlyCeprFamId" appearance="none">
                <textarea matInput
                          cdkTextareaAutosize
                          cdkAutosizeMinRows="1"
                          type="text"
                          [value]="cEPRFamID$ | async"
                          formControlName="cEPRFamID">
                </textarea>
              </mat-form-field>
              <ng-template #readOnlyCeprFamId><p>{{ cEPRFamID$ | async }}</p></ng-template>
            </td>
          </tr>

          <tr>
            <td>Person Code</td>
            <td id="cEPRFPersonCode">
              <mat-form-field *ngIf="!readOnly; else readOnlyCeprFPersonCode" appearance="none">
                <textarea matInput
                          cdkTextareaAutosize
                          cdkAutosizeMinRows="1"
                          type="text"
                          [value]="cEPRFPersonCode$ | async"
                          formControlName="cEPRFPersonCode">
                </textarea>
              </mat-form-field>
              <ng-template #readOnlyCeprFPersonCode><p>{{ cEPRFPersonCode$ | async }}</p></ng-template>
            </td>
          </tr>

          <tr>
            <td class="text-style">Length</td>
            <td>
              {{this.pcLength$ | async}}
            </td>
          </tr>

          <tr>
            <td class="text-style">Method</td>
            <td>
              {{this.pcAssignmentMethod$ | async}}
            </td>
          </tr>

          <tr>
            <td>Additional ID</td>
            <td id="cEPR3rdID">
              <mat-form-field *ngIf="!readOnly; else readOnlyCepr3rdId" appearance="none">
                <textarea matInput
                          cdkTextareaAutosize
                          cdkAutosizeMinRows="1"
                          type="text"
                          [value]="cEPR3rdID$ | async"
                          formControlName="cEPR3rdID">
                </textarea>
              </mat-form-field>
              <ng-template #readOnlyCepr3rdId><p>{{ cEPR3rdID$ | async }}</p></ng-template>
            </td>
          </tr>

          <tr>
            <td>ID on Cards</td>
            <td>
              {{this.cardID$ | async}}
            </td>
          </tr>

        </table>
      </div>

      <h2>Member Terms & Movements</h2>
      <div id="member-terms-and-movement-ea">
        <div class="terms-movements-field">
          <b>Term Dates</b>
          <p *ngIf="(this.drivers$ | async)?.termDateThruUsed">Any termination date sent by client/vendor will be converted from a 'to' date into a 'through' date in '
             CVS Health's RxClaim system. For example, a termination date of 5/1/2016 will be converted to 4/30/16 as part of the map,
              and will give the member coverage through 11:59pm on April 30, 2016. On May 1, 2016 at midnight, the member will no longer be covered.</p>
            <p *ngIf="!(this.drivers$ | async)?.termDateThruUsed">Any termination date sent by client/vendor will be treated as a 'through' date in '
              CVS Health's RxClaim system. For example, a termination date of 4/30/16 will give the member
              coverage through 11:59pm on April 30, 2016. On May 1, 2016 at midnight, the member will no longer
              be covered.</p>
        </div>

        <div id="cEPRTrmDates4AllMbrs" class="terms-movements-field" *ngIf="(this.drivers$ | async)?.participantTermDatesIsYes">
          <mat-form-field *ngIf="!readOnly; else readOnlyCeprTrmDates4AllMbrs">
              <textarea matInput
                        cdkTextareaAutosize
                        cdkAutosizeMinRows="1"
                        type="text"
                        [value]="cEPRTrmDates4AllMbrs$ | async"
                        formControlName="cEPRTrmDates4AllMbrs">
              </textarea>
          </mat-form-field>
          <ng-template #readOnlyCeprTrmDates4AllMbrs><p>{{ cEPRTrmDates4AllMbrs$ | async }}</p></ng-template>
        </div>

        <div id="cEPRDepImpTrms" class="terms-movements-field" *ngIf="(this.drivers$ | async)?.depImpTermsUsed">
          <mat-form-field *ngIf="!readOnly; else readOnlyCeprTrmDates4AllMbrs">
              <textarea matInput
                        cdkTextareaAutosize
                        cdkAutosizeMinRows="1"
                        type="text"
                        [value]="cEPRDepImpTrms$ | async"
                        formControlName="cEPRDepImpTrms">
              </textarea>
          </mat-form-field>
          <ng-template #readOnlyCeprTrmDates4AllMbrs><p>{{ cEPRDepImpTrms$ | async }}</p></ng-template>
        </div>

        <div id="cEPRImpTrms" class="terms-movements-field" *ngIf="(this.drivers$ | async)?.impliedTermsUsed ===  'Yes' || (this.drivers$ | async)?.impliedTermsUsed === 'No'">
          <b>Implied Terms</b>
          <mat-form-field *ngIf="!readOnly; else readOnlyCeprImpTrms">
              <textarea matInput
                        cdkTextareaAutosize
                        cdkAutosizeMinRows="1"
                        type="text"
                        [value]="cEPRImpTrms$ | async"
                        formControlName="cEPRImpTrms">
              </textarea>
          </mat-form-field>
          <ng-template #readOnlyCeprImpTrms><p>{{ cEPRImpTrms$ | async }}</p></ng-template>
        </div>

        <div class="terms-movements-field" *ngIf="(this.drivers$ | async)?.impliedTermsUsed === 'Yes' ">
          <b>Level</b>
          <span>{{this.impTermLevel$ | async}}</span>
        </div>

        <div id="cEPRImpTrm2" class="terms-movements-field" *ngIf="(this.drivers$ | async)?.moving">
          <mat-form-field *ngIf="!readOnly; else readOnlyCeprImpTrm2">
              <textarea matInput
                        cdkTextareaAutosize
                        cdkAutosizeMinRows="1"
                        type="text"
                        [value]="cEPRImpTrm2$ | async"
                        formControlName="cEPRImpTrm2">
              </textarea>
          </mat-form-field>
          <ng-template #readOnlyCeprImpTrm2><p>{{ cEPRImpTrm2$ | async }}</p></ng-template>
        </div>

        <div id="cEPRTMR" class="terms-movements-field" *ngIf="(this.drivers$ | async)?.isTmrUsed">
          <b>Term By Absence</b>
          <mat-form-field *ngIf="!readOnly; else readOnlyCeprTmr">
              <textarea matInput
                        cdkTextareaAutosize
                        cdkAutosizeMinRows="1"
                        type="text"
                        [value]="cEPRTMR$ | async"
                        formControlName="cEPRTMR">
              </textarea>
          </mat-form-field>
          <ng-template #readOnlyCeprTmr><p>{{ cEPRTMR$ | async }}</p></ng-template>
        </div>

        <div class="terms-movements-field">
          <span>{{this.tbaDate$ | async}}</span>
        </div>

        <div id="cEPRTMR2" class="terms-movements-field" *ngIf="(this.drivers$ | async)?.inclusionExlusionListUsed">
          <mat-form-field *ngIf="!readOnly; else readOnlyCeprTmr2">
              <textarea matInput
                        cdkTextareaAutosize
                        cdkAutosizeMinRows="1"
                        type="text"
                        [value]="cEPRTMR2$ | async"
                        formControlName="cEPRTMR2">
              </textarea>
          </mat-form-field>
          <ng-template #readOnlyCeprTmr2><p>{{ cEPRTMR2$ | async }}</p></ng-template>
        </div>

        <div id="cEPRTMR3" class="terms-movements-field" *ngIf="(this.drivers$ | async)?.manTMRProtectIsYes">
          <mat-form-field *ngIf="!readOnly; else readOnlyCeprTmr3">
              <textarea matInput
                        cdkTextareaAutosize
                        cdkAutosizeMinRows="1"
                        type="text"
                        [value]="cEPRTMR3$ | async"
                        formControlName="cEPRTMR3">
              </textarea>
          </mat-form-field>
          <ng-template #readOnlyCeprTmr3><p>{{ cEPRTMR3$ | async }}</p></ng-template>

        </div>

        <div id="cEPRTMR4" class="terms-movements-field" *ngIf="(this.drivers$ | async)?.tmrrangeListTypeIsDynamic">
          <mat-form-field *ngIf="!readOnly; else readOnlyCeprTmr4">
              <textarea matInput
                        cdkTextareaAutosize
                        cdkAutosizeMinRows="1"
                        type="text"
                        [value]="cEPRTMR4$ | async"
                        formControlName="cEPRTMR4">
              </textarea>
          </mat-form-field>
          <ng-template #readOnlyCeprTmr4><p>{{ cEPRTMR4$ | async }}</p></ng-template>

        </div>

        <div id="cEPRTMRIgnFutureTrm" class="terms-movements-field" *ngIf="(this.drivers$ | async)?.tmrignFtrTrmIsYes">
          <mat-form-field *ngIf="!readOnly; else readOnlyCeprTmrIngFutureTrm">
              <textarea matInput
                        cdkTextareaAutosize
                        cdkAutosizeMinRows="1"
                        type="text"
                        [value]="cEPRTMRIgnFutureTrm$ | async"
                        formControlName="cEPRTMRIgnFutureTrm">
              </textarea>
          </mat-form-field>
          <ng-template #readOnlyCeprTmrIngFutureTrm><p>{{ cEPRTMRIgnFutureTrm$ | async }}</p></ng-template>
        </div>

        <div id="cEPRTMRInclFtrEff" class="terms-movements-field" *ngIf="(this.drivers$ | async)?.tmrinclFtrEffIsYes">
          <mat-form-field *ngIf="!readOnly; else readOnlyCeprTrmInclFtrEff">
              <textarea matInput
                        cdkTextareaAutosize
                        cdkAutosizeMinRows="1"
                        type="text"
                        [value]="this.cEPRTMRInclFtrEff$ | async"
                        formControlName="cEPRTMRInclFtrEff">
              </textarea>
          </mat-form-field>
          <ng-template #readOnlyCeprTrmInclFtrEff><p>{{ cEPRTMRInclFtrEff$ | async }}</p></ng-template>
        </div>

        <div id="cEPRHistMthd" class="terms-movements-field" *ngIf="(this.drivers$ | async)?.hasHistoryMethod">
          <b>History Method</b>
          <mat-form-field *ngIf="!readOnly; else readOnlyCeprHistMthd">
            <textarea matInput
                      cdkTextareaAutosize
                      cdkAutosizeMinRows="1"
                      type="text"
                      [value]="cEPRHistMthd$ | async"
                      formControlName="cEPRHistMthd">
            </textarea>
          </mat-form-field>
          <ng-template #readOnlyCeprHistMthd><p>{{ cEPRHistMthd$ | async }}</p></ng-template>
        </div>
      </div>

      <h2>Additional Considerations</h2>
      <div id="additional-considerations-ea">
        <div id="cEPRSrvvngSps" class="ac-field">
          <b>Surviving Spouses</b>
          <mat-form-field *ngIf="!readOnly; else readOnlyCeprSrvvngSps">
            <textarea matInput
                      cdkTextareaAutosize
                      cdkAutosizeMinRows="1"
                      type="text"
                      [value]="cEPRSrvvngSps$ | async"
                      formControlName="cEPRSrvvngSps">
              </textarea>
          </mat-form-field>
          <ng-template #readOnlyCeprSrvvngSps><p>{{ cEPRSrvvngSps$ | async }}</p></ng-template>
        </div>

        <div id="cEPRCOB" class="ac-field">
          <b>Coordination of Benefits</b>
          <mat-form-field *ngIf="!readOnly; else readOnlyCeprCob">
            <textarea matInput
                      cdkTextareaAutosize
                      cdkAutosizeMinRows="1"
                      type="text"
                      [value]="cEPRCOB$ | async"
                      formControlName="cEPRCOB">
            </textarea>
          </mat-form-field>
          <ng-template #readOnlyCeprCob><p>{{ cEPRCOB$ | async }}</p></ng-template>
        </div>

        <div id="cEPRPCOBNCOBNote" class="ac-field" *ngIf="(this.drivers$ | async)?.pcobncobusedIsYes">
          <mat-form-field *ngIf="!readOnly; else readOnlyCeprCobNote">
            <textarea matInput
                      cdkTextareaAutosize
                      cdkAutosizeMinRows="1"
                      type="text"
                      [value]="cEPRPCOBNCOBNote$ | async"
                      formControlName="cEPRPCOBNCOBNote">
            </textarea>
          </mat-form-field>
          <ng-template #readOnlyCeprCobNote><p>{{ cEPRPCOBNCOBNote$ | async }}</p></ng-template>
        </div>

        <div id="cEPRGrpSusp" class="ac-field" *ngIf="(this.drivers$ | async)?.usesGroupSuspense">
          <b>Suspense</b>
          <mat-form-field *ngIf="!readOnly; else readOnlyCeprGrpSusp">
            <textarea matInput
                      cdkTextareaAutosize
                      cdkAutosizeMinRows="1"
                      type="text"
                      [value]="cEPRGrpSusp$ | async"
                      formControlName="cEPRGrpSusp">
            </textarea>
          </mat-form-field>
          <ng-template #readOnlyCeprGrpSusp><p>{{ cEPRGrpSusp$ | async }}</p></ng-template>
        </div>

        <div id="cEPRSuspense" class="ac-field">
                <mat-form-field *ngIf="!readOnly; else readOnlyCeprSuspense">
                <textarea matInput
                          cdkTextareaAutosize
                          cdkAutosizeMinRows="1"
                          type="text"
                          [value]="cEPRSuspense$ | async"
                          formControlName="cEPRSuspense">
                </textarea>
          </mat-form-field>
          <ng-template #readOnlyCeprSuspense><p>{{ cEPRSuspense$ | async }}</p></ng-template>
        </div>

        <div class="ac-field">
          <span id="retentionDaysEa">Retention Days: {{this.memberSuspenseRetentionDays$ | async}}</span>
        </div>

        <div id="cERPBatchOvr" class="ac-field" *ngIf="(this.drivers$ | async)?.batchOvrProtectIsYes">
          <b>Miscellaneous Items</b>
          <mat-form-field *ngIf="!readOnly; else readOnlyBatchOvr">
            <textarea matInput
                      style="white-space: pre-wrap;"
                      cdkTextareaAutosize
                      cdkAutosizeMinRows="1"
                      type="text"
                      [value]="cERPBatchOvr$ | async"
                      formControlName="cERPBatchOvr">
            </textarea>
          </mat-form-field>
          <ng-template #readOnlyBatchOvr><p>{{ cERPBatchOvr$ | async }}</p></ng-template>
        </div>

        <div id="cEPRCHOnly" class="ac-field" *ngIf="(this.drivers$ | async)?.crdhldrsOnlyQIsYes">
                <mat-form-field *ngIf="!readOnly; else readOnlyChOnly">
                <textarea matInput
                          cdkTextareaAutosize
                          cdkAutosizeMinRows="1"
                          type="text"
                          [value]="cEPRCHOnly$ | async"
                          formControlName="cEPRCHOnly">
                </textarea>
          </mat-form-field>
          <ng-template #readOnlyChOnly><p>{{ cEPRCHOnly$ | async }}</p></ng-template>
        </div>

        <div id="cEPRFamonCards" class="ac-field" *ngIf="cEPRFamonCards$ | async">
                <mat-form-field *ngIf="!readOnly; else readOnlyFamonCards">
                <textarea matInput
                          cdkTextareaAutosize
                          cdkAutosizeMinRows="1"
                          type="text"
                          [value]="cEPRFamonCards$ | async"
                          formControlName="cEPRFamonCards">
                </textarea>
          </mat-form-field>
          <ng-template #readOnlyFamonCards><p>{{ cEPRFamonCards$ | async }}</p></ng-template>
        </div>

        <div id="cEPRAutoCAG" class="ac-field" *ngIf="(drivers$ | async)?.grpOTForAutoCAGIsYes">
                <mat-form-field *ngIf="!readOnly; else readOnlyCeprAutoCag">
                <textarea matInput
                          cdkTextareaAutosize
                          cdkAutosizeMinRows="1"
                          type="text"
                          [value]="cEPRAutoCAG$ | async"
                          formControlName="cEPRAutoCAG">
                </textarea>
          </mat-form-field>
          <ng-template #readOnlyCeprAutoCag><p>{{ cEPRAutoCAG$ | async }}</p></ng-template>
        </div>

        <div id="cEPRStandaloneDeps" class="ac-field" *ngIf="(drivers$ | async)?.hasStandAloneDeps">
                <mat-form-field *ngIf="!readOnly; else readOnlyCeprStandaloneDeps">
                <textarea matInput
                          cdkTextareaAutosize
                          cdkAutosizeMinRows="1"
                          type="text"
                          [value]="cEPRStandaloneDeps$ | async"
                          formControlName="cEPRStandaloneDeps">
                </textarea>
          </mat-form-field>
          <ng-template #readOnlyCeprStandaloneDeps><p>{{ cEPRStandaloneDeps$ | async }}</p></ng-template>
        </div>

        <div id="cEPRSplitFam" class="ac-field" *ngIf="(drivers$ | async)?.memDepDifGrpIsYes">
                <mat-form-field *ngIf="!readOnly; else readOnlyCeprSplitFam">
                <textarea matInput
                          cdkTextareaAutosize
                          cdkAutosizeMinRows="1"
                          type="text"
                          [value]="cEPRSplitFam$ | async"
                          formControlName="cEPRSplitFam">
                </textarea>
          </mat-form-field>
          <ng-template #readOnlyCeprSplitFam><p>{{ cEPRSplitFam$ | async }}</p></ng-template>
        </div>

        <div id="field174" class="ac-field">
          <quill-editor
            *ngIf="!readOnly; else readOnlyField174"
            theme="snow"
            formControlName="field174"
            placeholder=""
          >
          </quill-editor>
          <ng-template #readOnlyField174>
            <quill-view [content]="this.eligAgreementForm.controls['field174'].value"></quill-view>
          </ng-template>
        </div>
      </div>

      <h2>Thresholds</h2>
      <div id="thresholds-ea">
        <table class="table-thresholds" >
          <tr id="mainHeader">
            <th>Thresholds</th>
            <th>File</th>
            <ng-container *ngIf="ssoeEnabled">
              <th *ngFor="let carrier of carrierArray">{{carrier}}</th>
            </ng-container>
            
          </tr>
          <ng-container class="value-Container" *ngFor="let field of thresholdFields">
            <tr id="thresholdRows" *ngIf="!field.inputType && !field.noShow">
              <td class="row-label">
                {{field.fieldLabel}}
              </td>
              <td>
                <span>{{ field.value }}</span>
                <span *ngIf="field.textbox2Value | async">, {{ field.textbox2Value | async }}</span>
                <span *ngIf="field.textbox3Value | async">, {{ field.textbox3Value | async }}</span>
              </td>
              <ng-container *ngIf="ssoeEnabled">
                <td *ngFor="let carrier of carrierArray">
                  {{field[carrier.trim()]}}
                </td>
              </ng-container> 
            </tr>
          </ng-container>
        </table>
        <table *ngIf="carrierArray2" class="table-thresholds" id="thresholdFieldsCont" >
          <tr id="mainHeader">
            <ng-container *ngIf="ssoeEnabled">
              <th *ngFor="let carrier of carrierArray2">{{carrier}}</th>
            </ng-container>
            
          </tr>
          <ng-container class="value-Container" *ngFor="let field of thresholdFields">
            <tr id="thresholdRows" *ngIf="!field.inputType && !field.noShow">
              <ng-container *ngIf="ssoeEnabled">
                <td *ngFor="let carrier of carrierArray2">
                  {{field[carrier.trim()]}}
                </td>
              </ng-container> 
            </tr>
          </ng-container>
        </table>
        <div id="thresholdNotes">
          <quill-editor
                *ngIf="!readOnly; else readOnlyThresholdNotes"
                theme="snow"
                formControlName="thresholdNotes"
                placeholder=""
              >
              </quill-editor>
              <ng-template #readOnlyThresholdNotes>
                <quill-view [content]="this.eligAgreementForm.controls['thresholdNotes']?.value"></quill-view>
              </ng-template>
        </div>
        
      </div>
    </div>
  </div>
</div>
</cvs-loader-overlay-container>
