<cvs-alert-placeholder id="reason-code-alert">
</cvs-alert-placeholder>

<div class="outer-grid">
<h2>Reason Codes</h2>
<ag-grid-angular class="ag-theme-alpine" [rowDragManaged]="true" (filterChanged)="onFilterChange( $event)"
[rowDragEntireRow]="true"
[rowDragMultiRow]="true" [suppressMenuHide]="true"
[rowSelection]="true"
(rowDragEnd)="onRowDragEnd($event)" domLayout='autoHeight' (sortChanged)="sortChange($event)" id="reasonCodeGrid" (keyup)="emitSave()"  (cellValueChanged)="updatedRow($event)"
    [context]="contextObj"  [stopEditingWhenCellsLoseFocus]="true" [stopEditingWhenGridLosesFocus]="true"
    (gridReady)="onGridReady($event)" [rowData]="dataRows">

</ag-grid-angular>
</div>
