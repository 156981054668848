import { DeleteBtnCellRendererComponent } from '../../common/DeleteBtnCellRenderer';

export const holidayColumns = [
  {
    headerName: 'Holiday Type', field: 'holidayType',
    editable: (param) => {
      return !param.data.isExpired;
    },
    cellStyle: (param) => {
      return !param.data.isExpired ? null : {color: 'lightgray'};
    },
    sortable: true,
    filter: true, lockVisible: true, lockPosition: true, resizable: true,
    width : 250
  },
  {
    headerName: 'Date', field: 'holidayDate',
    editable: (param) => {
      return !param.data.isExpired;
    },
    cellStyle: (param) => {
      return !param.data.isExpired ? null : {color: 'lightgray'};
    },
    sortable: true, filter: true, lockVisible: true, lockPosition: true, resizable: true,
    width: 150
  },
  {
    headerName: 'Action',
    cellRenderer: DeleteBtnCellRendererComponent,
    editable: false,
    width: 100
  }
];
