<section class="header">
  <cvs-alert-placeholder id="header-alert"></cvs-alert-placeholder>
  
</section>
<section class="criteria">
 
  <div [formGroup]="form" *ngIf="form">
    <h3 style="font-weight: normal;margin-bottom: 2px;margin-top: -5px;">Report Type</h3>
    <mat-radio-group formControlName="radio" class="pds-radio-group--large">
      <mat-radio-button *ngFor="let opt of radioOptions" [value]="opt.value">{{opt.display}}</mat-radio-button>

    </mat-radio-group>
    <mat-form-field class="criteria-select">
      <mat-label>Criteria</mat-label>
      <mat-select pdsMatOptionKeyboardFocus formControlName="criteria">
        <mat-option *ngFor="let options of criteriaList" [value]="options">{{options}}</mat-option>

      </mat-select>
      <mat-hint>Single Entry</mat-hint>
    </mat-form-field>
  </div>


  <ng-container [formGroup]="criteriaDependedentFrom" *ngIf="criteriaDependedentFrom">
    <!-- for carrier -->
    <div class="row">

      <ng-container *ngIf="currentCriteria === 'Carrier'">

        <mat-form-field>
          <input matInput placeholder="Carrier" formControlName="carrier" maxlength="9">
          <mat-hint>Single Entry</mat-hint>
        </mat-form-field>
      </ng-container>

      <ng-container *ngIf="currentCriteria === 'Carrier/Account'">

        <mat-form-field class="margin-right-sm">
          <input matInput placeholder="Carrier" formControlName="carrier" maxlength="9">
          <mat-hint>Single Entry</mat-hint>
        </mat-form-field>
        <mat-form-field>
          <input matInput placeholder="Account" formControlName="account" maxlength="15">
          <mat-hint>Single Entry</mat-hint>
        </mat-form-field>
      </ng-container>

      <ng-container *ngIf="currentCriteria === 'Carrier/Account/Group'">


        <mat-form-field class="margin-right-sm">
          <input matInput placeholder="Carrier" formControlName="carrier" maxlength="9">
          <mat-hint>Single Entry</mat-hint>
        </mat-form-field>
        <mat-form-field class="margin-right-sm">
          <input matInput placeholder="Account" formControlName="account" maxlength="15">
          <mat-hint>Single Entry</mat-hint>
        </mat-form-field>
        <mat-form-field class="margin-right-sm">
          <input matInput placeholder="Group" formControlName="group" maxlength="15">
          <mat-hint>Single Entry</mat-hint>
        </mat-form-field>

      </ng-container>
      <ng-container *ngIf="currentCriteria === 'Carrier List'">
        <mat-form-field appearance="outline">
          <textarea matInput formControlName="carrierList"></textarea>
        </mat-form-field>
        <mat-error *ngIf="!criteriaDependedentFrom.get('carrierList').valid">
          Carriers can only be 4-9 characters, no special characters, only one space between carriers, and comma
          separated, can enter upto 150 values
      </mat-error>
      </ng-container>
      <ng-container *ngIf="currentCriteria === 'Range'">

        <div class="row">
          <mat-form-field class="margin-right-sm">
            <input matInput placeholder="Carrier From" formControlName="carrierFrom" maxlength="9">
            <mat-hint>Single Entry</mat-hint>
          </mat-form-field>
          <mat-form-field class="margin-right-sm">
            <input matInput placeholder="Carrier End" formControlName="carrierEnd" maxlength="9">
            <mat-hint>Single Entry</mat-hint>
          </mat-form-field>
        </div>
        <div class="row">
          <mat-form-field class="margin-right-sm">
            <input matInput placeholder="Account From" formControlName="accountFrom" maxlength="15">
            <mat-hint>Single Entry</mat-hint>
          </mat-form-field>
          <mat-form-field class="margin-right-sm">
            <input matInput placeholder="Account End" formControlName="accountEnd" maxlength="15">
            <mat-hint>Single Entry</mat-hint>
          </mat-form-field>
        </div>
        <div class="row">
          <mat-form-field class="margin-right-sm">
            <input matInput placeholder="Group From" formControlName="groupFrom" maxlength="15">
            <mat-hint>Single Entry</mat-hint>
          </mat-form-field>
          <mat-form-field class="margin-right-sm">
            <input matInput placeholder="Group End" formControlName="groupEnd" maxlength="15">
            <mat-hint>Single Entry</mat-hint>
          </mat-form-field>
        </div>
      </ng-container>
    </div>

  </ng-container>
  <div class="flex suspense-btn-container">
    <button class="pds-btn-primary margin-tb" [disabled]="!(form.valid && criteriaDependedentFrom.valid)"
      (click)="searchResult()">Search</button>
    <button class="pds-btn-white margin-tb" (click)="scheduleEvent()"
      [disabled]="!(form.valid && criteriaDependedentFrom.valid)">
      <mat-icon class="cag-icon" svgIcon="reorder-simple--s"></mat-icon>Schedule
    </button>
  </div>

</section>
<!-- grid view -->
<section class="search-result" *ngIf="showGrid">
  <app-suspense-grid [radioType]="formRadioVal" [searchPayload]="searchPayload" [gridData]="gridData">
  </app-suspense-grid>
</section>
<!-- grid view END -->