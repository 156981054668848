<div class="user_settings--container">
  <cvs-alert-placeholder id="register-user-alert"></cvs-alert-placeholder>
  <cvs-alert-placeholder id="header-alert"></cvs-alert-placeholder>
  <cvs-alert-placeholder id="dateValidator"></cvs-alert-placeholder>
  <h1 class="header-text">User Settings</h1>
  <div class="pds-card">
    <div class="pds-card__content">
      <mat-icon class="pds-icon--s" svgIcon="profile--s"></mat-icon>
      <p class="pds-card__paragraph">First Name: <strong>{{currentUserFname$ | async}}</strong></p>
      <p class="pds-card__paragraph">Last Name: <strong>{{currentUserLname$ | async}}</strong></p>
      <p class="pds-card__paragraph">Role: <strong>{{currentUserRole$ | async}}</strong></p>
      <p class="pds-card__paragraph">Employee ID: <strong>{{currentUserEmployerId$ | async}}</strong></p>
      <p class="pds-card__paragraph">Email: <strong>{{currentUserEmail$ | async}}</strong></p>
      <mat-basic-chip *ngIf="user && user.outofofficeFromDate && !checkOooExpired" class="pds-status-chip--orange-dark mat-mdc-chip mat-mdc-basic-chip">
        <span class="indicator orange-indicator"></span> Out of Office</mat-basic-chip>
      <mat-basic-chip *ngIf="user && checkOooExpired" class="pds-status-chip--success mat-mdc-chip mat-mdc-basic-chip">
        <span class="indicator green-indicator"></span> Active</mat-basic-chip>
      <p class="pds-card__date" *ngIf="user && user.outofofficeFromDate && !checkOooExpired">{{user.outofofficeFromDate
        | date}} - {{user.outofofficeToDate | date}}</p>
    </div>
  </div>
  <div class="user_settings--content">
  <mat-tab-group (selectedTabChange)="tabChange($event)" class="pds-primary-tab" aria-label="User Settings" dynamicHeight>
    <mat-tab label="User Settings">
      <div class="user_settings--content">
        <h3 class="user_settings--container__content_header">User Information</h3>
      </div>
      <cvs-loader-overlay-container [isLoading]="showSpinner$ | async" loadingMessage="Loading">
        <div class="user_settings--container__form">
          <form [formGroup]="userSettingsForm" class="pds-container-form" id="error-form-validation-a11y">
            <div class="user_settings--container__form__flex-with-gap-1">
              <mat-form-field style="width: 23em">
                <mat-label>Text Email</mat-label>
                <input matInput formControlName="email">
              </mat-form-field>
              <mat-form-field style="width: 23em">
                <mat-label>Backup</mat-label>
                <!-- <mat-select formControlName="backup">
                  <mat-option *ngFor="let user of allUsers" [value]="user">
                    {{user.firstName}} {{user.lastName}}
                  </mat-option>
                </mat-select> -->
                <mat-select formControlName="backup" [compareWith]="compareUsers">
                  <mat-option *ngFor="let user of activeUsers$ | async" [value]="user">
                    {{user.firstName}} {{user.lastName}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field style="width: 23em">
                <mat-label>Leader</mat-label>
                <!-- <mat-select formControlName="leader">
                  <mat-option *ngFor="let user of allUsers" [value]="user">
                    {{user.firstName}} {{user.lastName}}
                  </mat-option>
                </mat-select> -->
                <mat-select formControlName="leader" [compareWith]="compareUsers">
                  <mat-option *ngFor="let user of leaderMaterialUsers$ | async" [value]="user">
                    {{user.firstName}} {{user.lastName}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
               
            </div>
            <div style="display: flex;" class="mtb-sm">
              <b>Out Of Office</b>
              <mat-checkbox formControlName="allDayEvent" style="left: 30px;" #refAllDay
                (change)="allDayEventChecked($event)">All Day Event</mat-checkbox>
            </div>
            <div style="display: flex; " class="mb-s">
              <mat-form-field>
                <mat-label>Start Date</mat-label>
                <div style="display: flex;">
                  <input id="startDate" matInput (dateChange)="onDateChange($event, 'startDate')"
                    formControlName="startDate" [matDatepicker]="startDatepicker">
                  <mat-icon class="calendarTimePick" (click)="startDatepicker.open()" svgIcon="calendar--s">StartDate
                    Icon
                  </mat-icon>
                </div>
                <mat-hint>MM/DD/YYYY</mat-hint>
                <mat-datepicker #startDatepicker></mat-datepicker>
              </mat-form-field>
              <mat-form-field style="width: 140px; left: 30px; display: flex;">
                <mat-label>Start Time</mat-label>
                <input type="time" style="margin-top: 3px; margin-bottom: -10px;" matInput formControlName="startTime"
                  name="startTime" (change)="updateTime($event, true)" />
              </mat-form-field>
            </div>
            <div style="display: flex;">
              <mat-form-field>
                <mat-label>Return Date</mat-label>
                <div style="display: flex;">
                  <input id="endDate" matInput (dateChange)="onDateChange($event, 'endDate')" formControlName="endDate"
                    [matDatepicker]="endDatepicker">
                  <mat-icon class="calendarTimePick" (click)="endDatepicker.open()" svgIcon="calendar--s">EndDate Icon
                  </mat-icon>
                </div>
                <mat-hint>MM/DD/YYYY</mat-hint>
                <mat-datepicker #endDatepicker></mat-datepicker>
              </mat-form-field>
              <mat-form-field style="width: 140px;left: 30px; display: flex;">
                <mat-label>End Time</mat-label>
                <input type="time" style="margin-top: 3px; margin-bottom: -10px;" formControlName="endTime" matInput
                  name="endTime" (change)="updateTime($event, false)" />
                </mat-form-field>

            </div>
            <div class="user_settings--container__form__btn">
              <button class="pds-btn-primary cancel_btn" (click)="userSettingsForm.reset()">Cancel</button>
              <button class="pds-btn-primary submit_btn" [disabled]="!userSettingsForm.valid"
                (click)="submitForm()">Save</button>
            </div>

            <div class="header">
              <h2>EPR Visibility</h2>
            </div>
            <div class="grid">
              <ag-grid-angular class="ag-theme-alpine pds-ag-grid" 
              #usersettinggrid (rowClicked)='onRowClicked($event)'>
              </ag-grid-angular>
              </div>
              
          </form>
        </div>
      </cvs-loader-overlay-container>
    </mat-tab>
    <mat-tab label="Scheduled Events" *ngIf="userId">
      <app-scheduled-events-grid [userId]="userId" *ngIf="scheduleEvnetsTabActive"></app-scheduled-events-grid>
    </mat-tab>
  </mat-tab-group>
</div>