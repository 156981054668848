import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {CVSHttpClient} from 'angular-component-library';
import {HttpClient} from '@angular/common/http';
import { ProcessingFileDB } from '../models/PermissionsObject';

@Injectable({
  providedIn: 'root'
})
export class AuditlogService {

  constructor(private http: CVSHttpClient, private httpgen: HttpClient) {  }

  getAuditLog(request: any, ssoeFlag= false,db= null): Observable<any> {
    const baseURL = environment.ewf_processingFile;
    let endPoint = ssoeFlag ? `ssoe/auditLog?db=${db}`: 'auditLog';

    return this.http.post(`${baseURL}/${endPoint}`, request);
  }

  createAuditLogRequest(request: any, ssoeFlag=false,db = null): Observable<any> {
    let ssoeBaseUrl = `${environment.baseUrlSSOE}/${environment.ssoe_Ewf}`;
    if(ssoeFlag && db){
    ssoeBaseUrl = this.getSSoeBaseUrl(db);
    }
    const baseURL = ssoeFlag ? ssoeBaseUrl: environment.baseUrl;
    return this.http.post(`${baseURL}/dialog/auditLog`, request);
  }

  getAuditLogSummaryReport(request: any, ssoeFlag=false , db = null): Observable<any> {
    let ssoeBaseUrl = `${environment.baseUrlSSOE}/${environment.ssoe_Ewf}`;
    if(ssoeFlag && db){
    ssoeBaseUrl = this.getSSoeBaseUrl(db);
    }
    const baseURL = ssoeFlag ? ssoeBaseUrl: environment.rxClaimUrl;
    if(ssoeFlag){
      // db value => env
      let url = new URL(baseURL);
      return this.http.downloadFile(`${url.origin}/reporting-engine/media/report/adhoc?fastLinkMediaId=${request.auditNumber}&pli=${request.pli}&reportType=SUMMARY`);
    }
    else{
      return this.http.post(`${baseURL}/auditLog/summary`, request);
    }

  }

  getAuditLogDetailsReport(request: any, ssoeFlag=false, db = null): Observable<any> {
    let ssoeBaseUrl = `${environment.baseUrlSSOE}/${environment.ssoe_Ewf}`;
    if(ssoeFlag && db){
    ssoeBaseUrl = this.getSSoeBaseUrl(db);
    }
    const baseURL = ssoeFlag ? ssoeBaseUrl: environment.rxClaimUrl;
    if(ssoeFlag){
      let url = new URL(baseURL);
      return this.http.downloadFile(`${url.origin}/reporting-engine/media/report/adhoc?fastLinkMediaId=${request.auditNumber}&pli=${request.pli}&reportType=DETAIL`);
    }
    else{
      return this.http.post(`${baseURL}/auditLog/details`, request);
    }

  }
  getSSoeBaseUrl(db: ProcessingFileDB): string {
    let ssoeBaseUrl;
    if(db === ProcessingFileDB.ADS){
      ssoeBaseUrl = environment.baseUrlSSOE_Ads
     }else if(db === ProcessingFileDB.PRD){
      ssoeBaseUrl = environment.baseUrlSSOE
     }else if(db === ProcessingFileDB.BETA){
      ssoeBaseUrl = environment.baseUrlSSOE_Beta
     }
     return `${ssoeBaseUrl}/${environment.ssoe_Ewf}`;
  }
}
