import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

export interface ThresholdConfirmConstants {
  request_type: string;
  icon: string;
  message: string;
  onConfirm: () => void;
}

@Component({
  selector: 'app-threshold-confirm-dialog',
  templateUrl: './threshold-confirm-dialog.component.html',
  styleUrls: ['./threshold-confirm-dialog.component.scss']
})
export class ThresholdConfirmDialogComponent {

  thresholdConfirmInput: string;
  confirmClicked = false;
  action: string;

  constructor(public dialogRef: MatDialogRef<ThresholdConfirmDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: ThresholdConfirmConstants) { }

  closeDialogAfterConfirmation() {
    setTimeout(() => {
      this.dialogRef.close({
          commentedNotes: this.thresholdConfirmInput,
          action: this.action
      });
    }, 3000);
  }

}
