import {NgModule} from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import {MatLegacyInputModule as MatInputModule} from '@angular/material/legacy-input';
import {MatLegacySelectModule as MatSelectModule} from '@angular/material/legacy-select';
import {MatLegacyFormFieldModule as MatFormFieldModule} from '@angular/material/legacy-form-field';
import {MatLegacyTabsModule as MatTabsModule} from '@angular/material/legacy-tabs';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatLegacyDialogModule as MatDialogModule} from '@angular/material/legacy-dialog';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatLegacyProgressBarModule as MatProgressBarModule} from '@angular/material/legacy-progress-bar';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import {MatLegacyAutocompleteModule as MatAutocompleteModule} from '@angular/material/legacy-autocomplete';
import { MatLegacyChipsModule as MatChipsModule} from '@angular/material/legacy-chips';
// All Angular Material Modules to be used in your project should be added through this file

@NgModule({
    imports: [MatButtonModule,
      MatChipsModule,
      MatIconModule,
      MatInputModule,
      MatSelectModule,
      MatFormFieldModule,
      MatTabsModule,
      MatRadioModule,
      MatCheckboxModule,
      MatExpansionModule,
      MatDialogModule,
      FormsModule,
      ReactiveFormsModule,
      MatProgressBarModule,
      MatListModule,
      MatButtonToggleModule,
      MatMenuModule,
      MatAutocompleteModule,
      MatSlideToggleModule
    ],
    exports: [MatButtonModule,
      MatChipsModule,
      MatIconModule,
      MatInputModule,
      MatSelectModule,
      MatFormFieldModule,
      MatTabsModule,
      MatRadioModule,
      MatCheckboxModule,
      MatExpansionModule,
      MatDialogModule,
      FormsModule,
      ReactiveFormsModule,
      MatProgressBarModule,
      MatListModule,
      MatButtonToggleModule,
      MatMenuModule,
      MatAutocompleteModule,
      MatSlideToggleModule
    ],
})
export class MyMaterialModule { }
