<cvs-alert-placeholder id="file-layout"></cvs-alert-placeholder>
<!-- <cvs-alert-placeholder id="file-layout-warning"></cvs-alert-placeholder> -->

<div id="record">
  <div id="record-list" *ngIf="!isEditable">
    <div class="leftpanel" *ngIf="leftpanelVisible">
    <button id="register-user-btn" class="pds-btn-text" (click)="addRecord()" style="width:100%;">
      <mat-icon svgIcon="plus--s"></mat-icon>
      Add
    </button>

    <mat-form-field id="search-record" *ngIf="searchRecordForm" [formGroup]="searchRecordForm">


      <input matInput formControlName="recordSearch" placeholder="Record Type">


    </mat-form-field>

    <ng-container *ngFor="let rec of (recordData); let rec_index = index;">
      <div class="record-list-details col-md-12 row"
        [ngClass]="{'record-highlight' :  (selectedRecordData && rec_index === selectedRecordData.recIndex )  }">
        <div class="col-md-8" style="margin-right: 2rem;" (click)="loadRecord(rec ,rec_index )"
          id="{{rec_index+'record'}}">
          <div class="name">{{ rec.recordType}} Rec Type</div>
          <div class="ldap" class="recordName-highlight"
            [ngStyle]="{'font-weight':(selectedRecordData && rec_index === selectedRecordData.recIndex ) ? 'bolt' :''}"
            style="padding-top: 7px">{{ rec.recordName }}</div>
        </div>
        <div class="col-md-3">
          <button mat-icon-button [matMenuTriggerFor]="menu" (click)="loadRecord(rec ,rec_index )">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu" [overlapTrigger]="false">
            <button mat-menu-item (click)="renameRecord(rec)">
              <span>Edit</span>
            </button>
            <button mat-menu-item (click)="copyRecord(rec)">
              <span>Copy</span>
            </button>
            <button mat-menu-item (click)="sortRecords()">
              <span>Sort</span>
            </button>
            <button mat-menu-item (click)="deleteRecord(rec)">
              <span>Delete</span>
            </button>

          </mat-menu>
        </div>
      </div>
    </ng-container>
   </div>
    <div class="dividerbar" (click)="toggleLeftPanel()">
      <mat-icon class="icon-props" svgIcon={{expansionIcon}}></mat-icon>
    </div>
  </div>

  <div class="mat-elevation-z8" [ngStyle]="{'width':isEditable ? '100%' :'90%'}">
    <ng-container>
      <div id="file-layout-warning" style="display: none;">
      <div  class="warning-container">
       <span class="warning-icon">
         <mat-icon
         matSuffix aria-hidden="true"
         class="material-symbols-outlined warning-mat-icon" svgIcon="error--s">error</mat-icon></span>
       <span style="width:93.5%; margin-left: 1rem;">
        <div class="warning-header">Warning: Action Needed</div>
        <div class="warning-para">Field and/or Length is invalid. See marked cells for review.</div>
       </span>
       <span>
        <mat-icon svgIcon="close-btn--s" class="warning-cross" (click)="validatePositionField(true)"></mat-icon>
       </span>
      </div>
      </div>
    </ng-container>
    <div class="col-md-12" *ngIf="isEditable">
      <p class="record-name" style="color: #958e85;">{{selectedRecordData.recordType}}</p>
      <p style="font-weight: bold;">{{selectedRecordData.recordName}}</p>
    </div>
    <div class="row" style="padding: 8px 0 3px 0; justify-content: space-between; " *ngIf=" !isEditable ; else editableCase ">
      <div class="search-add-container">
        <span>
          <mat-form-field appearance="standard">
            <input matInput placeholder="Search" id = "search"[formControl]="searchForm.controls.search" >
            <mat-icon svgIcon="search--s"></mat-icon>
          </mat-form-field>
        </span>

    </div>
    <div style="display: flex;align-items:flex-end;padding-top:50px;height:20px">
      <button id="register-user-btn" class="pds-btn-text crud-button"  (click)="addFileLayout()"
        [disabled]="!selectedRecordData || rejectActionsApiPending">
        <mat-icon svgIcon="plus--s"></mat-icon>
        Add
      </button>
      <button class="pds-btn-text crud-button" [disabled]="isRowSelected" (click)="setEditable()">
        <mat-icon svgIcon="pencil--s"></mat-icon>
        <span>Edit</span>
      </button>
      <button class="pds-btn-text crud-button" [disabled]="isRowSelected" (click)="deleteFileLayout()">
        <mat-icon svgIcon="trash--s"></mat-icon>
        <span>Delete</span>
      </button>
    </div>
    </div>
    <ng-template #editableCase>
      <div class="row" style="padding: 8px 0 3px 0">
        <div style="justify-content:start" class="col-md-6 row">
          <h1>Edit Mode</h1>
          <button _ngcontent-mic-c13="" class="pds-btn-text col-md-4" style="/* padding: 8px 20px; */"
            (click)="exitSaveFileLayout()">
            <mat-icon svgIcon="close-btn--s"></mat-icon>
            <span>Exit without Saving</span>
          </button>
        </div>
        <div style="justify-content: end; display: flex;" class="col-md-6">
          <button _ngcontent-mic-c13="" class="pds-btn-text col-md-4"
            (click)="saveFileLayout()">
            <mat-icon svgIcon="folder--s"></mat-icon>
            <span>Save & Exit</span>
          </button>

        </div>
      </div>
    </ng-template>
    <form [formGroup]="fileLayoutForm" autocomplete="off"
      *ngIf="(filelayout && fileTableData.length) else noTable">
      <ng-container formArrayName="filelayout">
        <cvs-loader-overlay-container [isLoading]="showSpinner | async" loadingMessage="Loading">
        <mat-table [dataSource]="filelayout.controls" [ngClass]="!isEditable? leftpanelVisible ? 'table-props': 'table-props1' :'table-edit-props'">
          <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
          <mat-row #row *matRowDef="let row;  let i = dataIndex ; columns: displayedColumns;" [ngClass]="{highlight: row.highlighted}"></mat-row>
          <!--- Note that these columns can be defined in any order.
                  The actual rendered columns are set as a property on the row definition" -->

          <!-- Id Column -->
          <ng-container matColumnDef="check">
            <mat-header-cell *matHeaderCellDef></mat-header-cell>
            <mat-cell *matCellDef="let element" [formGroup]="element">
              <!-- style="margin-bottom:-25px ;" -->
              <!-- style="margin-top: -14px; " -->
              <mat-checkbox #checkbox (change)="element.highlighted = checkbox.checked" formControlName="check" [disabled]="isEditable && !checkbox.checked"></mat-checkbox>
            </mat-cell>
          </ng-container>
          <!-- User Column -->
          <ng-container matColumnDef="fieldNumber">
            <mat-header-cell *matHeaderCellDef class="border-side"> Field Number </mat-header-cell>

            <mat-cell *matCellDef="let element" [formGroup]="element">
              <ng-container *ngIf="(element.get('check')?.value && isEditable) else div_fieldNumber">
                <mat-form-field floatLabel="never" appearance="none" class="col-internal-6">
                  <input matInput formControlName="fieldNumber" appAlphaNumeric />
                  <mat-error *ngIf="element.controls['fieldNumber'].errors?.required">Required *</mat-error>
                </mat-form-field>
              </ng-container>
              <ng-template #div_fieldNumber>
                <div class="padding-top-bottom">{{element.get('fieldNumber')?.value}}</div>
              </ng-template>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="fieldName">
            <mat-header-cell *matHeaderCellDef> Field Name </mat-header-cell>
            <mat-cell *matCellDef="let element" [formGroup]="element">
              <ng-container *ngIf="(element.get('check')?.value && isEditable) else div_fieldName">
                <mat-form-field floatLabel="never" appearance="none" class="col-internal-6">
                  <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="1" cdkAutosizeMaxRows="8"
                    formControlName="fieldName"></textarea>
                  <mat-error *ngIf="element.controls['fieldName'].errors?.required">Required *</mat-error>
                </mat-form-field>

              </ng-container>
              <ng-template #div_fieldName>
                <div class="padding-top-bottom">{{element.get('fieldName')?.value}}</div>
              </ng-template>

            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="position">
            <mat-header-cell *matHeaderCellDef> Position </mat-header-cell>
            <mat-cell *matCellDef="let element" [formGroup]="element"
            [ngClass]="{'cell-highlight': element.get('position').showWarning === true}"

              >
              <ng-container *ngIf="(element.get('check')?.value && isEditable) else div_position">
                <mat-form-field floatLabel="never" appearance="none"
                style="text-align: right;"
                 class=" mat-field-small col-internal-4">
                 <!-- <div style="display: flex;"> -->
                  <!-- <button mat-button matPrefix mat-icon-button> -->


                      <mat-icon style="
                       position: absolute;
                       margin-top: -1.2rem;
                       margin-left: -0.6rem;
                       font-size: 24px;" *ngIf="element.get('position').showWarning" matPrefix
                        aria-hidden="true" class="material-symbols-outlined orange" svgIcon="error--s">error</mat-icon>
                <!-- </button> -->


                <input matInput formControlName="position" type="number"
                style="margin-left: 1.4rem;
                min-width: 4rem;"
                (change)="validatePositionField()"/>

                 <!-- </div> -->
                  <mat-error *ngIf="element.controls['position'].errors?.required">Required *</mat-error>
                </mat-form-field>
              </ng-container>
              <ng-template  #div_position>
                <mat-icon *ngIf="element.get('position').showWarning"
                style="font-size: 24px; position:absolute ;width: 1rem; margin-left: -7px; margin-top:auto"
                 matSuffix aria-hidden="true" class="material-symbols-outlined orange" svgIcon="error--s" >error</mat-icon>
                <div class="padding-top-bottom"
                [ngStyle]="{'min-width': (element.get('position').showWarning) ? '4rem' :'5.4rem',
                'margin-left': (element.get('position').showWarning) ? '12px' :'' }"
                >{{element.get('position')?.value}}</div>

              </ng-template>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="length">
            <mat-header-cell *matHeaderCellDef> Length </mat-header-cell>
            <mat-cell *matCellDef="let element" [formGroup]="element">
              <ng-container *ngIf="(element.get('check')?.value && isEditable) else div_length">
                <mat-form-field floatLabel="never" appearance="none"
                style="text-align: right;"
                class="mat-field-small col-internal-4">
                  <input matInput formControlName="length" style="min-width: 4.8rem;" type="number" (change)="validatePositionField()"/>
                  <mat-error *ngIf="element.controls['length'].errors?.required">Required *</mat-error>
                </mat-form-field>
              </ng-container>
              <ng-template #div_length>
                <div class="padding-top-bottom" style="min-width: 5rem;">{{element.get('length')?.value}}</div>
              </ng-template>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="use">
            <mat-header-cell *matHeaderCellDef> Use </mat-header-cell>
            <mat-cell *matCellDef="let element" [formGroup]="element">
              <ng-container *ngIf="(element.get('check')?.value && isEditable) else div_use">
                <mat-form-field floatLabel="never" appearance="none" class=" mat-field-small col-internal-4">
                  <input matInput formControlName="use" appAlphaNumeric />

                </mat-form-field>

              </ng-container>
              <ng-template #div_use>
                <div class="padding-top-bottom">{{element.get('use')?.value}}</div>

              </ng-template>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="type">
            <mat-header-cell *matHeaderCellDef> Type </mat-header-cell>
            <mat-cell *matCellDef="let element" [formGroup]="element">
              <ng-container *ngIf="(element.get('check')?.value && isEditable) else div_type">
                <mat-form-field floatLabel="never" appearance="none" class=" mat-field-small col-internal-4">
                  <input matInput formControlName="type" appAlphaNumeric />
                </mat-form-field>

              </ng-container>
              <ng-template #div_type>
                <div class="padding-top-bottom">{{element.get('type')?.value}}</div>
              </ng-template>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="fieldInformation">
            <mat-header-cell *matHeaderCellDef> Field Information </mat-header-cell>
            <mat-cell *matCellDef="let element" [formGroup]="element">
              <ng-container *ngIf="(element.get('check')?.value && isEditable) else div_fieldInformation">
                <mat-form-field floatLabel="never" appearance="none" class="col-internal-20">
                  <!-- <input  matInput formControlName="fieldInformation" /> -->
                  <textarea matInput style="" cdkTextareaAutosize cdkAutosizeMinRows="1" cdkAutosizeMaxRows="8"
                    formControlName="fieldInformation"></textarea>
                  </mat-form-field>

              </ng-container>
              <ng-template #div_fieldInformation>
                <div class="padding-top-bottom">{{element.get('fieldInformation')?.value}}</div>

              </ng-template>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="internalNotes">
            <mat-header-cell *matHeaderCellDef> Internal Notes </mat-header-cell>
            <mat-cell *matCellDef="let element" [formGroup]="element">
              <ng-container *ngIf="(element.get('check')?.value && isEditable) else div_internalNotes">
                <mat-form-field *ngIf="!element.get('internalNotes').disabled" floatLabel="never" appearance="none"
                  class="col-internal-20">
                  <!-- <input matInput formControlName="internalNotes" /> -->
                  <textarea matInput style=""
                  cdkTextareaAutosize cdkAutosizeMinRows="1" cdkAutosizeMaxRows="8"
                  formControlName="internalNotes"></textarea>
                </mat-form-field>
              </ng-container>
              <ng-template #div_internalNotes>
                <div class="padding-top-bottom">{{element.get('internalNotes')?.value}}</div>

              </ng-template>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="rejectLevel">
            <mat-header-cell *matHeaderCellDef> Reject Level </mat-header-cell>
            <mat-cell *matCellDef="let element" [formGroup]="element">
              <ng-container *ngIf="(element.get('check')?.value && isEditable) else div_rejectLevel">

                <mat-form-field floatLabel="never" appearance="none" class="col-internal-20">
                  <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="1" cdkAutosizeMaxRows="8"
                    formControlName="rejectLevel"></textarea>
                </mat-form-field>
              </ng-container>
              <ng-template #div_rejectLevel>
                <div class="div-reject padding-top-bottom">{{element.get('rejectLevel')?.value}}</div>

              </ng-template>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="fieldActions">
            <mat-header-cell *matHeaderCellDef> Field Actions</mat-header-cell>
            <mat-cell *matCellDef="let element" [formGroup]="element">
              <ng-container *ngIf="(element.get('check').value && isEditable) else div_fieldActions">
                <mat-form-field floatLabel="never" appearance="none">
                  <div class="fieldAction-panel">
                    <mat-select matInput placeholder="fieldActions" formControlName="fieldActions" multiple>
                      <mat-select-trigger>{{getSelectedValue(element, 'fieldActions')}}</mat-select-trigger>
                      <mat-optgroup disabled class="mat-optgroup-header">
                        <div class="header-labels">
                          <div class="default-label">Default</div>
                          <div class="reaction-label">Field Actions</div>
                        </div>
                       
                      </mat-optgroup>
                      <mat-option style="margin-left:140px" *ngFor="let item of fieldActionsData" [value]="item.id" [disabled]="element.get('fieldActionsDefault').value === item.id">
                        <div style="width: 150px;text-overflow: ellipsis;display:flex">
                          <span style="width: 120px;overflow: hidden;text-overflow: ellipsis;" [title]="item.message">{{item.message}}</span>
                          <div  style="padding-left: 5px;" matTooltipClass="tooltip-bg-color" [matTooltip]="item.messageDescription"  matTooltipHideDelay="300"><mat-icon svgIcon="info-circle--s"></mat-icon></div>
                        </div>
                        <mat-radio-button [name]="item.id-element.get('position')"  style="position:fixed;margin-left:-170px;padding:0px 30px;" [value]="item.id" [checked]="dataItemChecked(item,element, 'fieldActionsDefault')" [disabled]="!isSelected(item,element, 'fieldActions')"
                                          (click)="HandleRadioEvent($event)" (change)="radioChange($event,element, 'fieldActionsDefault')"></mat-radio-button>

                        <span style="padding-left:20px">
                      <a *ngIf="isSelected(item,element, 'fieldActions')"
                         (click)="HandleSearchEvent($event);openDialog(item,'fieldActions')">
                          <span *ngIf="item.messageCode">{{item.messageCode}}</span>
                      </a>
                      </span>
                      </mat-option>
                    </mat-select>
                  </div>
                </mat-form-field>
              </ng-container>
              <ng-template #div_fieldActions>
                <div class="div-reject padding-top-bottom">{{getSelectedValue(element, 'fieldActions')}}</div>

              </ng-template>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="actionValues">
            <mat-header-cell *matHeaderCellDef> Action Values </mat-header-cell>
            <mat-cell *matCellDef="let element" [formGroup]="element">
              <ng-container *ngIf="(element.get('check')?.value && isEditable) else div_actionValues">
                <mat-form-field floatLabel="never" appearance="none" class=" mat-field-small col-internal-4">
                  <input matInput formControlName="actionValues"  />
                </mat-form-field>

              </ng-container>
              <ng-template #div_actionValues>
                <div class="padding-top-bottom">{{element.get('actionValues')?.value}}</div>
              </ng-template>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="fieldValidation">
            <mat-header-cell *matHeaderCellDef> Validations </mat-header-cell>
            <mat-cell *matCellDef="let element" [formGroup]="element">
              <ng-container *ngIf="(element.get('check').value && isEditable) else div_fieldValidation">
                <mat-form-field floatLabel="never" appearance="none">
                  <div class="validation-panel">
                    <mat-select matInput placeholder="Validation" formControlName="fieldValidation" multiple>
                      <mat-select-trigger>{{getSelectedValue(element, 'fieldValidation')}}</mat-select-trigger>
                      <mat-optgroup disabled class="mat-optgroup-header">
                        <div class="header-labels">
                          <div class="default-label">Default</div>
                          <div class="reaction-label">Validations</div>
                        </div>

                      </mat-optgroup>
                      <mat-option style="margin-left:140px" *ngFor="let item of validationsData" [value]="item.id" [disabled]="element.get('validationDefault').value === item.id">
                        <div style="width: 150px;text-overflow: ellipsis;display:flex">
                          <span style="width: 120px;overflow: hidden;text-overflow: ellipsis;" [title]="item.message">{{item.message}}</span>
                          <div  style="padding-left: 5px;" matTooltipClass="tooltip-bg-color" [matTooltip]="item.messageDescription"  matTooltipHideDelay="300"><mat-icon svgIcon="info-circle--s"></mat-icon></div>
                        </div>
                        <mat-radio-button [name]="item.id-element.get('position')" style="position:fixed;margin-left:-170px;padding:0px 30px;" [value]="item.id" [checked]="dataItemChecked(item,element, 'validationDefault')" [disabled]="!isSelected(item,element, 'fieldValidation')"
                                          (click)="HandleRadioEvent($event)" (change)="radioChange($event,element, 'validationDefault')"></mat-radio-button>

                        <span style="padding-left:20px">
                      <a *ngIf="isSelected(item,element, 'fieldValidation')"
                         (click)="HandleSearchEvent($event);openDialog(item,'fieldValidation')">
                          <span *ngIf="item.messageCode">{{item.messageCode}}</span>
                      </a>
                      </span>
                      </mat-option>
                    </mat-select>
                  </div>
                </mat-form-field>
              </ng-container>
              <ng-template #div_fieldValidation>
                <div class="div-reject padding-top-bottom">{{getSelectedValue(element, 'fieldValidation')}}</div>

              </ng-template>
            </mat-cell>
          </ng-container>



          <ng-container matColumnDef="fieldValues">
            <mat-header-cell *matHeaderCellDef class="border-side"> Validation Values </mat-header-cell>

            <mat-cell *matCellDef="let element" [formGroup]="element">
              <ng-container *ngIf="(element.get('check').value && isEditable) else div_fieldValues">
                <mat-form-field floatLabel="never" appearance="none" class="col-internal-6">
                  <input matInput formControlName="fieldValues"  />
                </mat-form-field>
              </ng-container>
              <ng-template #div_fieldValues>
                <div class="padding-top-bottom">{{element.get('fieldValues')?.value}}</div>
              </ng-template>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="rejectActions">
            <mat-header-cell *matHeaderCellDef> Reactions</mat-header-cell>
            <mat-cell *matCellDef="let element" [formGroup]="element">
              <ng-container *ngIf="(element.get('check').value && isEditable) else div_rejectActions">
                  <mat-form-field floatLabel="never" appearance="none">
                    <div class="reaction-panel">
                  <mat-select matInput placeholder="Reactions" formControlName="rejectActions" multiple (selectionChange)="selectionChange($event.value,element,'rejectActions','rejectActionsMessage')">
                    <mat-select-trigger>{{getSelectedValue(element, 'rejectActions')}}</mat-select-trigger>
                    <mat-optgroup disabled class="mat-optgroup-header">
                      <div class="header-labels">
                        <div class="default-label">Default</div>
                        <div class="reaction-label">Reactions</div>
                        <div class="message-label">Message Code</div>
                      </div>

                    </mat-optgroup>
                    <mat-option style="margin-left:140px" *ngFor="let item of rejectActionsData" [value]="item.id" [disabled]="element.get('rejectActionsDefault').value === item.id" >
                      <div style="width: 150px;text-overflow: ellipsis;display:flex">
                        <span style="width: 120px;overflow: hidden;text-overflow: ellipsis;" [title]="item.message">{{item.message}}</span>
                        <div  style="padding-left: 5px;" matTooltipClass="tooltip-bg-color" [matTooltip]="item.messageDescription"  matTooltipHideDelay="300"><mat-icon svgIcon="info-circle--s"></mat-icon></div>
                      </div>
                      <mat-radio-button [name]="item.id-element.get('position') " style="position:fixed;margin-left:-170px;padding:0px 30px;" [value]="item.id" [checked]="dataItemChecked(item,element, 'rejectActionsDefault')" [disabled]="!isSelected(item,element, 'rejectActions')"
                      (click)="HandleRadioEvent($event)" (change)="radioChange($event,element, 'rejectActionsDefault')"></mat-radio-button>
                  <!-- this. just changes the default selection -->
                      <span style="padding-left:20px">
                      <a *ngIf="isSelected(item,element, 'rejectActions')"
                      (click)="HandleSearchEvent($event);openDialog(item,'rejectActions',element)">
                      <mat-icon svgIcon="search--s"></mat-icon>
                          <span *ngIf="getMessageCode(element,item,'rejectActionsMessage')">{{getMessageCode(element,item,'rejectActionsMessage')}}</span>
                      </a>
                      </span>
                    </mat-option>
                  </mat-select>
                </div>
                  </mat-form-field>
            </ng-container>
              <ng-template #div_rejectActions>
                <div class="div-reject padding-top-bottom">{{getSelectedValue(element, 'rejectActions')}}</div>

              </ng-template>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="rejectActionValues">
            <mat-header-cell *matHeaderCellDef> Reject Action Values </mat-header-cell>
            <mat-cell *matCellDef="let element" [formGroup]="element">
              <ng-container *ngIf="(element.get('check').value && isEditable) else div_rejectActionValues">
                <mat-form-field floatLabel="never" appearance="none" class=" mat-field-small col-internal-4">
                  <input matInput formControlName="rejectActionValues"  />
                </mat-form-field>

              </ng-container>
              <ng-template #div_rejectActionValues>
                <div class="padding-top-bottom">{{element.get('rejectActionValues')?.value}}</div>
              </ng-template>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="messageCode">
            <mat-header-cell *matHeaderCellDef> Message Code </mat-header-cell>
            <mat-cell *matCellDef="let element" [formGroup]="element">
              <ng-container>
                <div *ngIf="getRejectActionsMessage(element, 'rejectActions')" class="padding-top-bottom">{{getRejectActionsMessage(element, 'rejectActions')}}</div>
              </ng-container>
            </mat-cell>
          </ng-container>


          <ng-container matColumnDef="profile">
            <mat-header-cell *matHeaderCellDef> Profile </mat-header-cell>
            <mat-cell *matCellDef="let element" [formGroup]="element">
              <ng-container *ngIf="(element.get('check').value && isEditable) else div_profile">
                <mat-form-field floatLabel="never" appearance="none" class="col-internal-8">
                  <textarea matInput  cdkTextareaAutosize cdkAutosizeMinRows="1" cdkAutosizeMaxRows="8"
                    formControlName="profile"></textarea>
                </mat-form-field>

              </ng-container>
              <ng-template #div_profile>
                <div class="div-profile padding-top-bottom">{{element.get('profile')?.value}}</div>

              </ng-template>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="memberDetails">
            <mat-header-cell *matHeaderCellDef> Member Details </mat-header-cell>
            <mat-cell *matCellDef="let element" [formGroup]="element">
              <ng-container *ngIf="(element.get('check')?.value && isEditable) else div_memberDetails">
                <mat-form-field floatLabel="never" appearance="none" class="col-internal-8">
                  <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="1" cdkAutosizeMaxRows="8"
                  formControlName="memberDetails"></textarea>
                  <!-- <textarea matInput style="" rows="2" class="text-areas"
                  formControlName="memberDetails"></textarea> -->

                </mat-form-field>

              </ng-container>
              <ng-template #div_memberDetails>
                <div class="div-profile padding-top-bottom">{{element.get('memberDetails')?.value}}</div>
              </ng-template>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="sendToSi">
            <mat-header-cell *matHeaderCellDef> Send To SI </mat-header-cell>
            <mat-cell *matCellDef="let element" [formGroup]="element">
              <ng-container *ngIf="(element.get('check')?.value && isEditable) else div_sendToSi">
                <div class="small-mat">

                  <mat-form-field floatLabel="never" appearance="outline" class="col-internal-8 ">


                    <mat-select  formControlName="sendToSI" >
                      <mat-option *ngFor="let options of sendToSiOption"
                      [value]="options" >{{ options }}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

                </ng-container>
              <ng-template #div_sendToSi>
                <div *ngIf="element && element.get('sendToSI')" class="div-profile padding-top-bottom"> {{element.get('sendToSI')?.value}}</div>
              </ng-template>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="auditHistory">
            <mat-header-cell *matHeaderCellDef> Audit History </mat-header-cell>
            <mat-cell *matCellDef="let element" [formGroup]="element" style="margin-bottom: -25px;">
              <a  style="margin-top:-19px ;"
                (click)="seeHistory(filelayout.controls.indexOf(element))"> See History </a>
            </mat-cell>
          </ng-container>
        </mat-table>
        </cvs-loader-overlay-container>
      </ng-container>
    </form>
    <ng-template #noTable>
      <p>No Records found for this particular record. </p>
    </ng-template>
  </div>

</div>
