
<div class="" [ngStyle]="{'min-width': (providedInfo.type === 'audit_History') ? '25rem' : ''}">
    <h2 mat-dialog-title style="padding-bottom: 1rem; border-bottom :2px solid lightgray">{{providedInfo.title}}</h2>
    
    <ng-container *ngIf="providedInfo.type === 'audit_History' else showHistory">
            <ng-container [formGroup]="form">
            <mat-dialog-content class="" style="min-height:7rem ;">
                <mat-form-field class="col-md-12">
                    <textarea matInput placeholder="Enter Text Here" style="min-height: 10rem;"
                        formControlName="auditComment"></textarea>
                </mat-form-field>
        
        
            </mat-dialog-content>
            <mat-dialog-actions class="row">
                <button class="mat-raised-button col-4" style="padding: 8px 20px;" (click)="close()">Close</button>
                <button class="mat-raised-button pds-btn-primary col-4" style="padding: 8px 20px;" (click)="save()" [disabled] = disableSave>Save</button>
            </mat-dialog-actions>
        </ng-container>
        </ng-container>
        <ng-template #showHistory>
            <mat-dialog-content class="" style="min-height:7rem ;">
                <form [formGroup]="form" autocomplete="off" style="overflow: auto;"
      *ngIf="history">
      <ng-container formArrayName="history">
        <mat-table [dataSource]="history.controls">
          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row;  let i = dataIndex ; columns: displayedColumns;"></mat-row>

          <!--- Note that these columns can be defined in any order.
                  The actual rendered columns are set as a property on the row definition" -->

          <!-- Id Column -->
         
          <!-- User Column -->
          <!-- ['auditComment', 'auditDate', 'auditUser'] -->
          <ng-container matColumnDef="auditDate">
            <mat-header-cell *matHeaderCellDef class="border-side"> Date </mat-header-cell>
            <mat-cell *matCellDef="let element" [formGroup]="element">
              <!-- <mat-form-field floatLabel="never" appearance="none" class="col-internal-6">
                <input matInput formControlName="auditDate" class="dateClass" />
              </mat-form-field> -->
              <div>{{element.get('auditDate').value}}</div>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="auditUser">
            <mat-header-cell *matHeaderCellDef> User </mat-header-cell>
            <mat-cell *matCellDef="let element" [formGroup]="element">
              <!-- <mat-form-field floatLabel="never" appearance="none" class="col-internal-6">
                <input matInput formControlName="auditUser" class= "userClass"/>
              </mat-form-field> -->
              <div>{{element.get('auditUser').value}}</div>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="auditComment">
            <mat-header-cell *matHeaderCellDef> Comment </mat-header-cell>
            <mat-cell *matCellDef="let element" [formGroup]="element">
              <!-- <mat-form-field floatLabel="never" appearance="none" class="col-internal-4">
                <textarea matInput placeholder="Enter Text Here" style="min-width: 20rem;"
                formControlName="auditComment" class="commentClass" ></textarea>
              </mat-form-field> -->
              <div>{{element.get('auditComment').value}}</div>
            </mat-cell>
          </ng-container>
         

          <!--
            memberDetails: new FormControl({value : data.memberDetails ,  disabled: true } , []),
            auditHistory: -->

        </mat-table>
      </ng-container>
    </form>
        
        
            </mat-dialog-content>
            <mat-dialog-actions class="row" style="justify-content: end;">

                <button class="mat-raised-button pds-btn-primary col-4" style="padding: 8px 20px;"
                 (click)="close()">Close</button>
                
            </mat-dialog-actions>
        </ng-template>
    

    </div>