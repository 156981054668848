<ng-container>
    <div class="header">
        <h1>CAG Ref Batch Request</h1>
        <span>
            <mat-icon class="close-btn" svgIcon="close-btn--s" (click)="close()"></mat-icon>
          </span>
    </div>
   
    <cvs-loader-overlay-container [isLoading]="showSpinner | async" loadingMessage="Loading">
    <div class="inner-container" [formGroup]="form">
        <div class="col-md-12">
            <mat-form-field class="col-6">
                <mat-label>CAG Table ID</mat-label>
                <input matInput formControlName="cagTableId">
            </mat-form-field>
        </div>
        <span style="font-size: 12.5px; color: #db3321;" *ngIf="form.get('cagTableId').errors && form.get('cagTableId').touched">Field is required and accepts numbers only</span>
        <div class="col-md-12">
            <mat-form-field class="custom-select col-6">
                <mat-label>Request Type</mat-label>
                <mat-select formControlName="requestType" (selectionChange)="onRequestTypeChange($event.value)">
                    <mat-option [value]="requestType" *ngFor="let requestType of requestTypeList">{{
                        requestType}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        
        <div *ngIf="requestTypeVal !== 'Extract'">
            <div *ngIf="!selectedFile else filename">
                <button class="pds-btn-white"  (click)="file.click()">Upload File</button>
                <input type="file" style="display: none;" accept=".csv,.txt" (change)="uploadFile($event)"  #file>
                <div style="margin:1rem 0;">
                    <p>Upload TXT or CSV file (File name max allowed characters(256))</p>
                </div>
            </div>
               
            <ng-template  #filename>
                <div style="display: flex;" >
                    <mat-icon svgIcon="file-send--s"></mat-icon>
                    <a>{{selectedFile.name}}</a>
                    <mat-icon svgIcon="close-circle--s" (click)="uploadCancel()"></mat-icon>
                </div>
            </ng-template>
        </div>
     
           
          
    </div>
    <mat-dialog-actions class="row mat-bottom-btns">
        <button class="pds-btn-white" (click)="close()" >Cancel</button>
        <button class="pds-btn-primary"  (click)="save()" [disabled]="!form.valid">Submit</button>
    </mat-dialog-actions>
    </cvs-loader-overlay-container>
</ng-container>