<mat-dialog-content class="horizontal-container dialog-header">
  <h2>Add Project</h2>
  <button id="closeBtn" class="cvs-icon-only-btn" mat-dialog-close (click)="closeHandler()">
    <mat-icon svgIcon="close-btn--s"></mat-icon>
  </button>
</mat-dialog-content>

<mat-dialog-content id="bodyTextContent" [formGroup]="addProjectForm">
  <div>
    <mat-form-field appearance="outline">
      <mat-label>Project Type</mat-label>
      <mat-select formControlName="prjTypeToAdd">
        <mat-option *ngFor="let projectType of getProjectType('projectType')"
                    [value]="projectType">{{projectType}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="horizontal-container">
    <mat-form-field  appearance="outline" class="form-field-spacing">
      <mat-label>Start Date</mat-label>
      <input matInput [matDatepicker]="startDteDatePicker" formControlName="prjStartDteToAdd">
      <mat-datepicker-toggle matSuffix [for]="startDteDatePicker"></mat-datepicker-toggle>
      <mat-datepicker #startDteDatePicker></mat-datepicker>
    </mat-form-field>
  </div>

  <div class="horizontal-container">
    <mat-form-field appearance="outline">
      <mat-label>Go-Live Date</mat-label>
      <input matInput [matDatepicker]="goLiveDatePicker" formControlName="prjGoLiveDteToAdd">
      <mat-datepicker-toggle matSuffix [for]="goLiveDatePicker"></mat-datepicker-toggle>
      <mat-datepicker #goLiveDatePicker></mat-datepicker>
    </mat-form-field>
  </div>

  <div class="horizontal-container btn-container">
    <button id="cancelBtn"
            class="pds-btn-white"
            (click)="closeHandler()">Cancel
    </button>

    <button id="submitBtn"
            class="pds-btn-primary"
            (click)="submitNewProject()"
            [disabled]="!addProjectForm.valid"
    >Add
    </button>
  </div>
</mat-dialog-content>
