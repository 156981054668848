import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild, ElementRef} from '@angular/core';
import {Store} from '@ngrx/store';
import {Router} from '@angular/router';
import {AgGridAngular} from 'ag-grid-angular';
import {Observable, Subscription} from 'rxjs';
import {UsersService} from '../../services/users.service';
import {getIsRegisteredUser, getRole, getUserId,
getPermissionsProcessingFilesObject} from '../../reducers';
import * as fromRoot from '../../reducers';
import * as ColumnSets from './myProcessingFilesColumnSets';
import {Permission} from '../../models/Authorization';
import {EprService} from '../../services/epr.service';
import {ThreasholdDialogComponent} from '../../threshold-dialog/threshold-dialog.component';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import {TranslationsService} from '../../services/translations.service';
import {CellRendererComponent} from '../../admin/extract-epr-feed-gui/cell-renderer.component';
import {StatusPopoverComponent} from '../../dashboard/status-popover/status-popover.component';
import { CVSAlertService, CVSAlertType } from 'angular-component-library';
import { ProcessingFileDB } from 'src/app/models/PermissionsObject';

@Component({
  selector: 'app-myprocessingfiles',
  templateUrl: './my-processing-files.component.html',
  styleUrls: ['./my-processing-files.component.scss']
})
export class MyProcessingFilesComponent implements OnInit, AfterViewInit {
  @ViewChild('myprcgrid') myprcgrid: AgGridAngular;
  fileProcessingRow: any;
  fileProcessingColumns: any;

  pliValue: any;
  gridLoaded = false;
  currentUserId: number;
  isAdmin = false;
  userId: any;
  data: any[];
  translationsRecords: any[];
  processFilespermissionObject$ = this.store.select(getPermissionsProcessingFilesObject)
  processFilespermissionObject:any
  defaultMyprocessingFilter = {g5: {filterType: 'text', type: 'notContains', filter: 'Complete'}};
   role: any;
  db: ProcessingFileDB = ProcessingFileDB.ADS;

  constructor(private store: Store<fromRoot.AppState>, private router: Router, private eprService: EprService,private alertService:CVSAlertService,
              private usersService: UsersService, private dialog: MatDialog, private translationsService: TranslationsService) {
  }

  ngOnInit() {
    this.usersService.userRole.subscribe(res=>{
      this.role= res;
    })
    this.store.subscribe(resp => {
      this.pliValue = resp.header.pli;
      this.userId = resp.user.id;
      });
    this.getTranslationsRecords();
    this.processFilespermissionObject$.subscribe(res=>{
      this.processFilespermissionObject = res
    })
 }

  getTranslationsRecords() {
    this.translationsService.getTranslations().subscribe(response => {
      this.translationsRecords = response;
      if (response) {
        this.createThresholdData();
      }
    });
  }

  colorInRowsForProcessingFiles = (params) => {
    const pgCriteriaField =
      this.getPGCriteriaField(Object.keys(params.data));

    let returnValue = null;
    if (
      params.data.tRecord &&
      params.data.tRecord.status &&
      params.data.tRecord.status.trim() === 'Error'
    ) {
      returnValue = this.isNotToShowBold(params.data[pgCriteriaField])
        ? { 'background-color': '#fae6e6', 'font-weight': 700 }
        : { 'background-color': '#fae6e6' };
    } else if (
      params.data.tRecord &&
      params.data.tRecord.status &&
      params.data.tRecord.status.trim() === 'Pending'
    ) {
      returnValue = this.isNotToShowBold(params.data[pgCriteriaField])
        ? { 'background-color': '#fff6d0', 'font-weight': 700 }
        : { 'background-color': '#fff6d0' };
    } else if (
      params.data.tRecord &&
      params.data.tRecord.status &&
      params.data.tRecord.status.trim() === 'Processing'
    ) {
      returnValue = this.isNotToShowBold(params.data[pgCriteriaField])
        ? { 'background-color': '#b0ebb4', 'font-weight': 700 }
        : { 'background-color': '#b0ebb4' };
    } else if (this.isNotToShowBold(params.data[pgCriteriaField])) {
      returnValue = { 'font-weight': 700 };
    }

    return  returnValue;
  }

  isNotToShowBold(rowValue) {
    //console.log(rowValue)
      if (
        rowValue !== 'Undefined' &&
        rowValue !== undefined &&
        rowValue !== null &&
        rowValue !== 'N/A' &&
        rowValue.trim() !== '') {
        return true;
      }
    return false;
  }

  getPGCriteriaField(x) {
    const gridApi = this.myprcgrid.api;
    return x.filter(el => gridApi.getColumnDef(el) ? gridApi.getColumnDef(el).headerName === 'PG Criteria' : null)[0];
  }


  ngAfterViewInit(): void {
     this.myprcgrid.api.setGridOption("columnDefs", ColumnSets.myProcessingFilesColumns);
     this.myprcgrid.api.setGridOption("getRowStyle", this.colorInRowsForProcessingFiles);
     this.myprcgrid.api.setGridOption("tooltipShowDelay", 0);
     //this.myprcgrid.api.setFilterModel(this.defaultMyprocessingFilter);

    // this.createThresholdData();
    // this.thresholdGrid.api.sizeColumnsToFit();
  }

  private setStatusToolTip(rowData: any) {
    let updatedRows = [];
    if (this.translationsRecords && this.translationsRecords.length > 0) {
      this.translationsRecords = this.translationsRecords.sort((a, b) => a.translationId - b.translationId);
      this.translationsRecords.forEach((tRecord, i) => {
        rowData.forEach((rRecord) => {
          if (rRecord.g12 === tRecord.input.trim()) {
            rRecord.tRecord = tRecord;
            rRecord.progressBarDetails = {
              outputText: tRecord.output !== null ? tRecord.output.trim() : '',
              softText: tRecord.softText !== null ? tRecord.softText.trim() : '',
              totalTranslations: this.translationsRecords.length,
              rowSequence: Number(i) + 1,
              statusColour: this.getProgressBarColor(tRecord.status)[0],
              statusBorderColour: this.getProgressBarColor(tRecord.status)[1]
            };
          }
          rRecord.translationsRecords = this.translationsRecords;
        });
      });
      updatedRows = rowData;
    }
    return updatedRows;
  }

  getProgressBarColor(status) {
      let color = '';
      let statusBorder = '';
      if (status === 'Pending') {
        color = '#AD8E03';
        statusBorder = 'grey';
      } else if (status === 'Processing') {
        color = '#006400';
        statusBorder = 'darkseagreen';
      } else if (status === 'Error') {
        color = '#CC0000';
        statusBorder = 'lightgrey';
      }
      return [color, statusBorder];
    }

  showErrorMessage(ErrorMsg) {
    this.alertService.sendAlert({
      headline: "Error occured while fetching Processing files", body: "Processing files API failed for "+ ErrorMsg,
      alertType: CVSAlertType.Error, removedAfterMilliseconds: 5000,
      alertDocumentHolderName: '#epr-alert',
    });
    document.getElementById('epr-alert').scrollIntoView();
  }
  private createThresholdData() {
      const fixFilter = { g1: {filterType: 'text', type: 'equals', filter: this.pliValue}};
      const filter = { ...fixFilter };
      if (this.pliValue!== null) {
        this.eprService.getAllCurrentFilesByFilter(filter, this.userId, this.eprService.includeBackup,this.db).subscribe(
          rowData => {
            if(rowData.errorResp) {
              this.showErrorMessage(rowData.errorResp);          
            }
            let response= rowData.response?rowData.response:[];
            if (rowData) {
              this.data = response;
              this.data = this.setStatusToolTip(this.data);
              this.gridLoaded = true;
            }
          }, error => {
            this.data = [];
            this.gridLoaded = true;
          });
      } else {
        this.myprcgrid.api.setGridOption("rowData", []);
      }
  }


  onFilterChange(event: any) {
    if (this.gridLoaded) {
      const filterObject = event.api.getFilterModel();
      const fixFilter = {g1: {filterType: 'text', type: 'equals', filter: this.pliValue}};
      const filter = {...filterObject, ...fixFilter };
      this.eprService.getAllCurrentFilesByFilter(filter, this.userId, this.eprService.includeBackup,this.db).subscribe(
        rowData => {
          if(rowData.errorResp) {
            this.showErrorMessage(rowData.errorResp);          
          }
          let response= rowData.response?rowData.response:[];
        this.data = response;
        this.data = this.setStatusToolTip(this.data);
        }, error => {
            this.data = [];
          });
      }
  }

  onRowClicked($event: any) {
    if(this.role && (this.role === Permission.FILETRACKER || this.role === Permission.VISITOR || this.role ===
    Permission.SPEDM)){
      return;
    }
    let obj = this.processFilespermissionObject
    if((obj["openthresholdModal"] && obj["pbmRole"])){
      const dialogRef = this.dialog.open(ThreasholdDialogComponent, {
        width: '70%',
        height: '90%',
        panelClass: 'my-dialog',
        data: {
          selected: 'Processing Files',
          body: $event.data,
          db: this.db,
        }

      });

      dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      });
    }else{
      return
    }

    }

    
dbToggle(event){
  this.onFilterChange(this.myprcgrid)
}
}
