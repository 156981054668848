import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import {DataManagementService} from "../../../services/data-management.service";

@Component({
  selector: 'app-add-edit-fieldlookup-popup',
  templateUrl: './add-edit-relationshipCode-item-popup.component.html',
  styleUrls: ['./add-edit-relationshipCode-item-popup.component.scss']
})
export class AddEditFieldLookupPopupComponent {
  public value: string;
  public description: string;
  public dataValues: any = {}
  public isEditMode: boolean = false;
  public title: string = "";
  addEditItem: UntypedFormGroup;
  headerInfo: any;
  valueInfo: any;
  type: any;
  directions;
  rows;
  disableSave: boolean = false;
  constructor(private fb: UntypedFormBuilder, @Inject(MAT_DIALOG_DATA) public data:any , public dialogRef: MatDialogRef<AddEditFieldLookupPopupComponent>, private dataManagementService:DataManagementService) {
    this.dataValues= data;
    this.headerInfo= data.headerInfo;
    this.valueInfo= data.valueInfo;
    this.type= data.type;
    this.isEditMode = (this.dataValues?.mode)?.includes("Edit");
    this.directions= `Input the following to ${this.isEditMode ? 'edit':'create'} ${this.isEditMode ? (['a','i','e','o','u'].includes(this.valueInfo[0].toLowerCase())?'an': 'a'):'a new'} ${this.valueInfo}`
    this.value= this.isEditMode? this.dataValues.message : '';
    this.description = this.isEditMode ? this.dataValues.messageDescription :'';
    this.rows = data?.rows
    this.createForm();

  }

  createForm() {
    this.addEditItem = this.fb.group({
      value: [{value:this.value,disabled:this.isEditMode}],
      description: [this.description, Validators.required]
    })
    if (this.dataValues.hasOwnProperty('rows')) {
      this.addEditItem.controls.value.valueChanges.subscribe(res => {
        let found = this.rows.filter(row => row.message === res)
        let control = this.addEditItem.controls.value
        if (found[0]) {
          this.disableSave = true
          control.setErrors({ 'duplicate': true })
        } else {
          this.disableSave = false
          if (control.hasError('duplicate')) {
            delete control.errors.duplicate
            control.updateValueAndValidity({emitEvent: false})
          }
        }
      })
    }
  }
  save(): void{
    if(this.addEditItem.valid) {
      this.value =  this.addEditItem.get('value')?.value;
      this.description = this.addEditItem.get('description')?.value;
      const newValidationRow = [{
        id:  this.isEditMode ? this.dataValues.id: null,
        message: this.value,
        type:this.type,
        messageDescription: this.description,
        sortOrder: null,
        reportType: null,
        reportMessageType: null,
        reportMessageCodesTypeValue: null

      }];
      this.dialogRef.close(newValidationRow);
    }
  }
  deleteRecord(){
    this.dialogRef.close(this.dataValues.actionType);
  }

}
