import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { FileLayoutService } from '../file-layout.service';
@Component({
  selector: 'app-file-layout-dialog',
  templateUrl: './file-layout-dialog.component.html',
  styleUrls: ['./file-layout-dialog.component.scss']
})
export class FileLayoutDialogComponent implements OnInit {
  form: UntypedFormGroup;
  description: string;
  providedInfo: any;
  rejectActionsData: any=[];
  validationsData: any =[];
  fieldActionsData: any = [];
  constructor(
      private fb: UntypedFormBuilder,
      private dialogRef: MatDialogRef<FileLayoutDialogComponent>,
      @Inject(MAT_DIALOG_DATA) data ,
      private flService: FileLayoutService
      ) {
      this.description = data.description;
      this.providedInfo = data;
      this.rejectActionsData = data.rejectActionsData;
      this.validationsData = data.validationsData;
      this.fieldActionsData = data.fieldActionsData;
  }

  ngOnInit() {

    if (this.providedInfo.type === 'add_record' || this.providedInfo.type === 'rename_record') {
        this.form = this.fb.group({
            recordType: ['', [Validators.required]],
            recordName: ['', [Validators.required]],
            fileType: ['', [Validators.required]],
            includeInSsoe: ['', [Validators.required]],
        });
        if (this.providedInfo.type === 'rename_record') {
        this.form.patchValue(this.providedInfo.currentRecordData , {emitEvent: false});
    }

    } else if (this.providedInfo.type === 'add_file_layout') {

        this.form = this.fb.group({
            fieldName: ['', [Validators.required]],
            fieldNumber: ['', [ Validators.required]],
            position: ['', [Validators.required ]],
            length: ['', [Validators.required ]],
            use: ['', [ ]],
            type: ['', [ ]],
            fieldInformation: ['', [ ]],
            internalNotes: ['', [ ]],
            rejectLevel: ['', [ ]],
            actionValues: ['', [ ]],
            fieldValidation:[[],[]],
            validationDefault:['',[]],
            fieldValues: ['', []],
            fieldActions:[[],[]],
            fieldActionsDefault:['',[]],
            rejectActions:[[],[]],
            rejectActionsDefault:['',[]],
            rejectActionValues:['',[]],
            profile: ['', [ ]],
            memberDetails: ['', [ ]],
            fileAudit: ['', [ ]],

        });

    }
  }

  save() {
    if (!this.form.invalid) {
     
      let payload= this.form.value;
      payload.rejectActions?.map(t=> {
        t.default= t.id === payload.rejectActionsDefault;
      });
      payload.fieldValidation?.map(t=> {
        t.default= t.id === payload.validationDefault;
      });
      payload.fieldActions?.map(t=> {
        t.default= t.id === payload.fieldActionsDefault;
      });
        this.dialogRef.close(this.form.value);
    } else {
        this.form.markAllAsTouched();
    }

  }

  close() {
      this.dialogRef.close(null);
  }

  ischeckBoxChecked(item: any, defaultField) {
    const defaultId = this.form.get(defaultField).value;
    return item.id=== defaultId;
  }

  HandleRadioEvent($event: any) {
    $event.stopPropagation();
  }
  isSelected(item:any, fieldName){
    return this.form.get(fieldName).value.includes(item)
  }

  radioChange($event: any, defaultField) {
    this.form.get(defaultField).setValue($event.value);
  }

}
