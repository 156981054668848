import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import {DataManagementService} from "../../../services/data-management.service";
import {MessageSupressionService} from "../../../services/message-supression.service";

@Component({
  selector: 'app-detail-messages-modal',
  templateUrl: './detailMessageModal.component.html',
  styleUrls: ['./detailMessageModal.component.scss']
})
export class DetailMessageModalComponent implements OnInit {
  selectedMessage: any;
  messages:any=[];
  filteredMessages: any;
  boxesChecked =  false;
    constructor(private fb: UntypedFormBuilder, @Inject(MAT_DIALOG_DATA) public data:any , public dialogRef: MatDialogRef<DetailMessageModalComponent>,
                private msgSupressService: MessageSupressionService) {

    }
  ngOnInit() {
          this.msgSupressService.getReportingMessageTypesRows().subscribe(res1 => {

            this.messages = res1.filter(message=>message.reportMessageType !== "Group");
            this.filteredMessages = this.messages
            setTimeout(() => {
              let inputs = document.getElementsByTagName("input");


             for (var i = inputs.length -1 ; i>= 0; i--)
              if (inputs[i].type === "checkbox"){
                if(this.data.filter(mess=>mess.message === inputs[i].value).length > 0){
                  inputs[i].checked = true;
                  this.boxesChecked =  true;
                }
              }
            }, 100);

        });
      }

  selectAll(){
    let inputs = document.getElementsByTagName("input");

    for (var i = inputs.length -1 ; i>= 0; i--){
      if (inputs[i].type === "checkbox"){
          inputs[i].checked = true;
        }
    }
    this.boxesChecked =  true;
  }
  clearSelection(){
    let inputs = document.getElementsByTagName("input");

    for (var i = inputs.length -1 ; i>= 0; i--){
      if (inputs[i].type === "checkbox" && inputs[i].checked){
          inputs[i].checked = false;
        }
    }
    this.boxesChecked =  false;
  }

  onCancel(event:any) {
    this.dialogRef.close();
    event.stopPropagation();
  }
  closeModal(event:any) {
    this.dialogRef.close();
    event.stopPropagation();
  }
  onSelect(event:any) {
    let selectedValues = [],
    inputs = document.getElementsByTagName("input");

    for (var i = inputs.length -1 ; i>= 0; i--){
      if (inputs[i].type === "checkbox" && inputs[i].checked){

        this.messages.filter(message=>{
         if(message.message === inputs[i].value){
          message.msgId = message.id;
          message.id = null;
          return selectedValues.push(message)
         }})

      }
    }

    this.dialogRef.close(selectedValues);
    event.stopPropagation();
  }
  onChange(){
    let inputs = document.getElementsByTagName("input");
    for (var i = inputs.length -1 ; i>= 0; i--){
      if (inputs[i].type === "checkbox" && inputs[i].checked){
        this.boxesChecked =  true;
        return
      }else if(inputs[i].type === "checkbox" && !inputs[i].checked){
        this.boxesChecked =  false;
      }
    }
  }

  filterData(event: any) {
    let searchTerm = event.target.value?.toLowerCase();
    this.filteredMessages = this.messages
    if (searchTerm != undefined) {
      //"message","reportType","messageDescription"
      this.filteredMessages= this.filteredMessages.filter((msg) =>{
        if(
        msg.message?.toLowerCase().includes(searchTerm) ||
        msg.reportType?.toLowerCase().includes(searchTerm )||
        msg.messageDescription?.toLowerCase().includes(searchTerm )
      ){
        return msg;
      }
      })

    }
  }
}
