import { DeleteBtnCellRendererComponent } from "src/app/common/DeleteBtnCellRenderer";

export const FiletrackerColumns = [

  {
    headerName: 'Setup Id',
    field: 'setupId',
    hide: true,
  },
  {
    headerName: 'Contact Id',
    field: 'contactId',
    hide: true,
    // suppressToolPanel: true
  },
  {
    headerName: 'Email Id',
    field: 'emailId',
    hide: true,
  },
  {
        headerName: 'Email Id',
        headerClass: 'bg-master',
        field: 'cmnctTypeDesc',
        sortable: true,
        filter: true,
        lockVisible: true,
        lockPosition: true,
        editable: true,
        width: 20,
  },
  {
    headerName: 'Alert Type',
    headerClass: 'bg-master',
    field: 'alertTypeCd',
    sortable: true,
    filter: true,
    lockVisible: true,
    lockPosition: true,
    cellRenderer: 'genderCellRenderer',
    cellEditor: 'agSelectCellEditor',
    cellEditorParams: {
      values: ['Email', 'Mobile Email'],
      cellRenderer: 'genderCellRenderer',
    },
    width: 20,
  },
  {
    headerName: 'Communication Type',
    headerClass: 'bg-master',
    field: 'cmnctTypeCd',
    sortable: true,
    filter: true,
    lockVisible: true,
    lockPosition: true,
    cellRenderer: 'genderCellRenderer',
    cellEditor: 'agSelectCellEditor',
    cellEditorParams: {
      values: ['External', 'Internal'],
      cellRenderer: 'genderCellRenderer',
    },
    width: 20,
  },
  {
    headerName: 'Status',
    headerClass: 'bg-master',
    field: 'statusCd',
    sortable: true,
    filter: true,
    lockVisible: true,
    lockPosition: true,
    cellRenderer: 'genderCellRenderer',
    cellEditor: 'agSelectCellEditor',
    cellEditorParams: {
      values: ['Active', 'Inactive'],
      cellRenderer: 'genderCellRenderer',
    },
    width: 20,
  },
  {
    headerName: 'Action',
    cellRenderer: DeleteBtnCellRendererComponent,
    editable: false,
    width: 100,
  },
];

