// import { StatusPopoverComponent } from './status-popover/status-popover.component';
/* eslint-disable max-len */
import { ICellRendererParams } from 'ag-grid-community';
import { BackupEcCellRendererComponent } from '../common/BackupEcCellRenderer';
import { ToolTipComponent } from "./tool-tip/tool-tip.component";
import { EligibilityBtnCellRendererComponent } from '../common/EligibilityBtnCellRender';
import * as moment from 'moment';

export const tbaColumns = [
  { headerName: 'Client Name', field: 'clientName', sortable: true, filter: true, resizable: true, filterParams: { newRowsAction: 'keep',  buttons: ["apply", "reset"] } },
  { headerName: 'Carrier', field: 'carrier', sortable: true, filter: true, resizable: true, filterParams: { newRowsAction: 'keep',  buttons: ["apply", "reset"] }},
  { headerName: 'PLI', field: 'pli', sortable: true, filter: true, resizable: true,filterParams: { newRowsAction: 'keep',  buttons: ["apply", "reset"] } },
  { headerName: 'EC', field: 'activeEc', sortable: true, filter: true,filterParams: { newRowsAction: 'keep',  buttons: ["apply", "reset"] }, resizable: true,
  cellRendererParams: (params) => ({
    activeEc: params.data.activeEc,
    ecOOOFromDate: params.data.ecOOOFromDate,
    ecOOOFromDateTimestamp: params.data.ecOOOFromDateTimestamp,
    ecOOOToDate: params.data.ecOOOToDate,
    ecOOOToDateTimestamp: params.data.ecOOOToDateTimestamp,
    backupName: params.data.backupToEc
  }),
  cellRenderer: BackupEcCellRendererComponent },
  {
    headerName: 'Status',
    field: 'status',
    sortable: true,
    filter: true,
    resizable: true,
    filterParams: { newRowsAction: 'keep',  buttons: ["apply", "reset"] },
    cellRenderer: params => {
      const currentValue = params.data.status;
      if (currentValue === "Exceeded") {
        const progressBarPercent = 50;
        const progressBar = '<div>' + currentValue + '</div>' +
          '<div style="border-radius: 6px; width: 100%; margin-top: -6%; background-color:' + "#cc0000" + '40;">' +
          '<div style="background-color: ' + "#cc0000" + '; ' +
          'border-radius: 2px; padding: 1%; width: ' + progressBarPercent + '%;">' +
          '</div>' +
          '</div>';
        return progressBar;
      } else if (currentValue === "Complete") {
        const progressBarPercent = 100;
        const progressBar = '<div>' + currentValue + '</div>' +
          '<div style="border: 0.1px solid #000000;border-radius: 6px; width: 100%; margin-top: -6%; background-color:' + "#000000" + '40;">' +
          '<div style="background-color: ' + "#000000" + '; ' +
          'border-radius: 2px; padding: 1%; width: ' + progressBarPercent + '%;">' +
          '</div>' +
          '</div>';
        return progressBar;
      } else {
        return params.value;
      }
    }
  },
  {
    headerName: 'Comments',
    field: 'comment',
    sortable: true,
    filter: true,
    resizable: true,
    filterParams: {newRowsAction: 'keep',  buttons: ["apply", "reset"]},
    cellRenderer: ToolTipComponent,
    cellRendererParams: (params: ICellRendererParams) => params.data
  },
  {
    headerName: 'File Name',
    cellRenderer: params => {
      return params.data.fileName? params.data.fileName : 'Manual' 
    },
    field: 'fileName', sortable: true, filter: true, resizable: true, filterParams: { newRowsAction: 'keep' }
  },
  { headerName: 'Terms Applied', field: 'termsApplied', sortable: true, filter: true,filterParams: { newRowsAction: 'keep',  buttons: ["apply", "reset"] }, resizable: true },
  { headerName: 'TBA Flag', field: 'tbaFlag', sortable: true, filter: true,filterParams: { newRowsAction: 'keep',  buttons: ["apply", "reset"] }, resizable: true },
  { headerName: 'TBA Date', field: 'tbaDate', sortable: true, filter: true, filterParams: { newRowsAction: 'keep',  buttons: ["apply", "reset"] },resizable: true },
  { headerName: 'Threshold CNT', field: 'thresholdCNT', sortable: true, filter: true, filterParams: { newRowsAction: 'keep',  buttons: ["apply", "reset"] },resizable: true },
  { headerName: 'Threshold PCT', field: 'thresholdPCT', sortable: true, filter: true, filterParams: { newRowsAction: 'keep',  buttons: ["apply", "reset"] },resizable: true },
  {
    headerName: 'Calculated Terms',
    cellRenderer: params => {
      return params.data.actualTerms < params.data.calculatedTerms ? params.data.calculatedTerms + '*': params.data.calculatedTerms;
    },
    field: 'calculatedTerms', sortable: true, filter: true, resizable: true, filterParams: { newRowsAction: 'keep' }
  },
  { headerName: 'Actual Terms', field: 'actualTerms', sortable: true, filter: true,filterParams: { newRowsAction: 'keep',  buttons: ["apply", "reset"] }, resizable: true },
  { headerName: 'Range/List', field: 'rangeList', sortable: true, filter: true,filterParams: { newRowsAction: 'keep',  buttons: ["apply", "reset"] }, resizable: true },
  { headerName: 'TBA Protected', field: 'tbaProtected', sortable: true, filter: true,filterParams: { newRowsAction: 'keep',  buttons: ["apply", "reset"] }, resizable: true },
  { headerName: 'Batch Override Protected', field: 'batchOverrideProtected', sortable: true, filter: true,filterParams: { newRowsAction: 'keep',  buttons: ["apply", "reset"] }, resizable: true },
  { headerName: 'Process Date', field: 'processDate', sortable: true, filter: true,filterParams: { newRowsAction: 'keep',  buttons: ["apply", "reset"] }, resizable: true },
  {
    headerName: 'Lapse',
    field: 'lapse',
    sortable: true,
    filter: false,
    resizable: true,
    filterParams: { newRowsAction: 'keep',  buttons: ["apply", "reset"] },
    width: 250,
  },
];
export const ftOnboardingEligibilityColumns = [
  // {headerName: 'cltSetupID', field: 'cltSetupID', sortable: true, filter: true, resizable: true},
  {
    headerName: 'Active/Inactive', field: 'ActiveorInactive',
    sortable: true, filter: 'agTextColumnFilter', resizable: true,
    cellStyle: params => {
      if (params.value === 'I') {
        return { color: '#808080' };
      } else {
        return { color: '#000000' };
      }
    }
  },
  { headerName: 'PLI Code', field: 'pliCode', sortable: true, filter: true, resizable: true },
  {
    headerName: 'EC',
    field: 'ec',
    sortable: true,
    filter: true,
    resizable: true,
    cellRendererParams: (params) => ({
      activeEc: params.data.activeEc,
      ecOOOFromDate: params.data.ecOOOFromDate,
      ecOOOFromDateTimestamp: params.data.ecOOOFromDateTimestamp,
      ecOOOToDate: params.data.ecOOOToDate,
      ecOOOToDateTimestamp: params.data.ecOOOToDateTimestamp,
      backupName: params.data.backupToEc
    }),
    cellRenderer: BackupEcCellRendererComponent
  },
  { headerName: 'Client ID', field: 'clientId', sortable: true, filter: true, resizable: true },
  { headerName: 'Submitter ID', field: 'submitterId', sortable: true, filter: true, resizable: true },
  { headerName: 'Client Name', field: 'clientName', sortable: true, filter: true, resizable: true },
  { headerName: 'File Route', field: 'fileRoute', sortable: true, filter: true, resizable: true },
  { headerName: 'File Type', field: 'fileType', sortable: true, filter: true, resizable: true },
  { headerName: 'File Name Pattern', field: 'fileTypeNamePattern', sortable: true, filter: true, resizable: true },
  { headerName: 'PG Contract', field: 'pgContract', sortable: true, filter: true, resizable: true },
  {
    headerName: 'PG Turn Around Time LVL 1',
    field: 'pgTurnAroundTimeLvl1',
    sortable: true,
    filter: true,
    resizable: true
  },
  { headerName: 'PG Percentage LVL 1', field: 'pgPercentageLv1', sortable: true, filter: true, resizable: true },
  {
    headerName: 'PG Turn Around Time LVL 2',
    field: 'pgTurnAroundTimeLvl2',
    sortable: true,
    filter: true,
    resizable: true
  },
  { headerName: 'PG Percentage LVL 2', field: 'pgPercentageLv2', sortable: true, filter: true, resizable: true },
  {
    headerName: 'PG Load Reporting Turn Around Time', field: 'pgLoadReportingTurnAroundTime',
    sortable: true, filter: true, resizable: true
  },
  { headerName: 'PG Period', field: 'pgPeriod', sortable: true, filter: true, resizable: true },
  {
    headerName: 'PG File Critical Client In Effect',
    field: 'pgFileCriticalClientInEffect',
    sortable: true,
    filter: true,
    resizable: true
  },
  {
    headerName: 'PG Load Reporting In Effect',
    field: 'pgLoadReportingInEffect',
    sortable: true,
    filter: true,
    resizable: true
  },
  { headerName: 'PG Days Allocation', field: 'pgDaysAllocation', sortable: true, filter: true, resizable: true },
  {
    headerName: 'Call Alert Receiver Group',
    field: 'callAlertReceiverGroup',
    sortable: true,
    filter: true,
    resizable: true
  },
  {
    headerName: 'Successful Receipt Alert',
    field: 'successfulReceiptAlert',
    sortable: true,
    filter: true,
    resizable: true
  },
  {
    headerName: 'Not Processed Alert',
    cellStyle: row => {
      if (row.data.notProcCallIn) {
        return { color: 'red' };
      }
    },
    field: 'notProcessedAlert', sortable: true, filter: true, resizable: true
  },
  {
    headerName: 'Not Processed 1st Alert %',
    cellStyle: row => {
      if (row.data.notProcCallIn) {
        return { color: 'red' };
      }
    },
    field: 'notProcessedfirstAlert', sortable: true, filter: true, resizable: true
  },
  {
    headerName: 'PG Load Reporting Alert',
    cellStyle: row => {
      if (row.data.missedPgLoadReportCallIn) {
        return { color: 'red' };
      }
    },
    field: 'pgLoadReportingAlert', sortable: true, filter: true, resizable: true
  },
  {
    headerName: 'PG Load Reporting 1st Alert %',
    cellStyle: row => {
      if (row.data.missedPgLoadReportCallIn) {
        return { color: 'red' };
      }
    },
    field: 'pgLoadReportingfirstAlert', sortable: true, filter: true, resizable: true
  },
  { headerName: 'Missing Files Alert', field: 'missingFilesAlert', sortable: true, filter: true, resizable: true },
  {
    headerName: 'Processing Error Alert',
    cellStyle: row => {
      if (row.data.procErrorCallIn) {
        return { color: 'red' };
      }
    },
    field: 'processingErrorAlert', sortable: true, filter: true, resizable: true
  },
  {
    headerName: 'Multiple Expected Files Alert',
    field: 'multipleExpectedFilesAlert',
    sortable: true,
    filter: true,
    resizable: true
  },
  { headerName: 'System', field: 'system', sortable: true, filter: true, resizable: true },
  {
    headerName: 'Note', field: 'note', sortable: true, filter: true, resizable: true,
    tooltipField: 'note',
    tooltipComponentParams: { color: '#ececec' }
  },
  ];





export const ftFileMonitorEligibilityColumns = [
  // {headerName: 'File Tracker Details', field: 'pliCode', sortable: true, filter: true, resizable: true},
  { headerName: 'Completed', field: 'completed', sortable: true, filter: true, resizable: true },
  { headerName: 'Client ID', field: 'clientId', sortable: true, filter: true, resizable: true },
  {
    headerName: 'Client Name', field: 'clientName', sortable: true, filter: true, resizable: true,
    filterParams: { newRowsAction: 'keep' },
  },
  { headerName: 'Submitted ID', field: 'submittedId', sortable: true, filter: true, resizable: true },
  { headerName: 'PLI Name', field: 'pliName', sortable: true, filter: true, resizable: true },
  { headerName: 'Dest Client ID', field: 'destClientId', sortable: true, filter: true, resizable: true },
  { headerName: 'Start Time', field: 'startTime', sortable: true, filter: true, resizable: true },
  { headerName: 'RX Claim End Time ', field: 'fileEndTime', sortable: true, filter: true, resizable: true },
  { headerName: 'End Time', field: 'endTime', sortable: true, filter: true, resizable: true },
  { headerName: 'File Type', field: 'fileType', sortable: true, filter: true, resizable: true },
  { headerName: 'File From Name', field: 'fileFromName', sortable: true, filter: true, resizable: true },
  { headerName: 'Rx Claim File Name', field: 'fileName', sortable: true, filter: true, resizable: true },
  { headerName: 'File Outbound', field: 'fileOutbound', sortable: true, filter: true, resizable: true },
  { headerName: 'Audit Number', field: 'auditnumber', sortable: true, filter: true, resizable: true },
  { headerName: 'Error Type', field: 'errorType', sortable: true, filter: true, resizable: true },
];

export const ftOnboardingSpedmColumns = [
  // {headerName: 'cltSetupID', field: 'cltSetupID', sortable: true, filter: true, resizable: true},
  { headerName: 'Client Receiver', field: 'clientReceiver', sortable: true, filter: true, resizable: true },
  { headerName: 'Client Name', field: 'clientName', sortable: true, filter: true, resizable: true },
  { headerName: 'Drug Name', field: 'drugName', sortable: true, filter: true, resizable: true },
  { headerName: 'File Type', field: 'fileType', sortable: true, filter: true, resizable: true },
  { headerName: 'File Name Mask', field: 'filenameMask', sortable: true, filter: true, resizable: true },
  {
    headerName: 'Active/InActive Status',
    field: 'activeorInactiveStatus',
    sortable: true,
    filter: true,
    resizable: true
  },
  { headerName: 'Report Frequency', field: 'reportFrequency', sortable: true, filter: true, resizable: true },
  { headerName: 'Report Frequency Value', field: 'reportFrequencyVal', sortable: true, filter: true, resizable: true },
  {
    headerName: 'Contracted Delivery Time(ET)',
    field: 'contractedDeliveryTime',
    sortable: true,
    filter: true,
    resizable: true
  },
  {
    headerName: 'Late File Warning Time(ET)',
    field: 'lateFileWarningTime',
    sortable: true,
    filter: true,
    resizable: true
  },
  { headerName: 'Frequency of Alerts', field: 'alertFrequency', sortable: true, filter: true, resizable: true },
  { headerName: 'Late File Warning Alert', field: 'lateFileWarningAlert', sortable: true, filter: true, resizable: true },
  { headerName: 'Missed File SLA Alert', field: 'missedFileSLAAlert', sortable: true, filter: true, resizable: true },
  {
    headerName: 'Note', field: 'note', sortable: true, filter: true, resizable: true,
    tooltipField: 'note',
    tooltipComponentParams: { color: '#ececec' }
  },
];

export const ftFileMonitorSpedmColumns = [
  // {headerName: 'File Tracker Details', field: 'pliCode', sortable: true, filter: true, resizable: true},
  { headerName: 'Global ID', field: 'globalId', sortable: true, filter: true, resizable: true },
  { headerName: 'Receiver ID', field: 'receiverId', sortable: true, filter: true, resizable: true },
  { headerName: 'File Route', field: 'fileRoute', sortable: true, filter: true, resizable: true },
  { headerName: 'Manufacturer', field: 'manufacturer', sortable: true, filter: true, resizable: true },
  { headerName: 'Drug name', field: 'drugName', sortable: true, filter: true, resizable: true },
  { headerName: 'File Type', field: 'fileType', sortable: true, filter: true, resizable: true },
  { headerName: 'File name pattern', field: 'fileNamePattern', sortable: true, filter: true, resizable: true },
  { headerName: 'Report Frequency', field: 'reportFrequency', sortable: true, filter: true, resizable: true },
  { headerName: 'Email Alt', field: 'emailAlt', sortable: true, filter: true, resizable: true },
  { headerName: 'CreationTime', field: 'creationTime', sortable: true, filter: true, resizable: true },
  { headerName: 'Completed', field: 'complete', sortable: true, filter: true, resizable: true },
];

export const eprColumns = [
  {
    headerName: 'Client Name',
    field: 'e1',
    sortable: true,
    filter: true,
    resizable: true,
    filterParams: { newRowsAction: 'keep' }
  },
  {
    headerName: 'Carrier',
    field: 'e2',
    sortable: true,
    filter: true,
    resizable: true,
    filterParams: { newRowsAction: 'keep' }
  },
  {
    headerName: 'PLI',
    field: 'e3',
    sortable: true,
    filter: true,
    resizable: true,
    filterParams: { newRowsAction: 'keep' }
  },
  {
    headerName: 'EC',
    field: 'e4',
    sortable: true,
    filter: true,
    resizable: true,
    filterParams: { newRowsAction: 'keep' },
    cellRendererParams: (params) => ({
      activeEc: params.data.activeEc,
      ecOOOFromDate: params.data.ecOOOFromDate,
      ecOOOFromDateTimestamp: params.data.ecOOOFromDateTimestamp,
      ecOOOToDate: params.data.ecOOOToDate,
      ecOOOToDateTimestamp: params.data.ecOOOToDateTimestamp,
      backupName: params.data.backupToEc
    }),
    cellRenderer: BackupEcCellRendererComponent
  },
  {
    headerName: 'Eligibility Type',
    field: 'e5',
    sortable: true,
    filter: true,
    resizable: true,
    filterParams: { newRowsAction: 'keep' }
  },
  {
    headerName: 'FTP ID',
    field: 'e6',
    sortable: true,
    filter: true,
    resizable: true,
    filterParams: { newRowsAction: 'keep' }
  },
  {
    headerName: 'Vendor',
    field: 'e7',
    sortable: true,
    filter: true,
    resizable: true,
    filterParams: { newRowsAction: 'keep' }
  },
  {
    headerName: 'PG Criteria',
    field: 'e8',
    sortable: true,
    filter: true,
    resizable: true,
    filterParams: { newRowsAction: 'keep' }
  },
  {
    headerName: 'SSOE Migrated',
    field: 'e9',
    sortable: true,
    filter: true,
    resizable: true,
    filterParams: { newRowsAction: 'keep' }
  },
  {
    headerName: 'File Format',
    field: 'e10',
    sortable: true,
    filter: true,
    resizable: true,
    filterParams: { newRowsAction: 'keep' }
  },
  {
    headerName: 'Client Code',
    field: 'clientCode',
    sortable: true,
    filter: true,
    resizable: true,
    filterParams: { newRowsAction: 'keep' }
  },
  {
    headerName: 'Eligibility Agreement',
    field: 'download',
    sortable: true,
    cellStyle:{"display": "flex",
  "justify-content": "center"
},
    filter: true,
    resizable: true,
    cellRenderer: EligibilityBtnCellRendererComponent,
    filterParams: { newRowsAction: 'keep' }
  },
  {
    headerName: 'Sign Off status',
    field: 'eligAgreementSignOffStatus',
    sortable: true,
    filter: true,
    resizable: true,
    filterParams: { newRowsAction: 'keep' }
  },
];

export const myMappingColumns = [
  {
    headerName: 'Client Name',
    field: 'e1',
    sortable: true,
    filter: true,
    resizable: true,
    filterParams: { newRowsAction: 'keep' }
  },
  {
    headerName: 'Carrier(s)',
    field: 'e2',
    sortable: true,
    filter: true,
    resizable: true,
    filterParams: { newRowsAction: 'keep' }
  },
  {
    headerName: 'PLI',
    field: 'e3',
    sortable: true,
    filter: true,
    resizable: true,
    filterParams: { newRowsAction: 'keep' }
  },
  {
    headerName: 'EC',
    field: 'e4', sortable: true,
    filter: true, resizable: true,
    filterParams: { newRowsAction: 'keep' },
    cellRendererParams: (params) => ({
      activeEc: params.data.activeEc,
      ecOOOFromDate: params.data.ecOOOFromDate,
      ecOOOFromDateTimestamp: params.data.ecOOOFromDateTimestamp,
      ecOOOToDate: params.data.ecOOOToDate,
      ecOOOToDateTimestamp: params.data.ecOOOToDateTimestamp,
      backupName: params.data.backupToEc
    }),
    cellRenderer: BackupEcCellRendererComponent
  },
  {
    headerName: 'Mapping Status',
    cellRenderer: params => {
      const mappingStatus = ['Requested', 'Mapping', 'Submitted', 'New Coding', 'Auto Coding', 'QA',
        'UAT', 'Ready to Deploy', 'Completed', 'Cancelled', 'Historic'];
      let baselineDate = null
      let progclr = '';
      let backgroundClr = ''
      let progressIndex = 0;
      let progressBarPercent = 0;
      const currentValue = params.value;
      if (currentValue) {
        mappingStatus.forEach((item, index) => {
          if (currentValue?.toLowerCase().includes(item?.toLowerCase())) {
            progressIndex = index * 10;
          }
        });
        if (progressIndex) {
          progressBarPercent = (progressIndex / 110) * 100;
        }

        if (params.data.e6) {
          baselineDate = new Date(params.data.e6)
          const currentDate = new Date();
          const diffInTime = baselineDate.getTime() - currentDate.getTime();
          const diffInDays = diffInTime / (1000 * 3600 * 24);

          if (params.data.e5 === '11 - Historic' || params.data.e5 === '9 - Completed') {
            progclr = 'black';
            backgroundClr = '#00000040'
          }
          else if (params.data.e5 === '10 - Cancelled') {
            progclr = 'black';
            backgroundClr = '#00000040';
            progressBarPercent = 0;
          }
          else if (diffInDays > 7) {
            progclr = '#61A515';
            backgroundClr = '#61A51540'
          }
          else if ((diffInDays >= 1 && diffInDays < 7) || (diffInDays > 0 && diffInDays < 1)) {
            progclr = '#FFD216';
            backgroundClr = '#FFD21640'
          }
          else if (diffInDays == 0 || diffInDays < 0 ) {
            progclr = '#D0021B';
            backgroundClr = '#CC000040'
          }
        } else {
          if (params.data.e5 === '11 - Historic' || params.data.e5 === '9 - Completed') {
            progclr = 'black';
            backgroundClr = '#00000040'
          }
          else if (params.data.e5 === '10 - Cancelled') {
            progclr = 'black';
            backgroundClr = '#00000040';
            progressBarPercent = 0;
          } else {
            progclr = '#61A515';
            backgroundClr = '#61A51540'
          }
        }

        const progressBar = '<div>' + currentValue + '</div>' +
          `<div style="border-radius: 6px; width: 100%; margin-top: -6%; background-color:${backgroundClr};">` +
          '<div style="background-color: ' + progclr + '; ' +
          'border-radius: 2px; padding: 1%; width: ' + progressBarPercent + '%;">' +
          '</div>' +
          '</div>';

        return progressBar;
      } else {
        return params.value;
      }
    },
    field: 'e5', sortable: true, filter: true, resizable: true, filterParams: { newRowsAction: 'keep' }
  },
  {
    headerName: 'Format',
    field: 'format',
    sortable: true,
    filter: true,
    resizable: true,
    filterParams: { newRowsAction: 'keep' }
  },
  {
    headerName: 'Created',
    field: 'created',
    sortable: true,
    filter: true,
    resizable: true,
    filterParams: { newRowsAction: 'keep' }
  },
  {
    headerName: 'Baseline Date',
    field: 'e6',
    sortable: true,
    filter: true,
    resizable: true,
    filterParams: { newRowsAction: 'keep' }
  },
  {
    headerName: 'Testing Date',
    field: 'e7',
    sortable: true,
    filter: true,
    resizable: true,
    filterParams: { newRowsAction: 'keep' }
  },
  {
    headerName: 'Go Live Date',
    field: 'e8',
    sortable: true,
    filter: true,
    resizable: true,
    filterParams: { newRowsAction: 'keep' }
  },
  {
    headerName: 'Vendor',
    field: 'e9',
    sortable: true,
    filter: true,
    resizable: true,
    filterParams: { newRowsAction: 'keep' }
  },
  {
    headerName: 'Map Type',
    field: 'e10',
    sortable: true,
    filter: true,
    resizable: true,
    filterParams: { newRowsAction: 'keep' }
  },
  {
    headerName: 'User Story',
    field: 'e11',
    sortable: true,
    filter: true,
    resizable: true,
    filterParams: { newRowsAction: 'keep' }
  },
  {
    headerName: 'Mapping BA',
    field: 'e12',
    sortable: true,
    filter: true,
    resizable: true,
    filterParams: { newRowsAction: 'keep' },
    // cellRenderer: BackupEcCellRendererComponent
  },
  {
    headerName: 'Coder',
    field: 'e13',
    sortable: true,
    filter: true,
    resizable: true,
    filterParams: { newRowsAction: 'keep' },
    // cellRenderer: BackupEcCellRendererComponent
  },
  {
    headerName: 'QA',
    field: 'e14',
    sortable: true,
    filter: true,
    resizable: true,
    filterParams: { newRowsAction: 'keep' },
    // cellRenderer: BackupEcCellRendererComponent
  },
  {
    headerName: 'Tester',
    field: 'e15',
    sortable: true,
    filter: true,
    resizable: true,
    filterParams: { newRowsAction: 'keep' },
    // cellRenderer: BackupEcCellRendererComponent
  }
];


export const processFilesColumns = [
  { headerName: 'Client Name', field: 'g1', sortable: true, resizable: true, filter:true, filterParams: { newRowsAction: 'keep',  buttons: ["apply", "reset"] } },
  {
    headerName: 'Carrier',
    field: 'g2',
    sortable: true,
    filter: true,
    resizable: true,
    filterParams: { newRowsAction: 'keep',  buttons: ["apply", "reset"] }
  },
  {
    headerName: 'PLI',
    field: 'g3',
    sortable: true,
    filter: true,
    resizable: true,
    filterParams: { newRowsAction: 'keep',  buttons: ["apply", "reset"], closeOnApply: true,}
  },
  {
    headerName: 'EC',
    field: 'g4',
    sortable: true,
    filter: false,
    resizable: true,
    filterParams: { newRowsAction: 'keep',  buttons: ["apply", "reset"] },
    cellRendererParams: (params) => ({
      activeEc: params.data.activeEc,
      ecOOOFromDate: params.data.ecOOOFromDate,
      ecOOOFromDateTimestamp: params.data.ecOOOFromDateTimestamp,
      ecOOOToDate: params.data.ecOOOToDate,
      ecOOOToDateTimestamp: params.data.ecOOOToDateTimestamp,
      backupName: params.data.backupToEc
    }),
    cellRenderer: BackupEcCellRendererComponent
  },
  {
    headerName: 'Status',
    field: 'g5',
    sortable: true,
    filter: true,
    resizable: true,
    filterParams: { newRowsAction: 'keep',  buttons: ["apply", "reset"] },
    tooltipField: 'tRecord.softText',
    //   valueGetter(params) {
    //     if (params.data && params.data.progressBarDetails  && params.data.progressBarDetails.outputText) {
    //     return params.data.progressBarDetails.outputText;
    //     }
    // },
    cellRenderer: params => {
      const currentValue = params.data.progressBarDetails;
      const outputLoadCompleteStr = "Load Report Send Complete"
      if (currentValue && currentValue.outputText !== "Complete" && currentValue.outputText !== outputLoadCompleteStr.trim()) {
        const progressBarPercent = (currentValue.rowSequence / currentValue.totalTranslations) * 100;
        const progressBar = '<div>' + currentValue.outputText + '</div>' +
          '<div style="border-radius: 6px; width: 100%; margin-top: -6%; background-color:' + currentValue.statusColour + '40;">' +
          '<div style="background-color: ' + currentValue.statusColour + '; ' +
          'border-radius: 2px; padding: 1%; width: ' + progressBarPercent + '%;">' +
          '</div>' +
          '</div>';
        return progressBar;
      } else if (currentValue && currentValue.outputText === "Complete" || currentValue.outputText === outputLoadCompleteStr.trim()) {
        const progressBarPercent = (currentValue.rowSequence / currentValue.totalTranslations) * 100;
        const progressBar = '<div>' + currentValue.outputText + '</div>' +
          '<div style="border: 0.1px solid #000000;border-radius: 6px; width: 100%; margin-top: -6%; background-color:' + currentValue.statusColour + '40;">' +
          '<div style="background-color: ' + currentValue.statusColour + '; ' +
          'border-radius: 2px; padding: 1%; width: ' + progressBarPercent + '%;">' +
          '</div>' +
          '</div>';
        return progressBar;
      } else {
        return params.value;
      }
    }
  },
  {
    headerName: 'Comments',
    field: 'comment',
    sortable: true,
    filter: true,
    resizable: true,
    filterParams: {newRowsAction: 'keep',  buttons: ["apply", "reset"]},
    cellRenderer: ToolTipComponent,
    cellRendererParams: (params: ICellRendererParams) => params.data,
    // cellRenderer: params => {
    //   if(params.value){
    //     return '<div matTooltip="'+params.value+'" >' +
    //       '<mat-icon class = "mat-icon material-icons mat-icon-no-color" ' +
    //       'role = "img" aria-hidden="true">chat_bubble_outline</mat-icon>' +
    //       '</div>'
    //   } else{
    //     return '';
    //   }
    // }
  },
  {
    headerName: 'Library',
    field: 'g13',
    sortable: true,
    filter: true,
    resizable: true,
    filterParams: { newRowsAction: 'keep',  buttons: ["apply", "reset"] }
  },
  {
    headerName: 'File',
    field: 'g14',
    sortable: true,
    filter: true,
    resizable: true,
    filterParams: { newRowsAction: 'keep',  buttons: ["apply", "reset"] }
  },
  { headerName: 'Member', field: 'g6', sortable: true, filter: true, resizable: true, filterParams: { newRowsAction: 'keep',  buttons: ["apply", "reset"] } },
  {
    headerName: 'Business Date',
    field: 'g7',
    sortable: true,
    filter: true,
    resizable: true,
    filterParams: { newRowsAction: 'keep',  buttons: ["apply", "reset"] }
  },
  {
    headerName: 'Records Count',
    field: 'g8',
    sortable: true,
    filter: false,
    resizable: true,
    filterParams: { newRowsAction: 'keep',  buttons: ["apply", "reset"] }
  },
  {
    headerName: 'Lapse',
    field: 'lapse',
    sortable: true,
    filter: false,
    resizable: true,
    filterParams: { newRowsAction: 'keep',  buttons: ["apply", "reset"] },
    width: 250,
  },
  {
    headerName: 'PG Criteria',
    field: 'g11',
    sortable: true,
    filter: true,
    resizable: true,
    filterParams: { newRowsAction: 'keep',  buttons: ["apply", "reset"] }
  },
  { headerName: 'Client Code', field: 'clientCode', sortable: true, filter: true, resizable: true },
];

export const clientAssignmentColumns = [
  { headerName: 'Client Name', field: 'c1', sortable: true, filter: true, resizable: true },
  { headerName: 'Carrier', field: 'c2', sortable: true, filter: true, resizable: true },
  { headerName: 'PLI', field: 'c3', sortable: true, filter: true, resizable: true },
  { headerName: 'EC', field: 'c4', sortable: true, filter: true, resizable: true },
  { headerName: 'Eligibility Type', field: 'c5', sortable: true, filter: true, resizable: true },
  { headerName: 'FTP ID', field: 'c6', sortable: true, filter: true, resizable: true },
  { headerName: 'Vendor', field: 'c7', sortable: true, filter: true, resizable: true },
  { headerName: 'PG Criteria', field: 'c8', sortable: true, filter: true, resizable: true },
  { headerName: 'Format', field: 'c9', sortable: true, filter: true, resizable: true }
];

export const openEnrollmentColumns = [
  { headerName: 'Client Name', field: 'oe1', sortable: true, filter: true, resizable: true },
  { headerName: 'Carrier', field: 'oe2', sortable: true, filter: true, resizable: true },
  { headerName: 'PLI', field: 'oe3', sortable: true, filter: true, resizable: true },
  {
    headerName: 'EC',
    field: 'oe4',
    sortable: true,
    filter: true,
    resizable: true,
    cellRendererParams: (params) => ({
      activeEc: params.data.activeEc,
      ecOOOFromDate: params.data.ecOOOFromDate,
      ecOOOFromDateTimestamp: params.data.ecOOOFromDateTimestamp,
      ecOOOToDate: params.data.ecOOOToDate,
      ecOOOToDateTimestamp: params.data.ecOOOToDateTimestamp,
      backupName: params.data.backupToEc
    }),
    cellRenderer: BackupEcCellRendererComponent
  },
  { headerName: 'Eligibility Type', field: 'oe5', sortable: true, filter: true },
  { headerName: 'FTP ID', field: 'oe6', sortable: true, filter: true, resizable: true },
  { headerName: 'Vendor', field: 'oe7', sortable: true, filter: true, resizable: true },
  { headerName: 'PG Criteria', field: 'oe8', sortable: true, filter: true, resizable: true },
  { headerName: 'File Type', field: 'oe9', sortable: true, filter: true, resizable: true },
  { headerName: 'Benefit Plan Year', field: 'oe24', sortable: true, filter: true, resizable: true },
  { headerName: 'Client Send OE File', field: 'oe10', sortable: true, filter: true, resizable: true },
  { headerName: 'OE Window', field: 'oe11', sortable: true, filter: true, resizable: true },
  { headerName: 'OE Test File', field: 'oe12', sortable: true, filter: true, resizable: true },
  { headerName: 'OE File Exp Date', field: 'oe13', sortable: true, filter: true, resizable: true },
  { headerName: 'OE ID Cards', field: 'oe14', sortable: true, filter: true, resizable: true },
  { headerName: 'Data on OE File', field: 'oe15', sortable: true, filter: true, resizable: true },
  { headerName: 'OE CVS Term Drops', field: 'oe16', sortable: true, filter: true, resizable: true },
  { headerName: 'OE Current Terms', field: 'oe17', sortable: true, filter: true, resizable: true },
  { headerName: 'OE CAG Change', field: 'oe18', sortable: true, filter: true, resizable: true },
  { headerName: 'OE File After OE Date', field: 'oe19', sortable: true, filter: true, resizable: true },
  { headerName: 'OE Date After OE File', field: 'oe20', sortable: true, filter: true, resizable: true },
  { headerName: 'New Bnft Year File', field: 'oe21', sortable: true, filter: true, resizable: true },
  { headerName: 'New Bnft Year File Date', field: 'oe22', sortable: true, filter: true, resizable: true },
  { headerName: 'New Bnft Year File Terms', field: 'oe23', sortable: true, filter: true, resizable: true },
];

export const projectColumns = [
  { headerName: 'Client Name', field: 'clientName', sortable: true, filter: true, resizable: true },
  { headerName: 'Carrier', field: 'carrier', sortable: true, filter: true, resizable: true },
  { headerName: 'PLI', field: 'pli', sortable: true, filter: true, resizable: true },
  {
    headerName: 'EC',
    field: 'ec',
    sortable: true,
    filter: true,
    resizable: true,
    cellRendererParams: (params) => ({
      activeEc: params.data.activeEc,
      ecOOOFromDate: params.data.ecOOOFromDate,
      ecOOOFromDateTimestamp: params.data.ecOOOFromDateTimestamp,
      ecOOOToDate: params.data.ecOOOToDate,
      ecOOOToDateTimestamp: params.data.ecOOOToDateTimestamp,
      backupName: params.data.backupToEc
    }),
    cellRenderer: BackupEcCellRendererComponent
  },

  { headerName: 'Project Type', field: 'projectType', sortable: true, filter: true, resizable: true },
  {
    headerName: 'Project Phase', field: 'prjPhase', sortable: true, filter: true, resizable: true,
    cellRenderer: params => {
      const projectPhase = params.data && params.data.prjPhase;
      const projectStatus = params.data && params.data.prjStatus;
      // return params;

      if (params && projectPhase && projectStatus) {
        let progressBarPercent;
        let progressBar;
        if (projectPhase === 'null' || projectPhase === null || projectPhase === undefined) {
          progressBar = '<div></div>';
          progressBarPercent = 0;
        } else if (projectPhase === 'not started' || projectPhase === 'Not Started') {
          progressBar = '<div>' + 'Not Started' + '</div>';
          progressBarPercent = 0;
        } else if (projectPhase === 'In Discovery') {
          progressBar = '<div>' + 'In Discovery' + '</div>';
          progressBarPercent = 10;
        } else if (projectPhase === 'In Setup') {
          progressBar = '<div>' + 'In Setup' + '</div>';
          progressBarPercent = 20;
        } else if (projectPhase === 'Waiting on BSR') {
          progressBar = '<div>' + 'Waiting on BSR' + '</div>';
          progressBarPercent = 30;
        } else if (projectPhase === 'Awaiting FTP Login ID') {
          progressBar = '<div>' + 'Awaiting FTP Login ID' + '</div>';
          progressBarPercent = 40;
        } else if (projectPhase === 'Ready for QC') {
          progressBar = '<div>' + 'Ready for QC' + '</div>';
          progressBarPercent = 50;
        } else if (projectPhase === 'Awaiting Test File') {
          progressBar = '<div>' + 'Awaiting Test File' + '</div>';
          progressBarPercent = 60;
        } else if (projectPhase === 'Testing') {
          progressBar = '<div>' + 'Testing' + '</div>';
          progressBarPercent = 70;
        } else if (projectPhase === 'Awaiting Baseline') {
          progressBar = '<div>' + 'Awaiting Baseline' + '</div>';
          progressBarPercent = 80;
        } else if (projectPhase === 'Loading Baseline') {
          progressBar = '<div>' + 'Loading Baseline' + '</div>';
          progressBarPercent = 85;
        } else if (projectPhase === 'Awaiting EPR Sign-Off') {
          progressBar = '<div>' + 'Awaiting EPR Sign-Off' + '</div>';
          progressBarPercent = 90;
        } else if (projectPhase === 'Ready for Go-Live') {
          progressBar = '<div>' + 'Ready for Go-Live' + '</div>';
          progressBarPercent = 95;
        } else if (projectPhase === 'On Hold') {
          progressBar = '<div>' + 'On Hold' + '</div>';
          progressBarPercent = 0;
        } else if (projectPhase === 'Completed') {
          progressBar = '<div>' + 'Completed' + '</div>';
          progressBarPercent = 0;
        } else if (projectPhase === 'Cancelled') {
          progressBar = '<div>' + 'Cancelled' + '</div>';
          progressBarPercent = 0;
        }

        let newProjectStatus;
        switch (projectStatus) {
          case 'Red':
          case 'red':
            newProjectStatus = "#cc0000";
            break;
          case 'Green':
          case 'green':
            newProjectStatus = "#61A515";
            break;
          case 'Yellow':
          case 'yellow':
            newProjectStatus = "#FFD216";
            break;
          case 'Black':
          case 'black':
            newProjectStatus = "#000000";
            break;

          default:
            newProjectStatus = "#000000";
            break;
        }
        progressBar = progressBar +
          '<div style="border-radius: 6px; width: 100%; margin-top: -6%; background-color: ' + newProjectStatus + '40;">' +
          '<div style="background-color: ' + projectStatus + '; ' +
          'border-radius: 2px; padding: 1%; width: ' + progressBarPercent + '%;">' +
          '</div>' +
          '</div>';
        return progressBar;
      } else {
        return params.value;
      }
    }
  },
  { headerName: 'Prj Go-Live Date', field: 'prjGoLiveDte', sortable: true, filter: true, resizable: true },
  { headerName: 'Prj Status', field: 'prjStatus', sortable: true, filter: true, resizable: true },
  { headerName: 'Card Status', field: 'cardStatus', sortable: true, filter: true, resizable: true },

  { headerName: 'Disc Due Date', field: 'newDiscDueDate', sortable: true, filter: true, resizable: true },
  { headerName: 'Disc Cmplt Date', field: 'newDiscCmpltDate', sortable: true, filter: true, resizable: true },
  { headerName: 'Setup Due Date', field: 'newSetupDueDate', sortable: true, filter: true, resizable: true },
  { headerName: 'Setup Cmplt Date', field: 'newSetupCmpltDate', sortable: true, filter: true, resizable: true },
  { headerName: 'Testing Due Date', field: 'newTstDueDate', sortable: true, filter: true, resizable: true },
  { headerName: 'Testing Cmplt Date', field: 'newTstCmpltDate', sortable: true, filter: true, resizable: true },
  { headerName: 'Baseline Due Date', field: 'newBLDueDate', sortable: true, filter: true, resizable: true },
  { headerName: 'Baseline Cmplt Date', field: 'newBLCmpltDate', sortable: true, filter: true, resizable: true },
  { headerName: 'ID Cards Due Date', field: 'newIDCrdsDueDate', sortable: true, filter: true, resizable: true },
  { headerName: 'ID Cards Cmplt Date', field: 'newIDCrdsCmpltDate', sortable: true, filter: true, resizable: true },

  {
    headerName: 'Eligibility Type',
    field: 'eligibilityType',
    sortable: true,
    filter: true,
    resizable: true,
    filterParams: { newRowsAction: 'keep' }
  },
  { headerName: 'FTP ID', field: 'ftpId', sortable: true, filter: true, resizable: true },
  { headerName: 'Vendor', field: 'vendorLookup', sortable: true, filter: true, resizable: true },
  { headerName: 'PG Criteria', field: 'pgCriteria', sortable: true, filter: true, resizable: true },
  {
    headerName: 'SSOE Migrated',
    field: 'consolidateProfileLogic',
    sortable: true,
    filter: true,
    resizable: true
  },
  { headerName: 'File Format', field: 'format', sortable: true, filter: true, resizable: true }
];
// for active projects
export const activeProjectColumns = [
  { headerName: 'Client Name', field: 'a1', sortable: true, filter: true, resizable: true },
  { headerName: 'Carrier', field: 'a2', sortable: true, filter: true, resizable: true },
  { headerName: 'PLI', field: 'a3', sortable: true, filter: true, resizable: true },
  {
    headerName: 'EC',
    field: 'a4',
    sortable: true,
    filter: true,
    resizable: true,
    cellRendererParams: (params) => ({
      activeEc: params.data.activeEc,
      ecOOOFromDate: params.data.ecOOOFromDate,
      ecOOOFromDateTimestamp: params.data.ecOOOFromDateTimestamp,
      ecOOOToDate: params.data.ecOOOToDate,
      ecOOOToDateTimestamp: params.data.ecOOOToDateTimestamp,
      backupName: params.data.backupToEc
    }),
    cellRenderer: BackupEcCellRendererComponent
  },

  { headerName: 'Project Type', field: 'a5', sortable: true, filter: true, resizable: true },
  {
    headerName: 'Project Phase', field: 'a6', sortable: true, filter: true, resizable: true,
    cellRenderer: params => {
      const projectPhase = params.data && params.data.a6;
      const projectStatus = params.data && params.data.prjStatus;
      // return params;

      if (params && projectPhase && projectStatus) {
        let progressBarPercent;
        let progressBar;
        if (projectPhase === 'null' || projectPhase === null || projectPhase === undefined) {
          progressBar = '<div></div>';
          progressBarPercent = 0;
        } else if (projectPhase === 'not started' || projectPhase === 'Not Started') {
          progressBar = '<div>' + 'Not Started' + '</div>';
          progressBarPercent = 0;
        } else if (projectPhase === 'In Discovery') {
          progressBar = '<div>' + 'In Discovery' + '</div>';
          progressBarPercent = 10;
        } else if (projectPhase === 'In Setup') {
          progressBar = '<div>' + 'In Setup' + '</div>';
          progressBarPercent = 20;
        } else if (projectPhase === 'Waiting on BSR') {
          progressBar = '<div>' + 'Waiting on BSR' + '</div>';
          progressBarPercent = 30;
        } else if (projectPhase === "Awaiting FTP Login ID") {
          progressBar = '<div>' + "Awaiting FTP Login ID" + '</div>';
          progressBarPercent = 40;
        } else if (projectPhase === 'Ready for QC') {
          progressBar = '<div>' + 'Ready for QC' + '</div>';
          progressBarPercent = 50;
        } else if (projectPhase === 'Awaiting Test File') {
          progressBar = '<div>' + 'Awaiting Test File' + '</div>';
          progressBarPercent = 60;
        } else if (projectPhase === 'Testing') {
          progressBar = '<div>' + 'Testing' + '</div>';
          progressBarPercent = 70;
        } else if (projectPhase === 'Awaiting Baseline') {
          progressBar = '<div>' + 'Awaiting Baseline' + '</div>';
          progressBarPercent = 80;
        } else if (projectPhase === 'Loading Baseline') {
          progressBar = '<div>' + 'Loading Baseline' + '</div>';
          progressBarPercent = 85;
        } else if (projectPhase === 'Awaiting EPR Sign-Off') {
          progressBar = '<div>' + 'Awaiting EPR Sign-Off' + '</div>';
          progressBarPercent = 90;
        } else if (projectPhase === 'Ready for Go-Live') {
          progressBar = '<div>' + 'Ready for Go-Live' + '</div>';
          progressBarPercent = 95;
        } else if (projectPhase === 'On Hold') {
          progressBar = '<div>' + 'On Hold' + '</div>';
          progressBarPercent = 0;
        } else if (projectPhase === 'Completed') {
          progressBar = '<div>' + 'Completed' + '</div>';
          progressBarPercent = 100;
        } else if (projectPhase === 'Cancelled') {
          progressBar = '<div>' + 'Cancelled' + '</div>';
          progressBarPercent = 0;
        }
        let newProjectStatus;
        switch (projectStatus) {
          case 'Red':
          case 'red':
            newProjectStatus = "#cc0000";
            break;
          case 'Green':
          case 'green':
            newProjectStatus = "#61A515";
            break;
          case 'Yellow':
          case 'yellow':
            newProjectStatus = "#FFD216";
            break;
          case 'Black':
          case 'black':
            newProjectStatus = "#000000";
            break;
        }
        progressBar = progressBar +
          '<div style="border-radius: 6px; width: 100%; margin-top: -6%; background-color: ' + newProjectStatus + '40;">' +
          '<div style="background-color: ' + projectStatus + '; ' +
          'border-radius: 2px; padding: 1%; width: ' + progressBarPercent + '%;">' +
          '</div>' +
          '</div>';
        return progressBar;
      } else {
        return params.value;
      }
    }
  },
  { headerName: 'Prj Go-Live Date', field: 'a7', sortable: true, filter: true, resizable: true },
  { headerName: 'Prj Status', field: 'prjStatus', sortable: true, filter: true, resizable: true },
  { headerName: 'Card Status', field: 'a8', sortable: true, filter: true, resizable: true },

  {
    headerName: 'QC\'d By',
    field: 'a9',
    sortable: true,
    filter: true,
    resizable: true,
    // cellRenderer: BackupEcCellRendererComponent
  },
  { headerName: 'QC Cmplt Date', field: 'a10', sortable: true, filter: true, resizable: true },

  { headerName: 'Disc Due Date', field: 'aNewDiscDueDate', sortable: true, filter: true, resizable: true },
  { headerName: 'Disc Cmplt Date', field: 'aNewDiscCmpltDate', sortable: true, filter: true, resizable: true },
  { headerName: 'Setup Due Date', field: 'aNewSetupDueDate', sortable: true, filter: true, resizable: true },
  { headerName: 'Setup Cmplt Date', field: 'aNewSetupCmpltDate', sortable: true, filter: true, resizable: true },
  { headerName: 'Testing Due Date', field: 'aNewTstDueDate', sortable: true, filter: true, resizable: true },
  { headerName: 'Testing Cmplt Date', field: 'aNewTstCmpltDate', sortable: true, filter: true, resizable: true },
  { headerName: 'Baseline Due Date', field: 'aNewBLDueDate', sortable: true, filter: true, resizable: true },
  { headerName: 'Baseline Cmplt Date', field: 'aNewBLCmpltDate', sortable: true, filter: true, resizable: true },
  { headerName: 'ID Cards Due Date', field: 'aNewIDCrdsDueDate', sortable: true, filter: true, resizable: true },
  { headerName: 'ID Cards Cmplt Date', field: 'aNewIDCrdsCmpltDate', sortable: true, filter: true, resizable: true },

  {
    headerName: 'Eligibility Type',
    field: 'a11',
    sortable: true,
    filter: true,
    resizable: true,
    filterParams: { newRowsAction: 'keep' }
  },
  { headerName: 'FTP ID', field: 'a12', sortable: true, filter: true, resizable: true },
  { headerName: 'Vendor', field: 'a13', sortable: true, filter: true, resizable: true },
  { headerName: 'PG Criteria', field: 'a14', sortable: true, filter: true, resizable: true },
  { headerName: 'SSOE Migrated', field: 'a15', sortable: true, filter: true, resizable: true },
  { headerName: 'File Format', field: 'a16', sortable: true, filter: true, resizable: true },
  { headerName: 'Client Code', field: 'clientCode', sortable: true, filter: true, resizable: true },

];

export const qcProjectColumns = [
  { headerName: 'Client Name', field: 'qcClientName', sortable: true, filter: true, resizable: true },
  { headerName: 'Carrier', field: 'qcCarrier', sortable: true, filter: true, resizable: true },
  { headerName: 'PLI', field: 'qcPli', sortable: true, filter: true, resizable: true },
  {
    headerName: 'EC',
    field: 'qcEc',
    sortable: true,
    filter: true,
    resizable: true,
    cellRendererParams: (params) => ({
      activeEc: params.data.activeEc,
      ecOOOFromDate: params.data.ecOOOFromDate,
      ecOOOFromDateTimestamp: params.data.ecOOOFromDateTimestamp,
      ecOOOToDate: params.data.ecOOOToDate,
      ecOOOToDateTimestamp: params.data.ecOOOToDateTimestamp,
      backupName: params.data.backupToEc
    }),
    cellRenderer: BackupEcCellRendererComponent
  },

  { headerName: 'Project Type', field: 'qcProjectType', sortable: true, filter: true, resizable: true },
  { headerName: 'Prj Go-Live Date', field: 'qcPrjGoLiveDte', sortable: true, filter: true, resizable: true },
  { headerName: 'Prj Status', field: 'qcPrjStatus', sortable: true, filter: true, resizable: true },

  {
    headerName: 'QC\'d By',
    field: 'qcPrjQcBy',
    sortable: true,
    filter: true,
    resizable: true,
    // cellRenderer: BackupEcCellRendererComponent
  },
  { headerName: 'QC Cmplt Date', field: 'qcPrjQcDte', sortable: true, filter: true, resizable: true },

  { headerName: 'Disc Cmplt Date', field: 'qcNewDiscCmpltDate', sortable: true, filter: true, resizable: true },
  { headerName: 'Testing Due Date', field: 'qcNewTstDueDate', sortable: true, filter: true, resizable: true },
  { headerName: 'Testing Cmplt Date', field: 'qcNewTstCmpltDate', sortable: true, filter: true, resizable: true },
  { headerName: 'Baseline Due Date', field: 'qcNewBLDueDate', sortable: true, filter: true, resizable: true },
  { headerName: 'Baseline Cmplt Date', field: 'qcNewBLCmpltDate', sortable: true, filter: true, resizable: true },
  { headerName: 'ID Cards Due Date', field: 'qcNewIDCrdsDueDate', sortable: true, filter: true, resizable: true },
  { headerName: 'ID Cards Cmplt Date', field: 'qcNewIDCrdsCmpltDate', sortable: true, filter: true, resizable: true },

  { headerName: 'Eligibility Type', field: 'qcEligibilityType', sortable: true, filter: true, resizable: true },
  { headerName: 'FTP ID', field: 'qcFtpId', sortable: true, filter: true, resizable: true },
  { headerName: 'Vendor', field: 'qcVendorLookup', sortable: true, filter: true, resizable: true },
  { headerName: 'PG Criteria', field: 'qcPgCriteria', sortable: true, filter: true, resizable: true },
  {
    headerName: 'SSOE Migrated',
    field: 'consolidateProfileLogic',
    sortable: true,
    filter: true,
    resizable: true
  }
];

export const testingProjectColumns = [
  { headerName: 'Client Name', field: 't1', sortable: true, filter: true, resizable: true },
  { headerName: 'Carrier', field: 't2', sortable: true, filter: true, resizable: true },
  { headerName: 'PLI', field: 't3', sortable: true, filter: true, resizable: true },
  {
    headerName: 'EC',
    field: 't4',
    sortable: true,
    filter: true,
    resizable: true,
    cellRendererParams: (params) => ({
      activeEc: params.data.activeEc,
      ecOOOFromDate: params.data.ecOOOFromDate,
      ecOOOFromDateTimestamp: params.data.ecOOOFromDateTimestamp,
      ecOOOToDate: params.data.ecOOOToDate,
      ecOOOToDateTimestamp: params.data.ecOOOToDateTimestamp,
      backupName: params.data.backupToEc
    }),
    cellRenderer: BackupEcCellRendererComponent
  },

  { headerName: 'Project Type', field: 't5', sortable: true, filter: true, resizable: true },
  { headerName: 'Prj Go-Live Date', field: 't6', sortable: true, filter: true, resizable: true },
  { headerName: 'Prj Status', field: 'tPrjStatus', sortable: true, filter: true, resizable: true },

  {
    headerName: 'Tester',
    field: 't7',
    sortable: true,
    filter: true,
    resizable: true,
    // cellRenderer: BackupEcCellRendererComponent
  },
  { headerName: 'Test Case #', field: 't8', sortable: true, filter: true, resizable: true },
  { headerName: 'Overall Status', field: 't9', sortable: true, filter: true, resizable: true },
  { headerName: 'Test File Name', field: 't10', sortable: true, filter: true, resizable: true },
  { headerName: 'Client/Vendor Approval', field: 't11', sortable: true, filter: true, resizable: true },

  { headerName: 'Testing Due Date', field: 'tNewTstDueDate', sortable: true, filter: true, resizable: true },
  { headerName: 'Testing Cmplt Date', field: 'tNewTstCmpltDate', sortable: true, filter: true, resizable: true },
  { headerName: 'Baseline Due Date', field: 'tNewBLDueDate', sortable: true, filter: true, resizable: true },
  { headerName: 'Baseline Cmplt Date', field: 'tNewBLCmpltDate', sortable: true, filter: true, resizable: true },
  { headerName: 'ID Cards Due Date', field: 'tNewIDCrdsDueDate', sortable: true, filter: true, resizable: true },
  { headerName: 'ID Cards Cmplt Date', field: 'tNewIDCrdsCmpltDate', sortable: true, filter: true, resizable: true },

  { headerName: 'Eligibility Type', field: 't12', sortable: true, filter: true, resizable: true },
  { headerName: 'FTP ID', field: 't13', sortable: true, filter: true, resizable: true },
  { headerName: 'Vendor', field: 't14', sortable: true, filter: true, resizable: true },
  { headerName: 'PG Criteria', field: 't15', sortable: true, filter: true, resizable: true }
];


const checkbox = (params) => `<input class="checkboxCustom" type='checkbox' ${params.value ? 'checked' : ''} /><label></label>`;
const filterDate = {
  debounceMs: 500,
  suppressAndOrCondition: true,
  newRowsAction: 'keep',
  comparator: function (filterLocalDateAtMidnight, cellValue) {
    if (cellValue == null) {
      return 0;
    }
    //yyyy-mm-dd
    var dateParts = cellValue.split('-');
    var year = Number(dateParts[0]);
    var month = Number(dateParts[1]) - 1;
    var day = Number(dateParts[2]);
    var cellDate = new Date(year, month, day);
    if (cellDate < filterLocalDateAtMidnight) {
      return -1;
    } else if (cellDate > filterLocalDateAtMidnight) {
      return 1;
    } else {
      return 0;
    }
  }
}
export const cagRef = [
  {
    headerName: '', field: 'cagRefManualCheck',
    cellRenderer: params => checkbox(params),
    sortable: true, filter: true, lockVisible: true, lockPosition: true, filterParams: { newRowsAction: 'keep' }
  },

  {
    headerName: 'CAG Table ID',
    editable: (param) => {
      return allowCagRefEdit(param)
    },
    resizable: true,
    field: 'cagTableId',
    cellStyle: mandatoryCellRule,
    sortable: true, filter: true, lockVisible: true, lockPosition: true, filterParams: { newRowsAction: 'keep' }
  },
  {
    headerName: 'CAG Reference ID',
    editable: (param) => {
      return allowCagRefEdit(param)
    },
    cellStyle: mandatoryCellRule,
    field: 'cagReferenceId',
    resizable: true,
    sortable: true, filter: true, lockVisible: true, lockPosition: true, filterParams: { newRowsAction: 'keep' }
  },
  {
    headerName: 'Carrier',
    editable: (param) => {
      return allowCagRefEdit(param)
    },
    cellStyle: mandatoryCellRule,
    field: 'carrier',
    sortable: true,
    filter: true,
    lockVisible: true,
    lockPosition: true,
    filterParams: { newRowsAction: 'keep' },
    resizable: true
  },
  {
    headerName: 'Account',
    editable: (param) => {
      return allowCagRefEdit(param)
    },
    cellStyle: mandatoryCellRule,
    field: 'account',
    sortable: true,
    filter: true,
    lockVisible: true,
    lockPosition: true,
    filterParams: { newRowsAction: 'keep' },
    resizable: true
  },
  {
    headerName: 'Group',
    editable: (param) => {
      return allowCagRefEdit(param)
    },
    cellStyle: mandatoryCellRule,
    field: 'group',
    sortable: true,
    filter: true,
    lockVisible: true,
    lockPosition: true,
    filterParams: { newRowsAction: 'keep' },
    resizable: true
  },
  {
    headerName: 'Client Product Code',
    editable: (param) => {
      return allowCagRefEdit(param)
    },
    field: 'clientProd',
    sortable: true,
    filter: true,
    lockVisible: true,
    lockPosition: true,
    filterParams: { newRowsAction: 'keep' },
    resizable: true
  },
  {
    headerName: 'Care Network',
    editable: (param) => {
      return allowCagRefEdit(param)
    },
    field: 'careNetwork',
    sortable: true,
    filter: true,
    lockVisible: true,
    lockPosition: true,
    filterParams: { newRowsAction: 'keep' },
    resizable: true
  },
  {
    headerName: 'Care Qualifier',
    editable: (param) => {
      return allowCagRefEdit(param)
    },
    field: 'careQual',
    sortable: true,
    filter: true,
    lockVisible: true,
    lockPosition: true,
    filterParams: { newRowsAction: 'keep' },
    resizable: true
  },
  {
    headerName: 'EC',
    //  editable:(param)=>{return allowCagRefEdit(param)},
    //  cellStyle: mandatoryCellRule,
    editable: false,
    field: 'ec',
    sortable: true,
    filter: true,
    lockVisible: true,
    lockPosition: true,
    filterParams: { newRowsAction: 'keep' },
    resizable: true,
    cellRendererParams: (params) => ({
      activeEc: params.data.activeEc,
      ecOOOFromDate: params.data.ecOOOFromDate,
      ecOOOFromDateTimestamp: params.data.ecOOOFromDateTimestamp,
      ecOOOToDate: params.data.ecOOOToDate,
      ecOOOToDateTimestamp: params.data.ecOOOToDateTimestamp,
      backupName: params.data.backupToEc
    }),
    cellRenderer: BackupEcCellRendererComponent
  },
  {
    headerName: 'From Date',
    editable: (param) => {
      return allowCagRefEdit(param)
    },
    cellStyle: mandatoryCellRule,
    field: 'fromDate', sortable: true,
    filter: 'agDateColumnFilter',
    lockVisible: true, lockPosition: true,
    filterParams: filterDate,
    resizable: true
  },
  {
    headerName: 'Thru Date',
    editable: (param) => {
      return allowCagRefEdit(param)
    },
    cellStyle: mandatoryCellRule,
    field: 'termDate', sortable: true, filter: 'agDateColumnFilter', lockVisible: true, lockPosition: true,
    filterParams: filterDate,
    resizable: true
  },
  {
    headerName: 'Status',
    editable: (param) => {
      return allowCagRefEdit(param)
    },
    cellStyle: mandatoryCellRule,
    cellEditor: 'agSelectCellEditor',
    cellEditorParams: {
      values: ['Y', 'N'],
    },
    cellRenderer: (data) => {
      return data.value;
    },
    field: 'status',
    sortable: true,
    filter: true,
    lockVisible: true,
    lockPosition: true,
    filterParams: { newRowsAction: 'keep' },
    resizable: true
  },

];

export const cagRefBatch = [
  {
    headerName: 'CAG Table ID',
    editable: false,
    field: 'cagTableId',
    sortable: true, filter: true, lockVisible: true, lockPosition: true, filterParams: { newRowsAction: 'keep' },
    resizable: true
  },

  {
    headerName: 'File Name',
    editable: false,
    field: 'inputFileName',
    sortable: true, filter: true, lockVisible: true, lockPosition: true, filterParams: { newRowsAction: 'keep' },
    resizable: true
  },
  {
    headerName: 'Date',
    editable: false,
    field: 'createdTime',
    sortable: true, filter: true, lockVisible: true, lockPosition: true, filterParams: { newRowsAction: 'keep' },
    resizable: true
  },
  {
    headerName: 'EC',
    editable: false,
    field: 'createdBy',
    sortable: true, filter: true, lockVisible: true, lockPosition: true, filterParams: { newRowsAction: 'keep' },
    cellRendererParams: (params) => ({
      activeEc: params.data.activeEc,
      ecOOOFromDate: params.data.ecOOOFromDate,
      ecOOOFromDateTimestamp: params.data.ecOOOFromDateTimestamp,
      ecOOOToDate: params.data.ecOOOToDate,
      ecOOOToDateTimestamp: params.data.ecOOOToDateTimestamp,
      backupName: params.data.backupToEc
    }),
    cellRenderer: BackupEcCellRendererComponent,
    resizable: true
  },
  {
    headerName: 'Request Type',
    editable: false,
    field: 'requestType',
    sortable: true, filter: true, lockVisible: true, lockPosition: true, filterParams: { newRowsAction: 'keep' },
    resizable: true
  },
  {
    headerName: 'Status',
    editable: false,
    field: 'status',
    cellRenderer: params => {
      const statusList = { completed: 'Completed', queue: 'Queue', progress: 'Progress', error: 'Error' }
      const currentValue = params.value;
      let progressBarPercent = 0;
      let color = 'green'
      if (currentValue) {

        if (currentValue === statusList.completed) {
          progressBarPercent = 100;
        } else if (currentValue === statusList.queue) {
          progressBarPercent = 50;
        } else if (currentValue === statusList.progress) {
          progressBarPercent = 60;
        } else if (currentValue === statusList.error) {
          progressBarPercent = 70;
          color = 'red';
        } else {
          progressBarPercent = 0;
        }


        let newProjectStatus;
        switch (color) {
          case 'Red':
          case 'red':
            newProjectStatus = "#cc0000";
            break;
          case 'Green':
          case 'green':
            newProjectStatus = "#61A515";
            break;
          case 'Yellow':
          case 'yellow':
            newProjectStatus = "#FFD216";
            break;
          case 'Black':
          case 'black':
            newProjectStatus = "#000000";
            break;

          default:
            newProjectStatus = "#000000";
            break;
        }
        const progressBar = '<div>' + currentValue + '</div>' +
          '<div style="border-radius: 6px; width: 100%; margin-top: -6%; background-color: ' + newProjectStatus + '40;">' +
          '<div style="background-color: ' + color + '; ' +
          'border-radius: 2px; padding: 1%; width: ' + progressBarPercent + '%;">' +
          '</div>' +
          '</div>';
        return progressBar;
      } else {
        return params.value;
      }
    },
    sortable: true, filter: true, lockVisible: true, lockPosition: true, filterParams: { newRowsAction: 'keep' },
    resizable: true
  },
  {
    headerName: 'Output File',
    cellStyle:showCagRefBatchOutput,
    editable:false,
    field: 'outputFileName',
    sortable: true, filter: true, lockPosition: true, filterParams: { newRowsAction: 'keep' },
    resizable: true,
    cellClass: "highlight-link"
  },
  {
    headerName: 'Message',
    editable: false,
    field: 'messageDetail',
    sortable: true, filter: true, lockVisible: true, lockPosition: true, filterParams: { newRowsAction: 'keep' },
    resizable: true
  },

];
function showCagRefBatchOutput(params){
  if(!params.context.permissions.includes("ELG_VIEW_CAG_REF_OUTPUT")){
    return {'display': "none"}
  }
  return }
function mandatoryCellRule(params) {
  let checkkBoxSelected = false;
  if (params.data && params.data.cagRefManualCheck) {
    checkkBoxSelected = true;
  } else {
    checkkBoxSelected = false;
  }
  if (checkkBoxSelected &&
    (params.context && params.context.saveTriggered) &&
    ((!params.value) || (params.value && !params.value.toString().trim())))
    return { 'border-color': 'red' }
  else
    return { 'border-color': 'transparent' }
}

function allowCagRefEdit(params) {
  let checkkBoxSelected = false;
  if (params.data && params.data.cagRefManualCheck) {
    checkkBoxSelected = true;
  } else {
    checkkBoxSelected = false;
  }
  if (params.context && (params.context.allowEdit || params.context.addTable) && checkkBoxSelected) {
    return true;
  } else {
    return false;
  }
}

export const intFeedsProjectColumns = [
  { headerName: 'Client Name', field: 'i1', sortable: true, filter: true, resizable: true },
  { headerName: 'Carrier', field: 'i2', sortable: true, filter: true, resizable: true },
  { headerName: 'PLI', field: 'i3', sortable: true, filter: true, resizable: true },
  {
    headerName: 'EC',
    field: 'i4',
    sortable: true,
    filter: true,
    resizable: true,
    cellRendererParams: (params) => ({
      activeEc: params.data.activeEc,
      ecOOOFromDate: params.data.ecOOOFromDate,
      ecOOOFromDateTimestamp: params.data.ecOOOFromDateTimestamp,
      ecOOOToDate: params.data.ecOOOToDate,
      ecOOOToDateTimestamp: params.data.ecOOOToDateTimestamp,
      backupName: params.data.backupToEc
    }),
    cellRenderer: BackupEcCellRendererComponent
  },

  { headerName: 'Project Type', field: 'i5', sortable: true, filter: true, resizable: true },
  { headerName: 'Project Phase', field: 'i7', sortable: true, filter: true, resizable: true },
  { headerName: 'Prj Go-Live Date', field: 'i6', sortable: true, filter: true, resizable: true },
  { headerName: 'Prj Status', field: 'iPrjStatus', sortable: true, filter: true, resizable: true },

  { headerName: 'Verify Plan Design in QL', field: 'i8', sortable: true, filter: true, resizable: true },
  { headerName: 'Core ID Offset', field: 'i9', sortable: true, filter: true, resizable: true },
  { headerName: 'Core Alt CH offset', field: 'i10', sortable: true, filter: true, resizable: true },
  { headerName: 'Xwalk ID Field', field: 'i11', sortable: true, filter: true, resizable: true },
  { headerName: 'Elig Settings', field: 'i12', sortable: true, filter: true, resizable: true },
  { headerName: 'Matching Rule', field: 'i13', sortable: true, filter: true, resizable: true },
  { headerName: 'Network Settings', field: 'i14', sortable: true, filter: true, resizable: true },
  { headerName: 'Primary ID Choice', field: 'i15', sortable: true, filter: true,
    filterParams: { newRowsAction: 'keep' , filterOptions:[ {
    displayKey: 'default',
    displayName: 'DEFAULT',
    predicate: ([filterValue], cellValue) => cellValue == null
  },'contains', 'notContains', 'equals','notEqual', 'startsWith', 'endsWith']
  }
  ,resizable: true },

  { headerName: 'Baseline Due Date', field: 'iNewBLDueDate', sortable: true, filter: true, resizable: true },
  { headerName: 'Baseline Cmplt Date', field: 'iNewBLCmpltDate', sortable: true, filter: true, resizable: true },
  { headerName: 'ID Cards Due Date', field: 'iNewIDCrdsDueDate', sortable: true, filter: true, resizable: true },
  { headerName: 'ID Cards Cmplt Date', field: 'iNewIDCrdsCmpltDate', sortable: true, filter: true, resizable: true },

  { headerName: 'Eligibility Type', field: 'i16', sortable: true, filter: true, resizable: true },
  { headerName: 'FTP ID', field: 'i17', sortable: true, filter: true, resizable: true },
  { headerName: 'Vendor', field: 'i18', sortable: true, filter: true, resizable: true },
  { headerName: 'PG Criteria', field: 'i19', sortable: true, filter: true, resizable: true }

];

export const masterEprColumns = [
  { headerName: 'Client Name', field: '0', sortable: true, resizable: true, filter: true },
  { headerName: 'Carrier', field: '1', sortable: true, resizable: true, filter: true },
  { headerName: 'PLI', field: '2', sortable: true, resizable: true, filter: true },
  {
    headerName: 'EC', field: '3', sortable: true, resizable: true, filter: true,
    cellRendererParams: (params) => ({
      activeEc: params.data.activeEc,
      ecOOOFromDate: params.data.ecOOOFromDate,
      ecOOOFromDateTimestamp: params.data.ecOOOFromDateTimestamp,
      ecOOOToDate: params.data.ecOOOToDate,
      ecOOOToDateTimestamp: params.data.ecOOOToDateTimestamp,
      backupName: params.data.backupToEc
    }),
    cellRenderer: BackupEcCellRendererComponent
  },
  { headerName: 'Eligibility Type', field: '28', sortable: true, resizable: true, filter: true },
  { headerName: 'FTP ID', field: '100', sortable: true, resizable: true, filter: true },
  { headerName: 'Vendor Lookup', field: '116', sortable: true, resizable: true, filter: true },
  { headerName: 'PG Criteria', field: '52', sortable: true, resizable: true, filter: true },
  { headerName: 'Format', field: '94', sortable: true, resizable: true, filter: true },
  { headerName: 'SSOE Migrated', field: '235', sortable: true, resizable: true, filter: true },
  {
    headerName: 'Backup EC',
    field: 'backupToEc',
    sortable: true,
    resizable: true,
    filter: true,
    cellRendererParams: (params) => ({
      activeEc: params.data.backupActiveEc,
      ecOOOFromDate: params.data.backupecOooFromDate,
      ecOOOFromDateTimestamp: params.data.backupEcOooFromDateTimestamp,
      ecOOOToDate: params.data.backupecOooToDate,
      ecOOOToDateTimestamp: params.data.backupEcOooToDateTimestamp,
    }),
    cellRenderer: BackupEcCellRendererComponent
  },
  { headerName: 'Go-Live Date', field: '4', sortable: true, resizable: true, filter: true },
  { headerName: 'Client Code', field: '5', sortable: true, resizable: true, filter: true },
  { headerName: 'QL Client Code', field: '6', sortable: true, resizable: true, filter: true },
  { headerName: 'Prj Start Date', field: '7', sortable: true, resizable: true, filter: true },
  { headerName: 'Project Type', field: '8', sortable: true, resizable: true, filter: true },
  { headerName: 'Project Phase', field: '9', sortable: true, resizable: true, filter: true },
  { headerName: 'Project Status', field: 'mvPrjStatus', sortable: true, resizable: true, filter: true },
  { headerName: 'Card Status', field: '10', sortable: true, resizable: true, filter: true },
  { headerName: 'Prj SFDC Case', field: '11', sortable: true, resizable: true, filter: true },
  {
    headerName: 'QC\'d By',
    field: '12',
    sortable: true,
    resizable: true,
    filter: true,
    // cellRenderer: BackupEcCellRendererComponent
  },
  { headerName: 'Disc Due Date', field: 'mvNewDiscDueDate', sortable: true, resizable: true, filter: true },
  { headerName: 'Disc Cmplt Date', field: 'mvNewDiscCmpltDate', sortable: true, resizable: true, filter: true },
  { headerName: 'Setup Due Date', field: 'mvNewSetupDueDate', sortable: true, resizable: true, filter: true },
  { headerName: 'Setup Cmplt Date', field: 'mvNewSetupCmpltDate', sortable: true, resizable: true, filter: true },
  { headerName: 'Test File Expt Date', field: '17', sortable: true, resizable: true, filter: true },
  { headerName: 'Testing Due Date', field: 'mvNewTstDueDate', sortable: true, resizable: true, filter: true },
  { headerName: 'Testing Cmplt Date', field: 'mvNewTstCmpltDate', sortable: true, resizable: true, filter: true },
  { headerName: 'Baseline File Expt Date', field: '20', sortable: true, resizable: true, filter: true },
  { headerName: 'Baseline Due Date', field: 'mvNewBLDueDate', sortable: true, resizable: true, filter: true },
  { headerName: 'Baseline Cmplt Date', field: 'mvNewBLCmpltDate', sortable: true, resizable: true, filter: true },
  { headerName: 'ID Cards Due Date', field: 'mvNewIDCrdsDueDate', sortable: true, resizable: true, filter: true },
  { headerName: 'ID Cards Cmplt Date', field: 'mvNewIDCrdsCmpltDate', sortable: true, resizable: true, filter: true },
  { headerName: 'Prj Go-Live Date', field: '25', sortable: true, resizable: true, filter: true },
  { headerName: 'Prj Cmplt Date', field: '26', sortable: true, resizable: true, filter: true },
  { headerName: 'Prj QC Date', field: '27', sortable: true, resizable: true, filter: true },
  { headerName: 'Multiple Carriers', field: '29', sortable: true, resizable: true, filter: true },
  { headerName: 'Integrated Accumulations', field: '30', sortable: true, resizable: true, filter: true },
  { headerName: 'Shared ID', field: '31', sortable: true, resizable: true, filter: true },
  { headerName: 'Adjustment Method', field: '32', sortable: true, resizable: true, filter: true },
  { headerName: 'MMP', field: '33', sortable: true, resizable: true, filter: true },
  { headerName: 'Med D', field: '34', sortable: true, resizable: true, filter: true },
  { headerName: 'Medicaid', field: '35', sortable: true, resizable: true, filter: true },
  { headerName: 'Medicaid ID', field: '36', sortable: true, resizable: true, filter: true },
  { headerName: 'Subsidy/RDS', field: '37', sortable: true, resizable: true, filter: true },
  { headerName: 'Med B', field: '38', sortable: true, resizable: true, filter: true },
  { headerName: 'Commercial', field: '39', sortable: true, resizable: true, filter: true },
  { headerName: 'Population Type', field: '40', sortable: true, resizable: true, filter: true },
  { headerName: 'COB', field: '41', sortable: true, resizable: true, filter: true },
  { headerName: 'PCOB/NCOB', field: '42', sortable: true, resizable: true, filter: true },
  { headerName: 'STCOB', field: '43', sortable: true, resizable: true, filter: true },
  { headerName: 'COBC', field: '44', sortable: true, resizable: true, filter: true },
  { headerName: 'Cardholders Only', field: '45', sortable: true, resizable: true, filter: true },
  { headerName: 'Surviving Spouses', field: '46', sortable: true, resizable: true, filter: true },
  { headerName: 'Stand Alone Deps', field: '47', sortable: true, resizable: true, filter: true },
  { headerName: 'Mbrs/Deps Different CAG', field: '48', sortable: true, resizable: true, filter: true },
  { headerName: 'Phone Lines', field: '49', sortable: true, resizable: true, filter: true },
  { headerName: 'Phone Lines Date', field: '50', sortable: true, resizable: true, filter: true },
  { headerName: 'Print Cards/Kits', field: '51', sortable: true, resizable: true, filter: true },
  { headerName: 'Turn Around Time L1', field: '53', sortable: true, resizable: true, filter: true },
  { headerName: 'Percentage L1', field: '54', sortable: true, resizable: true, filter: true },
  { headerName: 'Turn Around Time L2', field: '55', sortable: true, resizable: true, filter: true },
  { headerName: 'Percentage L2', field: '56', sortable: true, resizable: true, filter: true },
  { headerName: 'PG Period', field: '57', sortable: true, resizable: true, filter: true },
  { headerName: 'PG in Effect', field: '58', sortable: true, resizable: true, filter: true },
  { headerName: 'PG Reporting in Effect', field: '59', sortable: true, resizable: true, filter: true },
  { headerName: 'Exact Match BPG', field: '60', sortable: true, resizable: true, filter: true },
  { headerName: 'Mem ID Length', field: '61', sortable: true, resizable: true, filter: true },
  { headerName: 'Mem ID Type (PC)', field: '62', sortable: true, resizable: true, filter: true },
  { headerName: 'Mem ID Type (w/o PC)', field: '63', sortable: true, resizable: true, filter: true },
  { headerName: 'Unique IDs in Fam', field: '64', sortable: true, resizable: true, filter: true },
  { headerName: 'Fam ID Equal Mbr ID', field: '65', sortable: true, resizable: true, filter: true },
  { headerName: 'Person Codes', field: '66', sortable: true, resizable: true, filter: true },
  { headerName: 'Autogen', field: '67', sortable: true, resizable: true, filter: true },
  { headerName: 'Autogen Program', field: '68', sortable: true, resizable: true, filter: true },
  { headerName: 'Ignore PC', field: '69', sortable: true, resizable: true, filter: true },
  { headerName: 'Append PC to Alt ID', field: '70', sortable: true, resizable: true, filter: true },
  { headerName: 'Carrier List for PC Autogen', field: '71', sortable: true, resizable: true, filter: true },
  { headerName: 'PC Assignment Method', field: '72', sortable: true, resizable: true, filter: true },
  { headerName: 'PC Appended to ID', field: '73', sortable: true, resizable: true, filter: true },
  { headerName: 'PC Length', field: '74', sortable: true, resizable: true, filter: true },
  { headerName: 'Alternate ID', field: '75', sortable: true, resizable: true, filter: true },
  { headerName: 'Alternate ID Type (PC)', field: '76', sortable: true, resizable: true, filter: true },
  { headerName: 'Alternate ID Type (w/o PC)', field: '77', sortable: true, resizable: true, filter: true },
  { headerName: 'Autogen', field: '78', sortable: true, resizable: true, filter: true },
  { headerName: 'Autogen Base Length', field: '79', sortable: true, resizable: true, filter: true },
  { headerName: 'Autogen Prefix', field: '80', sortable: true, resizable: true, filter: true },
  { headerName: 'Override with Inbound', field: '81', sortable: true, resizable: true, filter: true },
  { headerName: 'EOB ID', field: '82', sortable: true, resizable: true, filter: true },
  { headerName: 'CET ID', field: '83', sortable: true, resizable: true, filter: true },
  { headerName: 'Report ID', field: '84', sortable: true, resizable: true, filter: true },
  { headerName: 'MAB dtl ID', field: '85', sortable: true, resizable: true, filter: true },
  { headerName: 'Card ID', field: '86', sortable: true, resizable: true, filter: true },
  { headerName: 'Mail Order Form ID', field: '87', sortable: true, resizable: true, filter: true },
  { headerName: 'X-Walk for FSA', field: '88', sortable: true, resizable: true, filter: true },
  { headerName: 'Alt ID Length', field: '89', sortable: true, resizable: true, filter: true },
  { headerName: '3rd ID Field', field: '90', sortable: true, resizable: true, filter: true },
  { headerName: 'ID in QL', field: '91', sortable: true, resizable: true, filter: true },
  { headerName: 'Multi File Feeds', field: '93', sortable: true, resizable: true, filter: true },
  { headerName: 'B2B', field: '95', sortable: true, resizable: true, filter: true },
  { headerName: 'Pre-Preload', field: '96', sortable: true, resizable: true, filter: true },
  { headerName: 'Pre-Preload Program', field: '97', sortable: true, resizable: true, filter: true },
  { headerName: 'Mapping to Format', field: '98', sortable: true, resizable: true, filter: true },
  { headerName: 'Client File Transmission', field: '99', sortable: true, resizable: true, filter: true },
  { headerName: 'File Routing', field: '101', sortable: true, resizable: true, filter: true },
  { headerName: 'File Type', field: '102', sortable: true, resizable: true, filter: true },
  { headerName: 'History Method', field: '103', sortable: true, resizable: true, filter: true },
  { headerName: 'Type 4', field: '104', sortable: true, resizable: true, filter: true },
  { headerName: 'Type 5', field: '105', sortable: true, resizable: true, filter: true },
  { headerName: 'Type 5 Email', field: '106', sortable: true, resizable: true, filter: true },
  { headerName: 'Type 5 Address', field: '107', sortable: true, resizable: true, filter: true },
  { headerName: 'Type 5 Privacy', field: '108', sortable: true, resizable: true, filter: true },
  { headerName: 'Privacy Moves Level', field: '109', sortable: true, resizable: true, filter: true },
  { headerName: 'Batch Override Protection', field: '110', sortable: true, resizable: true, filter: true },
  { headerName: 'Load Rrts Delivered', field: '111', sortable: true, resizable: true, filter: true },
  { headerName: 'Report NDM/FTP Profile', field: '112', sortable: true, resizable: true, filter: true },
  { headerName: 'File Frequency', field: '113', sortable: true, resizable: true, filter: true },
  { headerName: 'Exclude Holidays', field: '114', sortable: true, resizable: true, filter: true },
  { headerName: 'Variance', field: '115', sortable: true, resizable: true, filter: true },
  { headerName: 'Vendor', field: '92', sortable: true, resizable: true, filter: true },
  { headerName: 'Term Dates', field: '117', sortable: true, resizable: true, filter: true },
  { headerName: 'Term Date Thru', field: '118', sortable: true, resizable: true, filter: true },
  { headerName: 'Dep Terms on File', field: '119', sortable: true, resizable: true, filter: true },
  { headerName: 'Dep Imp Terms', field: '120', sortable: true, resizable: true, filter: true },
  { headerName: 'Dep Imp Terms Method', field: '121', sortable: true, resizable: true, filter: true },
  { headerName: 'Implied Terms', field: '122', sortable: true, resizable: true, filter: true },
  { headerName: 'Implied Term Level', field: '123', sortable: true, resizable: true, filter: true },
  { headerName: 'Move History', field: '124', sortable: true, resizable: true, filter: true },
  { headerName: 'Move Accums', field: '125', sortable: true, resizable: true, filter: true },
  { headerName: 'Move Prior Auths', field: '126', sortable: true, resizable: true, filter: true },
  { headerName: 'Include Termed Records', field: '127', sortable: true, resizable: true, filter: true },
  { headerName: 'Move All Claims', field: '128', sortable: true, resizable: true, filter: true },
  { headerName: 'Term by Absence', field: '129', sortable: true, resizable: true, filter: true },
  { headerName: 'Ignore Future Terms', field: '130', sortable: true, resizable: true, filter: true },
  { headerName: 'Include Future Effectives', field: '131', sortable: true, resizable: true, filter: true },
  { headerName: 'TBA Date', field: '132', sortable: true, resizable: true, filter: true },
  { headerName: 'Range/List Used', field: '133', sortable: true, resizable: true, filter: true },
  { headerName: 'Account List', field: '134', sortable: true, resizable: true, filter: true },
  { headerName: 'Group List', field: '135', sortable: true, resizable: true, filter: true },
  { headerName: 'Manual TBA Protection', field: '136', sortable: true, resizable: true, filter: true },
  { headerName: 'Compare Process', field: '137', sortable: true, resizable: true, filter: true },
  { headerName: 'ROBOT Name', field: '138', sortable: true, resizable: true, filter: true },
  { headerName: 'Email/NDM Script', field: '139', sortable: true, resizable: true, filter: true },
  { headerName: 'Compare Frequency', field: '140', sortable: true, resizable: true, filter: true },
  { headerName: 'Term Threshold (Incl Moves)', field: '144', sortable: true, resizable: true, filter: true },
  { headerName: 'Term Threshold (Excl Moves)', field: '145', sortable: true, resizable: true, filter: true },
  { headerName: 'Term Threshold (Actives)', field: '146', sortable: true, resizable: true, filter: true },
  { headerName: 'Include Future Terms', field: '147', sortable: true, resizable: true, filter: true },
  { headerName: 'Family ID Threshold', field: '148', sortable: true, resizable: true, filter: true },
  { headerName: 'Demographic Match Level', field: '149', sortable: true, resizable: true, filter: true },
  { headerName: 'ID Card Threshold', field: '166', sortable: true, resizable: true, filter: true },
  { headerName: 'Entire Pop Cards', field: '167', sortable: true, resizable: true, filter: true },
  { headerName: 'ID On Card', field: '168', sortable: true, resizable: true, filter: true },
  { headerName: 'Printing', field: '169', sortable: true, resizable: true, filter: true },
  { headerName: 'SSN Validation', field: '170', sortable: true, resizable: true, filter: true },
  { headerName: 'ID Card Report Output', field: '171', sortable: true, resizable: true, filter: true },
  { headerName: 'Printed On Card', field: '172', sortable: true, resizable: true, filter: true },
  { headerName: 'Regardless of CAG', field: '173', sortable: true, resizable: true, filter: true },
  { headerName: 'Moved Member Card', field: '174', sortable: true, resizable: true, filter: true },
  { headerName: 'Suppression Level', field: '175', sortable: true, resizable: true, filter: true },
  { headerName: 'Card Triggers', field: '176', sortable: true, resizable: true, filter: true },
  { headerName: 'Multi Birth', field: '177', sortable: true, resizable: true, filter: true },
  { headerName: 'Auto-CAG', field: '178', sortable: true, resizable: true, filter: true },
  { headerName: 'Group Files', field: '179', sortable: true, resizable: true, filter: true },
  { headerName: 'Group Suspension', field: '180', sortable: true, resizable: true, filter: true },
  { headerName: 'Group Retention Days', field: '181', sortable: true, resizable: true, filter: true },
  { headerName: 'Prescriber/Physician Files', field: '182', sortable: true, resizable: true, filter: true },
  { headerName: 'Diagnosis Files', field: '183', sortable: true, resizable: true, filter: true },
  { headerName: 'Lab Files', field: '184', sortable: true, resizable: true, filter: true },
  { headerName: 'Member Suspense', field: '185', sortable: true, resizable: true, filter: true },
  { headerName: 'Member Retention Days', field: '186', sortable: true, resizable: true, filter: true },
  { headerName: 'Client Product Code', field: '187', sortable: true, resizable: true, filter: true },
  { headerName: 'Client Rider Code', field: '188', sortable: true, resizable: true, filter: true },
  { headerName: 'Care Network', field: '189', sortable: true, resizable: true, filter: true },
  { headerName: 'Care Facility', field: '190', sortable: true, resizable: true, filter: true },
  { headerName: 'Care Facility On the Fly', field: '191', sortable: true, resizable: true, filter: true },
  { headerName: 'Care Qualifier', field: '192', sortable: true, resizable: true, filter: true },
  { headerName: 'Care Qualifier On the Fly', field: '193', sortable: true, resizable: true, filter: true },
  { headerName: 'PCP', field: '194', sortable: true, resizable: true, filter: true },
  { headerName: 'PCP On the Fly', field: '195', sortable: true, resizable: true, filter: true },
  { headerName: 'Member Level Plans', field: '196', sortable: true, resizable: true, filter: true },
  { headerName: 'Client Defined Data', field: '197', sortable: true, resizable: true, filter: true },
  { headerName: 'Grace Period', field: '198', sortable: true, resizable: true, filter: true },
  { headerName: 'Healthcare Exchange Client', field: '199', sortable: true, resizable: true, filter: true },
  { headerName: 'New Benefit Plan Year', field: '200', sortable: true, resizable: true, filter: true },
  { headerName: 'Client OE File', field: '201', sortable: true, resizable: true, filter: true },
  { headerName: 'OE Window', field: '202', sortable: true, resizable: true, filter: true },
  { headerName: 'OE File', field: '203', sortable: true, resizable: true, filter: true },
  { headerName: 'OE File Date', field: '204', sortable: true, resizable: true, filter: true },
  { headerName: 'OE Cards', field: '205', sortable: true, resizable: true, filter: true },
  { headerName: 'OE Terms', field: '206', sortable: true, resizable: true, filter: true },
  { headerName: 'OE Term Drops', field: '207', sortable: true, resizable: true, filter: true },
  { headerName: 'Data After OE File', field: '208', sortable: true, resizable: true, filter: true },
  { headerName: 'Data After OE File Date', field: '209', sortable: true, resizable: true, filter: true },
  { headerName: 'OE New Benefit Year File', field: '210', sortable: true, resizable: true, filter: true },
  { headerName: 'OE New Benefit Year File Date', field: '211', sortable: true, resizable: true, filter: true },
  { headerName: 'OE New Benefit Year File Terms', field: '212', sortable: true, resizable: true, filter: true },
  { headerName: 'CAG Structure Change', field: '213', sortable: true, resizable: true, filter: true },
  { headerName: 'OE Test File', field: '214', sortable: true, resizable: true, filter: true },
  { headerName: 'EC Audited', field: '215', sortable: true, resizable: true, filter: true },
  { headerName: 'Auditor', field: '216', sortable: true, resizable: true, filter: true },
  { headerName: 'Audit Date', field: '217', sortable: true, resizable: true, filter: true },
  { headerName: 'TBA Threshold (#)', field: '141', sortable: true, resizable: true, filter: true },
  { headerName: 'TBA Threshold (%)', field: '142', sortable: true, resizable: true, filter: true },
  { headerName: 'MLX Change Threshold (#)', field: '227', sortable: true, resizable: true, filter: true },
  { headerName: 'MLX Change Threshold (%)', field: '228', sortable: true, resizable: true, filter: true },
  { headerName: 'New Pop Threshold (#)', field: '237', sortable: true, resizable: true, filter: true },
  { headerName: 'New Pop Threshold (%)', field: '151', sortable: true, resizable: true, filter: true },
  { headerName: 'Mbr ID Change Threshold (#)', field: '236', sortable: true, resizable: true, filter: true },
  { headerName: 'Mem ID Change Threshold (%)', field: '153', sortable: true, resizable: true, filter: true },
  { headerName: 'First Name Change Threshold (#)', field: '158', sortable: true, resizable: true, filter: true },
  { headerName: 'First Name Change Threshold (%)', field: '159', resizable: true, sortable: true, filter: true },
  { headerName: 'Last Name Change Threshold (#)', field: '155', sortable: true, resizable: true, filter: true },
  { headerName: 'Last Name Change Threshold (%)', field: '156', resizable: true, sortable: true, filter: true },
  { headerName: 'DOB Change Threshold (#)', field: '161', sortable: true, resizable: true, filter: true },
  { headerName: 'DOB Change Threshold (%)', field: '162', sortable: true, resizable: true, filter: true },
  { headerName: 'Rel Change Threshold (#)', field: '164', sortable: true, resizable: true, filter: true },
  { headerName: 'Rel Change Threshold (%)', field: '165', sortable: true, resizable: true, filter: true },
  { headerName: 'Error Threshold (#)', field: 'flErrorThresholdCnt', sortable: true, resizable: true, filter: true },
  { headerName: 'Error Threshold (%)', field: '143', sortable: true, resizable: true, filter: true }
];

export function createMasterEprColumns(masterViewColumns: string[]) {
  const customMasterEprColumns = [];

  masterViewColumns.forEach(columnId => {
    customMasterEprColumns.push(masterEprColumns.filter(column => column.field === columnId)[0]);
  });

  return customMasterEprColumns;
}

export function statusToolValues(currentValue: any) {
  if (currentValue && currentValue.outputText) {
    const progressBarPercent = (currentValue.rowSequence / currentValue.totalTranslations) * 100;
    const progressBar = '<div>' + currentValue.outputText + '</div><div style="background-color: ' + currentValue.statusColour + '; ' +
      'border-radius: 2px; padding: 1%; width: ' + progressBarPercent + '%; margin-top: -5%;">' +
      '</div>';
    return currentValue.outputText ? progressBar : '';
  }
}
