import {Observable, BehaviorSubject, Subject, of} from 'rxjs';
import { environment } from './../../environments/environment';
import { CVSHttpClient } from 'angular-component-library';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {catchError, filter, map} from "rxjs/operators";
import {B} from "@angular/cdk/keycodes";

@Injectable({
  providedIn: 'root'
})
export class MessageSupressionService {
  addNewMsgSupRow$ = new BehaviorSubject(null);
  addNewMapTypeRow$ = new BehaviorSubject(null);
  addNewRejectActionRow$ = new BehaviorSubject(null);
  addNewValidationRow$ = new BehaviorSubject(null);
  addNewFieldActionsRow$ = new BehaviorSubject(null);
  addNewReportingMessageTypesRow$ = new BehaviorSubject(null);
  addNewReportMessageCodesRow$ = new BehaviorSubject(null);
  refreshRelationshipCodeData$ = new BehaviorSubject(null);
  refreshSexCodeData$ = new BehaviorSubject(false);
  refreshFamilyTypeData$ = new BehaviorSubject(false);
  refreshPrintFormatCodeData$ = new BehaviorSubject(false);
  refreshMemberTypeData$ = new BehaviorSubject(false);
  refreshMemberCoverageTypeData$ = new BehaviorSubject(false);
  refreshAidTypeData$ = new BehaviorSubject(false);
  refreshLicsData$= new BehaviorSubject(false);
  refreshEthnicityData$= new BehaviorSubject(false);
  refreshLanguageData$= new BehaviorSubject(false);
  saveNewMsgSupRecords$ = new BehaviorSubject(false);
  saveNewMapTypeRecords$ = new BehaviorSubject(false);
  saveNewRejectActionrecords$ = new BehaviorSubject(false);
  saveNewFieldLookuprecords$ = new BehaviorSubject(false);
  saveNewValidationRecords$  = new BehaviorSubject(false);
  saveNewFieldActionsRow$= new BehaviorSubject(false);
  saveNewReportingMessageTypesRow$ = new BehaviorSubject(false);
  saveNewReportingMessageCodes$= new BehaviorSubject(false);
  saveVersionRecords$ = new Subject();
  addVersionRow$ = new Subject();
  addNewReasonCodesRow$ = new BehaviorSubject(null);
  saveNewReasonCodes$= new BehaviorSubject(false);

  constructor(private http: CVSHttpClient) { }


  getAllMsgSupressRows(type: boolean = false): Observable<any> {
    const typeUrl = type ? 'mapType' : 'msgSuppression';
    return this.http.get(`${environment.baseUrl}/mapping/get?type=${typeUrl}`);
  }
  getAllrejectedActionRows(): Observable<any> {
    return this.http.get(`${environment.baseUrl}/rejectaction/get`);
  }
  getValidationRows(): Observable<any> {
    return this.http.get(`${environment.baseUrl}/mapping/get?type=validations`);
  }

  getReasonCodesRows(): Observable<any> {
    return this.http.get(`${environment.baseUrl}/mapping/get?type=reasonCodes`);
  }

  getRejectActionsRows(): Observable<any> {
    return this.http.get(`${environment.baseUrl}/mapping/get?type=rejectActions`);
  }

  getFiledLookupRows(type: any): Observable<any> {
    return this.http.get(`${environment.baseUrl}/mapping/get?type=${type}`);
  }

  getFieldActionsRows(): Observable<any> {
    return this.http.get(`${environment.baseUrl}/mapping/get?type=fieldActions`);
  }
  getMessageCodesRows(): Observable<any> {
    return this.http.get(`${environment.baseUrl}/mapping/get?type=reportingMessageCodes`);
  }
  getReportingMessageTypesRows(): Observable<any> {
    return this.http.get(`${environment.baseUrl}/mapping/get?type=reportingMessageTypes`);
  }

  getReportingMessageCodes(): Observable<any> {
    return this.http.get(`${environment.baseUrl}/mapping/get?type=reportingMessageCodes`);
  }


  saveSortedRecord(request: any, type: boolean = false): Observable<any> {
    return this.http.post(`${environment.baseUrl}/sortorder/save`, request);
  }
  saveMsgSupressRow(request: any, type: boolean = false): Observable<any> {
    return this.http.post(`${environment.baseUrl}/mapping/save`, request);
  }
  saveRejectedActionRow(request: any, type: boolean = false): Observable<any> {
    return this.http.post(`${environment.baseUrl}/rejectaction/save`, request);
  }
  saveFieldActionsRow(request: any, type: boolean = false): Observable<any> {
    return this.http.post(`${environment.baseUrl}/rejectaction/save`, request);
  }
  deleteMsgSupressRow(request: any, type: boolean = false): Observable<any> {
    return this.http.post(`${environment.baseUrl}/mapping/delete`, request);
  }
  deleteRejectActionRow(request: any, type: boolean = false): Observable<any> {
    return this.http.post(`${environment.baseUrl}/rejectaction/delete`, request);
  }
  deleteFieldActionsRow(request: any, type: boolean = false): Observable<any> {
    return this.http.post(`${environment.baseUrl}/rejectaction/delete`, request);
  }
  onMsgSupressMessage(): Observable<any> {
    return this.addNewMsgSupRow$.asObservable();
  }
  addNewRow(newRow: any) {
    this.addNewMsgSupRow$.next(newRow);
  }
  addNewMapTypeRow(newRow: any) {
    this.addNewMapTypeRow$.next(newRow);
  }
  addNewRejectActionRow(newRow: any) {
    this.addNewRejectActionRow$.next(newRow);
  }
  onMapTypeMessage(): Observable<any> {
    return this.addNewMapTypeRow$.asObservable();
  }
  onRejectActionMessage(): Observable<any> {
    return this.addNewRejectActionRow$.asObservable();
  }

  onFieldActionsMessage(): Observable<any> {
    return this.addNewFieldActionsRow$.asObservable();
  }
  onReportingMessageTypeMessage(): Observable<any> {
    return this.addNewReportingMessageTypesRow$.asObservable();

  }

  onReportMessageCodesMessage(): Observable<any> {
    return this.addNewReportMessageCodesRow$.asObservable();
  }

  addNewValidationRow(newRow: any) {
    this.addNewValidationRow$.next(newRow);
  }
  onValidationMessage(): Observable<any> {
    return this.addNewValidationRow$.asObservable();
  }

  addVersionRow(newRow: any) {
    this.addVersionRow$.next(newRow);
  }
  addNewFieldActionsRow(newRow: any) {
    this.addNewFieldActionsRow$.next(newRow);
  }
  addNewReportingMessageTypesRow(newRow: any) {
    this.addNewReportingMessageTypesRow$.next(newRow);
  }
  addNewReportMessageCodesRow(newRow: any) {
    this.addNewReportMessageCodesRow$.next(newRow);
  }
  addNewReasonCodesRow(newRow: any) {
    this.addNewReasonCodesRow$.next(newRow);
  }
  onReasonCodes(): Observable<any> {
    return this.addNewReasonCodesRow$.asObservable();
  }

  getNewVewsionRow(){
    return this.addVersionRow$.asObservable();
  }
  getAllVersionData(type: String): Observable<any> {
    return this.http.get(`${environment.baseUrl}/mapping/get?type=${type}`);
  }
  saveVersionRow(request: any, type: boolean = false): Observable<any> {
    return this.http.post(`${environment.baseUrl}/mapping/save`, request);
  }
  deleteVersionRow(request: any, type: boolean = false): Observable<any> {
    return this.http.post(`${environment.baseUrl}/mapping/delete`, request);
  }

}
