import { AppState } from './../../../reducers/index';
import { Store } from '@ngrx/store';
import { DeleteBtnCellRendererComponent } from './../../../common/DeleteBtnCellRenderer';
import { CVSAlertService, CVSAlertType } from 'angular-component-library';
import { MessageSupressionService } from './../../../services/message-supression.service';
import { AgGridAngular } from 'ag-grid-angular';
import { Component, OnInit, Output, ViewChild, EventEmitter, AfterViewInit, HostListener } from '@angular/core';
export interface MsgSupressModel {
  id: any;
  input: string;
}
export const msgSupressGridColumns = [
  {
    headerName: 'Id', field: 'id', hide: true
  },
  {
    headerName: 'Message', field: 'message',
    editable: true,
    sortable: true,
    filter: true, lockVisible: true, lockPosition: true, resizable: true,
    heigth: 300,
    width: 440,
    cellEditor: 'agLargeTextCellEditor',
    cellEditorPopup: true,
    cellEditorParams: {
      maxLength: 1000,
      rows: 10,
      cols: 50
    }
  },
  {
    headerName: 'Action',
    cellRenderer:  DeleteBtnCellRendererComponent,
    editable: false,
    width: 90
  }
];


@Component({
  selector: 'app-msg-supress-data-grid',
  templateUrl: './msg-supress-data-grid.component.html',
  styleUrls: ['./msg-supress-data-grid.component.scss']
})
export class MsgSupressDataGridComponent implements OnInit, AfterViewInit {
  @ViewChild('msgSupressGrid') msgSupressGrid: AgGridAngular;
  @Output() isSaveMsgCalled: EventEmitter<any> = new EventEmitter();

  dataRows = [];
  hlGridHeight = 350;
  contextObj: any;
  readonly: boolean;

  selectedStatus = '';

  translationAddMessage: any;
  translationEditMessage: any;
  translationDeleteMessage: any;
  changedRow: any;


  constructor(private msgSupressService: MessageSupressionService, private alertService: CVSAlertService, private store: Store<AppState>) {

    this.contextObj = {
      componentParent: this
    };
  }
  ngOnInit() {
    this.getAllRows();
    this.hlGridHeight = 350;
  }

  ngAfterViewInit(): void {
    setTimeout(() => { this.addNewRow(); this.saveUnSavedRecords();
                       this.alertService.clearAlert('#msg-alert');
    }, 500);

  }
  saveUnSavedRecords() {

    this.msgSupressService.saveNewMsgSupRecords$.subscribe(resp => {
      const updatedRec = [];

      if (resp) {
        this.msgSupressGrid.api.forEachNode(node => {
          if (node.data.isUpdated || node.data.isNew) {
            delete node.data.isUpdated;
            delete node.data.isNew;
            updatedRec.push(node.data);
          }
        });
        if(updatedRec.length > 0){
          this.msgSupressService.saveMsgSupressRow(updatedRec).subscribe(_ => {
            this.readonly = false;
    
            this.alertService.sendAlert({
                headline: 'Save successful!',
                alertType: CVSAlertType.Success,
                removedAfterMilliseconds: 5000,
                alertDocumentHolderName: '#msg-alert',
              });
            
              // this.store.dispatch(new LoadMasterDataSetAction('holidays'));
            });
        }
       
      }
    });

  }
  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    if (this.readonly) {

      if (confirm('Changes have NOT been saved! Click \'OK\' to exit WITHOUT saving.')) {
        return true;
      } else {
        return false;
      }
    }
  }
  addNewRow() {
    this.msgSupressService.onMsgSupressMessage().subscribe(newRow => {
      if (newRow != null) {
        this.msgSupressGrid.api.applyTransaction({
          addIndex: 0,
          add: newRow
        });
        this.hlGridHeight = this.msgSupressGrid.api.getModel()?(this.msgSupressGrid.api.getDisplayedRowCount() * 42) + 52 :this.hlGridHeight;
    
        this.msgSupressService.addNewMsgSupRow$.next(null)
        
      }

    });
  }
  getAllRows() {
    this.msgSupressService.getAllMsgSupressRows().subscribe(res => {
      this.dataRows = res;
      this.dataRows.sort((a, b) => a.id - b.id);
    });
    this.changedRow = false;
  }
  showAlert(alertMessage, alertType) {
    this.alertService.sendAlert({
      headline: alertMessage,
      alertType,
      removedAfterMilliseconds: 5000,
      alertDocumentHolderName: '#msg-alert',
    });
  }
  onGridReady(params) {
    this.msgSupressGrid = params;
    this.hlGridHeight = this.dataRows.length * 51;
    this.msgSupressGrid.api.setGridOption("columnDefs", msgSupressGridColumns);
    this.msgSupressGrid.api.redrawRows();
  }
  UpdatedRow(cell) {
    cell.data.isUpdated = true;
    this.readonly = true;

  }
  emitSave() {
    this.isSaveMsgCalled.emit();
  }

  public deleteTableRecord(node, colId) {
    if (node.data.isNew) {
      this.readonly = false;
      this.msgSupressGrid.api.applyTransaction({remove:[node.data]});
      this.hlGridHeight = (this.msgSupressGrid.api.getDisplayedRowCount() * 42) + 52;
    } else {
      this.msgSupressService.deleteMsgSupressRow([{ ...node.data }]).subscribe(resp => {
        this.msgSupressGrid.api.applyTransaction({remove:[node.data]});
        this.hlGridHeight = (this.msgSupressGrid.api.getDisplayedRowCount() * 42) + 52;
      });
    }
  
  }
}
