import { Component, Input, OnInit } from '@angular/core';
import { GridOptions } from "ag-grid-community";
import { CVSAlertService, CVSAlertType } from 'angular-component-library';
import { RteSuspenseService } from 'src/app/services/rte-suspense.service';
import { scheduledEventsGridCol } from './scheduled-events-grid-column-sets';
import { BehaviorSubject, Subject, of } from "rxjs";
import { RecipientTooltipComponent } from 'src/app/common/TooltipComponent';

@Component({
  selector: 'app-scheduled-events-grid',
  templateUrl: './scheduled-events-grid.component.html',
  styleUrls: ['./scheduled-events-grid.component.scss']
})
export class ScheduledEventsGridComponent implements OnInit {
  @Input() userId: any;
  @Input() isAdmin: boolean;

  private gridApi;
  columnDefs
  gridOptions = {
    rowClass: 'cvs-ag-grid-row',
    rowSelection: 'multiple',
    suppressRowClickSelection: true
  } as GridOptions;
  selectedColDefinition: (any)[];
  gridRowData: any[];
  isRowSelected: boolean;
  selectedRowsArr: any;

  constructor(private suspenseService: RteSuspenseService, private alertService: CVSAlertService) { }

  ngOnInit(): void {

    this.columnDefs = scheduledEventsGridCol;
    this.getGridData();

  }

  getGridData() {
    this.suspenseService.getScheduleEventsRecords(this.userId, this.isAdmin).subscribe(
      res => {
        if (res.response && res.response.length > 0) {
          this.gridRowData = res.response;
        } else if (res.response && res.response.length === 0) {
          this.gridRowData = [];
        } else if (!res.response) {
          this.gridRowData = [];
        }else if (res.errorMessage) {
          this.gridRowData = [];
          this.alertService.sendAlert({
            headline: res.errorMessage,
            alertType: CVSAlertType.Warning,
            removedAfterMilliseconds: 10000,
            alertDocumentHolderName: '#header-alert',
          })
        }
      },
      err => {
        this.gridRowData = [];
        this.alertService.sendAlert({
          headline: 'Something went wrong. Please try again.',
          alertType: CVSAlertType.Error,
          removedAfterMilliseconds: 10000,
          alertDocumentHolderName: '#header-alert',
        })
      })
  }

  onGridReady(params) {
    this.gridApi = params.api;
  }
}
