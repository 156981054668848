import {AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import * as fromRoot from '../../reducers';
import { AppState,
  getProjectIterations,
  getProjectStatus,
  getProjectTrackerDisplayNames,
  getProjectType,
  getReadOnly,
  getSelectedProjectIndex,
  getProjectPhase,
  getStatusDates,
  getEPRState,
  getCurrentGoLiveDate,
  getCheckListDrivers,
  getDrivers,
  getHeaderState
} from '../../reducers';
import {Store} from '@ngrx/store';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Permission} from '../../models/Authorization';
import {FormType} from '../../models/FormType';
import {initialState} from '../../reducers/project-tracker.reducer';
import {combineLatest, Observable, of, Subscription } from 'rxjs';
import { switchMap, tap } from "rxjs/operators";
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {AddProjectDialogContentComponent} from './add-project-dialog-content/add-project-dialog-content';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import {DeleteProjectTrackerFormAction, ProjectOrTestingEdit, SelectProjectAction} from '../../actions/epr.action';
import {CVSAlertService, CVSAlertType, Direction} from 'angular-component-library';
import {ProjectTypeChangeAction} from '../../actions/project-tracker.action';
import {ProjectType, projectTypeChecklistMetadata} from '../../models/ProjectTypeEnum';
import {ConfirmationDialogComponent} from '../confirmation-dialogue/confirmation-dialog.component';
import {AlertType} from '../../models/AlertType';
import {ConfirmationDialogData} from '../questionnaire/confirmation-dialog-content/confirmation-dialog-content.component';
import {getRole} from '../../reducers';
import {DataManagementService} from "../../services/data-management.service";
import {testResultsFormConfig} from "../testing/TestingConfig";
import { UsersService } from 'src/app/services/users.service';
import { AgGridAngular } from 'ag-grid-angular';
import * as ColumnSets from './project-tracker-column-sets';
import { EditBtnCellRendererComponent } from 'src/app/common/EditBtnCellRenderer';
import * as moment from 'moment';
import { MatAccordion } from '@angular/material/expansion';
import { baselineTasksConfig, discoveryTasksConfig, goLiveConfig, idCardsConfig, integratedFeedsTasksConfig, setupTasksConfig, testTasksConfig } from '../checklist/ChecklistTasksConfig';
import {InputType} from '../../models/FormFieldConfig';
import { ChecklistSettingsService } from 'src/app/services/checklist-settings.service';
import { PopulateChecklistFieldsEnabledStateAction, ToggleFieldEnabledStatusAction } from 'src/app/actions/checklist-fields-enabled.action';
import {EprService} from '../../services/epr.service';
import { ProjectConfirmationDialogComponent } from './project-confirmation-dialogue/project-confirmation-dialog.component';

const progressBarColor = {
  black: 'black-progress',
  red: 'red-progress',
  yellow: 'yellow-progress',
  green: 'green-progress',
  default: 'default-progress'
}
@Component({
  selector: 'app-project-tracker',
  templateUrl: './project-tracker.component.html',
  styleUrls: ['./project-tracker.component.scss']
})

export class ProjectTrackerComponent implements OnInit, OnDestroy, AfterViewInit{
  @ViewChild('projecttrackergrid') projecttrackergrid: AgGridAngular;
  @ViewChild(MatAccordion) accordion: MatAccordion;
  @Input() eprId: number;
  inputType = InputType;
  expandAll: boolean = true;
  Permission = Permission;
  right = Direction.RIGHT;
  FormType = FormType;
  formType = FormType.CHECKLIST;
  checklistForm = new UntypedFormGroup({});
  selectedProjectIndex$ = this.store.select(getSelectedProjectIndex) as Observable<number>;
  projectTrackerOptions$ = this.store.select(getProjectTrackerDisplayNames) as Observable<string[]>;
  projectIterations$ = this.store.select(getProjectIterations) as Observable<string[]>;
  projectTrackerState = this.store.select(fromRoot.getProjectTrackerState);
  checkListDrivers$ = this.store.select(getCheckListDrivers);
  questionnaireDriver$ = this.store.select(fromRoot.getDrivers)
  status$ = this.store.select(getProjectStatus);
  readOnly$ = this.store.select(getReadOnly) as Observable<boolean>;
  projectTypeStore = this.store.select(getProjectType);
  readOnlySubscription: Subscription;
  saveSubscription: Subscription;
  isProjectClicked: boolean = false;
  projectTrackerForm: UntypedFormGroup = new UntypedFormGroup({});
  projectTypeOptions = Object.keys(ProjectType).map(key => ProjectType[key]);
  projectTypeChecklistMetadata;
  projectPhaseOptions = [
    '',
    'Not Started',
    'In Discovery',
    'In Setup',
    'Waiting on BSR',
    'Awaiting FTP Login ID',
    'Ready for QC',
    'Awaiting Test File',
    'Testing',
    'Awaiting Baseline',
    'Loading Baseline',
    'Awaiting EPR Sign-Off',
    'Ready for Go-Live',
    'On Hold',
    'Completed',
    'Cancelled'
  ];
  projectCardStatusOptions = [
    '',
    'Y - Pending',
    'Y - Requested',
    'Unknown',
    'N/A'
  ];

  projectIterList: Array<any> = [];
  projectType: any;
  projectTypeSubscription:Subscription;
  projectIterIndexSubscription: Subscription;
  validationSubscribe: Subscription;
 // projectIterList$ = this.store.select(getProjectIterations);
  // goLiveDate$: Observable<string>;
  projectList : any[] = [];
  allowedValues : any[] = [];
  role: any;
  dataRows: any[] = [];
  isLoading: boolean = false;
  contextObj: any;
  isReadOnly: boolean = true;
  prjStatus:any;
  drivers: any [] = []
  checklistSections = [
    {
      sectionConfig: discoveryTasksConfig,
      header: 'Discovery',
      abbreviation: 'newDisc',
      noteControlName: 'discoveryNotes',
      showToggles: false,
      isSaving:false
    },
    {
      sectionConfig: setupTasksConfig,
      header: 'Setup',
      abbreviation: 'newSetup',
      noteControlName: 'setupPhaseNotes',
      showToggles: false,
      isSaving:false
    },
    {
      sectionConfig: testTasksConfig,
      header: 'Testing',
      abbreviation: 'newTst',
      noteControlName: 'testNote',
      showToggles: false,
      hasExpectedDate: true,
      isSaving:false
    },
    {
      sectionConfig: integratedFeedsTasksConfig,
      header: 'Integrated Feeds',
      abbreviation: 'newIntFds',
      noteControlName: 'integFeedMigNotes',
      showToggles: false,
      hasNoDates: false,
      isSaving:false
    },
    {
      sectionConfig: baselineTasksConfig,
      header: 'Baseline',
      abbreviation: 'newBL',
      noteControlName: 'baselineNote',
      showToggles: false,
      hasExpectedDate: true,
      isSaving:false
    },
    {
      sectionConfig: idCardsConfig,
      header: 'Printed Materials',
      abbreviation: 'newIDCrds',
      noteControlName: 'iDCardTaskNotes',
      showToggles: false,
      isSaving:false
    },
    {
      sectionConfig: goLiveConfig,
      header: 'Go-Live',
      abbreviation: 'goLive',
      noteControlName: 'goLiveNote',
      showToggles: false,
      hasGoLiveDate: true,
      isSaving:false
    }
  ];
  checklistSectionProgress = {
    newDisc: 0,
    newSetup: 0,
    newTst: 0,
    newIntFds: 0,
    newBL: 0,
    newIDCrds: 0,
    goLive: 0
  };
  checklistFieldMetadata;
  selectedProjectIndexSubscription: Subscription;
  projectTrackerDataRows$:Subscription;
  subs: Subscription[] = [];
  users: any=[];
  isRefresh: boolean;
  projectDetail: any;
  disableSave: boolean = false;
  headerState: any;
  eprState: any;
  projectsToDelete: Array<any> = [];
  goLiveDisplayDate: any;
  isEprReadOnly: boolean;
  isAddDisabled: boolean;
  projectData: any = null;
  
  constructor(private store: Store<AppState>, private eprService: EprService, public element: ElementRef, private checklistSettingsService: ChecklistSettingsService,
    private fb: UntypedFormBuilder, public dialog: MatDialog, private dataManagementService: DataManagementService,private usersService: UsersService,
    private alertService: CVSAlertService) {
      this.subs.push(this.checklistSettingsService.getChecklistMasterMetadata().subscribe(metadata => {
        this.checklistFieldMetadata= metadata;}))
      this.contextObj = { componentParent: this,currentPage: "projectTracker", projectTrackerReadOnly:this.isReadOnly };
    }

  ngOnInit() {
    this.subs.push(this.store.select(getHeaderState).subscribe(resp=>{this.headerState = resp}),
    this.store.select(getEPRState).subscribe(resp=>this.eprState= resp),
    this.eprService.triggerProjectTestingConfirmation$.subscribe(resp=>{
      if(resp && !this.isReadOnly){
        this.openProjectModal();
      }
    })
  )
    this.populateProjectTableData();
    this.eprService.reloadPrj.subscribe((reload)=>{
      if(reload && this.isProjectClicked){


        this.populateProjectTableData(true)

      }
    })
    // this.selectedProjectIndexSubscription = this.selectedProjectIndex$.subscribe(index => {
    //   this.goLiveDate$ = this.store.select(getCurrentGoLiveDate, index);
    // });


    this.populateChecklistFormControls();
    this.status$.subscribe(status=> { this.prjStatus = status })
    this.projectTrackerForm = this.fb.group(initialState.projects[0]);
    this.usersService.userRole.subscribe(role => {
      if (role) {
        this.role = role;
      }
    });

    this.readOnlySubscription = this.readOnly$.subscribe(readOnly => {
    this.isEprReadOnly = readOnly;
    if(!this.isEprReadOnly){  //epr in edit mode
      this.isReadOnly = true;
      this.projectTrackerForm.disable({emitEvent:false});
      this.checklistForm.disable({emitEvent:false});
      this.store.dispatch( new ProjectOrTestingEdit(!this.isReadOnly));
    }
    });

    // this.projectIterIndexSubscription = this.projectIterList$.subscribe(projectIterList => {
    //   this.projectIterList = projectIterList;
    // });

    this.dataManagementService.getMasterDataList().subscribe(res => {
      if(res) {
        const dataListOptions = [];
        res.options?.forEach(option => dataListOptions.push(option.replace(/\\,/g, ',')));
        res.options = dataListOptions;
        this.projectList = res;
      }
    });
    this.usersService.getUsersByRole([Permission.TESTER_AUDITOR]).subscribe(res =>{
      this.users= res;
    });
  }
  onGridReady(params) {
    this.projecttrackergrid = params;
    this.projecttrackergrid?.api.setGridOption("columnDefs", ColumnSets.projectTrackerColumns);
    this.projecttrackergrid?.api.redrawRows();

    this.projecttrackergrid.gridOptions = {
      editType: "fullRow",
      onCellValueChanged(data) { this.UpdatedRow(data); 
      },
      getRowStyle: params =>{
        if(params.node.isSelected()){
          return{background:"#B8E3EB"}
        }
      },

    }

  }
  get getProjectTrackersDisplayNames() {
    // @ts-ignore
    return (new Date(this.projectDetail?.prjStartDte).yymmdd() + ' ' + this.projectDetail?.projectType) || null;
  };


  populateChecklistFormControls() {
    this.checklistSections.map((checklistSection: any) => {
      // Add the note
      this.checklistForm.addControl(checklistSection.noteControlName, new UntypedFormControl(''));
      checklistSection.sectionConfig.map(checklistTask => {
        this.checklistForm.addControl(checklistTask.formControlName, new UntypedFormControl(''));
        if (checklistTask.extraTaskField) {
          this.checklistForm.addControl(
            checklistTask.extraTaskField.controlName, new UntypedFormControl(''));
        }
      });

      if (!checklistSection.hasNoDates) {
        // Add the expected date for testing and baseline
        if (checklistSection.hasExpectedDate) {
          this.checklistForm.addControl(checklistSection.abbreviation + 'ExptDate', new UntypedFormControl(''));
        }
        // Add the due and end dates to the form group
        if (!checklistSection.hasGoLiveDate) {
          this.checklistForm.addControl(checklistSection.abbreviation + 'DueDate', new UntypedFormControl(''));
        }
        this.checklistForm.addControl(checklistSection.abbreviation + 'CmpltDate', new UntypedFormControl(''));
      }
      //checklistSection.className = this.populateprogressBarColor(checklistSection);
    });
  }
  orgDateValueChange(newDateVal, checklistSection){
    checklistSection.className = this.populateprogressBarColor(checklistSection);
  }
  populateProjectTableData(refresh=false){

   this.subs.push(this.eprService.getProjectTabData(this.eprId).subscribe(data=>{
      let projectList = (data && data.response) ? data.response: [];
      let rows= [];
      if(projectList && projectList.length > 0){
        projectList = projectList?.sort((a,b) => b.projectId -a.projectId);
        projectList.forEach(prj => {
          let obj = this.formatTableData(prj);
          rows.push({...obj});
        });


        if(refresh){
          this.eprService.reloadPrj.next(false)
        } 
        this.projectIterList = rows;
      }
      this.projecttrackergrid.api.setGridOption("rowData", this.projectIterList);
      this.disableAdd()
    })
  );
  }
  formatTableData(prj: any) {
    let formattedDate =  prj?.projStartDate ? moment(prj?.projStartDate).format('YYMMDD'): "";
    const obj = {
            projectId: prj.projectId,
            prjStartDte: prj?.projStartDate,
            projectName: "Project - "+ formattedDate +" "+ (prj.projectType? prj.projectType : ""),
            projectType: prj.projectType ? prj.projectType: "",
            prjPhase: prj.prjPhase? prj.prjPhase: "",
            prjGoLiveDte: prj.prjGoLiveDte ? prj.prjGoLiveDte: "",
            prjStatus: prj.prjStatus ? prj.prjStatus: "",
            prjCardStatus: prj.projcardStatus ? prj.projcardStatus: "",
            QCdBy: prj.projQcBy? this.getQcBy(prj.projQcBy, true): "",
            prjQCDte: prj.projQcmpltDate ? prj.projQcmpltDate: "",
            newDiscDueDate: prj.newDiscDueDate? prj.newDiscDueDate: "",
            newDiscCmpltDate: prj.newDiscCmpltDate ? prj.newDiscCmpltDate: "",
            newSetupDueDate: prj.newSetupDueDate ? prj.newSetupDueDate: "",
            newSetupCmpltDate: prj.newSetupCmpltDate ? prj.newSetupCmpltDate: "",
            newTstDueDate: prj.newTstDueDate ? prj.newTstDueDate: "",
            newTstCmpltDate:prj.newTstCmpltDate ? prj.newTstCmpltDate: "",
            newBLDueDate: prj.newBLDueDate ? prj.newBLDueDate: "",
            newBLCmpltDate: prj.newBLCmpltDate ? prj.newBLCmpltDate: "",
            newIDCrdsDueDate: prj.newIDCrdsDueDate ? prj.newIDCrdsDueDate : "",
            newIDCrdsCmpltDate: prj.newIDCrdsCmpltDate ? prj.newIDCrdsCmpltDate: "",
            eligibilityType: prj.eligType ? prj.eligType: "",
            ftpid: prj.ftpid ? prj.ftpid: "",
            vendor: prj.vendor ? prj.vendor: "",
            pgCriteria: prj.pgCriteria ? prj.pgCriteria: "",
            ssoeMigrated: prj.consolidateProfileLogic ? prj.consolidateProfileLogic: "",
            format:prj.format ? prj.format : ""
    }
    return obj;
  }
  populateprogressBarColor(checklistSection) {
    let returnValue = progressBarColor.default;
    if(this.checklistForm.controls[checklistSection.abbreviation + 'CmpltDate']?.value){
      returnValue= progressBarColor.black;
    } else{
      let cntrlName = checklistSection.abbreviation + 'DueDate';
      let checklistDueDateContrl = this.checklistForm.controls[cntrlName];
      if(checklistDueDateContrl && checklistDueDateContrl.value) {
        let isDuedateOver =  moment(checklistDueDateContrl.value).isSameOrBefore(moment());
        let warningDate =   moment(checklistDueDateContrl.value).isBetween(moment(), moment().add(7,'d'))
        if(isDuedateOver && !warningDate) {
          returnValue= progressBarColor.red;
        } else{
          returnValue = warningDate ?  progressBarColor.yellow: progressBarColor.green;
        }
      }
    }
    return returnValue;
  }
  saveChecklistMetadata(section){
    this.checklistSections.forEach(section=>{
      if(section.showToggles){
        section.isSaving= true
      }

    })
    this.saveProjectDetail();
    /*
    for(let task of Object.keys(this.projectTypeChecklistMetadata)){
      if(this.checklistFieldMetadata[task]){
        this.checklistFieldMetadata[task].checklistProjectTypeMetadata?.forEach(checklistTask=> {
          if(checklistTask.projectType === this.projectType){
            checklistTask.labelEnabled = this.projectTypeChecklistMetadata[task]
            return
        }})
      }
    }
    this.checklistSettingsService.saveChecklistMasterMetadata(this.checklistFieldMetadata).subscribe(() => {
      section.showToggles = false
      this.checklistSections.forEach(section=>{

        section.isSaving= false
      })
    })
    */
  }
  expandCollapse(isExpanded){
    this.expandAll  = !isExpanded;
    isExpanded ? this.accordion?.openAll(): this.accordion?.closeAll();
  }
  toggleTheToggles(section: any) {

    section.showToggles = !section.showToggles;
  }
  toggleDisable(controlName: string) {
    this.projectTypeChecklistMetadata[controlName]= !this.projectTypeChecklistMetadata[controlName]
    this.setChecklistStatus(this.projectTypeChecklistMetadata)
    this.setChecklistBasedOnQuestionnaire(this.drivers[0], this.drivers[1])
    this.calculateChecklistSectionsProgress();
  }
 resetSections(){
  this.checklistSections.forEach(section=>{
    section.showToggles = false;

  })
 }
  ngAfterViewInit() {
   this.subs.push( this.projectTrackerForm.valueChanges.subscribe(fields => {
      if(fields.prjStartDte && fields.projectType) {
        //this.eprService.setEnableSaveButton(true);
        this.disableSave = false;
      } else {
        //this.eprService.setEnableSaveButton(false);
        this.disableSave = true
      }
    }),
    this.checklistForm.valueChanges.subscribe(val=>{
      this.calculateChecklistSectionsProgress();
    })
  );
      setTimeout(() => {
        this.validationSubscribe = combineLatest(
          this.checkListDrivers$, this.questionnaireDriver$).pipe(
          tap(([ checkListDrivers, questionnaireDrivers]) => {
            this.drivers = [ questionnaireDrivers, checkListDrivers]
            this.setChecklistStatus(this.projectTypeChecklistMetadata)
            this.setChecklistBasedOnQuestionnaire(questionnaireDrivers, checkListDrivers)
            this.calculateChecklistSectionsProgress();

          })
        ).subscribe();
      }, 1000);
  }


  private setChecklistStatus(metadata){
    if(metadata){
      for(let task of Object.keys(this.projectTypeChecklistMetadata)){
        if(!metadata[task] && task !== 'id' ){
          this.checklistForm.controls[task]?.disable({emitEvent: false});
        }else{
          this.checklistForm.controls[task]?.enable({emitEvent: false})
        }
      }
    }
  }
  private setChecklistBasedOnQuestionnaire(questionnaireDrivers, checkListDrivers){
    if (questionnaireDrivers.checkListBsrComplete) {
      this.checklistForm.controls['bSRActDate']?.disable({emitEvent: false});
    }
    if (!questionnaireDrivers.isPrePreLoadedUsed) {
      this.checklistForm.controls['prePreTstCpltDate']?.disable({emitEvent: false});
      this.checklistForm.controls['prePreCpltDate']?.disable({emitEvent: false});
    }
    if (questionnaireDrivers.checkListGroupFilesNotSent) {
      this.checklistForm.controls['grpPLISetup']?.disable({emitEvent: false});
      this.checklistForm.controls['grpStageCpltDate']?.disable({emitEvent: false});
    }
    if (!questionnaireDrivers.isQCDDUSed) {
      this.checklistForm.controls['cDDCmpltDte']?.disable({emitEvent: false});
      this.checklistForm.controls['cDDCarLstCmpltDte']?.disable({emitEvent: false});
    }
    if (questionnaireDrivers.checkListTransmitWithoutSecureFtp) {
      this.checklistForm.controls['cOLAActDate']?.disable({emitEvent: false});
    }
    if (questionnaireDrivers.checkListLoadReportsDelivered) {
      this.checklistForm.controls['fTPFormLoadRptsCmpltDte']?.disable({emitEvent: false});
    }
    if (!questionnaireDrivers.willPrintIDCards) {
      this.checklistForm.controls['reqManCrdFileCmpltDte']?.disable({emitEvent: false});
      this.checklistForm.controls['qCManCrdFileCmpltDte']?.disable({emitEvent: false});
      this.checklistForm.controls['manCrdFileSntVendorCmpltDte']?.disable({emitEvent: false});
      this.checklistForm.controls['qCCardProofCmpltDate']?.disable({emitEvent: false});
      this.checklistForm.controls['reqAutoRunCdCmpltDt']?.disable({emitEvent: false});
      this.checklistForm.controls['newIDCrdsCmpltDate']?.disable({emitEvent: false});
      this.checklistForm.controls['iDCardShellActDate']?.disable({emitEvent: false});
    }
    if (questionnaireDrivers.isMedDUsed) {
      this.checklistForm.controls['qLFlagCompDate']?.disable({emitEvent: false});
    }
    if (!questionnaireDrivers.isMedDUsed) {
      this.checklistForm.controls['fourrxscreentoannacmpltdate']?.disable({emitEvent: false});
      this.checklistForm.controls['fourrxIDconfigverifydate']?.disable({emitEvent: false});
    }
    if (questionnaireDrivers.formatNot834) {
      this.checklistForm.controls['gISTestCmpltDate']?.disable({emitEvent: false});
    }
    if (!questionnaireDrivers.isQuesMultiCarrierUsed) {
      this.checklistForm.controls['clientCodeVerifyDate']?.disable({emitEvent: false});
    }
    if (questionnaireDrivers.isGroupListUsedWithOpts) {
      this.checklistForm.controls['crtCAGListCmpltDate']?.disable({emitEvent: false});
    }
    if (checkListDrivers && questionnaireDrivers) {
      if(this.projectData !== null){
        if (!this.projectData.projectTracker.checklist?.libraryActDate) {
          this.checklistForm.controls['fastlinkActDate']?.disable({emitEvent: false});
        }

        if (!this.projectData.projectTracker.checklist?.libraryActDate || questionnaireDrivers.checkListGroupFilesNotSent) {
          this.checklistForm.controls['fastlinkGroupDate']?.disable({emitEvent: false});
        }
      }
    
    if (!questionnaireDrivers.isXWalkUsed) {
      this.checklistForm.controls['xwalkProfileActDate']?.disable({emitEvent: false});
    }
    if (questionnaireDrivers.isMemIDMoreThan5) {
      this.checklistForm.controls['sHRTIDSRCHActDate']?.disable({emitEvent: false});
    }
  }

  }
  private calculateChecklistSectionsProgress() {
    this.checklistSections.map(checklistSection => {
      let numberOfFields = 0;
      let completedFields = 0;
      checklistSection.sectionConfig.map(checklistTask => {
        const formControl = this.checklistForm.get(checklistTask.formControlName);
        if (!formControl.disabled) {
          numberOfFields++;

          if (formControl.value && (formControl.value.length > 0 || formControl.value instanceof moment)) {
            completedFields++;
          }
        }
      });
      if (numberOfFields === 0) { // Solves divide by 0 errors
        numberOfFields = 1;
      }
      this.checklistSectionProgress[checklistSection.abbreviation] =
        Math.trunc(completedFields / numberOfFields * 100);
    });
  }
  getBarClass(checklistSection: any){
    let returnValue = 'black-progress';
    if(this.checklistForm.controls[checklistSection.abbreviation + 'CmpltDate']?.value){
      returnValue= 'black-progress';
    } else{
      let cntrlName = checklistSection.abbreviation + 'DueDate';
      let checklistDueDateContrl = this.checklistForm.controls[cntrlName];
      if(checklistDueDateContrl && checklistDueDateContrl.value) {
        let isDuedateOver =  moment(checklistDueDateContrl.value).isSameOrBefore(moment());
        let warningDate =   moment(checklistDueDateContrl.value).isBetween(moment(), moment().add(7,'d'))
        if(isDuedateOver && !warningDate) {
          returnValue= 'red-progress';
        } else{
          returnValue = warningDate ? 'yellow-progress': 'green-progress';
        }
      }
    }
    return returnValue;
  }
  disableAdd(){
   this.isAddDisabled =  this.projectIterList.length ? (this.projecttrackergrid?.api?.getRowNode('0')?.data?.projectId ? false :true ) : false ;
  }
  addProject() {
    const dialogRef = this.dialog.open(AddProjectDialogContentComponent);
    dialogRef.afterClosed().subscribe(data => {
      if(data){
        data.eprId = this.eprId
        data.projStartDate = data?.prjStartDte;
        let obj: any = this.formatTableData(data);
        this.projectIterList.unshift(data);
        this.projecttrackergrid.api.setGridOption("rowData", this.projectIterList);
        // this.projecttrackergrid?.api?.updateRowData({
        //   add: [data],
        //   addIndex: 0,
        // });

        // this.projecttrackergrid?.api?.refreshView()
        this.disableAdd();
        const node = this.projecttrackergrid.api.getRowNode('0')
        const event = {node,data:node.data}
        this.onRowClicked(event);
      }
    });
  }
  UpdatedRow(event) {
  }
  onFilterChange(event) {}
  sortChange(event) {}
  onRowDragEnd(event){}

  openProjectModal() {
    // @ts-ignore
    this.dialog.open(ProjectConfirmationDialogComponent, {
      data: {
        body: 'Save or discard Project changes to continue  ',
        type: AlertType.CONFIRMATION,
        header:"Project Changes Unsaved" ,
        width:"30rem",
        onConfirm: () => {return;}
      } as ConfirmationDialogData
    });
  }
  onRowClicked(event){

    if(this.isLoading && !this.isReadOnly){
      return
    }
   else{
    if(!this.isReadOnly){ //project was in edit mode
     this.openProjectModal();
     return;
    }
    this.isReadOnly = true;

    event.node.setSelected(true)
    this.isProjectClicked = true;
    this.projectType = event.data.projectType;

    if(!event.node.data.projectId){
    this.projectDetail = event.node.data;
    this.goLiveDisplayDate =  this.projectDetail?.prjGoLiveDte || new Date();
    this.patchValue(event.node.data);
    //new project get admin checklist data specific to project
    this.projectTypeChecklistMetadata = this.projectTypeChecklistMetadata?this.projectTypeChecklistMetadata : projectTypeChecklistMetadata
    for(let task of Object.keys(this.projectTypeChecklistMetadata)){

      this.checklistFieldMetadata[task].checklistProjectTypeMetadata?.forEach(checklistTask=> {
        if(checklistTask.projectType === this.projectType){
          this.projectTypeChecklistMetadata[task] = checklistTask.labelEnabled
          return
      }})

    }
    return;
    }
    this.isLoading = true;
    this.eprService.getProjectDetail(event.node.data.projectId).pipe(switchMap(resp=>{
       if(resp){
       this.projectData = resp;
       this.projectTypeChecklistMetadata = this.projectData?.projectTracker?.checklist?.checklistFieldsEnabledModel || projectTypeChecklistMetadata;
       }
      /*
      if(this.projectType !== event.data.projectType){

        this.isLoading = true;

        if(!this.checklistFieldMetadata){
          this.projectTypeChecklistMetadata = false
        return  this.checklistSettingsService.getChecklistFieldsEnabledByProjectType(event.data.projectType).pipe(switchMap(res=> {
          this.store.dispatch(new PopulateChecklistFieldsEnabledStateAction(res))
        this.projectTypeChecklistMetadata = this.projectType = event.data.projectType? res: this.projectTypeChecklistMetadata
        this.projectType = event.data.projectType
        this.isLoading= false;
        return of(resp);
      }))



        } else{

          this.projectType = event.data.projectType
          this.projectTypeChecklistMetadata = this.projectTypeChecklistMetadata?this.projectTypeChecklistMetadata : projectTypeChecklistMetadata
          for(let task of Object.keys(this.projectTypeChecklistMetadata)){

            this.checklistFieldMetadata[task].checklistProjectTypeMetadata?.forEach(checklistTask=> {
              if(checklistTask.projectType === this.projectType){
                this.projectTypeChecklistMetadata[task] = checklistTask.labelEnabled
                return
            }})

          }
          this.isLoading= false;

        }

      }
      */

      return of(resp);
    })).subscribe(response=>{
      this.isLoading = false;
      this.projectDetail = response.projectTracker;
      this.goLiveDisplayDate =  this.projectDetail?.prjGoLiveDte || new Date();
      this.changeReadMode(true);
      this.patchValue();

    })





    event.node.setSelected(true)
    this.isProjectClicked = true;

    // this.projectIterList.forEach((project)=>{if(project.id === event.node.data.projectId){
    //   index = this.projectIterList.indexOf(project)
    // }})
    // this.store.dispatch(new SelectProjectAction(index));
    }
  }
  public deleteTableRecord(event, colId) {
    this.isProjectClicked = false;
    this.openDeleteProjectIterationModal(event, event.rowIndex);
  }
  openDeleteProjectIterationModal(node, iteration: number) {
    // @ts-ignore
    this.dialog.open(ConfirmationDialogComponent, {
      data: {
        body: 'Are you sure you want to delete this Project: ' + node.data?.projectName + '?',
        additionalInfo: "Warning: This cannot be undone.",
        type: AlertType.CONFIRMATION,
        buttonText: 'Confirm Delete',
        onConfirm: () => this.deleteProject(node, iteration)
      } as ConfirmationDialogData
    });
  }

  deleteProject(node, iteration: number) {
    if (this.projectIterList.length <= 0) {
    } else {
      if(node.data.projectId){
        this.eprService.deleteProjectIteration([node.data.projectId]).subscribe(resp=>{
         this.saveBanner({delete:true})
         });
      }
      this.projecttrackergrid?.api?.applyTransaction({remove:[node.data]});
      this.projectIterList.splice(iteration,1);
      // if (this.projectIterList[iteration]?.id === undefined) {
      //   this.store.dispatch(new DeleteProjectTrackerFormAction(null, iteration));
      // } else {
      //   this.store.dispatch(new DeleteProjectTrackerFormAction(this.projectIterList[iteration].id, iteration));
      // }

    }
    this.disableAdd();
    this.dialog.closeAll();
  }

  selectProject($event: MatSelectChange) {
    this.store.dispatch(new SelectProjectAction($event.value));
  }

  ngOnDestroy(): void {
    // if(this.readOnlySubscription){
    //   this.readOnlySubscription.unsubscribe();
    // }
    // this.projectIterIndexSubscription.unsubscribe();
    this.subs.forEach(sub=>sub.unsubscribe());
  }

  onProjectTypeChange($event: MatSelectChange) {
    this.store.dispatch(new ProjectTypeChangeAction($event.value));
  }
  getHyperlink(controlName: string) {
    if (this.checklistFieldMetadata) {
      return this.checklistFieldMetadata[controlName]['hyperlink'];
    } else {
      return null;
    }
  }
  getProjectType(projectType: any) {
    if (this.projectList.length > 0 &&  projectType) {
      const currentProject = this.projectList.filter(obj => obj.listType === projectType);
      if(currentProject && currentProject.length > 0) {
        return currentProject?.[0]?.options;
      } else {
        return
      }
    }
  }

  getQcBy(id:any, aggrid= false) {
    const user = this.users.find(t=>t.id === id);
    if(user && aggrid){
      return  user.lastName +", "+ user.firstName;
    }
    if(user){
      return user.firstName +" "+ user.lastName;
    }

  }

  changeReadMode(readOnly){
    this.isReadOnly = readOnly;
    this.store.dispatch( new ProjectOrTestingEdit(!this.isReadOnly));
    if(this.isReadOnly){
      this.patchValue();
      this.projectTrackerForm.disable({emitEvent: false});
      this.checklistForm.disable({emitEvent: false});
      this.checklistSections.forEach(section=>{
        section.showToggles = false;
        section.isSaving= false
      })

    }else{
      
      if(!this.projectTrackerForm.get('prjStartDte')?.value || !this.projectTrackerForm.get('projectType')?.value) {
      //  this.eprService.setEnableSaveButton(false); //change it to current save button be disabled
        this.disableSave = true;
      }
      this.checklistForm.enable({emitEvent:false})
      this.setChecklistStatus(this.projectTypeChecklistMetadata)
      this.setChecklistBasedOnQuestionnaire(this.drivers[0], this.drivers[1])
      this.calculateChecklistSectionsProgress();
        this.projectTrackerForm.enable({emitEvent: false});
        this.projectTrackerForm.get('prjStartDte').setValidators([Validators.required, Validators.minLength(8)]);
        this.projectTrackerForm.get('projectType').setValidators([Validators.required]);
        this.projectTrackerForm.get('projectType').markAsTouched();

        if(!this.projectTrackerForm.get('prjStartDte').value) {
          this.projectTrackerForm.get('prjStartDte').setErrors({'required':true});
          this.projectTrackerForm.get('prjStartDte').markAsTouched();
        }
        if (this.projectList.length > 0 ) {
          this.allowedValues = this.projectList.filter(obj => obj.listType === 'projectType')[0]?.options;
          if (this.allowedValues && !this.allowedValues.includes(this.projectType)) {
            this.projectTrackerForm.get('projectType').setErrors({'required':true});
            this.projectTrackerForm.get('projectType').markAsTouched();
          }
        }



    }
  }
  patchValue(newData?) {

    if(newData){
      this.projectTrackerForm.patchValue(newData);
      this.checklistForm.reset();
      return;
    }
    this.projectTrackerForm.patchValue(this.projectDetail);
    this.checklistForm.patchValue(this.projectDetail?.checklist);
  }
  saveProjectDetail(){

   const payload = {
    eprId : this.eprState.id,
    eprName : "string",
    editorId : this.eprState?.editorId,
     header:{...this.headerState},
     projectTracker:{...this.projectTrackerForm.getRawValue(),
      id: this.projectDetail?.id || null,
      eprId : this.eprState.id,
      oldPrjStartDte : this.projectDetail?.oldPrjStartDte,
      oldProjectType: this.projectDetail?.oldProjectType,
      checklist: {
        id:this.projectDetail?.checklist?.id || null,
        ...this.checklistForm.getRawValue(),
        checklistFieldsEnabledModel: {
          ...this.projectTypeChecklistMetadata,
          id: this.projectDetail?.checklist?.checklistFieldsEnabledModel?.id || null
        }
      }
     },
     eprHistory:this.eprService.getCommonFiledsHistoryObj(this.eprState.id, this.headerState, new Date(), 'Update')

   }
   this.isLoading = true;
   this.eprService.saveProjectDetail(payload).subscribe(resp=>{
    if(resp?.status==="SUCCESS"){
      this.saveBanner({success:true});
      this.projectDetail = resp?.response?.projectTracker;
      this.projectData = resp?.response
      this.changeReadMode(true);

      if(!payload.projectTracker.id){

      }
       this.populateProjectTableData();
      }else{
        this.saveBanner({error:true,errorMessage:resp?.errorMessage});
      }
      this.isLoading = false;
      this.checklistSections.forEach(section=>{
        section.showToggles = false;
        section.isSaving= false
      })
   })

  }

  saveBanner(save){
    if(save?.success){
      this.alertService.sendAlert({
        headline: 'Saved Successfully',
        alertType: CVSAlertType.Success,
        alertDocumentHolderName: '#project-alert',
        removedAfterMilliseconds: 4000,
      })
   }else if(save?.delete){
    this.alertService.sendAlert({
      headline: 'Deleted Successfully',
      alertType: CVSAlertType.Success,
      alertDocumentHolderName: '#project-alert',
      removedAfterMilliseconds: 4000,
    })
  }
    else if(save?.error){
      this.alertService.sendAlert({
        headline: save?.errorMessage? save?.errorMessage : 'Error',
        alertType: CVSAlertType.Error,
        alertDocumentHolderName: '#project-alert',
        removedAfterMilliseconds: 4000,
      })
    }
   }


}
