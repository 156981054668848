import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import {LogicCodeApiService} from '../../../services/logic-code-api.service';
import {LogicCodeService} from '../logic-code.service';
import {DropdownOption} from '../../../models/DropdownOption';
import { map, filter, debounceTime, distinctUntilChanged, catchError } from 'rxjs/operators';
@Component({
  selector: 'app-add-logic',
  templateUrl: './add-logic.component.html',
  styleUrls: ['./add-logic.component.scss']
})
export class AddLogicComponent implements OnInit {
  form: UntypedFormGroup;
  description: string;
  providedInfo: any;
  statusList = [
    {value: 'pending', displayName: 'Pending'},
    {value: 'active', displayName: 'Active'},
    {value: 'inactive', displayName: 'Inactive'}
  ] as DropdownOption[];
  mapTypeList = [];
  rxClaimFieldsList = [];
  searchRxClaimField: UntypedFormGroup;
  filteredRxClaimList: any[];
  constructor(
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<AddLogicComponent>,
    @Inject(MAT_DIALOG_DATA) data ,
    private api: LogicCodeApiService,
    private logicService: LogicCodeService,
    ) {

    this.description = data.description;
    this.providedInfo = data;
    }

  ngOnInit() {
    this.createGroup();
    this.getMapTypes();
  }
  createGroup() {
    this.form = new UntypedFormGroup({
      logicCode: new UntypedFormControl('', [Validators.required] ),
      mapType: new UntypedFormControl('', [Validators.required] ),
      rxClaimField: new UntypedFormControl( '', [Validators.required]),
      fieldTag: new UntypedFormControl(null),
      mappingInstructions: new UntypedFormControl('', [Validators.required] ),
      mappingDescription: new UntypedFormControl( '', [Validators.required]),
      logicCodeStatus: new UntypedFormControl('', [Validators.required] )
    });
    // this.form.get('logicCodeStatus').valueChanges.pipe( distinctUntilChanged()).subscribe(input => {
    //  this.updateFieldTag(input);
    // });
    if (this.providedInfo.type === 'edit_logic') {
      this.form.patchValue( this.providedInfo.editData, {emitEvent: false});
    } else {

    this.form.get('logicCodeStatus').patchValue('Pending', {emitEvent: false});
    }
    this.rxClaimFieldsList = this.providedInfo.rxClaimFieldsList;
    this.filteredRxClaimList = this.rxClaimFieldsList;
    if (!this.providedInfo.rxClaimFieldsList) {
      this.getRxClaimFields();
    }
    this.form.get('rxClaimField').valueChanges.pipe( distinctUntilChanged()).subscribe(input => {

      if (input && input.length) {
       this.filteredRxClaimList = this.rxClaimFieldsList.filter(rx => {
        if (rx?.toLowerCase().startsWith(input?.toLowerCase())) {
          return rx;
        }
       });
      } else {
        this.filteredRxClaimList = this.rxClaimFieldsList;

      }
    });
  }
  // updateFieldTag(updatedLogicValue?) {
  //   const control =  this.form.get('fieldTag');
  //   if (!updatedLogicValue) {
  //     updatedLogicValue = this.form.get('logicCodeStatus').value;
  //   }
  //   if (updatedLogicValue === this.statusList[1].displayName) {
  //     control.setValidators(Validators.required);
  //     if (!control.value) {
  //       control.setErrors({required: true});
  //     }
  //     control.markAsTouched();
  //    } else {
  //     control.clearValidators();
  //    }
  //   control.updateValueAndValidity();
  // }

  getMapTypes() {
    return this.api.getMapTypesApi().subscribe(data => {
      if (data) {
        this.mapTypeList = data;
      }
    });
  }

  getRxClaimFields() {
    return this.api.getRxclaimFieldsApi().subscribe(data => {
      if (data) {
        this.rxClaimFieldsList = data;
        this.filteredRxClaimList = data;
      }
    });
  }


  save() {
    // this.updateFieldTag();
    const field = (this.form.get('rxClaimField')) as UntypedFormControl;
      const currentValue = field.value;
      if (this.rxClaimFieldsList.indexOf(currentValue) === -1) { // user input not found or not yet selected from dropdown.
        field.setErrors({required: true});
      }
      this.form.markAllAsTouched();

      if (!this.form.invalid) {
        this.dialogRef.close(this.form.value);
      }

}

close() {
    this.dialogRef.close(null);
}


}
