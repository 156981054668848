import { Store } from '@ngrx/store';
import { CVSAlertService, CVSAlertType } from 'angular-component-library';
import { Component, OnInit, Output, ViewChild, EventEmitter, AfterViewInit, HostListener } from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import {MessageSupressionService} from "../../../services/message-supression.service";
import {DeleteBtnCellRendererComponent} from "../../../common/DeleteBtnCellRenderer";
import {
  AddEditFieldLookupPopupComponent
} from "../add-edit-relationshipCode-item-popup/add-edit-field-lookup-popup.component";
import {DataManagementService} from "../../../services/data-management.service";
import {AppState} from "../../../reducers";
import {Subscription} from "rxjs";
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

export const ethnicityColumns = [
  {
    headerName: 'Id', field: 'id', hide: true
  },
  {
    headerName: 'Value', field: 'message',
    editable: false,
    sortable: false,
    filter: true, lockVisible: true, lockPosition: true, resizable: true,
    heigth: 300, width: 150,
    cellEditor: 'agLargeTextCellEditor',
    cellEditorPopup: true,
    cellRenderer: (params) => {
      return `<mat-icon class="mat-icon md-16 material-symbols-outlined"
      style="margin-right: 5px;"
      role="img" aria-hidden="true">
      drag_indicator</mat-icon>${params.data?.message}`;
    },
    cellEditorParams: { maxLength: 1000, rows: 10, cols: 50 },
    unSortIcon: true
  },
  {
    headerName: 'Description', field: 'messageDescription',
    editable: false,
    sortable: false,
    filter: true, lockVisible: true, lockPosition: true, resizable: false,
    heigth: 300, flex:2,
    cellEditor: 'agLargeTextCellEditor',
    cellEditorPopup: true,
    cellEditorParams: { maxLength: 1000, rows: 10, cols: 50 },
    unSortIcon: true
  },
  {
    headerName: '',
    cellRenderer: DeleteBtnCellRendererComponent,
    editable: false,
    width: 100,
    cellStyle: { textAlign: 'right'}
  },
  {
    headerName: 'sortOrder', field: 'sortOrder', hide: true
  },
];
@Component({
  selector: 'app-ethnicity',
  templateUrl: './ethnicity.component.html',
  styleUrls: ['./ethnicity.component.scss']
})
export class EthnicityComponent implements OnInit, AfterViewInit {
  @ViewChild('ethnicityGrid') ethnicityGrid: AgGridAngular;
  dataRows = [];
  hlGridHeight = 350;
  contextObj: any;
  selectedStatus = '';
  changedRow: any;
  readonly: boolean;
  isRowDragged: boolean = false;
  isItemDeleted: boolean = false;
  filteredData: any =[];
  dialogConfig:any = new MatDialogConfig();
  isDelete: boolean = false;
  type = 'ethnicity'
  subscription: Subscription;
  noChanges: boolean = true;
  constructor(private msgSupressService: MessageSupressionService, private alertService: CVSAlertService, private store: Store<AppState>,
              private dataManagementService: DataManagementService,private dialog: MatDialog,
              private messageSupressionService:MessageSupressionService) {
    this.contextObj = {
      componentParent: this
    };
  }

  ngOnInit(): void {
    this.subscription=this.messageSupressionService.refreshEthnicityData$.subscribe(resp => {
      if(resp){
        this.getAllRows();
      }
    })
    this.getAllRows();
    this.hlGridHeight = 350;
  }


    ngAfterViewInit(): void {
    setTimeout(() => {
      this.saveUnSavedRecords();
      this.alertService.clearAlert('#ethnicity-alert');
    }, 500);

  }

  ngOnDestroy(){
    this.subscription.unsubscribe();
  }
  onGridSizeChanged($event){
    this.ethnicityGrid?.api.sizeColumnsToFit()
  }
  getAllRows() {
    this.msgSupressService.getFiledLookupRows(this.type).subscribe(res => {
        if(res && res.length && res.length> 0) {
          this.dataRows = res;
          this.dataManagementService.addRowdata.next(this.dataRows);
        }
    });
    this.changedRow = false;
  }

  onGridReady(params) {
    this.ethnicityGrid = params;
    this.hlGridHeight = this.dataRows.length * 51;
    this.ethnicityGrid.api.setGridOption("columnDefs", ethnicityColumns)
    this.ethnicityGrid.api.sizeColumnsToFit();
    this.ethnicityGrid.api.redrawRows();
  }


  saveUnSavedRecords() {
    this.msgSupressService.saveNewFieldLookuprecords$.subscribe(resp => {
      const updatedRec = [];
      let nodeData: any = {}
      if (resp) {
        let isAddorEditNode =  this.ethnicityGrid.api.getRenderedNodes()?.some(node=> (node.data.isNew || node.data.isUpdated));
        this.ethnicityGrid.api.forEachNode(node => {
          if(node.data?.isNew || node.data?.isUpdated) {
            delete node.data?.isUpdated;
            delete node.data?.isNew
            delete node.data?.action;
            nodeData = node.data;
            nodeData.type= 'ethnicity';
            nodeData.sortOrder = nodeData.sortOrder ? nodeData.sortOrder: node.rowIndex;
            updatedRec.push(nodeData);
          }
          if(this.isRowDragged || this.isItemDeleted) {
            nodeData = node.data;
            nodeData.sortOrder = node.rowIndex;
            updatedRec.push(nodeData);
          }
        });

        if (updatedRec && updatedRec.length) {
          if(isAddorEditNode) {
            this.msgSupressService.saveMsgSupressRow(updatedRec, true).subscribe(_ => {
              this.getAllRows();
              this.readonly = false;
              this.showAlert('Save successful!', CVSAlertType.Success)
            });
          }
           if(this.isRowDragged || this.isItemDeleted) {
            this.msgSupressService.saveSortedRecord(updatedRec, true).subscribe(_ => {
              this.getAllRows();
              this.isRowDragged = false;
              this.readonly = false;
              this.showAlert(this.isItemDeleted? 'Delete successful!': 'Save successful!', CVSAlertType.Success)
              this.isItemDeleted = false;
            });
          }
        }
      }
    });
  }

  private showAlert(alertMessage, alertType) {
    this.alertService.sendAlert({
      headline: alertMessage,
      alertType: alertType,
      removedAfterMilliseconds: 5000,
      alertDocumentHolderName: '#ethnicity-alert',
    });
  }

  sortChange(event){
    const sortOrder = event.api.getSortModel();
    this.ethnicityGrid.api.applyColumnState({
      state: sortOrder,
    });
  }


  onRowDragEnd(event) {
    this.isRowDragged = true;
    this.checkForChanges()
  }
  public updateRecord(node, record) {
    const currentRecordId = node?.data?.id;
    this.dataRows.map((item)=>  {
      if(item.id === currentRecordId) {
        node.data.isUpdated = true;
        item.message = record[0].message;
        item.messageDescription = record[0].messageDescription;
        return
      }
    });
    this.msgSupressService.saveNewFieldLookuprecords$.next(true);
    this.ethnicityGrid.api.setGridOption("rowData", this.dataRows);
  }

  public deleteTableRecord(node, colId) {
    if (node.data.isNew) {
      this.readonly = false;
      this.ethnicityGrid.api.applyTransaction({remove: [node.data]});
      this.hlGridHeight = (this.ethnicityGrid.api.getModel().getRowCount() * 42) + 52;
    } else {
      this.msgSupressService.deleteMsgSupressRow([{ ...node.data }], false).subscribe(resp => {
        this.ethnicityGrid.api.applyTransaction({remove: [node.data]});
        this.isItemDeleted = true;
        this.msgSupressService.refreshEthnicityData$.next(true);
        this.hlGridHeight = (this.ethnicityGrid.api.getModel().getRowCount() * 42) + 52;
      });
    }
    this.checkForChanges()
  }

  onFilterChange(event: any) {
    this.filteredData = [];
    event.api.forEachNodeAfterFilter((node) =>{
      this.filteredData.push(node.data);
    })
    this.dataManagementService.addRowdata.next(this.filteredData);
  }
  save(){
    let updatedSortOrder = this.ethnicityGrid.api.getRenderedNodes()
    .map((x, index) => { x.data.sortOrder = index; return x.data})

    this.messageSupressionService.saveMsgSupressRow(updatedSortOrder, true).subscribe(res=>{
      this.alertService.sendAlert({
        headline: 'Sort order save successful!',
        alertType: CVSAlertType.Success,
        removedAfterMilliseconds: 5000,
        alertDocumentHolderName: '#master-data-alert',
      });
      this.ethnicityGrid.api.setGridOption('rowData',updatedSortOrder)
    })
    this.noChanges = true

  }
  checkForChanges() {
    let newValue = this.ethnicityGrid.api.getRenderedNodes().map(x => x.data)
    for (let index = 0; index < this.dataRows.length; index++) {
      if (newValue[index].id !== this.dataRows[index].id) {
        this.noChanges = false
        return
      }
    }
    this.noChanges = true;
  }


  onRowClicked($event: any) {
    if(this.isDelete){
      return;
    }
    this.dialogConfig.data = {...$event.data, mode : 'Edit', headerInfo: 'Ethnicity Type', valueInfo: 'ethnicity' };
    this.dialogConfig.height= '420px';
    this.dialogConfig.width=  '400px';
    const dialogRef = this.dialog.open(AddEditFieldLookupPopupComponent, this.dialogConfig)
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.messageSupressionService.saveMsgSupressRow(result, true).subscribe(_ => {
          this.getAllRows();
          this.readonly = false;
          this.showAlert('Save successful!', CVSAlertType.Success)
        });
      }
    });
  }
}
