import {AfterViewInit, Component, Inject, OnInit, ViewChild} from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import {ParamMapService} from '../../../services/param-map.service';
import {AgGridAngular} from 'ag-grid-angular';
import {lookupFields} from './lookupFields.constant';
import { Subscription} from 'rxjs';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../reducers';
import {DataManagementService} from '../../../services/data-management.service';
import {DeleteBtnCellRendererComponent} from "../../../common/DeleteBtnCellRenderer";

@Component({
  selector: 'app-add-new-attributes',
  templateUrl: './add-new-attributes.component.html',
  styleUrls: ['./add-new-attributes.component.scss']
})
export class AddNewAttributesComponent implements OnInit, AfterViewInit {
  form: UntypedFormGroup;
  @ViewChild('referenceTable') referenceTable: AgGridAngular;
  rulesList: any = [
    { name: 'Reference', value: 'Reference' },
    { name: 'Imply', value: 'Imply' },
    { name: 'Concat', value: 'Concat' },
    {name: 'Substring', value: 'Substring'},
    { name: 'Source', value: 'Source' }
  ];
  dataListSubscription: Subscription;
  paramLevelList: any = [];
  showRulesOptions = false;
  selectedRule: any;
  contextObj: any;
  referenceTableData: any = [];
  defaultConcatSource1Val:string='API';
  defaultConcatSource3Val:string='TRN';
  defaultColDef = {
    flex: 0,
    minWidth: 50,
    editable: true,
    resizable: false
  };


  columnDefs: any = [{ headerName: 'Input', field: 'input', sortable: true,
    filter: true,
    lockVisible: true,
    lockPosition: true,
    editable: true,
    width: 100},
    {headerName: 'Output', field: 'output', sortable: true,
      filter: true,
      lockVisible: true,
      lockPosition: true,
      editable: true,
      width: 100},
    {
      headerName: 'Action',
      cellRenderer:  DeleteBtnCellRendererComponent,
      editable: false,
      referenceflag: true,
      width: 100
    }
  ];
  lookupFields: any = lookupFields;
  constructor(
    private fb: UntypedFormBuilder,
    private store: Store<fromRoot.AppState>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<AddNewAttributesComponent>,
    private paramService: ParamMapService,
    private datamanagementService: DataManagementService) {
    this.contextObj = {
      componentParent: this
    };
  }
  ngOnInit() {
    this.setupForm();
    if (this.data) {
      const data: any = {
        parameter: this.data.parameter,
        parameterType: this.data.parameterType,
        description: this.data.description,
        paramLevel: this.data.paramLevel,
        availableValues: this.data.availableValues,
        defaultValue: this.data.defaultValue,
        rule: this.data.rule
      };
      if (this.data.ruleParams && this.data.rule === 'Reference') {
        const ruleParams = JSON.parse(this.data.ruleParams);
        data.referenceSource = ruleParams.referenceSource;
        if (ruleParams.referencetable.length) {
          this.referenceTableData = ruleParams.referencetable;

        }
      }
      if (this.data.ruleParams && this.data.rule === 'Imply') {
        const ruleParams = JSON.parse(this.data.ruleParams);
        data.imply = ruleParams.imply;
      }
      if (this.data.ruleParams && this.data.rule === 'Concat') {
        const ruleParams = JSON.parse(this.data.ruleParams);
        data.concatSource1 = ruleParams.source1;
        data.concatSource2 = ruleParams.source2;
        data.concatSource3 = ruleParams.source3;
      }
      if (this.data.ruleParams && this.data.rule === 'Substring') {
        const ruleParams = JSON.parse(this.data.ruleParams);
        data.subStringSource = ruleParams.subStringSource;
        data.subStringStart = ruleParams.subStringStart;
        data.subStringLength = ruleParams.subStringLength;
      }
      if (this.data.ruleParams && this.data.rule === 'Source') {
        const ruleParams = JSON.parse(this.data.ruleParams);
        data.source = ruleParams.source;
      }
      if (this.data.rule) {
        this.showRulesOptions = true;
        this.selectedRule = this.data.rule;
      }
      this.form.patchValue(data);
    }
    this.loadData();
  }
  ngAfterViewInit() {
    if (this.referenceTable) {
      
      this.referenceTable.api.setGridOption("columnDefs", this.columnDefs);
      this.referenceTable.api.setGridOption("rowData", this.referenceTableData);

    }

  }
  closeHandler() {
    this.dialogRef.close();
  }

  loadData() {
    this.dataListSubscription = this.datamanagementService.getMasterDataList().subscribe(masterDataList => {
      Array.from(masterDataList).forEach((masterData: any) => {
        if (masterData.listType === 'parameterLevel') {
          masterData.options.forEach((masterdataOptions: any) => {
            masterdataOptions.replace(/\\,/g, ',');
          });
          this.paramLevelList = masterData.options;
        }
      });

      // if (this.paramLevelList) {
      //   const dataListOptionsWithEscapeChars = this.paramLevelList.slice();
      //   dataListOptionsWithEscapeChars.forEach(option => {
      //     try {
      //       this.paramLevelList.push(option.replace(/\\,/g, ','));
      //     } catch (e) {

      //     }
      //   });
      // }
    });
  }

  close() {

    const formValue = this.form.value;
    const paramMapData = this.prepareParamMapData(formValue);
    this.paramService.deleteParamMap(paramMapData.id).subscribe(data => {
      this.dialogRef.close({
        isdelete: true
      });
    });
  }
  save() {
    if (!this.isFormValid()) {
      return;
    }
    const formValue = this.form.value;
    const paramMapData = this.prepareParamMapData(formValue);
    this.paramService.saveParamMap(paramMapData).subscribe(data => {
      this.dialogRef.close({issave: true});
    });
  }
  isFormValid() {
    let valid = true;
    const defaultControls = ['parameter', 'parameterType', 'description', 'paramLevel', 'availableValues', 'defaultValue', 'rule'];
    const rules = {
      Reference : ['referenceSource', 'referencetable'],
      Imply: ['imply'],
      Concat: ['concatSource1', 'concatSource2', 'concatSource3'],
      Substring: ['subStringSource', 'subStringStart', 'subStringLength'],
      Source: ['source']
    };
    Object.keys(this.form.controls).forEach(key => {
      if (defaultControls.includes(key) && this.form.get(key).invalid) {
        valid = false;
      } else if (Object.keys(rules).includes(this.selectedRule)) {
        rules[this.selectedRule].forEach(val => {
          if (this.form.get(val).invalid) {
            valid = false;
          }
          if (this.form.value.referenceSource && this.referenceTableData.length > 0) {
            valid = true;
          }
        });
      }
    });
    return valid;
  }
    ruleSelect() {
    this.showRulesOptions = true;
    this.selectedRule = this.form.get('rule').value;
  }
    prepareParamMapData(formValue) {
    const paramMapData: any = {
      id: this.data.id,
      eprId: this.data.eprId,
      parameterType: this.data.parameterType,
      parameter: formValue.parameter,
      fileType: this.data.fileType,
      rule: formValue.rule,
      description: formValue.description,
      availableValues: formValue.availableValues,
      defaultValue: formValue.defaultValue,
      paramLevel: formValue.paramLevel
    };
    if (formValue.rule === 'Reference') {
      const obj = {
        referenceSource: formValue.referenceSource,
        referencetable: this.referenceTableData,
      };
      paramMapData.ruleParams = JSON.stringify(obj);
    }
    if (formValue.rule === 'Imply') {
      const obj = {
        imply: formValue.imply
      };
      paramMapData.ruleParams = JSON.stringify(obj);
    }
    if (formValue.rule === 'Concat') {
      const obj = {
        source1: formValue.concatSource1,
        source2: formValue.concatSource2,
        source3: formValue.concatSource3
      };
      paramMapData.ruleParams = JSON.stringify(obj);
    }
    if (formValue.rule === 'Source') {
      const obj = {
        source: formValue.source
      };
      paramMapData.ruleParams = JSON.stringify(obj);
    }
    if (formValue.rule === 'Substring') {
      const obj = {
        subStringSource: formValue.subStringSource,
        subStringStart: formValue.subStringStart,
        subStringLength: formValue.subStringLength
      };
      paramMapData.ruleParams = JSON.stringify(obj);
    }
    return paramMapData;
  }
    setupForm() {
    this.form = new UntypedFormGroup({
      parameter: new UntypedFormControl('', [Validators.required]),
      parameterType: new UntypedFormControl('', [Validators.required]),
      description: new UntypedFormControl(''),
      paramLevel: new UntypedFormControl('', [Validators.required]),
      availableValues: new UntypedFormControl(''),
      defaultValue: new UntypedFormControl(''),
      rule: new UntypedFormControl('', [Validators.required]),

      referenceSource:  new UntypedFormControl('', [Validators.required]),
      referencetable:  new UntypedFormControl('', [Validators.required]),


      concatSource1:  new UntypedFormControl('', [Validators.required]),
      concatSource2: new UntypedFormControl('', [Validators.required]),
      concatSource3: new UntypedFormControl('', [Validators.required]),

      imply:  new UntypedFormControl('', [Validators.required]),

      source:  new UntypedFormControl('', [Validators.required]),

      subStringSource:  new UntypedFormControl('', [Validators.required]),
      subStringStart: new UntypedFormControl('', [Validators.required]),
      subStringLength: new UntypedFormControl('', [Validators.required])
    });
  }

add() {
    const  obj = {
      input : '',
      output: ''
    };
    this.referenceTableData.push(obj);
    this.referenceTable.api.setGridOption("rowData", this.referenceTableData);
  }
  referenceTableRecUpdated(cell) {
    cell.data.isUpdated = true;
  }
  public deleteTableRecord(node, colId) {
    if (node) {
      this.referenceTableData.splice(node.rowIndex,1);
      this.referenceTable.api.setGridOption("rowData", this.referenceTableData);
    }
  }
}
