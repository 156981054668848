
<ng-container>
    <h2> {{isEditMode ? 'Edit':'Add'}} {{headerInfo}}</h2>
    <p class="msg">{{directions}}</p>
    <form [formGroup]="addEditItem">
    <mat-dialog-content style="height: 270px" class="mat-typography">
       <mat-form-field>
        <mat-label>Value</mat-label>
        <input matInput formControlName="value" >
      </mat-form-field>
      <div [ngStyle]="{'color': disableSave ? 'red' :'#767676'}"
       style="margin-top: -30px; color: #767676">Optional, must be a unique value</div>
      <mat-form-field style="margin-top: 20px" appearance="outline">
        <textarea matInput formControlName="description"></textarea>
      </mat-form-field>
      <div [ngStyle]="{ marginTop: '-30px','color': addEditItem?.controls['description'].errors?.required ? 'red' :'#767676'}">Required*</div>
    </mat-dialog-content>
    <mat-dialog-actions style="margin-top: -15px !important;" class="cvs-theme" align="end">
      <button mat-button mat-dialog-close class="btn-cancel">Cancel</button>
      <button mat-button class="pds-btn-primary"  [disabled]="addEditItem.invalid || disableSave" (click)="save()" cdkFocusInitial>Save</button>
    </mat-dialog-actions>
    </form>
   </ng-container>

