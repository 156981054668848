<div class="container">
    <div class="header">
        <h2 mat-dialog-title>{{providedInfo.title}}</h2>
        <mat-icon class="cag-icon" svgIcon="close-btn--s" (click)="close()"></mat-icon>
    </div>

    <ng-container>
        <mat-dialog-content class="content-container">
            <ng-container [formGroup]="occurenceForm">
                <div class="radio-cntr">
                    <mat-radio-group class="pds-radio-group--large">
                        <mat-radio-button *ngFor="let opt of options.occurence" [value]="opt"
                            [checked]="opt === currentOccurence" (change)="onOccurenceChange($event)">{{opt}}
                        </mat-radio-button>
                    </mat-radio-group>
                </div>
                <ng-container>
                    <div class="flex-col-cntr">
                        <container-element [ngSwitch]="currentOccurence">
                            <div *ngSwitchCase="'Daily'">
                                <mat-form-field>
                                    <mat-label>On</mat-label>
                                    <mat-select pdsMatOptionKeyboardFocus formControlName="on"
                                        (selectionChange)="dailyOnValueChange($event)">
                                        <mat-option *ngFor="let option of dailyOnOptionList" [value]="option">{{option}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>

                                <mat-form-field class="ml-15" *ngIf="formOnValue !== 'Every Weekday'">
                                    <mat-label>Day</mat-label>
                                    <input type="number" matInput formControlName="day" />
                                </mat-form-field>
                            </div>
                            <div *ngSwitchCase="'Weekly'">

                                <mat-form-field>
                                    <mat-label>Every Week(s)</mat-label>
                                    <mat-select pdsMatOptionKeyboardFocus formControlName="weeks">
                                        <mat-option *ngFor="let option of weeklyOnOptionList" [value]="option">
                                            {{option}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>

                                <div class="checkbox-cntr">
                                    <mat-label class="radio-checkbox-label">On</mat-label>
                                    <mat-checkbox *ngFor="let availableDay of daysList;" class="checkbox-field"
                                        (change)="onCheckboxTick($event.checked, availableDay)"
                                        [checked]="isCheckMark(availableDay)">
                                        {{availableDay}}</mat-checkbox>
                                    <mat-error
                                        *ngIf="this.weeklySelectedDayOptions.length === 0 && occurenceForm.touched">
                                        Field is required
                                    </mat-error>
                                </div>

                            </div>
                            <div *ngSwitchCase="'Monthly'">
                                <mat-form-field>
                                    <mat-label>On</mat-label>
                                    <mat-select pdsMatOptionKeyboardFocus formControlName="on">
                                        <mat-option *ngFor="let option of monthlynYearlyOnOptionList" [value]="option">
                                            {{option}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field class="ml-15" *ngIf="formOnValue === 'Every'">
                                    <mat-label>Day</mat-label>
                                    <input type="number" matInput formControlName="day" />
                                </mat-form-field>
                                <mat-form-field class="ml-15" *ngIf="formOnValue !== 'Every'">
                                    <mat-label>Day</mat-label>
                                    <mat-select pdsMatOptionKeyboardFocus formControlName="day">
                                        <mat-option *ngFor="let option of weeklyDayOptionList" [value]="option">
                                            {{option}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field class="ml-15">
                                    <mat-label>Of Every Month(s)</mat-label>
                                    <input type="number" matInput formControlName="months" />
                                </mat-form-field>
                            </div>
                            <div *ngSwitchCase="'Yearly'">
                                <mat-form-field>
                                    <mat-label>On</mat-label>
                                    <mat-select pdsMatOptionKeyboardFocus formControlName="on">
                                        <mat-option *ngFor="let option of monthlynYearlyOnOptionList" [value]="option">
                                            {{option}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field class="ml-15" *ngIf="formOnValue === 'Every'">
                                    <mat-label>Day</mat-label>
                                    <input type="number" matInput formControlName="day" />
                                </mat-form-field>
                                <mat-form-field class="ml-15" *ngIf="formOnValue !== 'Every'">
                                    <mat-label>Day</mat-label>
                                    <mat-select pdsMatOptionKeyboardFocus formControlName="day">
                                        <mat-option *ngFor="let option of weeklyDayOptionList" [value]="option">
                                            {{option}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field class="ml-15">
                                    <mat-label>Of</mat-label>
                                    <mat-select pdsMatOptionKeyboardFocus formControlName="months">
                                        <mat-option *ngFor="let option of yearlyOfOptionList" [value]="option">
                                            {{option}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>

                        </container-element>

                        <div>
                            <mat-form-field>
                                <mat-label>Time</mat-label>
                                <input type="time" matInput formControlName="time" name="time" />
                                <mat-hint>Time is set to CST</mat-hint>
                            </mat-form-field>
                        </div>
                        <div>
                            <mat-form-field class="margin-right-sm" style="width: 273px;">
                                <input matInput placeholder="Report Name" formControlName="reportName">
                            </mat-form-field>
                        </div>
                        <div formArrayName="recipient" class="flex-col-cntr">
                            <div *ngFor="let recipient of recipients.controls; let i = index; let first = first">
                                <mat-form-field style="width: 273px;">
                                    <div class="recipient-fields" [formGroupName]="i">
                                        <input matInput placeholder="Recipient" formControlName="recipientName">
                                    </div>
                                </mat-form-field>
                                <mat-icon svgIcon="close-circle-f--s" type="button" *ngIf="!first"
                                    (click)="deleteRecipient(i)"></mat-icon>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </ng-container>

            <button class="pds-btn-text-only margin-tb" (click)="addRecipient()">
                <mat-icon svgIcon="plus--s"></mat-icon>Add Recipient
            </button>
        </mat-dialog-content>
        <mat-dialog-actions class="row">
            
            
            <button class="pds-btn-white" (click)="close()" >Close</button>
            <button class="mat-raised-button pds-btn-primary  col-4"
                [disabled]="!(occurenceForm.valid)" (click)="save()">Save</button>
        </mat-dialog-actions>

    </ng-container>
</div>