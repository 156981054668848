import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {EMPTY, Observable} from 'rxjs';
import {LoaderService} from './loader.service';
import {finalize} from 'rxjs/operators';
import { UsersService } from './services/users.service';

@Injectable()
export class EwfHttpInterceptor implements HttpInterceptor {
  constructor(private userService:UsersService){}
  activeRequests = 0;

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if(req&& req.url && req.url.includes('/auth/refreshtoken')){
      return EMPTY;
    }
    LoaderService.show();
    this.activeRequests++;

    if(req && req.url && !req.url.includes("/auth/authorizedUser/ELG" && this.userService.userEntity)){
      const userInfo = this.userService.userEntity;
      const basicInfo = userInfo?.basicUserInfo;
      if(userInfo && basicInfo){
        function toString(value){
          return value ? value.toString() : "";
        }
        const newReq = req.clone({headers:req.headers.append("firstName",basicInfo.firstName)
           .append("lastName",toString(basicInfo?.lastName))
           .append("emailAddress", toString(basicInfo?.email))
          .append("internalUser", toString(userInfo?.internalUser))
          .append("role", toString(userInfo.appRoles[0]))
        })
        ;

        req= newReq;
      }

    }
    

    return next.handle(req).pipe(
      finalize(() => {
        this.activeRequests--;
        if (this.activeRequests === 0) {
          LoaderService.hide();
        }
      })
    );
  }
}
