import {Component, Inject, OnInit} from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import {DialogData} from '../../../../threshold-dialog/threshold-dialog.component';

@Component({
  selector: 'app-logic-code-modal',
  templateUrl: './logic-code-modal.component.html',
  styleUrls: ['./logic-code-modal.component.scss']
})
export class LogicCodeModalComponent implements OnInit {
  modalData;

  constructor(public dialogRef: MatDialogRef<LogicCodeModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit() {
    this.modalData = this.data;
  }

  closeModal() {
    this.dialogRef.close();
  }
}
