import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {ProjectTrackerStateType} from '../../../reducers/project-tracker.reducer';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../../reducers';
import {AddProjectTrackerFormAction} from '../../../actions/epr.action';
import {getNumberOfProjectTrackers} from '../../../reducers';
import {ProjectType} from '../../../models/ProjectTypeEnum';
import {DataManagementService} from "../../../services/data-management.service";

export interface AddProjectDialogData {
  headline: string;
  body: any;
}

@Component({
  selector: 'app-add-project-dialog-content',
  templateUrl: './add-project-dialog-content.html',
  styleUrls: ['./add-project-dialog-content.scss']
})
export class AddProjectDialogContentComponent implements OnInit, OnDestroy {

  constructor(@Inject(MAT_DIALOG_DATA) public data: AddProjectDialogData,
              public dialogRef: MatDialogRef<AddProjectDialogData>,
              private fb: UntypedFormBuilder,
              private store: Store<fromRoot.AppState>,
              private dataManagementService: DataManagementService
  ) {  }

  numberOfProjects: number;
  projectList : any = [];
  numberOfProjectsSubscription$ = this.store.select(getNumberOfProjectTrackers).subscribe(num => {
    this.numberOfProjects = num;
  });

  addProjectForm: UntypedFormGroup = new UntypedFormGroup({});

  projectTypeOptionsMock = Object.keys(ProjectType).map(key => ProjectType[key]);

  projectStatusMock = [
    'Green',
    'Yellow',
    'Red',
    'Blue',
    'Grey',
    'Black'
  ];

  ngOnInit() {
    this.addProjectForm = this.fb.group({
      prjStartDteToAdd: ['', Validators.required],
      prjTypeToAdd: ['', Validators.required],
      prjStatusToAdd: '',
      prjGoLiveDteToAdd: ''
    });

    this.dataManagementService.getMasterDataList().subscribe(res => {
      if(res){
        const dataListOptions = [];
        res.options?.forEach(option => dataListOptions.push(option.replace(/\\,/g, ',')));
        res.options = dataListOptions;
        this.projectList = res;
      }
    });
  }

  closeHandler() {
    this.dialogRef.close(false);
  }

  submitNewProject() {
    const newProjectTracker = {
      prjStartDte: this.addProjectForm.controls['prjStartDteToAdd'].value,
      status: this.addProjectForm.controls['prjStatusToAdd'].value,
      prjctSFDCCase: '',
      projectType: this.addProjectForm.controls['prjTypeToAdd'].value,
      prjPhase: '',
      prjGoLiveDte: this.addProjectForm.controls['prjGoLiveDteToAdd'].value,
      prjQCDte: '',
      prjQcBy: null,
      prjCardStatus: '',
      prjNotes: '',
      internalProjectType: false
    } as ProjectTrackerStateType;
    // Note that the new project which will now be selected is at the index which equals the old size to account for arrays starting at 0
    //this.store.dispatch(new AddProjectTrackerFormAction({newProjectTracker, numberOfProjects: this.numberOfProjects}));
    this.dialogRef.close(newProjectTracker);
  }

  ngOnDestroy(): void {
    this.numberOfProjectsSubscription$.unsubscribe();
  }

  getProjectType(projectType: any) {
    if (this.projectList.length > 0) {
      return this.projectList.filter(obj => obj.listType === projectType)[0].options;
    }
  }
}
