import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {CVSAuthConfig, CVSBaseConfig, SideNavConfig} from 'angular-component-library';
import {environment} from '../environments/environment';
import {Router} from '@angular/router';
import * as fromRoot from './reducers';
import {Store} from '@ngrx/store';
import {User} from './models/User';
import {User_PBM} from './models/User_PBM';
import {setLoggedInUserPBMAction, SetPermissionsObjectAction} from './actions/userPbm.action';
import {Permission} from './models/Authorization';
import {Subscription} from 'rxjs';
import {UsersService} from "./services/users.service";
import {Sidenav} from './ewf-base/sidenav/sidenav';
import {EprService} from './services/epr.service';
import {PermissionsObject} from './models/PermissionsObject';
import { CookieService } from 'ngx-cookie-service';
import { LoadUsersSettingAction } from './actions/user-management.action';
import { TaggingService } from '@pbm-shared/pbm-shared-services';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  public authConfig: CVSAuthConfig;
  baseConfig: CVSBaseConfig;
  sideNavConfig: SideNavConfig;
  role: Permission;
  permissions = [];
  user: any;
  getRoleSubscription: Subscription;
  subs:Subscription[] = [];
  roles ={
    'ELG_FILETRACKER': Permission.FILETRACKER,
    'ELG_SPEDM' : Permission.SPEDM,
    'ELG_FT_BENEFIT' : Permission.FT_BENEFIT,
    'ELG_CLIENT_USER':Permission.CLIENT_USER,
    'ELG_CONSULTANT_USER':Permission.CONSULTANT_USER,
    'ELG_OTHER_FILE_USER':Permission.OTHER_FILE_USER}



  @ViewChild('cvsBase') public cvsBase: any;
  sideNav: Sidenav;
  myPbmRoles:any;
  browserRefresh;
  constructor(public router: Router, private store: Store<fromRoot.AppState>,
              private userService: UsersService,private eprService:EprService,
            private cookieService: CookieService, private taggingService: TaggingService,
            ) {
              
              this.subs.push(this.userService.user.subscribe((user=>{
                if(user){
                  this.store.dispatch(new LoadUsersSettingAction(user.id));
                  this.cookieService.set("requesterID",JSON.stringify(user.ldapId));
                }
              }))
            );
    // this.authConfig = environment.authConfig as CVSAuthConfig;

    this.authConfig = {
      accessDeniedMessage: environment.accessDeniedMessage,
      ssoBaseUrl: environment.ssoBaseUrl,
      clientId: environment.clientId,
      cagRefUrl: environment.cagRefUrl,
      backendBaseUrl: environment.backendBaseUrl,
      myPBMSiteURL: environment.myPBM,
      suspenseUrl: environment.suspenseUrl,
      baseProfileManagementUrl: environment.baseProfileManagementUrl,
      baseFiletrackerUrl: environment.baseFiletrackerUrl,
      baseCustomMappingUrl: environment.baseCustomMappingUrl,
      baseUrlSSOE: environment.baseUrlSSOE,
      idle: 7200,
      timeOut: 120,
      authenticationEnabled: environment.authenticationEnabled,
      authorizationUrl: environment.authorizationUrl,
      tokenUrl: environment.tokenUrl,
      scope: environment.scope,
      useAccessToken: environment.useAccessToken,
      loginUrl: environment.loginUrl,
      logoutUrl: environment.logoutUrl,
      issuer: environment.authIssuer
    } as CVSAuthConfig;

    router.config.forEach((k, v) => {
      if (k.path === 'myPBM') {
        if (k.data) {
          k.data.externalUrl = environment.myPBM;
        }
      }
    });

    this.baseConfig = {
      homeRoute: '/',
      appName: environment.appName,
      unbranded: false,
      brand: "Caremark",
      setUserNameFunction: this.getUserPBM,
      dummy: true,
    } as CVSBaseConfig;

    /*getting Role from store is not working after sso integration beacuse role api is calling before
    * token url so we altered the route to get the role using direct userService*/

    this.sideNav = new Sidenav();
  }

  ngOnInit(){
    if (null != environment.appDynamicsKey) {
      this.loadAppDynamicsScript();
    }
    if (null != environment.adobeAnalyticsUrl) {
      this.loadAemScript();
    }
  }

  ngAfterViewInit(){

    //Saves 5 digits of session specific code to compare if new login session is happening
    if(this.cookieService.check('session')){
      if(this.cvsBase?.authorizationService?.cvsHttpClient?.authenticationService?.oAuthService._storage.nonce.slice(-5) === this.cookieService.get('session')){
        this.browserRefresh = true;
      }else{
        this.browserRefresh = false;
        this.cookieService.set("session", this.cvsBase?.authorizationService?.cvsHttpClient?.authenticationService?.oAuthService._storage.nonce.slice(-5))
      }
    }else{
      this.browserRefresh = false;
      this.cookieService.set("session", this.cvsBase?.authorizationService?.cvsHttpClient?.authenticationService?.oAuthService._storage.nonce.slice(-5))
    }
    this.cvsBase.authorizationService?.authorizedUser?.subscribe(result=> {
        if (result) {
       this.userService.userEntity = result;   
       let permissionCodes = result.permissions.map(permission => permission.permissionCode)
          this.userService.permissionCodes = permissionCodes

          let permissionObject
          this.myPbmRoles = this.userService.myPbmRoles
          if(!this.role){
            this.role = this.checkAppRoles(result)


            permissionObject = new PermissionsObject(permissionCodes, this.role)
          }
          if(!this.browserRefresh){
            let userType = `cvs_health_colleague|eligibity_${this.role.toLowerCase()}`
            this.taggingService.link({
              linkName: "eligibility|login_success",
              linkInteraction: "1",
              pageName:"eligibility|login",
              userType: userType,
              loginSuccess:"1"
              });
          }
          this.cookieService.set("permissionObject",JSON.stringify(permissionObject))
          this.storePermissionObject(permissionObject)
          this.userService.userRole.next(this.role)
          this.cvsBase.welcomeMessage = 'Welcome,' + '  ' + result.basicUserInfo.firstName;
          this.userService.userFullName.next(result.basicUserInfo.lastName + ', ' + result.basicUserInfo.firstName);
          this.sideNavConfig = this.sideNav.getSideNavConfig(result);

          this.eprService.getMyUser().subscribe(res=>{
            if(res){
              this.userService.userId.next(res.id);
              this.userService.user.next(res)
              this.getUserPBM(result,this.role,res)
            }
          })
        }
      });
  }

  private loadAemScript() {
    const aemScript = document.createElement('script');
    aemScript.src = environment.adobeAnalyticsUrl;
    const head = document.getElementsByTagName('head')[0];
    if (head !== null && head !== undefined) {
      document.head.appendChild(aemScript);
    }
    
    aemScript.onload = () => {
      console.log('adobe analytics ready for tracking:', window['_satellite']);
      this.taggingService.analyticsScriptLoaded();
    };
  }
  private loadAppDynamicsScript() {
    const script1 = document.createElement('script');
    script1.type = 'text/javascript';
    script1.innerHTML =
      'window["adrum-start-time"] = new Date().getTime(); ' +
      '(function(config){ ' +
      'config.appKey = "' + environment.appDynamicsKey + '"; ' +
      'config.adrumExtUrlHttp = "http://cdn.appdynamics.com"; ' +
      'config.adrumExtUrlHttps = "https://cdn.appdynamics.com"; ' +
      'config.beaconUrlHttp = "http://pdx-col.eum-appdynamics.com"; ' +
      'config.beaconUrlHttps = "https://pdx-col.eum-appdynamics.com"; ' +
      'config.useHTTPSAlways = true; ' +
      'config.resTiming = {"bufSize": 200, "clearResTimingOnBeaconSend": true}; ' +
      'config.maxUrlLength = 512; ' +
      '})(window["adrum-config"] || (window["adrum-config"] = {}));';
    const script2 = document.createElement('script');
    script2.src = '//cdn.appdynamics.com/adrum/adrum-23.3.0.4265.js';
    const head = document.getElementsByTagName('head')[0];
    if (head !== null && head !== undefined) {
      document.head.appendChild(script1);
      document.head.appendChild(script2);
    }
  }


checkAppRoles = (res) =>{

  if(!res.internalUser){
    if(res.accessType === 'Client User'){
      return Permission.CLIENT_USER
    }else if(res.accessType === 'Consultant User'){
      return Permission.CONSULTANT_USER
    }else{
      return Permission.VISITOR
    }
  }else if(res.internalUser){
    let roleCheck
    for (let role of Object.keys(this.myPbmRoles)) {
            if (res.appRoles.includes(role)){

              roleCheck = this.myPbmRoles[role];
            }
    }
    if(!roleCheck){
      roleCheck = Permission.VISITOR
    }
    return roleCheck
  }
   
  }


 getUserPBM = (user_PBM: User_PBM,Role:Permission,legacy_User:User) => {

  if(user_PBM){
    this.store.dispatch(setLoggedInUserPBMAction({user: user_PBM,role:Role,legacyUser:legacy_User}));
      return user_PBM.basicUserInfo.firstName;
    } else {
      return 'Visitor';
    }
}
storePermissionObject = (permissionObject: PermissionsObject) =>{
  this.store.dispatch(new SetPermissionsObjectAction(permissionObject))

}

  ngOnDestroy(): void {
    this.getRoleSubscription.unsubscribe();
  }
}


