import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { FileLayoutService } from '../file-layout.service';

@Component({
  selector: 'app-delete-record-confirmation',
  templateUrl: './delete-record-confirmation.component.html',
  styleUrls: ['./delete-record-confirmation.component.scss']
})
export class DeleteRecordConfirmationComponent implements OnInit {
  form: UntypedFormGroup;
  description: string;
  providedInfo: any;
  constructor(
      private fb: UntypedFormBuilder,
      private dialogRef: MatDialogRef<DeleteRecordConfirmationComponent>,
      @Inject(MAT_DIALOG_DATA) data ,
      private flService: FileLayoutService
      ) {
      this.description = data.description;
      this.providedInfo = data;
  }

  ngOnInit() {
    if (this.providedInfo.type === 'delete') {

    }
  }

  save() {
      this.dialogRef.close(1);
  }

  close() {
      this.dialogRef.close(null);
  }

}
