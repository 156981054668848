import { GridOptions } from 'ag-grid-community';
import { AdminTabList, AdminTabPermissions } from './AdminTabList';
import { Permission } from './Authorization';
import { DashboardButtonsList, DashboardButtonsPermissions, dashboardViews, DashboardViewsPermissions } from './DashboardViewsList';


export class PermissionsObject {
    pbmRole:String
    permissionCodes:Array<String>


    constructor(permissionCodes, role){
      this.pbmRole = role
        this.permissionCodes = permissionCodes

    }

    createDashboardObject(){
      let dashboardObject:DashboardObject
      dashboardObject = new DashboardObject()

      if(this.pbmRole){
        //Dashboard Views
        for(const [k,v] of Object.entries(DashboardViewsPermissions)){
          if(this.permissionCodes.includes(v)){
            dashboardObject.dashboardViews.push(dashboardViews[k])
          }
        }
        //Dashboard Buttons
        for(const [k,v] of Object.entries(DashboardButtonsPermissions)){
          if(this.permissionCodes.includes(v)){
              dashboardObject.buttons[k] = true

          }

        }
        //View Epr permission
        if (this.permissionCodes.includes("ELG_VIEW_EPR")){
          dashboardObject.viewEPR = true
        }

      }

     return dashboardObject
    }

    createAdminObject() {
      let adminObject: AdminObject;
      adminObject = new AdminObject
      
        for(const [k,v] of Object.entries(AdminTabPermissions)){
          if(this.permissionCodes.includes(v)){
             adminObject.object[k] = true
          }
      }

      return  adminObject
    }
    createEprOpbject(){
      let eprObject: EPRObject;
      eprObject = new EPRObject()
      if(this.pbmRole){
        for(const [k,v] of Object.entries(eprObject.EprButtonsPermissions)){
          if(this.permissionCodes.includes(v)){
              eprObject.buttons[k] = true

          }

        }
      }
      return eprObject
    }

    createProcessingFilesObject(){

      let processingFilesButtonsPermissions = {
        adsPrdToggle:"ELG_BOX_TOGGLE",
        betaToggle:"ELG_BETA_TOGGLE",
        openthresholdModal:"ELG_OPEN_FILE_MODAL",
        advanceFile:"ELG_ADVANCE_FILE",
        resetFile:"ELG_RESET_FILE",
        cancelFile:"ELG_CANCEL_FILE",
        detailsDownlaod:"ELG_DOWNLOAD_FILE_DETAILS",
        summaryDownload:"ELG_DOWNLOAD_FILE_SUMMARY",
        sendEmail:"ELG_SEND_EMAIL_FROM_FILE",
        addComment:"ELG_FILE_COMMENT"
      }

      let buttons = Object.keys(processingFilesButtonsPermissions)
      .reduce((accumulator,currentValue) => ({...accumulator,[currentValue]:false}),{});

      if(this.pbmRole){
        for(const [k,v] of Object.entries(processingFilesButtonsPermissions)){
          if(this.permissionCodes.includes(v)){
            buttons[k] = true
        }
        }
      }
      if(this.pbmRole){
        buttons['pbmRole'] = true
        }
      return buttons
    }

    createFiletrackerObject(){

      let filetrackerButtonsPermissions = {
        viewFtOnboarding:"ELG_VIEW_FT_ONBOARDING",
        editFtOnboarding:"ELG_EDIT_FT_ONBOARDING",
        addFtOnboarding:"ELG_ADD_FT_ONBOARDING",
        deleteFtOnboarding:"ELG_DELETE_FT_ONBOARDING",
      }

      let buttons = Object.keys(filetrackerButtonsPermissions)
      .reduce((accumulator,currentValue) => ({...accumulator,[currentValue]:false}),{});

      if(this.pbmRole){
        for(const [k,v] of Object.entries(filetrackerButtonsPermissions)){
          if(this.permissionCodes.includes(v)){
            buttons[k] = true
        }
        }
      }
      if(this.pbmRole){
        buttons['pbmRole'] = true
        }
      return buttons
    }

}


export interface DashboardViews {
    id: string;
    title: string;
    seq: number;
    roles: string[];
    isLoaded: boolean;
    gridOptions: GridOptions;
    rowData: any;
    rowStyle?: any;
    access:boolean,
    showToggles:boolean,
    ads:boolean,
    prd:boolean,
    beta:boolean
  }
export class DashboardObject {
    public viewEPR:boolean
    public buttons: any
    public dashboardViews: DashboardViews[]
    constructor(){
        this.dashboardViews = []
        this.buttons = Object.keys(DashboardButtonsList).reduce((accumulator,currentValue) => ({...accumulator,[currentValue]:false}),{});
        this.viewEPR =false
    }

    setDefaultViews(){
      this.dashboardViews = [
            { id: '', title: 'EPR\'s', seq: 101, roles: [], isLoaded: false, gridOptions: null, rowData: [], access:false, showToggles:false, ads:true, prd:false, beta:false},
            {
              id: '', title: 'EPR Master', seq: 102, roles: [Permission.ADMIN, Permission.MANAGER, Permission.EC,
              Permission.PROCESSOR, Permission.LEADERSHIP, Permission.ERM, Permission.TESTER_AUDITOR,
               Permission.INTFEED, Permission.SI_MAPPER, Permission.CLIENT_USER,Permission.CONSULTANT_USER,Permission.OTHER_FILE_USER],
              isLoaded: false, gridOptions: null, rowData: [],
              access:false, showToggles:false, ads:true, prd:false, beta:false},
            {
              id: '', title: 'Integrated Feeds', seq: 103, roles: [Permission.ADMIN, Permission.MANAGER, Permission.EC,
              Permission.PROCESSOR, Permission.LEADERSHIP, Permission.ERM, Permission.TESTER_AUDITOR,
               Permission.INTFEED, Permission.SI_MAPPER, Permission.CLIENT_USER,Permission.CONSULTANT_USER,Permission.OTHER_FILE_USER],
              isLoaded: false, gridOptions: null, rowData: [] ,
              access:false, showToggles:false, ads:true, prd:false, beta:false
            },
            {
              id: '', title: 'QC/Audit', seq: 104, roles: [Permission.ADMIN, Permission.MANAGER, Permission.EC,
              Permission.PROCESSOR, Permission.LEADERSHIP, Permission.ERM, Permission.TESTER_AUDITOR,
               Permission.INTFEED, Permission.SI_MAPPER, Permission.CLIENT_USER,Permission.CONSULTANT_USER,Permission.OTHER_FILE_USER],
              isLoaded: false, gridOptions: null, rowData: [] ,
              access:false, showToggles:false, ads:true, prd:false, beta:false
            },
            {
              id: '', title: 'Testing', seq: 105, roles: [Permission.ADMIN, Permission.MANAGER, Permission.EC, Permission.PROCESSOR,
              Permission.LEADERSHIP, Permission.ERM, Permission.TESTER_AUDITOR,
               Permission.INTFEED, Permission.SI_MAPPER, Permission.CLIENT_USER,Permission.CONSULTANT_USER,Permission.OTHER_FILE_USER],
              isLoaded: false, gridOptions: null, rowData: [] ,
              access:false, showToggles:false, ads:true, prd:false, beta:false
            },
            {
              id: '', title: 'Open Enrollment', seq: 106, roles: [Permission.ADMIN, Permission.MANAGER, Permission.EC,
              Permission.PROCESSOR, Permission.LEADERSHIP, Permission.ERM, Permission.TESTER_AUDITOR,
               Permission.INTFEED, Permission.SI_MAPPER, Permission.CLIENT_USER,Permission.CONSULTANT_USER,Permission.OTHER_FILE_USER],
              isLoaded: false, gridOptions: null, rowData: [],
              access:false, showToggles:false, ads:true, prd:false, beta:false
            },
            {
              id: '', title: 'Projects', seq: 107, roles: [Permission.ADMIN, Permission.MANAGER, Permission.EC,
              Permission.PROCESSOR, Permission.LEADERSHIP, Permission.ERM, Permission.TESTER_AUDITOR,
              Permission.INTFEED, Permission.SI_MAPPER, Permission.CLIENT_USER,Permission.CONSULTANT_USER,Permission.OTHER_FILE_USER], isLoaded: false, gridOptions: null, rowData: [],
              access:false, showToggles:false, ads:true, prd:false, beta:false
            },
            {
              id: '', title: '1/1 Projects', seq: 109, roles: [Permission.ADMIN, Permission.MANAGER, Permission.EC,
              Permission.PROCESSOR, Permission.LEADERSHIP, Permission.ERM, Permission.TESTER_AUDITOR, Permission.INTFEED,
              Permission.SI_MAPPER,Permission.VISITOR, Permission.CLIENT_USER,Permission.CONSULTANT_USER,Permission.OTHER_FILE_USER], isLoaded: false, gridOptions: null, rowData: [],
              access:false, showToggles:false, ads:true, prd:false, beta:false
            },
            {
              id: '', title: 'Processing Files', seq: 110, roles: [Permission.ADMIN, Permission.MANAGER, Permission.EC,
              Permission.PROCESSOR, Permission.LEADERSHIP, Permission.ERM, Permission.TESTER_AUDITOR, Permission.INTFEED,
              Permission.SI_MAPPER, Permission.CLIENT_USER,Permission.CONSULTANT_USER,Permission.OTHER_FILE_USER], isLoaded: false, gridOptions: null, rowData: [],
              access:false, showToggles:false, ads:true, prd:false, beta:false
            },
            {
              id: '', title: 'File Mappings', seq: 111, roles: [Permission.ADMIN, Permission.MANAGER, Permission.EC,
              Permission.PROCESSOR, Permission.LEADERSHIP,
              Permission.ERM, Permission.TESTER_AUDITOR,
               Permission.INTFEED, Permission.SI_MAPPER, Permission.CLIENT_USER,Permission.CONSULTANT_USER,Permission.OTHER_FILE_USER], isLoaded: false, gridOptions: null, rowData: [],
              access:false, showToggles:false, ads:true, prd:false, beta:false
            },
            {
              id: '', title: 'FT Onboarding - Eligibility', seq: 112, roles: [Permission.ADMIN, Permission.MANAGER, Permission.EC,
              Permission.PROCESSOR, Permission.LEADERSHIP, Permission.ERM, Permission.TESTER_AUDITOR,
               Permission.INTFEED, Permission.SI_MAPPER, Permission.CLIENT_USER,Permission.CONSULTANT_USER,Permission.OTHER_FILE_USER],
              isLoaded: false, gridOptions: null, rowData: [],
              access:false, showToggles:false, ads:true, prd:false, beta:false
            },

            {
              id: '', title: 'CAG REF (Manual)', seq: 113, roles: [Permission.ERM, Permission.ADMIN, Permission.MANAGER,
              Permission.EC, Permission.PROCESSOR, Permission.LEADERSHIP, Permission.ERM, Permission.TESTER_AUDITOR,
              Permission.INTFEED, Permission.SI_MAPPER, Permission.CLIENT_USER,Permission.CONSULTANT_USER,Permission.OTHER_FILE_USER], isLoaded: false, gridOptions: null, rowData: [],
              access:false, showToggles:false, ads:true, prd:false, beta:false
            },
            {
              id: '', title: 'CAG REF (Batch)', seq: 114, roles: [Permission.ERM, Permission.ADMIN, Permission.MANAGER,
              Permission.EC, Permission.PROCESSOR, Permission.LEADERSHIP, Permission.ERM, Permission.TESTER_AUDITOR,
              Permission.INTFEED, Permission.SI_MAPPER, Permission.CLIENT_USER,Permission.CONSULTANT_USER,Permission.OTHER_FILE_USER], isLoaded: false, gridOptions: null, rowData: [],
              access:false, showToggles:false, ads:true, prd:false, beta:false
            },
            { id: '', title: 'Term By Absence', seq: 115, roles: [], isLoaded: false, gridOptions: null, rowData: [], access:false, showToggles:false, ads:true, prd:false, beta:false}

          ];
    }


}
export class AdminObject{
    public object
    constructor(){
        this.object = Object.keys(AdminTabList).reduce((accumulator,currentValue) => ({...accumulator,[currentValue]:false}),{});
    }
    setDefaultAdminLegacy(){
      this.object = Object.keys(AdminTabList).reduce((accumulator,currentValue) => ({...accumulator,[currentValue]:true}),{});

    }
}
export class EPRObject{
  public  EprButtonsPermissions = {
    COPY_EPR : "ELG_COPY_EPR",
    EDIT_EPR: "ELG_EDIT_EPR",
    DELETE_EPR : "ELG_DELETE_EPR",

  }
  private  EprButtonsList ={
    COPY_EPR : "",
    EDIT_EPR: "",
    DELETE_EPR : "",

  }
  public buttons
  constructor(){

    this.buttons = Object.keys(this.EprButtonsList).reduce((accumulator,currentValue) => ({...accumulator,[currentValue]:false}),{});
}


}

export enum ProcessingFileDB  {
ADS =  "ads",
PRD =  "prd",
BETA = "beta"
}
