<div class="parent-container">
<span>
  <mat-icon class="close-btn" svgIcon="close-btn--s" (click)="closeModal()"></mat-icon>
</span>
<h4 class="header-text">Add Logic Code</h4>
<div class="search-add-container">
    <span>
      <mat-form-field appearance="standard">
        <input matInput placeholder="Search" (keyup)="applyFilter()" #logicCodeInput [(ngModel)]="logicInput">
        <mat-icon svgIcon="search--s"></mat-icon>
      </mat-form-field>
    </span>
  <span class="add-new-logic-code" (click)="showAddLogicModal()">
      <mat-icon svgIcon="plus--s"></mat-icon><span class="new-code-text">New Logic Codes</span>
    </span>
</div>
<cvs-loader-overlay-container [isLoading]="showSpinner | async" loadingMessage="Loading">
  <div class="table-container">
    <mat-table [dataSource]="dataSource" class="mat-elevation-z0">

      <ng-container matColumnDef="radio">
        <mat-header-cell  *matHeaderCellDef></mat-header-cell>
        <mat-cell class="radio-button" *matCellDef="let element"><mat-radio-button (change)="updateSelectedLogicCode(element)" [checked]="selectedLogicCode === element.logicCode" ></mat-radio-button></mat-cell>
      </ng-container>

      <ng-container matColumnDef="logicCodes">
        <mat-header-cell *matHeaderCellDef> Logic Codes </mat-header-cell>
        <mat-cell *matCellDef="let element">{{element.logicCode}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="mappingInstructions">
        <mat-header-cell *matHeaderCellDef> Mapping Instructions </mat-header-cell>
        <mat-cell *matCellDef="let element">
        <div class="comments"> {{element.mappingInstructions}} </div>  
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="mappingDescription">
        <mat-header-cell *matHeaderCellDef> Mapping Description </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <div class="comments"> {{element.mappingDescription}}  </div></mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>

    </mat-table>
  </div>
  <div class="btn-container">
    <span class="save-logic-code">
  <button id="save-logic-code" class="pds-btn-primary" (click)="saveSelectedLogicCode()">
    <mat-icon svgIcon="folder--s"></mat-icon>
    Select Logic Code
  </button>
</span>
    <span class="cancel-btn">
  <button id="cancel-btn" class="pds-btn-white" (click)="closeModal()">
    <mat-icon svgIcon="folder--s"></mat-icon>
    Cancel
  </button>
</span>
<span class="clear">
  <button id="cancel-btn" class="pds-btn-white" (click)="resetSelection()">
    <mat-icon svgIcon="trash--s"></mat-icon>
    clear
  </button>
</span>
  </div>

</cvs-loader-overlay-container>

</div>
