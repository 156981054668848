<cvs-alert-placeholder id="Classification-code"></cvs-alert-placeholder>

<div id="dashboard-table-one" class="dashboard-table">

  <div class="row">
    <div class="btn-cc">
      <button id="add-btn" (click)="AddNew()" [disabled]="adddisabled"   class="pds-btn-primary">
        <mat-icon class="mat-sym" svgIcon="plus--s"></mat-icon>
      Add
      </button>
      <button id="cancel-edit-btn" class="pds-btn-primary" (click)="save()" [disabled]="editdisabled" >
        <mat-icon>save</mat-icon>
        Save
  
        </button>
      <button id="add-btn" (click)="deleteClassification()" [disabled]="deleteDisabled"   class="pds-btn-primary">
        <mat-icon class="mat-sym" svgIcon="trash--s"></mat-icon>
      Delete
      </button>
    </div>
  </div>
  <cvs-loader-overlay-container [isLoading]="showSpinner | async" loadingMessage="Loading">
    <ag-grid-angular
      class="ag-theme-alpine"
      #grid1
    (rowValueChanged)="onRowValueChanged($event)"
    [rowSelection]="rowSelection"
    [editType]="editType"
    (gridReady)="onGridReady($event)"
    (selectionChanged)="OnSelectionChange($event)"
    (cellEditingStarted)="OnCellClicked($event)"
    >
    </ag-grid-angular>

  </cvs-loader-overlay-container>
  </div>
