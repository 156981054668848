import {Component} from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import * as fromRoot from '../reducers';
import {Observable, Subscription} from 'rxjs';
import {Store} from '@ngrx/store';
import {getEditMode} from '../reducers/filetracker.reducer';
import { CopayService } from '../services/copay.service';
import {MappingFormService} from "../epr/mapping-documents/mapping-form.service";

@Component({
  selector: 'app-delete-menu-btn-cell-renderer',
  template: `
  <button *ngIf="(readOnly$ | async) === false" class="cvs-icon-only-btn" style="align-self:left;" mat-button [matMenuTriggerFor]="menu"><mat-icon svgIcon="more-vert-f--s"></mat-icon></button> 
  <mat-menu #menu="matMenu" class='deleteMenu'>
    <button mat-button style="font-weight:normal;" (click)="btnClickedHandler(rowIndex)">
      Remove
    </button>
  </mat-menu>
  `,
})
export class DeleteMenuCellRendererComponent implements ICellRendererAngularComp {
  public params: any;
  readOnly$;
  rowIndex;
  constructor() {
  }
  agInit(params: any): void {
    this.params = params;
    this.readOnly$ = this.params.context.componentParent.readOnly$
    this.rowIndex = params.node.rowIndex
  }

 
  btnClickedHandler(rowIndex) {
    if (this.params.context.prefixGrid){
      this.params.context.componentParent.deletePrefixRow(rowIndex)
      return;
    }
  }

  refresh(params: any): boolean {
    return false;
  }

  deleteRow() {
    
  }


 
}
