import {Component, ComponentFactory} from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import * as fromRoot from '../reducers';
import {Observable} from 'rxjs';
import {Store} from '@ngrx/store';
import {getEditMode} from '../reducers/filetracker.reducer';
import { CopayService } from '../services/copay.service';
import {MappingFormService} from "../epr/mapping-documents/mapping-form.service";
import {MessageSupressionService} from "../services/message-supression.service";

@Component({
  selector: 'app-delete-btn-cell-renderer',
  styles: ['button { color: #767676 !important; }'],
  template: `
    <button mat-icon-button (click)="btnClickedHandler($event)" [disabled]="checkDisabled()">
      <mat-icon svgIcon="trash--s"></mat-icon>
    </button>

  `,
})
export class DeleteBtnCellRendererComponent implements ICellRendererAngularComp {
  public params: any;
  isExpired: boolean ;
  isEditMode = false;
  copaydelete: boolean;
  isEnableDeleteButton: boolean;
  constructor(private store: Store<fromRoot.AppState>,private copayService: CopayService, private mappingFormService: MappingFormService,
              private messageSupressionService: MessageSupressionService) {
  }
  agInit(params: any): void {
    this.params = params;
    this.isExpired = params.data.isExpired;
    const isButtonEnabled = this.params?.context?.componentParent?.isEnableDeleteButton;
    this.isEnableDeleteButton = isButtonEnabled ? isButtonEnabled: false;
    this.copaydelete = this.copayService.isEdited;
    this.store.select(fromRoot.getEditMode).subscribe(resp => {
      this.isEditMode = resp;
    });
  }

  checkDisabled(){
    if(this.isEnableDeleteButton) {
      return !this.isEnableDeleteButton;
    }else if(this.copaydelete !== undefined){
      return this.isExpired || !this.copaydelete;
    }
    return this.isExpired;
  }
  btnClickedHandler($event) {
    let componentName = this.params.context.name 
    $event.stopImmediatePropagation();
      if (confirm('Are you sure want to delete the selected Row')) {

        if(componentName === "SpedmContactDialogComponent" ){
          this.params.context.componentParent.deleteSpedmTracker(this.params.node)
          
        }else if (componentName === "FiletrackerContactDialogComponent"){
          this.params.context.componentParent.deleteFiterTracker(this.params.node)
        }
        else if (componentName === "CopayContactDialogComponent"){
          this.params.context.componentParent.deletebenefitTracker(this.params.node)
        }
        else{
          this.deleteRow(componentName)
        }
      }else{
        $event.stopImmediatePropagation();
    }

    }

  refresh(params: any): boolean {
    return false;
  }

  deleteRow(component) {
    if (component === "MappingRequirementsComponent") {
      if (this.params.node.data?.seqNumber) {
        this.mappingFormService.deleteMappingsFileSent(this.params.node.data).subscribe(res => {
          if (res) {
            this.deleteMappingFileSentRecord();
          }
        });
      } else{
        this.deleteMappingFileSentRecord();
      }
    } else {
      this.params.context.componentParent.deleteTableRecord(this.params.node, this.params.column.columnId);
    }
  }


  deleteMappingFileSentRecord() {
    let gridRowData = this.params.api.getRenderedNodes().filter(res => res.rowIndex !== this.params.node.rowIndex);
    let data = gridRowData.map(res => res.data);
    this.params.api.setGridOption("rowData",data);
  }
}
