<cvs-alert-placeholder id="header-alert"></cvs-alert-placeholder>
<mat-tab-group #tabGroup (selectedIndexChange)="tabchanged(tabGroup,$event)">
  <ng-container *ngIf="adminTabsPermission.USERS">

    <mat-tab  label="Users">
      <!-- <app-user-management *ngIf="selectedTab === 'USERS'"></app-user-management> -->
      <lib-user-setting-mfe-library></lib-user-setting-mfe-library>
     </mat-tab>
   </ng-container>
   <ng-container *ngIf="adminTabsPermission.DATA">

    <mat-tab  label="Data">
      <app-data-management *ngIf="selectedTab === 'DATA'"></app-data-management>
     </mat-tab>
   </ng-container>
   <ng-container *ngIf="adminTabsPermission.PARM_MAPPING">

    <mat-tab  label="Param Mapping">
      <app-parm-mapping *ngIf="selectedTab === 'PARM_MAPPING'"></app-parm-mapping>
     </mat-tab>
   </ng-container>
   <ng-container *ngIf="adminTabsPermission.SETTINGS">

    <mat-tab  label="Settings">
      <app-settings *ngIf="selectedTab === 'SETTINGS'"></app-settings>
     </mat-tab>
   </ng-container>
   <ng-container *ngIf="adminTabsPermission.FILE_LAYOUT">

    <mat-tab  label="File Layout">
      <app-file-layout *ngIf="selectedTab === 'FILE_LAYOUT'"></app-file-layout>
     </mat-tab>
   </ng-container>
   <ng-container *ngIf="adminTabsPermission.LOGIC_CODES">

    <mat-tab  label="Logic Codes">
      <app-logic-code *ngIf="selectedTab === 'LOGIC_CODES'"></app-logic-code>
     </mat-tab>
   </ng-container>
   <ng-container *ngIf="adminTabsPermission.CLASSIFICATION_CODES">

    <mat-tab  label="Classification Code">
      <app-classification-code *ngIf="selectedTab === 'CLASSIFICATION_CODES'"></app-classification-code>
     </mat-tab>
   </ng-container>
   <ng-container *ngIf="adminTabsPermission.SCHEDULED_EVENTS">

    <mat-tab  label="Scheduled Events">
      <app-scheduled-events-grid [isAdmin]="true" [userId]="userId" *ngIf="scheduleEvnetsTabActive || selectedTab === 'SCHEDULED_EVENTS'"></app-scheduled-events-grid>
     </mat-tab>
   </ng-container>



  <!-- <mat-tab label="Scheduled Events" *ngIf="userId">

  </mat-tab> -->

</mat-tab-group>
