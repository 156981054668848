import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {CVSHttpClient} from 'angular-component-library';
import {Contact} from '../models/Contact';
import {Spedm} from '../models/Spedm';
import {ParamMap} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ParamMapService {

  paramMap: ParamMap;
  isNew: boolean;

  constructor(private http: CVSHttpClient) {  }

  getAll(): Observable<any> {
    return this.http.get(`${environment.backendBaseUrl}/admin/settings/allParamMapping`);
  }

  getParamMapByFileType(fileType: string): Observable<any> {
    return this.http.get(`${environment.backendBaseUrl}/admin/settings/paramMapping/${fileType}`);
  }
  getParamMapById(id: number): Observable<any> {
    return this.http.get(`${environment.backendBaseUrl}/admin/settings/paramMapping/${id}`);
  }

  saveParamMap(paramMap: ParamMap): Observable<any> {
    return this.http.post(`${environment.backendBaseUrl}/admin/settings/saveParamMapping`, paramMap);
  }

  deleteParamMap(Id: number): Observable<any> {
    return this.http.delete(`${environment.backendBaseUrl}/admin/settings/deleteParamMapping/${Id}`);
  }

  getAddParams(request: any, type: any): Observable<any> {
    return this.http.post(`${environment.rxClaimUrl}/dataExtract/additionalParameters?type=${type}`, request);
  }
}
