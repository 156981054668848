import {Injectable} from '@angular/core';

import {EprComponent} from '../epr/epr.component';
import {StopEditAction} from '../actions/epr.action';
import {Store} from '@ngrx/store';
import * as fromRoot from '../reducers';

@Injectable()
export class EprDeactivateGuard  {
  constructor(private store: Store<fromRoot.AppState>) {  }

  canDeactivate(component: EprComponent): boolean {
    if (!component.readonly) {
      if (confirm('Changes have NOT been saved! Click \'OK\' to exit WITHOUT saving.')) {
        this.store.dispatch(new StopEditAction());
        return true;
      } else {
        return false;
      }
    }else if(component.isProjectTestingEditable){
      if (confirm(`Changes have NOT been saved for ${component.selectedTab}! Click \'OK\' to exit WITHOUT saving.`)) {
        return true;
      } else {
        return false;
      } 
    }

    return true;
  }
}
