<cvs-alert-placeholder id="reportingMessageCodes-alert">
</cvs-alert-placeholder>

<div class="outer-grid">
  <h2>Reporting Message Codes</h2>
  <ag-grid-angular class="ag-theme-alpine" [rowDragManaged]="true"
                   [rowDragEntireRow]="true"
                   [rowDragMultiRow]="true"
                   [rowSelection]="rowSelection"
                   [suppressMenuHide]="true"
                   (filterChanged)="onFilterChange( $event)"
                   (rowDragEnd)="onRowDragEnd($event)" domLayout='autoHeight' (sortChanged)="sortChange($event)" id="reportingMessageCodesGrid" (keyup)="emitSave()"  (cellValueChanged)="UpdatedRow($event)"
                   [context]="contextObj"  [stopEditingWhenCellsLoseFocus]="true" [stopEditingWhenGridLosesFocus]="true"
                   (gridReady)="onGridReady($event)" [rowData]="dataRows">
  </ag-grid-angular>
</div>
