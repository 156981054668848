<cvs-alert-placeholder id="project-alert">
</cvs-alert-placeholder>
<div class="mr">
  <div class="margin-button">
    <button id="add-prj-btn" class="pds-btn-ghost" [disabled]="!isEprReadOnly ? !isEprReadOnly :  (isAddDisabled|| !isReadOnly)" (click)="addProject();disableAdd()">
      Add Project
    </button>
  </div>


  <ag-grid-angular class="ag-theme-alpine" (rowClicked)='onRowClicked($event)' [rowSelection]="true"
    domLayout='autoHeight' #projecttrackergrid [context]="contextObj" 
    [stopEditingWhenCellsLoseFocus]="true" (gridReady)="onGridReady($event)" >
  </ag-grid-angular>

</div>
<cvs-loader-overlay-container [isLoading]="isLoading" loadingMessage="Loading">
<div class="flex-spb">
  <div class="Expand-All" *ngIf="isProjectClicked" (click)="expandCollapse(expandAll);">
    <mat-icon svgIcon="expand--xs" class="ExpandBtn" *ngIf="!expandAll">Expand All</mat-icon>
    <mat-icon svgIcon="collapse--xs" class="CollapseBtn" *ngIf="expandAll">Collapse All</mat-icon>
    <span class="expand-txt">{{!expandAll?'Expand All':'Collapse All'}}</span>
  </div>
   <div class="button-container">
     <div>
       <button *ngIf="isReadOnly && isProjectClicked" [disabled]="!isEprReadOnly" (click)="changeReadMode(false)" class="pds-btn-primary">Edit Project</button>
       <button *ngIf="!isReadOnly" (click)="changeReadMode(true)" class="pds-btn-white">Discard Project Changes</button>
       <button *ngIf="!isReadOnly" [disabled]="disableSave" (click)="saveProjectDetail()" class="pds-btn-primary">Save</button>
    </div>
    <div class="epr-hint" *ngIf="!isEprReadOnly && isProjectClicked">To edit Project, save EPR change</div>
   </div>
</div>
<!-- #docregion multi -->

<mat-accordion class="example-headers-align" multi *ngIf="isProjectClicked">
  <!-- #enddocregion multi -->
  <mat-panel>
    <form [formGroup]="projectTrackerForm" id="projectTrackerForm" >
      <table class="project-info project-row">
        <th colspan="3" style="padding-left:20px;padding-top:10px;padding-bottom:10px">
          <mat-panel-header>
            <mat-panel-title>
              <div class="prj-heading">Project - {{getProjectTrackersDisplayNames}}
              </div>
            </mat-panel-title>
          </mat-panel-header>
        </th>
        <tr>
          <td *ngIf="isReadOnly" [ngClass]="isReadOnly? 'width-10': 'width-15'" style="padding-left:20px;">
            <div style="display: flex;flex-direction: column;gap: 7px;margin-bottom: 35px;height: 33px">
              <div class="field-label">Start Date</div>
              <div class="field-value">{{projectTrackerForm.get('prjStartDte').value| date:'MM/dd/YY':'UTC'}}</div>
            </div>
            <div style="display: flex;flex-direction: column;gap: 7px;margin-bottom: 35px;height:33px">
              <div class="field-label">Go Live</div>
              <div class="field-value">{{projectTrackerForm.get('prjGoLiveDte').value | date:'MM/dd/YYYY':'UTC'}}</div>
            </div>
            <div style="display: flex;flex-direction: column;gap: 7px;margin-bottom: 35px;height:33px">
              <div class="field-label">QC Date</div>
              <div class="field-value">{{projectTrackerForm.get('prjQCDte').value | date: 'MM/dd/YYYY':'UTC'}}</div>
            </div>
          </td>
          <td *ngIf="!isReadOnly" [ngClass]="isReadOnly? 'width-10': 'width-15'" style="padding-left:20px;">
            <mat-form-field id="prj-start-date" class="checklist-date-pickers" [appearance]="isReadOnly? 'none': ''">
              <mat-label>Start Date</mat-label>
              <input matInput [matDatepicker]="datepicker" formControlName="prjStartDte">
              <mat-datepicker-toggle matSuffix [ngStyle]="{'visibility': !isReadOnly? 'visible' : 'hidden'}"
                [for]="datepicker">

              </mat-datepicker-toggle>
              <mat-datepicker #datepicker></mat-datepicker>
              <mat-error *ngIf="projectTrackerForm.get('prjStartDte').invalid">
                Start Date is required
              </mat-error>
            </mat-form-field>
            <mat-form-field id="prj-go-live-date" class="checklist-date-pickers" [appearance]="isReadOnly? 'none': ''">
              <mat-label>Go Live</mat-label>
              <input matInput [matDatepicker]="goLiveDatePicker" formControlName="prjGoLiveDte">
              <mat-datepicker-toggle matSuffix [ngStyle]="{'visibility': !isReadOnly? 'visible' : 'hidden'}"
                [for]="goLiveDatePicker">

              </mat-datepicker-toggle>
              <mat-datepicker #goLiveDatePicker></mat-datepicker>
            </mat-form-field>
            <mat-form-field id="prj-qc-date" class="checklist-date-pickers" [appearance]="isReadOnly? 'none': ''">
              <mat-label>QC Date</mat-label>
              <input matInput [matDatepicker]="qcDatePicker" formControlName="prjQCDte">
              <mat-datepicker-toggle matSuffix [ngStyle]="{'visibility': !isReadOnly? 'visible' : 'hidden'}"
                [for]="qcDatePicker">

              </mat-datepicker-toggle>
              <mat-datepicker #qcDatePicker></mat-datepicker>
            </mat-form-field>
          </td>
          <td *ngIf="isReadOnly" [ngClass]="isReadOnly? 'width-10': 'width-15'">
            <div style="display: flex;flex-direction: column;gap: 7px;margin-bottom: 35px;height:33px">
              <div class="field-label">Project Type</div>
              <div class="field-value">{{projectTrackerForm.get('projectType').value}}</div>
            </div>
            <div style="display: flex;flex-direction: column;gap: 7px;margin-bottom: 35px;height:33px">
              <div class="field-label">SFDC Case#</div>
              <div class="field-value">{{projectTrackerForm.get('prjctSFDCCase').value}}</div>
            </div>
            <div style="display: flex;flex-direction: column;gap: 7px;margin-bottom: 35px;height:33px">
              <div class="field-label">QC'd By</div>
              <div class="field-value">{{getQcBy(projectTrackerForm.get('prjQcBy').value) }}</div>
            </div>
          </td>
          <td *ngIf="!isReadOnly" [ngClass]="isReadOnly? 'width-10': 'width-15'">
            <mat-form-field id="prj-type" [appearance]="isReadOnly? 'none': ''"
              [ngClass]="isReadOnly? 'withoutArrow': ''">
              <mat-label>Project Type</mat-label>
              <mat-select id="test" formControlName="projectType" (selectionChange)="onProjectTypeChange($event)">
                <mat-error *ngIf="(projectTrackerForm.get('projectType').errors?.required)
                                    && (projectTrackerForm.get('projectType').errors?.nullValue)
                                    && (projectTrackerForm.get('projectType').touched)">Project Type is
                  required</mat-error>
                <mat-option *ngFor="let dropOption of getProjectType('projectType')"
                  [value]="dropOption">{{dropOption}}</mat-option>
              </mat-select>
              <mat-error *ngIf="projectTrackerForm.get('prjStartDte').invalid">
                ProjectType is required
              </mat-error>
            </mat-form-field>
            <mat-form-field id="prj-sfdc-case" [appearance]="isReadOnly? 'none': ''">
              <mat-label>SFDC Case #</mat-label>
              <input type="text" matInput placeholder="SFDC Case #" formControlName="prjctSFDCCase">
            </mat-form-field>
            <mat-form-field id="prj-qcd-by" [appearance]="isReadOnly? 'none': ''"
              [ngClass]="isReadOnly? 'withoutArrow': ''">
              <mat-label>QC'd By</mat-label>
              <mat-select formControlName="prjQcBy">
                <mat-option *ngFor="let user of [Permission.TESTER_AUDITOR] | getUsers | async"
                  [value]="user.id">{{user.firstName}} {{user.lastName}}</mat-option>
              </mat-select>
            </mat-form-field>
          </td>
          <td *ngIf="isReadOnly" [ngClass]="isReadOnly? 'width-15': 'width-15'">
            <div style="display: flex;flex-direction: column;gap: 7px;margin-bottom: 35px;height:33px">
              <div class="field-label">Phase</div>
              <div class="field-value">{{projectTrackerForm.get('prjPhase').value}}</div>
            </div>
            <div style="display: flex;flex-direction: column;gap: 7px;margin-bottom: 35px;height:33px">
              <div class="field-label">Card Status</div>
              <div class="field-value">{{projectTrackerForm.get('prjCardStatus').value}}</div>
            </div>
            <div style="display: flex;align-items: center;gap: 7px;margin-bottom: 35px;height:33px">
              <cvs-toggle-switch formControlName="internalProjectType"></cvs-toggle-switch>
              <div class="field-label">Internal Only</div>
              <mat-icon svgIcon="info-circle-f--s"
              #tooltip="matTooltip" class="tooltip-icon"
              matTooltip="Internal Only projects will NOT show on Project Dashboard view for External Client Users"
              matTooltipClass="tooltip-font" matTooltipPosition="below"
              matTooltipHideDelay="100">
          </mat-icon>
            </div>
          </td>
          <td *ngIf="!isReadOnly" [ngClass]="isReadOnly? 'width-10': 'width-15'">
            <mat-form-field id="prj-phase" [appearance]="isReadOnly? 'none': ''"
              [ngClass]="isReadOnly? 'withoutArrow': ''">
              <mat-label>Phase</mat-label>
              <mat-select formControlName="prjPhase">
                <mat-option *ngFor="let dropOption of this.projectPhaseOptions"
                  [value]="dropOption">{{dropOption}}</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field id="prj-card-status" [appearance]="isReadOnly? 'none': ''"
              [ngClass]="isReadOnly? 'withoutArrow': ''">
              <mat-label>Card Status</mat-label>
              <mat-select formControlName="prjCardStatus">
                <mat-option *ngFor="let dropOption of this.projectCardStatusOptions"
                  [value]="dropOption">{{dropOption}}</mat-option>
              </mat-select>
            </mat-form-field>
            <div style="display: flex;align-items: center;gap: 7px;margin-top: 10px;">
              <cvs-toggle-switch formControlName="internalProjectType"></cvs-toggle-switch>
              <div class="field-label">Internal Only</div>
              <mat-icon svgIcon="info-circle-f--s"
                    #tooltip="matTooltip" class="tooltip-icon"
                    matTooltip="Internal Only projects will NOT show on Project Dashboard view for External Client Users"
                    matTooltipClass="tooltip-font" matTooltipPosition="below"
                    matTooltipHideDelay="100">
          </mat-icon>
            </div>
          </td>
          <td colspan="3" class="notesbox" [ngClass]="isReadOnly? 'width-30': 'width-20'">
            <div class="projectnotes-outer">
              <div *ngIf="isReadOnly" style="padding-left: 2px;" class="field-label">Notes</div>

              <div  [ngClass]="isReadOnly? 'notesbox-read width-30': 'notes_txtarea width-20'">
                <quill-editor  placeholder="" formControlName="prjNotes">
                </quill-editor>

              </div>
              </div>

          </td>
        </tr>
        <tr style="height:10px"></tr>
      </table>

    </form>
  </mat-panel>
  <!-- Project details ends here -->

  <form [formGroup]="checklistForm"  *ngFor="let checklistSection of checklistSections"
    class="prj-tracker-container">

    <mat-expansion-panel [expanded]="expandAll? true: false" [togglePosition]="'before'">
      <mat-expansion-panel-header style="height: 56px;">
        <div style="padding-top:10px;width: 100%; display: flex; flex-direction: column">
          <mat-panel-title style="justify-content: space-between">
            <div style="display: flex;" id="{{checklistSection.abbreviation}}">
              <span class="checklist-header" id="checklist-header-{{checklistSection.abbreviation}}">
                <div class="prj-subheading">{{checklistSection.header}}</div>
                <div class="progressbar-outer">{{checklistSectionProgress[checklistSection.abbreviation]}}%
                  <span style="padding-left: 5px">Complete</span>
                  <mat-progress-bar [ngClass]="checklistSection.className +' '+ getBarClass(checklistSection)"
                    style="width: 104px;" [value]="checklistSectionProgress[checklistSection.abbreviation]">
                  </mat-progress-bar>
                </div>
              </span>
            </div>
            <div class="header-dateDesc">
              <span *ngIf="checklistForm.controls[checklistSection.abbreviation + 'ExptDate']?.value">
                Expected Date:
                <span class="bold-dte">{{ checklistForm.controls[checklistSection.abbreviation + 'ExptDate']?.value |
                  date:'MM/dd/YYYY':'UTC' }} </span>
                <span
                  *ngIf="checklistForm.controls[checklistSection.abbreviation + 'DueDate']?.value ||checklistForm.controls[checklistSection.abbreviation + 'CmpltDate']?.value"
                  style="padding:0px 16px">|</span>
              </span>
              <span *ngIf="checklistForm.controls[checklistSection.abbreviation + 'DueDate']?.value">
                Due Date:
                <span class="bold-dte"> {{ checklistForm.controls[checklistSection.abbreviation + 'DueDate']?.value |
                  date:'MM/dd/YYYY':'UTC' }} </span>
                <span *ngIf="checklistForm.controls[checklistSection.abbreviation + 'CmpltDate']?.value"
                  style="padding:0px 16px">|</span>
              </span>
              <span *ngIf="checklistSection.hasGoLiveDate && goLiveDisplayDate">
                Go Live Date:
                <span class="bold-dte"> {{ goLiveDisplayDate | date:'MM/dd/YYYY' :'UTC' }} </span>
                <span *ngIf="checklistForm.controls[checklistSection.abbreviation + 'CmpltDate']?.value"
                  style="padding:0px 16px">|</span>
              </span>
              <span *ngIf="checklistForm.controls[checklistSection.abbreviation + 'CmpltDate']?.value">
                Completed Date:
                <span class="bold-dte"> {{ checklistForm.controls[checklistSection.abbreviation + 'CmpltDate']?.value |
                  date:'MM/dd/YYYY':'UTC' }} </span>
              </span>
            </div>
          </mat-panel-title>
          <mat-panel-description>
          </mat-panel-description>
        </div>
      </mat-expansion-panel-header>

      <cvs-loader-overlay-container [isLoading]='isLoading && !isReadOnly' [opacity]=".9"
        [loadingMessage]="'Loading...'">
        <table id='checklistTable' style="width:100%">
          <td style=" vertical-align:top;">

            <div id="task-list">
              <table class="project-info">
                <tr class="table-heading">
                  <td style="width:260px !important" id="dates">
                    <ng-container *ngIf="!checklistSection.hasNoDates && !checklistSection.hasExpectedDate " class="date-Container">
                      <mat-form-field *ngIf="checklistSection.hasGoLiveDate" [appearance]="isReadOnly? 'none': ''"
                        class="section-date-pickers">
                        <!--                <mat-label>Go Live Date</mat-label>-->
                        <input id="go-live-date" matInput [matDatepicker]="goLiveDatePicker"
                          [value]="goLiveDisplayDate">
                        <mat-hint>Go Live Date</mat-hint>
                        <mat-datepicker-toggle [for]="goLiveDatePicker" matSuffix
                          [ngStyle]="{'visibility': !isReadOnly? 'visible' : 'hidden'}">

                        </mat-datepicker-toggle>
                        <mat-datepicker #goLiveDatePicker></mat-datepicker>
                      </mat-form-field>

                      <mat-form-field *ngIf="!checklistSection.hasGoLiveDate" [appearance]="isReadOnly? 'none': ''"
                        class="section-date-pickers">
                        <!--                <mat-label>Due Date</mat-label>-->
                        <input matInput [matDatepicker]="dueDatepicker"
                          (dateChange)="orgDateValueChange($event.value, checklistSection)"
                          (click)="$event.stopPropagation()"
                          formControlName="{{checklistSection.abbreviation + 'DueDate'}}">
                        <mat-hint>Due Date</mat-hint>
                        <mat-datepicker-toggle matSuffix [for]="dueDatepicker"
                          [ngStyle]="{'visibility': !isReadOnly? 'visible' : 'hidden'}">

                        </mat-datepicker-toggle>
                        <mat-datepicker #dueDatepicker></mat-datepicker>
                      </mat-form-field>
                      <mat-form-field class="section-date-pickers" [appearance]="isReadOnly? 'none': ''">
                        <!--            <mat-label>Completed Date</mat-label>-->
                        <input matInput [matDatepicker]="endDatepicker"
                          (dateChange)="orgDateValueChange($event.value, checklistSection)"
                          (click)="$event.stopPropagation()"
                          formControlName="{{checklistSection.abbreviation + 'CmpltDate'}}">
                        <mat-hint>Completed Date</mat-hint>
                        <mat-datepicker-toggle matSuffix [for]="endDatepicker"
                          [ngStyle]="{'visibility': !isReadOnly? 'visible' : 'hidden'}">

                        </mat-datepicker-toggle>
                        <mat-datepicker #endDatepicker></mat-datepicker>
                      </mat-form-field>

                    </ng-container>
                    <ng-container *ngIf="!checklistSection.hasNoDates && checklistSection.hasExpectedDate " class="date-Container">
                      <mat-form-field *ngIf="checklistSection.hasExpectedDate" [appearance]="isReadOnly? 'none': ''"
                        class="section-date-pickers expectedDate">
                        <!--                <mat-label>Expected Date</mat-label>-->
                        <input matInput [matDatepicker]="exptDatepicker" (click)="$event.stopPropagation()"
                          formControlName="{{checklistSection.abbreviation + 'ExptDate'}}">
                        <mat-hint>Expected Date</mat-hint>
                        <mat-datepicker-toggle matSuffix [for]="exptDatepicker"
                          [ngStyle]="{'visibility': !isReadOnly ? 'visible' : 'hidden'}">

                        </mat-datepicker-toggle>
                        <mat-datepicker #exptDatepicker></mat-datepicker>
                      </mat-form-field>
                      <mat-form-field *ngIf="checklistSection.hasGoLiveDate" [appearance]="isReadOnly? 'none': ''"
                        class="section-date-pickers expectedDate">
                        <!--                <mat-label>Go Live Date</mat-label>-->
                        <input id="go-live-date" matInput [matDatepicker]="goLiveDatePicker"
                          [value]="goLiveDisplayDate">
                        <mat-hint>Go Live Date</mat-hint>
                        <mat-datepicker-toggle [for]="goLiveDatePicker" matSuffix
                          [ngStyle]="{'visibility': !isReadOnly? 'visible' : 'hidden'}">

                        </mat-datepicker-toggle>
                        <mat-datepicker #goLiveDatePicker></mat-datepicker>
                      </mat-form-field>

                      <mat-form-field *ngIf="!checklistSection.hasGoLiveDate" [appearance]="isReadOnly? 'none': ''"
                        class="section-date-pickers expectedDate">
                        <!--                <mat-label>Due Date</mat-label>-->
                        <input matInput [matDatepicker]="dueDatepicker"
                          (dateChange)="orgDateValueChange($event.value, checklistSection)"
                          (click)="$event.stopPropagation()"
                          formControlName="{{checklistSection.abbreviation + 'DueDate'}}">
                        <mat-hint>Due Date</mat-hint>
                        <mat-datepicker-toggle matSuffix [for]="dueDatepicker"
                          [ngStyle]="{'visibility': !isReadOnly? 'visible' : 'hidden'}">

                        </mat-datepicker-toggle>
                        <mat-datepicker #dueDatepicker></mat-datepicker>
                      </mat-form-field>
                      <mat-form-field class="section-date-pickers expectedDate" [appearance]="isReadOnly? 'none': ''">
                        <!--            <mat-label>Completed Date</mat-label>-->
                        <input matInput [matDatepicker]="endDatepicker"
                          (dateChange)="orgDateValueChange($event.value, checklistSection)"
                          (click)="$event.stopPropagation()"
                          formControlName="{{checklistSection.abbreviation + 'CmpltDate'}}">
                        <mat-hint>Completed Date</mat-hint>
                        <mat-datepicker-toggle matSuffix [for]="endDatepicker"
                          [ngStyle]="{'visibility': !isReadOnly? 'visible' : 'hidden'}">

                        </mat-datepicker-toggle>
                        <mat-datepicker #endDatepicker></mat-datepicker>
                      </mat-form-field>

                    </ng-container>

                </tr>

                <tr style="height:40px"></tr>

                <tr class="table-heading checklist-heading">
                  <td style="width:328px" class="headingBtn">
                    <div class="checklist-sub-head">
                      <h3 class="w7">Task</h3>
                        <div class="customize w7" (click)="toggleTheToggles(checklistSection)"
                        *ngIf="!isReadOnly && projectTypeChecklistMetadata">
                            <mat-icon svgIcon="paper-edit--s"></mat-icon> 
                          Customize 
                          
                        </div>

                    </div>  
                    <div class="line"></div>
                    
                    <!-- <button *ngIf="!isReadOnly && checklistSection.showToggles"
                      [disabled]=checklistSection.isSaving class="pds-btn-primary saveBtn"
                      (click)="saveChecklistMetadata(checklistSection)">
                      Save
                    </button> -->
                  </td>
                  <td><h3 class="w7">Completed Date</h3></td>
                  <td></td>
                </tr>
                <ng-container *ngFor="let task of checklistSection.sectionConfig">
                  <tr class="table-bottom-border checklist-table">
                    <td style="width:328px; padding-right:10px;">
                      <cvs-toggle-switch *ngIf="!isReadOnly && checklistSection.showToggles"
                        [value]="projectTypeChecklistMetadata[task.formControlName]"
                        (onToggle)="toggleDisable(task.formControlName)">
                      </cvs-toggle-switch>
                      <app-label-link [hyperlink]="getHyperlink(task.formControlName)" [labelText]="task.label">
                      </app-label-link>
                    </td>
                    <td>

                      <mat-form-field appearance='none' class="checklist-date-pickers">
                        <input matInput [matDatepicker]="datepicker" [readonly]="isReadOnly"
                          formControlName="{{task.formControlName}}">

                        <mat-datepicker-toggle matSuffix [for]="datepicker"
                          [ngStyle]="{'visibility': (!isReadOnly  && !isLoading)? 'visible' : 'hidden'}">

                        </mat-datepicker-toggle>


                        <mat-datepicker #datepicker></mat-datepicker>
                      </mat-form-field>
                    </td>
                    <td colspan="checklistSection.sectionConfig.length"></td>
                  </tr>
                  <ng-container class="horizontal-container" *ngIf="task.extraTaskField">
                    <tr [ngSwitch]="task.extraTaskField.inputType">
                      <td>{{task.extraTaskField.label}} </td>
                      <td colspan="2" style="display: inline-flex;">
                        <mat-form-field *ngSwitchCase="inputType.DROPDOWN" [appearance]="isReadOnly? 'none': ''">
                          <!--mat-label id="dropdown-label" style="font-size: 12px">{{task.extraTaskField.label}}</mat-label-->
                          <mat-select style="margin: 10px" [readonly]="isReadOnly"
                            formControlName="{{task.extraTaskField.controlName}}">
                            <mat-option *ngFor="let dropOption of task.extraTaskField.options"
                              [value]="dropOption">{{dropOption}}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>

                        <mat-form-field *ngSwitchCase="inputType.TEXT" appearance="outline"
                          [appearance]="isReadOnly? 'none': ''">
                          <input class="input-type-text" type="text" [readonly]="isReadOnly" matInput
                            placeholder="{{task.extraTaskField.label}}"
                            formControlName="{{task.extraTaskField.controlName}}">
                        </mat-form-field>
                      </td>

                    </tr>
                  </ng-container>
                </ng-container>

              </table>
            </div>


          </td>
          <td style="vertical-align:top;height:100%;">
            <div class="notesBox"  [ngClass]="isReadOnly? 'notesbox-read': 'notesbox-edit'">
              <quill-editor (keydown)="$event.stopPropagation()"
              formControlName="{{ checklistSection.noteControlName }}"
                class="notes_txtarea_common"></quill-editor>
            </div>
          </td>

        </table>
      </cvs-loader-overlay-container>


    </mat-expansion-panel>

  </form>
</mat-accordion>
</cvs-loader-overlay-container>
<!-- </div> -->
