<div class="list-container">


  <mat-accordion multi="true">
    <mat-expansion-panel [togglePosition]="'before'" id="mappingRequirement"
                          expanded="true"
                         style="margin-bottom: 1rem;">
      <mat-expansion-panel-header style="pointer-events: none">
        <mat-panel-title>
          <div style="font-size: 1.3rem; display:flex; padding-left:50px">
            <span> Header Information</span>
          </div>
        </mat-panel-title>

      </mat-expansion-panel-header>
      <!-- body -->
      <div class="mat-accord-body">
        <ag-grid-angular
          class="ag-theme-alpine"
          id="headerInfoGrid"
          #headerInfoGrid
          [stopEditingWhenCellsLoseFocus]="false"
          [singleClickEdit]="true"
          (cellValueChanged)="dataChanged($event)"
          (gridReady)="onGridReady($event)"
        >
        </ag-grid-angular>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>

<div>
    <div class="Expand-All" (click)="togglePanels()">
      <mat-icon svgIcon="expand--xs"  class="ExpandBtn" *ngIf="!panelOpenState">Expand All</mat-icon>
      <mat-icon svgIcon="collapse--xs"  class="CollapseBtn"  *ngIf="panelOpenState">Collapse All</mat-icon>
      <span class="expand-text">{{!panelOpenState?'Expand All':'Collapse All'}}</span>
    </div>

      <mat-accordion  multi="true">
      <ng-container>
        <mat-expansion-panel [togglePosition]="'before'" [expanded]="panelOpenState">
          <mat-expansion-panel-header>
            <mat-panel-title style="width: 20em">
              <span><b>Client Basics</b></span> &nbsp;&nbsp;
            </mat-panel-title>
          </mat-expansion-panel-header>
            <mat-panel-description style="flex: auto;">
            <div class="list-container">
              <ag-grid-angular class="ag-theme-alpine" 
                [rowDragManaged]="true"  #clientBasicsGrid [singleClickEdit]="true"
                (filterChanged)="onFilterChange( $event)" [getRowStyle]="rowColor"
                [rowDragEntireRow]="true" [rowDragMultiRow]="true" [suppressMenuHide]="true"
                [suppressMoveWhenRowDragging]="true"
                rowSelection="multiple" (rowDragEnd)="onRowDragEnd($event)" 
                domLayout='autoHeight' (sortChanged)="sortChange($event, 1)" id="clientBasicsGrid" 
                (cellValueChanged)="dataChanged($event)"
                [context]="contextObj" 
                [stopEditingWhenCellsLoseFocus]="true" (gridReady)="onQuestionsGridReady($event, 1)">
              </ag-grid-angular> 
            </div>
            </mat-panel-description>
        </mat-expansion-panel>
      </ng-container>
      </mat-accordion>
  <mat-accordion  multi="true">
    <ng-container>
      <mat-expansion-panel [togglePosition]="'before'" [expanded]="panelOpenState">
        <mat-expansion-panel-header>
          <mat-panel-title style="width: 20em">
            <span><b>Printed Materials</b></span> &nbsp;&nbsp;
          </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-panel-description style="flex: auto;">
          <div class="list-container">
            <ag-grid-angular class="ag-theme-alpine" 
                [rowDragManaged]="true"  #printedMatsGrid [singleClickEdit]="true"
                (filterChanged)="onFilterChange( $event)" [getRowStyle]="rowColor"
                [rowDragEntireRow]="true" [rowDragMultiRow]="true" [suppressMenuHide]="true"
                [suppressMoveWhenRowDragging]="true"
                rowSelection="multiple" (rowDragEnd)="onRowDragEnd($event)" 
                domLayout='autoHeight' (sortChanged)="sortChange($event, 7)" id="printedMatsGrid" 
                (cellValueChanged)="dataChanged($event)" [suppressRowClickSelection]="true"
                [context]="contextObj" 
                [stopEditingWhenCellsLoseFocus]="true" (gridReady)="onQuestionsGridReady($event, 7)">
              </ag-grid-angular> 
          </div>
        </mat-panel-description>
      </mat-expansion-panel>
    </ng-container>
  </mat-accordion>


  <mat-accordion  multi="true">
      <ng-container>
        <mat-expansion-panel [togglePosition]="'before'" [expanded]="panelOpenState">
          <mat-expansion-panel-header>
            <mat-panel-title style="width: 20em">
              <span><b>ID Configuration</b></span> &nbsp;&nbsp;
            </mat-panel-title>
          </mat-expansion-panel-header>
            <mat-panel-description style="flex: auto;">
          <div class="list-container">
            <ag-grid-angular class="ag-theme-alpine" 
                [rowDragManaged]="true"  #idConfigGrid [singleClickEdit]="true"
                (filterChanged)="onFilterChange( $event)" [getRowStyle]="rowColor"
                [rowDragEntireRow]="true" [rowDragMultiRow]="true" [suppressMenuHide]="true"
                [suppressMoveWhenRowDragging]="true"
                rowSelection="multiple" (rowDragEnd)="onRowDragEnd($event)" 
                domLayout='autoHeight' (sortChanged)="sortChange($event, 2)" id="idConfigGrid" 
                (cellValueChanged)="dataChanged($event)" [suppressRowClickSelection]="true"
                [context]="contextObj" 
                [stopEditingWhenCellsLoseFocus]="true" (gridReady)="onQuestionsGridReady($event, 2)">
              </ag-grid-angular>
          </div>
      </mat-panel-description>
      </mat-expansion-panel>
      </ng-container>
      </mat-accordion>

      <mat-accordion  multi="true">
        <ng-container>
          <mat-expansion-panel [togglePosition]="'before'" [expanded]="panelOpenState">
            <mat-expansion-panel-header>
              <mat-panel-title style="width: 20em">
                <span><b>File Params</b></span> &nbsp;&nbsp;
              </mat-panel-title>
            </mat-expansion-panel-header>
              <mat-panel-description style="flex: auto;">
            <div class="list-container">
              <ag-grid-angular class="ag-theme-alpine" 
                  [rowDragManaged]="true"  #fileParamsGrid [singleClickEdit]="true"
                  (filterChanged)="onFilterChange( $event)" [getRowStyle]="rowColor"
                  [rowDragEntireRow]="true" [rowDragMultiRow]="true" [suppressMenuHide]="true"
                  [suppressMoveWhenRowDragging]="true"
                  rowSelection="multiple" (rowDragEnd)="onRowDragEnd($event)" 
                  domLayout='autoHeight' (sortChanged)="sortChange($event, 3)" id="fileParamsGrid" 
                  (cellValueChanged)="dataChanged($event)" [suppressRowClickSelection]="true"
                  [context]="contextObj" 
                  [stopEditingWhenCellsLoseFocus]="true" (gridReady)="onQuestionsGridReady($event, 3)">
                </ag-grid-angular>         
            </div>
          </mat-panel-description>
        </mat-expansion-panel>
        </ng-container>
        </mat-accordion>

        <mat-accordion  multi="true">
          <ng-container>
            <mat-expansion-panel [togglePosition]="'before'" [expanded]="panelOpenState">
              <mat-expansion-panel-header>
                <mat-panel-title style="width: 20em">
                  <span><b>Vendor</b></span> &nbsp;&nbsp;
                </mat-panel-title>
              </mat-expansion-panel-header>
                <mat-panel-description style="flex: auto;">
              <div class="list-container">
                <ag-grid-angular class="ag-theme-alpine" 
                  [rowDragManaged]="true"  #vendorGrid [singleClickEdit]="true"
                  (filterChanged)="onFilterChange( $event)" [getRowStyle]="rowColor"
                  [rowDragEntireRow]="true" [rowDragMultiRow]="true" [suppressMenuHide]="true"
                  [suppressMoveWhenRowDragging]="true"
                  rowSelection="multiple" (rowDragEnd)="onRowDragEnd($event)" 
                  domLayout='autoHeight' (sortChanged)="sortChange($event, 4)" id="vendorGrid" 
                  (cellValueChanged)="dataChanged($event)" [suppressRowClickSelection]="true"
                  [context]="contextObj" 
                  [stopEditingWhenCellsLoseFocus]="true" (gridReady)="onQuestionsGridReady($event, 4)">
                </ag-grid-angular>
            </div>
          </mat-panel-description>
        </mat-expansion-panel>
        </ng-container>
        </mat-accordion>

        <mat-accordion  multi="true">
          <ng-container>
            <mat-expansion-panel [togglePosition]="'before'" [expanded]="panelOpenState">
              <mat-expansion-panel-header>
                <mat-panel-title style="width: 20em">
                  <span><b>Terms</b></span> &nbsp;&nbsp;
                </mat-panel-title>
              </mat-expansion-panel-header>
                <mat-panel-description style="flex: auto;">
                <div class="list-container">
                  <ag-grid-angular class="ag-theme-alpine" 
                    [rowDragManaged]="true"  #termsGrid [singleClickEdit]="true"
                    (filterChanged)="onFilterChange( $event)" [getRowStyle]="rowColor"
                    [rowDragEntireRow]="true" [rowDragMultiRow]="true" [suppressMenuHide]="true"
                    [suppressMoveWhenRowDragging]="true"
                    rowSelection="multiple" (rowDragEnd)="onRowDragEnd($event)" 
                    domLayout='autoHeight' (sortChanged)="sortChange($event, 5)" id="termsGrid" 
                    (cellValueChanged)="dataChanged($event)" [suppressRowClickSelection]="true"
                    [context]="contextObj" 
                    [stopEditingWhenCellsLoseFocus]="true" (gridReady)="onQuestionsGridReady($event, 5)">
                  </ag-grid-angular>
                </div>
              </mat-panel-description>
            </mat-expansion-panel>
            </ng-container>
            </mat-accordion>

            <mat-accordion  multi="true">
              <ng-container>
                <mat-expansion-panel [togglePosition]="'before'" [expanded]="panelOpenState">
                  <mat-expansion-panel-header>
                    <mat-panel-title style="width: 20em">
                      <span><b>Threshold</b></span> &nbsp;&nbsp;
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                    <mat-panel-description style="flex: auto;">
                    <div class="list-container">
                  <ag-grid-angular class="ag-theme-alpine" 
                    [rowDragManaged]="true"  #thresholdGrid [singleClickEdit]="true"
                    (filterChanged)="onFilterChange( $event)" [getRowStyle]="rowColor"
                    [rowDragEntireRow]="true" [rowDragMultiRow]="true" [suppressMenuHide]="true"
                    [suppressMoveWhenRowDragging]="true"
                    rowSelection="multiple" (rowDragEnd)="onRowDragEnd($event)" 
                    domLayout='autoHeight' (sortChanged)="sortChange($event, 6)" id="thresholdGrid" 
                    (cellValueChanged)="dataChanged($event)" [suppressRowClickSelection]="true"
                    [context]="contextObj" 
                    [stopEditingWhenCellsLoseFocus]="true" (gridReady)="onQuestionsGridReady($event, 6)">
                  </ag-grid-angular>
                </div>
              </mat-panel-description>
            </mat-expansion-panel>
            </ng-container>
            </mat-accordion>

                <mat-accordion  multi="true">
                  <ng-container>
                    <mat-expansion-panel [togglePosition]="'before'" [expanded]="panelOpenState">
                      <mat-expansion-panel-header>
                        <mat-panel-title style="width: 20em">
                          <span><b>Misc Info</b></span> &nbsp;&nbsp;
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                        <mat-panel-description style="flex: auto;">
                        <div class="list-container">
                     
                      <ag-grid-angular class="ag-theme-alpine" 
                        [rowDragManaged]="true"  #miscInfoGrid [singleClickEdit]="true"
                        (filterChanged)="onFilterChange( $event)" [getRowStyle]="rowColor"
                        [rowDragEntireRow]="true" [rowDragMultiRow]="true" [suppressMenuHide]="true"
                        [suppressMoveWhenRowDragging]="true"
                        rowSelection="multiple" (rowDragEnd)="onRowDragEnd($event)" 
                        domLayout='autoHeight' (sortChanged)="sortChange($event, 8)" id="miscInfoGrid" 
                        (cellValueChanged)="dataChanged($event)" [suppressRowClickSelection]="true"
                        [context]="contextObj" 
                        [stopEditingWhenCellsLoseFocus]="true" (gridReady)="onQuestionsGridReady($event, 8)">
                      </ag-grid-angular>
                    </div>
                  </mat-panel-description>
                </mat-expansion-panel>
                </ng-container>
                </mat-accordion>

                <mat-accordion  multi="true">
                  <ng-container>
                    <mat-expansion-panel [togglePosition]="'before'" [expanded]="panelOpenState">
                      <mat-expansion-panel-header>
                        <mat-panel-title style="width: 20em">
                          <span><b>Open Enrollment</b></span> &nbsp;&nbsp;
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                        <mat-panel-description style="flex: auto;">
                        <div class="list-container">
                      <ag-grid-angular class="ag-theme-alpine" 
                        [rowDragManaged]="true"  #openEnrollGrid [singleClickEdit]="true"
                        (filterChanged)="onFilterChange( $event)" [getRowStyle]="rowColor"
                        [rowDragEntireRow]="true" [rowDragMultiRow]="true" [suppressMenuHide]="true"
                        [suppressMoveWhenRowDragging]="true"
                        rowSelection="multiple" (rowDragEnd)="onRowDragEnd($event)" 
                        domLayout='autoHeight' (sortChanged)="sortChange($event, 9)" id="openEnrollGrid" 
                        (cellValueChanged)="dataChanged($event)" [suppressRowClickSelection]="true"
                        [context]="contextObj" 
                        [stopEditingWhenCellsLoseFocus]="true" (gridReady)="onQuestionsGridReady($event, 9)">
                      </ag-grid-angular>
                    </div>
                  </mat-panel-description>
                </mat-expansion-panel>
                </ng-container>
                </mat-accordion>
</div>


