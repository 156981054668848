import { AfterViewInit, Component, EventEmitter, HostListener, OnInit, Output, ViewChild } from '@angular/core';
import { AdminAlertTypeService } from 'src/app/services/admin-alert-type.service';
import { AgGridAngular } from 'ag-grid-angular';
import { EditComponent } from './edit/edit.component';
import { CVSAlertService, CVSAlertType } from 'angular-component-library';
import { DeleteBtnCellRendererComponent } from './../../../common/DeleteBtnCellRenderer';

export const adminAlertTypeGridColumns = [
  {
    headerName: 'Id', field: 'id', hide: true
  },
  {
    headerName: 'Alert Types', field: 'alertTypes',
    editable: true,
    sortable: true,
    filter: true, lockVisible: true, lockPosition: true, resizable: true,
    width: 140,
    cellEditor: 'agTextCellEditor',
    cellEditorParams: { maxLength: 50 }
  },
  {
    headerName: 'Internal', field: 'internal',
    editable: true,
    sortable: true, filter: true, lockVisible: true, lockPosition: true, resizable: true,
    width: 140, cellEditor: EditComponent
  },
  {
    headerName: 'Visitor', field: 'visitor',
    editable: true,
    sortable: true, filter: true, lockVisible: true, lockPosition: true, resizable: true,
    width: 140, cellEditor: EditComponent
  },
  {
    headerName: 'External', field: 'external',
    editable: true,
    sortable: true, filter: true, lockVisible: true, lockPosition: true, resizable: true,
    width: 140, cellEditor: EditComponent
  },
  {
    headerName: 'Action',
    cellRenderer: DeleteBtnCellRendererComponent,
    editable: false,
    width: 100
  }
];


@Component({
  selector: 'app-admin-alert-type',
  templateUrl: './admin-alert-type.component.html',
  styleUrls: ['./admin-alert-type.component.scss']
})
export class AdminAlertTypeComponent implements OnInit, AfterViewInit {

  @ViewChild('adminAlertTypeGrid') adminAlertTypeGrid: AgGridAngular;
  @Output() isSaveCalled: EventEmitter<any> = new EventEmitter();

  dataRows = [];
  payloadRequest = [];
  changedRow: any;

  rowsTobeDeleted = [];
  rowsTobeUpdated = [];
  sequenceColumnIDs = [1];

  hlGridHeight = 350;
  contextObj: any;

  readonly: boolean;

  selectedStatus = '';


  constructor(private adminAlertTypeService: AdminAlertTypeService, private alertService: CVSAlertService) {
    this.contextObj = {
      componentParent: this
    };
  }

  ngOnInit() {
    this.getAllAdminAlertTypes();
    this.hlGridHeight = 350;
    localStorage.setItem('deactivate-alert', '0');
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.addNewRow();
      this.saveUnSavedRecords();
      this.alertService.clearAlert('#admintype-alerts');
    }, 500);

  }

  getAllAdminAlertTypes() {

    this.adminAlertTypeService.getAllAdminAlertTypes().subscribe(res => {
      this.dataRows = res;
      this.payloadRequest = [];
      this.dataRows.sort((a, b) => a.id - b.id);
      localStorage.setItem('deactivate-alert', '0');
    });
    this.changedRow = false;
  }

  onGridReady(params) {
    this.adminAlertTypeGrid = params;
    this.hlGridHeight = this.dataRows.length * 51;
    this.adminAlertTypeGrid.api.setGridOption("columnDefs", adminAlertTypeGridColumns);
    this.adminAlertTypeGrid.api.redrawRows();
  }

  addNewRow() {
    this.adminAlertTypeService.onMessage().subscribe(newRow => {
      if (newRow != null) {
        this.adminAlertTypeGrid.api.applyTransaction({
          addIndex: 0,
          add: newRow
        });
        this.adminAlertTypeGrid.api.setFocusedCell(0, 'id');

        this.hlGridHeight = (this.adminAlertTypeGrid.api.getDisplayedRowCount() * 42) + 52;
      }
    });
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    if (this.readonly) {

      if (confirm('Changes have NOT been saved! Click \'OK\' to exit WITHOUT saving.')) {
        return true;
      } else {
        return false;
      }
    }
  }

  adminAlertRecUpdated(cell) {
    cell.data.isUpdated = true;
    this.readonly = true;
    localStorage.setItem('deactivate-alert', '1');
    this.emitSave();
  }

  saveUnSavedRecords() {

    this.adminAlertTypeService.saveNewAdminRecords$.subscribe(resp => {
      const updatedRec = [];

      if (resp) {
        this.adminAlertTypeGrid.api.forEachNode(node => {
          if (node.data.isUpdated || node.data.isNew) {
            delete node.data.isUpdated;
            delete node.data.isNew;
            updatedRec.push(node.data);
          }
        });

        this.adminAlertTypeService.saveAdminAlertTypeRow(updatedRec).subscribe(afterSave => {
          this.readonly = false;
          localStorage.setItem('deactivate-alert', '0');
          this.alertService.sendAlert({
            headline: 'Save successful!',
            alertType: CVSAlertType.Success,
            removedAfterMilliseconds: 5000,
            alertDocumentHolderName: '#admintype-alerts',
          });
          this.getAllAdminAlertTypes();
        });
      }
    });

  }

  emitSave() {
    this.isSaveCalled.emit();
  }

  public deleteTableRecord(node, colId) {
    if (node.data.isNew) {
      localStorage.setItem('deactivate-alert', '0');
      this.readonly = false;
      this.adminAlertTypeGrid.api.applyTransaction({remove:[node.data]});
      this.hlGridHeight = (this.adminAlertTypeGrid.api.getDisplayedRowCount() * 42) + 52;
    } else {
      this.adminAlertTypeService.deleteAdminAlertTypeRow([{ ...node.data }]).subscribe(resp => {
        if (resp > 0) {
          this.adminAlertTypeGrid.api.applyTransaction({remove:[node.data]});
          this.hlGridHeight = (this.adminAlertTypeGrid.api.getDisplayedRowCount() * 42) + 52;
        }
        this.getAllAdminAlertTypes();
      });
    }
  }
}
