import {AfterViewInit, Component, Inject, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {AgGridAngular} from 'ag-grid-angular';
import {Store} from '@ngrx/store';
import * as ColumnSets from './thresholdColumnSets';
import {GridOptions, Module} from '@ag-grid-community/core';
import {AuditlogService} from '../services/auditlog.service';
import {BalancesheetService} from '../services/balancesheet.service';
import {ThresholdService} from '../services/threshold.service';
import {EprService} from '../services/epr.service';
import {CommentService} from '../services/comment.service';
import * as fromRoot from '../reducers';
import {ThresholdConfirmConstants, ThresholdConfirmDialogComponent} from './threshold-confirm-dialog/threshold-confirm-dialog.component';
import {ThresholddialogreportService} from '../services/thresholddialogreport.service';
import {Thresholddialogdetailreportinput} from '../models/thresholddialogdetailreportinput';
import {Thresholddialogsummaryreportinput} from '../models/thresholddialogsummaryreportinput';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {UsersService} from '../services/users.service';
import {User} from '../models/User';
import {CellRendererComponent} from '../admin/extract-epr-feed-gui/cell-renderer.component';
import {StatusPopoverComponent} from '../dashboard/status-popover/status-popover.component';
import { MessageSupressionService } from '../services/message-supression.service';
import {CVSAlertService, CVSAlertType} from 'angular-component-library';
import {DownloadBtnCellRendererComponent} from '../common/DownloadBtnCellRenderer';
import { AppState, getPermissionsProcessingFilesObject } from '../reducers';
import { MatTableExporterDirective } from 'mat-table-exporter';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatButtonToggleGroup } from '@angular/material/button-toggle';
import { ColDef } from 'ag-grid-community';
import { HttpErrorResponse } from '@angular/common/http';
import { ProcessingFileDB } from '../models/PermissionsObject';
import { catchError, switchMap } from 'rxjs/operators';
import { forkJoin, Observable, of } from 'rxjs';
export interface DialogData {
  selected: string;
  data: any;
}

export interface ThresholdLogInput {
  meaud: string;
}

export interface AuditLogInput {
  memid: string;
  mecar: string;
  melib: string;
  mefile: string;
  membr: string;
  meaud: string;
}

export interface CommentInput {
  pli: string;
  carrier: string;
  fileName: string;
  memberName: string;
  library: string;
  commentedBy: string;
  commentedText: string;
}
export interface PeriodicElement {
  position: number;
  Carrier: string;
  Total: string;
  MP: string;
  MQ: string;
  MR: string;
  MS: string;
  MT: string;
  MU: string;
  MV: string;
  MW: string;
  MX: string;
  MY: string;
  MZ: string;
  NA: string;
}

const ELEMENT_DATA: PeriodicElement[] = [
  {position: 1,  Carrier: 'Total Records Received', Total: '1.0079', MP: '451197', MQ:"3648200", MR:"78564", MS:"114567", MT:"78787", MU:"43434", MV:"6734", MW:"89978",MX:"343435", MY:"676767", MZ:"2324", NA:"7887" },
  {position: 2,  Carrier: 'Header/Trailer', Total: '1.0079', MP: '451197', MQ:"3648200", MR:"78564", MS:"114567", MT:"", MU:"", MV:"", MW:"",MX:"", MY:"", MZ:"", NA:""},
  {position: 2,  Carrier: 'Members', Total: '1.0079', MP: '451197', MQ:"3648200", MR:"78564", MS:"114567", MT:"", MU:"", MV:"", MW:"",MX:"", MY:"", MZ:"", NA:""},
  {position: 1,  Carrier: 'Total Exceptions', Total: '1.0079', MP: '451197', MQ:"3648200", MR:"78564", MS:"", MT:"", MU:"", MV:"", MW:"",MX:"", MY:"", MZ:"", NA:""},
  {position: 2,  Carrier: 'Members Rejected', Total: '1.0079', MP: '451197', MQ:"3648200", MR:"78564", MS:"", MT:"", MU:"", MV:"", MW:"",MX:"", MY:"", MZ:"", NA:""},
  {position: 1,  Carrier: 'Informational', Total: '1.0079', MP: '451197', MQ:"3648200", MR:"78564", MS:"", MT:"", MU:"", MV:"", MW:"",MX:"", MY:"", MZ:"", NA:""},
  {position: 2,  Carrier: 'Members Added', Total: '1.0079', MP: '451197', MQ:"3648200", MR:"78564", MS:"", MT:"", MU:"", MV:"", MW:"",MX:"", MY:"", MZ:"", NA:""},
  {position: 2,  Carrier: 'Terms on File (not open ended)', Total: '1.0079', MP: '451197', MQ:"3648200", MR:"78564", MS:"", MT:"", MU:"", MV:"", MW:"",MX:"", MY:"", MZ:"", NA:""},
  {position: 3,  Carrier: 'Retro Terms', Total: '1.0079', MP: '451197', MQ:"3648200", MR:"78564", MS:"", MT:"", MU:"", MV:"", MW:"",MX:"", MY:"", MZ:"", NA:""},
  {position: 3,  Carrier: 'Current Terms', Total: '1.0079', MP: '451197', MQ:"3648200", MR:"78564", MS:"", MT:"", MU:"", MV:"", MW:"",MX:"", MY:"", MZ:"", NA:""},
  {position: 3,  Carrier: 'Future Terms', Total: '1.0079', MP: '451197', MQ:"3648200", MR:"78564", MS:"", MT:"", MU:"", MV:"", MW:"",MX:"", MY:"", MZ:"", NA:""},
  {position: 2,  Carrier: 'Demographic Changes', Total: '1.0079', MP: '451197', MQ:"3648200", MR:"78564", MS:"", MT:"", MU:"", MV:"", MW:"",MX:"", MY:"", MZ:"", NA:""},
  {position: 2,  Carrier: 'Person Code partial Match', Total: '1.0079', MP: '451197', MQ:"3648200", MR:"78564", MS:"", MT:"", MU:"", MV:"", MW:"",MX:"", MY:"", MZ:"", NA:""},
  {position: 1,  Carrier: 'Total Records loaded', Total: '1.0079', MP: '451197', MQ:"3648200", MR:"78564", MS:"", MT:"", MU:"", MV:"", MW:"",MX:"", MY:"", MZ:"", NA:""},
  {position: 2,  Carrier: 'Member', Total: '1.0079', MP: '451197', MQ:"3648200", MR:"78564", MS:"", MT:"", MU:"", MV:"", MW:"",MX:"", MY:"", MZ:"", NA:""},

];
@Component({
  selector: 'app-threshold-dialog.component',
  templateUrl: 'threshold-dialog.component.html',
  styleUrls: ['threshold-dialog.component.scss'],
  animations: [
    trigger('showAndHideAnimator', [
      state('show', style({ opacity: 1, color: 'red' })),
      state('hide', style({ opacity: 0, color: 'black' })),
      transition('show=>hide', [
        animate('0s')
      ]),
      transition('hide=>show', [
        animate('0s')
      ])
    ])
  ]
})
export class ThreasholdDialogComponent implements OnInit, AfterViewInit {

  requestMessage: any;
  auditComplete: any;
  ssoerequestMessage: any;

  public gridOptions: GridOptions;

  balanceSheetLast: Date = new Date();
  auditLogLast: Date = new Date();
  thresholdLast: Date = new Date();
  user: any;
  userId: any;
  showSnackBarConfirmation: boolean;

  @ViewChild('thresholdGrid') thresholdGrid: AgGridAngular;
  @ViewChild('auditGrid') auditGrid: AgGridAngular;
  @ViewChild('balanceSheetGrid') balanceSheetGrid: AgGridAngular;
  @ViewChild(MatTableExporterDirective) matTableExporter: MatTableExporterDirective;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  toolTipUser: any;
  message = 'Are you sure?';
  library: string;
  carrier: string;
  clientName: string;
  fileName: string;
  memberName: string;
  pli: string;
  status: string;
  load: string;
  ec: string;
  auditlogColumns: any;
  commentedText = '';
  loadUser: any;
  audit: any;
  isAdvanced: boolean;
  isCancelled: boolean;
  isResetToPreload: boolean;
  isSummay: boolean;
  isDetails: boolean;
  thresholdType: any;

  reportMessage: any = '';
  currentTabIndex = 0;
  currentRowData: any;

  backUser: any;
  getRowStyle: any;
  auditlogRowData: any[];
  selectedTabVal: string;
  contextObj: any;
  permissionObject$ = this.store.select(getPermissionsProcessingFilesObject)
  permissionObject:any;
  disableButtons = false;
  rxclaimMessage: any = '';
  thresholdRecords: any[];
  db:ProcessingFileDB;
  currentUserRole: any;
  ssoeFlag: boolean = true;
  displayedColumns: string[];
  stickyColumns = ['Carrier'];
  stickyHeaders = ['header-1'];
  rowKeyFields;
  balancesheetCareers;
  arrConstantFields = ['Carrier', 'Total'];
  showscollbar: boolean = false;
  carrierList = [
                  {position: 1, carrierName: "Total Record Received", field: "totalRecordsReceived"},
                  {position: 2, carrierName: "Header/Trailer", field: 'headerTrailerCount'},
                  {position: 2, carrierName: "Members", field: "inputCount"},
                  {position: 1, carrierName: "Total Exceptions", field: "totalException"},
                  {position: 2, carrierName: "Members Rejected", field: "rejectCount"},
                  {position: 1, carrierName: "Informational", field: ""},
                  {position: 2, carrierName: "Members Suspended", field: ""},
                  {position: 2, carrierName: "Members UnSuspended", field: ""},
                  {position: 2, carrierName: "Members Dropped from Suspense", field: ""},
                  {position: 2, carrierName: "History unsuspended", field: ""},
                  {position: 2, carrierName: "History Dropped from suspense", field: ""},
                  {position: 2, carrierName: "ST-Links suspended", field: ""},
                  {position: 2, carrierName: "Members Added", field: "addedCount"},
                  {position: 2, carrierName: "Terms on File(not open ended)", field: ""},
                  {position: 1, carrierName: "Total Added", field: "addedCount"},
                  {position: 1, carrierName: "Terms", field: "totalTerms"},
                  {position: 2, carrierName: "Retro Terms", field: "retroTerms"},
                  {position: 2, carrierName: "Current Terms", field: "currentTerms"},
                  {position: 2, carrierName: "Future Terms", field: "futureTerms"},
                  {position: 2, carrierName: "Implied Terms due to Mbr Move", field: ""},
                  {position: 2, carrierName: "Privacy move due to Implied Term", field: ""},
                  {position: 2, carrierName: "Dependent Term due to CH Term", field: ""},
                  {position: 2, carrierName: "Term by Absense", field: ""},
                  {position: 2, carrierName: "Batch Override Protected", field: ""},
                  {position: 2, carrierName: "Manual TBA Changes", field: ""},
                  {position: 2, carrierName: "Bad Addresses", field: ""},
                  {position: 2, carrierName: "History Move Total", field: ""},
                  {position: 3, carrierName: "From File", field: ""},
                  {position: 3, carrierName: "From Implied Terms Mbr Move", field: ""},
                  {position: 2, carrierName: "Alternate IDs created", field: ""},
                  {position: 2, carrierName: "Accounts created", field: ""},
                  {position: 2, carrierName: "Groups created", field: ""},
                  {position: 2, carrierName: "Care Facility Created", field: ""},
                  {position: 2, carrierName: "Care Qualifier Created", field: ""},
                  {position: 2, carrierName: "Misc", field: ""},
                  {position: 2, carrierName: "Demographic Changes", field: ""},
                  {position: 2, carrierName: "Person Codse Partial Match", field: ""},
                  {position: 1, carrierName: "Total Records Loaded", field: "totalRecordLoaded"},
                  {position: 2, carrierName: "Members Loaded", field: ""},
                  {position: 2, carrierName: "Members", field: "members"},
                  {position: 2, carrierName: "Status", field: ""},

                ];
  arrStaticRows = ["totalRecordsReceived" , "totalException" , "totalTerms","totalRecordLoaded","members"]; //
  dataSource: any;
  defaultColDefTG:ColDef = {
      flex: 0,
      minWidth: 50,
      editable: true,
      resizable: false
    };
    rowClassRulesThresholdGrid = {
      'error-row': params => params.api.getValue('status', params.node) === 'Error',
      'waiting-row': params => params.api.getValue('status', params.node) === 'Waiting'
    };
    tooltipShowDelayTG = 0;

    defaultColDefAuditLg = {
      flex: 0,
      minWidth: 130,
      editable: true,
      sortable: true,
      resizable: false
    };
    rowClassRulesAuditLg = {
      'user-row': params => params.api.getValue('auditUser', params.node) !== 'SYSTEM',
    };
    tooltipShowDelayAuditLg = 0;

    defaultColDefBalSheet = {
      // flex: 0,
      // minWidth: 130,
      editable: true,
      resizable: true,
    };

    tooltipShowDelayBalSheet = 0;
  userName: string;

  constructor(private store: Store<fromRoot.AppState>, private msgSupressService: MessageSupressionService,
              @Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<ThreasholdDialogComponent>,
              private auditlogService: AuditlogService, private thresholdService: ThresholdService,
              private balanceSheetService: BalancesheetService,
              private eprService: EprService,
              private commentsService: CommentService,
              private dialog: MatDialog,
              private snackBar: MatSnackBar,
              private alertService: CVSAlertService,
              private thresholddialogreportService: ThresholddialogreportService,
              private userService: UsersService) {
                this.contextObj = {
                  componentParent: this
                };

  }

  ngOnInit() {
    const rowData = this.data.body;
    this.ssoeFlag = rowData.ssoeFlag;
    this.currentRowData = rowData;
    this.db = this.data.db;
    this.store.subscribe(resp => {
      if (resp && resp.user) {
        this.userId = resp.user.id;
      }
    });
    this.userService.userFullName.subscribe(res=>{
      this.userName = res
    })
    if (this.data && rowData) {
      // const fileDetails: string = rowData.g13.split('/');
      this.library = rowData.g13;
      this.carrier = rowData.g2;
      this.clientName = rowData.g1;
      this.fileName = rowData.g14;
      this.memberName = rowData.g6;
      this.pli = rowData.g3;
      this.ec = rowData.g4;
      this.status = rowData?.tRecord?.input;
      this.load = rowData.g8;
      this.loadUser = rowData.g9;
      this.audit = rowData.g10;
      this.isSummay = rowData.tRecord && rowData.tRecord.isSummary === 'Y' ? true : false;
      this.isDetails = rowData.tRecord && rowData.tRecord.isDetailed === 'Y' ? true : false;
      this.updateStatus(rowData);

      this.eprService.getMyUser().subscribe(resp => {
        this.user = resp;
        this.toolTipUser = rowData.g3;
      });
    }
    this.getBackupUser();
    this.selectedTabVal = 'Extended';

    this.userService.userRole.subscribe(role => {
      this.currentUserRole = role;
    })
    this.permissionObject$.subscribe(res=>{
      this.permissionObject = res
    })
  }
  updateStatus(rowData: any) {
    this.isAdvanced = rowData.tRecord && rowData.tRecord.isAdvanced === 'Y';
    this.isCancelled = rowData.tRecord && rowData.tRecord.isCancelled === 'Y' ? true : false;
    this.isResetToPreload = rowData.tRecord && rowData.tRecord.isResetToPreload === 'Y' ? true : false;
    this.isSummay = rowData.tRecord && rowData.tRecord.isSummary === 'Y' ? true : false;
    this.isDetails = rowData.tRecord && rowData.tRecord.isDetailed === 'Y' ? true : false;
  }
  isSticky(arr, id: string) {
    return (arr || []).indexOf(id) !== -1;
  }

  closeDialog(): void {
    this.dialogRef.close(true);
  }

   ngAfterViewInit(): void {

    this.reportMessage = '';

    // this.thresholdGrid.gridOptions.
    let thresholdColumns= ColumnSets.thresholdColumns;
   if(this.ssoeFlag){
    thresholdColumns = ColumnSets.thresholdColumns.filter(item => item.field !=='status')
 }
    this.thresholdGrid.api.setGridOption("columnDefs", thresholdColumns);
    this.createThresholdData();


    this.auditGrid.api.setGridOption("columnDefs", ColumnSets.auditLogColumns);

    this.createAuditLogData(this.selectedTabVal);
    // this.filterMsgAbberviated();
    this.createBalanceSheetData();
    if(this.balanceSheetGrid) {

      this.balanceSheetGrid.api.setGridOption("columnDefs", ColumnSets.balanceSheetCols);

      this.balanceSheetGrid.api.setGridOption("headerHeight", 100);
      this.balanceSheetGrid.api.sizeColumnsToFit();
    }
  }

  private createThresholdData() {
    let request ;
    request = { meaud: this.audit ? this.audit?.trim() : "" } as ThresholdLogInput;

    this.thresholdService.getThresholdData(request, this.ssoeFlag, this.db).subscribe(rowData => {
      const response = rowData.response?.map(itm => ({ ...itm, thresholdType : itm.thresholdType?.trim(), carrier : itm.carrier?.trim() }));
      this.rxclaimMessage = rowData.message;
      if (this.rxclaimMessage === 'View unavailable due to scheduled maintenance') {
        this.showRxClaimErrorMessage();
        this.disableButtons = true;
      }
      if (response && response.length > 0) {
        if (this.data.body) {
          response.forEach(record => {
            record.translationStatus = this.data.body.tRecord ? this.data.body.tRecord.status : this.status;
            if (this.data.body && this.data.body.translationsRecords) {
              record.translationRecords = this.data.body.translationsRecords;
              const currentSortedTRecord = record.translationRecords.sort((a, b) => a.translationId - b.translationId);
              const currentTID = currentSortedTRecord.findIndex(el => record.status === el.input.trim());
              const currentTRecord = currentSortedTRecord[currentTID];
              // if record.status is empty, then softtext can be empty
              if (record.status === ''  ) {
                const sortedRec = currentSortedTRecord[currentSortedTRecord.findIndex(el => this.status === el.input.trim())];
                record.softText = sortedRec && sortedRec.softText;
              } else {
                record.softText = currentTRecord ? currentTRecord.softText : this.data.body.progressBarDetails
                  && this.data.body.progressBarDetails.softText;
              }
            }
            record.body = this.data.body;
          });
          response.map(res => {
            if(Number(res.records) === 0){
              res.download = false;
            } else{
              res.download = true;
            }
            return res;
          });
        }
      }
      this.thresholdGrid.api.setGridOption("rowData",response);
    });
  }

  colorInGrid = (params) => {
    const currVal = params.data;
    if (Number(currVal.calculatedThreshold) > Number(currVal.definedThreshold)) {
      return {'background-color': '#fae6e6' };
    }
    return null;
  }


   createAuditLogData(selectedTab?) {

    const request = {
      mecar: this.carrier ? this.carrier.trim() : '',
      memid: this.pli ? this.pli.trim() : '',
      melib: this.library ? this.library.trim() : '',
      mefile: this.fileName ?  this.fileName.trim() : '',
      membr: this.memberName ? this.memberName.trim() : '',
      meaud: this.audit ? this.audit.trim():''
    } as AuditLogInput;

    this.auditlogService.getAuditLog(request, this.ssoeFlag, this.db).subscribe(rowData => {
      if (rowData && rowData.message) {
      this.rxclaimMessage = rowData.message;
      }
      if (this.rxclaimMessage === 'View unavailable due to scheduled maintenance' && !this.ssoeFlag) {
        this.showRxClaimErrorMessage();
        this.disableButtons = true;
      }
      this.auditlogRowData = rowData.response;

      if (selectedTab === 'Default') {
      this.auditGrid.api.setGridOption("rowData",rowData.response);
      }else if(selectedTab === 'Extended'){
        this.filterMsgAbberviated(this.auditlogRowData)
      }
    });  }

  createBalanceSheetData() {

    const request = {
      mecar: this.carrier ? this.carrier.trim() : '',
      memid: this.pli ? this.pli.trim() : '',
      melib: this.library ? this.library.trim() : '',
      mefile: this.fileName ?  this.fileName.trim() : '',
      membr: this.memberName ? this.memberName.trim() : '',
      meaud: this.audit ? this.audit.trim():''
    } as AuditLogInput;

    this.balanceSheetService.getBalanceSheet(request, this.ssoeFlag,this.db).subscribe((rowData:any) => {
      if(this.ssoeFlag && rowData.length > 0 && rowData[0]?.carriers && rowData[0]?.carriers.length > 0){
        this.showscollbar = true;
        let careerHeaders = rowData[0]?.carriers?.map(value => value.carrierID);
        this.displayedColumns = [...this.arrConstantFields, ...careerHeaders];
        this.displayedColumns = this.displayedColumns.filter(col=> col !== undefined)
        this.balancesheetCareers = rowData[0]?.carriers;
        let rowKeyFields:Array<any>;
        if(rowData[0]?.carriers[0] &&  rowData[0]?.carriers[0]?.recordTypes[0]) {
          rowKeyFields = Object.keys(rowData[0]?.carriers[0]).concat(Object.keys(rowData[0]?.carriers[0]?.recordTypes[0]));
        } else {
          // var values = (Object.keys(rowData[0]?.carriers)).reduce((accumulator, current) => {
          //   return rowData[0]?.carriers[current].recordTypes;
          // }, []);
          var values =  rowData[0]?.carriers.filter(data=>data.recordTypes.length);

          rowKeyFields = values.length? Object.keys(rowData[0]?.carriers[0]).concat(Object.keys(values[0])) :
          Object.keys(rowData[0]?.carriers[0])
        }

        //let rowKeyFields = Object.keys(rowData[0]?.carriers[0]).concat(Object.keys(rowData[0]?.carriers[0]?.recordTypes[0]));
        this.rowKeyFields  = this.carrierList.filter(item => {
          if(rowKeyFields.length === 3 && item.field === "totalException"){
            return false;
          }
          return rowKeyFields.includes(item.field) || (this.arrStaticRows.includes(item.field))}

        );
        //rowKeyFields Represent each row
        this.rowKeyFields.forEach((row: any, index)=>{
          let fieldName = row?.field;
          let fieldsList =  (this.carrierList.filter((x=>x.field!==''))).map(({field})=> field);
          this.displayedColumns?.forEach(colheading => {
            //displayedCoumns represent each colums
            if(this.arrConstantFields.includes(colheading)) {
              if(this.arrConstantFields[0] === colheading){//  represnt the first column on each row
                row[colheading] = row?.carrierName;
              } else{
                let total = 0;
                this.balancesheetCareers.forEach((carrier, index)=>{
                  let additionalVal = 0;
                    if(index===0 && this.balancesheetCareers.length > 1) {
                      additionalVal = 2;
                    }
                  if(fieldName==="totalRecordsReceived"){
                    let headerTrailerCnt = carrier?.["headerTrailerCount"] ? carrier?.["headerTrailerCount"]: 0;
                    let membercnt = (carrier?.recordTypes && carrier?.recordTypes.length >0 && carrier?.recordTypes[0]?.["inputCount"]) ? carrier?.recordTypes[0]?.["inputCount"]: 0;
                    total+=  (headerTrailerCnt+additionalVal) + (membercnt);
                  } else if(fieldName==="totalException") { // Total Exception calculation for Total column
                    let rejectCnt = (carrier?.recordTypes && carrier?.recordTypes.length >0 && carrier?.recordTypes[0]?.["rejectCount"]) ? carrier?.recordTypes[0]?.["rejectCount"]: 0;
                    total+=  rejectCnt;
                  } else if(fieldName === "totalTerms"){
                    total+= (carrier?.recordTypes && carrier?.recordTypes.length >0 ? (
                     ( ( carrier?.recordTypes[0]?.["retroTerms"]) ? carrier?.recordTypes[0]?.["retroTerms"]: 0 ) +
                     ( ( carrier?.recordTypes[0]?.["currentTerms"]) ? carrier?.recordTypes[0]?.["currentTerms"]: 0) +
                     ( ( carrier?.recordTypes[0]?.["futureTerms"]) ? carrier?.recordTypes[0]?.["futureTerms"]: 0 )
                     ):0 )
                  }else if(fieldName === "totalRecordLoaded" || fieldName === "members"){
                    total+= (carrier?.recordTypes && carrier?.recordTypes.length >0 ? (
                      ( ( carrier?.recordTypes[0]?.["addedCount"]) ? carrier?.recordTypes[0]?.["addedCount"]: 0 ) +
                      ( ( carrier?.recordTypes[0]?.["auditCount"]) ? carrier?.recordTypes[0]?.["auditCount"]: 0) +
                      ( ( carrier?.recordTypes[0]?.["changeCount"]) ? carrier?.recordTypes[0]?.["changeCount"]: 0 )
                      ):0 )

                      if(fieldName === "members"){
                        total = total - ( ( carrier?.recordTypes[0]?.["rejectCount"]) ? carrier?.recordTypes[0]?.["rejectCount"]: 0 );
                        if(total<0){
                          total = 0;
                        }
                      }
                  }
                  else {
                    let fieldVal = carrier?.[fieldName] ? (carrier?.[fieldName]): (carrier?.recordTypes && carrier?.recordTypes.length >0 && carrier.recordTypes[0]?.[fieldName] ? carrier.recordTypes[0]?.[fieldName]: 0)
                    total+= fieldVal
                  }
                })
                row[colheading] = (fieldName === "headerTrailerCount" && this.balancesheetCareers.length > 1)? (total + 2): total;
              }

            } else{
              let find = this.balancesheetCareers.filter(x=>x.carrierID === colheading);
              if(find && find.length > 0 && fieldsList && fieldsList.length > 0) {
                let objFields  = {}
                fieldsList.forEach(field => {
                  if(this.arrStaticRows.includes(field)) {
                    if(field==="totalRecordsReceived") {
                      objFields[field] =  (find[0]?.["headerTrailerCount"] ? find[0]?.["headerTrailerCount"]: 0) + ((find[0]?.recordTypes[0]?.["inputCount"]) ? (find[0]?.recordTypes[0]?.["inputCount"]): 0)
                    }else if(field === "totalTerms"){
                      objFields[field] = (find[0]?.recordTypes && find[0]?.recordTypes.length >0 ? (
                        (( find[0]?.recordTypes[0]?.["retroTerms"]) ? find[0]?.recordTypes[0]?.["retroTerms"]: 0 )+
                        (( find[0]?.recordTypes[0]?.["currentTerms"]) ? find[0]?.recordTypes[0]?.["currentTerms"]: 0) +
                        (( find[0]?.recordTypes[0]?.["futureTerms"]) ? find[0]?.recordTypes[0]?.["futureTerms"]: 0 )
                       ):0 )
                    }
                    else if(field === "totalRecordLoaded" || field === "members"){
                     let value = (find[0]?.recordTypes && find[0]?.recordTypes.length >0 ? (
                        ( ( find[0]?.recordTypes[0]?.["addedCount"]) ? find[0]?.recordTypes[0]?.["addedCount"]: 0 ) +
                        ( ( find[0]?.recordTypes[0]?.["auditCount"]) ? find[0]?.recordTypes[0]?.["auditCount"]: 0) +
                        ( ( find[0]?.recordTypes[0]?.["changeCount"]) ? find[0]?.recordTypes[0]?.["changeCount"]: 0 )
                        ):0 )
                        if(field === "members"){
                          value = value - ( ( find[0]?.recordTypes[0]?.["rejectCount"]) ? find[0]?.recordTypes[0]?.["rejectCount"]: 0 );
                          if(value<0){
                            value = 0;
                          }
                        }
                        objFields[field] = value;
                    }
                     else { // Total Exception calculation
                      objFields[field] = find[0]?.recordTypes[0]?.["rejectCount"] ? find[0]?.recordTypes[0]?.["rejectCount"] : 0;
                    }
                  } else{
                    objFields[field] = find[0]?.[field] ? (find[0]?.[field]) : (find[0]?.recordTypes[0]?.[field] ? find[0]?.recordTypes[0]?.[field] : 0);
                  }
                });
                row[colheading] = objFields;
              }
            }
        });
        })
        this.dataSource = this.rowKeyFields;
        this.dataSource.paginator = this.paginator;
      }
      if(this.ssoeFlag && rowData?.response && rowData?.response?.length >0) {
        rowData.response.map((obj) => {
          let result   = this.carrierList.find((item)=> item.carrierName === obj.carrier);
          if(obj.carrier==="Members") {
            obj.total  = 0;
          }
          obj.hidestatus = obj.total > 0 ? false: true;
          obj.position = result?.position;
        });
        this.dataSource = rowData.response;
        this.dataSource.paginator = this.paginator;
      }

      this.rxclaimMessage = rowData.message;
      if (this.rxclaimMessage === 'View unavailable due to scheduled maintenance') {
        this.showRxClaimErrorMessage();
        this.disableButtons = true;
      }
      this.balanceSheetGrid?.api.setGridOption("rowData",rowData.response);
    });
  }

  openConfirmationDialog(requestType: string, icon: string, actionIn: string) {
    this.auditComplete = '';
    this.dialog.open(ThresholdConfirmDialogComponent, {
      data: {
        request_type: requestType,
        icon,
        message: requestType?.replace('_FILE', ' ').toLowerCase(),
        onConfirm: () => this.createRequest(actionIn, requestType)
      } as ThresholdConfirmConstants
    }).afterClosed().subscribe(res => {
      if (res) {
        if (res.action !== 'back' && res.commentedNotes) {
          this.commentedText = res.commentedNotes;
          /*commented below line because we are calling 2 times add comment api*/
          // const apis = [this.addComment(actionIn), this.addCommentStatusToAuditLog()];
          const apis = [this.addComment(actionIn)];
          forkJoin(apis).subscribe(resp=>{

            switch (actionIn) {
              case 'Balance Sheet': {
                this.createBalanceSheetData();
                break;
              }
              case 'Threshold': {
                this.createThresholdData();
                break;
              }
              case 'Audit logs': {
                this.createAuditLogData(this.selectedTabVal);
                break;
              }
            }
            this.updateDialogReferenceStatus();
          })

        }
      }
    });
  }

  createRequest(actionIn: string, requestType: string) {
    switch (actionIn) {
      case 'Balance Sheet': {
        this.createBalSheetRequest(requestType);
        break;
      }
      case 'Threshold': {
        this.createThresholdRequest(requestType);
        break;
      }
      case 'Audit logs': {
        this.createAuditLogRequest(requestType);
        break;
      }
      default: {
        this.openSnackBar(actionIn);
        break;
      }
    }
  }
  openSnackBar(action) {
    this.showSnackBarConfirmation = !this.showSnackBarConfirmation;
  }

  createRxClaimRequest(requestType: string) {
    this.thresholdService.createThresholdRequest(this.buildRequest(requestType), this.ssoeFlag).subscribe({
      next: (resp) => {
        let ssoeerrorResp;
        let error:boolean = false;
        if(resp instanceof HttpErrorResponse && this.ssoeFlag){
          ssoeerrorResp = resp instanceof HttpErrorResponse
          if(resp.error.updateStatus === "Failed")
          this.ssoerequestMessage = 'Transaction Failed';
          error = true;
        }
        if(this.ssoeFlag && resp && !ssoeerrorResp){
          if(resp.updateStatus === "Success"){
            this.ssoerequestMessage = 'Transaction Successful';
            error = false;
          }else if(resp.updateStatus === "Failed") {
            this.ssoerequestMessage = 'Transaction Failed';
            error = true;
          }

        }
        if(this.ssoeFlag){
          this.showSsoeBannerMessage(error);
        }else if(this.commentedText.length === 0 && this.auditComplete && !this.ssoeFlag){
          this.showAuditBannerMessage(); //audit message is the request message when comment api runs after confirmation.
        }
        if (this.rxclaimMessage === 'View unavailable due to scheduled maintenance' && !this.ssoeFlag) {
          this.showRxClaimErrorMessage();
          this.rxclaimMessage = resp.Message;
          this.disableButtons = true;
        }
      },
    });
  }
  createBalSheetRequest(requestType: string) {
    this.createRxClaimRequest(requestType);
    this.createBalanceSheetData();
  }

  createThresholdRequest(requestType: string) {
    this.createRxClaimRequest(requestType);
    this.createThresholdData();
  }

  createAuditLogRequest(requestType: string) {
    this.createRxClaimRequest(requestType);
    this.createAuditLogData(this.selectedTabVal);
  }


  addComment(actionIn):Observable<any> {
    const request = {
      auditId: this.audit?.trim(),
      carrier : this.carrier?.trim(),
      memberName : this.memberName ? this.memberName?.trim(): '',
      library : this.library?.trim(),
      fileName : this.fileName ? this.fileName?.trim(): '',
      pli : this.pli?.trim(),
      commentedText : this.commentedText?.trim(),
      commentedBy : this.user.lastName + ', ' + this.user.firstName
    } as CommentInput;

    return this.commentsService.addComment(request).pipe(switchMap(rowData=>{
     // this.createAuditLogData(this.selectedTabVal);
        this.commentedText = '';
        this.auditComplete = undefined;
       // this.data = rowData.body;
      return of(rowData)
    }),
  catchError(err=>{return of(err)}));
  }

  addCommentStatusToAuditLog() : Observable<any> {
    const request = {
      carrier : this.carrier?.trim(),
      memberName : this.memberName?.trim(),
      library : this.library?.trim(),
      fileName : this.fileName?.trim(),
      pli : this.pli?.trim(),
      commentedText : this.requestMessage,
      commentedBy : this.user.lastName + ', ' + this.user.firstName
    } as CommentInput;

    return this.commentsService.addComment(request).pipe(switchMap(rowData => {
      //this.createAuditLogData(this.selectedTabVal);
      this.auditComplete = this.requestMessage;
     // this.updateDialogReferenceStatus();
      return of(rowData);
    }),
  catchError(err=>{return of(err)}));
  }
  addMainComment(){
    this.addComment('Threshold').pipe(catchError(err=>{return of(err)})).
    subscribe(resp=>{this.createAuditLogData(this.selectedTabVal);});
  }

  refreshBalSheet() {
    this.createBalanceSheetData();
    this.updateDialogReferenceStatus();
    this.balanceSheetLast = new Date();
  }

  refreshAuditLog() {
    this.createAuditLogData(this.selectedTabVal);
    this.updateDialogReferenceStatus();
    this.auditLogLast = new Date();
  }

  refreshThreashold() {
    this.createThresholdData();
    this.updateDialogReferenceStatus();
    this.thresholdLast = new Date();
  }

  getBackupUser() {

   if (!this.ec) {
     this.backUser = '';
     return;
    }
   if (this.ec === 'Undefined Name') {
      this.backUser = '';
      return;
    }
   const actualUser = this.ec.split(',');
   let ecUser: any;
   if (this.backUser === undefined || this.backUser === '') {
     const lastName = actualUser[0] ? actualUser[0].trim() : '';
     const firstName = actualUser[1] ? actualUser[1].trim() : '';
     this.userService.getUserByFirstAndLastName(firstName, lastName).subscribe(response => {
       ecUser = response;

       if (ecUser) {
         if (ecUser.backUpUser) {
           this.backUser = ecUser.backUpUser.lastName + ', ' + ecUser.backUpUser.firstName;
         } else {
           this.backUser = '';
         }
       } else {
         this.backUser = '';
       }
     });
   }
  }

  buildRequest(reqType: string) {
    if(this.ssoeFlag){

      switch (reqType){
        case 'ADVANCE_FILE':
          reqType = 'advance';
          break;
        case 'CANCEL_FILE':
          reqType = 'cancel';
          break;
        default:
          break;
      }

      return {
          "mediaId": this.audit,
          "requestType": reqType,
          "userID": this.userName
      };
    }else{
      return {
        request : {
          requestType: reqType,
          audit: this.audit,
          user: this.loadUser
        }
      };
    }

  }

  exportAuditLogSummary() {
    this.showReportMessageDiv();
    const request = {
      auditNumber: this.audit,
      pli: this.pli
    } as Thresholddialogsummaryreportinput;

    this.rxclaimMessage = this.thresholddialogreportService.exportAuditLogSummary(request, this.ssoeFlag, this.db,this.alertService);
    if (this.rxclaimMessage === 'View unavailable due to scheduled maintenance') {
      this.showRxClaimErrorMessage();
      this.disableButtons = true;
    }
  }

  exportAuditLogDetails() {
    this.showReportMessageDiv();
    const request = {
      auditNumber: this.audit,
      pli: this.pli,
    } as Thresholddialogdetailreportinput;
    this.rxclaimMessage = this.thresholddialogreportService.exportAuditLogDetails(request, this.ssoeFlag, this.db, this.alertService);
    if (this.rxclaimMessage === 'View unavailable due to scheduled maintenance') {
      this.showRxClaimErrorMessage();
      this.disableButtons = true;
    }
  }

  exportBalanceSheetSummary() {
    this.showReportMessageDiv();
    const request = {
      auditNumber: this.audit,
      pli: this.pli,
    } as Thresholddialogsummaryreportinput;

   this.rxclaimMessage = this.thresholddialogreportService.exportBalanceSheetSummary(request, this.ssoeFlag, this.db ,this.alertService);
    if (this.rxclaimMessage === 'View unavailable due to scheduled maintenance') {
      this.showRxClaimErrorMessage();
      this.disableButtons = true;
    }
  }


  exportBalanceSheetDetails() {
    this.showReportMessageDiv();
    const request = {
      auditNumber: this.audit,
      pli:this.pli,
    } as Thresholddialogdetailreportinput;
    this.rxclaimMessage = this.thresholddialogreportService.exportBalanceSheetDetails(request, this.ssoeFlag, this.db ,this.alertService);
    if (this.rxclaimMessage === 'View unavailable due to scheduled maintenance') {
      this.showRxClaimErrorMessage();
      this.disableButtons = true;
    }

  }

  exportThresholdSummary() {
    this.showReportMessageDiv();
    const request = {
      auditNumber: this.audit,
      pli:this.pli,
    } as Thresholddialogsummaryreportinput;

    this.rxclaimMessage = this.thresholddialogreportService.exportThresholdSummary(request, this.ssoeFlag, this.db,this.alertService);
    if (this.rxclaimMessage === 'View unavailable due to scheduled maintenance') {
      this.showRxClaimErrorMessage();
      this.disableButtons = true;
    }
  }
  downloadExcel(node, columnId) {
    this.showReportMessageDiv();
    let request;
    if (this.ssoeFlag) {
      request = {
        pli: this.pli,
        mediaId: this.audit,
        carrierId: node.data.carrier,
        thresholdTechnicalName: node.data.thresholdInputType,
        db:this.db
      };
    } else {
      request = {
        carrier: node.data.carrier,
        auditNumber: this.audit,
        type: node.data.thresholdType,
        message: node.data.message,
      };
    }
    this.thresholddialogreportService.exportThresholdType(request,this.ssoeFlag,this.alertService);

  }

  exportThresholdDetails() {
    this.showReportMessageDiv();
    const request = {
      auditNumber: this.audit,
      pli:this.pli,
    } as Thresholddialogdetailreportinput;

    this.rxclaimMessage = this.thresholddialogreportService.exportThresholdDetails(request, this.ssoeFlag,this.db ,this.alertService);
    if (this.rxclaimMessage === 'View unavailable due to scheduled maintenance') {
      this.showRxClaimErrorMessage();
      this.disableButtons = true;
    }

  }

  cleanupTransaction(event) {
    this.auditComplete = '';
    this.currentTabIndex = event.index;
    this.reportMessage = '';
  }

  showReportMessageDiv() {
    this.alertService.sendAlert({
      headline: 'Downloads take a while depending on the file size.',
      alertType: CVSAlertType.Success,
      alertDocumentHolderName: '#summary-alert',
    });
  }

  showRxClaimErrorMessage() {
    this.alertService.sendAlert({
      headline: 'View unavailable due to scheduled maintenance.',
      alertType: CVSAlertType.Error,
      alertDocumentHolderName: '#summary-alert',
    });
  }
  showSsoeBannerMessage(error:boolean){
    this.alertService.sendAlert({
      headline: this.ssoerequestMessage,
      alertType: error? CVSAlertType.Error :CVSAlertType.Success,
      alertDocumentHolderName: '#summary-alert',
    });
  }
  showAuditBannerMessage(){
    this.alertService.sendAlert({
      headline: this.auditComplete,
      alertType:  CVSAlertType.Info ,
      alertDocumentHolderName: '#summary-alert',
    });
  }
  updateDialogReferenceStatus() {
      // Getting rowData from this component which matches with componentInstance currentRowData
      if (this.currentRowData) {
        let filterObj:any = {
          g6: {
            filter: this.memberName.trim(),
            filterType: 'text',
            type: 'contains'
          }
        };
        if(this.audit){
          filterObj = {...filterObj,
            g10: {
              filter: this.audit.trim(),
              filterType: 'text',
              type: 'equals'
            }
          }
        }
        this.eprService.getMyProcessingFilesByFilter(filterObj, this.userId, false,this.db, this.ssoeFlag).subscribe(response => {
          // Updating the status here
          if (response && response.response[0] && response.response[0].g12) {
            this.status = response.response[0].g12;
            const updatedTRecord = this.data.body.translationsRecords.find(tRecord => tRecord.input.trim() === response.response[0].g12.trim())
            if(updatedTRecord){
              this.data.body.tRecord = updatedTRecord;
              this.updateStatus(this.data.body);
            }
          }
        });
      }
    }
    filterMsgAbberviated(data:any) {
    this.selectedTabVal = 'Extended';
    let msgDataRows;
      this.msgSupressService.getAllMsgSupressRows()
      .subscribe(res => {
        if (res != null) {
        msgDataRows = Object.entries(res).map((element) => element[1]['message']);
        let tabData:any[] = data
        if (msgDataRows != null && msgDataRows.length !== 0) {
          msgDataRows.forEach(element => {
            if (element != null && data) {
              const nullRows = data?.filter(x => x.auditMessage == null);
               tabData = tabData?.filter(x => {
                if (x.auditMessage == null) {
                return;
                }
                return !x.auditMessage?.toLowerCase().includes(element?.toLowerCase());
            })
              tabData = nullRows.concat(tabData);
              tabData.sort((a, b) => a.auditDate - b.auditDate);

            }
          });
        }
        this.auditGrid.api.setGridOption("rowData",tabData);
        }
      });
    }


    public get emailBody() : string {
      const subject = `${this.clientName?.trim()} | ${this.pli?.trim()} | ${this.carrier?.trim()} | ${this.memberName?.trim()} | ${this.status?.trim()}-${(this.data.body && this.data.body.tRecord) ? this.data.body.tRecord.output.trim() : ''}`;
      return `mailto:?subject=${subject}&cc=EligAdminApprovals`;
    }

}
