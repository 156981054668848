import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormArray, UntypedFormControl } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { FileLayoutService } from '../file-layout.service';
import {DatePipe} from '@angular/common';

@Component({
  selector: 'app-audit-history',
  templateUrl: './audit-history.component.html',
  styleUrls: ['./audit-history.component.scss']
})
export class AuditHistoryComponent implements OnInit {

  form: UntypedFormGroup;
  description: string;
  providedInfo: any;
  displayedColumns = ['auditDate', 'auditUser', 'auditComment'];
  constructor(
      private fb: UntypedFormBuilder,
      private dialogRef: MatDialogRef<AuditHistoryComponent>,
      @Inject(MAT_DIALOG_DATA) data ,
      private flService: FileLayoutService
      ) {
      this.description = data.description;
      this.providedInfo = data;
  }

  ngOnInit() {

    if (this.providedInfo.type === 'audit_History') {
        this.form = this.fb.group({
            auditComment: ['', [Validators.required, Validators.minLength(0), Validators.maxLength(50)]]
        });
    } else {


        const groupArray  = this.fb.group({history: new UntypedFormArray([])});

        this.providedInfo.history.forEach(data => {
            if (Object.keys(data).length) {
              (groupArray.get('history') as UntypedFormArray).push(this.addGroup(data));
              // get a Form array named record and add multiple record controls.

            }
          });
        this.form = groupArray;
    }
  }

   addGroup(data) {

    const date = new DatePipe('en-US').transform(data.auditDate, 'MM/dd/yyyy');
    const formGroup = new UntypedFormGroup({
        auditComment: new UntypedFormControl( {value : data.auditComment , disabled : true}, []),
        auditDate: new UntypedFormControl( date, []),
        auditUser: new UntypedFormControl( data.auditUser, []),
    });
    return formGroup;

}
  get history(): UntypedFormArray {
    if (this.form) {
      return this.form.get('history') as UntypedFormArray;
    } else {
      return null;
    }
  }

  save() {
      this.dialogRef.close(this.form.value);
  }

  close() {
      this.dialogRef.close(null);
  }

  get disableSave() {
    if (this.form && this.form.get('auditComment').value) {
    return false;
    } else {
    return true;
    }
  }
}
