<cvs-alert-placeholder id="sexcode-alert">
</cvs-alert-placeholder>
<div style="margin-top:30px; width:96%; justify-self:center;">
  <h2>Sex Code <button class="pds-btn-primary"  [disabled]="noChanges" (click)="save()">Save</button></h2>
  <ag-grid-angular class="ag-theme-alpine" [rowDragManaged]="true" (filterChanged)="onFilterChange( $event)"
                   [rowDragEntireRow]="true"
                   [rowDragMultiRow]="true" [suppressMenuHide]="true"
                   [rowSelection]="true"
                   (gridSizeChanged)="onGridSizeChanged($event)"
                   (rowDragEnd)="onRowDragEnd($event)"
                   (rowClicked)="onRowClicked($event)" domLayout='autoHeight' (sortChanged)="sortChange($event)" id="sexcodeGrid"
                   [context]="contextObj"  [stopEditingWhenCellsLoseFocus]="true" [stopEditingWhenGridLosesFocus]="true"
                   (gridReady)="onGridReady($event)" [rowData]="dataRows">
  </ag-grid-angular>
</div>
