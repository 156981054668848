<header>
  <div class="container">
    <div class="row">
      <div class="col-4">
        <h1>Reporting & Metrics</h1>
      </div>
    </div>
  </div>
</header>
<div>

  <mat-tab-group #tabGroup class="pds-primary-tab" (selectedTabChange)="tabchanged(tabGroup,$event)">
    <ng-container *ngIf="RteTabPermission('metrics')">
  
      <mat-tab  label="RTE Metrics">
        <app-rte-metrics  *ngIf="selectedTab === 'RTE Metrics'"></app-rte-metrics>
       </mat-tab>
     </ng-container>

     <ng-container *ngIf="RteTabPermission('suspense')">
  
      <mat-tab  label="Suspense">
        <app-rte-suspense *ngIf="selectedTab === 'Suspense'"></app-rte-suspense>
       </mat-tab>
     </ng-container>

     <ng-container *ngIf="RteTabPermission('history')">
  
      <mat-tab  label="EPR History">
        <app-rte-epr-history *ngIf="selectedTab === 'EPR History'"></app-rte-epr-history>
       </mat-tab>
     </ng-container>
  </mat-tab-group>  

  
