import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import {CVSAlertModule} from 'angular-component-library'

@Component({
  selector: 'app-add-edit-item-popup',
  templateUrl: './add-edit-item-popup.component.html',
  styleUrls: ['./add-edit-item-popup.component.scss']
})
export class AddEditItemPopupComponent implements OnInit {
  selectedOption = 'Member';
  public message: string;
  public reportMessageType: string;
  public messageDescription: string;
  public dataValues: any = {}
  public isEditMode: boolean = false;
  public isDelete: boolean;
  public title: string = "";
  type: string = "";
  mode: string;
  addEditItem: UntypedFormGroup;
  characterCount: number = 0;
  fieldType: string ="";
  dropdownConfig: any = [
    { type: "validations", title: " Validation", inputTitle: "validation"},
    { type: "fieldActions", title: " Field Action", inputTitle: "field action"},
    { type: "rejectActions", title: " Reaction", inputTitle: "reactions"},
    { type: "reportingMessageTypes", title: "  Reporting Message Types", inputTitle: "reporting message types"},
    { type: "reasonCodes", title: " Reason code", inputTitle: "reason code"},
  ];

  options = [
    { name: "Member", value: "Member" },
    { name: "Group", value: "Group" },
    { name: "Both", value: "Both" }
  ]
  constructor(private fb: UntypedFormBuilder, @Inject(MAT_DIALOG_DATA) public data:any , public dialogRef: MatDialogRef<AddEditItemPopupComponent>,) {
    this.dataValues= data;
    this.isEditMode = (this.dataValues?.actionType?.mode)?.includes("Edit");
    this.isDelete = (this.dataValues?.actionType?.mode)?.includes("Delete");
    const selectedType = this.dataValues?.actionType?.type;
    const currentActionDetails = this.dropdownConfig.filter((data)=> data.type == selectedType);
    this.mode = (this.dataValues?.actionType?.mode=="Add") ? 'to create a new': 'edit';

    if(currentActionDetails && currentActionDetails.length > 0) {
      this.title = this.dataValues?.actionType?.mode + currentActionDetails[0]?.title;
      this.type = currentActionDetails[0]?.inputTitle
      this.fieldType = (selectedType=="reportingMessageTypes") ? "Type": currentActionDetails[0]?.title;
    }
    this.addEditItem = this.fb.group({
      message: ['', Validators.required],
      reportMessageType: ['', Validators.required],
      messageDescription: ['']
    })
  }
 
  ngOnInit(): void {
    this.message = this.isEditMode ? this.dataValues.message :'';
    this.reportMessageType = this.isEditMode ? this.dataValues.reportMessageType :'';
    this.messageDescription = this.isEditMode ? this.dataValues.messageDescription :'';
    this.characterCount = this.messageDescription?.length;
    this.addEditItem.controls["message"]?.setValue(this.message);
    this.addEditItem.controls["reportMessageType"]?.setValue(this.reportMessageType);
    this.addEditItem.controls["messageDescription"]?.setValue(this.messageDescription);
  }
  save(): void{
    if(this.addEditItem.valid) {
      this.message =  this.addEditItem.get('message')?.value;
      this.reportMessageType =  this.addEditItem.get('reportMessageType')?.value;
      this.messageDescription = this.addEditItem.get('messageDescription')?.value;
      const newValidationRow = [{
        id:  this.isEditMode ? this.dataValues.id: null,
        message: this.message,
        reportMessageType: this.reportMessageType,
        messageDescription: this.messageDescription,
        isNew: this.isEditMode ? false : true,
        type: this.dataValues?.actionType?.type
      }];
      this.dialogRef.close(newValidationRow);
    }
  }
  deleteRecord(){
    this.dialogRef.close(this.dataValues.actionType);
  }
  findcharacterCount(event){
    this.characterCount  = 0;
    if(event?.target?.value && event?.target?.value !="") {
      this.characterCount  = event?.target?.value?.length;
    }    
  }
}
