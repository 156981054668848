import { DeleteBtnCellRendererComponent } from '../../../common/DeleteBtnCellRenderer';
import { AppState } from '../../../reducers/index';
import { Store } from '@ngrx/store';
import { CVSAlertService, CVSAlertType } from 'angular-component-library';
import { MessageSupressionService } from '../../../services/message-supression.service';
import { Component, OnInit, Output, ViewChild, EventEmitter, AfterViewInit, HostListener, OnDestroy } from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import {Subscription} from "rxjs";
import { EditBtnCellRendererComponent } from 'src/app/common/EditBtnCellRenderer';
import {DataManagementService} from "../../../services/data-management.service";


export interface ReportingMessageTypesModel {
  id: any;
  message: string;
}
export const reportingMessageTypesGridColumns = [
  {
    headerName: 'Id', field: 'id', hide: true
  },
  {
    headerName: 'Type', field: 'message',
    editable: false,
    sortable: true,
    filter: true, lockVisible: true, lockPosition: true, resizable: true,
    heigth: 300,
    width: 150,
    cellEditor: 'agLargeTextCellEditor',
    cellEditorPopup: true,
    cellRenderer: (params) => {
      return `<mat-icon class="mat-icon md-16 material-symbols-outlined"
      style="margin-right: 5px;"
      role="img" aria-hidden="true">
      drag_indicator</mat-icon>${params.data?.message}`;
    },
    cellEditorParams: {
      maxLength: 1000,
      rows: 10,
      cols: 50
    }
  },

  {
    headerName: 'Mbr or Grp', field: 'mbrorGrp',
    editable: false,
    sortable: true,
    filter: true, lockVisible: true, lockPosition: true, resizable: true,
    heigth: 300,
    width: 150,
    cellEditor: 'agLargeTextCellEditor',
    cellEditorPopup: true,
    cellRenderer: (params) => {
      return `${params.data?.reportMessageType}`;
    },
    cellEditorParams: {
      maxLength: 1000,
      rows: 10,
      cols: 50
    }
  },
  {
    headerName: 'Description', field: 'messageDescription',
    editable: true,
    sortable: true,
    filter: true, lockVisible: true, lockPosition: true, resizable: true,
    heigth: 300,
    width: 350,
    cellEditor: 'agLargeTextCellEditor',
    cellEditorPopup: true,
    cellEditorParams: {
      maxLength: 1000,
      rows: 10,
      cols: 50
    }
  },
  {
    headerName: '',
    field:"action",
    cellRenderer: EditBtnCellRendererComponent,
    editable: true,
    width: 90,
  }
];


@Component({
  selector: 'app-reporting-message-types',
  templateUrl: './reporting-message-types.component.html',
  styleUrls: ['./reporting-message-types.component.scss']
})
export class ReportingMessageTypesComponent implements OnInit, AfterViewInit, OnDestroy {
@ViewChild('reportingMessageTypesGrid') reportingMessageTypesGrid: AgGridAngular;
@Output() isSaveReportingMessageTypesCalled: EventEmitter<any> = new EventEmitter();

  dataRows = [];
  hlGridHeight = 350;
  contextObj: any;
  selectedStatus = '';
  changedRow: any;
  readonly: boolean;
  reportingMessageTypesSub: Subscription;
  updatedRec: any;
  isRowDragged: boolean;
  isItemDeleted: boolean = false;
  public rowSelection: 'single' | 'multiple' = 'multiple';
  filteredData: any=[];

constructor(private msgSupressService: MessageSupressionService, private alertService: CVSAlertService, private store: Store<AppState>,
            private dataManagementService: DataManagementService) {
    this.contextObj = {
      componentParent: this,
      currentPage: "reportingMessageTypes"
    };
  }
  ngOnInit() {
    this.getAllRows();/*data loading*/
    this.hlGridHeight = 350;
  }
ngAfterViewInit(): void {
  this.addNewRow();/*add new record clicked in parent Component*/
  setTimeout(() => {
      this.saveUnSavedRecords();/*refresh the grid based on dropdown selction in parent component*/
      this.alertService.clearAlert('#map-alert');
    }, 500);
  }
  getAllRows() {
    this.msgSupressService.getReportingMessageTypesRows().subscribe(res => {
      this.dataRows = res;
      console.log("RES ",res);
      //this.dataRows.sort((a, b) => a.id - b.id);
       this.dataManagementService.addRowdata.next(this.dataRows);
     });
    this.changedRow = false;

  }
  onRowDragEnd(event) {
    this.isRowDragged = true;
    this.emitSave();
  }
  saveUnSavedRecords() {
    this.msgSupressService.saveNewReportingMessageTypesRow$.subscribe(resp => {
      let nodeData: any = {}
      this.updatedRec = [];
      if (resp) {
        let isAddorEditNode =  this.reportingMessageTypesGrid.api.getRenderedNodes()?.some(node=> (node.data.isNew || node.data.isUpdated));
        this.reportingMessageTypesGrid.api.forEachNode((node) => {
            if(node.data?.isNew || node.data?.isUpdated) {
              delete node.data?.isUpdated;
              delete node.data?.isNew
              delete node.data?.action;
              nodeData = node.data;
              nodeData.type= 'reportingMessageTypes';
              nodeData.sortOrder = nodeData.sortOrder ? nodeData.sortOrder: node.rowIndex;
              this.updatedRec.push(nodeData);
            }
            if(this.isRowDragged || this.isItemDeleted) {
              nodeData = node.data;
              nodeData.sortOrder = node.rowIndex;
              this.updatedRec.push(nodeData);
            }
        });
        if (this.updatedRec && this.updatedRec.length) {
          if(isAddorEditNode) {
            this.msgSupressService.saveMsgSupressRow(this.updatedRec, true).subscribe(_ => {
              this.getAllRows();
              this.readonly = false;
              this.showAlert('Save successful!', CVSAlertType.Success)
            });
          }
           if(this.isRowDragged || this.isItemDeleted) {
            this.msgSupressService.saveSortedRecord(this.updatedRec, true).subscribe(_ => {
              this.getAllRows();
              this.isRowDragged = false;
              this.readonly = false;
              this.showAlert(this.isItemDeleted? 'Delete successful!': 'Save successful!', CVSAlertType.Success)
              this.isItemDeleted = false;
            });
          }
        }
      }
    });

  }

  addNewRow() {
   this.reportingMessageTypesSub= this.msgSupressService.onReportingMessageTypeMessage().subscribe(newRow => {
    const nextItemIndex = this.reportingMessageTypesGrid?.api?.getDisplayedRowCount();
    if (newRow != null && this.reportingMessageTypesGrid) {
        this.reportingMessageTypesGrid?.api?.applyTransaction({
          addIndex: nextItemIndex,
          add: newRow
        });
        this.reportingMessageTypesGrid?.api.setFocusedCell(0, 'id');

        this.hlGridHeight = (this.reportingMessageTypesGrid.api.getModel()?.getRowCount() * 42) + 52;
      }
    });
  }
  public updateRecord(node, record) {
    const currentRecordId = node?.data?.id;
    this.dataRows.map((item)=>  {
      if(item.id === currentRecordId) {
        node.data.isUpdated = true;
        item.message = record[0].message;
        item.reportMessageType = record[0].reportMessageType;
        item.messageDescription = record[0].messageDescription
        return
      }
    });
    this.msgSupressService.saveNewReportingMessageTypesRow$.next(true);
    this.reportingMessageTypesGrid.api.setGridOption("rowData", this.dataRows);
   }
   sortChange(event) {
    const sortOrder = event.api.getSortModel();
    this.reportingMessageTypesGrid.api.applyColumnState({
      state: sortOrder,
    });
  }


  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    if (this.readonly) {

      if (confirm('Changes have NOT been saved! Click \'OK\' to exit WITHOUT saving.')) {
        return true;
      } else {
        return false;
      }
    }
  }
  showAlert(alertMessage, alertType) {
    this.alertService.sendAlert({
      headline: alertMessage,
      alertType,
      removedAfterMilliseconds: 5000,
      alertDocumentHolderName: '#map-alert',
    });
  }
  ngOnDestroy(){
  this.reportingMessageTypesSub.unsubscribe();
  }
  onGridReady(params) {
    this.reportingMessageTypesGrid = params;
    this.hlGridHeight = this.dataRows.length * 51;
    this.reportingMessageTypesGrid.api.setGridOption("columnDefs", reportingMessageTypesGridColumns);
    this.reportingMessageTypesGrid.api.redrawRows();
    this.reportingMessageTypesGrid?.api.sizeColumnsToFit();
    this.reportingMessageTypesGrid.gridOptions = { onCellValueChanged(data)  {
      this.UpdatedRow(data);
    }};
  }
  UpdatedRow(cell) {
    cell.data.isUpdated = true;
    this.readonly = true;
    }
  emitSave() {
    this.isSaveReportingMessageTypesCalled.emit();

  }
public deleteTableRecord(node, colId) {
    if (node.data.isNew) {
      this.readonly = false;
      this.reportingMessageTypesGrid.api.applyTransaction({remove:[node.data]});
      this.hlGridHeight = (this.reportingMessageTypesGrid.api.getDisplayedRowCount() * 42) + 52;
    } else {
      this.msgSupressService.deleteMsgSupressRow([{ ...node.data }], false).subscribe(resp => {
        this.reportingMessageTypesGrid.api.applyTransaction({remove:[node.data]});
        this.isItemDeleted = true;
        this.msgSupressService.saveNewReportingMessageTypesRow$.next(true);
        this.hlGridHeight = (this.reportingMessageTypesGrid.api.getDisplayedRowCount() * 42) + 52;
      });
    }
  }

  onFilterChange(event: any) {
    this.filteredData = [];
    event.api.forEachNodeAfterFilter((node) =>{
      this.filteredData.push(node.data);
    })
    this.dataManagementService.addRowdata.next(this.filteredData);
  }
}
