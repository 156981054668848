<div class="mr">

<mat-grid-list cols="5">
    <mat-grid-tile>
      <h2 style="position: absolute; left: 15px;">On Boarding</h2>
    </mat-grid-tile>
    <mat-grid-tile></mat-grid-tile>
    <mat-grid-tile></mat-grid-tile>
    <mat-grid-tile></mat-grid-tile>
    <mat-grid-tile >
      <div style="position: absolute; right: 15px;"
      *ngIf="(permissionObject.addFtOnboarding && permissionObject.pbmRole)">
      <button *ngIf="isAdmin" id="createNewFiletracker"
              class="pds-btn-ghost" (click)="createNewFiletracker()">
<!--              [disabled]="isAddNewEntry">-->
<!--        <mat-icon svgIcon="plus&#45;&#45;s"></mat-icon>-->
        Add New Entry
      </button>
      </div>
    </mat-grid-tile>
  </mat-grid-list>

 <ag-grid-angular
        [domLayout]="'autoHeight'"
        class="ag-theme-alpine"
        #eprfiletracker
        (rowClicked)='onRowClicked($event)'
      >
      </ag-grid-angular>

  <div class="d-flex" style="padding: 10px;">
    <h2 style="justify-content: flex-start;">File Monitor</h2>
    <div style="justify-content: flex-end;">
      <span style="display:inline-flex" class=dashboard-hdr>
        <span class="slide-toggle">
          <mat-slide-toggle [checked]="islatest" (change)="onChange($event)" *ngIf="islatest">
            <span>Latest 7 Days</span>
          </mat-slide-toggle>
          <mat-slide-toggle [checked]="islatest" (change)="onChange($event)" *ngIf="!islatest">
            Older Than 7 Days
          </mat-slide-toggle>
        </span>
        <span>
          <span style="cursor:pointer;" (click)="refreshMyProcessingFiles('tableTwo')">
            <mat-icon svgIcon="refresh--s" class="refresh-btn" id="tableTwoRefreshProcessorBtn">
           Refresh
          </mat-icon>
          <span class="export-txt"> Refresh </span>
          </span>
          
          <span *ngIf="currentDateMonitor">
            <span class="dashboard-text">Last on </span>
            {{currentDateMonitor | date:' h:mm:ss'}}</span>
            <span style="cursor:pointer;" (click)="exportView('tableTwo')">
              <mat-icon
              id="tableTwoExportViewBtn"
               svgIcon="file-csv--s"  class="refresh-btn">
             Export View
            </mat-icon>
            <span class="export-txt"> Export </span>
            </span>
       
          </span>
      </span>
    </div>
  </div>
  <ag-grid-angular
    [domLayout]="'autoHeight'"
    class="ag-theme-alpine"
    #eprfilemonitor
    (rowClicked)='onRowClicked_Monitor($event)'
    (dragStopped)="onDragStopped('tableTwo')"
    (sortChanged)="onSortChange('tableTwo')"
    (firstDataRendered)="onFirstDataRendered($event)"
  >
  </ag-grid-angular>
</div>
