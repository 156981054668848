import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Store } from "@ngrx/store";
import { AppState } from "../reducers";
import { LoadEprAction } from "../actions/epr.action";
@Injectable({
    providedIn: 'root'
})
export class EprRouteResolver  {
    constructor(private store: Store<AppState>) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const eprId= route?.queryParams?.eprId;
        if(eprId){
            this.store.dispatch(new LoadEprAction(eprId,"mfe-admin-epr"));
        }
    }
}